import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useSelector } from 'react-redux';
import Modal from './Modal';

import BaseBlock from '../components/blocks/BaseBlock';
import Box from '../components/shared/atoms/Box';
import Text from '../components/shared/atoms/Text';
import Input from '../components/shared/atoms/Input';

import Button from '../components/shared/atoms/Button';
import Heading from '../components/shared/atoms/Heading';
// Icons
import * as api from '../utils/api';

const StyledButton = styled(Button)`
  width: ${(p) => (p.fixer ? 'auto' : '100%')};
`;

export default function MoreSlotsModal({ open, close, days }) {
  const { product_id, position } = useSelector((s) => s.course);
  const { t } = useTranslation();
  const [bookingUuid, setBookingUuid] = useState(null);

  useEffect(() => {
    // Extract booking-uuid from URL if present
    const urlParams = new URLSearchParams(window.location.search);
    const uuid = urlParams.get('booking-uuid');
    if (uuid) {
      setBookingUuid(uuid);
    }
  }, []);

  const nbSlots = useMemo(
    () =>
      Object.keys(days).reduce((acc, key) => {
        return acc + days[key].slots.filter((s) => !s.full).length;
      }, 0),
    [Object.keys(days).length],
  );

  const _submit = async (values, { setError, setSubmitting }) => {
    const payload = {
      position,
      product_id,
      nb_slots: nbSlots,
      ...values,
    };

    // Add booking-uuid to payload if it exists
    if (bookingUuid) {
      payload['booking-uuid'] = bookingUuid;
    }

    const waiting = await api.post(`/waitinglist`, payload);
    if (waiting.ok) {
      close();
    } else {
      setError(t('wronginformations'));
      setSubmitting(false);
    }
  };

  const ActionRow = ({ isSubmitting }) => (
    <Box>
      <StyledButton disabled={isSubmitting} alignSelf={'auto'} type={'submit'}>
        {t('send')}
      </StyledButton>
      <StyledButton variant="neutral" onClick={close} type={'button'} mt={2}>
        {t('close.cta')}
      </StyledButton>
    </Box>
  );
  return (
    <Modal open={open} onRequestClose={close} width="medium">
      <div>
        <div>
          <Heading level={5}>{t('moreslotsmodal.heading')}</Heading>
          <Text color="black" mb={5}>
            {t('moreslotsmodal.text')}
          </Text>
          <Formik
            initialValues={{ email: '', tel: '', prenom: '', nom: '', comment: '' }}
            onSubmit={_submit}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .required(t('email.invalid'))
                .email(t('email.invalid')),
              tel: Yup.string()
                .required(t('required.information')),
              prenom: Yup.string()
                .required(t('required.information')),
              nom: Yup.string()
                .required(t('required.information')),
              comment: Yup.string()
                .required(t('required.information'))
                .max(1023, t('long.description')),
            })}>
            {({ isSubmitting, setFieldValue, submitCount, errors }) => (
              <Form style={{ width: '100%' }}>
                <Field
                  name="prenom"
                  render={({ field }) => (
                    <Input
                      label="Prénom"
                      colorLabel="gray.darkest"
                      importantLabel={true}
                      mb={{ xs: 2, lg: 4 }}
                      color="black"
                      bg="white"
                      placeholder="Prénom"
                      onChange={(e) => setFieldValue('prenom', e.target.value)}
                      error={!!submitCount && errors.prenom}
                      {...field}
                    />
                  )}
                />
                <Field
                  name="nom"
                  render={({ field }) => (
                    <Input
                      label="Nom"
                      colorLabel="gray.darkest"
                      importantLabel={true}
                      mb={{ xs: 2, lg: 4 }}
                      color="black"
                      bg="white"
                      placeholder="Nom"
                      onChange={(e) => setFieldValue('nom', e.target.value)}
                      error={!!submitCount && errors.nom}
                      {...field}
                    />
                  )}
                />
                <Field
                  name="email"
                  render={({ field }) => (
                    <Input
                      label="E-mail"
                      colorLabel="gray.darkest"
                      importantLabel={true}
                      mb={{ xs: 2, lg: 4 }}
                      color="black"
                      bg="white"
                      placeholder="Email"
                      onChange={(e) => {
                        const value = e.target.value.toLowerCase().trim();
                        setFieldValue('email', value);
                      }}
                      error={!!submitCount && errors.email}
                      {...field}
                    />
                  )}
                />
                <Field
                  name="tel"
                  render={({ field }) => (
                    <Input
                      label="Téléphone"
                      colorLabel="gray.darkest"
                      importantLabel={true}
                      mb={{ xs: 2, lg: 4 }}
                      color="black"
                      bg="white"
                      placeholder="Téléphone"
                      onChange={(e) => setFieldValue('tel', e.target.value)}
                      error={!!submitCount && errors.tel}
                      {...field}
                    />
                  )}
                />
                <Field
                  name="comment"
                  render={({ field }) => (
                    <Input
                      label="Message"
                      colorLabel="gray.darkest"
                      importantLabel={true}
                      as="textarea"
                      mb={{ xs: 2, lg: 4 }}
                      color="black"
                      bg="white"
                      rows="3"
                      onChange={(e) => setFieldValue('comment', e)}
                      placeholder={t('moreslotsmodal.placeholder')}
                      error={!!submitCount && errors.comment}
                      {...field}
                    />
                  )}
                />
                <ActionRow isSubmitting={isSubmitting} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
}
