import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect
      x="0.9"
      y="-0.9"
      width="20.2"
      height="24.2"
      rx="4.1"
      transform="matrix(1 0 0 -1 5 28.2)"
      stroke="#161717"
      strokeWidth="1.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14 12H23"
      stroke="#161717"
      strokeWidth="1.8"
      strokeLinecap="round"
    />
    <path
      d="M9.06603 12H10.566"
      stroke="#161717"
      strokeWidth="1.8"
      strokeLinecap="round"
    />
    <path
      d="M9.06603 17.8679H10.566"
      stroke="#161717"
      strokeWidth="1.8"
      strokeLinecap="round"
    />
    <path
      d="M9.06603 23.7109H10.566"
      stroke="#161717"
      strokeWidth="1.8"
      strokeLinecap="round"
    />
    <path
      d="M14 17.8679H23"
      stroke="#161717"
      strokeWidth="1.8"
      strokeLinecap="round"
    />
    <path
      d="M14 23.7109H23"
      stroke="#161717"
      strokeWidth="1.8"
      strokeLinecap="round"
    />
    <rect
      x="10.9"
      y="2.9"
      width="10.2"
      height="4.2"
      rx="2.1"
      fill="white"
      stroke="#161717"
      strokeWidth="1.8"
    />
    <circle cx="24" cy="24" r="8" fill="#5ED9AD" />
    <path
      d="M27.0723 21.459L25.352 25.0424C24.9397 25.9013 24.7336 26.3307 24.411 26.5562C24.1278 26.7541 23.785 26.8482 23.4404 26.8226C23.048 26.7935 22.6514 26.5295 21.8584 26.0015L20.9995 25.4297"
      stroke="white"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = true;
const Checklist32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default Checklist32;
