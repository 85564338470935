import React from 'react';
import styled, { css } from 'styled-components';
import { color, space, typography } from 'styled-system';
import Text from './Text';

const switchHeading = (n) => {
  switch (n) {
    default:
    case 1:
      return {
        desktop: { size: '3em', margin: '24px' },
        mobile: { size: '2em', margin: '40px' },
      };
    case 2:
      return {
        desktop: { size: '2.25em', margin: '24px' },
        mobile: { size: '1.5em', margin: '32px' },
      };
    case 3:
      return {
        desktop: { size: '1.875em', margin: '16px' },
        mobile: { size: '1.375em', margin: '24px' },
      };
    case 4:
      return {
        desktop: { size: '1.5em', margin: '8px' },
        mobile: { size: '1.25em', margin: '8px' },
      };
    case 5:
      return {
        desktop: { size: '1.25em', margin: '8px' },
        mobile: { size: '1.125em', margin: '8px' },
      };
    case 6:
      return {
        desktop: { size: '1.125em', margin: '8px' },
        mobile: { size: '1.125em', margin: '8px' },
      };
  }
};
const StyledHeading = styled(Text)`
  text-align: ${(p) => (p.center ? 'center' : 'initial')};
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -0.01em;
  margin-top: 0;
  ${(p) => css`
    font-size: ${switchHeading(p.$level).mobile.size};
    margin-bottom: ${switchHeading(p.$level).mobile.margin};
    ${p.theme.mediaQueries.desktop} {
      font-size: ${switchHeading(p.$level).desktop.size};
      margin-bottom: ${p.mb ? p.mb : switchHeading(p.$level).desktop.margin};
    }
  `}
  ${color};
  ${space};
  ${typography};
`;

/**
 * @param {string} level Any number from 1 to 6
 * @param {string} as html element
 * @param {string} any extends Text properties (flexbox,color, space, layout)
 */
function Heading({ as: htmlAs, level = 1, children, ...props }) {
  return (
    <StyledHeading
      as={htmlAs || `h${level}`}
      $level={level}
      color="black"
      {...props}>
      {children}
    </StyledHeading>
  );
}
export const SecondaryHeading = styled('span')`
  color: ${(p) => p.theme.colors.blue.main};
  display: inline-flex;
`;

export default Heading;
