import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M30.095 4.086L31.46.819c.269-.642-.626-1.13-1.058-.58L27 4.581l1.905 1.333-1.365 3.267c-.269.642.626 1.13 1.058.58L32 5.419l-1.905-1.333zM7.5 24.25a5.5 5.5 0 100-11 5.5 5.5 0 000 11z"
      fill="#8A38F3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 7.25a.75.75 0 000 1.5h2.357l.3 1.948-7.59 2.009L11.213 9h1.037a.75.75 0 000-1.5h-4a.75.75 0 100 1.5h1.286l1.186 2.371-3.956 7.63h9.29l5.216-6.3.508 1.268a5.5 5.5 0 101.393-.557l-.945-2.36-.585-3.802H18zM13.787 17.5H9.233l2.318-4.47 2.235 4.47zm1.634-.084l4.01-4.842-5.68 1.503 1.67 3.339zm8.316-2.593a4 4 0 11-1.393.557l1.46 3.649a.75.75 0 101.392-.557l-1.46-3.65z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgEBikeColor32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgEBikeColor32;
