import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
// Components
import Box from '../../shared/atoms/Box';
import Row from '../../shared/atoms/Row';
import Heading from '../../shared/atoms/Heading';
import Input from '../../shared/atoms/Input';
import Button from '../../shared/atoms/Button';
import Alert from '../../shared/molecules/Alert';
// ValidationSchema
import { updatePasswordValidationSchema } from '../ValidationSchema';
// Icons
import { Eye24 } from '../../icons';

const Password = ({ submitPassword, updatePassword }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Heading level={5} mb={4}>
        {t('password')}
      </Heading>
      {updatePassword && (
        <Alert status="success" mb={4}>
          {t('password.successalert')}
        </Alert>
      )}
      <Formik
        initialValues={{
          current_password: '',
          new_password: '',
          new_password_confirm: '',
        }}
        validationSchema={updatePasswordValidationSchema}
        onSubmit={submitPassword}>
        {({ isSubmitting, submitCount, errors, values }) => (
          <Form>
            <Field
              name="current_password"
              render={({ field }) => (
                <Input
                  type="password"
                  mb={{ xs: 2, lg: 4 }}
                  color="black"
                  bg="white"
                  after={<Eye24 />}
                  placeholder={t('currentpassword.inputlabel')}
                  label={`${t('currentpassword.inputlabel')}*`}
                  error={!!submitCount && errors.current_password}
                  {...field}
                />
              )}
            />
            <Row sm gap={16}>
              <Field
                name="new_password"
                render={({ field }) => (
                  <Input
                    type="password"
                    mb={{ xs: 2, lg: 4 }}
                    color="black"
                    bg="white"
                    style={{ flex: 1 }}
                    placeholder={t('password')}
                    label={t('newpassword.inputlabel')}
                    error={!!submitCount && errors.new_password}
                    {...field}
                  />
                )}
              />
              <Field
                name="new_password_confirm"
                render={({ field }) => (
                  <Input
                    type="password"
                    mb={{ xs: 2, lg: 4 }}
                    color="black"
                    bg="white"
                    style={{ flex: 1 }}
                    label={t('confirmpassword.inputlabel')}
                    placeholder={t('confirmation')}
                    error={!!submitCount && errors.new_password_confirm}
                    {...field}
                  />
                )}
              />
            </Row>
            <Box>
              <Button
                disabled={
                  isSubmitting ||
                  !values.current_password ||
                  !values.new_password ||
                  !values.new_password_confirm
                }>
                {t('update')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Password;
