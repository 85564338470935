import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import PayFormNew from '../../PayFormNew';
import Box from '../atoms/Box';
import Text from '../atoms/Text';
import Radio from '../atoms/Radio';
import Cards from './Cards';
import CardLogos from '../atoms/CardLogos';
import PayFormNotLoggedIn from '../../PayFormNotLoggedIn';

const Container = styled(Box)`
  border: ${(p) =>
    p.selected
      ? `2px solid ${p.theme.colors.blue.electric}`
      : `1px solid ${p.theme.colors.gray.main}`};
  cursor: pointer;
`;

const CardsCheckout = ({
  cbs,
  _setCard,
  card,
  client,
  stripePayment,
  setStripePayment,
  loading,
  isNotLoggedIn = false,
  clientNotConnected,
  amount,
  giftcard,
  setGiftcard,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {!!cbs.length && (
        <Box my={4}>
          <Cards
            cards={cbs}
            card={card}
            client={client}
            _setCard={_setCard}
            loading={loading}
          />
          <Container
            borderRadius="xs"
            alignItems="center"
            onClick={() => _setCard('__ADD__')}
            selected={card === '__ADD__'}
            p={4}
            mt={3}>
            <Box
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%">
              <Text color="black" fontWeight="bold">
                {t('checkout.text')}
              </Text>
              <Radio checked={card === '__ADD__'} />
            </Box>
            {card === '__ADD__' && (
              <Box px={4} my={4} width="100%">
                <PayFormNew
                  stripePayment={stripePayment}
                  setStripePayment={setStripePayment}
                />
              </Box>
            )}
          </Container>
        </Box>
      )}
      {card === '__ADD__' && !cbs.length && !isNotLoggedIn && (
        <Box mb={4}>
          <PayFormNew
            stripePayment={stripePayment}
            setStripePayment={setStripePayment}
          />
        </Box>
      )}
      {isNotLoggedIn && (
        <Box mb={4}>
          <PayFormNotLoggedIn
            stripePayment={stripePayment}
            setStripePayment={setStripePayment}
            clientNotConnected={clientNotConnected}
            amount={amount}
            giftcard={giftcard}
            setGiftcard={setGiftcard}
          />
        </Box>
      )}
      <CardLogos mb={2} />
    </>
  );
};

export default CardsCheckout;
