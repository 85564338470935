import React from 'react';
import styled from 'styled-components';
// Components shared
import SectionBackground from '../../shared/molecules/SectionBackground';
import Box from '../../shared/atoms/Box';
import Text from '../../shared/atoms/Text';

const StyledImg = styled.img`
  max-width: 56px;
  height: 57px;
  object-fit: cover;
  border-radius: 40px;
`;

const CustomerReviews = ({ bg, img, review, name, job }) => (
  <SectionBackground
    bg={bg}
    p={5}
    mb={{ xs: 7, md: '112px' }}
    borderRadius={{ xs: 0, md: '24px' }}>
    <Box mb={5}>
      <Text variant="large" fontWeight="bold">
        {review}
      </Text>
    </Box>
    <Box flexDirection={'row'} alignItems={'center'}>
      <StyledImg src={img} alt="Avis client" />
      <Box ml={2}>
        <Text fontWeight="bold" color="black">
          {name}
        </Text>
        <Text variant="medium" color="black">
          {job}
        </Text>
      </Box>
    </Box>
  </SectionBackground>
);

export default CustomerReviews;
