import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components shared
import Container from '../../shared/atoms/Container';
import Box from '../../shared/atoms/Box';
import Row from '../../shared/atoms/Row';
import Heading from '../../shared/atoms/Heading';
import Text from '../../shared/atoms/Text';
import LightBulbColor32 from '../../icons/LightBulbColor32';

const StyledImg = styled.img`
  border-radius: 16px;
  width: 100%;

  height: auto;
  align-self: center;
`;

const HandleCustomerService = () => {
  const { t } = useTranslation();
  return (
    <Container mb={{ xs: 7, md: 10 }}>
      {/* Main Row */}
      <Row md gap={24}>
        {/* Left Box */}
        <Box>
          <Box
            border="1px solid"
            borderColor="gray.light"
            borderRadius="lg"
            p={5}>
            <StyledImg src="/static/img/pictos/image-section-2-1_marque.png" />
            <Heading level={4} mt={5}>
              {t('handlecustomerservice.brand.titles.1')}
            </Heading>
            <Text mb={5}>{t('handlecustomerservice.brand.texts.1')}</Text>
          </Box>
          <Box bg="blue.pale" borderRadius="lg" p={5} mt={5}>
            <Heading level={6}>
              <LightBulbColor32 /> {t('goodtoknow')}
            </Heading>
            <Text>{t('handlecustomerservice.texts.2')}</Text>
          </Box>
        </Box>
        {/* Right Box */}
        <Box style={{ flex: 2 }}>
          <Row
            md
            border="1px solid"
            borderColor="gray.light"
            borderRadius="lg"
            p={5}
            gap={24}>
            <Box justifyContent="center">
              <Heading level={4}>{t('handlecustomerservice.titles.3')}</Heading>
              <Text>{t('handlecustomerservice.texts.3')}</Text>
            </Box>
            <Box>
              <StyledImg src="/static/img/photos/image-section-2-2_marque.png"></StyledImg>
            </Box>
          </Row>
          <Row md gap={24} mt={5}>
            <Box
              border="1px solid"
              borderColor="gray.light"
              borderRadius="lg"
              p={5}>
              <StyledImg src="/static/img/photos/image-section-2-3_marque.png" />
              <Heading level={4} mt={5}>
                {t('handlecustomerservice.titles.4')}
              </Heading>
              <Text>{t('handlecustomerservice.texts.4')}</Text>
            </Box>
            <Box
              border="1px solid"
              borderColor="gray.light"
              borderRadius="lg"
              p={5}>
              <Heading level={4}>{t('handlecustomerservice.titles.5')}</Heading>
              <Text mb={5}>{t('handlecustomerservice.texts.5')}</Text>
              <StyledImg src="/static/img/pictos/image-section-2-4_marque.png" />
            </Box>
          </Row>
        </Box>
      </Row>
    </Container>
  );
};

export default HandleCustomerService;
