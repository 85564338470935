import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// Components
import Box from '../shared/atoms/Box';
import Heading from '../shared/atoms/Heading';
import Link from '../shared/atoms/Link';
import Text from '../shared/atoms/Text';
import Badge from '../shared/atoms/Badge';
import DottedRow from '../shared/molecules/DottedRow';
// Icons
import ExternalLink16 from '../icons/ExternalLink16';
// Utils
import idToFiche from './utils/idToFiche';
import { getLang } from '../../utils/utils';

const StyledUl = styled.ul`
  list-style-type: none;
  margin: 0;
`;

const StyledLink = styled(Link)`
  max-width: 85%;
`;

const PricesRows = ({ prestation }) => {
  const prestationDisplay = prestation.subs || prestation;
  if (!prestationDisplay.length) return null;
  return (
    <Box my={5}>
      <Heading level={4} color="black">
        {prestation.nom}
      </Heading>
      <StyledUl>
        {prestationDisplay.map((sub) => (
          <DottedRow
            key={sub.id}
            mb={4}
            before={
              getLang().lang === 'fr' && idToFiche[sub.id] ? (
                <StyledLink
                  variant="medium"
                  color="black"
                  after={<ExternalLink16 />}
                  href={`https://fiches.cyclofix.com/${idToFiche[sub.id]}`}>
                  <span>{sub.nom}</span>
                </StyledLink>
              ) : (
                <Text variant="medium" color="black">
                  <span>{sub.nom}&nbsp;</span>
                </Text>
              )
            }
            after={
              <Badge
                styledText={{
                  fontWeight: '600',
                  variant: 'small',
                  color: 'blue.dark',
                }}
                bg="blue.pale"
                mb={0}
                p={2}
                style={{
                  fontVariantNumeric: 'tabular-nums',
                  lineHeight: '14px',
                }}>
                {sub.prix}&euro;
              </Badge>
            }
          />
        ))}
      </StyledUl>
    </Box>
  );
};

PricesRows.propTypes = {
  prestation: PropTypes.object,
};

export default PricesRows;
