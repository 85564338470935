import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import PositionAutocomplete from './PositionAutocomplete';
import Input from '../atoms/Input';
import Box from '../atoms/Box.jsx';

const StyledInput = styled(Input)`
  ${(p) =>
    p.withPadding &&
    css`
      && > .inputContainer {
        padding-left: 32px;
        padding-right: 32px;
      }
    `}
`;

export default function AddressInput({
  withLabel = true,
  naked = true,
  before,
  after,
  bg,
  withAdornment = false,
  error = false,
  withGradient = false,
  placeholder,
  autoSelect = false,
  withPadding = false,
  ...props
}) {
  const { t } = useTranslation();
  return (
    <Box {...props} bg={bg}>
      <StyledInput
        label={withLabel ? t('address') : undefined}
        bg={bg}
        naked={naked}
        withPadding={withPadding}
        before={before}
        after={after}
        justifyContent="center"
        error={error}
        withGradient={withGradient}
        isAddressInput>
        <PositionAutocomplete
          autoSelect={autoSelect}
          withAdornment={withAdornment}
          placeholder={placeholder || t('addressinput.text')}
          error={error}
        />
      </StyledInput>
    </Box>
  );
}
