import { combineReducers } from 'redux';
import client from './client.jsx';
import autodiag from './autodiag.jsx';
import course from './course.jsx';
import places from './places.jsx';
import diag from './diag.jsx';
import lang from './lang.jsx';

const reducers = combineReducers({
  client,
  course,
  places,
  diag,
  lang,
  autodiag,
});

export default reducers;
