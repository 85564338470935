import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.97 7.4c.02.197.03.397.03.6v1h-.75v1.15a5.25 5.25 0 11-10.5 0V9H6V8a6 6 0 0111.97-.6zm-9.62 2.75V9h7.3v1.15a3.65 3.65 0 01-7.3 0zM7.64 7.4a4.398 4.398 0 011.81-2.986V5.5a.8.8 0 101.6 0V3.75a.81.81 0 00-.001-.047 4.417 4.417 0 011.902 0 .81.81 0 00-.001.047V5.5a.8.8 0 001.6 0V4.414A4.398 4.398 0 0116.36 7.4H7.64z"
      fill="#000"
    />
    <path
      d="M10.696 16.2c-1.433 0-2.348 0-3.193.258a5.9 5.9 0 00-2.04 1.1c-.68.563-1.183 1.328-1.971 2.525l-.344.522a.9.9 0 001.504.99l.262-.398c.897-1.364 1.25-1.883 1.697-2.254a4.1 4.1 0 011.418-.764c.556-.17 1.183-.179 2.816-.179h2.31c1.633 0 2.26.01 2.816.179a4.1 4.1 0 011.418.764c.447.371.8.89 1.697 2.254l.262.398a.9.9 0 001.504-.99l-.344-.522c-.787-1.197-1.29-1.962-1.97-2.525a5.9 5.9 0 00-2.041-1.1c-.845-.258-1.76-.258-3.193-.258h-2.608z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgFixer24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgFixer24;
