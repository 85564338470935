import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Checkbox from '../Checkbox';
import Separator from '../Separator';
import PayForm from '../PayForm';
import BaseBlock from './BaseBlock';
import Spinner from '../Spinner';
import * as api from '../../utils/api';
import Button from '../Button';

const CardPicker = ({ onSuccess, ...props }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [cbs, setCbs] = useState([]);
  const [card, setCard] = useState('');

  useEffect(() => {
    loadCards();
  }, []);

  const loadCards = async () => {
    const { client } = props;
    if (!client || !client.id) return;

    const cbs = await api.get(`/clients/${client.id}/sources`);

    let card = cbs.length ? cbs[0].id : '__ADD__';
    const defaultCard = cbs.find((c) => c.default);
    if (typeof defaultCard !== 'undefined') card = defaultCard.id;

    setLoading(false);
    setCbs(cbs);
    setCard(card);
  };

  return (
    <div>
      <BaseBlock>
        {!!cbs.length && (
          <div>
            <h3>{t('cardpicker.heading')}</h3>
            <div>
              {cbs.map((cb) => (
                <Checkbox
                  key={cb.id}
                  label={t(`cardpicker.cardlabel`, {
                    brand: cb.card.brand.toUpperCase(),
                    last4: cb.card.last4,
                  })}
                  value={card === cb.id}
                  onClick={() => setCard(cb.id)}
                />
              ))}
              <Separator axe="horizontal" />
              <Checkbox
                label={t('addnewcard.cta')}
                value={card === '__ADD__'}
                onClick={() => setCard('__ADD__')}
              />
            </div>
          </div>
        )}
        {card === '__ADD__' && (
          <PayForm onSuccess={onSuccess} isStandalone={true} />
        )}
        {!!card && card !== '__ADD__' && (
          <div>
            <Button
              main
              stacked
              style={{ width: 270 }}
              onClick={() => onSuccess(card)}
              disabled={loading}
              right>
              {t('confirm.cta')}
            </Button>
          </div>
        )}
        <BaseBlock style={{ textAlign: 'center' }}>
          {loading && <Spinner />}
        </BaseBlock>
      </BaseBlock>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    client: state.client,
  };
};

export default connect(mapStateToProps, null)(CardPicker);
