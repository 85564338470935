import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
// Shared components
import Box from '../../shared/atoms/Box';
import Text from '../../shared/atoms/Text';
import Check24 from '../../icons/Check24';

const StyledLiBox = styled(Box)`
  z-index: 1;
`;
const Progress = styled.div`
  ${(p) => {
    return css`
      position: absolute;
      z-index: 0;
      top: 15px;
      right: -98px;
      height: 6px;
      width: 147px;
      background-color: ${p.theme.colors.white};
      border-radius: 10%;
    `;
  }}
`;

const StyledFiller = styled.div`
  ${(p) => {
    return css`
      position: absolute;
      z-index: 0;
      top: 15px;
      right: -98px;
      height: 6px;
      width: 147px;
      background-color: ${p.theme.colors.blue.electric};
      border-radius: 10%;
      transition: all 1s ease;
    `;
  }}
`;

const ProgressCourse = ({ statut }) => {
  const { t } = useTranslation();
  return (
    <Box flexDirection="row" mt={5}>
      <StyledLiBox alignItems="center" position="relative">
        {statut === 'scheduled' || statut === 'finished' ? (
          <StyledFiller />
        ) : (
          <Progress />
        )}

        <Box
          bg="blue.electric"
          width="28px"
          height="36px"
          justifyContent="center"
          alignItems="center"
          borderRadius="sm"
          zIndex={2}>
          <Check24 color="white" />
        </Box>
        <Text
          textAlign="center"
          color={statut === 'dispatched' ? 'blue.electric' : 'gray.darkest'}
          fontWeight={2}
          fontSize="sm">
          {t('progresscourse.texts.1')}
        </Text>
      </StyledLiBox>
      <StyledLiBox alignItems="center" position="relative" ml={8}>
        {statut === 'finished' ? <StyledFiller /> : <Progress />}
        <Box
          bg={
            statut === 'scheduled' || statut === 'finished'
              ? 'blue.electric'
              : 'white'
          }
          width="28px"
          height="36px"
          justifyContent="center"
          alignItems="center"
          borderRadius="sm"
          zIndex={2}>
          <Check24 color="white" />
        </Box>
        <Text
          textAlign="center"
          fontSize="sm"
          color={statut === 'scheduled' ? 'blue.electric' : 'gray.darkest'}
          fontWeight={statut === 'scheduled' || statut === 'finished' ? 2 : 0}>
          {t('progresscourse.texts.2')}
        </Text>
      </StyledLiBox>
      <StyledLiBox alignItems="center" position="relative" ml={7}>
        <Box
          bg={statut === 'finished' ? 'blue.electric' : 'white'}
          width="28px"
          height="36px"
          justifyContent="center"
          alignItems="center"
          borderRadius="sm"
          zIndex={2}>
          <Check24 color="white" />
        </Box>
        <Text
          fontSize="sm"
          textAlign="center"
          color={statut === 'finished' ? 'blue.electric' : 'gray.darkest'}
          fontWeight={statut === 'finished' ? 2 : 0}>
          {t('progresscourse.texts.3')}
        </Text>
      </StyledLiBox>
    </Box>
  );
};

export default ProgressCourse;
