import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M14.077 11.928l4.13-1.393-4.13 6.557 10.44-2.964"
      stroke="#FFE249"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.25 5a.75.75 0 01.75-.75h3.65l.093.644 1.999 13.997v.006l.445 3.109a3 3 0 11-1.475.284l-.216-1.514-3.92 4.704-.225.27H9.905A3.001 3.001 0 014 25a3 3 0 015.905-.75h8.744l4.561-5.474L21.35 5.75H19a.75.75 0 01-.75-.75zM8.5 25a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm18 0a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgScooterColor32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgScooterColor32;
