import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M16.662 10a.9.9 0 00-1.623-.78l-1.72 3.583a13.72 13.72 0 01-.468.927c-.114.194-.168.233-.177.24a.6.6 0 01-.388.106c-.012-.001-.077-.007-.275-.115a13.739 13.739 0 01-.875-.558l-.86-.572a.9.9 0 00-.997 1.498l.89.592c.37.247.695.463.976.618.297.163.626.303 1.007.332a2.4 2.4 0 001.553-.427c.313-.218.524-.507.696-.799.164-.277.332-.628.525-1.03L16.662 10z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.101 2.37C9.585 4.56 4.5 5.53 4.5 5.53c0 7.024.378 13.737 7.78 16.431a.64.64 0 00.439 0C20.122 19.267 20.5 12.554 20.5 5.53c0 0-5.174-1.033-7.64-3.178A5.46 5.46 0 0112.49 2c-.121.127-.251.25-.389.37zm4.666 3.993c.714.243 1.38.437 1.925.583-.036 2.966-.223 5.61-1.004 7.825-.817 2.317-2.29 4.183-5.188 5.347-2.898-1.164-4.371-3.03-5.188-5.347-.78-2.213-.968-4.855-1.004-7.817a29.07 29.07 0 001.888-.565c1.291-.436 2.93-1.097 4.3-2.05 1.355.932 2.988 1.588 4.271 2.024z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgInsurance24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgInsurance24;
