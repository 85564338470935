import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx={16} cy={16.5} r={7} fill="#28D898" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 16.5a5 5 0 11-10 0 5 5 0 0110 0zm-1.8 0a3.2 3.2 0 11-6.4 0 3.2 3.2 0 016.4 0z"
      fill="#1D1D1D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.5 14a6.5 6.5 0 01-3.581 5.81A6.5 6.5 0 0116 27.623a6.5 6.5 0 01-9.919-7.814A6.5 6.5 0 019.666 7.533a6.503 6.503 0 0112.668 0A6.5 6.5 0 0129.5 14zm-8.92-6.062l.357 1.547 1.58-.16a4.7 4.7 0 012.593 8.877l-1.547.778.718 1.575a4.7 4.7 0 01-7.172 5.65L16 25.34l-1.109.867a4.7 4.7 0 01-7.172-5.65l.718-1.576-1.546-.778a4.7 4.7 0 012.593-8.877l1.58.16.356-1.547a4.703 4.703 0 019.16 0z"
      fill="#1D1D1D"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = true;
const SvgSpring32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgSpring32;
