import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as api from '../../../../utils/api';
import characteristic from '../MyBike/characteristic';
import { suggestionKey, suggestionValue } from './utils';
// Components
import Modal from '../../../../modals/Modal';
import Text from '../../../shared/atoms/Text';
import Input from '../../../shared/atoms/Input';
import Button from '../../../shared/atoms/Button';
import Heading from '../../../shared/atoms/Heading';
import Row from '../../../shared/atoms/Row';
import Box from '../../../shared/atoms/Box';
import Link from '../../../shared/atoms/Link';
// Icons
import RecordEditColor56 from '../../../icons/RecordEditColor56';
import Time16 from '../../../icons/Time16';

const InputRow = ({ specs, updateNewSpecs, index }) => {
  const { t } = useTranslation();

  const BikeSpecSuggestion = () => {
    const translateKey = suggestionKey(t);
    const translateValue = suggestionValue(t);
    const spec = translateValue.map((_, index) => {
      return { [translateKey[index]]: translateValue[index] };
    });
    return spec;
  };

  const { property, value, error } = specs;
  const [suggestions, setSuggestions] = useState(BikeSpecSuggestion);

  const filter = (search) => {
    setSuggestions(
      suggestions.map((category) => {
        const key = Object.keys(category)[0];
        const filteredArray = category[key].filter((p) =>
          p.toLowerCase().includes(search.toLowerCase()),
        );
        return { [key]: filteredArray };
      }),
    );
  };

  return (
    <Row mb={2}>
      <Input
        value={property}
        error={error}
        placeholder={t('bikespecs.modal.placeholders.1')}
        suggestions={suggestions}
        onChange={(e) =>
          e.target
            ? (updateNewSpecs({
              property: e.target.value,
              value,
              index,
              error: false,
            }),
              filter(e.target.value))
            : updateNewSpecs({ property: e, value, index, error: false })
        }
      />
      <Input
        placeholder={t('bikespecs.modal.placeholders.2')}
        value={value}
        error={error}
        onChange={(e) =>
          updateNewSpecs({
            property,
            value: e.target.value,
            index,
            error: false,
          })
        }
      />
    </Row>
  );
};

const BikeSpecsModal = ({
  showModal,
  openModal,
  bike,
  currentSpec,
  setCurrentSpec,
  setLoading,
  getBikeAndCourse,
  ...props
}) => {
  const { t } = useTranslation();
  const characteristicTranslate = characteristic(t);
  const [newSpecs, setNewSpecs] = useState([
    { property: '', value: '', index: 0, error: false },
  ]);
  const [currentProperty, setCurrentProperty] = useState('');
  const [currentValue, setCurrentValue] = useState('');
  const [errorCurrentValue, setErrorCurrentValue] = useState(false);

  const updateNewSpecs = ({ property, value, index, error }) => {
    const copySpecs = [...newSpecs];
    copySpecs[index] = { property, value, index, error };
    setNewSpecs(copySpecs);
  };

  const handleSave = async () => {
    const specPromise = [];
    let error = false;
    for (const spec of newSpecs) {
      if (!spec.property || !spec.value) {
        const copySpecs = [...newSpecs];
        copySpecs[spec.index] = {
          property: spec.property,
          value: spec.value,
          index: spec.index,
          error: t('bikespecs.modal.errors.1'),
        };
        setNewSpecs(copySpecs);
        error = true;
      }
      const key = Object.entries(characteristicTranslate).reduce(
        (acc, [key, value]) => {
          if (value.title === spec.property) {
            acc.push(key);
          }
          return acc;
        },
        [],
      );
      specPromise.push(
        api.post(`/velos/${bike.id}/properties`, {
          property: key[0] || spec.property,
          value: spec.value,
        }),
      );
    }
    if (!error) {
      await Promise.all(specPromise);
      setLoading(true);
      getBikeAndCourse();
      openModal(false);
      setNewSpecs([{ property: '', value: '', index: 0 }]);
    }
  };

  const deleteProperty = async () => {
    await api.del(`/velos/${bike.id}/properties`, {
      property: currentProperty,
    });
    setLoading(true);
    getBikeAndCourse();
    openModal(false);
    setCurrentProperty('');
    setCurrentValue('');
  };

  const updateValue = async () => {
    if (currentProperty === '' || currentValue === '') {
      setErrorCurrentValue(t('bikespecs.modal.errors.2'));
    } else {
      await api.put(`/velos/${bike.id}/properties`, [
        {
          property: currentProperty,
          value: currentValue,
        },
      ]);
      setLoading(true);
      getBikeAndCourse();
      openModal(false);
      setCurrentProperty('');
      setCurrentValue('');
      setErrorCurrentValue(false);
    }
  };

  const addInput = () => {
    return setNewSpecs([
      ...newSpecs,
      {
        property: '',
        value: '',
        index: newSpecs.length,
        error: false,
      },
    ]);
  };
  useEffect(() => {
    if (currentSpec && showModal === 'SPECS_UPDATE') {
      const key = Object.keys(currentSpec);
      const newPropertyKey = characteristic[currentSpec.key]
        ? currentSpec.key
        : key[0];
      setCurrentProperty(newPropertyKey);
      setCurrentValue(currentSpec[key[0]]);
    }
  }, [currentSpec, showModal]);

  return (
    <Modal
      zIndex="1002"
      overflow="visible"
      width="medium"
      hasCloseAction={true}
      open={showModal === 'SPECS_ADD' || showModal === 'SPECS_UPDATE'}
      onRequestClose={() => {
        openModal(false);
        setNewSpecs([{ property: '', value: '', index: 0 }]);
        setCurrentSpec(null);
        setCurrentProperty('');
        setCurrentValue('');
        setErrorCurrentValue(false);
      }}>
      <Box>
        <RecordEditColor56 />
        <Heading level={4} mt={4}>
          {showModal === 'SPECS_ADD' && t('bikespecs.modal.headings.1')}
          {showModal === 'SPECS_UPDATE' && t('bikespecs.modal.headings.2')}
        </Heading>
        <Box mt={4}>
          <Row>
            <Text fontWeight={2}>{t('bikespecs.modal.texts.1')}</Text>
            <Text fontWeight={2}>{t('bikespecs.modal.texts.2')}</Text>
          </Row>
          {showModal === 'SPECS_ADD' &&
            newSpecs.map((specs) => (
              <InputRow
                specs={specs}
                updateNewSpecs={updateNewSpecs}
                index={specs.index}
                key={specs.index}
              />
            ))}
          {showModal === 'SPECS_UPDATE' && (
            <>
              <Row>
                <Input value={currentProperty} disabled={true} />
                <Input
                  value={currentValue}
                  error={errorCurrentValue}
                  onChange={(e) => {
                    setCurrentValue(e.target.value);
                    setErrorCurrentValue(false);
                  }}
                />
              </Row>
              <Link
                color="primary.darker"
                mt={4}
                before={<Time16 />}
                onClick={() => deleteProperty()}>
                {t('bikespecs.modal.delete')}
              </Link>
            </>
          )}
          <Box alignItems="flex-end" mt={2}>
            {showModal === 'SPECS_ADD' && (
              <Link
                fontWeight={2}
                color="blue.electric"
                onClick={() => addInput()}>
                {t('bikespecs.modal.add')}
              </Link>
            )}
            <Box flexDirection="row" mt={6}>
              <Button
                variant="neutral"
                mr={2}
                onClick={() => {
                  openModal(false);
                  setNewSpecs([{ property: '', value: '', index: 0 }]);
                  setCurrentSpec(null);
                  setCurrentProperty('');
                  setCurrentValue('');
                  setErrorCurrentValue(false);
                }}>
                {t('button.cancel')}
              </Button>
              <Button
                color="blue.electric"
                onClick={() => {
                  showModal === 'SPECS_ADD' && handleSave();
                  showModal === 'SPECS_UPDATE' && updateValue();
                }}>
                {t('save')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default BikeSpecsModal;
