import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.751 7.249L16 3.624 7.249 7.249 3.624 16l3.625 8.751L16 28.376l8.751-3.625L28.376 16l-3.625-8.751zM16 2L6.1 6.1 2 16l4.1 9.9L16 30l9.9-4.1L30 16l-4.1-9.9L16 2zm1.32 7.547a.75.75 0 01.608.87l-1.503 8.523a.75.75 0 01-1.477-.26l1.503-8.524a.75.75 0 01.869-.609zm-1.914 10.848a.75.75 0 01.609.869l-.055.31a.75.75 0 01-1.477-.26l.054-.31a.75.75 0 01.87-.609z"
      fill="#000"
    />
    <path
      d="M9 11l2 4"
      stroke="#8A38F3"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M29 8l2-2"
      stroke="#FFE249"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M19 20.875L20.574 22l1.111-3L24 20.575"
      stroke="#28D898"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgProblem32Color = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgProblem32Color;
