import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M8 11.5a1 1 0 100-2 1 1 0 000 2z" fill="#000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.25 4.75a3.75 3.75 0 117.5 0v1.357c.144.03.275.07.398.121a3 3 0 011.624 1.624C14 8.403 14 9.102 14 10.5c0 1.398 0 2.097-.228 2.648a3 3 0 01-1.624 1.624C11.597 15 10.898 15 9.5 15h-3c-1.398 0-2.097 0-2.648-.228a3 3 0 01-1.624-1.624C2 12.597 2 11.898 2 10.5c0-1.398 0-2.097.228-2.648a3 3 0 011.624-1.624c.123-.05.254-.09.398-.121V4.75zm6 0v1.252C10.024 6 9.776 6 9.5 6h-3c-.276 0-.524 0-.75.002V4.75a2.25 2.25 0 014.5 0zM6.5 7.5c-.72 0-1.184 0-1.54.025-.341.023-.472.063-.534.09a1.5 1.5 0 00-.812.811c-.026.062-.066.193-.089.535-.024.355-.025.82-.025 1.539 0 .72 0 1.184.025 1.54.023.341.063.472.09.534a1.5 1.5 0 00.811.812c.062.026.193.066.535.089.355.024.82.025 1.539.025h3c.72 0 1.184 0 1.54-.025.341-.023.472-.063.534-.09a1.5 1.5 0 00.812-.811c.026-.062.066-.193.089-.535.024-.355.025-.82.025-1.539 0-.72 0-1.184-.025-1.54-.023-.341-.063-.472-.09-.534a1.5 1.5 0 00-.811-.812c-.062-.026-.193-.066-.535-.089-.355-.024-.82-.025-1.539-.025h-3z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgLock16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgLock16;
