import React from 'react';
import { useDispatch } from 'react-redux';
import { setLang } from '../actions/lang';
import Dropdown from '../components/Dropdown';
import Box from '../components/shared/atoms/Box';
import { getLang } from '../utils/utils';

const LANGUES = [
  {
    value: 'fr',
    label: 'Français',
  },
  {
    value: 'en',
    label: 'English',
  },
];

const ChooseLanguage = ({ ...props }) => {
  const { lang } = getLang();
  const langue = LANGUES.find((l) => l.value === lang);

  const dispatch = useDispatch();

  const onChange = (value) => {
    dispatch(setLang(value));
  };

  return (
    <Box
      {...props}
      width="55%"
      alignSelf={{ xs: 'flex-start', lg: 'flex-end' }}>
      <Dropdown onChange={onChange} options={LANGUES} value={langue.label} />
    </Box>
  );
};

export default ChooseLanguage;
