export const SET_CLIENT = 'SET_CLIENT';
export const UNSET_CLIENT = 'UNSET_CLIENT';

export function setClient(client) {
  return (dispatch) => {
    dispatch({
      type: SET_CLIENT,
      client,
    });
  };
}

export function unsetClient() {
  return (dispatch) => {
    dispatch({
      type: UNSET_CLIENT,
    });
  };
}
