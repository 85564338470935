import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as api from '../../../utils/api';
// Components
import Box from '../../shared/atoms/Box';
import Text from '../../shared/atoms/Text';
import Cards from '../../shared/molecules/Cards';
import Alert from '../../shared/molecules/Alert';
// Hooks
import useAlert from '../../../hooks/useModal';

const StyledImg = styled.img`
  width: 83px;
  margin-bottom: 8px;
`;

const DisplayCards = ({ client, newCard, hasDeleteCard, setHasDeleteCard }) => {
  const { t } = useTranslation();
  const didMount = useRef(false);
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const showModal = useAlert();

  const getCards = async () => {
    setLoading(true);
    try {
      const cards = await api.get(`/clients/${client.id}/sources`);
      setCards(cards);
    } catch (err) {
      console.error('error getCards', err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!didMount.current || newCard) {
      getCards();
      didMount.current = true;
    }
  }, [newCard]);

  const setDefaultCard = async (id) => {
    setLoading(true);
    try {
      await api.put(`/clients/${client.id}/sources/${id}`);
    } catch (err) {
      console.error('error setDefaultCard', err);
    }
    setLoading(false);
  };

  const deleteCard = async (id) => {
    setLoading(true);
    try {
      const rep = await api.del(`/clients/${client.id}/sources/${id}`);
      if (rep.ok) {
        setCards((prev) => prev.filter((card) => card.id !== id));
        setHasDeleteCard(true);
        //   await getCards();
      } else {
        setHasDeleteCard('error');
      }
    } catch (err) {
      console.error('error delete card', err);
    }
    setLoading(false);
  };

  const modalDeleteCard = (id) => {
    showModal({
      title: t('displaycards.modal.delete.title'),
      content: t('displaycards.modal.delete.text'),
      showModal: true,
      showButton: [
        {
          text: t(`button.cancel`),
          variant: 'neutral',
        },
        {
          text: t(`delete`),
          color: 'blue.electric',
          onClick: () => deleteCard(id),
        },
      ],
    });
  };

  return (
    <>
      {hasDeleteCard && hasDeleteCard !== 'error' && (
        <Alert status="success" mb={5}>
          {t('displaycards.alerts.1')}
        </Alert>
      )}
      {hasDeleteCard === 'error' && (
        <Alert status="danger" mb={5}>
          {t('displaycards.alerts.2')}
        </Alert>
      )}
      {!loading && !cards.length ? (
        <Box justifyContent="center" alignItems="center">
          <Text variant="large" color="black" mb={2}>
            {t('displaycards.texts.1')}
          </Text>
          <Text>{t('displaycards.texts.2')}</Text>
        </Box>
      ) : (
        <Cards
          cards={cards}
          client={client}
          card={null}
          setDefaultCard={setDefaultCard}
          deleteCard={modalDeleteCard}
          loading={loading}
        />
      )}
    </>
  );
};

export default DisplayCards;
