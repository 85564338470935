import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
// Components
import Hero from '../../components/business/shared/Hero';
import Link from '../../components/shared/atoms/Link';
import ConvinceMyManager from '../../components/business/business/ConvinceMyManager';
import SectionBeReminded from '../../components/business/business/SectionBeReminded';
import DiscoverOurSolutions from '../../components/business/business/DiscoverOurSolutions';
import WeTellYouEverything from '../../components/shared/molecules/WeTellYouEverything';
import CustomerReviews from '../../components/business/shared/CustomerReviews';
import MaintainPleasure from '../../components/shared/organisms/MaintainPleasure';
import ToolkitFix from '../../components/shared/ToolkitFix';
// Components shared
import Container from '../../components/shared/atoms/Container';

const StyledToolKitCircle = styled(ToolkitFix)`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: 90px;
  transform: rotate(13deg);
  height: 90px;
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    top: -41px;
    right: -33px;
    left: auto;
    bottom: auto;
  }
`;

const StyledSpan = styled.span`
  color: ${(p) => p.theme.colors.primary.main};
  font-family: LTSaeada;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  font-style: italic;
  ${(p) => p.theme.mediaQueries.desktop} {
    font-size: 28px;
    line-height: 36px;
  }
`;

const Business = () => {
  const { t } = useTranslation();
  return (
    <Container fluid>
      <Hero
        type="employeurs"
        bg="green.lighter"
        title={t('company.heading')}
        text={t('company.subheading')}
        img="/static/img/photos/business/hero-image_entreprise.png"
        toolkit={<StyledToolKitCircle name="circle" />}
      />
      {/* <StyledSectionImage
        title="Ils nous font confiance"
        values={[
          { src: '/static/img/logos/logo-backmarket.png', alt: 'BackMarket' },
          {
            src: '/static/img/logos/logo-le-figaro.png',
            alt: 'Le Figaro',
            style: { pl: { sm: '5%', lg: 0 } },
          },
          {
            src: '/static/img/logos/logo-les-echos.png',
            alt: 'Les Echos',
            style: { px: { sm: '5%', lg: 0 } },
          },
          { src: '/static/img/logos/logo-france-2.png', alt: 'France 2' },
        ]}
      /> */}
      {/* <ConvinceMyManager /> */}
      <DiscoverOurSolutions />
      <SectionBeReminded />
      <CustomerReviews
        bg="stone"
        img="/static/img/photos/business/laura-testimonial.png"
        review="“Nous avons pu tester les ateliers de révisions vélos avec Roulezjeunesse pour les collaborateurs de Natixis. L’Equipe est au top, à l’écoute et disponible, tant pour l’organisation que pour les entretiens et réparations. Expérience à réitérer pour nos vélotafeurs qui sont de plus en plus nombreux !”"
        name="Laura"
        job="Responsable RSE chez Natixis"
      />
      <WeTellYouEverything
        values={[
          {
            title: t('company.questions.1'),
            text: t('company.answers.1'),
          },
          {
            title: t('company.questions.2'),
            text: t('company.answers.2'),
          },
          {
            title: t('company.questions.3'),
            text: t('company.answers.3'),
          },
          {
            title: t('company.questions.4'),
            text: (
              <span>
                <Trans
                  i18nKey="company.answers.4"
                  components={{
                    reactLink: (
                      <Link
                        to="/reparateur-a-domicile/"
                        color="gray.darkest"
                        style={{ fontWeight: 600, textDecoration: 'underline' }}
                      />
                    ),
                  }}
                />
              </span>
            ),
          },
        ]}
      />
      <MaintainPleasure
        title={t('maintainpleasure.title')}
        text={t('maintainpleasure.text')}
        buttonText={t('contactus')}
        link="https://feedback.roulezjeunesse.com/contact-b2b?type=employeurs"
      />
    </Container>
  );
};

export default Business;
