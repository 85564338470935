import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 9.25a3 3 0 11-6 0 3 3 0 016 0zm-1.5 0a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.841 3.504A3 3 0 001 6.5V12a3 3 0 003 3h8a3 3 0 003-3V6.5a3 3 0 00-2.841-2.996l-.836-1.485A2 2 0 009.58 1H6.42a2 2 0 00-1.743 1.02L3.84 3.503zM9.581 2.5H6.42a.5.5 0 00-.436.255l-.42.745h4.871l-.419-.745A.5.5 0 009.58 2.5zM2.5 6.5A1.5 1.5 0 014 5h8a1.5 1.5 0 011.5 1.5V12a1.5 1.5 0 01-1.5 1.5H4A1.5 1.5 0 012.5 12V6.5z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgCamera16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgCamera16;
