import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import Box from '../../shared/atoms/Box';
import Heading from '../../shared/atoms/Heading';
import Link from '../../shared/atoms/Link';
import SectionBackground from '../../shared/molecules/SectionBackground';
import Container from '../../shared/atoms/Container';
// Icons
import ArrowRight16 from '../../icons/ArrowRight16';

const StyledLink = styled(Link)`
  @media (max-width: ${(p) => p.theme.breakpoints.xl}) {
    width: 50%;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

const DiscoverOurSolutions = () => {
  const { t } = useTranslation();
  return (
    <SectionBackground alignItems="center" mb={{ xs: 7, md: 10 }}>
      <Container>
        <Heading level={2}>{t('discoveroursolutions.company.heading')}</Heading>
        <Box
          bg="gray.lightest"
          borderRadius="xs"
          px={{ xs: 5, md: 9 }}
          py={4}
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-around">
          <StyledLink
            fontWeight="bold"
            color="black"
            href="#credits-reparation"
            after={<ArrowRight16 />}
            mb={{ xs: 5, sm: 0 }}>
            {t('discoveroursolutions.company.links.1')}
          </StyledLink>
          <StyledLink
            fontWeight="bold"
            color="black"
            href="#ateliers-reparation"
            after={<ArrowRight16 />}
            mb={{ xs: 5, sm: 0 }}>
            {t('discoveroursolutions.company.links.2')}
          </StyledLink>
          <StyledLink
            fontWeight="bold"
            color="black"
            href="#programme-partenaire"
            after={<ArrowRight16 />}
            mb={0}>
            {t('discoveroursolutions.company.links.3')}
          </StyledLink>
        </Box>
      </Container>
    </SectionBackground>
  );
};

export default DiscoverOurSolutions;
