import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M8 19h16v7a3 3 0 01-3 3H11a3 3 0 01-3-3v-7z" fill="#FFF9B3" />
    <path
      d="M6.5 13.1a.9.9 0 100 1.8h4a.9.9 0 100-1.8h-4zM12.1 22.5a.9.9 0 01.9-.9h6a.9.9 0 110 1.8h-6a.9.9 0 01-.9-.9zM13 24.6a.9.9 0 100 1.8h6a.9.9 0 100-1.8h-6z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 9.1V5a3 3 0 013-3h12a3 3 0 013 3v4.1a4.9 4.9 0 014.9 4.9v5a4.9 4.9 0 01-4.9 4.9V27a3 3 0 01-3 3H10a3 3 0 01-3-3v-3.1A4.9 4.9 0 012.1 19v-5A4.9 4.9 0 017 9.1zm3-5.3h12A1.2 1.2 0 0123.2 5v4.1H8.8V5A1.2 1.2 0 0110 3.8zm15 7.1H7A3.1 3.1 0 003.9 14v5A3.1 3.1 0 007 22.1v-2.3h-.5a.9.9 0 110-1.8h19a.9.9 0 010 1.8H25v2.3a3.1 3.1 0 003.1-3.1v-5a3.1 3.1 0 00-3.1-3.1zM8.8 27v-7.2h14.4V27a1.2 1.2 0 01-1.2 1.2H10A1.2 1.2 0 018.8 27z"
      fill="#000"
    />
    <path
      d="M27.652 7a.9.9 0 10-1.8 0v2.1H24.75a.9.9 0 100 1.8h1.102V13a.9.9 0 101.8 0v-2.1h1.102a.9.9 0 000-1.8h-1.102V7z"
      fill="#d5ff4f"
    />
    <path
      d="M4.502 1.75a.75.75 0 10-1.5 0V4H1.75a.75.75 0 000 1.5h1.252v2.25a.75.75 0 001.5 0V5.5h1.252a.75.75 0 000-1.5H4.502V1.75z"
      fill="#1F77F9"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = true;
const SvgPrint32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPrint32;
