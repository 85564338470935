import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
// Components
import Heading from '../shared/atoms/Heading';

const TitleSelector = ({ partenaire, ...props }) => {
  const { t } = useTranslation();
  switch (partenaire) {
    case 'darty':
    case 'fnac':
      return (
        <h1>
          <Trans i18nKey="titleselector.titles.1" />
        </h1>
      );
    case 'ratp':
      return <h1>{t('titleselector.titles.2')}</h1>;
    case 'inscription':
      return (
        <Heading level={2} as="h1">
          {t('titleselector.titles.3')}
        </Heading>
      );
    case 'clientscdiscount':
      return (
        <Heading level={1} as="h1">
          Faites réparer votre vélo chez vous, en moins de 48h.
        </Heading>
      );
    default:
      return (
        <h1>
          <Trans i18nKey="titleselector.titles.1" />
        </h1>
      );
  }
};

export default TitleSelector;
