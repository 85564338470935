import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M19.543 3.44a.9.9 0 10-1.272-1.273l-1.53 1.53a.9.9 0 001.274 1.272l1.528-1.529z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.614 14.872l-5.022.297 1.71 2.976a2.542 2.542 0 01-4.403 2.544l-2.951-5.075.027-.016A3 3 0 012.75 13.45l-.535-1.995a3 3 0 012.122-3.674l.756-.202-.001-.003 7.553-3.784a2 2 0 012.827 1.27l1.955 7.295a2 2 0 01-1.813 2.515zm-7.075-1.384L7.21 8.528l6.24-3.127a.2.2 0 01.284.128l1.954 7.294a.2.2 0 01-.181.252l-6.968.413zm-1.358 1.884l1.403-.083 2.156 3.753a.742.742 0 01-1.285.742l-2.528-4.347.253-.068v.003zM4.803 9.52l.578-.155 1.156 4.313-.579.155a1.2 1.2 0 01-1.47-.849l-.534-1.995a1.2 1.2 0 01.849-1.47z"
      fill="#000"
    />
    <path
      d="M21.95 7.2a.9.9 0 01-.63 1.106l-2.024.554a.9.9 0 01-.475-1.736l2.023-.555a.9.9 0 011.106.63zM18.909 12.65a.9.9 0 011.032-1.475l1.771 1.24a.9.9 0 11-1.032 1.475l-1.771-1.24z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgMegaphone24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgMegaphone24;
