import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';
import Modal from '../../../../modals/Modal';
import Box from '../../../shared/atoms/Box';
import Text from '../../../shared/atoms/Text';
import Button from '../../../shared/atoms/Button';
import Radio from '../../../shared/atoms/Radio';
import ArrowEnterRight16 from '../../../icons/ArrowEnterRight16';
import CancelColor56 from '../../../icons/CancelColor56';

const StyledBox = styled(Box)`
  cursor: pointer;
  :hover {
    box-shadow: inset 0px 0px 0px 1px ${(p) => p.theme.colors.blue.electric};
`;
const CancelModal = ({
  cancelCourse,
  course,
  showModal,
  openModal,
  ...props
}) => {
  const { t } = useTranslation();
  const [cancelCategories, setCancelCategories] = useState({
    schedule: { isChecked: false, id: '20' },
    time_limit: { isChecked: false, id: '21' },
    too_expensive: { isChecked: false, id: '22' },
    solved: { isChecked: false, id: '23' },
    other: { isChecked: false, id: '14' },
  });
  const isLateCancel =
    !!course.fixer && !moment(course.date).isAfter(moment().add(1, 'h'));
  const hasBeenSelected = Object.values(cancelCategories).find(
    (c) => c.isChecked === true,
  );
  return (
    <Modal
      zIndex="1002"
      hasCloseAction={true}
      open={showModal === 'CANCEL_COURSE'}
      onRequestClose={() => {
        openModal(false);
        setCancelCategories({
          schedule: { isChecked: false, id: '20' },
          time_limit: { isChecked: false, id: '21' },
          too_expensive: { isChecked: false, id: '22' },
          solved: { isChecked: false, id: '23' },
          other: { isChecked: false, id: '14' },
        });
      }}>
      <CancelColor56 mb={2} />
      <Text fontWeight={2} mb={4}>
        {t('cancelmodal.heading')}
      </Text>
      {isLateCancel ? (
        <Text mb={4}>
          {t('cancelmodal.alert', { label: course?.fixer?.tel })}
        </Text>
      ) : (
        <>
          <Text mb={4}>{t('cancelmodal.title')}</Text>
          <StyledBox
            borderColor={
              cancelCategories.schedule.isChecked
                ? 'blue.electric'
                : 'gray.light'
            }
            borderWidth={cancelCategories.schedule.isChecked ? '2px' : '1px'}
            borderRadius="xs"
            borderStyle="solid"
            mb={4}
            p={4}
            flexDirection="row"
            justifyContent="space-between"
            onClick={() =>
              setCancelCategories({
                schedule: {
                  ...cancelCategories.schedule,
                  isChecked: true,
                },
                time_limit: {
                  ...cancelCategories.time_limit,
                  isChecked: false,
                },
                too_expensive: {
                  ...cancelCategories.too_expensive,
                  isChecked: false,
                },
                solved: {
                  ...cancelCategories.solved,
                  isChecked: false,
                },
                other: {
                  ...cancelCategories.other,
                  isChecked: false,
                },
              })
            }>
            <Box>
              <Text fontWeight={2}>{t('schedule')}</Text>
              <Text>
                <ArrowEnterRight16 />
                {t('cancelmodal.reasons.1.texts.1')}
              </Text>
              <Text>
                <ArrowEnterRight16 /> {t('cancelmodal.reasons.1.texts.2')}
              </Text>
            </Box>
            <Box>
              <Radio checked={cancelCategories.schedule.isChecked} />
            </Box>
          </StyledBox>
          <StyledBox
            borderColor={
              cancelCategories.time_limit.isChecked
                ? 'blue.electric'
                : 'gray.light'
            }
            borderWidth={cancelCategories.time_limit.isChecked ? '2px' : '1px'}
            borderRadius="xs"
            borderStyle="solid"
            mb={4}
            p={4}
            flexDirection="row"
            justifyContent="space-between"
            onClick={() =>
              setCancelCategories({
                schedule: {
                  ...cancelCategories.schedule,
                  isChecked: false,
                },
                time_limit: {
                  ...cancelCategories.time_limit,
                  isChecked: true,
                },
                too_expensive: {
                  ...cancelCategories.too_expensive,
                  isChecked: false,
                },
                solved: {
                  ...cancelCategories.solved,
                  isChecked: false,
                },
                other: {
                  ...cancelCategories.other,
                  isChecked: false,
                },
              })
            }>
            <Box>
              <Text fontWeight={2}>{t('cancelmodal.reasons.2')}</Text>
              <Text>
                <ArrowEnterRight16 />
                {t('cancelmodal.reasons.2.texts.1')}
              </Text>
              <Text>
                <ArrowEnterRight16 />
                {t('cancelmodal.reasons.2.texts.2')}
              </Text>
              <Text>
                <ArrowEnterRight16 />
                {t('cancelmodal.reasons.2.texts.3')}
              </Text>
            </Box>
            <Box>
              <Radio checked={cancelCategories.time_limit.isChecked} />
            </Box>
          </StyledBox>
          <StyledBox
            borderColor={
              cancelCategories.too_expensive.isChecked
                ? 'blue.electric'
                : 'gray.light'
            }
            borderWidth={
              cancelCategories.too_expensive.isChecked ? '2px' : '1px'
            }
            borderRadius="xs"
            borderStyle="solid"
            mb={4}
            p={4}
            flexDirection="row"
            justifyContent="space-between"
            onClick={() =>
              setCancelCategories({
                schedule: {
                  ...cancelCategories.schedule,
                  isChecked: false,
                },
                time_limit: {
                  ...cancelCategories.time_limit,
                  isChecked: false,
                },
                too_expensive: {
                  ...cancelCategories.too_expensive,
                  isChecked: true,
                },
                solved: {
                  ...cancelCategories.solved,
                  isChecked: false,
                },
                other: {
                  ...cancelCategories.other,
                  isChecked: false,
                },
              })
            }>
            <Box>
              <Text fontWeight={2}>{t('cancelmodal.reasons.3')}</Text>
              <Text>
                <ArrowEnterRight16 />
                {t('cancelmodal.reasons.3.texts.1')}
              </Text>
              <Text>
                <ArrowEnterRight16 />
                {t('cancelmodal.reasons.3.texts.2')}
              </Text>
            </Box>
            <Box>
              <Radio checked={cancelCategories.too_expensive.isChecked} />
            </Box>
          </StyledBox>
          <StyledBox
            borderColor={
              cancelCategories.solved.isChecked
                ? 'blue.electric'
                : 'gray.light'
            }
            borderWidth={cancelCategories.solved.isChecked ? '2px' : '1px'}
            borderRadius="xs"
            borderStyle="solid"
            mb={4}
            p={4}
            flexDirection="row"
            justifyContent="space-between"
            onClick={() =>
              setCancelCategories({
                schedule: {
                  ...cancelCategories.schedule,
                  isChecked: false,
                },
                time_limit: {
                  ...cancelCategories.time_limit,
                  isChecked: false,
                },
                too_expensive: {
                  ...cancelCategories.too_expensive,
                  isChecked: false,
                },
                solved: {
                  ...cancelCategories.solved,
                  isChecked: true,
                },
                other: {
                  ...cancelCategories.other,
                  isChecked: false,
                },
              })
            }>
            <Box>
              <Text fontWeight={2}>{t('cancelmodal.reasons.4')}</Text>
              <Text>
                <ArrowEnterRight16 />
                {t('cancelmodal.reasons.4.texts.1')}
              </Text>
              <Text>
                <ArrowEnterRight16 />
                {t('cancelmodal.reasons.4.texts.2')}
              </Text>
            </Box>
            <Box>
              <Radio checked={cancelCategories.solved.isChecked} />
            </Box>
          </StyledBox>
          <StyledBox
            borderColor={
              cancelCategories.other.isChecked ? 'blue.electric' : 'gray.light'
            }
            borderWidth={cancelCategories.other.isChecked ? '2px' : '1px'}
            borderRadius="xs"
            borderStyle="solid"
            p={4}
            flexDirection="row"
            justifyContent="space-between"
            onClick={() =>
              setCancelCategories({
                schedule: {
                  ...cancelCategories.schedule,
                  isChecked: false,
                },
                time_limit: {
                  ...cancelCategories.time_limit,
                  isChecked: false,
                },
                too_expensive: {
                  ...cancelCategories.too_expensive,
                  isChecked: false,
                },
                solved: {
                  ...cancelCategories.solved,
                  isChecked: false,
                },
                other: {
                  ...cancelCategories.other,
                  isChecked: true,
                },
              })
            }>
            <Box>
              <Text fontWeight={2}>{t('cancelmodal.reasons.5')}</Text>
            </Box>
            <Box>
              <Radio checked={cancelCategories.other.isChecked} />
            </Box>
          </StyledBox>
        </>
      )}

      <Box mt={5} flexDirection="row" justifyContent="flex-end">
        <Button
          size="compact"
          variant="neutral"
          mr={2}
          onClick={() => {
            openModal(false);
            setCancelCategories({
              schedule: { isChecked: false, id: '20' },
              time_limit: { isChecked: false, id: '21' },
              too_expensive: { isChecked: false, id: '22' },
              solved: { isChecked: false, id: '23' },
              other: { isChecked: false, id: '14' },
            });
          }}>
          {t('close.cta')}
        </Button>
        {isLateCancel ? (
          <Box></Box>
        ) : (
          <Button
            size="compact"
            color="blue.electric"
            disabled={!hasBeenSelected}
            onClick={() => {
              cancelCourse(cancelCategories);
              openModal(false);
              setCancelCategories({
                schedule: { isChecked: false, id: '20' },
                time_limit: { isChecked: false, id: '21' },
                too_expensive: { isChecked: false, id: '22' },
                solved: { isChecked: false, id: '23' },
                other: { isChecked: false, id: '14' },
              });
            }}>
            {t('cancelrequest.cta')}
          </Button>
        )}
      </Box>
    </Modal>
  );
};

export default CancelModal;
