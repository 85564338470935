import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  width: 16px;
  height: 16px;
  border: 2px solid ${(p) => p.theme.colors.gray.main};
  background-color: white;
  appearance: none;
  vertical-align: middle;
  transition: box-shadow 200ms;
  border-radius: 4px;
  :focus {
    border: 2px solid ${(p) => p.theme.colors.gray.main};
    appearance: none;
    vertical-align: middle;
    transition: box-shadow 200ms;
    border-radius: 4px;
  }
  :checked {
    background-color: ${(p) => p.theme.colors.blue.electric};
    background-image: url('/static/img/svg/Check 16 Color.svg');
    background-repeat: no-repeat;
    background-position: center;
    border: 2px solid ${(p) => p.theme.colors.blue.electric};
  }
`;

const Checkbox = ({ ...props }) => {
  return <StyledInput type="checkbox" {...props} />;
};

export default Checkbox;
