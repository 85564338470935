import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Link from '../shared/atoms/Link';
import Box from '../shared/atoms/Box';
import Text from '../shared/atoms/Text';
import Image from '../shared/atoms/Image';
import PinFill from '../icons/PinFill16';
import ArrowRight from '../icons/ArrowRight24';

const StyledBox = styled(Box)`
  border: 1px solid ${(p) => p.theme.colors.gray.light};
  transition: transform 150ms ease-in;
  width: 100%;
  :hover {
    cursor: pointer;
    transform: translate(0, -3px);
    }
  }
`;
const CityCard = ({ city, url, label, nombreAtelier }) => {
  const { t } = useTranslation();
  return (
    <Link to={`/annuaire/atelier-velo-trottinette/${city}`}>
      <StyledBox borderRadius="sm" p={4}>
        <Image src={url} />
        <Text mt={2} fontWeight={2} color="black" variant="large">
          {label}
        </Text>
        <Box flexDirection="row" justifyContent="space-between">
          <Box flexDirection="row" alignItems="center">
            <PinFill mr={1} />
            <Text color="gray.darkest">
              {t('citycard.text', {
                count: nombreAtelier,
              })}
            </Text>
          </Box>
          <ArrowRight style={{ cursor: 'pointer' }} />
        </Box>
      </StyledBox>
    </Link>
  );
};
export default CityCard;
