import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import Box from '../shared/atoms/Box';
import Separator from '../Separator';
import Text from '../shared/atoms/Text';

const StyledImg = styled.img`
  max-width: 100px;
`;

const StyledSeparator = styled(Separator)`
  height: 100%;
`;

const Review = ({ text, img, name, job }) => (
  <Box>
    <StyledImg src={img} alt={name} />
    <Text variant="notice2" mt={4} mb={4}>
      {text}
    </Text>
    <Text variant="large" fontWeight="bold">
      {name}
    </Text>
    <Text variant="small">{job}</Text>
  </Box>
);

const TheyBecameARepairer = () => {
  const { t } = useTranslation();
  return (
    <Box mb={11}>
      <Box flexDirection={{ xs: 'column', md: 'row' }}>
        <Review
          img="/static/img/photos/recruitmentFixer/jerome.png"
          name="Jérôme"
          job={t('theybecamearepair.texts.1')}
          text={t('theybecamearepair.texts.2')}
        />
        <Box>
          <StyledSeparator mx={7} bg="gray.light" />
        </Box>
        <Review
          img="/static/img/photos/recruitmentFixer/thibaut.png"
          name="Thibaut"
          job={t('theybecamearepair.texts.3')}
          text={t('theybecamearepair.texts.4')}
        />
      </Box>
    </Box>
  );
};

export default TheyBecameARepairer;
