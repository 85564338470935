import React from 'react';
// Components
import Skeleton from 'react-loading-skeleton';
import Box from '../shared/atoms/Box';

const PayByLinkSkeleton = () => (
  <Box flexDirection={{ xs: 'column-reverse', lg: 'row' }}>
    <Box mb={10} width={{ xs: '100%', lg: 3 / 5 }} pr={{ xs: 0, lg: 5 }}>
      <Skeleton style={{ borderRadius: 16 }} height={450} />
    </Box>
    <Box width={{ xs: '100%', lg: 2 / 5 }} pl={{ xs: 0, lg: 5 }}>
      <Skeleton style={{ borderRadius: 16 }} height={450} />
    </Box>
  </Box>
);

export default PayByLinkSkeleton;
