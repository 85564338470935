import React from 'react';

import Skeleton from 'react-loading-skeleton';

import Heading from '../shared/atoms/Heading';
import Box from '../shared/atoms/Box';

const DevisContentSkeleton = () => {
  return (
    <Box>
      <Box>
        <Heading level={2}>
          <Skeleton style={{ borderRadius: 16 }} />
        </Heading>
        <Heading level={5}>
          <Skeleton style={{ borderRadius: 16 }} />
        </Heading>
      </Box>
      <ul>
        <li>
          <Skeleton style={{ borderRadius: 16 }} />
        </li>
        <li>
          <Skeleton style={{ borderRadius: 16 }} />
        </li>
        <li>
          <Skeleton style={{ borderRadius: 16 }} />
        </li>
        <li>
          <Skeleton style={{ borderRadius: 16 }} />
        </li>
        <li>
          <Skeleton style={{ borderRadius: 16 }} />
        </li>
      </ul>
      <Box>
        <Skeleton style={{ borderRadius: 16 }} height={150} />
      </Box>
      <Box mt={5}>
        <Skeleton style={{ borderRadius: 16 }} height={150} />
      </Box>
      <Box mt={5}>
        <span style={{ textAlign: 'end' }}>
          <Skeleton style={{ borderRadius: 50 }} width={250} height={50} />
        </span>
      </Box>
    </Box>
  );
};

export default DevisContentSkeleton;
