import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M14.68 4.767a1 1 0 00-1.36 1.466l3.212 2.982c.67.623 1.127 1.049 1.452 1.405.136.15.236.274.311.38H4a1 1 0 000 2h14.294c-.075.107-.175.231-.31.38-.325.356-.782.782-1.452 1.405l-3.213 2.982a1 1 0 101.361 1.466l3.244-3.012c.631-.586 1.15-1.069 1.539-1.495.401-.44.728-.891.914-1.434a4 4 0 000-2.584c-.186-.543-.513-.993-.914-1.434-.388-.427-.908-.909-1.539-1.495l-3.243-3.012z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgArrowRight24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgArrowRight24;
