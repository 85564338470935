import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { space, variant, typography } from 'styled-system';
import Text from './Text';

const textSize = variant({
  prop: 'variant',
  key: 'texts',
});

const _getLinkColor = (color, theme) =>
  color.includes('.')
    ? theme.colors[color.split('.')[0]][color.split('.')[1]]
    : theme.colors[color];

const StyledLink = styled(Text)`
  ${(p) =>
    css`
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      font-family: 'LTSaeada';
      letter-spacing: -0.01em;
      font-weight: ${p.fontWeight === 'bold' ? 600 : 500};
      color: ${_getLinkColor(p.color, p.theme) || p.color} !important;
      &:hover {
        & > .icon-link {
          ${p.animation &&
      css`
              margin-right: -8px;
              transition: transform 0.3s ease-in-out;
            `
      }
        }
        font-weight: ${p.fontWeight === 'bold' ? 600 : 500};
        font-family: 'LTSaeada';
        color: ${p.color === 'black' && p.$hover
        ? p.theme.colors.blue.electric
        : p.color === 'blue.electric' && p.$hover
          ? p.theme.colors.turquoise.dark
          : _getLinkColor(p.color, p.theme) || p.color
      } !important;
        & > .icon-link > path {
          ${(p.color === 'black' || p.$hoverIcon) &&
      css`
              margin-bottom: 0px;
              fill: ${p.color === 'black'
          ? p.theme.colors.gray.darker
          : p.theme.colors.primary.main} !important;
            `
      }
        }
      }
      ${space}
      ${textSize}
      ${typography}
    `}
`;

export default function Link({
  color = 'primary.main',
  onClick,
  href,
  before,
  hover = true,
  hoverIcon = false,
  after,
  children,
  ...props
}) {
  const filteredProps = { ...props };
  if (typeof filteredProps.to !== 'undefined' && !filteredProps.to) {
    delete filteredProps.to;
  }
  return (
    <StyledLink
      as={
        (!filteredProps.to && !href) || onClick ? 'span' : href ? 'a' : RRLink
      }
      href={href}
      onClick={onClick}
      color={color}
      $hover={hover}
      $hoverIcon={hoverIcon}
      {...filteredProps}>
      {before &&
        React.cloneElement(before, {
          color,
          $pointer: true,
          mr: 1,
          className: 'icon-link',
        })}
      {children}
      {after &&
        React.cloneElement(after, {
          color,
          $pointer: true,
          ml: 1,
          className: 'icon-link',
        })}
    </StyledLink>
  );
}
