import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { position, space, layout, flexbox, color } from 'styled-system';
import styled, { css } from 'styled-components';
import Box from './Box';
import Text from './Text';
import EyeCrossed24 from '../../icons/EyeCrossed24';

const Adornment = styled('div')`
  z-index: 30;
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${position}
`;

const BaseInput = styled('input')`
  &,
  input,
  textarea {
    box-sizing: border-box;
    color: ${(p) => p.theme.colors.black};
    height: 100%;
    width: 100%;
    background-color: transparent !important;
    border: none;
    outline: none !important;
    font-size: ${(p) => p.theme.fontSizes.md} !important;
    font-weight: ${(p) => p.theme.fontWeights[0]};
    resize: none;
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    :focus {
      padding-left: ${(p) => (!p.before ? `${p.theme.space[4]}px` : 0)};
      color: ${(p) => p.theme.colors.black};
    }
    ${(p) =>
      p.error &&
      css`
        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus,
        :-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px ${p.theme.colors.primary.pale} inset !important;
        }
        color: ${p.theme.colors.gray.darker};
        :focus {
          color: ${p.theme.colors.gray.darker};
        }
        ::placeholder {
          color: ${p.theme.colors.gray.darker};
        }
      `}
    ${(p) =>
      p.disabled &&
      css`
        :active {
          padding-left: 16px;
          color: ${p.theme.colors.gray.dark};
        }
        ::placeholder {
          color: ${p.theme.colors.gray.main};
        }
        color: ${p.theme.colors.gray.dark};
      `}
  }
  ${space};
  ${layout};
  ${color};
  ${flexbox};
  ${position};
`;

const StyledGradient = styled.div`
  display: block;
  position: absolute;
  right: 0em;
  width: 20%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  ${(p) => p.theme.mediaQueries.smartphone} {
    display: none;
  }
`;

const StyledInputContainer = styled(Box)`
  ${(p) =>
    p.type === 'password' &&
    css`
      height: 52px !important;
    `}
  background-color: ${(p) => (p.bg ? p.bg : p.theme.colors.white)};
  box-shadow: 0 0 0 2px transparent;
  transition: box-shadow 0.2s;
  position: relative;
  ${(p) =>
    !p.naked &&
    css`
      border-radius: ${p.theme.radii.xs};
      border: 1px solid
        ${p.borderColor ? p.borderColor : p.theme.colors.gray.main};
    `}
  ${(p) =>
    p.$grouped === 'start' &&
    css`
      border-top-right-radius: ${p.theme.radii.xs};
      border-bottom-right-radius: ${p.theme.radii.xs};
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border: 1px solid
        ${p.borderColor ? p.borderColor : p.theme.colors.gray.main};
    `}
  ${(p) =>
    p.$grouped === 'end' &&
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: ${p.theme.radii.xs};
      border-bottom-left-radius: ${p.theme.radii.xs};
      border: 1px solid
        ${p.borderColor ? p.borderColor : p.theme.colors.gray.main};
    `}
  ${(p) =>
    p.error &&
    !p.isAddressInput &&
    css`
      background-color: ${p.theme.colors.primary.pale};
      box-shadow: 0 0 0 1px ${p.theme.colors.primary.darkest};
    `}
    ${(p) =>
      p.valided &&
      css`
        background-color: ${p.theme.colors.green.pale};
        box-shadow: 0 0 0 1px ${p.theme.colors.green.dark};
        border: none;
      `}
  ${(p) =>
    p.isFocused &&
    css`
      box-shadow: 0 0 0 2px ${p.theme.colors.gray.lighter};
    `}
    ${(p) =>
      p.disabled &&
      css`
        background-color: ${p.theme.colors.gray.lightest};
      `}
`;

const SuggestionBox = styled(Box)`
  position: absolute;
  cursor: pointer;
  overflow-y: scroll;
  z-index: 1002;
  max-height: 150px;
  width: 100%;
  top: 60px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05),
    0px 10px 50px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
`;
const Suggestion = styled(Box)`
  :hover {
    background-color: ${(p) => p.theme.colors.gray.lighter};
  }
`;

/**
 * @param {string} radius same as borderRadius xs | sm | md | lg
 * @param {string|boolean} grouped "start" or "end"
 * @param {string} name name fo label element
 * @param {string} label
 * @param {string} before Icon cmp
 * @param {string} after Icon cmp
 * @param {function} onRightIconClick
 * @param {string} any Styled System space properties
 */
export default function Input({
  grouped = false,
  type = 'text',
  placeholder,
  value,
  label,
  name,
  error = false,
  valided = false,
  onChange = () => {},
  onKeyDown,
  before,
  after,
  children,
  isBig = false,
  as = 'input',
  naked = false,
  autocomplete,
  rows = null,
  withGradient,
  withPadding,
  importantLabel = false,
  bg,
  disabled = false,
  colorLabel = null,
  suggestions = [],
  isAddressInput = false,
  borderColor = false,
  maxLength = 'false',
  required = false,
  ...props
}) {
  const [isFocused, setIsFocused] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const textAreaRows = as === 'textarea' && !!rows ? rows : undefined;
  const changeViewPassword = () => {
    if (type === 'password') setViewPassword(!viewPassword);
  };
  return (
    <Box {...props}>
      {label && (
        <Text
          pb={1}
          pr={0}
          as="label"
          htmlFor={name}
          color={colorLabel || 'black'}
          variant="small"
          fontWeight="500">
          {label}
          {importantLabel && (
            <Text as="span" color="primary.main">
              *
            </Text>
          )}
        </Text>
      )}
      <StyledInputContainer
        className="inputContainer"
        bg={bg}
        pl={before ? '38px' : 0}
        flexDirection="row"
        error={!!error}
        valided={valided}
        type={viewPassword ? 'text' : type}
        naked={naked}
        $grouped={grouped}
        isFocused={isFocused}
        alignItems={'center'}
        disabled={disabled}
        borderColor={borderColor}
        isAddressInput={isAddressInput}>
        {before && (
          <Adornment left={withPadding ? 0 : 3}>
            {React.cloneElement(before)}
          </Adornment>
        )}
        {!children ? (
          <BaseInput
            py={naked ? 0 : isBig ? '1.3em' : 3}
            px={naked ? 0 : isBig ? 3 : null}
            ml={before ? '8px' : 0}
            mr={after ? 6 : 0}
            pl={before ? 0 : 4}
            id={name}
            type={viewPassword ? 'text' : type}
            error={!!error}
            before={!!before}
            isFocused={isFocused}
            as={as}
            placeholder={placeholder}
            onChange={onChange}
            required={required}
            onKeyDown={onKeyDown}
            value={value}
            onFocus={() => setIsFocused(true)}
            onBlur={() =>
              suggestions
                ? setTimeout(() => setIsFocused(false), 200)
                : setIsFocused(false)
            }
            autocomplete={autocomplete}
            disabled={disabled}
            rows={textAreaRows}
            maxLength={maxLength}
          />
        ) : (
          <BaseInput
            as="span"
            py={naked ? 0 : isBig ? '1.3em' : 3}
            px={naked ? 0 : isBig ? 3 : null}
            ml={before ? '8px' : 0}
            mr={after ? 6 : 0}>
            {children}
          </BaseInput>
        )}
        {after && (
          <Adornment right={withPadding ? 0 : 3} onClick={changeViewPassword}>
            {React.cloneElement(viewPassword ? <EyeCrossed24 /> : after, {
              size: '1.2em',
            })}
          </Adornment>
        )}
        {withGradient && <StyledGradient />}
        {isFocused && !!suggestions.length && (
          <SuggestionBox bg="gray.darker">
            {suggestions.map((category, index) => {
              const key = Object.keys(category);
              return (
                <Box
                  display={suggestions[index][key[0]].length ? 'flex' : 'none'}
                  key={index}
                  pb={4}
                  pt={index === 0 ? 4 : 0}>
                  <Text color="gray.darker" pl={4} mb={2}>
                    {key[0]}
                  </Text>
                  {suggestions[index][key[0]].map((suggestion, i) => {
                    return (
                      <Suggestion key={suggestion + i}>
                        <Text
                          pl={6}
                          color="black"
                          fontWeight={1}
                          onClick={() => onChange(suggestion)}>
                          {suggestion}
                        </Text>
                      </Suggestion>
                    );
                  })}
                </Box>
              );
            })}
          </SuggestionBox>
        )}
      </StyledInputContainer>
      {error && (
        <Text
          pt={1}
          variant="caption"
          fontWeight="bold"
          color="primary.darkest">
          {error}
        </Text>
      )}
    </Box>
  );
}

Input.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  leftIcon: PropTypes.object,
  rightIcon: PropTypes.object,
  withGradient: PropTypes.bool,
};
