import React, { cloneElement } from 'react';
import styled from 'styled-components';
import { variant as systemVariant } from 'styled-system';
import Button from './Button';

const buttonSize = systemVariant({
  prop: 'size',
  key: 'buttonSizes',
});

const StyledButton = styled(Button)`
  border-radius: 22px;
  padding: 12px;
  min-width: initial;
  ${buttonSize}
`;

const IconButton = ({ icon, noFocusStyle = false, ...props }) => {
  return (
    <StyledButton {...props} iconButton noFocusStyle={noFocusStyle}>
      {cloneElement(icon, {
        $pointer: true,
      })}
    </StyledButton>
  );
};

export default IconButton;
