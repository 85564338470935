import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M16.5 4a8 8 0 00-7.196 4.5H14.5a1 1 0 110 2H8.64a8.043 8.043 0 000 3h5.86a1 1 0 110 2H9.304A8 8 0 0016.5 20c.937 0 1.834-.16 2.667-.455a1 1 0 11.666 1.886A10.004 10.004 0 017.13 15.5H4.5a1 1 0 110-2.001h2.112a10.08 10.08 0 010-3H4.5a1 1 0 110-2h2.63a10.004 10.004 0 0112.703-5.93 1 1 0 11-.666 1.885A7.986 7.986 0 0016.5 4z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgPrice24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPrice24;
