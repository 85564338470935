import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Box from '../../shared/atoms/Box';
import Text from '../../shared/atoms/Text';
import Heading from '../../shared/atoms/Heading';
import ToolkitFix from '../../shared/ToolkitFix';
import TextGradiant from '../../shared/atoms/TextGradiant';
// Icons
import LoveColor56 from '../../icons/LoveColor56';
import Chrono56 from '../../icons/Chrono56';
import Price56 from '../../icons/Price56';

const ToolKitFixOcto = styled(ToolkitFix)`
  position: absolute;
  transform: rotate(18deg);
  right: 50px;
  bottom: -70px;
  @media (max-width: ${(p) => p.theme.breakpoints.xl}) {
    display: none;
  }
`;
const ToolKitFixCircle = styled(ToolkitFix)`
  position: absolute;
  transform: rotate(-30deg);
  right: 180px;
  bottom: -130px;
  @media (max-width: ${(p) => p.theme.breakpoints.xl}) {
    display: none;
  }
`;

const ASmartGift = () => {
  const { t } = useTranslation();
  return (
    <Box
      flexDirection={{ xs: 'column', xl: 'row' }}
      mb={{ xs: 7, md: 110, lg: 130 }}>
      <Box flex={5} mr={{ xs: 0, lg: 8 }}>
        <Heading level={2} textAlign={{ xs: 'center', xl: 'start' }}>
          <Trans
            i18nKey="smartgift.headings.1"
            components={{
              gradiant: <TextGradiant />,
            }}
          />
        </Heading>
        <Text
          fontSize="xl"
          color="black"
          lineHeight="copy"
          fontWeight={1}
          textAlign={{ xs: 'center', xl: 'start' }}>
          {t('smartgift.text')}
        </Text>
        <Box mt={5} maxWidth={450} alignSelf={{ xs: 'center', xl: 'start' }}>
          <Box flexDirection="row" alignItems="center" mb={5}>
            <Box
              width={72}
              height={72}
              borderRadius="sm"
              bg="gray.lightest"
              alignItems="center"
              justifyContent="center"
              p={2}
              mr={4}>
              <Chrono56 />
            </Box>
            <Heading level={5}>{t('smartgift.headings.2')}</Heading>
          </Box>
          <Box flexDirection="row" alignItems="center" mb={5}>
            <Box
              width={72}
              height={72}
              borderRadius="sm"
              bg="gray.lightest"
              alignItems="center"
              justifyContent="center"
              p={2}
              mr={4}>
              <Price56 />
            </Box>
            <Heading level={5}>{t('smartgift.headings.3')}</Heading>
          </Box>
          <Box flexDirection="row" alignItems="center">
            <Box
              width={72}
              height={72}
              borderRadius="sm"
              bg="gray.lightest"
              alignItems="center"
              justifyContent="center"
              p={2}
              mr={4}>
              <LoveColor56 />
            </Box>
            <Heading level={5}>{t('smartgift.headings.4')}</Heading>
          </Box>
        </Box>
      </Box>
      <Box
        flex={4}
        position="relative"
        alignItems="center"
        mt={{ xs: 7, xl: 0 }}>
        <img
          src="/static/img/photos/giftcard/RepairAtHome.png"
          style={{ objectFit: 'contain' }}
        />
        <ToolKitFixOcto name="octo" size="187px" color="green.lighter" />
        <ToolKitFixCircle name="circle" size="172px" color="yellow.main" />
      </Box>
    </Box>
  );
};

export default ASmartGift;
