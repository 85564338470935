import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.996 4.5a2 2 0 11-4 0 2 2 0 014 0zm1.5 0a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zM7.293 8.991a5.68 5.68 0 00-5.08 3.141A1.982 1.982 0 003.986 15H12.014a1.982 1.982 0 001.773-2.868 5.679 5.679 0 00-5.08-3.14H7.293zm-3.738 3.812a4.18 4.18 0 013.738-2.312h1.414c1.583 0 3.03.895 3.738 2.312a.482.482 0 01-.431.697H3.986a.482.482 0 01-.431-.697z"
      fill="#1D1D1D"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgUser16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgUser16;
