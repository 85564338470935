import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as api from '../utils/api';

export default function useZones(id = false) {
  const { zones } = useSelector((s) => s.places);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!zones.length) {
      const getZones = async () => {
        const allZones = await api.get('/zones');
        if (!allZones.error_message) {
          dispatch({
            type: 'SET_ZONES',
            zones: allZones,
          });
        }
      };
      getZones();
    }
  });

  return id === false ? zones : zones.find((z) => z.id == id);
}

export function useCurrentZone() {
  const { zone_id } = useSelector((s) => s.course);

  return useZones(zone_id ?? null);
}
