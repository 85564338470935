import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import Box from '../../shared/atoms/Box';
import Heading from '../../shared/atoms/Heading';

const SkeletonCourses = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Heading level={3}>{t('pendingrequest')}</Heading>
      <Skeleton style={{ borderRadius: 24 }} height={150} />
      <Skeleton style={{ borderRadius: 24, marginTop: 24 }} height={150} />
      <Skeleton style={{ borderRadius: 24, marginTop: 24 }} height={150} />
      <Skeleton style={{ borderRadius: 24, marginTop: 24 }} height={150} />
    </Box>
  );
};

export default SkeletonCourses;
