import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.87 14.869l.132.131.14-.14c2.206-2.206 5.358-5.358 5.358-8.341C13.5 3.472 11.04 1 8.002 1 4.964 1 2.5 3.472 2.5 6.519c0 2.987 3.161 6.144 5.37 8.35zM8.026 8.7c1.144 0 2.071-.94 2.071-2.1 0-1.16-.927-2.1-2.071-2.1-1.145 0-2.072.94-2.072 2.1 0 1.16.927 2.1 2.072 2.1z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgPinfill16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPinfill16;
