import React from 'react';
import styled, { css } from 'styled-components';
import { space, position } from 'styled-system';

const StyledContainer = styled.div`
  ${(p) => css`
    // 1280 + 40*2 safe border padding
    max-width: ${p.$full ? '100%' : '1360px'};
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 20px;
    padding-right: 20px;
    ${p.$overflowHide &&
    css`
      overflow: hidden;
    `}
    ${p.theme.mediaQueries.tablet} {
      padding-left: 40px;
      padding-right: 40px;
    }
    ${p.theme.mediaQueries.desktop} {
      padding-left: 80px;
      padding-right: 80px;
    }
    ${p.$fluid &&
    css`
      padding-left: 0;
      padding-right: 0;
      ${p.theme.mediaQueries.tablet} {
        padding-left: 40px;
        padding-right: 40px;
      }
    `}
  `}
  ${space}
  ${position}
`;

/**
 * @param {boolean} fluid boolean
 * @param {boolean} fullWidth boolean
 * @param {any} space Styled System Spaces
 */
export default function Container({
  fluid = false,
  fullWidth = false,
  overflowHide = false,
  children,
  ...props
}) {
  return (
    <StyledContainer
      $fluid={fluid}
      $full={fullWidth}
      {...props}
      $overflowHide={overflowHide}>
      {children}
    </StyledContainer>
  );
}
