import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import {
  toggleSlot as actionToggleSlot,
  setFixerId,
  setSlot,
} from '../../actions/course';
import { selectCapabilities } from '../../reducers/diag';
import { selectIsLoggedIn } from '../../reducers/client'; // Correct import
import useResaShop from '../../hooks/useResaShop';
// Utils
import * as api from '../../utils/api.jsx';
// Modals
import NoFixerModal from '../../modals/NoFixerModal';
// Components
import RenewFixerEncart from '../../components/funnel/RenewFixerEncart';
import Box from '../../components/shared/atoms/Box';
import Heading from '../../components/shared/atoms/Heading';
import Text from '../../components/shared/atoms/Text';
import AgendaCheckbox from '../../components/dateTimeModule/AgendaCheckbox';
import DateTimeModule from '../../components/dateTimeModule/DateTimeModule';
import HelpCard from '../../components/shared/molecules/HelpCard';
// Icons
import ArrowRight24 from '../../components/icons/ArrowRight24';
import { useCurrentZone } from '../../hooks/useZones';

const RepairDate = ({ history }) => {
  const {
    fixerId,
    product_id,
    fixed,
    slots,
    position,
    selectedShop,
    fixerCode,
  } = useSelector((s) => s.course);
  const { id: clientId } = useSelector((s) => s.client);
  const isLoggedIn = useSelector((state) => {
    const loggedIn = selectIsLoggedIn(state);
    return loggedIn;
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [noDispoModalOpen, setNoDispoModalOpen] = useState(false);
  const [waitinglistSent, setWaitingListSent] = useState(false);
  const capabilities = useSelector((s) => {
    const queryParams = new URLSearchParams(window.location.search);
    return selectCapabilities(s, queryParams);
  });
  const [fixers, setFixers] = useState(null);
  const [found, setFound] = useState(null);
  const isShop = useResaShop();
  const zone = useCurrentZone();
  const autodiag = useSelector((state) => state.autodiag.autodiag);
  const isAutodiagV2 = autodiag === 'v2'; // Use this declaration

  const _submitWaitingList = async (values, { setError, setSubmitting }) => {
    const waiting = await api.post(`/waitinglist`, {
      position,
      product_id,
      ...values,
    });
    if (waiting.ok) setWaitingListSent(true);
    else {
      setError(t('wronginformations'));
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    setNoDispoModalOpen(false);
    if (fixerId) {
      dispatch(setFixerId(''));
    } else {
      history.push('/');
    }
  };

  const addToFixersList = (newFixers) => {
    setFixers((fixers) => {
      let currentList = fixers === null ? [] : [...fixers];

      currentList = currentList.concat(newFixers);
      const addedfilterList = [];
      currentList = currentList.filter((f) => {
        const exists = !!~addedfilterList.indexOf(f.fixer.id.toString());
        if (exists) {
          return false;
        }

        addedfilterList.push(f.fixer.id.toString());

        return true;
      });
      return [...new Set(currentList)];
    });
  };

  useEffect(() => {
    if (selectedShop?.id) {
      return;
    }

    const fetchFixerByCode = async () => {
      if (!fixerCode) {
        return;
      }
      const res = await api.get(`/fixers/codes`, {
        code: fixerCode,
      });

      if (res && !res.error_message) {
        addToFixersList([{ fixer: res }]);
        return [{ fixer: res }];
      }
    };

    const fetchFixersHistory = async () => {
      if (!clientId) {
        return;
      }
      const res = await api.get(`/clients/${clientId}/fixers`, {
        position,
      });

      if (res && !res.error_message) {
        addToFixersList(res);
        return res;
      }
    };

    (async () => {
      setFixers(null);
      const fixersCode = await fetchFixerByCode();
      const fixersHisto = await fetchFixersHistory();
      if (!fixersCode && !fixersHisto) {
        setFixers([]);
      }
    })();
  }, [clientId, position, dispatch, fixerCode]);

  useEffect(() => {
    if (fixers === null) {
      return;
    }

    if (fixerId && fixers && !fixers.find((e) => e.fixer.id === fixerId)) {
      dispatch(setFixerId(''));
    }

    setFound(fixers.find((o) => o.fixer.id === fixerId));
  }, [fixerId, fixers, dispatch]);

  const handleDateSubmit = () => {
    if (isAutodiagV2) {
      // Check if the user is logged in
      if (isLoggedIn) {
        // Redirect to RepairCheckout if logged in
        console.log('redirect /reparation/checkout');
        history.push('/reparation/checkout');
      } else {
        // Redirect to RepairLogin if not logged in
        console.log('redirect /reparation/informations');
        history.push('/reparation/informations');
      }
    } else {
      console.log('redirect /reparation/details');
      history.push('/reparation/details');
    }
  };

  return (
    <Box>
      <Heading level={2} mb={{ xs: 4, lg: 5 }}>
        {t('repairdate.heading')}
      </Heading>
      {!selectedShop?.id && (
        <>
          <Box flexDirection="row" mb={5}>
            <ArrowRight24 />
            <Text ml={2} color="black" fontSize="lg">
              {t('repairdate.subheading')}
            </Text>
          </Box>
          <RenewFixerEncart
            mb={5}
            fixers={fixers}
            fixer={found ? found.fixer : null}
          />
          <HelpCard title={t('goodtoknow...')} mb={5}>
            <Box flexDirection="row" mb={2}>
              <span
                style={{
                  fontSize: 32,
                  lineHeight: 0.7,
                  marginLeft: -2,
                  marginRight: 4,
                }}>
                •
              </span>
              <Text>
                <Trans i18nKey="repairdate.texts.1" />
              </Text>
            </Box>
            <Box flexDirection="row" mb={2}>
              <span
                style={{
                  fontSize: 32,
                  lineHeight: 0.7,
                  marginLeft: -2,
                  marginRight: 4,
                }}>
                •
              </span>
              <Text>
                <Trans i18nKey="repairdate.texts.2" />
              </Text>
            </Box>
            {zone?.id === 1 && (
              <Box flexDirection="row">
                <span
                  style={{
                    fontSize: 32,
                    lineHeight: 0.7,
                    marginLeft: -2,
                    marginRight: 4,
                  }}>
                  •
                </span>
                <Text>
                  <Trans i18nKey="repairdate.texts.4" />
                </Text>
              </Box>
            )}
          </HelpCard>
          <Box alignItems="flex-start">
            <AgendaCheckbox text={t('repairdate.texts.3')} mb={4} exemple />
          </Box>
        </>
      )}
      <NoFixerModal
        setNoDispoModalOpen={setNoDispoModalOpen}
        open={noDispoModalOpen}
        waitinglistSent={waitinglistSent}
        onSubmit={_submitWaitingList}
        close={handleClose}
        fixer={found ? found.fixer : null}
      />
      <DateTimeModule
        slots={slots}
        toggleSlot={(available_min, available_max) =>
          isShop
            ? dispatch(setSlot(available_min, available_max))
            : dispatch(actionToggleSlot(available_min, available_max))
        }
        position={position}
        fixerId={fixerId}
        onSubmit={handleDateSubmit} // Use the handleDateSubmit function here
        fixed={fixed}
        product_id={product_id}
        noDispoModalOpen={setNoDispoModalOpen}
        capabilities={capabilities}
        shopId={selectedShop?.id}
      />
    </Box>
  );
};

export default RepairDate;
