/* eslint-disable no-extra-boolean-cast */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import * as api from '../../../../utils/api';
import { resetPositionInput } from '../../../../actions/course.jsx';
// Components
import Box from '../../../shared/atoms/Box';
import Heading from '../../../shared/atoms/Heading';
import Button from '../../../shared/atoms/Button';
import Modal from '../../../../modals/Modal';
import AddressInput from '../../../shared/main-form/AddressInput';
import Spinner from '../../../Spinner';
import Text from '../../../shared/atoms/Text';
// Icons
import InfosColor56 from '../../../icons/InfosColor56';
import Pin24 from '../../../icons/Pin24';
import Times24 from '../../../icons/Times24';

const MapInput = styled(AddressInput)`
  padding: 14px 12px;
  border-radius: ${(p) => p.theme.radii.xs};
  border: 1px solid ${(p) => p.theme.colors.gray.light};
  height: auto;
  z-index: 10002;
  min-width: 'auto' !important;
  .react-autosuggest__suggestions-container {
    min-width: auto !important;
    z-index: 2000;
  }
`;

const UpdateAdresse = ({
  course,
  setCourse,
  showModal,
  openModal,
  getRequest,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { position, positionInput, positionLoading } = useSelector(
    (s) => s.course,
  );
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleSetAdresse = async () => {
    setLoading(true);
    if (!course.fixer) {
      // If no fixer has accepted yet
      const courseUpdated = await api.put(`/courses/${course.id}`, {
        position,
      });
      if (courseUpdated.id) {
        setCourse(courseUpdated);
        openModal(false);
        setErrorMsg('');
      } else {
        setErrorMsg(courseUpdated.error_message);
      }
    } else {
      // else if a fixer has already accepted
      const request = await api.post(`/courses/${course.id}/requests`, {
        position,
      });
      if (Number(request.ok) === 1) {
        getRequest();
        openModal(false);
        setErrorMsg('');
      } else {
        setErrorMsg(t('updatemodal.error'));
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!position.length) {
      setErrorMsg(t('newmainform.texts.1'));
    } else {
      setErrorMsg('');
    }
  }, [position]);

  const deleteInputAndPin = () => {
    dispatch(resetPositionInput());
  };

  return (
    <Modal
      zIndex="1002"
      hasCloseAction={true}
      width="medium"
      overflow="inherit"
      open={showModal === 'UPDATE_ADRESSE'}
      onRequestClose={() => {
        openModal(false);
        setErrorMsg('');
      }}>
      <Box mb={4}>
        <InfosColor56 />
      </Box>
      <Heading mb={4} level={5}>
        {t('updateadressemodal.title')}
      </Heading>
      <Text mb={4}>{t('updateadressemodal.subtitle')}</Text>
      <MapInput
        name="google-map-address-input"
        bg="white"
        withPadding
        withLabel={false}
        before={<Pin24 />}
        after={positionInput && <Times24 onClick={deleteInputAndPin} />}
      />
      {errorMsg && (
        <Text fontWeight={2} color="primary.darkest" mt={1}>
          {errorMsg}
        </Text>
      )}
      <Box
        alignItems="flex-end"
        justifyContent="flex-end"
        flexDirection="row"
        mt={5}>
        <Button
          size="compact"
          color="blue.electric"
          disabled={loading || positionLoading || !position.length}
          onClick={handleSetAdresse}
          style={{ width: '100%' }}>
          {loading ? <Spinner small /> : t('modify.cta')}
        </Button>
      </Box>
    </Modal>
  );
};

export default UpdateAdresse;
