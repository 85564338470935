import React from 'react';
import styled from 'styled-components';
import { device, colors } from '../../styles/constants';

const SectionWrapper = styled.section`
    margin: 4em 0;
    padding: 4em 0;
    
    ${(props) =>
      !!props.blue &&
      `
        background-color:${colors.blue};
        color:#ffffff;
    `}
    
    @media ${device.md} {
      margin: 4em 0;
      padding: 4em 0;
    }    
    @media ${device.sm} {
      margin: 3em 0;
      padding: 3em 0;
    }
    
    ${(props) =>
      !!props.top &&
      `
        margin: 2.3em 0;
        padding: 2.3em 0;
    `}    
`;

const Section = ({ children, ...props }) => (
  <SectionWrapper {...props}>{children}</SectionWrapper>
);

export default Section;
