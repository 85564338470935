import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M14 45a1 1 0 100 2h5a1 1 0 100-2h-5zM22 46a1 1 0 011-1h1a1 1 0 110 2h-1a1 1 0 01-1-1z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 11a7 7 0 017-7h14a7 7 0 017 7v6.182c.493.09.976.214 1.444.368a1 1 0 01-.626 1.9A8.991 8.991 0 0034 19a9 9 0 100 18 9.004 9.004 0 008.717-6.75 1 1 0 011.936.5A11.014 11.014 0 0136 38.818V45a7 7 0 01-7 7H15a7 7 0 01-7-7V11zm26 28c-6.075 0-11-4.925-11-11s4.925-11 11-11v-6a5.002 5.002 0 00-3.208-4.67l-.194 1.163A3 3 0 0127.64 10H16.361a3 3 0 01-2.96-2.507l-.193-1.162A5.002 5.002 0 0010 11v34a5 5 0 005 5h14a5 5 0 005-5v-6zM15.18 6h13.64l-.195 1.164A1 1 0 0127.64 8H16.361a1 1 0 01-.987-.836L15.18 6z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.455 20.505c.713.686.728 1.813.035 2.518l-9.81 9.972-2.575.005-4.59-4.63a1.767 1.767 0 01.025-2.518 1.814 1.814 0 012.545.024l3.3 3.329 8.525-8.666a1.814 1.814 0 012.545-.034z"
      fill="#28D898"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.007 17a1 1 0 00-2 0v2H16a1 1 0 100 2h1.007v2a1 1 0 102 0v-2H20a1 1 0 100-2h-.993v-2z"
      fill="#FFE249"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.007 6a1 1 0 00-2 0v2H42a1 1 0 100 2h1.007v2a1 1 0 102 0v-2H46a1 1 0 100-2h-.993V6z"
      fill="#d5ff4f"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.007 40a1 1 0 00-2 0v2H39a1 1 0 100 2h1.007v2a1 1 0 102 0v-2H43a1 1 0 100-2h-.993v-2z"
      fill="#1F77F9"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgSmartphone56Color = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgSmartphone56Color;
