import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 7.143a.9.9 0 000-1.8H5.957c-.458 0-.79 0-1.052-.013l.247-.28a4.1 4.1 0 11-1.065 4.18.9.9 0 10-1.717.54A5.902 5.902 0 0013.9 8 5.9 5.9 0 003.87 3.787l-.023.022-.022.024-.158.18c-.01-.249-.01-.559-.01-.97V2a.9.9 0 10-1.8 0V3.078c0 .53 0 .981.03 1.352.032.389.102.767.286 1.13a2.9 2.9 0 001.268 1.267c.362.184.74.254 1.13.285.37.03.821.03 1.351.03H7z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgBack16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgBack16;
