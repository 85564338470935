import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DevisContent from './DevisContent';
import HelpCard from '../shared/molecules/HelpCard';
import Text from '../shared/atoms/Text';
import QuestionContent from './QuestionContent';
import sizeTheVignettes from '../../utils/diag';

const isIntro = (qid) => qid == 35;
const isDevis = (qid) => qid[0] === 'd';

const DiagContent = ({
  questionid,
  onNavigate,
  popQ,
  withHelper,
  ...props
}) => {
  const { t } = useTranslation();
  // a revoir pour l'intro
  const intro = isIntro(questionid);
  useEffect(sizeTheVignettes);
  useEffect(() => {
    window.addEventListener('resize', sizeTheVignettes);

    return () => {
      window.removeEventListener('resize', sizeTheVignettes);
    };
  }, []);

  if (isDevis(questionid))
    return <DevisContent devisid={questionid.slice(1)} popQ={popQ} />;

  return (
    <>
      <QuestionContent
        questionid={questionid}
        withHelper={withHelper}
        onNavigate={onNavigate}
      />
      {intro && (
        <HelpCard title={t('diagcontent.title')} mb={6} mt={5}>
          <Text color="black">{t('diagcontent.text')}</Text>
        </HelpCard>
      )}
    </>
  );
};

export default DiagContent;
