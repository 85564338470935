import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx={28} cy={21} r={12} fill="#FFE249" />
    <path
      d="M4.462 9.816a1 1 0 00.379 1.363l5.176 2.922a1 1 0 00.984-1.742L5.824 9.437a1 1 0 00-1.362.38zM7.241 37.248a1 1 0 01-1.175-1.618l4.81-3.493a1 1 0 011.175 1.618l-4.81 3.493zM49.222 37.248a1 1 0 001.175-1.618l-4.81-3.493a1 1 0 00-1.175 1.618l4.81 3.493zM51.623 11.179a1 1 0 00-.984-1.742l-5.176 2.922a1 1 0 10.983 1.742l5.177-2.922zM4 23.153a1 1 0 001 1h3.308a1 1 0 000-2H5a1 1 0 00-1 1zM51.464 24.153a1 1 0 100-2h-3.308a1 1 0 100 2h3.308z"
      fill="#FFE249"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 20.691C11 11.455 18.63 4 28 4c9.37 0 17 7.455 17 16.691 0 5.869-3.084 11.023-7.732 13.995-1.14.73-1.868 1.855-1.868 3.029V46a6 6 0 01-6 6h-2.8a6 6 0 01-6-6v-8.285c0-1.175-.727-2.3-1.868-3.029C14.085 31.714 11 26.56 11 20.691zM28 6c-8.303 0-15 6.596-15 14.691 0 5.147 2.703 9.683 6.81 12.31 1.34.858 2.442 2.232 2.721 3.891l.869-.133v-12.33H21a3 3 0 01-3-3V20a3 3 0 013-3h1.4a3 3 0 013 3v2.429h5.2V20a3 3 0 013-3H35a3 3 0 013 3v1.429a3 3 0 01-3 3h-2.4v10.914l1.528-.235a6.397 6.397 0 012.062-2.106c4.108-2.628 6.81-7.164 6.81-12.31C43 12.595 36.303 6 28 6zm2.6 29.65V24.43h-5.2V36.45l5.2-.8zm-8 6.232v-2.977l10.823-1.665a4.954 4.954 0 00-.023.475v2.505l-10.8 1.662zm0 2.023V46c0 .298.032.588.094.867L33.4 45.22v-2.976l-10.8 1.661zm4 6.095a3.989 3.989 0 01-2.916-1.262l9.508-1.462A4.002 4.002 0 0129.4 50h-2.8zm-3.2-30v2.429H21a1 1 0 01-1-1V20a1 1 0 011-1h1.4a1 1 0 011 1zM35 22.429h-2.4V20a1 1 0 011-1H35a1 1 0 011 1v1.429a1 1 0 01-1 1z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgLightbulbColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgLightbulbColor56;
