import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as api from '../utils/api';
import { setClient } from '../actions/client';
import { resumeReparation } from '../utils/reparation';
// My components
import Box from '../components/shared/atoms/Box';
import Row from '../components/shared/atoms/Row';
import Container from '../components/shared/atoms/Container';
import Image from '../components/shared/atoms/Image';
import ResetPasswordForm from '../components/connexionModule/ResetPasswordForm';

const StyledImage = styled(Image)`
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    display: none;
  }
`;
const ResetPassword = ({ match, ...props }) => {
  const store = useSelector((s) => s);
  const dispatch = useDispatch();
  const history = useHistory();

  const submit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    const rep = await api.post('/clients/sessions', {
      password: values.password,
      token: match.params.token,
      verif_token: match.params.veriftoken,
    });

    if (rep.error_message) setErrors({ password: rep.error_message });
    else if (rep.id) {
      dispatch(setClient(rep));
      setSubmitting(false);

      /* redirect depending on query param */
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('redir')) {
        const redir = urlParams.get('redir');

        if (redir === 'reparation') resumeReparation(store, history);
        else if (redir.indexOf('/courses') === 0) history.push(redir);
        if (redir === 'account') history.push('/account');
      } else {
        history.push('/account');
      }
    }
  };

  return (
    <Container mt={5} mb={10}>
      <Row md gap={24} style={{ alignItems: 'stretch' }}>
        <Box>
          <ResetPasswordForm submit={submit} />
        </Box>
        <StyledImage
          borderRadius="md"
          src="/static/img/photos/login-side.png"
          alt="connectez vous"
        />
      </Row>
    </Container>
  );
};

export default ResetPassword;
