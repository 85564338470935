import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components shared
import SectionBackground from './SectionBackground';
import Box from '../atoms/Box';
import Heading from '../atoms/Heading';
import Button from '../atoms/Button';
import ToolkitFix from '../ToolkitFix';
// Icons
import AngleRight16 from '../../icons/AngleRight16';

const StyledButton = styled(Button)`
  width: 100%;
  text-align: center;
`;

const StyledBox = styled(Box)`
  z-index: 2;
  margin-bottom: -100px;
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    margin-bottom: 32px;
  }
`;

const StyledImg = styled.img`
  object-fit: contain;
  max-height: 549px;
  width: 100%;
  margin-bottom: calc(-150px - ${(p) => p.sizeHeader}px);
`;

const StyledToolKitSquare = styled(ToolkitFix)`
  position: absolute;
  left: 45%;
  top: 31px;
  transform: rotate(-15deg);
  width: 90px;
  height: 90px;
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    right: 24px;
    left: auto;
    top: 150px;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    display: none;
  }
`;

const StyledToolKitHexa = styled(ToolkitFix)`
  position: absolute;
  transform: rotate(13deg);
  width: 120px;
  height: 140px;
  right: -54px;
  left: 10%;
  top: -55px;
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    top: -62px;
    right: 70px;
    left: auto;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    top: -62px;
    right: -54px;
  }
`;

const StyledSectionBackground = styled(SectionBackground)`
  position: relative;
  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    max-height: calc(520px - ${(p) => p.sizeHeader}px);
  }
`;

const HeroShared = ({
  sizeHeader = 0,
  title,
  text,
  buttonText,
  buttonLink = 'https://feedback.roulezjeunesse.com/contact-b2b',
  color,
  img = '/static/img/photos/business/hero-image_home-business.png',
  colorHexa = 'blue.main',
  colorSquare = 'primary.main',
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ overflow: 'hidden' }}>
      <StyledSectionBackground
        sizeHeader={sizeHeader}
        bg={color}
        px={{ xs: '20px', md: 10 }}
        py={{ xs: 7, md: 10 }}
        mb="235px"
        justifyContent={{ xs: 'flex-start', md: 'flex-end' }}>
        <Box
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent="space-between">
          <StyledToolKitHexa name="hexa" color={colorHexa} />
          <StyledToolKitSquare name="square" color={colorSquare} />
          <StyledBox
            width={{ xs: '100%', sm: '75%', md: '50%' }}
            justifyContent="flex-end">
            <Heading level={1} mb={4}>
              {title}
            </Heading>
            <Box width={{ xs: '100%', md: '90%' }}>
              {text}
              <StyledButton
                target="_blank"
                href={buttonLink}
                after={<AngleRight16 />}
                animation>
                {buttonText}
              </StyledButton>
            </Box>
          </StyledBox>
          <Box
            width={{ xs: '100%', md: '50%' }}
            justifyContent="flex-end"
            alignItems="flex-end">
            <StyledImg
              src={img}
              alt={t('roulezjeunessebusiness')}
              sizeHeader={sizeHeader}
            />
          </Box>
        </Box>
      </StyledSectionBackground>
    </div>
  );
};

export default HeroShared;
