import i18n from '../i18n';

const strapiAnswer = ({
  quote,
  id,
  question,
  image,
  prio,
  content = '',
  content_en = '',
  capabilities,
}) => {
  const lang = i18n.language;

  const getContent = () => {
    return content;
  };
  const getPictureUrl = () => {
    return image;
  };

  return {
    quote,
    id,
    prio,
    question,
    image,
    content: getContent(),
    content_en,
    getContent,
    getPictureUrl,
    capabilities,
  };
};

export default strapiAnswer;
