import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as api from '../../../../utils/api';
// Components
import Box from '../../../shared/atoms/Box';
import Heading from '../../../shared/atoms/Heading';
import Button from '../../../shared/atoms/Button';
import Text from '../../../shared/atoms/Text';
import Modal from '../../../../modals/Modal';
import Dropzone from '../../../shared/organisms/NewDropzone';
import Spinner from '../../../Spinner';
// Icons
import ImageColor56 from '../../../icons/ImageColor56';

const UpdatePhotos = ({ course, showModal, openModal, getRelatedPhotos }) => {
  const { t } = useTranslation();
  const [dropZone, setDropZone] = useState([]);
  const [loading, setLoading] = useState(false);

  const closeAndGetPhotos = () => {
    getRelatedPhotos();
    openModal(false);
  };

  const handleSetPhotos = async () => {
    setLoading(true);
    const promises = dropZone.map((file) =>
      api.postImage(`/courses/${course.id}/photos`, file),
    );
    await Promise.all(promises);
    setLoading(false);
    closeAndGetPhotos();
    setDropZone([]);
  };

  return (
    <Modal
      zIndex="1002"
      hasCloseAction={true}
      width="medium"
      open={showModal === 'UPDATE_PHOTOS'}
      onRequestClose={closeAndGetPhotos}>
      <Box mb={4}>
        <ImageColor56 />
      </Box>
      <Heading mb={4} level={5}>
        {t('addpictures')}
      </Heading>
      <Text>{t('addpictures.title')}</Text>
      <Dropzone photos={dropZone} setPhotos={setDropZone} mt={4} mb={5} />
      <Box
        alignItems="flex-end"
        justifyContent="flex-end"
        flexDirection="row"
        mt={5}>
        <Button
          size="compact"
          color="blue.electric"
          onClick={handleSetPhotos}
          style={{ width: '100%' }}>
          {loading ? <Spinner small /> : t('save')}
        </Button>
      </Box>
    </Modal>
  );
};

export default UpdatePhotos;
