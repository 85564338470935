export const colors = {
  primary: {
    darkest: '#8F1505',
    darker: '#b8200c',
    dark: '#EF2C12',
    main: '#d5ff4f',
    light: '#FF6753',
    lighter: '#F6C8C0',
    pale: '#FFDFCF',
  },
  gray: {
    darkest: '#313534',
    darker: '#626A68',
    dark: '#939F9C',
    main: '#C6D2CF',
    light: '#E1EAE8',
    lighter: '#EBF2F0',
    lightest: '#F1F5F4',
  },
  white: '#FFFFFF',
  black: '#1D1D1D',
  stone: '#F2EBE2',
  yellow: {
    main: '#FFE249',
    lighter: '#FFF9B3',
    pale: '#FFFBCF',
    dark: '#6F611E',
  },
  orange: {
    lighter: '#FFDFCF'
  },
  violet: {
    main: '#8A38F3',
    lighter: '#E2BEFF',
    light: '#A471E6',
    dark: '#612AA8',
    pale: '#F0E0FE',
  },
  red: {
    main: '#d5ff4f',
    lighter: '#FF6753',
    pale: '#FFECEA',
    dark: '#EF2C12',
  },
  turquoise: {
    main: '#1D859E',
    lighter: '#B6DDE7',
    light: '#43A6BE',
    dark: '#0F657A',
  },
  green: {
    main: '#28D898',
    lighter: '#A6F2C4',
    pale: '#D7FCE5',
    dark: '#199266',
    roulezjeunesse: '#D5FF4F',
  },
  blue: {
    main: '#1F77F9',
    lighter: '#B0BDFF',
    pale: '#DAE9FE',
    dark: '#1653AE',
    electric: '#595fe7',
  },
  twitter: '#1DA1F2',
  facebook: '#3B5998',
};
const sizes = {
  xs: '0.75em',
  sm: '1em',
  md: '1.5em',
  lg: '2em',
  xl: '3em',
};
const breakpoints = {
  xs: '0',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1280px',
};
const mediaQueries = {
  smartphone: `@media (min-width: ${breakpoints.sm})`,
  tablet: `@media (min-width: ${breakpoints.md})`,
  desktop: `@media (min-width: ${breakpoints.lg})`,
};
const theme = {
  colors,
  sizes,
  mediaQueries,
  breakpoints,
  space: [0, 4, 8, 12, 16, 24, 32, 40, 48, 56, 80, 104, 128, 152],
  shadows: {
    sm: '0px 2px 8px rgba(0, 0, 0, 0.08), 0px 2px 16px rgba(0, 0, 0, 0.12)',
    md: '0px 2px 10px rgba(0, 0, 0, 0.05), 0px 4px 30px rgba(0, 0, 0, 0.10)',
    lg: '0px 4px 10px rgba(0, 0, 0, 0.05), 0px 20px 50px rgba(0, 0, 0, 0.15)',
  },
  radii: {
    none: '0',
    xxs: '8px',
    xs: '16px',
    sm: '24px',
    md: '32px',
    lg: '40px',
    xl: '80px',
    full: '50%',
  },
  texts: {
    caption2: {
      fontSize: '0.625em',
      lineHeight: '1.5',
      color: colors.gray.main,
    },
    notice2: {
      fontStyle: 'italic',
      fontSize: '18px',
      lineHeight: '27px',
    },
    caption: {
      fontSize: '0.75em',
      lineHeight: '1.5',
      color: colors.gray.main,
    },
    small: {
      fontSize: '0.875em',
      lineHeight: '1.5',
    },
    medium: {
      fontSize: '1em',
      lineHeight: '1.5',
    },
    large: {
      fontSize: '1.125em',
      lineHeight: '1.5',
    },
  },
  buttonSizes: {
    compact: {
      padding: `0.75rem 1.5rem`,
      fontSize: '14px',
    },
  },
  fontSizes: {
    xs: '0.75em',
    sm: '0.875em',
    md: '1em',
    lg: '1.125em',
    xl: '1.25em',
    xxl: '1.375em',
    xxxl: '1.875em',
  },
  fontWeights: [400, 500, 600],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em',
  },
  borders: Object.values(colors.gray).map((gray) => `1px solid ${gray}`),
};
export default theme;
