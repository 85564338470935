import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Row from '../shared/atoms/Row';
import Box from '../shared/atoms/Box';
import Heading from '../shared/atoms/Heading';
import Text from '../shared/atoms/Text';
import Button from '../shared/atoms/Button';
import BuildingColor32 from '../icons/BuildingColor32';
import AngleRight16 from '../icons/AngleRight16';
import { resumeReparation } from '../../utils/reparation';

const FixerAtHome = ({ product, ...props }) => {
  const history = useHistory();
  const store = useSelector((s) => s);
  const { t } = useTranslation();

  // TODO to be removed
  return ("Réparateur de vélo électrique");

  return (
    <Row md gap={56} {...props}>
      <Box justifyContent="center">
        <Heading level={2}>
          {t('fixerathome.headings.1', {
            product: product.productLabelMainPlural,
          })}
        </Heading>
        <Text mt={-2}>
          {t('fixerathome.texts.1', { product: product.productLabelMain })}
        </Text>
        <Text mt={4}>
          <BuildingColor32 mr={2} />
          <Trans
            i18nKey="fixerathome.texts.2"
            components={{
              strong: <b />,
            }}
          />
        </Text>
        <Box width="55%" mt={5}>
          <Button
            fullWidth
            after={<AngleRight16 />}
            onClick={() => resumeReparation(store, history)}>
            {t('mainform.texts.5')}
          </Button>
        </Box>
      </Box>
      <Box>
        <img src={product.fixerAtHomeImg} />
      </Box>
    </Row>
  );
};

export default FixerAtHome;
