/* eslint-disable no-extra-boolean-cast */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import useBookableShops from '../../../hooks/useBookableShops';
import ProductSelect from './ProductSelect';
import AddressInput from './AddressInput';
import Button from '../atoms/Button';
import Box from '../atoms/Box';

import {
  setAddress,
  setPosition,
  setSelectedShop,
} from '../../../actions/course.jsx';
import { resetDiag } from '../../../actions/diag.jsx';
import { selectLastQID, selectDevisID } from '../../../reducers/diag.jsx';
import useNavigatorPosition from '../../../hooks/useNavigatorPosition';
import { FeaturesContext } from '../../../contexts/FeaturesContext';

const StyledFormContainer = styled(Box)`
  border-radius: ${(p) => p.theme.radii.sm};
  ${(p) => p.theme.mediaQueries.tablet} {
    border-radius: ${(p) => p.theme.radii.lg};
  }
`;

const InputFormContainer = styled(Box)`
  border-right: none;
  border-bottom: 1px solid ${(p) => p.theme.colors.gray.light};
  ${(p) => p.theme.mediaQueries.desktop} {
    border-bottom: none;
  }
`;

const HtmlForm = styled.form`
  display: flex;
  flex-direction: column;
  ${(p) => p.theme.mediaQueries.desktop} {
    flex-direction: row;
  }
`;

const MapInput = styled(AddressInput)`
  .react-autosuggest__suggestions-container {
    left: -25px;
  }
`;

function NewMainForm({ productId, isWidget, ...props }) {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    positionLoading,
    position,
    positionError,
    positionInput,
    selectedShop,
    shops,
  } = useSelector((s) => ({
    ...s.course,
    lastQID: selectLastQID(s),
    devisID: selectDevisID(s),
  }));
  const bookableShops = useBookableShops();

  const dispatch = useDispatch();
  const { isError } = useNavigatorPosition();
  const { setWidget } = useContext(FeaturesContext);

  const _goToFixed = () => {
    if (!!bookableShops.length || !!shops.length) {
      history.push('/reparation');
      return;
    }

    if (selectedShop?.id && position != selectedShop?.address) {
      dispatch(resetDiag());
    }

    if (
      (selectedShop?.id && position != selectedShop?.address) ||
      !selectedShop?.id
    ) {
      dispatch(setSelectedShop(null));
    }

    if (isWidget) {
      setWidget(true);
    }
    history.push('/reparation/symptoms');
  };

  const _testCurrentInputForValidAddress = async () => {
    if (RegExp(/^[a-zA-Z]+,* *[a-zA-Z]*$/).test(positionInput)) {
      dispatch(setPosition('', t('newmainform.texts.1')));
      return;
    }
    const isValid = await dispatch(setAddress(positionInput));
    if (isValid) {
      _goToFixed();
    } else if (isValid === 0) {
      history.push('/reparation');
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (position) {
      _goToFixed();
    } else if (positionInput) {
      _testCurrentInputForValidAddress();
    } else {
      dispatch(setPosition('', t('newmainform.texts.2')));
    }
  };

  return (
    <StyledFormContainer
      p={{ xs: 4, lg: 3 }}
      width={{ xs: 1, md: 2 / 3, lg: 4 / 6 }}
      bg="white"
      boxShadow="sm"
      {...props}>
      <HtmlForm onSubmit={onSubmit}>
        <InputFormContainer
          flex={1}
          p={{ xs: 1, md: 2, lg: 0 }}
          pl={{ xs: 0, lg: 4 }}
          onSubmit={onSubmit}
          mb={{ xs: 4, lg: 0 }}>
          <MapInput
            positionLoading={positionLoading}
            bg="white"
            error={t(positionError)}
            flex={1}
            mb={{ xs: 3, md: 0 }}
            withGradient
          />
        </InputFormContainer>
        <Box
          p={{ xs: 1, md: 2, lg: 0 }}
          pl={{ xs: 0, lg: 4 }}
          flexDirection={{ xs: 'column', lg: 'row' }}
          alignItems={{ lg: 'center' }}>
          <Box
            minWidth="15rem"
            flexDirection="row"
            justifyContent="flex-end"
            mt={{ xs: 3, lg: 0 }}>
            <Button
              // disabled={!!positionError}
              loading={positionLoading}
              data-testid="see-availability"
              fullWidth="lg">
              {t('mainform.texts.5')}
            </Button>
          </Box>
        </Box>
      </HtmlForm>
    </StyledFormContainer>
  );
}

export default withRouter(NewMainForm);
