import React from 'react';

import Skeleton from 'react-loading-skeleton';
import Box from '../atoms/Box';

const CardsSkeleton = () => {
  return (
    <Box mb={4}>
      <Skeleton style={{ borderRadius: 16 }} height={68} />
    </Box>
  );
};

export default CardsSkeleton;
