import React from 'react';
import styled from 'styled-components';
import InputGroup from './blocks/InputGroup';

const InputWrapper = styled.input``;

const Input = (props) => {
  if (props.form) {
    // Formik case
    const {
      field, // { name, value, onChange, onBlur }
      form: { touched, errors, isValid }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      label,
      name,
      ...filteredProps
    } = props;

    return (
      <div>
        <InputGroup
          error={
            touched[field.name] && errors[field.name]
              ? errors[field.name]
              : false
          }
          valid={isValid}
          label={label}
          name={field.name}>
          <InputWrapper maxlength="3" touched {...field} {...filteredProps} />
        </InputGroup>
      </div>
    );
  }

  const { label, name, valid = false, error = false, ...filteredProps } = props;
  return (
    <div>
      <InputGroup label={label} error={error} valid={valid} name={name}>
        <InputWrapper {...filteredProps} />
      </InputGroup>
    </div>
  );
};

export default Input;
