import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 9a4 4 0 11-8 0 4 4 0 018 0zm-1.8 0a2.2 2.2 0 11-4.4 0 2.2 2.2 0 014.4 0z"
      fill="#000"
    />
    <path
      d="M14.923 14.1c-.743 0-1.216 0-1.662.068a5.9 5.9 0 00-3.783 2.237c-.275.358-.503.772-.86 1.423l-.407.738a.9.9 0 101.578.868l.368-.67c.408-.742.568-1.027.748-1.262a4.1 4.1 0 012.63-1.554c.292-.046.62-.048 1.465-.048.846 0 1.173.002 1.466.047a4.1 4.1 0 012.629 1.555c.18.235.34.52.748 1.261l.369.67a.9.9 0 101.577-.867l-.406-.738c-.358-.65-.586-1.065-.861-1.423a5.9 5.9 0 00-3.783-2.237c-.446-.068-.919-.068-1.662-.068h-.154z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.945 13.564a3.027 3.027 0 003.033-3.021A3.027 3.027 0 006.945 7.52a3.027 3.027 0 00-3.033 3.022 3.027 3.027 0 003.033 3.02zm0-1.8c.688 0 1.233-.553 1.233-1.221S7.633 9.32 6.945 9.32c-.688 0-1.233.554-1.233 1.222 0 .668.545 1.22 1.233 1.22z"
      fill="#000"
    />
    <path
      d="M7.233 14.678c-.938 0-1.622 0-2.244.214a3.9 3.9 0 00-1.458.902c-.47.461-.774 1.073-1.192 1.913l-.244.491a.9.9 0 001.61.803l.181-.362c.51-1.024.68-1.338.906-1.56a2.1 2.1 0 01.785-.486c.3-.103.656-.115 1.8-.115H8v-1.8h-.767z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgTeam24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgTeam24;
