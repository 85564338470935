import React, { createRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useSSPR } from 'react-sspr';
// Components
import Input from '../shared/atoms/Input';
import Heading from '../shared/atoms/Heading';
import Text from '../shared/atoms/Text';
import Link from '../shared/atoms/Link';
import Box from '../shared/atoms/Box';
import PricesRows from './PricesRows';
// Icons
import Search24 from '../icons/Search24';
import AngleRight16 from '../icons/AngleRight16';
// Utils
import { filteredElements } from '../../utils/elements';
import { BIKE_PRESTA_URL, SCOOTER_PRESTA_URL } from '../../sspr/constants';
import useScrollSpy from '../../hooks/useScrollSpy';
import { getLang } from '../../utils/utils';
import * as api from '../../utils/api';
import FranceOnly from '../FranceOnly';

const StyledImg = styled.img`
  height: 24px;
  margin-left: 8px;
`;

const StyledBox = styled(Box)`
  flex: 1;
  position: sticky;
  top: 100px;
  align-self: flex-start;
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    display: none;
  }
`;

const StyledText = styled(Text)`
  display: none;
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    display: block;
  }
`;

const checkParams = (param, productId) =>
  productId === '2' || param === 'trottinette'
    ? SCOOTER_PRESTA_URL
    : BIKE_PRESTA_URL;

const CategoriesAndPrices = ({ tab, setTab, product_id, ...rest }) => {
  const [search, setSearch] = useState('');
  const [prestationsEN, setPrestationsEN] = useState('');

  const { t } = useTranslation();

  const refs = useMemo(
    () =>
      Array(6)
        .fill(0)
        .map((_) => createRef()),
    [],
  );

  const onScreen = useScrollSpy({
    sectionElementRefs: refs,
    offsetPx: -80,
  });

  const { product } = useParams();

  const prestations = useSSPR(checkParams(product, product_id));
  const prestationsToDisplay =
    filteredElements('', prestationsEN.length ? prestationsEN : prestations) ||
    [];
  const prestationsResearch = prestationsToDisplay.reduce(
    (acc, cur) => [...acc, ...cur.subs],
    [],
  );
  const [prestationsResearchDisplay, setPrestationResearchDisplay] = useState(
    prestationsResearch,
  );

  const handleSearch = () => {
    const newPrestationsResearch = prestationsResearch.filter(
      (element) => element.nom.toLowerCase().indexOf(search.toLowerCase()) > -1,
    );
    setPrestationResearchDisplay(newPrestationsResearch);
  };

  useEffect(() => {
    handleSearch();
  }, [search]);

  useEffect(() => {
    setSearch('');
  }, [tab]);

  useEffect(() => {
    if (getLang().lang === 'en') {
      (async () => {
        const url = checkParams(product, product_id).replace(
          'https://api.roulezjeunesse.com',
          '',
        );
        setPrestationsEN(await api.get(url));
      })();
    }
  }, []);

  const executeScroll = (ref) => {
    window.scrollTo({
      behavior: 'smooth',
      top: ref.current.offsetTop - 80,
    });
  };

  return (
    <Box bg="transparent" flexDirection="row" my={7} {...rest}>
      <StyledBox mr={{ xs: 24, lg: 12 }}>
        <Box bg="gray.lightest" borderRadius="sm" p={5}>
          <Heading level={5} color="black">
            {t('category')}
          </Heading>
          {prestationsToDisplay &&
            prestationsToDisplay.map(({ nom }, index) => (
              <Link
                key={index}
                color={index === onScreen ? 'primary.main' : 'black'}
                mb={2}
                onClick={() => executeScroll(refs[index])}>
                {nom}
              </Link>
            ))}
          <Link
            fontWeight="bold"
            color="black"
            mt={4}
            mb={2}
            onClick={() => setTab(tab === '1' ? '2' : '1')}
            after={<AngleRight16 />}
            animation>
            {t('header.texts.3')} {tab === '1' ? t('scooter') : t('bike')}
          </Link>
        </Box>
        <FranceOnly>
          <Text mt={2}>
            {t('categoriesandprices.text')}
            <StyledImg src="/static/img/logos/axa.png" alt="AXA assurance" />
          </Text>
        </FranceOnly>
      </StyledBox>
      <Box style={{ flex: 3 }}>
        <Input
          before={<Search24 />}
          placeholder={t('search')}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
        {search.length ? (
          <PricesRows prestation={prestationsResearchDisplay} />
        ) : (
          prestationsToDisplay.map((prestation, index) => (
            <div key={prestation.id} ref={refs[index]}>
              <PricesRows key={prestation.id} prestation={prestation} />
            </div>
          ))
        )}
      </Box>
    </Box>
  );
};

export default CategoriesAndPrices;
