import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space, layout } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 63 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M14.038.417a1.5 1.5 0 00-2.121.045l-11.5 12a1.5 1.5 0 002.166 2.076l11.5-12a1.5 1.5 0 00-.045-2.121zM32.996 43.896a1.5 1.5 0 00-1.6-1.392l-16.58 1.149a1.5 1.5 0 10.207 2.993l16.58-1.15a1.5 1.5 0 001.393-1.6zM15.777 85.795a1.5 1.5 0 11-1.787 2.41L.641 78.302a1.5 1.5 0 111.787-2.409l13.35 9.902z"
      fill="#1D1D1D"
    />
  </svg>
);

const SIZE = 64;
const CAN_SET_COLOR = true;
const SvgPopRight = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space};
  ${layout};
`;
export default SvgPopRight;
