import * as api from '../utils/api';
import { to2Int, parse_all_shops } from '../utils/utils.jsx';
import { RESET_DIAG } from './diag.jsx';

export const SET_ADDRESS = 'SET_ADDRESS';
export const RESET_COURSE = 'RESET_COURSE';
export const SET_CODEPROMO = 'SET_CODEPROMO';
export const SET_POSITION_INPUT = 'SET_POSITION_INPUT';
export const SET_POSITION_LOADING = 'SET_POSITION_LOADING';
export const TOGGLE_PB_ID = 'TOGGLE_PB_ID';
export const SET_PRODUCT_ID = 'SET_PRODUCT_ID';
export const SET_BIKE_DETAILS = 'SET_BIKE_DETAILS';
export const UPDATE_BIKES = 'UPDATE_BIKES';
export const UPDATE_BIKE_DESCRIPTION = 'UPDATE_BIKE_DESCRIPTION';
export const ADD_NEW_BIKE = 'ADD_NEW_BIKE';
export const SET_COURSE_DETAILS = 'SET_COURSE_DETAILS';
export const SELECT_DATE = 'SELECT_DATE';
export const SET_FIXED = 'SET_FIXED';
export const SET_FIXER_ID = 'SET_FIXER_ID';
export const SET_FIXER_CODE = 'SET_FIXER_CODE';
export const SET_PHOTOS = 'SET_PHOTOS';
export const RESET_PHOTOS = 'RESET_PHOTOS';
export const SET_AVAILABLE = 'SET_AVAILABLE';
export const PUSH_SLOT = 'PUSH_SLOT';
export const POP_SLOT = 'POP_SLOT';
export const SET_SLOT = 'SET_SLOT';
export const RESET_SLOTS = 'RESET_SLOTS';
export const SET_PLACE_ID = 'SET_PLACE_ID';
export const RESET_POSITION_INPUT = 'RESET_POSITION_INPUT';
export const SET_SHOPS = 'SET_SHOPS';
export const SET_SELECTED_SHOP = 'SET_SELECTED_SHOP';
export const SET_BOOKING_UUID = 'SET_BOOKING_UUID';
export const SET_IS_IN_ZONE = 'SET_IS_IN_ZONE';

export function setPositionInput(input) {
  return async (dispatch) => {
    dispatch({
      type: SET_POSITION_INPUT,
      position: input,
    });
  };
}

export function resetPositionInput() {
  return (dispatch) => {
    dispatch({
      type: RESET_POSITION_INPUT,
      payload: {
        positionError: '',
        placeId: '',
        positionInput: '',
        position: '',
      },
    });
  };
}
export function resetSlots() {
  return (dispatch) => {
    dispatch({ type: RESET_SLOTS });
  };
}

// sets the position+error without verifying if the address is ok
export function setPosition(input, error = '') {
  return async (dispatch, getState) => {
    if (getState().course.position !== input) {
      dispatch({
        type: RESET_COURSE,
      });
    }

    dispatch({
      type: SET_ADDRESS,
      position: input,
      error,
    });
  };
}
export function setShops({ shops }) {
  return async (dispatch) => {
    dispatch({
      type: SET_SHOPS,
      shops,
    });
  };
}

// checks if the provided address is ok and set the position/error
export function setAddress(input) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_POSITION_LOADING,
      loading: true,
    });
    dispatch(setSelectedShop(null));

    const data = {
      product_id: [getState().course.product_id],
      with_shops: true,
    };

    if (getState().course.placeId) {
      data.place_id = getState().course.placeId;
    } else {
      data.position = input;
    }

    const isInZone = await api.get('/zones/availability', data);
    const { shops } = isInZone;

    if (shops) {
      const parsed = parse_all_shops(shops);
      dispatch(setShops({ shops: parsed }));
    } else {
      dispatch(setShops({ shops: [] }));
    }

    let isPositionOk = false;
    const position = isInZone.formatted_address || input;

    dispatch(setPositionInput(position));

    if (isInZone.is_in_zone || (shops && shops.length > 0)) {
      dispatch(setPosition(position, ''));
      dispatch({
        type: 'SET_ZONE_ID',
        zone_id: isInZone.zone_id,
      });
      dispatch({
        type: SET_IS_IN_ZONE,
        payload: isInZone.is_in_zone,
      });
      isPositionOk = true;
    } else if (isInZone.is_in_zone === false && !shops) {
      dispatch(setPosition('', 'error.address'));
      dispatch({
        type: SET_IS_IN_ZONE,
        payload: isInZone.is_in_zone,
      });
      isPositionOk = false;
    } else if (isInZone.error_message) {
      dispatch(setPosition('', isInZone.error_message));
    } else {
      dispatch(setPosition('', 'error.address'));
    }

    dispatch({
      type: SET_POSITION_LOADING,
      loading: false,
    });

    return isInZone;
  };
}

export function togglePBID(id) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_PB_ID,
      id,
    });
  };
}
export function setPlaceId(id) {
  return (dispatch) => {
    dispatch({
      type: SET_PLACE_ID,
      id,
    });
  };
}

export function setProductId(product_id) {
  return async (dispatch, getState) => {
    if (product_id !== getState().course.product_id) {
      dispatch({
        type: RESET_COURSE,
      });
      dispatch({
        type: RESET_DIAG,
      });
    }

    dispatch({
      type: SET_PRODUCT_ID,
      product_id,
    });

    const { positionInput } = getState().course;

    if (positionInput) {
      dispatch(setAddress(positionInput));
    }
  };
}

export function setBikeDetails(bike_details) {
  return (dispatch) => {
    dispatch({
      type: SET_BIKE_DETAILS,
      bike_details,
    });
  };
}

export function setSelectedShop(shop) {
  return (dispatch, getState) => {
    if (getState().course.selectedShop?.id !== shop?.id) {
      dispatch({
        type: RESET_DIAG,
      });
    }
    dispatch({
      type: SET_SELECTED_SHOP,
      shop,
    });
  };
}

export function setLoading(bool) {
  return (dispatch) => {
    dispatch({
      type: SET_POSITION_LOADING,
      loading: bool,
    });
  };
}

export function setCourseDetails(course_details) {
  return (dispatch) => {
    dispatch({
      type: SET_COURSE_DETAILS,
      course_details,
    });
  };
}

export function addNewBike(newBike) {
  return {
    type: ADD_NEW_BIKE,
    payload: newBike,
  };
}

export function updateBikeDescription(index, description) {
  return {
    type: UPDATE_BIKE_DESCRIPTION,
    payload: { index, description },
  };
}

export function updateBikesAndCourseDetails(updatedBikes) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_BIKES,
      bikes: updatedBikes,
    });
  };
}

export function selectDate(date) {
  return (dispatch) => {
    dispatch({
      type: SELECT_DATE,
      date,
    });
  };
}

export function setFixerFromCode(code) {
  return async (dispatch) => {
    const fixer = await api.get('/fixers/codes', { code });

    if (fixer && fixer.id) {
      dispatch({
        type: SET_FIXER_CODE,
        fixerCode: code,
      });
      dispatch({
        type: SET_FIXER_ID,
        payload: fixer.id,
      });
    }
  };
}
export function setFixed(fixed) {
  return (dispatch) => {
    dispatch({
      type: SET_FIXED,
      fixed,
    });
  };
}

export function setFixerId(id) {
  return (dispatch) => {
    dispatch({
      type: SET_FIXER_ID,
      payload: id,
    });
  };
}

export function setIsActiveFavorite(boolean) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_ACTIVE_FAVORITE,
      payload: boolean,
    });
  };
}

export function setPhotos(photos) {
  return (dispatch) => {
    dispatch({
      type: SET_PHOTOS,
      photos,
    });
  };
}
export function resetPhotos() {
  return (dispatch) => {
    dispatch({
      type: RESET_PHOTOS,
    });
  };
}

export function setAvailable(val, minMax) {
  minMax = minMax.toLowerCase();
  if (!~['min', 'max'].indexOf(minMax)) minMax = 'min';

  return (dispatch, getState) => {
    if (minMax === 'min') {
      if (getState().course.available_max < val) {
        dispatch({
          type: SET_AVAILABLE,
          val,
          minMax: 'max',
        });
      }
    } else if (getState().course.available_min > val) {
      dispatch({
        type: SET_AVAILABLE,
        val,
        minMax: 'min',
      });
    }

    dispatch({
      type: SET_AVAILABLE,
      val,
      minMax,
    });
  };
}

export function pushSlot(available_min, available_max) {
  return (dispatch, getState) => {
    dispatch({
      type: PUSH_SLOT,
      available_min,
      available_max,
    });
  };
}

export function popSlot(available_min, available_max) {
  return (dispatch, getState) => {
    dispatch({
      type: POP_SLOT,
      available_min,
      available_max,
    });
  };
}

export function toggleSlot(available_min, available_max) {
  return (dispatch, getState) => {
    const state = getState();

    if (
      state.course.slots.find(
        (s) =>
          s.available_min === available_min &&
          s.available_max === available_max,
      )
    ) {
      dispatch({
        type: POP_SLOT,
        available_min,
        available_max,
      });
    } else {
      dispatch({
        type: PUSH_SLOT,
        available_min,
        available_max,
      });
    }
  };
}

export function setSlot(available_min, available_max) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_SLOT,
      available_min,
      available_max,
    });
  };
}

export function checkIfMinMaxInBounds() {
  return (dispatch, getState) => {
    const { available_min, available_max } = getState().course;
    const { minMax } = getState().configs;

    if (available_min) {
      let comparableMin = available_min.split(':');

      comparableMin =
        parseInt(comparableMin[0]) + parseInt(comparableMin[1]) / 30;
      if (comparableMin < minMax.min) {
        const val = `${to2Int(minMax.min)}:00`;
        dispatch(setAvailable(val, 'min'));
      }
    }

    if (available_max) {
      let comparableMax = available_max.split(':');

      comparableMax =
        parseInt(comparableMax[0]) + parseInt(comparableMax[1]) / 30;

      if (comparableMax > minMax.max) {
        const val = `${to2Int(minMax.max)}:00`;
        dispatch(setAvailable(val, 'max'));
      }
    }
  };
}

export function setBookingUUID(bookingUUID) {
  return (dispatch) => {
    dispatch({
      type: SET_BOOKING_UUID,
      bookingUUID,
    });
  };
}
