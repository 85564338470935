import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import useNavigatorPosition from '../../hooks/useNavigatorPosition';
import AddressInput from '../shared/main-form/AddressInput';
// Components
import Box from '../shared/atoms/Box';
import Row from '../shared/atoms/Row';
import Container from '../shared/atoms/Container';
import Heading from '../shared/atoms/Heading';
import Link from '../shared/atoms/Link';
import Text from '../shared/atoms/Text';
import SectionBackground from '../shared/molecules/SectionBackground.jsx';
// Icons
import SvgAngleRight16 from '../icons/AngleRight16';
import SvgPin24 from '../icons/Pin24';
import PartenaireContext from '../../contexts/PartenaireContext';
// Hook
import useDeviceWidth from '../../hooks/useDeviceWidth';

const MapRoulezjeunesseImg = styled.img`
  max-width: 100%;
`;

const WeFixEverywhere = ({
  mapHasBackground = true,
  giftcard = false,
  ...props
}) => {
  const { t } = useTranslation();
  const { positionInput } = useSelector((s) => s.course);
  const { isError } = useNavigatorPosition();
  const { partenaire, cobranding } = useContext(PartenaireContext);
  const { isDesktop } = useDeviceWidth();

  // TODO to be removed
  return <div />;

  return (
    <SectionBackground {...props}>
      <Container>
        <Row gap={72} reverse={true} md>
          <Box
            width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}
            pr={{ xs: 0, lg: 5 }}
            alignItems="center">
            <MapRoulezjeunesseImg
              src={
                mapHasBackground
                  ? '/static/img/pictos/map-cyclofix-7.png'
                  : '/static/img/pictos/map-cyclofix-nobg.png'
              }
              alt="Carte de France des villes couvertes par le service RoulezJeunesse"
            />
          </Box>
          <Box
            justifyContent="center"
            width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}
            mb={{ xs: 6, lg: 0 }}
            pl={{ xs: 0, lg: 5 }}>
            <Heading level={2} color="black" mb={4}>
              {t('wefixeverywhere.heading')}
            </Heading>
            <Text mb={4}>
              <Text as="span" color="primary.main">
                <b>Chez moi:</b>
              </Text>{' '}
              {t('wefixeverywhere.texts.1')}
            </Text>
            <Text mb={4}>
              <Text as="span" color="primary.main">
                <b>En atelier:</b>
              </Text>{' '}
              {t('wefixeverywhere.texts.2')}
            </Text>
          </Box>
        </Row>
      </Container>
    </SectionBackground>
  );
};

export default WeFixEverywhere;
