import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.004 38.618C9.874 32.579.762 23.775.762 15.454.762 7.133 7.589.382 16.004.382c8.416 0 15.234 6.75 15.234 15.072 0 8.321-9.112 17.125-15.234 23.164z"
      fill="#d5ff4f"
    />
    <ellipse cx={16} cy={15.995} rx={6} ry={5.995} fill="#fff" />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgPingeoloc60 = styled(SVG)`
  min-height: ${(p) => p.size || SIZE}px;
  min-width: ${(p) => p.size || SIZE}px;
  max-height: ${(p) => p.size || SIZE}px;
  max-width: ${(p) => p.size || SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPingeoloc60;
