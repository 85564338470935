import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Text from './shared/atoms/Text';
import Box from './shared/atoms/Box';
import Card from './shared/atoms/Card';
import Link from './shared/atoms/Link';
import SVGMessage from './icons/Message24';
import SVGTimes from './icons/Times24';

import { ToastCtx } from '../contexts/ToastContext';

const BorderedMessageIcon = ({
  bg = 'primary.lighter',
  size = '2em',
  ...props
}) => (
  <Card
    p={2}
    alignItems="center"
    justifyContent="center"
    borderRadius="full"
    height={size}
    width={size}
    bg={bg}
    {...props}>
    <SVGMessage color="primary.main" />
  </Card>
);

const StyledToastContainer = styled(Box)`
  box-sizing: border-box;
  z-index: 999999; // lol
  top: 6rem;
  right: 1.5rem;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
  @keyframes toast-in-right {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    top: 0;
    right: 0;
    left: 0;
  }
`;

const ToastComponent = styled(Box)`
  background: #fff;
  transition: 0.3s ease;
  position: relative;
  pointer-events: auto;
  margin: 0 0 6px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  width: 310px;
  border-radius: ${(p) => p.theme.radii.md};
  box-shadow: ${(p) => p.theme.shadows.sm};
  &:hover {
    box-shadow: ${(p) => p.theme.shadows.md};
    cursor: pointer;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    width: 100%;
  }
`;

const ToastDescription = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ToastContainer = ({ autoDelete = true, dismissTime = 10000 }) => {
  const history = useHistory();
  const { toastList, deleteToast } = useContext(ToastCtx);

  const onToastClick = (id, to) => {
    history.push(to);
    deleteToast(id);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length) {
        deleteToast(toastList[0].id);
      }
    }, dismissTime);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [toastList, autoDelete, dismissTime]);

  return (
    <StyledToastContainer position={{ xs: 'fixed', lg: 'absolute' }}>
      {toastList &&
        toastList.map((toast) => (
          <ToastComponent key={toast.id} flexDirection="row">
            <Box mr={3}>
              <BorderedMessageIcon />
            </Box>
            <Box width="100%">
              <Box flexDirection="row" justifyContent="space-between">
                <Text variant="large" color={toast.type} mb={1}>
                  {toast.title}
                </Text>
                <SVGTimes
                  size="xs"
                  color={'gray.darker'}
                  onClick={() => deleteToast(toast.id)}
                  p={0}
                />
              </Box>
              <ToastDescription variant="small" mb={3}>
                {toast.description}
              </ToastDescription>
              {toast.to && (
                <Box
                  bg="primary.lighter"
                  p={2}
                  borderRadius="xs"
                  alignItems="center">
                  <Link
                    color={toast.type}
                    onClick={() => onToastClick(toast.id, toast.to)}>
                    {toast.btnLabel}
                  </Link>
                </Box>
              )}
            </Box>
          </ToastComponent>
        ))}
    </StyledToastContainer>
  );
};

ToastContainer.propTypes = {
  autoDelete: PropTypes.bool,
  dismissTime: PropTypes.number,
};

export default ToastContainer;
