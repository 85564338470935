import React from 'react';
import { getLang } from '../utils/utils';

export default function FranceOnly({ children }) {
  if (getLang().lang !== 'fr') {
    return <React.Fragment />;
  }

  return children;
}
