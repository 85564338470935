import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Box from '../../shared/atoms/Box';
import Heading from '../../shared/atoms/Heading';
import Text from '../../shared/atoms/Text';
import Input from '../../shared/atoms/Input';
import Button from '../../shared/atoms/Button';
import IconButton from '../../shared/atoms/IconButton';
import GiftCardAmount from './GiftCardAmount';
// Icons
import ArrowRight24 from '../../icons/ArrowRight24';
import ArrowLeft24 from '../../icons/ArrowLeft24';

const AbsolutePrice = styled(Heading)`
  position: absolute;
  right: 35px;
  top: 20px;
`;
const AbsoluteCurrency = styled(Text)`
  position: absolute;
  right: 21px;
  top: 22px;
`;
const BuyGiftCardCustomization = ({ giftcard, setGiftcard, nextStep }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box flex={3} mr={{ xs: 0, lg: 9 }}>
        <Box
          borderColor="gray.light"
          borderWidth="1px"
          borderRadius="sm"
          borderStyle={{ xs: null, md: 'solid' }}
          p={{ xs: 0, md: 6 }}>
          <Box flexDirection="row" alignItems="center">
            <Box mr={4}>
              <IconButton
                color="gray.lightest"
                iconButton
                noFocusStyle
                style={{ padding: 8 }}
                icon={<ArrowLeft24 />}
                onClick={
                  giftcard.giftcardChoice === 1
                    ? () =>
                        setGiftcard({
                          ...giftcard,
                          giftcardChoice: 6,
                        })
                    : () =>
                        setGiftcard({
                          ...giftcard,
                          giftcardChoice: giftcard.giftcardChoice - 1,
                        })
                }
              />
            </Box>
            <Box position="relative">
              <img
                src={`/static/img/pictos/giftcard/gift-card-${giftcard.giftcardChoice}.png`}
              />
              <AbsolutePrice
                level={3}
                color={giftcard.giftcardChoice === 1 ? 'white' : 'black'}>
                {giftcard.customAmount
                  ? giftcard.customAmount
                  : giftcard.amount}
              </AbsolutePrice>
              <AbsoluteCurrency
                fontSize="lg"
                color={giftcard.giftcardChoice === 1 ? 'white' : 'black'}
                fontWeight={2}>
                €
              </AbsoluteCurrency>
            </Box>
            <Box ml={4}>
              <IconButton
                iconButton
                noFocusStyle
                style={{ padding: 8 }}
                color="gray.lightest"
                icon={<ArrowRight24 />}
                onClick={
                  giftcard.giftcardChoice === 6
                    ? () =>
                        setGiftcard({
                          ...giftcard,
                          giftcardChoice: 1,
                        })
                    : () =>
                        setGiftcard({
                          ...giftcard,
                          giftcardChoice: giftcard.giftcardChoice + 1,
                        })
                }
              />
            </Box>
          </Box>
          <GiftCardAmount
            giftcard={giftcard}
            setGiftcard={setGiftcard}
            customInputWidth={90}
          />
        </Box>
      </Box>
      <Box flex={2}>
        <Heading level={6} mb={4} mt={{ xs: 5, lg: 0 }}>
          {t('beneficiary')} :
        </Heading>
        <Input
          label={t('firstname')}
          importantLabel
          placeholder={'Jean-Michel'}
          mb={4}
          value={giftcard.recipientName}
          error={giftcard.errorName}
          onChange={(e) =>
            setGiftcard({
              ...giftcard,
              recipientName: e.target.value,
              errorName: false,
            })
          }
        />
        <Input
          label={t('giftcardcustomization.message.label')}
          placeholder={t('giftcardcustomization.message.placeholder')}
          as="textarea"
          rows="3"
          maxLength="450"
          mb={5}
          value={giftcard.message}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          onChange={(e) =>
            setGiftcard({ ...giftcard, message: e.target.value })
          }
        />
        <Button fullWidth onClick={() => nextStep()}>
          {t('placeorder')}
        </Button>
      </Box>
    </>
  );
};

export default BuyGiftCardCustomization;
