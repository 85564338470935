import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import Heading from '../shared/atoms/Heading';
import Box from '../shared/atoms/Box';
import Text from '../shared/atoms/Text';
import Container from '../shared/atoms/Container';

import SectionBackground from '../shared/molecules/SectionBackground';
import ThumbUp32 from '../icons/ThumbUp32';
import HandShake32 from '../icons/HandShake32';
import Support32 from '../icons/Support32';
import Checklist32 from '../icons/Checklist32';
import { getLang } from '../../utils/utils';
import FranceOnly from '../FranceOnly';

const AxaLogo = styled.img`
  position: relative;
  top: -3px;
  left: 4px;
  height: 24px;
  width: auto;
`;

const FixerPillImg = styled.img`
  max-width: 100%;
  ${(p) => p.theme.mediaQueries.tablet} {
    max-width: 420px;
  }
  ${(p) => p.theme.mediaQueries.desktop} {
    max-width: 100%;
  }
`;

const Item = ({ title, content, icon, axaIcon = false, t }) => (
  <Box flexDirection="row" mb={5}>
    <Box display="block" mr={2}>
      {icon}
    </Box>
    <Box>
      <Heading level={5} mt={1} color="black" mb={2}>
        {title}
      </Heading>
      <Text>
        {content}
        {axaIcon && (
          <FranceOnly>
            <AxaLogo
              src="/static/img/logos/axa.png"
              alt={t('gainserenity.text.1')}
            />
          </FranceOnly>
        )}
      </Text>
    </Box>
  </Box>
);

export default function GainSerenity({ ...props }) {
  const { t } = useTranslation();
  return (
    <SectionBackground {...props}>
      <Container>
        <Box alignItems="center" flexDirection={{ xs: 'column', lg: 'row' }}>
          <Box
            width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}
            mb={{ xs: 6, lg: 0 }}
            pl={{ xs: 0, lg: 5 }}>
            <Heading level={2} color="black" mb={5}>
              {t('gainserenity.heading')}
            </Heading>
            <Box>
              <Item
                icon={<ThumbUp32 />}
                title={t('gainserenity.titles.1')}
                content={t('gainserenity.texts.1')}
                t={t}
              />
              <Item
                icon={<Checklist32 />}
                title={t('gainserenity.titles.2')}
                content={t('gainserenity.texts.2')}
                t={t}
              />
              <Item
                axaIcon
                icon={<Support32 />}
                title={t('gainserenity.titles.3')}
                content={t('gainserenity.texts.3')}
                t={t}
              />
              <Item
                icon={<HandShake32 />}
                title={t('gainserenity.titles.4')}
                content={t('gainserenity.texts.4')}
                t={t}
              />
            </Box>
          </Box>
          <Box
            width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}
            pl={{ xs: 0, lg: 7 }}
            alignItems="center">
            <LazyLoad>
              <FixerPillImg
                src="/static/img/photos/map-reservation.png"
                alt={t('gainserenity.texts.5')}
              />
            </LazyLoad>
          </Box>
        </Box>
      </Container>
    </SectionBackground>
  );
}
