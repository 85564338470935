import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 11.652c0-1.85 0-2.775.388-3.303a2 2 0 011.426-.808c.653-.06 1.446.415 3.032 1.367l.58.348c1.51.905 2.263 1.358 2.52 1.943a2 2 0 010 1.602c-.257.585-1.01 1.038-2.52 1.943l-.58.348c-1.586.952-2.379 1.428-3.032 1.367a2 2 0 01-1.426-.808c-.388-.528-.388-1.453-.388-3.303v-.696zm3.817-1.03l.58.349c.789.473 1.26.758 1.578.99l.051.039a3.567 3.567 0 01-.051.039c-.318.232-.79.517-1.577.99l-.58.348c-.828.496-1.33.795-1.706.976a3.85 3.85 0 01-.065.031 4.617 4.617 0 01-.007-.071c-.038-.416-.04-1-.04-1.965v-.696c0-.965.002-1.55.04-1.965l.007-.071.065.03c.376.182.878.48 1.705.977z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-2 0a8 8 0 11-16 0 8 8 0 0116 0z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgPlay24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPlay24;
