import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
// Components
import Box from '../atoms/Box';
import Text from '../atoms/Text';
import Radio from '../atoms/Radio';
import Link from '../atoms/Link';
import Button from '../atoms/Button';
import CardsSkeleton from './CardsSkeleton';

const Container = styled(Box)`
  border: ${(p) =>
    p.selected
      ? `2px solid ${p.theme.colors.blue.electric}`
      : `1px solid ${p.theme.colors.gray.light}`};
  ${(p) =>
    p.card &&
    css`
      cursor: pointer;
    `}
`;

const StyledImg = styled.img`
  height: 32px;
  width: 48px;
`;

const chooseImageCard = (name) => {
  switch (name) {
    case 'visa':
      return '/static/img/logos/Visa.png';
    case 'amex':
      return '/static/img/logos/Amex.png';
    case 'mastercard':
      return '/static/img/logos/Mastercard.png';
    case 'maestro':
      return '/static/img/logos/Maestro.png';
    default:
      return '/static/img/logos/CB.png';
  }
};

const Cards = ({
  cards = [],
  card,
  _setCard = () => { },
  client,
  deleteCard = () => { },
  setDefaultCard = () => { },
  loading = false,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {loading ? (
        <>
          {cards.map((index) => (
            <CardsSkeleton key={index} />
          ))}
        </>
      ) : (
        <>
          {cards.map((cb) => (
            <Container
              key={cb.id}
              borderRadius="xs"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              onClick={() => _setCard(cb.id)}
              selected={card === cb.id}
              card={card}
              p={4}
              mb={4}>
              <Box width="100%">
                <Box
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%">
                  <Box flexDirection="row" alignItems="center">
                    <StyledImg
                      src={chooseImageCard(cb.card.brand)}
                      alt={cb.card.brand}
                    />
                    <Box justifyContent="center" ml={4}>
                      <Box flexDirection="row" alignItems="center">
                        <Text mr={1}>•••• •••• •••• </Text>
                        <Text variant="small">{cb.card.last4}</Text>
                      </Box>
                    </Box>
                  </Box>
                  {card ? (
                    <Radio key={cb.id} checked={card === cb.id} />
                  ) : (
                    <Button
                      variant="neutral"
                      color="primary.darkest"
                      onClick={() => deleteCard(cb.id)}>
                      {t('delete')}
                    </Button>
                  )}
                </Box>
                {!card && !cb.default && (
                  <Link
                    color="blue.electric"
                    mt={2}
                    onClick={() => setDefaultCard(cb.id)}>
                    {t('card.text')}
                  </Link>
                )}
              </Box>
            </Container>
          ))}
        </>
      )}
    </>
  );
};

export default Cards;
