import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { space } from 'styled-system';
import Text from '../atoms/Text';
import Box from '../atoms/Box';
// Icons
import Check16 from '../../icons/Check16';
import Plus16 from '../../icons/Plus16';

const AbsolutePoint = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: -3px;
  left: -3px;
  background-color: ${(p) => p.theme.colors.blue.electric};
  box-shadow: 0 0 0 2px white;
  ${space}
`;

const AbsoluteCheck16 = styled(Check16)`
  cursor: pointer;
  position: absolute;
  right: 8px;
`;

const AbsolutePlus16 = styled(Plus16)`
  cursor: pointer;
  position: absolute;
  right: 8px;
`;

export default function DisplayBoxHour({
  hour,
  isSelected,
  slotsUsed,
  selectedSlot,
  momentHour0,
  disabled,
  ...props
}) {
  return (
    <Box {...props} justifyContent="center">
      <Text
        color={
          isSelected ? 'blue.electric' : disabled ? 'gray.darker' : 'black'
        }>
        {isSelected
          ? moment(selectedSlot).format('LT')
          : momentHour0.format('LT')}
      </Text>
      {slotsUsed && <AbsolutePoint />}
      {!disabled &&
        (isSelected ? (
          <AbsoluteCheck16 color="blue.electric" />
        ) : (
          <AbsolutePlus16 />
        ))}
    </Box>
  );
}
