import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Autosuggest from 'react-autosuggest';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { debounce } from '../../../utils/utils';
import * as api from '../../../utils/api.jsx';
import {
  setAddress,
  setPosition,
  setPositionInput,
  setPlaceId,
} from '../../../actions/course.jsx';

import Box from '../atoms/Box.jsx';
import Text from '../atoms/Text.jsx';
import SvgGeoloc24 from '../../icons/Geoloc24.jsx';
import SvgPin24 from '../../icons/Pin24.jsx';

import useNavigatorPosition from '../../../hooks/useNavigatorPosition';
import PartenaireContext from '../../../contexts/PartenaireContext';

const hover = (p) =>
  css`
    background-color: ${p.theme.colors.gray.lightest};
    border-radius: ${p.theme.radii.xxs};
  `;

const AutoPositionContainer = styled(Box)`
  padding: 4px;
  cursor: pointer;
  &:hover {
    ${hover}
  }
`;

const AutocompleteContainer = styled.div`
  ${(p) =>
    css`
      .react-autosuggest__container {
        border-radius: ${p.theme.radii.xxs};
      }
      .react-autosuggest__input {
        padding: 0;
        font-size: ${p.theme.fontSizes.md};
        font-weight: 500;
      }
      .react-autosuggest__input--focused {
        padding: 0 !important;
      }
      .react-autosuggest__suggestions-container {
        margin-top: ${p.theme.sizes.sm};
        border-radius: ${p.theme.radii.xs};
        background-color: white;
        overflow: hidden;
        box-shadow: ${p.theme.shadows.md};
        font-size: ${p.theme.fontSizes.md};
        font-weight: 500;
        padding: 8px;
        position: absolute;
        left: ${p.withAdornment ? '-3.5rem' : '-43px'};
        right: ${p.withAdornment ? '-0.75rem' : '-1.5rem'};
        width: auto;
        min-width: 16rem;
        ${p.theme.mediaQueries.tablet} {
          left: ${p.withAdornment ? '-2.75rem' : '-43px'};
          right: 0;
          min-width: 32rem;
        }
      }
      .react-autosuggest__suggestion {
        padding: 0;
      }
      /* .react-autosuggest__suggestion:not(:first-child):not(:last-child) {
        margin: 4px 0;
      } */
      .react-autosuggest__suggestion--highlighted,
      .react-autosuggest__suggestion:hover {
        ${hover}
      }
    `}
  ${(p) =>
    p.error &&
    css`
      .react-autosuggest__container {
        background-color: ${p.theme.colors.primary.pale};
        box-shadow: 0 0 0 1px ${p.theme.colors.primary.darkest};
      }
    `}
`;

function SuggestionsContainer({ containerProps, t, children }) {
  const { isError, setNavigatorPosition, position } = useNavigatorPosition();
  const dispatch = useDispatch();
  const loading = useSelector((s) => s.course.positionLoading);

  const setAutoPosition = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!position) {
      setNavigatorPosition();
    } else {
      dispatch(setPosition(position));
      dispatch(setAddress(position));
    }
  };

  return (
    <div {...containerProps}>
      <AutoPositionContainer
        flexDirection="row"
        alignItems="center"
        onClick={setAutoPosition}>
        <Box bg="gray.lightest" p={2} borderRadius="xxs" mr={3}>
          <SvgGeoloc24 />
        </Box>
        <Box>
          {position ? (
            <div onClick={setAutoPosition}>
              <Text color="black">
                <b>{t('autocomplete.texts.1')}</b>
              </Text>
              <Text variant="caption" color="gray.darkest">
                <b>{position}</b>
              </Text>
            </div>
          ) : (
            <Text color="black">
              {loading ? (
                <b>{t('autocomplete.texts.2')}</b>
              ) : (
                <b>{t('autocomplete.texts.3')}</b>
              )}
            </Text>
          )}
          {isError && (
            <Text color="violet.dark" variant="caption">
              <b>{t('autocomplete.texts.4')}</b>
            </Text>
          )}
        </Box>
      </AutoPositionContainer>
      {children}
    </div>
  );
}

const Suggestion = ({ item }) => {
  if (!item.description) {
    return null;
  }
  return (
    <Box flexDirection="row" alignItems="center" p={2}>
      <Box bg="gray.lightest" p={2} borderRadius="xxs" mr={3}>
        <SvgPin24 />
      </Box>
      <b>{item.description}</b>
    </Box>
  );
};

const PositionAutocomplete = ({
  autoSelect,
  withAdornment = false,
  placeholder,
  error = false,
}) => {
  const { t } = useTranslation();
  const [suggestions, setSuggestions] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { partenaire, cobranding } = useContext(PartenaireContext);

  const {
    positionInput,
    positionError,
    positionLoading,
    position,
  } = useSelector((s) => s.course);
  const [displaySuggested, setDisplaySuggested] = useState(false);
  const [selectInput, setSelectInput] = useState(false);

  const onChange = (event, { newValue }) => {
    event.preventDefault();
    dispatch(setPosition(''));
    dispatch(setPositionInput(newValue));
    setDisplaySuggested(false);
  };

  const onSuggestionSelected = async (event, { suggestion, method }) => {
    if (method === 'enter') {
      event.preventDefault();
    }
    dispatch(setPlaceId(suggestion.place_id));
    const isValid = await dispatch(setAddress(suggestion.description));
    if (isValid === 0) {
      history.push('/reparation');
    } else if (autoSelect && !selectInput) {
      setSelectInput(true);
    }
  };

  const fetch = async (input) => {
    setDisplaySuggested(false);
    const response = await api.get('/zones/addresses', { input });
    if (response.predictions) {
      setSuggestions(response.predictions);
    } // else, maybe an API throttle
  };

  const debounceFetch = React.useCallback(
    debounce((query) => fetch(query), 700),
    [],
  );

  const onSuggestionsFetchRequested = ({ value }) => {
    if (value && value.length >= 3) {
      debounceFetch(value);
    } else {
      setSuggestions([]);
    }
  };

  const getSuggestionValue = (item) => item.description;

  const shouldRenderSuggestions = () => !position && !displaySuggested;

  const onSuggestionsClearRequested = () => setSuggestions([]);

  useEffect(() => {
    if (positionLoading) {
      onSuggestionsClearRequested();
      setDisplaySuggested(true);
    }
  }, [positionLoading]);

  const renderSuggestionsContainer = (p) => {
    return <SuggestionsContainer autoSelect={autoSelect} {...p} t={t} />;
  };
  const renderSuggestion = (item) => <Suggestion item={item} />;

  const inputProps = {
    placeholder: placeholder || t('autocomplete.texts.5'),
    onChange,
    value: positionInput,
    'data-testid': 'google-map-address-input',
  };

  useEffect(() => {
    if (!positionError.length && selectInput && !positionLoading) {
      setSelectInput(false);

      if (partenaire && !cobranding) {
        window.location.href = `https://www.roulezjeunesse.com/reparation?position=${position}`;
      } else {
        history.push('/reparation');
      }
    }
  }, [positionLoading]);

  useEffect(() => {
    if (!positionError.length && selectInput && positionLoading) {
      if (partenaire && !cobranding) {
        window.location.href = `https://www.roulezjeunesse.com/reparation?position=${position}`;
      } else {
        history.push('/reparation');
      }

      setSelectInput(false);
    }
  }, [positionError]);

  return (
    <AutocompleteContainer
      withAdornment={withAdornment}
      error={error}
      onClick={() => setDisplaySuggested(false)}>
      <Autosuggest
        suggestions={[{ description: '' }, ...suggestions]}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        renderSuggestionsContainer={renderSuggestionsContainer}
        getSuggestionValue={getSuggestionValue}
        // alwaysRenderSuggestions={!position}
        shouldRenderSuggestions={shouldRenderSuggestions}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={inputProps}
      />
    </AutocompleteContainer>
  );
};

export default PositionAutocomplete;
