import { css } from 'styled-components';

export const size = {
  xs: '576px',
  sm: '768px',
  md: '992px',
  lg: '1280px',
};

const getMaxWidth = (s) => `${Number(s.replace('px', '') - 0.02)}px`;

export const device = {
  xs: `(max-width: ${getMaxWidth(size.xs)})`,
  sm: `(max-width: ${getMaxWidth(size.sm)})`,
  md: `(max-width: ${getMaxWidth(size.md)})`,
  lg: `(max-width: ${getMaxWidth(size.lg)})`,
};

export const svgColors = {
  green:
    'invert(47%) sepia(93%) saturate(563%) hue-rotate(124deg) brightness(97%) contrast(101%)',
  orange:
    'invert(36%) sepia(38%) saturate(5642%) hue-rotate(346deg) brightness(103%) contrast(101%)',
  blue:
    'invert(41%) sepia(85%) saturate(1326%) hue-rotate(209deg) brightness(101%) contrast(104%)',
  lightblue: 'rgba(56,111,255,0.75)',
  yellow:
    'invert(86%) sepia(71%) saturate(508%) hue-rotate(342deg) brightness(106%) contrast(104%)',
  purple:
    'invert(52%) sepia(64%) saturate(1046%) hue-rotate(214deg) brightness(96%) contrast(91%)',
  grey:
    'invert(95%) sepia(63%) saturate(208%) hue-rotate(177deg) brightness(101%) contrast(96%)',
  lightgrey:
    'invert(85%) sepia(0%) saturate(504%) hue-rotate(136deg) brightness(102%) contrast(93%)',
  darkgrey:
    'invert(12%) sepia(7%) saturate(2163%) hue-rotate(159deg) brightness(93%) contrast(83%)',
  coral:
    'invert(87%) sepia(99%) saturate(2794%) hue-rotate(301deg) brightness(100%) contrast(96%)',
  lightcoral:
    'invert(75%) sepia(72%) saturate(269%) hue-rotate(320deg) brightness(100%) contrast(105%)',
};

export const colors = {
  green: '#00be9b',
  orange: '#ED7F10',
  blue: '#5a81ff',
  lightblue: 'rgba(56,111,255,0.75)',
  yellow: '#fffc41',
  purple: '#9682eb',
  grey: '#f6f8fa',
  lightgrey: '#d7d7d7',
  darkgrey: '#252f35',
  coral: '#FAAA82',
  lightcoral: '#ffc5a8',
  primary: {
    main: '#d5ff4f',
    dark: '#EF2C12',
    light: '#FF6753',
    lighter: '#F6C8C0',
    lightest: '#F6DDD8',
  },
  secondary: {
    main: '#5a81ff',
    dark: '#164FE1',
    light: '#7999FE',
    lighter: '#C3D3FE',
    lightest: '#EBF1FF',
  },
  grays: {
    '1': '#31353F',
    '2': '#5B5E66',
    '3': '#83868C',
    '4': '#ADAEB2',
    '5': '#D2D5D9',
    '6': '#F0F3F7',
  },
  black: '#1D2029',
  white: '#ffffff',
  info: '#6666FF',
  success: '#10DA92',
  warning: '#FFD666',
  danger: '#FF3D6B',
  title: '#2e414b',
};

export const titles = {
  title1: css`
    font-size: 2em;
    line-height: 1.1em;
    font-weight: 600;
    font-family: 'LTSaeada', sans-serif;
  `,
  title2: css`
    font-size: 1.5em;
    line-height: 1.1em;
    font-weight: 600;
    font-family: 'LTSaeada', sans-serif;
  `,
  title3: css`
    font-size: 1.375em;
    line-height: 1.3em;
    font-family: 'LTSaeada', sans-serif;
  `,
  title4: css`
    font-size: 1.25em;
    line-height: 1.3em;
    font-family: 'LTSaeada', sans-serif;
  `,
};

export const shadows = {
  box: 'box-shadow: 2px 5px 12px -2px rgba(146,146,146,0.35);',
  none: 'box-shadow: 0;',
  xs: 'box-shadow: 0px 4px 8px 0px rgba(13, 32, 83, 0.05);',
  sm: 'box-shadow: 0px 4px 15px 0px rgba(13, 32, 83, 0.1);',
  md: 'box-shadow: 0px 8px 40px 0px rgba(13, 32, 83, 0.07);',
  lg: 'box-shadow: 0px 8px 40px 0px rgba(13, 32, 83, 0.1);',
};

export const radii = {
  none: '0',
  xs: '8px',
  sm: '12px',
  md: '16px',
  full: '50%',
};

export const spacing = {
  '1': '4px',
  '2': '8px',
  '3': '16px',
  '4': '32px',
  '5': '40px',
  '6': '48px',
  '7': '56px',
};

export const spacers = {
  none: '0',
  xs: '40px',
  sm: '80px',
  md: '104px',
  lg: '128px',
  xl: '152px',
};
