import React from 'react';
import styled, { css } from 'styled-components';
import {
  variant,
  flexbox,
  space,
  color,
  typography,
  layout,
} from 'styled-system';
import propTypes from '@styled-system/prop-types';

const textSize = variant({
  prop: 'variant',
  key: 'texts',
});

/**
 *
 * @param {string} variant caption | small | medium | large
 * @param {string} any any Styled System space, color, typography
 */
const StyledText = styled('p')`
  ${(p) =>
    css`
      text-align: ${p.textAlign ? p.textAlign : 'initial'};
      font-style: ${p.fontStyle ? p.fontStyle : 'normal'};
      font-weight: ${p.fontWeight ? p.fontWeight : p.fontWeight};
    `}
  font-variant-numeric: tabular-nums;
  letter-spacing: -0.01em;
  margin: 0;
  ${(p) =>
    p.variant === 'notice' &&
    css`
      font-family: LTSaeada;
      color: black;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      font-style: italic;
      ${p.theme.mediaQueries.desktop} {
        font-size: 28px;
        line-height: 36px;
      }
    `}
  ${textSize};
  ${space};
  ${color};
  ${typography};
  ${flexbox};
  ${layout};
`;

export default function Text({ variant = 'regular', ...props }) {
  return <StyledText variant={variant} {...props} />;
}

Text.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.typography,
  ...propTypes.flexbox,
  ...propTypes.layout,
};
