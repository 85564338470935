import React from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getLang } from '../../utils/utils';
import useResaShop from '../../hooks/useResaShop';
// Components
import HelpCard from '../shared/molecules/HelpCard';
import Heading from '../shared/atoms/Heading';
import Text from '../shared/atoms/Text';
import { useCurrentZone } from '../../hooks/useZones';

const FirstLetterUpperCase = styled.span`
  text-transform: capitalize;
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 250px;
  object-position: bottom;
  border-radius: 16px;
`;

export default function RepairCheckoutWithoutPayment({ client }) {
  const { t } = useTranslation();
  const shop = useResaShop();
  const { lang } = getLang();
  const zone = useCurrentZone();

  // TODO to be removed
  return <div />;

  return (
    <>
      <Heading level={2} mb={{ xs: 4, lg: 5 }}>
        {t('repaircheckoutshop.heading.1')}{' '}
        <FirstLetterUpperCase>
          {client?.prenom?.replace(/\s/g, '') || null}
        </FirstLetterUpperCase>{' '}
        !
      </Heading>
      <StyledImage
        src="/static/img/photos/repair/image-shop-ladefense.png"
        alt={t('repairdefaultshop.alt.1')}
      />
      {zone && zone.light && (
        <HelpCard
          title={t('No hidden fees')}
          p={5}
          mt={5}
          mb={5}
          bg="gray.lightest">
          <Text color="gray.darkest">
            <Trans
              i18nKey="checkout.light.text"
              components={{
                slink: <a href={t('prices.link')} target={'_blank'} />,
              }}
            />
          </Text>
        </HelpCard>
      )}
      {shop && (
        <HelpCard
          title={t('repaircheckoutshop.title')}
          p={5}
          mt={5}
          bg="gray.lightest">
          <Text color="gray.darkest">
            <Trans
              i18nKey={'repaircheckoutshop.text.1'}
              values={{
                shopName: shop.name,
                shopAddress: shop.address,
              }}
            />
          </Text>
        </HelpCard>
      )}
    </>
  );
}
