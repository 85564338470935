import React from 'react';
import styled from 'styled-components';
// Components
import Row from './Row';
import Box from './Box';
import Heading from './Heading';
import Text from './Text';
import Container from './Container';

const StyledImg = styled.img`
  max-width: 600px;
  width: 100%;
`;

const reversable = (elts, reverse) => {
  return reverse ? elts.reverse() : elts;
};

const SectionInformation = ({
  title,
  text,
  img,
  reverse = false,
  width = 7,
  ...props
}) => (
  <Container {...props}>
    <Row sm gap={56} reverse={reverse}>
      {reversable(
        [
          <Box style={{ flex: width }} alignItems="center" key={1}>
            <StyledImg src={img} alt="Roulez Jeunesse entreprise" />
          </Box>,
          <Box style={{ flex: 5 }} justifyContent="center" key={2}>
            <Heading level={2} mb={4}>
              {title}
            </Heading>
            {text}
          </Box>,
        ],
        reverse,
      )}
    </Row>
  </Container>
);

export default SectionInformation;
