import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M13 18.295c.107-.075.231-.176.38-.311.356-.325.782-.782 1.405-1.452l2.982-3.213a1 1 0 011.466 1.361l-3.012 3.244c-.586.631-1.069 1.15-1.495 1.539-.44.401-.891.728-1.434.914a4 4 0 01-2.584 0c-.543-.186-.993-.513-1.434-.914-.427-.388-.909-.908-1.495-1.539l-3.012-3.243a1 1 0 011.466-1.361l2.982 3.212c.623.67 1.049 1.127 1.405 1.452.149.135.273.236.38.31V4a1 1 0 112 0v14.295z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgArrowDown24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgArrowDown24;
