import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import { useSelector, useDispatch } from 'react-redux';
import useBookableShops from '../../../hooks/useBookableShops';
import ZoomControls from '../atoms/ZoomControls';
import Card from '../atoms/Card';
import ResultCard from '../molecules/ResultCard';
import AddressInput from '../main-form/AddressInput';

import useNavigatorPosition from '../../../hooks/useNavigatorPosition';
import {
  makeCircle,
  makeMarker,
  pictoUrl,
} from '../../../utils/google-maps-helpers.jsx';

import { resetPositionInput } from '../../../actions/course.jsx';

import Pin24 from '../../icons/Pin24';
import PinShop40 from '../../icons/doNotDelete/PinShop40';
import Times24 from '../../icons/Times24';
import SvgPinCyclofix from '../../icons/PinCyclofix';

const KEY = 'AIzaSyDjcNaS-p0KQPMFt7cEOgKXdxlMgTAO-Gg';

const FR_DEFAULT_LATLNG = { lat: 47, lng: 2 };
const FR_DEFAULT_ZOOM = 6;
const ZOOM_BREAKPOINT = 9;
const ADDRESS_DEFAULT_ZOOM = 13;

const GOOGLE_MAP_OPTIONS = {
  scrollwheel: false,
  fullscreenControl: false,
  zoomControl: false,
  styles: [
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#E7EEED',
        },
      ],
    },
    {
      featureType: 'landscape.natural',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#A6F2C4',
        },
      ],
    },
    {
      featureType: 'landscape.natural.landcover',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#A6F2C4',
        },
      ],
    },
    {
      featureType: 'landscape.natural.terrain',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#A6F2C4',
        },
      ],
    },
    {
      featureType: 'poi.attraction',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.business',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.medical',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#A6F2C4',
        },
      ],
    },
    {
      featureType: 'poi.sports_complex',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#FFE249',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#B0BDFF',
        },
      ],
    },
  ],
};

const MapZoomControls = styled(ZoomControls)`
  display: none;
  ${(p) => p.theme.mediaQueries.tablet} {
    display: flex;
    top: 1.5rem;
    right: 1.5rem;
  }
  ${(p) => p.theme.mediaQueries.desktop} {
    top: ${(p) => (p.$fixed ? '3rem' : '1.5rem')};
  }
`;

const MapInput = styled(AddressInput)`
  padding: 14px 12px;
  border-radius: ${(p) => p.theme.radii.xs};
  height: auto;
  position: absolute;
  z-index: 100;
  right: 1.5rem;
  top: 1.5rem;
  left: ${(p) => (p.$fixed ? '2.5rem' : '1.5rem')};
  min-width: 'auto' !important;
  .react-autosuggest__suggestions-container {
    min-width: auto !important;
  }
  box-shadow: ${(p) => p.theme.shadows.md};
  ${(p) => p.theme.mediaQueries.tablet} {
    right: 6rem;
  }
  ${(p) => p.theme.mediaQueries.desktop} {
    top: 3rem;
    right: 80px;
    top: ${(p) => (p.$fixed ? '3rem' : '1.5rem')};
  }
`;

const PinComponent = ({
  index,
  setHoverShop,
  scrollToShop,
  hoverShop,
  shop,
}) => (
  <div
    style={{
      position: 'absolute',
      transform: 'translate(-50%, -100%)',
      zIndex: hoverShop === index ? 100 : 0,
    }}>
    {shop.id === 3786 ? (
      <SvgPinCyclofix
        style={{
          cursor: 'pointer',
        }}
        onMouseOver={() => setHoverShop(index)}
        onMouseOut={() => setHoverShop(null)}
        onClick={() => scrollToShop(index)}
      />
    ) : (
      <PinShop40
        style={{
          cursor: 'pointer',
        }}
        onMouseOver={() => setHoverShop(index)}
        onMouseOut={() => setHoverShop(null)}
        onClick={() => scrollToShop(index)}
        number={index + 1}
      />
    )}
  </div>
);

export default function SearchMap({
  fixedLayout = false,
  zones,
  zoneToFocus,
  shops = [],
  setIndexShopClick,
  displayError = false,
  inputSearch = true,
  overridePosition = false,
  refs,
  borderRadius = { xs: 'none', md: 'md', lg: 'lg' },
}) {
  const { position, positionInput, positionError, placeId } = useSelector(
    (s) => s.course,
  );
  const bookableShops = useBookableShops();

  const dispatch = useDispatch();
  const [mapRef, setMapRef] = useState(null);
  const [hoverShop, setHoverShop] = useState(null);
  const [GMapsRef, setGMapsRef] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const { isError, position: positionNavigator } = useNavigatorPosition();
  const circlesRef = useRef(null);
  const markersRef = useRef(null);
  const resultMarkerRef = useRef(null);
  const geolocMarkerRef = useRef(null);

  const deleteInputAndPin = () => {
    dispatch(resetPositionInput());
    if (resultMarkerRef.current) {
      resultMarkerRef.current.setVisible(false);
    }
  };

  // Return map bounds based on list of places
  const getMapBounds = (map, maps, shops) => {
    const bounds = new maps.LatLngBounds();

    shops.forEach((shop) => {
      bounds.extend(new maps.LatLng(shop.value.lat, shop.value.lng));
    });
    return bounds;
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

  const onApiLoaded = (map, maps) => {
    setMapRef(map);
    setGMapsRef(maps);
    if (shops && positionError) {
      // Get bounds by our places
      const bounds = getMapBounds(map, maps, shops);
      // Fit map to bounds
      map.fitBounds(bounds);
      // Bind the resize listener
      bindResizeListener(map, maps, bounds);
    }
  };

  // when map loads, we define geocode
  // finds position coords, creates marker, zooms in
  const geocode = useCallback(
    (request, type) => {
      const geocoder = new GMapsRef.Geocoder(mapRef);

      const ref = type === 'geoloc' ? geolocMarkerRef : resultMarkerRef;
      geocoder.geocode(request, (result, status) => {
        if (status === 'OK') {
          const latlng = result[0].geometry.location;
          mapRef.setCenter(latlng);
          if (!positionError || !shops.length || !!bookableShops.length) {
            mapRef.setZoom(ADDRESS_DEFAULT_ZOOM);
          } else {
            const bounds = getMapBounds(mapRef, GMapsRef, shops);
            mapRef.fitBounds(bounds);
            bindResizeListener(mapRef, GMapsRef, bounds);
          }
          if (!ref.current) {
            const marker = makeMarker(GMapsRef, mapRef, latlng, type);
            ref.current = marker;
            setShowResult(true);
            return marker;
          }
          ref.current.setIcon(pictoUrl(type));

          if (ref.current.visible === false) ref.current.setVisible(true);

          ref.current.setPosition(latlng);
          setShowResult(true);
          return ref.current;
        }
        setShowResult(false);
        return null;
      });
    },
    [GMapsRef, mapRef, positionError],
  );

  // on address select, geocode address
  useEffect(() => {
    if (GMapsRef && mapRef) {
      if (overridePosition) {
        geocode({ address: overridePosition }, 'address');
      } else if (position) {
        geocode({ address: position }, 'address');
      } else if (placeId) {
        geocode({ placeId }, 'address');
      }
    }
  }, [GMapsRef, geocode, mapRef, placeId, position, overridePosition]);

  // on current geolocation select, geocode current location
  useEffect(() => {
    if (GMapsRef && mapRef) {
      if (positionNavigator) {
        geocode({ address: positionNavigator }, 'geoloc');
      }
    }
  }, [GMapsRef, geocode, mapRef, positionNavigator]);

  // hide zone markers when map is zoomed in
  useEffect(() => {
    if (GMapsRef && mapRef && zones) {
      const listener = GMapsRef.event.addListener(
        mapRef,
        'zoom_changed',
        () => {
          const zoomLevel = mapRef.getZoom();
          if (zoomLevel > ZOOM_BREAKPOINT) {
            circlesRef.current.map((c) => c.setVisible(true));
            markersRef.current.map((c) => c.setVisible(false));
          }
          if (zoomLevel <= ZOOM_BREAKPOINT) {
            circlesRef.current.map((c) => c.setVisible(false));
            markersRef.current.map((c) => c.setVisible(true));
            setShowResult(false);
          }
        },
      );
      return () => GMapsRef.event.removeListener(listener);
    }
  }, [GMapsRef, mapRef, zones]);

  // when zones are loaded, add zone markers & circles
  useEffect(() => {
    if (GMapsRef && mapRef && zones) {
      markersRef.current = zones.map((z) =>
        makeMarker(GMapsRef, mapRef, z, 'zones'),
      );
      if (position) {
        markersRef.current.map((c) => c.setVisible(false));
      }
      circlesRef.current = zones.map((z) => makeCircle(GMapsRef, mapRef, z));
      circlesRef.current.map((circle) => circle.setVisible(!!position));
    }
    return () => { };
  }, [GMapsRef, mapRef, zones]);

  // focus on zone
  useMemo(() => {
    if (zoneToFocus) {
      mapRef.setCenter(zoneToFocus.center);
      mapRef.setZoom(zoneToFocus.zoom);
    }
  }, [mapRef, zoneToFocus]);

  const scrollToShop = (index) => {
    if (!setIndexShopClick) return;
    setIndexShopClick(index);
    window.scrollTo({
      behavior: 'smooth',
      top: refs[index].current?.offsetTop,
    });
  };

  return (
    <Card p={0} width="100%" height="100%" borderRadius={borderRadius}>
      <MapZoomControls
        $fixed={fixedLayout}
        onZoomIn={() => mapRef.setZoom(mapRef.getZoom() + 1)}
        onZoomOut={() => mapRef.setZoom(mapRef.getZoom() - 1)}
      />
      {positionInput && inputSearch && inputSearch !== 'light' && (
        <ResultCard
          fixedLayout={fixedLayout}
          showResult={showResult}
          position={position}
          positionError={displayError && positionError}
          positionInput={positionInput}
        />
      )}
      {inputSearch && (
        <MapInput
          $fixed={fixedLayout}
          name="google-map-address-input"
          bg="white"
          error={isError && !positionInput}
          naked
          withPadding
          withLabel={false}
          before={<Pin24 />}
          after={positionInput && <Times24 onClick={deleteInputAndPin} />}
        />
      )}
      <GoogleMapReact
        bootstrapURLKeys={{ key: KEY }}
        options={GOOGLE_MAP_OPTIONS}
        defaultCenter={FR_DEFAULT_LATLNG}
        defaultZoom={FR_DEFAULT_ZOOM}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => onApiLoaded(map, maps)}>
        {shops &&
          shops.map((shop, index) => (
            <PinComponent
              index={index}
              key={index}
              lat={shop.value.lat}
              lng={shop.value.lng}
              setHoverShop={setHoverShop}
              scrollToShop={scrollToShop}
              hoverShop={hoverShop}
              shop={shop.value}
            />
          ))}
      </GoogleMapReact>
    </Card>
  );
}
