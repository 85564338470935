import React from 'react';
import Box from '../atoms/Box';

/**
 * @param {string} bg none | any color from theme
 * @param {string | null} title when added displays TitledAlert
 * @param {any} any extend Styled System Box props
 */

export default function SectionBackground({
  bg = null,
  children,
  marginException = false,
  ...props
}) {
  const hasNoBg = bg === 'none' || bg === null;
  return (
    <Box
      // as="section" // TODO Attention Styles globaux
      borderRadius={hasNoBg ? 'none' : { xs: 'none', md: 'lg', lg: 'xl' }}
      bg={hasNoBg ? 'transparent' : bg}
      p={0}
      mb={
        hasNoBg || marginException
          ? { xs: 7, md: 10, lg: '112px' }
          : { xs: 0, md: 7 }
      }
      {...props}>
      {children}
    </Box>
  );
}
