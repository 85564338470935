import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M8.75 12.503a.75.75 0 000 1.5h.533c.673 0 1.224 0 1.671-.037.464-.037.882-.118 1.273-.317a3.25 3.25 0 001.42-1.42c.2-.391.28-.81.318-1.273.036-.448.036-.998.036-1.672V6.72c0-.674 0-1.224-.036-1.672-.038-.463-.119-.882-.318-1.272a3.25 3.25 0 00-1.42-1.42c-.391-.2-.81-.28-1.273-.318C10.507 2 9.956 2 9.283 2H8.75a.75.75 0 000 1.5h.501c.713 0 1.202 0 1.581.032.37.03.57.085.714.159.329.168.597.435.764.765.074.144.13.343.16.713.03.38.031.869.031 1.581v2.503c0 .712 0 1.202-.031 1.58-.03.371-.086.57-.16.714a1.75 1.75 0 01-.764.765c-.144.074-.343.13-.714.16-.38.03-.868.03-1.58.03H8.75z"
      fill="#000"
    />
    <path
      d="M2.75 8.75h6.219a3.48 3.48 0 01-.065.079c-.193.226-.464.498-.869.903l-.737.738a.75.75 0 101.06 1.06l.76-.759c.377-.377.693-.693.93-.971.246-.29.45-.59.568-.95a2.75 2.75 0 000-1.7c-.117-.36-.322-.66-.569-.95a17.828 17.828 0 00-.93-.972L8.36 4.47a.75.75 0 10-1.06 1.061l.736.737c.405.405.676.677.869.904l.065.079H2.75a.75.75 0 000 1.5z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgLogin16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgLogin16;
