import React from 'react';
import styled from 'styled-components';
import Text from '../../../shared/atoms/Text';
import { lightenDarken } from '../../../../utils/utils';

const StyledText = styled(Text)`
  cursor: pointer;
  color: ${(p) =>
    p.tab === p.activeTab
      ? p.theme.colors.blue.electric
      : p.theme.colors.gray.darkest};

  :hover {
    color: ${(p) =>
    p.tab === p.activeTab
      ? lightenDarken(p.theme.colors.blue.electric, 10)
      : lightenDarken(p.theme.colors.gray.darkest, 10)};
    :after {
      background: ${(p) => lightenDarken(p.theme.colors.blue.electric, 10)};
    }
  }
  :after {
    content: '';
    margin-top: 8px;
    height: 2px;
    background: ${(p) => p.theme.colors.blue.electric};
    display: ${(p) => (p.tab === p.activeTab ? 'block' : 'none')};
  }
`;
const TabsMenu = ({
  tab,
  activeTab = 'INTERVENTIONS',
  setActiveTab,
  children,
  ...props
}) => {
  return (
    <StyledText
      tab={tab}
      activeTab={activeTab}
      onClick={() => setActiveTab(tab)}
      {...props}>
      {children}
    </StyledText>
  );
};
export default TabsMenu;
