import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fill="#FFE249"
      d="M8 26h16v3H8zM9.186 10.958h13.555L18 16h-4l-4.814-5.042z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 7.56V3h2v4.56c0 1.078.245 2.132.706 3.065a6.005 6.005 0 001.907 2.262l2.806 1.974c.192.136.338.316.436.513a1.418 1.418 0 010 1.252 1.37 1.37 0 01-.436.513l-.002.002-2.804 1.972a6.004 6.004 0 00-1.907 2.262A6.931 6.931 0 009 24.439V29H7v-4.56c0-1.378.312-2.736.913-3.952a8.003 8.003 0 012.55-3.01l2.1-1.478-2.1-1.478a8.003 8.003 0 01-2.55-3.01A8.931 8.931 0 017 7.56zM25 3v4.56a8.931 8.931 0 01-.913 3.952 8.003 8.003 0 01-2.55 3.01L19.438 16l2.1 1.478a8.002 8.002 0 012.55 3.01A8.931 8.931 0 0125 24.44V29h-2v-4.56a6.931 6.931 0 00-.706-3.065 6.004 6.004 0 00-1.907-2.262l-2.804-1.973-.002-.001a1.37 1.37 0 01-.436-.513 1.418 1.418 0 010-1.252 1.37 1.37 0 01.436-.513l.002-.002 2.804-1.972a6.004 6.004 0 001.907-2.262A6.931 6.931 0 0023 7.561V3h2z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3a1 1 0 011-1h20a1 1 0 110 2H6a1 1 0 01-1-1zm0 26a1 1 0 011-1h20a1 1 0 110 2H6a1 1 0 01-1-1z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgHourglass32Color = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgHourglass32Color;
