const etapeText = (t) => [
  t('sidebar.titles.1'),
  t('sidebar.titles.2'),
  t('sidebar.titles.5'),
  t('informations'),
  t('sidebar.titles.6'),
];

const Etape = (progress, t) => {
  const text = etapeText(t);
  switch (progress) {
    case 0:
      return text[0];
    case 25:
      return text[1];
    case 50:
      return text[2];
    case 90:
      return text[4];
    default:
      return text[3];
  }
};

export const EtapeNumber = (progress) => {
  switch (progress) {
    case 0:
      return 1;
    case 25:
      return 2;
    case 50:
      return 3;
    case 90:
      return 5;
    default:
      return 4;
  }
};

export const EtapeNumberWithBookableShop = (progress) => {
  switch (progress) {
    case 0:
      return 1;
    case 50:
      return 2;
    case 90:
      return 4;
    default:
      return 3;
  }
};

export default Etape;
