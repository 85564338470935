import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 21a1 1 0 00-.978 1.207C16.71 27.823 21.869 32 28 32c6.13 0 11.29-4.177 12.478-9.793A1 1 0 0039.5 21h-23zM28 30c-4.762 0-8.766-2.952-10.184-7h20.368c-1.418 4.048-5.422 7-10.184 7z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52 28c0 13.255-10.745 24-24 24S4 41.255 4 28 14.745 4 28 4s24 10.745 24 24zm-2 0c0 12.15-9.85 22-22 22S6 40.15 6 28 15.85 6 28 6s22 9.85 22 22z"
      fill="#000"
    />
    <path
      d="M6.007 8a1 1 0 10-2 0v2H3a1 1 0 100 2h1.007v2a1 1 0 102 0v-2H7a1 1 0 100-2h-.993V8z"
      fill="#FFE249"
    />
    <path
      d="M10 47a1 1 0 10-2 0v1.966H7a1 1 0 100 2h1V53a1 1 0 102 0v-2.034h1a1 1 0 100-2h-1V47z"
      fill="#28D898"
    />
    <path
      d="M42.002 37a1 1 0 10-2 0v2H39a1 1 0 100 2h1.002v2a1 1 0 102 0v-2h1.002a1 1 0 100-2h-1.002v-2z"
      fill="#1F77F9"
    />
    <path
      d="M21.181 2.816A4.424 4.424 0 0126.6 5.944c1.045 3.9-5.295 11.15-5.295 11.15s-9.065-2.922-10.16-7.01c-.787-2.937.769-4.785 3.128-5.417a4.415 4.415 0 013.927.839 4.416 4.416 0 012.981-2.69zM41.411 5.184a4.424 4.424 0 013.129 5.418c-1.045 3.9-10.16 7.01-10.16 7.01s-6.39-7.064-5.295-11.15c.787-2.938 3.059-3.761 5.418-3.129a4.415 4.415 0 012.981 2.69 4.416 4.416 0 013.927-.839z"
      fill="#d5ff4f"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgLoveColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgLoveColor56;
