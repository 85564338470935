import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M12 12a2 2 0 100-4 2 2 0 000 4z" fill="#000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.206 21.8l-.204.2-.19-.187C8.597 18.66 4 14.15 4 9.883 4 5.532 7.584 2 12.002 2 16.421 2 20 5.531 20 9.884c0 4.261-4.585 8.764-7.794 11.916zm-3.823-6.625c1.081 1.414 2.37 2.767 3.618 4.015 1.248-1.248 2.535-2.6 3.616-4.015C17.148 13.172 18 11.378 18 9.884 18 6.662 15.343 4 12.002 4S6 6.664 6 9.884c0 1.494.852 3.288 2.383 5.29z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgPin24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPin24;
