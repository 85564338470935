import React from 'react';
import styled, { css } from 'styled-components';
import { space, position } from 'styled-system';
import { device } from '../../../styles/constants';

import Box from './Box';

const StyledRow = styled(Box)`
    display:flex;
    display:-ms-flexbox;
    flex-direction: row;
    align-items: ${(p) => (p.noStretch ? 'flex-start' : 'stretch')};
    -ms-align-items: ${(p) => (p.noStretch ? 'flex-start' : 'stretch')};
    ${space}
    ${position}
    > * {
      ${(p) =>
        !p.noSize &&
        `
      flex:1;
      -ms-flex:1;
      `}
      :not(:last-child) {
                margin-right: ${(props) => (props.gap ? props.gap : 10)}px;
              }
    }
    
    ${(props) =>
      !!props.between &&
      css`
        justify-content: space-between;
        > * {
          flex: none;
          -ms-flex: none;
          align-items: center;
          -ms-align-items: center;
        }
      `}
    
    ${(props) =>
      !!props.lg &&
      css`
        @media ${device.lg} {
          ${props.reverse
            ? css`
                flex-direction: column-reverse;
                -ms-flex-direction: column-reverse;
              `
            : css`
                display: block;
              `};

          > * {
            ${(p) =>
              !p.noSize &&
              `
      flex:1;
      -ms-flex:1;
      `}
            :not(:last-child) {
              margin-right: 0;
              margin-bottom: ${(props) => (props.gap ? props.gap : 10)}px;
            }
          }
        }
      `}        
    ${(props) =>
      !!props.md &&
      css`
        @media ${device.md} {
          ${
            props.reverse
              ? css`
                  flex-direction: column-reverse;
                  -ms-flex-direction: column-reverse;
                `
              : css`
                  display: block;
                `
          };

          > * {
            ${(p) =>
              !p.noSize &&
              `
      flex:1;
      -ms-flex:1;
      `}
            ${
              props.reverse
                ? css`
                    :not(:first-child) {
                      margin-right: 0;
                      margin-bottom: ${(props) =>
                        props.gap ? props.gap : 10}px;
                    }
                  `
                : css`
                    :not(:last-child) {
                      margin-right: 0;
                      margin-bottom: ${(props) =>
                        props.gap ? props.gap : 10}px;
                    }
                  `
            }
        }
      `}    
    ${(props) =>
      !!props.sm &&
      css`
        @media ${device.sm}{
            ${
              props.reverse
                ? css`
                    flex-direction: column-reverse;
                    -ms-flex-direction: column-reverse;
                  `
                : css`
                    display: block;
                  `
            };
            
             > * {
      ${(p) =>
        !p.noSize &&
        `
      flex:1;
      -ms-flex:1;
      `}
                :not(:last-child) {
                    margin-right:0;
                }
                :not(:${props.reverse ? `first` : `last`}-child) {
                    margin-bottom:${(props) => (props.gap ? props.gap : 10)}px;
                }
            }
        }
    `}    
    ${(props) =>
      !!props.xs &&
      css`
        @media ${device.xs} {
          display: block;

          > * {
            ${(p) =>
              !p.noSize &&
              `
      flex:1;
      -ms-flex:1;
      `}
            :not(:last-child) {
              margin-right: 0;
              margin-bottom: ${(props) => (props.gap ? props.gap : 10)}px;
            }
          }
        }
      `}
      `;
/**
 * A Box with flex and row by default break in columns on mobile
 * @param {string} as html element
 * @param {string} any extends Box properties (space, layout, color, flexbox)
 */
function Row({ children, ...props }) {
  return <StyledRow {...props}>{children}</StyledRow>;
}
export default Row;
