import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M6.5 8a1.501 1.501 0 013 0 1.501 1.501 0 01-3 0z" fill="#000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 13c3.185 0 5.9-2.073 7-5-1.1-2.927-3.815-5-7-5-3.182 0-5.9 2.073-7 5 1.1 2.927 3.818 5 7 5zm0-1.5c-2.312 0-4.362-1.395-5.37-3.5C3.637 5.895 5.687 4.5 8 4.5c2.315 0 4.363 1.395 5.37 3.5-1.007 2.105-3.055 3.5-5.37 3.5z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgEye16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgEye16;
