import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
// Components
import Button from '../shared/atoms/Button';
import Input from '../shared/atoms/Input';
import Box from '../shared/atoms/Box';
import WrapperForm from './WrapperForm';
// validationSchema
import { updatePasswordValidationSchema } from './ValidationSchema';

const ResetPasswordForm = ({ submit, ...props }) => {
  const { t } = useTranslation();
  const withTitle = t('resetpassword.titles.1');
  return (
    <WrapperForm
      withTitle={withTitle}
      text={t('resetpassword.texts.1')}
      withSwitch={true}
      {...props}>
      <Formik
        initialValues={{ password: '', password_confirm: '' }}
        validationSchema={updatePasswordValidationSchema}
        onSubmit={submit}>
        {({ isSubmitting, submitCount, errors, values }) => (
          <Form>
            <Field
              name="password"
              render={({ field }) => (
                <Input
                  type="password"
                  mb={{ xs: 2, lg: 4 }}
                  color="black"
                  bg="white"
                  placeholder={t('password')}
                  label={t('newpassword.inputlabel')}
                  error={!!submitCount && errors.password}
                  {...field}
                />
              )}
            />
            <Field
              name="password_confirm"
              render={({ field }) => (
                <Input
                  type="password"
                  mb={{ xs: 2, lg: 4 }}
                  color="black"
                  bg="white"
                  placeholder={t('password')}
                  label={t('confirmpassword.inputlabel')}
                  error={!!submitCount && errors.password_confirm}
                  {...field}
                />
              )}
            />
            <Box mt={5} flexDirection="column" justifyContent="space-between">
              <Button
                disabled={
                  isSubmitting || !values.password || !values.password_confirm
                }
                fullWidth
                alignSelf="flex-end"
                mb={4}
                animation>
                {t('button.valid')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </WrapperForm>
  );
};
export default ResetPasswordForm;
