import React from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
// Components
import HowMuchCanWeEarn from '../components/recruitmentFixer/HowMuchCanWeEarn';
import WhoAreTheRepairers from '../components/recruitmentFixer/WhoAreTheRepairers';
import TheyBecameARepairer from '../components/recruitmentFixer/TheyBecameARepairer';
import SkillsNeeded from '../components/recruitmentFixer/SkillsNeeded';
import FAQ from '../components/recruitmentFixer/FAQ';
import JoinTheNetwork from '../components/recruitmentFixer/JoinTheNetwork';
// Components shared
import Container from '../components/shared/atoms/Container';
import Box from '../components/shared/atoms/Box';
import Link from '../components/shared/atoms/Link';
import Text from '../components/shared/atoms/Text';
import HeroShared from '../components/shared/molecules/HeroShared';
import Steps from '../components/recruitmentFixer/Steps';
import SectionInformation from '../components/shared/atoms/SectionInformation';
import Separator from '../components/Separator';
// Icons
import ArrowRight24 from '../components/icons/ArrowRight24';
import ArrowLinkSalary from '../components/icons/doNotDelete/ArrowLinkSalary';

const StyledArrow = styled(ArrowLinkSalary)`
  margin-bottom: -100px;
  margin-left: 30%;
`;

const StyledLink = styled(Link)`
  font-weight: bold;
  text-decoration: underline;
`;

const RecuitmentFixer = () => {
  const { t } = useTranslation();
  return (
    <Container fluid>
      <HeroShared
        buttonLink="https://feedback.roulezjeunesse.com/formulaire-reseau?source=site_roulezjeunesse"
        title={t('recruitment.title')}
        colorHexa="green.lighter"
        colorSquare="yellow.main"
        text={
          <>
            <Text variant="large" mb={4} color="black">
              {t('recruitment.paragraphs.1')}
            </Text>
            <Text variant="large" mb={5} color="black">
              {t('recruitment.paragraphs.2')}
            </Text>
          </>
        }
        buttonText={t('button.recruit')}
        color="gray.lightest"
        img="/static/img/photos/recruitmentFixer/hero-image-acquiz.png"
      />
      <Steps
        title={t('recruit.headings.1')}
        text={t('recruit.texts.1')}
        product={{ productLabelMain: 'Roulezjeunesse app' }}
        colorShadow="#F1F5F4"
        steps={[
          {
            title: t('recruit.titles.1'),
            text: t(`recruit.texts.2`),
          },
          {
            title: t('recruit.titles.2'),
            text: t(`recruit.texts.3`),
          },
          {
            title: t('recruit.titles.3'),
            text: t(`recruit.texts.4`),
          },
          {
            title: t('recruit.titles.4'),
            text: t('recruit.texts.5'),
          },
        ]}
      />
      <Container>
        <SectionInformation
          mb={11}
          px={{ xs: 0, lg: 5 }}
          width={5}
          title={t('recruit.titles.5')}
          text={
            <>
              <Box flexDirection="row" mb={4}>
                <ArrowRight24 />
                <Text ml={2} color="black">
                  <Trans
                    i18nKey="recruit.texts.6"
                    components={{
                      strong: <b />,
                    }}
                  />
                </Text>
              </Box>
              <Box flexDirection="row" mb={4}>
                <ArrowRight24 />
                <Text ml={2} color="black">
                  <Trans
                    i18nKey="recruit.texts.7"
                    components={{
                      strong: <b />,
                    }}
                  />
                </Text>
              </Box>
              <Box flexDirection="row" mb={4}>
                <ArrowRight24 />
                <Text ml={2} color="black">
                  <Trans
                    i18nKey="recruit.texts.8"
                    components={{
                      strong: <b />,
                      lien: (
                        <StyledLink
                          target="_blank"
                          href="https://blog.roulezjeunesse.com/combien-gagne-un-r%C3%A9parateur-itin%C3%A9rant-de-v%C3%A9lo-458d1a5c069c"
                          color="black"
                        />
                      ),
                    }}
                  />
                </Text>
              </Box>
              <StyledArrow />
            </>
          }
          img="/static/img/photos/recruitmentFixer/ride-repair-cashin.png"
        />
      </Container>
      <HowMuchCanWeEarn />
      <Container>
        <WhoAreTheRepairers />
        <Separator axe="horizontal" bg="gray.light" margin="40px 0px" />
        <TheyBecameARepairer />
      </Container>
      <SkillsNeeded />
      <FAQ
        link="https://faq.roulezjeunesse.com/category/zw4havgv5g-futurfixer"
        mb={{ xs: 7, md: 10 }}
      />
      <JoinTheNetwork />
    </Container>
  );
};

export default RecuitmentFixer;
