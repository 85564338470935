import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.006 48C12.28 40.42.8 29.367.8 18.92.8 8.476 9.403 0 20.006 0 30.61 0 39.2 8.475 39.2 18.92c0 10.447-11.48 21.5-19.194 29.08z"
      fill="#1D859E"
    />
    <text
      x="50%"
      y="50%"
      dominantBaseline="middle"
      fontSize="18px"
      fontWeight="600"
      textAnchor="middle"
      fill="#fff">
      {props.number ? props.number : null}
    </text>
  </svg>
);

const SIZE = 40;
const CAN_SET_COLOR = true;
const SvgPinshop40 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPinshop40;
