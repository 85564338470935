import React from 'react';
import { useTranslation } from 'react-i18next';
// Components
import Box from '../../shared/atoms/Box';
import Heading from '../../shared/atoms/Heading';
import Text from '../../shared/atoms/Text';
import Button from '../../shared/atoms/Button';
// Icons
import Logout16 from '../../icons/Logout16';

const Deconnexion = ({ logout }) => {
  const { t } = useTranslation();
  return (
    <Box
      justifyContent="space-between"
      flexDirection={{ xs: 'column', sm: 'row' }}>
      <Box mb={{ xs: 4, sm: 0 }}>
        <Heading level={5}>{t('disconnection')}</Heading>
        <Text>{t('deconnexion.subtitle')}</Text>
      </Box>
      <Button
        before={<Logout16 />}
        variant="neutral"
        color="primary.darkest"
        onClick={(e) => {
          logout();
          e.preventDefault();
          window.location.assign('/');
        }}>
        {t('disconnect.cta')}
      </Button>
    </Box>
  );
};

export default Deconnexion;
