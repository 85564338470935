import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M10.164 3.253a1 1 0 00-1.328 1.494l1.809 1.609c.296.263.529.47.714.644H3a1 1 0 100 2h8.36c-.186.174-.418.38-.715.644l-1.81 1.609a1 1 0 101.33 1.494l1.84-1.635c.438-.39.814-.725 1.098-1.024.297-.313.564-.659.717-1.094a3 3 0 000-1.988c-.153-.435-.42-.78-.717-1.094-.284-.3-.66-.634-1.098-1.024l-1.84-1.635z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgArrowRight16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgArrowRight16;
