import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.83 7.459l2.915-2.387A8.962 8.962 0 0012 3a8.998 8.998 0 00-7.927 13.265A8.999 8.999 0 0012 21a8.962 8.962 0 005.634-1.982 8.998 8.998 0 003.209-8.7h-8.645v3.561h4.812a5.364 5.364 0 01-2.308 2.74 5.351 5.351 0 01-7.764-6.357 5.353 5.353 0 017.891-2.803z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgGoogle24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgGoogle24;
