import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Component
import Box from './Box';
import Text from './Text';
// Icons
import Lock16 from '../../icons/Lock16';

const StyledCard = styled.img`
  width: 27px;
  height: 18px;
  margin-right: 4px;
`;

const StyledStripe = styled.img`
  width: 82px;
  height: 18px;
  margin-right: 8px;
`;

const CardLogos = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box flexDirection="row" {...props}>
        <StyledStripe src="/static/img/logos/stripe.png" alt="Stripe" />
        <StyledCard src="/static/img/logos/Visa.png" alt="Visa" />
        <StyledCard src="/static/img/logos/Mastercard.png" alt="Mastercard" />
        <StyledCard src="/static/img/logos/CB.png" alt="CB" />
      </Box>
      <Box flexDirection="row" mb={4} alignItems="center">
        <Lock16 />
        <Text variant="caption" color="gray.darkest" ml={1}>
          {t('cardlogo.text')}
        </Text>
      </Box>
    </>
  );
};

export default CardLogos;
