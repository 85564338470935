import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Container from '../shared/atoms/Container';
import Box from '../shared/atoms/Box';
import Row from '../shared/atoms/Row';

const RepairSkeleton = () => {
  return (
    <Container fluid>
      <Container pt={4}>
        <Box flexDirection="row">
          <Box flex={2} mr={8} pt={7}>
            <Skeleton height={24} width="70%" style={{ borderRadius: 16 }} />
            <Row mt={5} flexDirection="row" gap={16}>
              <Skeleton height={58} style={{ borderRadius: 16 }} />
              <Skeleton height={58} style={{ borderRadius: 16 }} />
            </Row>
            <Row mt={4} flexDirection="row" gap={16}>
              <Skeleton height={58} style={{ borderRadius: 16 }} />
              <Skeleton height={58} style={{ borderRadius: 16 }} />
            </Row>
          </Box>
          <Box flex={1} pt={7}>
            <Skeleton height={300} style={{ borderRadius: 16 }} />
          </Box>
        </Box>
      </Container>
    </Container>
  );
};

export default RepairSkeleton;
