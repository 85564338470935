import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M20.25 5H8.508L4 19.354h6.521V31l9.729-17.333h-6.877L20.25 5z"
      fill="#FFF9B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.65 2.73a.9.9 0 01.858-.63H24.25a.9.9 0 01.705 1.46l-5.719 7.207h5.014a.9.9 0 01.785 1.34l-9.73 17.334A.9.9 0 0113.622 29V18.254H8a.9.9 0 01-.859-1.17L11.65 2.73zm1.519 1.17L9.226 16.454h5.295a.9.9 0 01.9.9v8.204l7.292-12.991h-5.34a.9.9 0 01-.705-1.46L22.387 3.9h-9.218z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgLightning = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgLightning;
