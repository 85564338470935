import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12 5.6a.9.9 0 01.9.9v4.6H16a.9.9 0 110 1.8h-4a.9.9 0 01-.9-.9V6.5a.9.9 0 01.9-.9z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0-1.8a8.2 8.2 0 100-16.4 8.2 8.2 0 000 16.4z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgHour24 = styled(SVG)`
  min-height: ${(p) => p.size || SIZE}px;
  min-width: ${(p) => p.size || SIZE}px;
  max-height: ${(p) => p.size || SIZE}px;
  max-width: ${(p) => p.size || SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgHour24;
