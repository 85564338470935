/* eslint-disable no-unused-expressions */
/* eslint-disable class-methods-use-this */
const Chat = require('twilio-chat');

export default class TwilioService {
  static serviceInstance;

  static chatClient;

  static getInstance() {
    if (!TwilioService.serviceInstance) {
      TwilioService.serviceInstance = new TwilioService();
    }
    return TwilioService.serviceInstance;
  }

  async getChatClient(twilioToken) {
    if (!TwilioService.chatClient && !twilioToken) {
      throw new Error('Twilio token is null or undefined');
    }
    if (!TwilioService.chatClient && twilioToken) {
      return Chat.Client.create(twilioToken).then((client) => {
        TwilioService.chatClient = client;
        return TwilioService.chatClient;
      });
    }
    return Promise.resolve().then(() => TwilioService.chatClient);
  }

  async getUnreadCount(channel) {
    channel
      .getUnconsumedMessagesCount()
      .then((count) => (Number(count) ? count : 0));
  }

  clientShutdown() {
    TwilioService.chatClient?.shutdown();
    TwilioService.chatClient = null;
  }

  addTokenListener(getToken) {
    if (!TwilioService.chatClient) {
      throw new Error('Twilio client is null or undefined');
    }

    TwilioService.chatClient.on('tokenAboutToExpire', () => {
      getToken().then((token) => TwilioService.chatClient.updateToken(token));
    });

    TwilioService.chatClient.on('tokenExpired', () => {
      getToken().then((token) => TwilioService.chatClient.updateToken(token));
    });
    return TwilioService.chatClient;
  }

  parseChannels(channels) {
    return Promise.all(channels.map(this.parseChannel));
  }

  async parseChannel(channel) {
    const members = await channel.getMembers();
    const count = await channel.getUnconsumedMessagesCount();
    const membersObject = {};
    members.forEach((member) => {
      membersObject[member.state.identity] = {
        _id: member.state.sid,
        name: member.attributes.name,
      };
    });
    return {
      _id: channel.sid,
      id: channel.channelState.uniqueName,
      createdAt: channel.dateCreated,
      updatedAt: channel.dateUpdated,
      unreadCount: count || 0,
      messages: [],
      members: membersObject,
      lastMessageTime:
        channel.lastMessage?.dateCreated ??
        channel.dateUpdated ??
        channel.dateCreated,
    };
  }

  parseMessages(messages) {
    return Promise.all(messages.map(this.parseMessage));
  }

  async parseMessage(message) {
    let media = null;
    if (message.type === 'media') {
      const url = await message.media.getContentTemporaryUrl();
      media = {
        url,
        type: message.media.state.contentType,
      };
    }
    const parsedMessage = {
      _id: message.sid,
      author: message.author,
      body: message.body,
      createdAt: message.dateCreated,
      media,
    };
    return parsedMessage;
  }
}
