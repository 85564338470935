import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.99 15.303c-.78 1.51-2.528 2.21-4.063 1.87l-.026-.006-.025-.007c-1.37-.341-2.715-1.293-3.721-2.283-.986-.972-1.966-2.296-2.327-3.71-.54-1.77.456-3.44 1.842-4.166L6.76 5.096 5.326 6.528a1.286 1.286 0 00-.126.234c-.07.172-.14.414-.189.733-.098.64-.092 1.484.079 2.446a11.173 11.173 0 003.065 5.87 11.369 11.369 0 005.897 3.096c.964.175 1.803.181 2.437.082.675-.106.924-.297.951-.324l1.46-1.457-1.909-1.905zm3.545 3.099l-1.683 1.679c-1.772 1.768-7.947 1.3-12.11-2.854-4.164-4.154-4.543-10.316-2.86-12.084l1.683-1.679c.62-.619 1.683-.619 2.391 0l2.48 2.475c.886.884.532 2.386-.62 2.74-.797.265-1.328 1.149-1.062 1.944.443 1.857 2.834 4.155 4.606 4.597.797.176 1.683-.266 1.949-1.061.354-1.149 1.86-1.503 2.746-.619l2.48 2.475c.62.619.62 1.68 0 2.387z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgPhone24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPhone24;
