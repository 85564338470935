import React from 'react';
import { useTranslation } from 'react-i18next';
import ProductSelector from '../../components/signupLanding/ProductSelector';
// Components
import Heading from '../../components/shared/atoms/Heading';
import Text from '../../components/shared/atoms/Text';
import CTANativeApp from '../../components/shared/organisms/CTANativeApp';
import HowItWorks from '../../components/shared/organisms/HowItWorks';
import WeFixEverywhere from '../../components/home/WeFixEverywhere';
import Box from '../../components/shared/atoms/Box';
import Container from '../../components/shared/atoms/Container';

const SignupLandingConfirmation = ({ partenaire, ...props }) => {
  const { t } = useTranslation();
  return (
    <Container fluid>
      <Box justifyContent="center" mb={7} mt={5}>
        <Heading level={2}>
          {t('signuplanding.heading')}{' '}
          <img src="/static/img/pictos/rocket.png" height="32" />
        </Heading>
        <Text>
          {t('signuplanding.texts.1')}{' '}
          <ProductSelector partenaire={partenaire} /> !
          <br />
          {t('signuplanding.texts.2')}
          <b>{partenaire === 'ratp' ? 16 : 10}€</b> {t('signuplanding.texts.3')}
        </Text>
      </Box>
      <Box mb={8}>
        <CTANativeApp />
      </Box>
      <HowItWorks isLanding={true} />
      <WeFixEverywhere />
    </Container>
  );
};

export default SignupLandingConfirmation;
