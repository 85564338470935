import React from 'react';
import Skeleton from 'react-loading-skeleton';
// Shared Components
import Container from '../components/shared/atoms/Container';
import Box from '../components/shared/atoms/Box';
import Row from '../components/shared/atoms/Row';

const CourseSkeleton = () => {
  return (
    <Container fluid>
      <Skeleton style={{ borderRadius: 80 }} height={300} />
      <Container>
        <Row xs gap={56} my={9}>
          <Box style={{ flex: 7 / 12 }}>
            <Skeleton style={{ borderRadius: 32 }} height={300} />
            <Box mt={9}>
              <Skeleton style={{ borderRadius: 32 }} count={2} height={100} />
            </Box>
          </Box>
          <Box style={{ flex: 5 / 12 }}>
            <Skeleton style={{ borderRadius: 32 }} count={2} height={100} />
          </Box>
        </Row>
      </Container>
    </Container>
  );
};

export default CourseSkeleton;
