import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
// Components
import SectionBackground from '../shared/molecules/SectionBackground';
import Box from '../shared/atoms/Box';
import Row from '../shared/atoms/Row';
import Heading from '../shared/atoms/Heading';
import Text from '../shared/atoms/Text';
import Button from '../shared/atoms/Button';
// Icons
import ArrowRight24 from '../icons/ArrowRight24';
import AngleRight16 from '../icons/AngleRight16';
import PlayBtn from '../icons/doNotDelete/PlayBtn';
import ArrowUpOrnament from '../icons/ArrowUpOrnament';
import LightBulbColor32 from '../icons/LightBulbColor32';
// Hooks
import useAlert from '../../hooks/useModal';

const StyledLogo = styled.img`
  width: 13%;
  max-width: 450px;
  margin-bottom: 16px;
  object-fit: contain;
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    width: 30%;
  }
`;

const StyledImg = styled.img`
  border-radius: 24px;
`;

const StyledPlay = styled(PlayBtn)`
  left: 50%;
  margin-left: -50px;
  top: 50%;
  margin-top: -50px;
  height: 100px;
  width: 100px;
  position: absolute;
`;

const StyledBox = styled(Box)`
  position: relative;
  cursor: pointer;
`;

const StyledOrament = styled(ArrowUpOrnament)`
  margin-top: -10px;
  margin-left: 8px;
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    display: none;
  }
`;

const StyledDiv = styled.div`
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  display: flex;
  height: 100%;
  margin-top: 40px;
`;

const DisplayText = ({ text }) => (
  <Box flexDirection="row" mb={4}>
    <ArrowRight24 />
    <Box ml={2}>{text}</Box>
  </Box>
);

const SkillsNeeded = () => {
  const { t } = useTranslation();
  const showModal = useAlert();
  const openModal = () => {
    showModal({
      width: 'large',
      content: (
        <StyledDiv>
          <iframe
            width="100%"
            height="550"
            frameBorder="0"
            src="https://www.youtube.com/embed/7bB6YKCuaao?autoplay=1&border=0&modestbranding=1&loop=1&"></iframe>
        </StyledDiv>
      ),
      showModal: true,
      showButton: [
        {
          text: t(`close.cta`),
          variant: 'neutral',
        },
      ],
    });
  };
  return (
    <SectionBackground bg="yellow.main" p={{ xs: '20px', md: 10 }} mb={11}>
      <StyledLogo
        src="/static/img/logos/logo-roulezjeunesse.academy-red.png"
        alt={t('roulezjeunesse.academy')}
      />
      <Row sm pt={{ xs: '20px', md: 0 }} gap={72}>
        <Box mb={{ xs: 6, md: 0 }} width={{ xs: '100%', md: '50%' }}>
          <Heading level={2}>{t('skillsneeded.headings.1')}</Heading>
          <StyledBox onClick={openModal} mb={5}>
            <StyledImg
              src="/static/img/photos/recruitmentFixer/video-image-cfx-academy.jpg"
              alt="Alexandre, training lead"
            />
            <StyledPlay />
          </StyledBox>
          <Box flexDirection="row">
            <Text variant="notice2" style={{ width: '80%' }}>
              {t('skillsneeded.texts.1')}
            </Text>
            <StyledOrament />
          </Box>
        </Box>
        <Box
          width={{ xs: '100%', md: '50%' }}
          justifyContent="center"
          alignItems="center">
          <Text variant="large" fontWeight="bold" color="black" mb={4}>
            {t('skillsneeded.texts.2')}
          </Text>
          <DisplayText
            text={
              <Text color="black">
                <Trans
                  i18nKey="skillsneeded.texts.3"
                  components={{
                    strong: <b />,
                  }}
                />
              </Text>
            }
          />
          <DisplayText
            text={
              <Text color="black">
                <Trans
                  i18nKey="skillsneeded.texts.4"
                  components={{
                    strong: <b />,
                  }}
                />
              </Text>
            }
          />
          <DisplayText
            text={
              <Text color="black">
                <Trans
                  i18nKey="skillsneeded.texts.5"
                  components={{
                    strong: <b />,
                  }}
                />
              </Text>
            }
          />
          <Box
            border="1px solid"
            borderColor="yellow.lighter"
            borderRadius="xs"
            bg="yellow.lighter"
            mb={5}
            p={2}>
            <Box
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-start">
              <LightBulbColor32 mb={{ xs: 3, lg: 0 }} mr={2} />
              <Box>
                <Text variant="notice2" color="black">
                  {t('skillsneeded.texts.6')}
                </Text>
              </Box>
            </Box>
          </Box>
          <Button
            target="_blank"
            variant="filledWhite"
            after={<AngleRight16 />}
            href="https://academy.cyclofix.com/">
            {t('skillsneeded.texts.7')}
          </Button>
        </Box>
      </Row>
    </SectionBackground>
  );
};

export default SkillsNeeded;
