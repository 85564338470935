import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M20 5a1 1 0 00-2 0v1c0 1.417 0 2.419-.065 3.203-.063.771-.182 1.243-.37 1.613a4 4 0 01-1.749 1.748c-.37.189-.841.308-1.613.371C13.419 13 12.417 13 11 13H5.42c.147-.153.327-.333.55-.556l2.737-2.737a1 1 0 10-1.414-1.414l-2.764 2.764c-.373.373-.695.695-.938.981-.257.303-.484.631-.616 1.035a3 3 0 000 1.854c.132.404.36.732.616 1.035.243.286.565.608.937.98l2.765 2.765a1 1 0 001.414-1.414L5.97 15.556A44.024 44.024 0 015.42 15h5.624c1.363 0 2.447 0 3.322-.071.895-.074 1.659-.227 2.358-.583a6 6 0 002.622-2.622c.356-.7.51-1.463.583-2.359C20 8.491 20 7.407 20 6.044V5z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgArrowEnterLeft24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgArrowEnterLeft24;
