import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '../atoms/Box';
import Text from '../atoms/Text';
import Separator from '../../Separator';

import ArrowEnterRight16 from '../../icons/ArrowEnterRight16';
import ArrowRight24 from '../../icons/ArrowRight24';

export default function DevisRecap({ questions, ...rest }) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Box flexDirection="row">
        <ArrowRight24 mr={2} />
        <Text mb={5} color="black">
          {t('devisrecap.texts.1')}
          <span style={{ fontWeight: 500 }}>{t('devisrecap.texts.2')}</span>
        </Text>
      </Box>
      <Box bg="gray.lightest" borderRadius="sm" {...rest} p={5}>
        {questions.map((question, i) => (
          <Box key={`${question.q.id}_${i}`}>
            <Text variant="large" color="black">
              <b>{question.q.name}</b>
            </Text>
            <Text color="gray.darkest">
              <ArrowEnterRight16 mr={2} />
              {question.a.content}
            </Text>
            {i + 1 < questions.length && (
              <Separator axe="horizontal" bg="gray.main" />
            )}
          </Box>
        ))}
      </Box>
    </Fragment>
  );
}
