const sizeTheVignettes = () => {
  const btns = [...document.getElementsByClassName('copybtns')];

  const doReset = () =>
    btns.forEach((b) => {
      b.style.width = 'auto';
      b.children[0].style.height = 'auto';
    });
  doReset();

  let maxH = 0;
  let maxW = 0;

  const doRun = () =>
    btns.forEach((b) => {
      const currH = Math.ceil(b.getBoundingClientRect().height);
      const currW = Math.ceil(b.getBoundingClientRect().width);

      if (currW > maxW) maxW = currW;
      else b.style.width = `${maxW}px`;

      if (currH > maxH) maxH = currH;
      else b.children[0].style.height = `${maxH}px`;
    });

  doRun();
  doRun();
};

export default sizeTheVignettes;
