import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M3.434 10.004a.75.75 0 011.121-.997l1.609 1.81c.422.474.708.795.947 1.022.049.046.093.086.134.12V3.006a.75.75 0 111.5 0v8.955c.04-.035.085-.075.134-.121.239-.227.525-.548.947-1.023l1.608-1.81a.75.75 0 111.121.998l-1.63 1.833c-.395.445-.723.814-1.014 1.09-.302.287-.618.527-1.005.662a2.75 2.75 0 01-1.822 0c-.388-.136-.703-.376-1.005-.662-.292-.276-.62-.645-1.015-1.09l-1.63-1.833z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgArrowDown16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgArrowDown16;
