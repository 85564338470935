import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M40 18a2 2 0 012-2h9a2 2 0 012 2v6a2 2 0 01-2 2H40v-8z"
      fill="#d5ff4f"
    />
    <path
      d="M48.636 19.881a.94.94 0 100-1.88.94.94 0 000 1.88zM45.668 24H43l1.897-1.867 1.092 1.093a.454.454 0 01-.32.774zM49.012 24h1.451l-3.047-3.051a.931.931 0 00-1.312-.005l-.274.27 2.5 2.503c.182.181.427.283.682.283z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.662 17h2.17l1.372 2.733-2.989 5.167a8.462 8.462 0 00-2.944-.816 1 1 0 00-.271-.037H6a1 1 0 000 2h3.02a8.484 8.484 0 00-2.646 3.983H4a1 1 0 100 2h2.014a8.453 8.453 0 00.358 2.986H6a1 1 0 100 2h1.29a8.5 8.5 0 007.21 3.987c4.306 0 7.864-3.193 8.423-7.334h6.32l10.714-5.522.837 1.813A5.975 5.975 0 0038 35.02c0 3.304 2.686 5.983 6 5.983s6-2.68 6-5.984-2.686-5.983-6-5.983c-.467 0-.922.053-1.359.154L42.092 28H51a1 1 0 100-2h-9.831l-3.064-6.638L36.928 15H33a1 1 0 100 2h2.396l.71 2.63H23.391L22.069 17h3.022a1 1 0 100-2h-7.429a1 1 0 000 2zM8.498 35.016H13a1 1 0 110 2H9.816a6.49 6.49 0 004.684 1.987 6.495 6.495 0 006.4-5.334h-7.757l4.067-7.032a6.494 6.494 0 00-2.71-.588 6.501 6.501 0 00-6 3.981H10a1 1 0 110 2H8.019a6.436 6.436 0 00.48 2.986zM36.95 21.63H24.395l4.904 9.76 9.82-5.06-2.17-4.7zm-14.672.241l4.923 9.798h-4.244a8.456 8.456 0 00-3.056-5.689l2.377-4.109zM44 31.036c-.167 0-.332.01-.493.03l1.401 3.036a1 1 0 01-1.816.838l-1.449-3.14A3.975 3.975 0 0040 35.02a3.992 3.992 0 004 3.983c2.214 0 4-1.789 4-3.984a3.992 3.992 0 00-4-3.983zm-25.118-3.294a6.459 6.459 0 012.061 3.927H16.61l2.272-3.927z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgBikeCargoColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgBikeCargoColor56;
