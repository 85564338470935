import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
// Hook
import useDeviceWidth from '../../hooks/useDeviceWidth';
// Components
import Hero from '../../components/business/shared/Hero';
import WeTellYouEverything from '../../components/shared/molecules/WeTellYouEverything';
import CustomerReviews from '../../components/business/shared/CustomerReviews';
import MaintainPleasure from '../../components/shared/organisms/MaintainPleasure';
import ToolkitFix from '../../components/shared/ToolkitFix';
import SectionInformation from '../../components/shared/atoms/SectionInformation';
import Text from '../../components/shared/atoms/Text';
// Components shared
import Container from '../../components/shared/atoms/Container';
import Link from '../../components/shared/atoms/Link';

const StyledToolKitHexa = styled(ToolkitFix)`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: 90px;
  transform: rotate(13deg);
  height: 90px;
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    top: -41px;
    right: -33px;
    left: auto;
    bottom: auto;
  }
`;

const StyledSpan = styled.span`
  color: ${(p) => p.theme.colors.primary.main};
  font-family: LTSaeada;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  font-style: italic;
  ${(p) => p.theme.mediaQueries.desktop} {
    font-size: 28px;
    line-height: 36px;
  }
`;

const Flotte = () => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceWidth();

  return (
    <Container fluid>
      <Hero
        type="flottes"
        bg="blue.lighter"
        title={t('fleets.heading')}
        text={t('fleets.subheading')}
        img="/static/img/photos/business/hero-image_flotte.png"
        toolkit={<StyledToolKitHexa name="hexa" color="blue.main" />}
      />
      <SectionInformation
        mb={{ xs: 7, md: 10 }}
        title={t('fleets.titles.1')}
        text={<Text>{t('fleets.texts.1')}</Text>}
        img="/static/img/photos/business/image-section-1_flotte.png"
      />
      <SectionInformation
        mb={{ xs: 7, md: 10 }}
        reverse={!isMobile}
        title={t('fleets.titles.2')}
        text={<Text>{t('fleets.texts.2')}</Text>}
        img="/static/img/photos/business/image-section-2_flotte.png"
      />
      <SectionInformation
        mb={{ xs: 7, md: 10 }}
        title={t('fleets.titles.3')}
        text={
          <Text>
            <Trans i18nKey="fleets.texts.3" />
          </Text>
        }
        img="/static/img/photos/business/image-section-3_flotte.png"
      />
      <SectionInformation
        mb={{ xs: 7, md: 10 }}
        reverse={!isMobile}
        title={t('fleets.titles.4')}
        text={<Text>{t('fleets.texts.4')}</Text>}
        img="/static/img/photos/business/image-section-4_flotte.png"
      />
      <CustomerReviews
        bg="blue.lighter"
        img="/static/img/logos/logo-velib.png"
        review={
          <span>
            <Trans
              i18nKey="fleets.customer.review"
              components={{ styledspan: <StyledSpan /> }}
            />
          </span>
        }
        name="Pierre Heyraud"
        job={<span>{t('fleets.customer')}</span>}
      />
      <WeTellYouEverything
        values={[
          {
            title: t('fleets.questions.1'),
            text: (
              <span>
                <Trans
                  i18nKey="fleets.answers.1"
                  components={{
                    reactLink: (
                      <Link
                        to="/reparateur-a-domicile/"
                        color="gray.darkest"
                        style={{ fontWeight: 600, textDecoration: 'underline' }}
                      />
                    ),
                  }}
                />
              </span>
            ),
          },
          {
            title: (
              <span>
                {t('fleets.questions.2')}
                &nbsp;?
              </span>
            ),
            text: (
              <span>
                <Trans i18nKey="fleets.answers.2" />
              </span>
            ),
          },
          {
            title: t('fleets.questions.3'),
            text: t('fleets.answers.3'),
          },
          {
            title: t('fleets.questions.4'),
            text: t('fleets.answers.4'),
          },
          {
            title: t('fleets.questions.5'),
            text: (
              <span>
                <Trans i18nKey="fleets.answers.5" />
              </span>
            ),
          },
          {
            title: t('fleets.questions.6'),
            text: (
              <span>
                <Trans i18nKey="fleets.answers.6" />
              </span>
            ),
          },
        ]}
      />
      <MaintainPleasure
        title={t('maintainpleasure.title')}
        text={t('maintainpleasure.text')}
        buttonText={t('contactus')}
        link="https://feedback.roulezjeunesse.com/contact-b2b?type=flottes"
      />
    </Container>
  );
};
export default Flotte;
