import React, { useEffect, useState, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import * as api from '../../utils/api';
// Hooks
import useAlert from '../../hooks/useModal';
import useBookableShops from '../../hooks/useBookableShops';
// Icons
import SadColor56 from '../../components/icons/SadColor56';
import RepairDefaultHomeAndShops from '../../components/repair/RepairDefaultHomeAndShops';
import RepairDefaultShopBookable from '../../components/repair/RepairDefaultShopBookable';
import useZones from '../../hooks/useZones';

const RepairDefault = () => {
  const { t } = useTranslation();
  const zones = useZones();
  const [isInCycloZone, setIsInCycloZone] = useState(false);
  const { positionError, shops, product_id, position } = useSelector(
    (s) => s.course,
  );
  const [refs, setRefs] = useState([]);
  const [indexShopClick, setIndexShopClick] = useState(null);
  const showModal = useAlert();
  const bookableShops = useBookableShops();

  useEffect(() => {
    const refs = Array(shops.length)
      .fill(0)
      .map((_) => createRef());
    setRefs(refs);
  }, [shops]);

  useEffect(() => {
    if (position.length < 3) {
      return;
    }
    const isInZone = async () => {
      const data = {
        product_id: [product_id],
        with_shops: true,
        position,
      };
      const response = await api.get('/zones/availability', data);
      setIsInCycloZone(response.is_in_zone);
    };
    isInZone();
  }, [position]);

  useEffect(() => {
    if (positionError && !bookableShops.length) {
      showModal({
        title: t('repairdefault.modal.title'),
        content: shops.length ? t('repairdefault.modal.text') : '',
        showModal: true,
        icon: <SadColor56 />,
        showButton: [
          {
            text: t(`repairdefault.modal.cta`),
          },
        ],
      });
    }
  }, [positionError]);

  return (
    <div>
      <Helmet
        title={t('repairdefault.meta.title')}
        meta={[
          {
            name: 'description',
            content: t('repairdefault.meta.description'),
          },
        ]}
      />
      {!bookableShops.length ? ( // case shop annuaire only or cyclo only
        <RepairDefaultHomeAndShops
          isInZone={isInCycloZone}
          zones={zones}
          refs={refs}
          setIndexShopClick={setIndexShopClick}
          shops={shops}
          positionError={positionError}
          indexShopClick={indexShopClick}
        />
      ) : (
        // case shop bookable (& maybe cyclo)
        <RepairDefaultShopBookable
          isInCycloZone={isInCycloZone}
          zones={zones}
          bookable={bookableShops}
          product_id={product_id}
        />
      )}
    </div>
  );
};

export default RepairDefault;
