import styled from 'styled-components';
import { device } from '../styles/constants';

const ResponsiveHide = styled.div`
    ${(props) =>
      !!props.lg &&
      `
        @media ${device.lg} {
            display: none;
            width:0;
            height:0;
            flex:0;
        }
        `}
    ${(props) =>
      !!props.md &&
      `
        @media ${device.md} {
            display: none;
            width:0;
            height:0;
            flex:0;
        }
        `}
  ${(props) =>
    !!props.sm &&
    `
        @media ${device.sm} {
            display: none;
            width:0;
            height:0;
            flex:0;
        }
        `}
`;

export default ResponsiveHide;
