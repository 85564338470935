import styled from 'styled-components';

const Vignette = styled.img`
  display: block;
  max-width: 100px;
  max-height: 300px;
  width: auto;
  height: auto;
  margin: 0 auto;
`;

export default Vignette;
