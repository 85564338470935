import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12c0-2.8 0-4.2.545-5.27A5 5 0 014.73 4.545C5.8 4 7.2 4 10 4h4c2.8 0 4.2 0 5.27.545a5 5 0 012.185 2.185C22 7.8 22 9.2 22 12c0 2.8 0 4.2-.545 5.27a5 5 0 01-2.185 2.185C18.2 20 16.8 20 14 20h-4c-2.8 0-4.2 0-5.27-.545a5 5 0 01-2.185-2.185C2 16.2 2 14.8 2 12zm8-6h4c1.433 0 2.388.002 3.121.061.71.058 1.035.161 1.241.266a3 3 0 011.311 1.311c.072.142.144.34.2.684L12 10.946 4.127 8.322c.056-.343.128-.542.2-.684a3 3 0 011.311-1.311c.206-.105.53-.208 1.24-.266C7.613 6.001 8.568 6 10 6zm-5.995 4.39L12 13.053l7.994-2.665C20 10.851 20 11.381 20 12c0 1.433-.002 2.388-.061 3.121-.058.71-.161 1.035-.266 1.241a3 3 0 01-1.311 1.311c-.206.105-.53.208-1.24.266-.734.06-1.689.061-3.122.061h-4c-1.433 0-2.388-.002-3.121-.061-.71-.058-1.035-.161-1.241-.266a3 3 0 01-1.311-1.311c-.105-.206-.207-.53-.266-1.24C4.001 14.387 4 13.432 4 12c0-.62 0-1.15.005-1.61z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgMessage24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgMessage24;
