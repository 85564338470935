import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M25.333 24.5a6 6 0 110-12 6 6 0 010 12z" fill="#1F77F9" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.333 1.5a.75.75 0 00-.75.75v.755C20.029 3 19.415 3 18.733 3h-4.8c-.682 0-1.295 0-1.85.005V2.25a.75.75 0 00-1.5 0v.794c-1.31.064-2.212.222-2.974.61a6 6 0 00-2.622 2.622C4.333 7.56 4.333 9.24 4.333 12.6v7.8c0 3.36 0 5.04.654 6.324a6 6 0 002.622 2.622C8.893 30 10.573 30 13.933 30h4.8c3.36 0 5.04 0 6.324-.654a6 6 0 002.622-2.622c.654-1.284.654-2.964.654-6.324v-7.8c0-3.36 0-5.04-.654-6.324a6 6 0 00-2.622-2.622c-.762-.388-1.664-.546-2.974-.61V2.25a.75.75 0 00-.75-.75zm-9.25 3.006v.744a.75.75 0 01-1.5 0v-.704c-.164.008-.32.019-.469.03-.907.075-1.429.213-1.824.414a4.5 4.5 0 00-1.967 1.967c-.2.395-.34.917-.413 1.824l-.014.177H26.77l-.014-.177c-.074-.907-.212-1.429-.413-1.824a4.5 4.5 0 00-1.967-1.967c-.395-.2-.917-.34-1.824-.413a16.255 16.255 0 00-.469-.031v.704a.75.75 0 01-1.5 0v-.744c-.542-.006-1.153-.006-1.85-.006h-4.8c-.697 0-1.308 0-1.85.006zm14.74 5.952c.01.612.01 1.317.01 2.142v7.8c0 1.705-.001 2.893-.077 3.819-.074.907-.212 1.429-.413 1.824a4.5 4.5 0 01-1.967 1.966c-.395.202-.917.34-1.824.414-.926.076-2.114.077-3.819.077h-4.8c-1.705 0-2.893-.001-3.819-.077-.907-.074-1.429-.212-1.824-.413a4.5 4.5 0 01-1.967-1.967c-.2-.395-.34-.917-.413-1.824-.076-.926-.077-2.114-.077-3.819v-7.8c0-.825 0-1.53.01-2.142h20.98zm-5.09 3.792a.75.75 0 00-.75.75v2a.75.75 0 001.5 0v-2a.75.75 0 00-.75-.75zm-5.4 0a.75.75 0 00-.75.75v2a.75.75 0 001.5 0v-2a.75.75 0 00-.75-.75zm-6.15.75a.75.75 0 011.5 0v2a.75.75 0 01-1.5 0v-2zm10.8 6a.75.75 0 011.5 0v2a.75.75 0 01-1.5 0v-2zm-5.4 0a.75.75 0 011.5 0v2a.75.75 0 11-1.5 0v-2zm-4.65-.75a.75.75 0 00-.75.75v2a.75.75 0 001.5 0v-2a.75.75 0 00-.75-.75z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgCalendar32Color = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgCalendar32Color;
