import React from 'react';

import Box from '../atoms/Box';
import Heading from '../atoms/Heading';

import LightBulbColor32 from '../../icons/LightBulbColor32';

export default function HelpCard({ title, children, ...rest }) {
  return (
    <Box
      border="1px solid"
      borderColor="gray.light"
      borderRadius="xs"
      p={{ xs: 4, lg: 5 }}
      {...rest}>
      <Box
        flexDirection={{ xs: 'column-reverse', lg: 'row' }}
        justifyContent="space-between"
        alignItems="flex-start">
        <Box>
          {title && <Heading level={6}>{title}</Heading>}
          {children}
        </Box>
      </Box>
    </Box>
  );
}
