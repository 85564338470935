import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../styles/constants';
import CobrandLogos from '../CobrandLogos';

const LogoSelector = ({ partenaire, ...props }) => {
  const { t } = useTranslation();
  switch (partenaire) {
    case 'decathlon':
      return <CobrandLogos url={'/static/img/logos/decathlon.png'} />;
    case 'cdiscount':
    case 'clientscdiscount':
      return <CobrandLogos url={'/static/img/logos/cdiscount.png'} />;
    case 'darty':
    case 'fnac':
      return (
        <React.Fragment>
          <span style={{ margin: 10, color: colors.darkgrey }}>x</span>
          <img
            src={
              partenaire === 'darty'
                ? '/static/img/logos/darty.png'
                : "data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='31.999' height='9.711' color='%23E1A925'><path fill='none' d='M21.202 6.474c-.214.293-1.29.297-1.29.297-1.073.072-2.671.024-2.645.967.027 1.001 1.331.889 1.941.864.689-.028 1.28-.212 1.772-.812.428-.522.24-1.285.238-1.316-.002-.03-.016 0-.016 0zM0 2.945h-.002'/><path fill='currentColor' d='M9.886 2.908c-1.046.282-1.459.69-1.619.935v-.898H2.836c-.154-1.751 1.64-1.714 1.64-1.714 1.756-.052 1.827 1.087 1.827 1.087h1.964C8.267.268 5.445.034 5.445.034 2.681-.187 1.729.751 1.729.751.86 1.499.883 2.677.9 2.945H0l.019 1.344h.886V9.58h1.963V4.289l3.438.001v5.29h1.962V5.864c.102-.635.526-1.5 2.048-1.629 0 0 2.225-.366 2.517 1.634v3.71h1.962V5.106c-.449-3.18-4.909-2.198-4.909-2.198zm13.313 2.681c.168-2.304-2.26-2.632-2.26-2.632-2.164-.362-3.407.004-3.427.01-2.117.487-2.124 2.18-2.124 2.18h2.008s.018-1.32 2.164-1.15c0 0 1.548.068 1.639.771 0 0 .504.838-1.639.962-2.156.127-4.72.092-4.448 2.108 0 0-.051 1.936 3.473 1.872 0 0 1.881.058 2.652-.907v.03l.065.746h1.948l-.05-3.99h-.001zM20.98 7.79c-.492.6-1.083.784-1.772.812-.61.025-1.914.137-1.941-.864-.026-.943 1.572-.895 2.645-.967 0 0 1.076-.004 1.29-.297 0 0 .014-.03.016 0 .002.031.19.794-.238 1.316zm9.01-.44c-.299.631-.809.994-2.067 1.006-.54.005-2.284-.186-2.371-2.057l-.001-.068c.055-1.87 1.837-2.063 2.415-2.076 1.071-.022 1.687.317 2.004.946l2.009-.018c-.018-.244-.052-.372-.181-.635-.403-.75-1.42-1.68-3.95-1.605-.714.021-1.549.128-2.069.333-.873.345-2.368 1.263-2.271 3.213l-.002-.001c.102 2.04 1.512 2.639 2.318 2.976.532.223 1.221.289 2.086.305 2.551.049 3.53-.94 3.919-1.698.124-.266.156-.394.17-.639l-2.009.018z'/></svg>"
            }
            height="25"
            className="logo"
            alt={t('roulezjeunesse.texts.alt')}
          />
        </React.Fragment>
      );
    case 'ratp':
      return (
        <React.Fragment>
          <span style={{ margin: 10, color: colors.darkgrey }}>x</span>
          <img
            src="/static/img/logos/ratp.png"
            height="35"
            className="logo"
            alt={t('roulezjeunesse.texts.alt')}
          />
        </React.Fragment>
      );
    default:
      return <React.Fragment />;
  }
};

export default LogoSelector;
