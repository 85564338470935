import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M16 26.665c5.338 0 9.665-4.327 9.665-9.665 0-5.338-4.327-9.665-9.665-9.665v19.33z"
      fill="#d5ff4f"
    />
    <path
      d="M16.9 17.819a2.01 2.01 0 10-1.8-.006v7.947a.9.9 0 101.8 0v-7.942z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1 2a.9.9 0 01.9-.9h6a.9.9 0 110 1.8h-2.105a.917.917 0 01.005.1v1.03c2.341.16 4.513.94 6.35 2.178a.902.902 0 01.114-.137l1.414-1.415A.9.9 0 0126.05 5.93l-1.383 1.383A12.968 12.968 0 0129 17c0 7.18-5.82 13-13 13S3 24.18 3 17c0-3.955 1.766-7.497 4.553-9.882L6.363 5.93a.9.9 0 111.273-1.273l1.378 1.378a12.925 12.925 0 016.087-2.003L15.1 4V3c0-.034.002-.067.005-.1H13a.9.9 0 01-.9-.9zM16 28.2c6.186 0 11.2-5.014 11.2-11.2 0-6.186-5.014-11.2-11.2-11.2C9.814 5.8 4.8 10.814 4.8 17c0 6.186 5.014 11.2 11.2 11.2z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgChrono = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgChrono;
