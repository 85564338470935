import React, { useState } from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import Text from '../atoms/Text';
import DisplayBoxHour from './DisplayBoxHour';
import Box from '../atoms/Box';

const StyledBox = styled(Box)`
  background-color: ${(p) => p.theme.colors.white};

  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
      color: ${p.theme.colors.gray.dark};
      border-color: ${p.theme.colors.gray.lighter};
    `}
  cursor: pointer;
  :hover {
    border-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.turquoise.dark : theme.colors.blue.electric};
  }
`;

const OptionsBox = styled(Box)`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  z-index: 1000;
  position: absolute;
  top: 48px;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
`;
const Option = styled(Box)`
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
      background-color: ${(p) => p.theme.colors.gray.lightest};
      color: ${(p) => p.theme.colors.gray.dark};
    `}
  width: 100%;
  height: 40px;
  :hover {
    background-color: ${({ theme }) => theme.colors.gray.light};
  }
`;
const Overlay = styled(Box)`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
`;
const HourPicker = ({
  date = new Date(),
  hour = 9,
  selectedSlot,
  disabled = false,
  slot = { 0: 1, 15: 1, 30: 1, 45: 1 },
  onChange,
}) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const momentDate = moment(date);
  const momentHour0 = moment(momentDate).hour(hour).minute(0);
  const momentHour15 = moment(momentDate).hour(hour).minute(15);
  const momentHour30 = moment(momentDate).hour(hour).minute(30);
  const momentHour45 = moment(momentDate).hour(hour).minute(45);
  const HourArray = [momentHour0, momentHour15, momentHour30, momentHour45];

  const isSelected =
    selectedSlot &&
    moment(selectedSlot).isSame(momentDate, 'day') &&
    moment(selectedSlot).hour() === hour;

  const handleChange = (option) => {
    onChange(option);
    setIsVisible(false);
  };

  const slotsUsed = Object.values(slot).filter((value) => value === 0);

  return (
    <Box position="relative" mb={2}>
      <Overlay isVisible={isVisible} onClick={() => setIsVisible(!isVisible)} />
      <StyledBox
        flexDirection="row"
        borderRadius="xxs"
        border="1px solid"
        borderColor={isSelected ? 'blue.electric' : 'gray.light'}
        p={2}
        alignItems="center"
        justifyContent="center"
        position="relative"
        height="40px"
        isSelected={isSelected}
        onClick={() => setIsVisible(!isVisible)}
        disabled={disabled}>
        {disabled ? (
          <Text fontSize="xs" fontWeight={1}>
            {t('full')}
          </Text>
        ) : (
          <DisplayBoxHour
            hour={hour}
            isSelected={isSelected}
            slotsUsed={!!slotsUsed.length && !disabled}
            selectedSlot={selectedSlot}
            momentHour0={momentHour0}
            disabled={disabled}
          />
        )}
      </StyledBox>
      <OptionsBox
        bg="white"
        boxShadow="lg"
        borderRadius="xxs"
        alignItems="center"
        isVisible={isVisible}
        onMouseLeave={() => setIsVisible(false)}>
        {HourArray.map((hour, index) => (
          <Option
            key={hour + index}
            justifyContent="center"
            onClick={() => handleChange(hour)}
            disabled={!slot[hour.format('m')]}>
            <Text textAlign="center">{hour.format('LT')}</Text>
          </Option>
        ))}
      </OptionsBox>
    </Box>
  );
};
export default HourPicker;
