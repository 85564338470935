import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  injectStripe,
  Elements,
  StripeProvider,
  CardCVCElement,
  CardNumberElement,
  CardExpiryElement,
} from 'react-stripe-elements';
import { useStripeSetupIntent, useStripePaymentIntent } from '../utils/pay';
import { Row } from './blocks/Grid';
import Input from './Input';
import constants from '../utils/constants';
import ErrorLabel from './blocks/ErrorLabel';
import Button from './Button';
import * as api from '../utils/api';
import { SET_CODEPROMO } from '../actions/course';
import { isDev, isStaging } from '../utils/utils';
import InputGroup from './blocks/InputGroup';
import Spinner from './Spinner';
import InfoText from './InfoText';

const PayForm = ({
  codepromo,
  setCodepromo,
  stripe,
  onSuccess,
  isStandalone = false,
  doPay = false,
  ...props
}) => {
  const { t } = useTranslation();
  const [intent, reloadIntent] = doPay
    ? useStripePaymentIntent({
      client_id: props.client.id,
      amount: props.amount,
    })
    : useStripeSetupIntent();
  const [error, setError] = useState('');
  const [cperror, setCperror] = useState('');
  const [loading, setLoading] = useState(false);

  const _checkCP = async (e) => {
    const rep = await api.get(`/courses/prix`, {
      codepromo: e.target.value,
      client_id: props.client.id,
    });

    if (rep.errors.length) {
      setCperror(rep.errors[0]);
    } else {
      setCperror('');
    }
  };

  const _doSend = async () => {
    setLoading(true);
    if (doPay) {
      // pay
      const rep = await stripe.handleCardPayment(
        intent,
        {},
        {
          payment_method_data: {
            billing_details: {
              name: `${props.client.prenom} ${props.client.nom}`,
            },
          },
          save_payment_method: true,
        },
      );
      if (rep.error) {
        setError(rep.error.message);
        setLoading(false);
      } else {
        let ret;

        if (props.course.paiement !== 'ATELIER') {
          ret = await api.post(`/courses/${props.course.id}/payment`, {
            payment_id: rep.paymentIntent.id,
          });
        } else {
          ret = await api.post(
            `/ateliers/${props.course.id}/participants/${props.client.id}/payment`,
            { payment_id: rep.paymentIntent.id },
          );
        }

        if (ret.error_message) {
          setError(ret.error_message);
          reloadIntent();
          setLoading(false);
        } else {
          onSuccess();
        }
      }
    } else {
      // save card for later
      const rep = await stripe.handleCardSetup(
        intent,
        {},
        {
          payment_method_data: {
            billing_details: {
              name: `${props.client.prenom} ${props.client.nom}`,
            },
          },
        },
      );
      if (rep.error) {
        setError(rep.error.message);
        reloadIntent();
        setLoading(false);
      } else {
        const ret = await api.post(`/clients/${props.client.id}/intents`, {
          id: rep.setupIntent.payment_method,
        });
        if (ret.error_message) {
          setError(ret.error_message);
          reloadIntent();
          setLoading(false);
        } else {
          onSuccess(rep.setupIntent.payment_method);
        }
      }
    }
  };

  return (
    <div>
      {intent && (
        <Row>
          <div>
            <div>
              <InputGroup
                label={t('number.card')}
                error={''}
                valid={true}
                name={'nb'}>
                <CardNumberElement style={{ base: { fontSize: '19.5px' } }} />
              </InputGroup>
              <InputGroup
                label={t('expiration')}
                error={''}
                valid={true}
                name={'expiry'}>
                <CardExpiryElement style={{ base: { fontSize: '19.5px' } }} />
              </InputGroup>
              <InputGroup
                label={t('security.code')}
                error={''}
                valid={true}
                name={'cvc'}>
                <CardCVCElement style={{ base: { fontSize: '19.5px' } }} />
              </InputGroup>
              <div>
                <img
                  src={`${constants.CDN_BASE_URL}/img/logos/stripe.png`}
                  height={25}
                  alt="Stripe"
                />
                <img
                  src={`${constants.CDN_BASE_URL}/img/logos/secure.png`}
                  height={25}
                  alt={t('secure.payment')}
                />
              </div>
              {!isStandalone && (
                <div style={{ textAlign: 'right' }}>
                  <InfoText>{t('payform.texts.1')}</InfoText>
                </div>
              )}
            </div>
            <Button
              main
              stacked
              disabled={loading}
              style={{ width: 270 }}
              onClick={_doSend}>
              {isStandalone
                ? doPay
                  ? t(`Pcta.payment`, { price: props.amount })
                  : t('Confirmer')
                : t('cta.finalize.order')}
            </Button>
            {error ? <ErrorLabel>{error}</ErrorLabel> : <span>&nbsp;</span>}
            {!isStandalone && (
              <div>
                <Input
                  name="codepromo"
                  label={'Code promo'}
                  placeholder={t('promo.code')}
                  component={Input}
                  onChange={(e) => setCodepromo(e.target.value)}
                  value={codepromo}
                  onBlur={(e) => _checkCP(e)}
                />
                {cperror ? (
                  <ErrorLabel>{cperror}</ErrorLabel>
                ) : (
                  <span>&nbsp;</span>
                )}
              </div>
            )}
          </div>
        </Row>
      )}
      {!intent && (
        <div style={{ textAlign: 'center' }}>
          <Spinner />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const o = {
    codepromo: state.course.codepromo,
    client: state.client,
  };

  if (typeof ownProps.client !== 'undefined') o.client = ownProps.client;

  return o;
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCodepromo: (codepromo) => {
      dispatch({
        type: SET_CODEPROMO,
        codepromo,
      });
    },
  };
};

const FatPayForm = injectStripe(
  connect(mapStateToProps, mapDispatchToProps)(PayForm),
);

const ElementedForm = ({ ...props }) => {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    if (window.Stripe) {
      setStripe(
        window.Stripe(
          isDev() || isStaging()
            ? 'pk_test_51PL4zwDvQ5rVbR2X68tvDUswq5vXNyeft6gRAH5dSBtA93wq8K0k7Jl1HIJpn6d9pixfOd3FRN0T1YU9iMukH3x100nEnjo2GG'
            : 'pk_live_51PL4zwDvQ5rVbR2Xvvp8lBpJqVpsew4t2W2QKnGn1vvlyF7ALLej2GDHUIg0bN8jB7dzMFql0n9cNhgJHoJjZIOv00o6ne1cdT',
        ),
      );
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        setStripe(
          window.Stripe(
            isDev() || isStaging()
              ? 'pk_test_51PL4zwDvQ5rVbR2X68tvDUswq5vXNyeft6gRAH5dSBtA93wq8K0k7Jl1HIJpn6d9pixfOd3FRN0T1YU9iMukH3x100nEnjo2GG'
              : 'pk_live_51PL4zwDvQ5rVbR2Xvvp8lBpJqVpsew4t2W2QKnGn1vvlyF7ALLej2GDHUIg0bN8jB7dzMFql0n9cNhgJHoJjZIOv00o6ne1cdT',
          ),
        );
      });
    }
  }, []);
  return (
    <StripeProvider stripe={stripe}>
      <Elements>
        <FatPayForm {...props} />
      </Elements>
    </StripeProvider>
  );
};

export default ElementedForm;
