import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Hooks
import useWindowSize from '../../hooks/useWindowSize';
import { size } from '../../styles/constants.jsx';
// Components
import Box from '../shared/atoms/Box';
import Text from '../shared/atoms/Text';
import Separator from '../Separator';
import Link from '../shared/atoms/Link';
import ProgressSideBar from './ProgressSideBar';
import Progress from '../blocks/Progress';
// Utils
import { readFile } from '../../utils/utils';
import Etape, { EtapeNumber, EtapeNumberWithBookableShop } from './utils/Etape';
// Icons
import AngleDown16 from '../icons/AngleDown16';
import AngleUp16 from '../icons/AngleUp16';

const StyledBox = styled(Box)`
  position: fixed;
  max-width: 310px;
`;

const Container = styled(Box)`
  position: relative;
  box-shadow: 0 0 0 1px ${(p) => p.theme.colors.gray.light};
`;

function GlobalSidebar({ progress, photos, etape }) {
  const { width } = useWindowSize();
  const [photosDisplay, setPhotosDisplayable] = useState([]);
  const isHideable = width <= Number(size.md.replace('px', ''));
  const {
    date,
    position,
    bike_details,
    course_details,
    product_id,
    positionError,
    slots,
  } = useSelector((s) => s.course);
  const { t } = useTranslation();
  const { questions, devis, inProgressQuestion } = useSelector((s) => s.diag);
  const [isCollapsed, setIsCollapsed] = useState(!isHideable);
  const [allMenu, setAllMenu] = useState(false);
  const etapeNumber = EtapeNumber(progress);

  useEffect(() => {
    setIsCollapsed(!isHideable);
    return () => setIsCollapsed(true);
  }, [isHideable]);

  useEffect(() => {
    (async () => {
      const newPhotosDisplayable = [];

      for (let i = 0; i < photos.length; i++) {
        newPhotosDisplayable.push(await readFile(photos[i]));
      }
      setPhotosDisplayable(newPhotosDisplayable);
    })();
  }, [photos]);

  const goBackTo = useMemo(
    () =>
      devis.id
        ? `/reparation/symptoms/d${devis.id}`
        : questions.length
        ? `/reparation/symptoms/${questions.slice(-1)[0].id}`
        : `/reparation/symptoms`,
    [devis.id, questions.length],
  );

  return (
    <>
      {isCollapsed ? (
        <Box mt={9} width={{ xs: 1, lg: '34%' }}>
          <StyledBox>
            <ProgressSideBar
              {...{
                progress,
                goBackTo,
                product_id,
                slots,
                photosDisplay,
                questions,
                position,
                inProgressQuestion,
                course_details,
                bike_details,
                positionError,
                date,
              }}
            />
          </StyledBox>
        </Box>
      ) : (
        <>
          {
            <div>
              <Container mx={{ xs: 0, md: '-80px' }} etape={etapeNumber}>
                <Box
                  px={5}
                  py={4}
                  flexDirection="row"
                  justifyContent="space-between">
                  <Box flexDirection="row">
                    <Text variant="small" fontWeight="bold">
                      {t('step')} {etapeNumber}/{etape} :&nbsp;
                    </Text>
                    <Text variant="small">{Etape(progress, t)}</Text>
                  </Box>
                  <Link
                    color="black"
                    variant="small"
                    hover={false}
                    after={allMenu ? <AngleUp16 /> : <AngleDown16 />}
                    onClick={() => setAllMenu(!allMenu)}>
                    {allMenu ? t('close.cta') : t('sidebar.details.cta')}
                  </Link>
                </Box>
                {!allMenu && <Progress value={(etapeNumber * 100) / etape} />}
              </Container>
              {allMenu && (
                <>
                  <Box py={4} px={5}>
                    <ProgressSideBar
                      {...{
                        progress,
                        goBackTo,
                        product_id,
                        slots,
                        photosDisplay,
                        positionError,
                        questions,
                        position,
                        bike_details,
                        course_details,
                        inProgressQuestion,
                      }}
                    />
                  </Box>
                  <Separator axe="horizontal" my={0} bg="gray.light" />
                </>
              )}
            </div>
          }
        </>
      )}
    </>
  );
}

export default GlobalSidebar;
