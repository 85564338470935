import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.975 4.318a2 2 0 10-3.95 0A6.253 6.253 0 005.75 10.25v1.588c0 .321-.099.635-.283.898l-1.081 1.543C2.993 16.267 4.416 19 6.843 19h1.692a3.5 3.5 0 006.93 0h1.692c2.427 0 3.85-2.733 2.457-4.721l-1.08-1.543a1.564 1.564 0 01-.284-.898V10.25a6.253 6.253 0 00-4.275-5.932zM12 6a4.25 4.25 0 00-4.25 4.25v1.588c0 .732-.225 1.446-.645 2.045l-1.081 1.543A1 1 0 006.843 17h10.314a1 1 0 00.819-1.574l-1.08-1.543a3.564 3.564 0 01-.646-2.045V10.25A4.25 4.25 0 0012 6zm0 14a1.5 1.5 0 01-1.415-1h2.83A1.5 1.5 0 0112 20z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgBell24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgBell24;
