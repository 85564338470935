import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M8.05 3.7a.75.75 0 01.75.75v2.818h1.75a.75.75 0 110 1.5h-2.5a.747.747 0 01-.75-.75V4.45a.75.75 0 01.75-.75z"
      fill="#1D1D1D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 8A7 7 0 111 8a7 7 0 0114 0zm-1.5 0a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0z"
      fill="#1D1D1D"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgHour16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgHour16;
