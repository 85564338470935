import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 16h2.286l1.255 3.662-2.936 5.09A9 9 0 1021.945 34h3.493L36.98 21.494l1.576 3.677a9 9 0 101.838-.789L38 18.795V16h3.5a.618.618 0 110 1.237 1 1 0 000 2 2.618 2.618 0 100-5.237H35a1 1 0 100 2h1v3H21.428L20.4 16H24a1 1 0 100-2h-8a1 1 0 100 2zm5.945 16h1.826l-3.37-9.828-2.065 3.58A8.993 8.993 0 0121.946 32zM17.328 27.5A6.994 6.994 0 0119.93 32h-5.198l2.597-4.501zm-1.731-1.001L11.269 34h8.66A7.001 7.001 0 016 33a7 7 0 019.597-6.502zM34.716 21H22.114l3.413 9.955L34.716 21zm6.473 5.237a7 7 0 11-1.838.789l2.73 6.368a1 1 0 101.838-.788l-2.73-6.37z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgBikeColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgBikeColor56;
