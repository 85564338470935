import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../modals/Modal';
// Components
import Box from '../../shared/atoms/Box';
import Heading from '../../shared/atoms/Heading';
import Button from '../../shared/atoms/Button';
import PayFormNew from '../../PayFormNew';
import Spinner from '../../Spinner';

const CardModal = ({
  showModal,
  setShowModal,
  stripePayment,
  setStripePayment,
  sendRequest,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      zIndex="1002"
      width="small"
      hasCloseAction={true}
      open={showModal}
      onRequestClose={() => setShowModal(false)}>
      <Heading mb={4} level={5}>
        {t('cardmodal.heading')}
      </Heading>
      <Box mt={5}>
        <PayFormNew
          stripePayment={stripePayment}
          setStripePayment={setStripePayment}
        />
      </Box>
      <Box
        alignItems="flex-end"
        justifyContent="flex-end"
        flexDirection="row"
        mt={5}>
        <Button
          size="compact"
          ml={4}
          disabled={!stripePayment.complete}
          variant="filled"
          onClick={sendRequest}
          style={{ alignSelf: 'flex-end' }}>
          {stripePayment.state === 'send' ? <Spinner small light /> : t('save')}
        </Button>
      </Box>
    </Modal>
  );
};

export default CardModal;
