import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SvgArrowEnterLeft24 from '../../icons/ArrowEnterLeft24';
import Box from '../atoms/Box';
import Container from '../atoms/Container';
import Heading from '../atoms/Heading';
import Text from '../atoms/Text';
import NewMainForm from '../main-form/NewMainForm';
// Hook
import useDeviceWidth from '../../../hooks/useDeviceWidth';
import { getLang } from '../../../utils/utils';
import FranceOnly from '../../FranceOnly';

const Root = styled(Box)`
  position: relative;
`;

const StyledSvgArrowEnterLeft24 = styled(SvgArrowEnterLeft24)`
  transform: rotate(-90deg);
  position: relative;
  top: 0.3rem;
`;
const StyledBox = styled(Box)`
  position: relative;
  z-index: 1;
  background-image: url(${(p) => p.$url});
  background-repeat: no-repeat;
  background-position: bottom 0 right -50px;
  background-size: 350px;
  ${(p) => p.theme.mediaQueries.tablet} {
    background-image: url(${(p) => p.$url2}), url(${(p) => p.$url});
    background-position: top -20px right 40%, bottom 0 right -220px;
    background-size: ${(p) => (p.$knacki ? '240px' : '120px')},
      ${(p) => (p.$knacki ? 'cover' : 'contain')};
  }
  ${(p) => p.theme.mediaQueries.desktop} {
    background-position: top -20px right ${(p) => (p.$knacki ? '30%' : '45%')},
      right;
    background-size: ${(p) => (p.$knacki ? '240px' : '120px')}, contain;
  }
`;

const Title = ({ content, product, t }) => {
  let title;
  if (content && content.city)
    title = t('hero.titles.1', {
      city: content.city,
    });
  else if (product)
    title = t('hero.titles.2', {
      product: product.product,
    });
  else title = t('hero.titles.3');
  return <>{title}</>;
};

export default function Hero({ content = null, product = null, ...rest }) {
  const { t } = useTranslation();
  const { isMobile } = useDeviceWidth();
  let url = '/static/img/photos/hero-home.png';
  // const urlMobile = '/static/img/photos/home-hero-mobile-campaign-2021.png';
  let url2 = '';

  if (content && content.background) {
    url = content.background.file.url;
    url2 = '/static/img/photos/eric-move.png';
  }
  if (product) {
    url = product.url;
  }

  return (
    <Root fluid mb={{ xs: 7, md: 10, lg: 10 }} mt={30} padding={50} {...rest}>
      <StyledBox
        borderRadius={{ xs: 'none', md: 'lg', lg: 'xl' }}
        border="1px solid black"
        bg={product ? 'gray.lightest' : 'blue.electric'}
        justifyContent="center"
        // $url={url}
        // $url2={url2}
        $knacki={!!content}
        style={{
          clear: 'none',
          gridRowGap: '100px',
          borderRadius: '30px',
          width: '100%',
          maxWidth: '1200px',
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'flex',
          overflow: 'visible',
        }}>
        <Container
          zIndex={2}
          py={{ xs: 7, md: 10, lg: 12 }}
          pb={{ xs: content ? '280px' : '280px', md: 12 }}>
          <Box width="100%">
            <Heading
              color="white"
              mb={isMobile ? 5 : 4}
              style={{ maxWidth: '16ch' }}>
              <Title content={content} product={product} t={t} />
            </Heading>
            {!isMobile && (
              <Box flexDirection="row" alignItems="flex-end" mb={6}>
                <Text
                  width="90%"
                  variant="large"
                  style={{ fontWeight: 500, color: '#FFFFFF' }}>
                  {t('hero.texts.1')}
                  <StyledSvgArrowEnterLeft24 />
                </Text>
              </Box>
            )}
          </Box>
          <NewMainForm />
          <FranceOnly>
            <Box mt={4} pl={28}>
              <Text
                fontWeight={'bold'}
                color={product ? 'gray.darkest' : 'white'}>
                <img
                  src="/static/img/logos/axa.png"
                  alt="AXA assurance"
                  height={24}
                  width={24}
                />{' '}
                {t('hero.texts.2')} 💪
              </Text>
            </Box>
          </FranceOnly>
        </Container>
      </StyledBox>
    </Root>
  );
}
