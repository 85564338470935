export const GET_PREDICTIONS = 'GET_PREDICTIONS';
export const SET_LOADING = 'SET_LOADING';
export const SET_AC_ADDRESS = 'SET_AC_ADDRESS';

export function googleAutocomplete(input, bias) {
  return async (dispatch) => {
    dispatch({
      type: SET_AC_ADDRESS,
      input,
    });
    if (input.length < 3) {
      dispatch({
        type: GET_PREDICTIONS,
        predictions: [],
      });
      return;
    }
    dispatch({
      type: SET_LOADING,
      value: true,
    });

    /* let googleUrl='https://maps.googleapis.com/maps/api/place/autocomplete/json';

        let queryParameters = '?&input='+input+'&key='+googleKey;
        queryParameters += '&types=geocode';

        if(bias&&bias.lat&&bias.lng) queryParameters+= '&radius=10000&location='+bias.lat+','+bias.lng;

        let req = await fetch(googleUrl+queryParameters, {
            mode: 'no-cors'
        });
        let t  = await req.text();
        console.log('t',t); */

    /* autocompleteService.getPlacePredictions({input}, data=>{
            dispatch({
                type:GET_PREDICTIONS,
                predictions:data
            });
            dispatch({
                type:SET_LOADING,
                value:false
            });
        }); */
  };
}
