import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  setFixerId,
  setAddress,
  setSelectedShop,
} from '../../../actions/course';
// Course components
// Shared components
import Row from '../../shared/atoms/Row';
import Box from '../../shared/atoms/Box';
import Button from '../../shared/atoms/Button';
import Separator from '../../Separator';
import Text from '../../shared/atoms/Text';
import Link from '../../shared/atoms/Link';
// Icons
import Time16 from '../../icons/Time16';
import Back16 from '../../icons/Back16';
import ThinkingFaceColor56 from '../../icons/ThinkingFaceColor56';
import HelpColor56 from '../../icons/HelpColor56';
import Messaging56Color from '../../icons/Messaging56Color';
import OpinionColor56 from '../../icons/OpinionColor56';
import SvgHappyColor56 from '../../icons/HappyColor56';
import * as api from '../../../utils/api';

const SideActions = ({ course, openModal }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isShop = !!course.fixer?.shop_id;

  switch (course.state) {
    case 'dispatched':
      return (
        <Box
          borderColor="gray.light"
          borderWidth="1px"
          borderRadius="sm"
          borderStyle="solid"
          p={4}>
          <Row md gap={16}>
            <Box>
              <Text>{t('sideactions.changedyourmind')}</Text>
              <Button
                before={<Time16 />}
                size="compact"
                variant="neutral"
                color="primary.darkest"
                fullWidth
                mt={2}
                onClick={() => openModal('CANCEL_COURSE')}>
                {t('cancelrequest.cta')}
              </Button>
            </Box>
          </Row >
          <Separator axe="horizontal" mt={4} />
          <Row md gap={16}>
            <HelpColor56 />
            {isShop ? (
              <Box>
                <Text>
                  En cas de problème veuillez contacter directement l'atelier
                </Text>
                <Link
                  color="blue.electric"
                  onClick={() =>
                    window.open(
                      `/ateliers-reparation/${course.fixer?.shop_id}/${course.fixer.prenom}`,
                      '_blank',
                    )
                  }>
                  Voir la fiche atelier
                </Link>
              </Box>
            ) : (
              <Box>
                <Text>{t('sideactions.needhelp')}</Text>
                <Link
                  color="blue.electric"
                  onClick={() =>
                    window.open('https://faq.roulezjeunesse.com/', '_blank')
                  }>
                  {t('sideactions.gethelp')}
                </Link>
                <Link
                  color="blue.electric"
                  onClick={() => openModal('CONTACT_COURSE')}>
                  {t('contactus')}
                </Link>
              </Box>
            )}
          </Row>
          <Separator axe="horizontal" mt={4} />
          <Row md gap={16}>
            <Messaging56Color />
            <Box>
              <Text>{t('sideactions.dispatched.text')}</Text>
            </Box>
          </Row>
        </Box >
      );

    case 'scheduled':
      return (
        <Box
          borderColor="gray.light"
          borderWidth="1px"
          borderRadius="sm"
          borderStyle="solid"
          p={4}>
          <Row md gap={16}>
            {/* <ThinkingFaceColor56 /> */}
            <Box>
              <Text>{t('sideactions.changedyourmind')}</Text>
              <Button
                variant="neutral"
                size="compact"
                fullWidth
                mt={2}
                onClick={() =>
                  openModal(
                    'UPDATE_DISPONIBILITY',
                    isShop
                      ? { shop_id: course.fixer?.shop_id }
                      : { fixer_id: course.fixer_id },
                  )
                }>
                {t('sideactions.scheduled.reschedule')}
              </Button>
              {!isShop && (
                <Button
                  variant="neutral"
                  size="compact"
                  fullWidth
                  mt={2}
                  onClick={() => openModal('UPDATE_ADRESSE')}>
                  {t('sideactions.scheduled.changeadress')}
                </Button>
              )}
              <Button
                before={<Time16 />}
                variant="neutral"
                color="primary.darkest"
                fullWidth
                mt={2}
                size="compact"
                onClick={() => openModal('CANCEL_COURSE')}>
                {t('cancelrequest.cta')}
              </Button>
            </Box>
          </Row>
          <Separator axe="horizontal" mt={4} />
          <Row md gap={16}>
            <HelpColor56 />
            {isShop ? (
              <Box>
                <Text>
                  En cas de problème veuillez contacter directement l'atelier
                </Text>
                <Link
                  color="blue.electric"
                  onClick={() =>
                    window.open(
                      `/ateliers-reparation/${course.fixer?.shop_id}/${course.fixer.prenom}`,
                      '_blank',
                    )
                  }>
                  Voir la fiche atelier
                </Link>
              </Box>
            ) : (
              <Box>
                <Text>{t('sideactions.needhelp')}</Text>
                <Link
                  color="blue.electric"
                  onClick={() =>
                    window.open('https://faq.roulezjeunesse.com/', '_blank')
                  }>
                  {t('sideactions.gethelp')}
                </Link>
                <Link
                  color="blue.electric"
                  onClick={() => openModal('CONTACT_COURSE')}>
                  {t('contactus')}
                </Link>
              </Box>
            )}
          </Row>
        </Box>
      );
    case 'finished':
      return (
        <Box
          borderColor="gray.light"
          borderWidth="1px"
          borderRadius="sm"
          borderStyle="solid"
          p={4}>
          <Row md gap={16}>
            <SvgHappyColor56 />
            <Box>
              <Text>
                {t('sideactions.finished.newappointment', {
                  label: course.fixer.prenom,
                })}
              </Text>
              <Button
                size="compact"
                fullWidth
                mt={2}
                onClick={
                  isShop
                    ? async () => {
                      const shop = await api.get(
                        `/shops/${course.fixer.shop_id}`,
                      );
                      dispatch(setSelectedShop(shop));
                      history.push('/reparation/symptoms');
                    }
                    : () => {
                      dispatch(setFixerId(course.fixer.id));
                      history.push('/reparation');
                    }
                }>
                {t('sideactions.finished.newappointment.cta', {
                  label: course.fixer.prenom,
                })}
              </Button>
            </Box>
          </Row>
          <Separator axe="horizontal" mt={4} />
          <Row md gap={16}>
            <OpinionColor56 />
            <Box>
              <Text>{t('sideactions.finished.feedback')}</Text>
              <Button
                variant="neutral"
                size="compact"
                fullWidth
                mt={2}
                onClick={() =>
                  window.open(
                    `https://feedback.roulezjeunesse.com/satisfaction-post-intervention?course_id=${course.id}&fixer=${course.fixer.prenom}&fixer_id=${course.fixer.id}`,
                    '_blank',
                  )
                }>
                {t('sideactions.finished.feedback.cta')}
              </Button>
            </Box>
          </Row>
          <Separator axe="horizontal" mt={4} />
          <Row md gap={16}>
            <HelpColor56 />
            {isShop ? (
              <Box>
                <Text>
                  En cas de problème veuillez contacter directement l'atelier
                </Text>
                <Link
                  color="blue.electric"
                  onClick={() =>
                    window.open(
                      `/ateliers-reparation/${course.fixer?.shop_id}/${course.fixer.prenom}`,
                      '_blank',
                    )
                  }>
                  Voir la fiche atelier
                </Link>
              </Box>
            ) : (
              <Box>
                <Text>{t('sideactions.needhelp')}</Text>
                <Link
                  color="blue.electric"
                  onClick={() => openModal('PROBLEM_COURSE')}>
                  {t('sideactions.finished.signalproblem')}
                </Link>
                <Link
                  color="blue.electric"
                  onClick={() =>
                    window.open('https://faq.roulezjeunesse.com/', '_blank')
                  }>
                  {t('sideactions.gethelp')}
                </Link>
                <Link
                  color="blue.electric"
                  onClick={() => openModal('CONTACT_COURSE')}>
                  {t('contactus')}
                </Link>
              </Box>
            )}
          </Row>
        </Box>
      );
    default:
      return (
        <Box
          borderColor="gray.light"
          borderWidth="1px"
          borderRadius="sm"
          borderStyle="solid"
          p={4}>
          <Row md gap={16}>
            <ThinkingFaceColor56 />
            <Box>
              <Text>{t('sideactions.default.texts.1')}</Text>
              <Button
                color="blue.electric"
                before={<Back16 />}
                fullWidth
                size="compact"
                mt={2}
                onClick={() =>
                  openModal('UPDATE_DISPONIBILITY', {
                    isReopen: true,
                  })
                }>
                {t('relaunchrequest')}
              </Button>
            </Box>
          </Row>
          <Separator axe="horizontal" mt={4} />
          <Row md gap={16}>
            <HelpColor56 />
            {isShop ? (
              <Box>
                <Text>
                  En cas de problème veuillez contacter directement l'atelier
                </Text>
                <Link
                  color="blue.electric"
                  onClick={() =>
                    window.open(
                      `/ateliers-reparation/${course.fixer?.shop_id}/${course.fixer.prenom}`,
                      '_blank',
                    )
                  }>
                  Voir la fiche atelier
                </Link>
              </Box>
            ) : (
              <Box>
                <Text>{t('sideactions.needhelp')}</Text>
                <Link
                  color="blue.electric"
                  onClick={() =>
                    window.open('https://faq.roulezjeunesse.com/', '_blank')
                  }>
                  {t('sideactions.gethelp')}
                </Link>
                <Link
                  color="blue.electric"
                  onClick={() => openModal('CONTACT_COURSE')}>
                  {t('contactus')}
                </Link>
              </Box>
            )}
          </Row>
        </Box>
      );
  }
};

export default SideActions;
