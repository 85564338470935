import React from 'react';
import styled from 'styled-components';
import { shadows, radii, colors } from '../../../styles/constants';
import SVGPlus from '../../icons/Plus16';
import SVGMinus from '../../icons/Minus16';

const StyledSpan = styled.span`
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    cursor: pointer;
  }
  :first-child {
    border-bottom: 1px solid ${colors.grays['6']};
  }
`;

const StyledControls = styled.div`
  background-color: ${colors.white};
  position: absolute;
  top: 3rem;
  right: 3rem;
  z-index: 100;
  ${shadows.lg};
  display: flex;
  flex-direction: column;
  border-radius: ${radii.xs};
  cursor: pointer;
`;

const ZoomControls = ({ onZoomIn, onZoomOut, ...props }) => (
  <StyledControls {...props}>
    <StyledSpan onClick={onZoomIn}>
      <SVGPlus color="black" />
    </StyledSpan>
    <StyledSpan onClick={onZoomOut}>
      <SVGMinus color="black" />
    </StyledSpan>
  </StyledControls>
);

export default ZoomControls;
