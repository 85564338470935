import React, { Fragment, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import styled, { css } from 'styled-components';
import { useParams, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Input from '../components/shared/atoms/Input';
import Card from '../components/shared/atoms/Card';
import Button from '../components/shared/atoms/Button';
import Box from '../components/shared/atoms/Box';
import Separator from '../components/Separator';
import SvgTimes from '../components/icons/Times24';
import * as api from '../utils/api';

import Search24 from '../components/icons/Search24';
import AngleRight16 from '../components/icons/AngleRight16';
import BikeFrameColor32 from '../components/icons/BikeFrameColor32';
import WheelColor32 from '../components/icons/WheelColor32';
import TransmissionColor32 from '../components/icons/TransmissionColor32';
import HandleBarColor32 from '../components/icons/HandleBarColor32';
import BrakeColor32 from '../components/icons/BrakeColor32';

import Container from '../components/shared/atoms/Container';
import Heading from '../components/shared/atoms/Heading';
import Text from '../components/shared/atoms/Text';
import Badge from '../components/shared/atoms/Badge';
import IconButton from '../components/shared/atoms/IconButton';
import Grid from '../components/shared/atoms/Grid';
import DottedList from '../components/shared/atoms/DottedList';

import SectionBackground from '../components/shared/molecules/SectionBackground';
import Image from '../components/shared/atoms/Image';

const InputButton = styled(IconButton)`
  ${(p) => css`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: ${p.theme.radii.xs};
    border-bottom-right-radius: ${p.theme.radii.xs};
    height: 51px;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  `}
`;
const CardImage = styled.img`
  width: 100%;
  max-width: 30rem;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const BackgroundCard = styled(Card)`
  background-image: url('/static/img/photos/certif-card-2.png');
  background-size: cover;
`;
const CertificationSection = styled(SectionBackground)`
  transition: background-color 300ms ease-in;
  background-image: url('/static/img/pictos/certif-tool-1.png'),
    url('/static/img/pictos/certif-tool-2.png');
  background-repeat: no-repeat;
  background-position: top -80px left -160px, bottom -200px right -150px;
  background-size: 280px, 350px;
  ${(p) => p.theme.mediaQueries.tablet} {
    background-position: top -60px left -160px, bottom -240px right -150px;
    background-size: 320px, 450px;
  }
  ${(p) => p.theme.mediaQueries.desktop} {
    background-position: top 24px left -80px, bottom -250px right -150px;
    background-size: 350px, 500px;
  }
  position: relative;
  &:before {
    height: 100%;
    width: 100%;
    background-color: ${(p) => (p.$showTools ? 'transparent' : 'white')};
    content: ' ';
    transition: background-color 300ms ease-in;
    position: absolute;
  }
`;

const CheckpointSection = ({ title, Icon, content, ...rest }) => {
  return (
    <Box mt={5} {...rest}>
      <Box flexDirection="row" alignItems="center" mb={2}>
        <Icon mr={1} />
        <Text variant="large" color="black">
          <b>{title}</b>
        </Text>
      </Box>
      <DottedList dotColor="gray.dark">
        {content.map((str) => (
          <li key={str}>{str}</li>
        ))}
      </DottedList>
    </Box>
  );
};

// certif number OK 194991G8845
const Certification = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [verificationInput, setVerificationInput] = useState(id || '');
  const [verification, setVerification] = useState(false);
  const [error, setError] = useState(false);

  const getVerification = async (certifId) => {
    const trimmed = certifId.replace('#', '').trim();
    setLoading(true);
    setError(false);
    setVerification(false);
    try {
      const verif = await api.get(`/prestations/${trimmed}/certifications`);
      if (verif.error_message) {
        setError(true);
      } else {
        setVerification(verif);
        history.push(`/certification/${trimmed}`);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const handleVerification = (e) => {
    e.preventDefault();
    getVerification(verificationInput);
  };

  const resetVerification = () => {
    setError(false);
    setVerification(false);
    setVerificationInput(id || '');
    history.push('/certification');
  };

  useEffect(() => {
    if (id) {
      getVerification(id);
      setVerificationInput(id);
    }
  }, [id]);

  // TODO to be removed
  return <div />;

  return (
    <Container fluid>
      <Helmet
        title={t('certification.meta')}
        meta={[
          {
            name: 'description',
            content: t('certification.meta'),
          },
        ]}
      />
      <CertificationSection
        $showTools={!!verification}
        bg={verification ? 'green.lighter' : 'white'}>
        <Container py={10} style={{ zIndex: 3 }}>
          <Box alignItems="center">
            <img
              height="auto"
              width="125px"
              src="/static/img/pictos/tampon_cyclofix.png"
              alt={t('certification.texts.1')}
            />
            <Heading level={2} as="h1" mt={5} textAlign="center">
              {t('certification.headings.1')}
            </Heading>
            {verification ? (
              <Box width={{ xs: 1, lg: '70%' }} alignItems="center">
                <Separator
                  axe="horizontal"
                  mt={0}
                  mb={5}
                  bg="rgba(0,0,0,0.2)"
                />
                <Box flexDirection="row" alignItems="baseline" mb={4}>
                  <Text color="black" fontWeight="500">
                    {t('certification.texts.2')}
                  </Text>
                  <Badge ml={2}>{verificationInput}</Badge>
                </Box>
                <Text mb={5} textAlign="center">
                  {t('certification.texts.3')}
                </Text>
                <Text color="black" fontWeight="500">
                  {t('certification.texts.4', {
                    date: moment(verification.date).format('DD/MM/YYYY'),
                  })}
                </Text>
              </Box>
            ) : (
              <Fragment>
                <Text
                  size="large"
                  mb={5}
                  fontWeight="500"
                  maxWidth="40ch"
                  textAlign="center">
                  {t('certification.texts.5')}
                </Text>
                <Box
                  as="form"
                  width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}
                  onSubmit={handleVerification}
                  flexDirection="row">
                  <Input
                    width="100%"
                    placeholder={t('certification.texts.6')}
                    grouped="end"
                    value={verificationInput}
                    onChange={(e) => {
                      setVerificationInput(e.target.value);
                    }}
                  />
                  <InputButton
                    size="compact"
                    icon={<Search24 color="white" />}
                    loading={loading}
                    type={'submit'}
                  />
                </Box>
              </Fragment>
            )}
            {error && (
              <Box height="4rem">
                <Text color="primary.darkest" fontWeight="500" mt={6}>
                  <SvgTimes color="primary.darkest" mr={2} />
                  {t('certification.texts.7')}
                </Text>
              </Box>
            )}
          </Box>
        </Container>
      </CertificationSection>
      <Container mb={10}>
        {/*
         *
         * VERIFICATION
         *
         * */}
        {verification && (
          <Box mt={{ xs: 8, lg: 0 }} mb={10}>
            <Card
              p={6}
              bg="gray.lightest"
              borderRadius={{ xs: 'sm', lg: 'lg' }}>
              <Heading level={4}>{t('certification.headings.2')}</Heading>
              <Grid gap={3} noGapMobile>
                <Grid item width={{ xs: 1, lg: 1 / 3 }}>
                  <CheckpointSection
                    title={t('certification.titles.1')}
                    Icon={HandleBarColor32}
                    content={[
                      t('certification.texts.8'),
                      t('certification.texts.9'),
                      t('certification.texts.10'),
                    ]}
                  />
                  <CheckpointSection
                    mt={5}
                    title={t('certification.titles.2')}
                    Icon={TransmissionColor32}
                    content={[
                      t('certification.texts.11'),
                      t('certification.texts.12'),
                      t('certification.texts.13'),
                      t('certification.texts.14'),
                      t('certification.texts.15'),
                      t('certification.texts.16'),
                    ]}
                  />
                </Grid>
                <Grid item width={{ xs: 1, lg: 1 / 3 }}>
                  <CheckpointSection
                    title={t('certification.titles.3')}
                    Icon={BrakeColor32}
                    content={[
                      t('certification.texts.17'),
                      t('certification.texts.18'),
                      t('certification.texts.19'),
                      t('certification.texts.20'),
                      t('certification.texts.21'),
                      t('certification.texts.22'),
                    ]}
                  />
                  <CheckpointSection
                    title={t('certification.titles.4')}
                    Icon={WheelColor32}
                    content={[
                      t('certification.texts.23'),
                      t('certification.texts.24'),
                      t('certification.texts.25'),
                    ]}
                  />
                </Grid>
                <Grid item width={{ xs: 1, lg: 1 / 3 }}>
                  <CheckpointSection
                    title={t('certification.titles.5')}
                    Icon={BikeFrameColor32}
                    content={[
                      t('certification.texts.26'),
                      t('certification.texts.27'),
                      t('certification.texts.28'),
                      t('certification.texts.29'),
                      t('certification.texts.30'),
                      t('certification.texts.31'),
                      t('certification.texts.32'),
                      t('certification.texts.33'),
                    ]}
                  />
                </Grid>
              </Grid>
              <Separator axe="horizontal" my={6} bg="gray.light" />
              <Heading level={4}>
                {t('certification.headings.3', {
                  count: verification.photos.length,
                })}
              </Heading>
              <Box flexDirection="row" flexWrap="wrap" mx="-8px">
                {verification.photos.map((p, i) => (
                  <Box key={i} width={{ xs: 1, md: 1 / 2, lg: 1 / 3 }} p={2}>
                    <Image src={p.full} borderRadius="xs" />
                  </Box>
                ))}
              </Box>
            </Card>
            <Button
              animation
              mt={4}
              onClick={resetVerification}
              variant="linkBlack"
              alignSelf="center"
              after={<AngleRight16 />}>
              {t('certification.cta.1')}
            </Button>
            <Separator axe="horizontal" mt={8} mb={0} />
          </Box>
        )}
        {/*
         *
         * CARDS
         *
         * */}
        <Box alignItems="center">
          <Box width={{ xs: 1, md: 3 / 4, lg: 1 }}>
            <Grid gap={3}>
              <Grid item width={{ xs: 1, lg: 1 / 3 }}>
                <Card
                  height={{ xs: '30rem', md: '32rem' }}
                  bg="yellow.main"
                  p={0}>
                  <Box p={{ xs: 4, md: 5, lg: 6 }}>
                    <Heading level={4} mb={4}>
                      {t('certification.headings.4')}
                    </Heading>
                    <Text mb={4}>{t('certification.texts.34')}</Text>
                    <Button
                      animation
                      href="https://reparations.roulezjeunesse.com/certification-technique-roulezjeunesse"
                      target="_blank"
                      variant="filledWhite"
                      after={<AngleRight16 />}>
                      {t('certification.cta.2')}
                    </Button>
                  </Box>
                  <CardImage
                    src="/static/img/photos/certif-card-1.png"
                    alt="Vélo d'occasion"
                  />
                </Card>
              </Grid>
              <Grid item width={{ xs: 1, lg: 1 / 3 }}>
                <BackgroundCard
                  height={{ xs: '30rem', md: '32rem' }}
                  p={{ xs: 4, md: 5, lg: 6 }}
                  justifyContent="flex-end">
                  <Heading level={3} color="white">
                    {t('certification.headings.5')}
                  </Heading>
                  <Button
                    to="/reparation"
                    animation
                    variant="filledWhite"
                    after={<AngleRight16 />}>
                    {t('button.book')}
                  </Button>
                </BackgroundCard>
              </Grid>
              <Grid item width={{ xs: 1, lg: 1 / 3 }}>
                <Card
                  height={{ xs: '30rem', md: '32rem' }}
                  bg="violet.main"
                  p={0}>
                  <Box p={{ xs: 4, md: 5, lg: 6 }} style={{ zIndex: 1 }}>
                    <Heading level={4} color="white" mb={4}>
                      {t('certification.headings.6')}
                    </Heading>
                    <Text mb={4} color="white">
                      {t('certification.texts.35')}
                    </Text>
                    <Button
                      animation
                      href="https://reparations.roulezjeunesse.com/roulezjeunesse-plus"
                      target="_blank"
                      variant="filledWhite"
                      after={<AngleRight16 />}>
                      {t('certification.cta.2')}
                    </Button>
                  </Box>
                  <CardImage
                    src="/static/img/photos/certif-card-3.png"
                    alt={t('certification.alt.1')}
                  />
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Container>
  );
};

export default Certification;
