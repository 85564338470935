import { useEffect, useState } from 'react';
import { useSSPR } from 'react-sspr';
import { SHOPS_CITIES_URL } from '../sspr/constants';
import { parse_all_shops, capitalizeFirstLetter } from '../utils/utils';

const formatShopCities = (rawShopCities) => {
  if (!rawShopCities || !rawShopCities.shops) {
    return { slugifyNearby: [], parsedShops: [], name: '' };
  }

  const parsedShops = parse_all_shops(rawShopCities.shops);
  let { nearby } = rawShopCities;
  nearby = nearby.filter((city) => !!city);
  const slugifyNearby = nearby.map((city) =>
    capitalizeFirstLetter(city.toLowerCase()),
  );

  return {
    slugifyNearby,
    parsedShops,
    name: capitalizeFirstLetter(rawShopCities.name.toLowerCase()),
  };
};

const useShopsCities = (slug) => {
  const rawShopsCities = useSSPR(`${SHOPS_CITIES_URL}/${slug}`);
  const formattedShopsCities = formatShopCities(rawShopsCities);
  const [shopsCities, setShopsCities] = useState(
    formattedShopsCities.parsedShops,
  );
  const [othersCities, setOthersCities] = useState(
    formattedShopsCities.slugifyNearby,
  );
  const [name, setName] = useState(formattedShopsCities.name);

  useEffect(() => {
    if (rawShopsCities) {
      const formattedCities = formatShopCities(rawShopsCities);
      setOthersCities(formattedCities.slugifyNearby);
      setShopsCities(formattedCities.parsedShops);
      setName(formattedCities.name);
    }
  }, [rawShopsCities]);

  return { shopsCities, othersCities, name };
};

export default useShopsCities;
