import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      className="recolor"
      d="M13 42a9 9 0 100-18 9 9 0 000 18zM49.333 9.72l1.91-4.574c.378-.898-.875-1.583-1.48-.811L45 10.413l2.667 1.867-1.91 4.574c-.378.898.875 1.583 1.48.811L52 11.587 49.333 9.72z"
      fill="#8A38F3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 13a1 1 0 100 2h3.69l.87 4.084-13.798 3.806L20.4 16h3.1a1 1 0 000-2h-8a1 1 0 100 2h2.786l1.255 3.662L11.269 34h14.169l11.825-12.81 1.522 3.856a9 9 0 101.86-.735l-2.013-5.1L37.31 13H32zm-11.598 9.172L23.772 32h-9.04l5.67-9.828zm5.125 8.783l-2.115-6.17 10.477-2.89-8.362 9.06zm15.859-4.768a7 7 0 11-1.86.735l2.544 6.445a1 1 0 001.86-.734l-2.544-6.446z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = true;
const SvgEBikeColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      .recolor {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgEBikeColor56;
