import React, { useContext } from 'react';
import styled from 'styled-components';

import constants from '../utils/constants';
import Row from './shared/atoms/Row';
import { Times24 } from './icons';
import { colors } from '../styles/constants';
import useResaShop from '../hooks/useResaShop';
import LogoSelector from './signupLanding/LogoSelector';
import PartenaireContext from '../contexts/PartenaireContext';
import CobrandLogos from './CobrandLogos';

const LogoImg = styled.img`
  height: 40px;
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    height: 32px;
  }
`;

function WidgetHeader({ isInFunnel = false }) {
  const shop = useResaShop();
  const { partenaire } = useContext(PartenaireContext);
  const close = () => {
    if (window) {
      window.postMessage('cylofixClose', '*');
      parent && parent.postMessage('cylofixClose', '*');
      window.close();
    }
  };

  return (
    <Row p={3} between>
      <div>
        {isInFunnel && shop && shop.profile_picture ? (
          <CobrandLogos url={shop.profile_picture} />
        ) : (
          <LogoSelector partenaire={partenaire} />
        )}
        <LogoImg
          src={`${constants.CDN_BASE_URL}/img/logos/roulezjeunesse-main-logo.png`}
          className="logo"
          style={{
            align: 'center',
            ...(isInFunnel && shop && shop.profile_picture
              ? { height: 25 }
              : {}),
          }}
        />
      </div>
      <div>
        <Times24 onClick={close} />
      </div>
    </Row>
  );
}

export default WidgetHeader;
