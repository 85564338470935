import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import usePersistantState from '../hooks/usePersistantState';
import Box from './shared/atoms/Box';
import Text from './shared/atoms/Text';
import TimesCircle from './icons/TimesCircle24';
import Smiley32Color from './icons/Smiley32Color';
import ToolkitFix from './shared/ToolkitFix';
import {
  getContentfulData,
  resolveSingleEntry,
} from '../sspr/contenful-service';

const CloseIconBtn = styled(Box)`
  & * {
    cursor: pointer;
  }
`;

const ToolkitBG = styled(ToolkitFix)`
  position: absolute;
  right: 4rem;
  top: -2rem;
  transform: rotate(-15deg);
  display: none;
  ${(p) => css`
    ${p.theme.mediaQueries.tablet} {
      display: block;
    }
  `}
`;

export default function HeadBand() {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [showHeadBand, setShowHeadBand] = usePersistantState(
    'ui-headband',
    true,
    'sessionStorage',
  );
  const widgetMode = /widget/i.test(pathname);

  const [ad, setAd] = useState();

  useEffect(() => {
    (async () => {
      const data = await getContentfulData('encarts&fields.type=headband');
      const adData = resolveSingleEntry(data);
      setAd(adData);
    })();
  }, []);

  if (!ad) {
    return <div />;
  }

  if (!showHeadBand) {
    return null;
  }
  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      bg="green.lighter"
      py={3}
      px={4}
      width="100%"
      display={widgetMode ? 'none' : 'flex'}>
      <Box flexDirection="row" alignItems="center" style={{ zIndex: 10 }}>
        <span style={{ marginRight: '8px' }}>
          <Smiley32Color />
        </span>
        {ad.fields.link ? (
          <a href={ad.fields.link}>
            {ad.fields.title}
            {ad.fields.content}
          </a>
        ) : (
          <Text color="black">
            {ad.fields.title}
            {ad.fields.content}
          </Text>
        )}
      </Box>
      <ToolkitBG name="hexa" color="green.main" />
      <CloseIconBtn ml={3} onClick={() => setShowHeadBand(false)}>
        <TimesCircle color="black" />
      </CloseIconBtn>
    </Box>
  );
}
