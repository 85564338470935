import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import Text from '../shared/atoms/Text';
import Link from '../shared/atoms/Link';
import Badge from '../shared/atoms/Badge';
import OpenHoursShop from './OpenHoursShop';
import Box from '../shared/atoms/Box';
import Row from '../shared/atoms/Row';
// Icons
import Pin24 from '../icons/Pin24';
import Phone24 from '../icons/Phone24';
import Web24 from '../icons/Web24';

const StyledLink = styled(Link)`
  text-decoration-line: underline;
`;

const DisplayInformationsShop = ({ shop, open_hours, today }) => {
  const { t } = useTranslation();
  return (
    <Box pt={4} ml={'8%'}>
      <Box flexDirection={{ xs: 'column', md: 'row' }}>
        {!!open_hours && (
          <Box>
            <OpenHoursShop {...{ open_hours, today }} />
          </Box>
        )}
        <Box flex={1} ml={{ xs: 0, md: open_hours ? 6 : 0 }}>
          <Row gap={16} sm>
            <Box flexDirection="row" alignItems="flex-start" mb={4}>
              <Badge bg="gray.lightest" mr={4}>
                <Pin24 />
              </Badge>
              {shop.value.address ? (
                <Text>{shop.value.address}</Text>
              ) : (
                <Text fontStyle="italic" color="gray.darker">
                  {t('displayshopmodal.texts.1')}
                </Text>
              )}
            </Box>
            <Box flexDirection="row" alignItems="flex-start" mb={4}>
              <Badge bg="gray.lightest" mr={4}>
                <Phone24 />
              </Badge>
              {shop.value.phone ? (
                <Text>{shop.value.phone}</Text>
              ) : (
                <Text fontStyle="italic" color="gray.darker">
                  {t('displayshopmodal.texts.2')}
                </Text>
              )}
            </Box>
          </Row>
          <Row gap={16} sm>
            <Box flexDirection="row" alignItems="flex-start" mb={4}>
              <Badge bg="gray.lightest" mr={4}>
                <Web24 />
              </Badge>
              {shop.value.website ? (
                <StyledLink href={shop.value.website} color="black">
                  {shop.value.website}
                </StyledLink>
              ) : (
                <Text fontStyle="italic" color="gray.darker">
                  {t('displayshopmodal.texts.3')}
                </Text>
              )}
            </Box>
          </Row>
        </Box>
      </Box>
    </Box>
  );
};

export default DisplayInformationsShop;
