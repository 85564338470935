import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import InfoText from '../InfoText';
import Text from '../shared/atoms/Text';
import Link from '../shared/atoms/Link';

const InfoTextSelector = ({ partenaire, ...props }) => {
  const { t } = useTranslation();
  switch (partenaire) {
    case 'darty':
    case 'fnac':
      return (
        <InfoText>
          {t('infotextselector.texts.1')}
          <br />
          <a
            href="/static/PolitiqueDeConfidentialite.pdf"
            target={'_blank'}>
            {t('infotextselector.texts.2')}
          </a>
        </InfoText>
      );
    case 'ratp':
      return (
        <InfoText>
          {t('infotextselector.texts.3')}
          <br />
          <Trans
            i18nKey="infotextselector.texts.4"
            components={{
              lien: <a href="https://www.roulezjeunesse.com" />,
            }}
          />
        </InfoText>
      );
    case 'inscription':
      return (
        <Text variant="small">
          <Trans
            i18nKey="infotextselector.texts.5"
            components={{
              lien: (
                <Link href="https://www.roulezjeunesse.com/reparateur-a-domicile" />
              ),
            }}
          />
        </Text>
      );
    case 'clientscdiscount':
      return <div />;
    default:
      return <InfoText>{t('infotextselector.texts.6')}</InfoText>;
  }
};

export default InfoTextSelector;
