import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fixerPhotoUrl } from '../../../utils/fixers';
import { readableDateChat, readableHour } from '../../../utils/reparation';
import { capitalizeFirstLetter, readableProduct } from '../../../utils/utils';
// Components
import Box from '../../shared/atoms/Box';
import Text from '../../shared/atoms/Text';
import Heading from '../../shared/atoms/Heading';
import ChatModule from '../../chat';
import ToolkitFix from '../../shared/ToolkitFix';
// Icons
import Calendar24 from '../../icons/Calendar24';
import Hour24 from '../../icons/Hour24';
import Pin24 from '../../icons/Pin24';

const FixerPhoto = styled('img')`
  width: 80px;
  height: 80px;
  border-radius: 100%;
`;

const StyledBox = styled(Box)`
  border: 1px solid ${(p) => p.theme.colors.gray.light};
`;

const StyledBoxRelative = styled(Box)`
  z-index: 0;
  position: relative;
  overflow: hidden;
`;

const StyledToolkit = styled(ToolkitFix)`
  z-index: -1;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(227deg);
  width: 120px;
  height: 120px;
`;

const CourseChat = ({ course, client }) => {
  const { t } = useTranslation();
  return (
    <Box mb={9}>
      <Heading level={4} mb={4}>
        {t('coursechat.heading')}
      </Heading>
      <StyledBox borderRadius="md" p={2}>
        <StyledBoxRelative
          bg="gray.lightest"
          borderRadius="sm"
          p={4}
          flexDirection="row">
          <FixerPhoto src={fixerPhotoUrl(course.fixer)} />
          <Box ml={4} width="100%">
            <Text variant="large" fontWeight="bold" color="black">
              {course.fixer.prenom}
            </Text>
            <Text variant="small" color="gray.darker">
              {t('coursechat.texts.1', {
                vehicule: readableProduct(course.product_id),
              })}
            </Text>
            <Box my={2} gap={16} flexDirection="row">
              <Box flexDirection="row" mr={4}>
                <Calendar24 />
                <Text ml={1}>
                  {capitalizeFirstLetter(readableDateChat(course.date))}
                </Text>
              </Box>
              <Box flexDirection="row">
                <Hour24 />
                <Text ml={1}>
                  {t('coursechat.texts.2', { date: readableHour(course.date) })}
                </Text>
              </Box>
            </Box>
            <Box flexDirection="row">
              <Pin24 />
              <Text ml={1}>{course.position}</Text>
            </Box>
          </Box>
        </StyledBoxRelative>
        <ChatModule
          fixer={course.fixer}
          channelId={`course_${client.id}_${course.fixer_id}`}
          isReadOnly={course.status === 'TERMINE'}
        />
      </StyledBox>
    </Box>
  );
};

export default CourseChat;
