import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../shared/atoms/Card';
import Box from '../shared/atoms/Box';
import Image from '../shared/atoms/Image';
import Text from '../shared/atoms/Text';
import SvgPlay from '../icons/Play24';

import { fixerPhotoUrl } from '../../utils/fixers';

const ChatMessage = ({
  fixer,
  message,
  isOwnMessage,
  isFirstMessage,
  setMediaModal,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      my={1}
      flexDirection="row"
      alignItems="flex-start"
      justifyContent={isOwnMessage ? 'flex-end' : 'flex-start'}>
      {!isOwnMessage && fixer && isFirstMessage ? (
        <Image
          src={fixerPhotoUrl(fixer)}
          alt="Reparateur Roulezjeunesse"
          borderRadius="full"
          height="2rem"
          width="2rem"
          mr={1}
        />
      ) : (
        <Box mr={1} />
      )}
      {message.media ? (
        message.media.type.includes('video') ? (
          <Card
            py={2}
            px={3}
            height="12rem"
            width="70%"
            justifyContent="center"
            alignItems="center"
            bg="white"
            style={{ cursor: 'pointer' }}
            onClick={() => setMediaModal(message.media)}>
            <Box flexDirection="row" alignItems="center">
              <SvgPlay
                size="lg"
                color="blue.main"
                mr={2}
                style={{ cursor: 'pointer' }}
              />
              <Text fontWeight="bold">{t('watchvideo')}</Text>
            </Box>
          </Card>
        ) : (
          <Image
            style={{ cursor: 'pointer' }}
            src={message.media.url}
            height="12rem"
            width="70%"
            onClick={() => setMediaModal(message.media)}
          />
        )
      ) : (
        <Card
          py={2}
          px={3}
          maxWidth="70%"
          display="inline-block"
          bg={isOwnMessage ? 'blue.electric' : 'gray.lightest'}>
          <Text
            style={{ wordBreak: 'break-all' }}
            color={isOwnMessage ? 'white' : 'text'}
            as="span">
            {message.body}
          </Text>
        </Card>
      )}
    </Box>
  );
};

export default ChatMessage;
