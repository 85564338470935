import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M8 0a8 8 0 00-8 8v8a8 8 0 008 8h1.835L12 27l2.165-3H16a8 8 0 008-8V8a8 8 0 00-8-8H8z"
      fill="#d5ff4f"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.572 8.762c.974 0 1.764-.842 1.764-1.88 0-1.04-.79-1.882-1.764-1.882-.975 0-1.765.842-1.765 1.881 0 1.04.79 1.881 1.765 1.881zM10.005 17H5l3.558-3.734 2.049 2.186c.536.571.155 1.548-.602 1.548zm6.273 0H19l-5.716-6.103a1.672 1.672 0 00-2.462-.01l-.514.54L15 16.435c.34.361.8.565 1.28.565z"
      fill="#fff"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgPincyclo24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPincyclo24;
