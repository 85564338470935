import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M13 14.5a1 1 0 10-2 0v3a1 1 0 102 0v-3z" fill="#000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 10.143a3.026 3.026 0 00-.53.162 4 4 0 00-2.166 2.164C4 13.204 4 14.136 4 16s0 2.796.304 3.53a4 4 0 002.165 2.165C7.204 22 8.136 22 10 22h4c1.864 0 2.796 0 3.53-.305a4 4 0 002.165-2.164C20 18.796 20 17.864 20 16s0-2.796-.305-3.53a4 4 0 00-2.164-2.165 3.026 3.026 0 00-.531-.162V7A5 5 0 007 7v3.143zM15 10V7a3 3 0 10-6 0v3h6zm-7.052 2.033C8.42 12.001 9.04 12 10 12h4c.96 0 1.579.001 2.052.033.457.032.63.085.713.12a2 2 0 011.083 1.082c.034.083.088.257.119.713.032.473.033 1.093.033 2.052 0 .96-.001 1.578-.033 2.052-.031.457-.085.63-.12.713a2 2 0 01-1.082 1.083c-.083.034-.256.088-.713.119C15.58 19.999 14.96 20 14 20h-4c-.96 0-1.579-.001-2.052-.033-.456-.031-.63-.085-.713-.12a2 2 0 01-1.083-1.082c-.034-.083-.087-.256-.119-.713C6.001 17.578 6 16.96 6 16c0-.96.001-1.579.033-2.053.032-.455.085-.63.12-.712a2 2 0 011.082-1.083c.083-.034.257-.087.713-.119z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgLock24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgLock24;
