import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.003 35C6.578 28.936 0 24.519 0 18.643 0 12.767 4.928 8 11.003 8S22 12.767 22 18.643 15.422 28.936 11.003 35z"
      fill="#8A38F3"
    />
    <path d="M27 47h7.8z" fill="#000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 7l18 14v16.8c0 3.92 0 5.88-.763 7.378a7 7 0 01-3.06 3.059c-1.186.605-2.663.73-5.177.756V49H21.2c-3.92 0-5.88 0-7.378-.763a7 7 0 01-3.059-3.06C10 43.682 10 41.72 10 37.8V21L28 7zm-1 40V36a4 4 0 018 0v11m2-.007V36a6 6 0 00-12 0v11h-3.8c-1.993 0-3.368-.002-4.435-.089-1.043-.085-1.615-.242-2.035-.456a5 5 0 01-2.185-2.185c-.214-.42-.371-.992-.456-2.035C12.002 41.168 12 39.793 12 37.8V21.978L28 9.534l16 12.444V37.8c0 1.993-.002 3.368-.089 4.435-.085 1.043-.242 1.615-.456 2.035a5 5 0 01-2.185 2.185c-.42.214-.992.371-2.035.456-.62.051-1.345.073-2.235.082z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgHouse56Color = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgHouse56Color;
