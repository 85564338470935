import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// Components
import SideBar from '../shared/molecules/SideBar';

// Utils
import useResaShop from '../../hooks/useResaShop';
import { newReadableslots, readableQuestions } from '../../utils/utils';
import { selectLastQID } from '../../reducers/diag';
import useFirstQuestionID from '../../hooks/useFirstQuestionID';

const ProgressSideBar = ({
  progress,
  goBackTo,
  product_id,
  slots,
  photosDisplay,
  position,
  questions,
  inProgressQuestion,
  positionError,
  bike_details,
  course_details,
}) => {
  const { t } = useTranslation();
  const { devis, selectedShop } = useSelector((s) => ({
    ...s.diag,
    ...s.course,
  }));
  const firstQID = useFirstQuestionID();
  const isResaShop = useResaShop();

  return (
    <div style={{ position: 'relative' }}>
      <SideBar
        inProgress={progress === 0 && !positionError}
        valided={progress !== 0}
        title={t('sidebar.titles.1')}
        content={[
          product_id == 1 ? t('sidebar.titles.3') : t('sidebar.titles.4'),
          isResaShop ? (
            <>
              <b>Réparation en atelier</b> : {isResaShop.name}
            </>
          ) : null,
          position,
        ]}
        link="/reparation"
        first
      />
      <SideBar
        disabled={!firstQID}
        inProgress={progress === 25}
        valided={progress > 25}
        title={t('sidebar.titles.2')}
        content={[
          readableQuestions(
            questions,
            Object.keys(devis).length ? false : inProgressQuestion,
            progress === 25,
          ),
        ]}
        questions={questions}
        link={goBackTo}
        diag
      />
      <SideBar
        inProgress={progress === 50}
        valided={progress > 50}
        title={t('sidebar.titles.5')}
        content={[newReadableslots(slots, false, isResaShop)]}
        link="/reparation/date"
      />
      <SideBar
        inProgress={progress >= 60 && progress <= 75}
        valided={progress > 75}
        title={t('sidebar.titles.7')}
        content={[bike_details, course_details]}
        photosDisplay={photosDisplay}
        link={'/reparation/details'}
      />
      <SideBar
        inProgress={progress >= 90}
        valided={progress > 90}
        title={t('sidebar.titles.6')}
        link={null}
        end
      />
    </div>
  );
};

export default ProgressSideBar;
