import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
// Components
import Box from '../../shared/atoms/Box';
import Row from '../../shared/atoms/Row';
import Heading from '../../shared/atoms/Heading';
import Input from '../../shared/atoms/Input';
import Button from '../../shared/atoms/Button';
import Alert from '../../shared/molecules/Alert';
// ValidationSchema
import updateValidationSchema from '../ValidationSchema';

const Informations = ({ client, submitInformations, updateInformations }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Heading level={5} mb={4}>
        {t('informations')}
      </Heading>
      {updateInformations && (
        <Alert status="success" mb={4}>
          {t('informations.alert')}
        </Alert>
      )}
      <Formik
        initialValues={{
          nom: client.nom,
          prenom: client.prenom,
          tel: client.tel,
          email: client.email,
          invoices_company: client.invoices_company,
        }}
        enableReinitialize
        validationSchema={updateValidationSchema}
        onSubmit={submitInformations}>
        {({ isSubmitting, setFieldValue, submitCount, errors }) => (
          <Form>
            <Row sm gap={16}>
              <Field
                name="prenom"
                render={({ field }) => (
                  <Input
                    mb={{ xs: 2, lg: 4 }}
                    color="black"
                    bg="white"
                    style={{ flex: 1 }}
                    label={t('firstname.inputlabel')}
                    placeholder={t('firstname')}
                    error={!!submitCount && errors.prenom}
                    {...field}
                  />
                )}
              />
              <Field
                name="nom"
                render={({ field }) => (
                  <Input
                    mb={{ xs: 2, lg: 4 }}
                    color="black"
                    bg="white"
                    style={{ flex: 1 }}
                    placeholder={t('lastname')}
                    label={t('lastname.inputlabel')}
                    error={!!submitCount && errors.nom}
                    {...field}
                  />
                )}
              />
            </Row>
            <Row sm gap={16}>
              <Field
                name="tel"
                render={({ field }) => (
                  <Input
                    mb={{ xs: 2, lg: 4 }}
                    color="black"
                    bg="white"
                    placeholder={t('phone')}
                    label={t('phone.inputlabel')}
                    error={!!submitCount && errors.tel}
                    {...field}
                  />
                )}
              />
              <Field
                name="email"
                render={({ field }) => (
                  <Input
                    mb={{ xs: 2, lg: 4 }}
                    color="black"
                    bg="white"
                    placeholder={t('email')}
                    label={t('email.inputlabel')}
                    onChange={(e) => {
                      const value = e.target.value.toLowerCase().trim();
                      setFieldValue('email', value);
                    }}
                    error={!!submitCount && errors.email}
                    {...field}
                  />
                )}
              />
            </Row>
            <Field
              name="invoices_company"
              render={({ field }) => (
                <Input
                  mb={{ xs: 2, lg: 4 }}
                  color="black"
                  bg="white"
                  placeholder={t('company')}
                  label={t('company')}
                  {...field}
                />
              )}
            />
            <Box>
              <Button disabled={isSubmitting}>
                {t('update')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Informations;
