import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import Box from '../shared/atoms/Box';
import Link from '../shared/atoms/Link';
import Text from '../shared/atoms/Text';
import OpenHoursShop from './OpenHoursShop';
// Icons
import AngleUp16 from '../icons/AngleUp16';
import AngleDown16 from '../icons/AngleDown16';

const StyledLink = styled(Link)`
  cursor: ${(p) => (p.detailsHoursDropdown ? 'pointer' : 'auto')};
`;

const DisplayText = styled(Text)`
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    display: none;
  }
`;

const DisplayHoursShop = ({
  today_open_hours = [],
  index = null,
  open_hours,
  today,
  hideDetailsHours = true,
  detailsHoursDropdown = true,
  heading = true,
  openHoursColor = 'green.dark',
  detailsHoursText = 'open',
}) => {
  const [openHours, setOpenHours] = useState(null);
  const { t } = useTranslation();

  return (
    <Box mb={1}>
      {open_hours ? (
        <Box
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems="flex-start">
          {heading && (
            <DisplayText color="black">{t('schedule')}&nbsp;:</DisplayText>
          )}
          <Box>
            {today_open_hours[0] !== '' ? (
              <>
                {!hideDetailsHours ? (
                  <StyledLink
                    detailsHoursDropdown={detailsHoursDropdown}
                    onClick={() =>
                      setOpenHours(
                        openHours === index || !detailsHoursDropdown
                          ? null
                          : index,
                      )
                    }>
                    {openHours === index ? (
                      <Text color={openHoursColor} mr={'4px'}>
                        &nbsp;{t('open.today')}
                      </Text>
                    ) : (
                      <>
                        <Text color={openHoursColor}>
                          &nbsp;{t(detailsHoursText)}
                        </Text>
                        <Text color="black" mr="4px">
                          &nbsp;·&nbsp;{today_open_hours[0]}&nbsp;-&nbsp;
                          {!today_open_hours[3]
                            ? today_open_hours[1]
                            : today_open_hours[3]}
                        </Text>
                      </>
                    )}
                    {openHours === index && detailsHoursDropdown ? (
                      <AngleUp16 />
                    ) : detailsHoursDropdown ? (
                      <AngleDown16 />
                    ) : null}
                  </StyledLink>
                ) : (
                  <Text color={openHoursColor} mr="4px" fontWeight={1}>
                    &nbsp;{t('open.today')}
                  </Text>
                )}
              </>
            ) : (
              <>
                {!hideDetailsHours ? (
                  <Link onClick={() => setOpenHours(openHours ? null : index)}>
                    <Text color="primary.darkest" mr="4px">
                      &nbsp;{t('close.today')}
                    </Text>
                    {openHours === index ? <AngleUp16 /> : <AngleDown16 />}
                  </Link>
                ) : (
                  <Text color="primary.darkest" mr="4px" fontWeight={1}>
                    &nbsp;{t('close.today')}
                  </Text>
                )}
              </>
            )}
            {openHours === index && !hideDetailsHours && (
              <OpenHoursShop {...{ open_hours, today }} />
            )}
          </Box>
        </Box>
      ) : (
        <Text color="gray.darker" fontStyle="italic">
          {t('no.time')}
        </Text>
      )}
    </Box>
  );
};

export default DisplayHoursShop;
