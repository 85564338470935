import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M42 37c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12z"
      fill="#1F77F9"
    />
    <path
      d="M28 28a1 1 0 011 1v4a1 1 0 11-2 0v-4a1 1 0 011-1zM19 28a1 1 0 011 1v4a1 1 0 11-2 0v-4a1 1 0 011-1zM38 29a1 1 0 10-2 0v4a1 1 0 102 0v-4zM29 39a1 1 0 10-2 0V43a1 1 0 102 0V39zM20 39.001a1 1 0 10-2 0V43a1 1 0 002 0V39zM37 38a1 1 0 011 1V43a1 1 0 11-2 0V39a1 1 0 011-1z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 4a1 1 0 011 1v1h14V5a1 1 0 112 0v1.011c2.7.037 4.321.193 5.632.86a8 8 0 013.496 3.497C48 12.08 48 14.32 48 18.8v20.4c0 4.48 0 6.72-.872 8.432a8 8 0 01-3.496 3.496C41.92 52 39.68 52 35.2 52H20.8c-4.48 0-6.72 0-8.432-.872a8 8 0 01-3.496-3.496C8 45.92 8 43.68 8 39.2V18.8c0-4.48 0-6.72.872-8.432a8 8 0 013.496-3.496C13.974 6.054 16.047 6.003 20 6V5a1 1 0 011-1zm15 4v1a1 1 0 102 0v-.989c.894.013 1.642.038 2.291.091 1.21.1 1.906.284 2.433.552a6 6 0 012.622 2.622c.268.527.453 1.222.552 2.433.053.65.078 1.397.09 2.291H10.012c.013-.894.038-1.642.091-2.291.1-1.21.284-1.906.552-2.433a6 6 0 012.622-2.622c.527-.268 1.222-.453 2.433-.552 1.083-.088 2.437-.1 4.291-.102v1a1 1 0 102 0V8h14zM10 18v21.2c0 2.273.002 3.858.102 5.091.1 1.21.284 1.906.552 2.433a6 6 0 002.622 2.622c.527.268 1.222.453 2.433.552 1.233.1 2.818.102 5.091.102h14.4c2.273 0 3.858-.002 5.091-.102 1.21-.1 1.906-.284 2.433-.552a6 6 0 002.622-2.622c.268-.527.453-1.222.552-2.432.1-1.234.102-2.819.102-5.092V18.8 18H10z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgCalendarColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgCalendarColor56;
