import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M47 51l-.628.778L48 53.092V51h-1zm-19 0l-.6.8.623.467.605-.489L28 51zm-1.4-2.3a1 1 0 00-1.2 1.6l1.2-1.6zm15.65-1.533l.628-.779-.628-.506-.628.507.628.778zm-9.5 0l.628-.779-.628-.506-.628.507.628.778zM37.5 51l-.628.778.628.507.628-.507L37.5 51zM8 30a1 1 0 102 0H8zm7-24h15V4H15v2zm31 16v29h2V22h-2zM28.6 50.2l-2-1.5-1.2 1.6 2 1.5 1.2-1.6zm19.028.022l-4.75-3.834-1.256 1.557 4.75 3.833 1.256-1.556zm-15.506-3.834l-4.75 3.834 1.256 1.556 4.75-3.833-1.256-1.556zm9.5 0l-4.75 3.834 1.256 1.556 4.75-3.833-1.256-1.556zm-3.494 3.834l-4.75-3.834-1.256 1.557 4.75 3.833 1.256-1.556zM10 30V11H8v19h2zM30 6c8.837 0 16 7.163 16 16h2c0-9.941-8.059-18-18-18v2zM15 4a7 7 0 00-7 7h2a5 5 0 015-5V4z"
      fill="#000"
    />
    <path
      d="M27 5h3a5 5 0 015 5v2.857a5 5 0 005 5h2a5 5 0 015 5V25"
      stroke="#000"
      strokeWidth={2}
    />
    <path
      d="M14 24h16M14 30h16M36 24h5M36 30h5M36 36h5"
      stroke="#000"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x={13} y={12} width={18} height={6} rx={3} fill="#d5ff4f" />
    <circle cx={12} cy={46} r={10} fill="#FFE249" />
    <circle cx={14} cy={44} r={10} stroke="#000" strokeWidth={2} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.638 39.8a4.15 4.15 0 00-3.456 1.85h2.333a.9.9 0 010 1.8h-3a4.192 4.192 0 000 1.008h3a.9.9 0 010 1.8h-2.334a4.15 4.15 0 003.457 1.85c.487 0 .953-.084 1.385-.236a.9.9 0 11.6 1.697 5.956 5.956 0 01-7.476-3.311H8.9a.9.9 0 110-1.8h.806a6.036 6.036 0 010-1.008H8.9a.9.9 0 110-1.8h1.247a5.955 5.955 0 017.476-3.311.9.9 0 01-.6 1.697 4.147 4.147 0 00-1.385-.236z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgBillColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgBillColor56;
