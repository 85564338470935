import React from 'react';
import { useTranslation } from 'react-i18next';
import NewMainForm from '../../components/shared/main-form/NewMainForm';
import Box from '../../components/shared/atoms/Box';
import constants from '../../utils/constants';

const RepairWidget = (props) => {
  const { t } = useTranslation();
  return (
    <Box p={3}>
      <Box style={{ maxWidth: 450 }}>
        <h1>{t('baseline')}</h1>
        <NewMainForm style={{ flex: 1 }} isWidget />
        <img
          src={`${constants.CDN_BASE_URL}/img/logos/roulezjeunesse-main-logo.png`}
          style={{ align: 'center' }}
        />
      </Box>
    </Box>
  );
};

export default RepairWidget;
