import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import Separator from './Separator';
import Heading from './shared/atoms/Heading.jsx';
import Box from './shared/atoms/Box.jsx';
import Container from './shared/atoms/Container.jsx';
import Link from './shared/atoms/Link.jsx';
import Text from './shared/atoms/Text';
import AppSectionLinks from './footer/AppSectionLinks';
import ZonesSectionLinks from './footer/ZonesSectionLinks';
// Icons
import Facebook24 from './icons/Facebook24';
import Instagram24 from './icons/Instagram24';
import LinkedIn24 from './icons/LinkedIn24';
// Utils
import zoneSection from './footer/utils/zoneSection';
import ChangeCountry from '../scenes/ChangeCountry';
import ChooseLanguage from '../scenes/ChooseLanguage';
import { getLang } from '../utils/utils';
import FranceOnly from './FranceOnly';

const StyledText = styled(Text)`
  display: none;
  ${(p) => p.theme.mediaQueries.tablet} {
    display: block;
  }
`;

const appSection = (t) => [
  {
    header: t('footer.headings.1'),
    links: [
      { label: t('footer.labels.1'), href: '/tarifs/velo' },
      {
        label: t('footer.labels.4'),
        href: 'https://blog.roulezjeunesse.com/',
        blank: true,
      },
    ],
  },
  {
    header: t('footer.headings.2'),
    links: [
      { label: t('roulezjeunessebusiness'), to: '/entreprises' },
      { label: t('footer.labels.5'), to: '/reparation' },
      {
        label: t('footer.labels.6'),
        href: '/produits/velo-electrique',
      },
      {
        label: t('footer.labels.14'),
        to: '/annuaire/atelier-velo-trottinette',
      },
    ],
  },
  {
    header: t('footer.headings.3'),
    links: [
      { label: t('footer.labels.10'), href: 'https://faq.roulezjeunesse.com/' },
      { label: t('footer.labels.13'), to: '/devenir-reparateur-velo' },
    ],
  },
  {
    content: (
      <>
        <Box
          my={4}
          flexDirection="row"
          flexWrap="wrap"
          justifyContent={{ md: 'flex-start', lg: 'flex-end' }}>
          <Box flexDirection="row">
            <Link
              color="black"
              href="https://www.facebook.com/people/Roulez-Jeunesse/61559942076250/"
              mx={3}
              before={<Facebook24 />}
            />
            <Link
              color="black"
              href="https://www.instagram.com/roulezjeunessevelo"
              mr={3}
              before={<Instagram24 />}
            />
            <Link
              color="black"
              href="https://www.linkedin.com/company/roulez-jeunesse/"
              before={<LinkedIn24 />}
            />
          </Box>
        </Box>
        <ChangeCountry />
      </>
    ),
  },
];

const Footer = () => {
  const { t } = useTranslation();
  const translateAppSection = appSection(t);
  const translateZoneSection = zoneSection(t);
  return (
    <footer>
      <Separator axe="horizontal" my={0} bg="gray.light" />
      <Container fluid>
        <Container>
          <Box my={{ xs: 5, lg: 7 }} flexDirection="row" flexWrap="wrap">
            {translateAppSection.map((section, idx) => (
              <Box
                key={idx}
                flexGrow={1}
                width={{ xs: 1 / 2, md: 1 / 3, lg: 1 / 4 }}>
                {section.header && (
                  <Heading
                    color="black"
                    level={5}
                    mb={4}
                    style={{ marginTop: 0 }}>
                    {section.header}
                  </Heading>
                )}
                {section.content}
                {section.links && <AppSectionLinks links={section.links} />}
              </Box>
            ))}
          </Box>
          <FranceOnly>
            <Separator axe="horizontal" my={2} bg="gray.light" />
            <Box my={7}>
              <Heading color="black" level={5} my={4}>
                {t('footer.headings.4')}
              </Heading>
              <ZonesSectionLinks zones={translateZoneSection} />
            </Box>
          </FranceOnly>
          <Box
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignItems="baseline"
            py={4}>
            <Box as="ul" flexDirection="row" my={0}>
              <Box as="li" mr={4}>
                <Link variant="small" color="black" to="/mentions-legales">
                  {t('footer.texts.1')}
                </Link>
              </Box>
              <Box as="li" mr={4}>
                <Link
                  variant="small"
                  color="black"
                  href="/static/PolitiqueDeConfidentialite.pdf"
                  target="_blank">
                  {t('footer.texts.2')}
                </Link>
              </Box>
              <Box as="li" mr={4}>
                <Link
                  color="black"
                  href="/static/CGV.pdf"
                  variant="small"
                  target="_blank">
                  {t('CGV')}
                </Link>
              </Box>
            </Box>
            <Text
              mt={{ xs: 5, md: 0 }}
              color="black"
              variant="caption"
              alignSelf={{ xs: 'flex-start', md: 'center' }}>
              {t(`footer.texts.3`, {
                year: new Date().getFullYear(),
              })}
            </Text>
          </Box>
        </Container>
      </Container>
    </footer>
  );
};

export default Footer;
