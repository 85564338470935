import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  useStripe,
  useElements,
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useStripeSetupIntent, useStripePaymentIntent } from '../utils/pay';
import * as api from '../utils/api';
import constants from '../utils/constants';
import { isDev, isStaging } from '../utils/utils';
// Components
import Box from './shared/atoms/Box';
import Text from './shared/atoms/Text';
import PayFormSkeleton from './PayFormSkeleton';

const stripePromise = loadStripe(
  isDev() || isStaging()
    ? constants.STRIPE_KEY_TEST
    : constants.STRIPE_KEY_LIVE,
);

const StyledSpan = styled.span`
  color: ${(p) => p.theme.colors.primary.main};
`;

const StyledBox = styled(Box)`
  .elementStripe {
    font-family: DraftC, Arial, sans-serif;
    font-size: 1em !important;
    font-weight: 400;
    padding: 12px 16px;
    border: 1px solid ${(p) => p.theme.colors.gray.light};
    font-size: 16px;
    background-color: ${(p) => p.theme.colors.gray.lightest};
    border-radius: 16px;
    ::placeholder {
      color: #626a68;
      width: 100%;
    }
  }
  .elementStripeError {
    padding: 12px 16px;
    border: 1px solid ${(p) => p.theme.colors.primary.darkest};
    font-size: 16px;
    background-color: ${(p) => p.theme.colors.primary.lighter};
    border-radius: 16px;
    ::placeholder {
      color: #626a68;
    }
  }
`;

const errorStipe = (error) => {
  switch (error) {
    case 'invalid_number':
    case 'incomplete_number':
      return ['number'];
    case 'invalid_expiry_month':
    case 'expired_card':
      return ['expiry'];
    case 'invalid_cvc':
    case 'incorrect_cvc':
      return ['cvc'];
    case 'card_declined':
    case 'processing_error':
    case 'missing':
    case 'rate_limit':
    case 'invalid_request_error':
    case 'incorrect_number':
      return ['number', 'cvc', 'expiry'];
    default:
      return [];
  }
};

const PayFormNew = ({
  client: propsClient,
  stripePayment,
  setStripePayment,
  doPay = false,
  setLoading = () => {},
  amount,
  course,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const { expiry, cvc, number, message, state } = stripePayment;
  // const codepromo = useSelector((s) => s.course.codepromo);
  const client = useSelector((s) => {
    const { client } = s;
    if (typeof propsClient !== 'undefined') return propsClient;
    return client;
  });

  const [intent, reloadIntent] = doPay
    ? useStripePaymentIntent({
        client_id: client.id,
        amount,
        ...(course ? { course_id: course.id } : {}),
      })
    : useStripeSetupIntent();

  const checkElementsError = (error) => {
    setLoading(false);
    const WhereErrorComeFrom = errorStipe(error.code);
    setStripePayment({
      cvc: !WhereErrorComeFrom.indexOf('cvc'),
      number: !WhereErrorComeFrom.indexOf('number'),
      expiry: !WhereErrorComeFrom.indexOf('expiry'),
      state: 'error',
      message: error.message,
    });
  };

  useEffect(() => {
    const { state } = stripePayment;
    if (state === 'send') _doSend();
  }, [stripePayment]);

  const _doSend = async () => {
    const cardNumber = elements.getElement(CardNumberElement);
    setLoading(true);
    if (doPay) {
      // pay
      const rep = await stripe.confirmCardPayment(intent, {
        payment_method: {
          card: cardNumber,
          billing_details: {
            name: `${client.prenom} ${client.nom}`,
          },
        },
        save_payment_method: true,
      });
      if (rep.error) {
        checkElementsError(rep.error);
      } else {
        let ret;
        if (course.paiement !== 'ATELIER') {
          ret = await api.post(`/courses/${course.id}/payment`, {
            payment_id: rep.paymentIntent.id,
          });
        } else {
          ret = await api.post(
            `/ateliers/${course.id}/participants/${client.id}/payment`,
            { payment_id: rep.paymentIntent.id },
          );
        }

        if (ret.error_message) {
          checkElementsError(rep.error);
          reloadIntent();
        } else {
          setLoading(false);
          setStripePayment({ ...stripePayment, state: 'succes' });
        }
      }
    } else {
      // save card for later
      const rep = await stripe.confirmCardSetup(intent, {
        payment_method: {
          card: cardNumber,
          billing_details: {
            name: `${client.prenom} ${client.nom}`,
          },
        },
      });
      if (rep.error) {
        reloadIntent();
        checkElementsError(rep.error);
      } else {
        const ret = await api.post(`/clients/${client.id}/intents`, {
          id: rep.setupIntent.payment_method,
        });
        if (ret.error_message) {
          checkElementsError(rep.error);
          reloadIntent();
        } else {
          setStripePayment({
            ...stripePayment,
            state: 'succes',
            payment_method: rep.setupIntent.payment_method,
          });
          setLoading(false);
        }
      }
    }
  };

  const setDefaultStripePayment = (e, value) => {
    const { complete } = e;
    setStripePayment({ ...stripePayment, [value]: false, complete });
  };

  return (
    <Box width="100%" m={0}>
      {!intent && <PayFormSkeleton />}
      {intent && (
        <>
          <StyledBox m={0} data-testid="card-number-element">
            <Text
              mb={1}
              as="label"
              color="black"
              variant="small"
              fontWeight="400">
              {t('number.card')}
              <StyledSpan>*</StyledSpan>
            </Text>
            <CardNumberElement
              onChange={(e) => setDefaultStripePayment(e, 'number')}
              className={number ? 'elementStripeError' : 'elementStripe'}
            />
            {number && (
              <Text
                mt={1}
                variant="caption"
                fontWeight="bold"
                data-testid="error-card-number-element"
                color="primary.darkest">
                {message}
              </Text>
            )}
            <Box mt={4} flexDirection={{ xs: 'column', lg: 'row' }}>
              <Box
                mr={{ xs: 0, lg: 2 }}
                width="100%"
                data-testid="card-expiry-element">
                <Text
                  as="label"
                  mb={1}
                  color="black"
                  variant="small"
                  fontWeight="400">
                  {t('expiration')}
                  <StyledSpan>*</StyledSpan>
                </Text>
                <CardExpiryElement
                  onChange={(e) => setDefaultStripePayment(e, 'expiry')}
                  className={expiry ? 'elementStripeError' : 'elementStripe'}
                />
                {expiry && (
                  <Text
                    mt={1}
                    variant="caption"
                    fontWeight="bold"
                    color="primary.darkest">
                    {message}
                  </Text>
                )}
              </Box>
              <Box
                ml={{ xs: 0, lg: 2 }}
                mt={{ xs: 4, lg: 0 }}
                width="100%"
                data-testid="card-cvc-element">
                <Text
                  as="label"
                  mb={1}
                  color="black"
                  variant="small"
                  fontWeight="400">
                  {t('security.code')}
                  <StyledSpan>*</StyledSpan>
                </Text>
                <CardCvcElement
                  onChange={(e) => setDefaultStripePayment(e, 'cvc')}
                  className={cvc ? 'elementStripeError' : 'elementStripe'}
                />
                {cvc && (
                  <Text
                    mt={1}
                    variant="caption"
                    fontWeight="bold"
                    color="primary.darkest">
                    {message}
                  </Text>
                )}
              </Box>
            </Box>
            {message && state === 'error' && (
              <Text
                mt={1}
                variant="caption"
                fontWeight="bold"
                color="primary.darkest">
                {message}
              </Text>
            )}
          </StyledBox>
        </>
      )}
    </Box>
  );
};

const ElementedForm = ({ ...props }) => {
  return (
    <Elements stripe={stripePromise}>
      <PayFormNew {...props} />
    </Elements>
  );
};

export default ElementedForm;
