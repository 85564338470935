import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M40.04 5.142a18.995 18.995 0 00-19.898 4.402 19.009 19.009 0 00-3.002 3.873l7.292 7.256 15.609-15.53z"
      fill="#FFBBB3"
    />
    <path
      d="M12.086 43.743a2 2 0 102.828-2.828 2 2 0 00-2.828 2.828z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.078 13.356a18.759 18.759 0 00-2.692 11.252c.162 2.07-.346 4.206-1.822 5.674l-5.817 5.787a9.299 9.299 0 000 13.198c3.663 3.644 9.601 3.644 13.264 0l5.816-5.788c1.476-1.468 3.622-1.975 5.703-1.813a18.991 18.991 0 0014.898-5.487 18.778 18.778 0 005.039-17.788c-.172-.7-.384-1.392-.638-2.072a23.5 23.5 0 01-1.66 1.307c-9.163 6.568-22.02 5.75-30.264-2.452l-1.827-1.818zm14.607 26.317c-2.457-.192-5.253.384-7.268 2.389L18.6 47.849c-2.882 2.868-7.56 2.868-10.442 0a7.299 7.299 0 010-10.362l5.816-5.787c2.017-2.007 2.598-4.796 2.405-7.249a16.776 16.776 0 011.231-7.746c8.763 8.612 22.24 9.605 32.093 2.985a16.784 16.784 0 01-4.686 15.072 16.992 16.992 0 01-13.332 4.91z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgWrenchkeyColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgWrenchkeyColor56;
