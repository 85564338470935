import { useState } from 'react';

// for old broswers that do not support Session storage + Server side
function isBrowserSupportSessionStorage() {
  if (typeof window !== 'undefined') {
    const storage = window.sessionStorage;
    try {
      storage.setItem('test', 'test');
      storage.removeItem('test');
      return true;
    } catch (e) {
      return false;
    }
  }
  return false;
}

export default function usePersistantState(
  key,
  initialValue,
  storageType = 'localStorage',
) {
  const [saveToStore] = useState(isBrowserSupportSessionStorage());
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window !== 'undefined') {
      if (saveToStore) {
        const item = window[storageType].getItem(key);
        return item ? JSON.parse(item) : initialValue;
      }
    }
    return initialValue;
  });

  const setValue = (value) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);
    if (saveToStore) {
      window[storageType].setItem(key, JSON.stringify(valueToStore));
    }
  };

  return [storedValue, setValue];
}
