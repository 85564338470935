import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#Box_Color_56_svg__clip0)">
      <path
        d="M36 24L13 13l6.5-3.5L43 21v7a1 1 0 01-1 1h-2l-2.342 2.049c-.646.566-1.658.106-1.658-.753V24z"
        fill="#B0BDFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 32a1 1 0 011-1h12a1 1 0 110 2H1a1 1 0 01-1-1z"
        fill="#1F77F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 27a1 1 0 011-1h3a1 1 0 110 2H3a1 1 0 01-1-1z"
        fill="#28D898"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 37.5a1 1 0 011-1h7a1 1 0 110 2H4a1 1 0 01-1-1z"
        fill="#FFE249"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 43a1 1 0 011-1h6a1 1 0 110 2H1a1 1 0 01-1-1z"
        fill="#8A38F3"
      />
      <path
        d="M43.385 37.034a1 1 0 01-.42 1.35l-9.5 5a1 1 0 11-.93-1.769l9.5-5a1 1 0 011.35.42zM38.979 36.378a1 1 0 00-.958-1.756l-5.5 3a1 1 0 10.958 1.756l5.5-3z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 3.882l-24 12V22.5a1 1 0 102 0v-4.382l21 10.5v20.764L12.155 41.96a1 1 0 00-.894 1.789L28 52.118l24-12V15.882l-24-12zm1 45.5l21-10.5V18.118l-21 10.5v20.764zM48.764 16.5L28 6.118 7.236 16.5 28 26.882 48.764 16.5z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="Box_Color_56_svg__clip0">
        <path fill="#fff" d="M0 0h56v56H0z" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgBoxColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgBoxColor56;
