import React from 'react';
import { withRouter } from 'react-router';

class ScrollToTopOnMount extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location &&
      this.props.history.action === 'PUSH'
    ) {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 1);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTopOnMount);
