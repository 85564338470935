import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M18.147 9.853l1.647-6.147c-2.11-.322-7.023-.564-9.794 1.036-2.771 1.6-5.184 5.687-6.044 7.53l6.83 1.83c.472-.849 1.761-2.748 3.147-3.548 1.386-.8 3.387-.8 4.214-.701zM14.853 22.147l-1.647 6.147c2.11.322 7.023.564 9.794-1.036 2.771-1.6 5.184-5.687 6.044-7.53l-6.83-1.83c-.472.849-1.761 2.748-3.147 3.548-1.386.8-3.387.8-4.214.701z"
      fill="#FFBBB3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.376 23C.51 16.304 2.804 7.742 9.5 3.876 16.196.01 24.758 2.304 28.624 9c3.866 6.696 1.572 15.258-5.124 19.124C16.804 31.99 8.242 29.696 4.376 23zm1.732-1a11.952 11.952 0 01-1.418-8.13l4.859 1.303a6.969 6.969 0 00.889 4.327 6.969 6.969 0 003.303 2.934l-1.302 4.858A11.952 11.952 0 016.108 22zM20 22.062a6.969 6.969 0 01-4.327.89L14.37 27.81c2.695.487 5.571.06 8.129-1.418a11.952 11.952 0 005.292-6.331l-4.858-1.302A6.97 6.97 0 0120 22.062zm8.31-3.933l-4.859-1.302a6.969 6.969 0 00-.889-4.327 6.968 6.968 0 00-3.303-2.934l1.302-4.858A11.953 11.953 0 0126.892 10a11.952 11.952 0 011.418 8.13zM18.63 4.19L17.326 9.05A6.97 6.97 0 0013 9.938a6.97 6.97 0 00-2.934 3.303l-4.858-1.302A11.952 11.952 0 0110.5 5.608a11.952 11.952 0 018.13-1.418zM12.17 18.5a5 5 0 118.66-5 5 5 0 01-8.66 5z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgHelpColor32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgHelpColor32;
