import React from 'react';
import SvgCheck24 from '../../icons/Check24';
import SvgInfos24 from '../../icons/Infos24';
import SvgWarning24 from '../../icons/Warning24';
import SvgDanger24 from '../../icons/Danger24';
import Box from '../atoms/Box';
import Card from '../atoms/Card';
import Heading from '../atoms/Heading';
import Text from '../atoms/Text';

/**
 * @param {string} status info | warning | danger | success
 * @param {string | null} title when added displays TitledAlert
 * @param {any} any extend Styled System space props
 */

const switchStatus = (str) => {
  switch (str) {
    default:
    case 'info':
      return {
        bg: 'blue.pale',
        text: 'blue.dark',
        icon: <SvgInfos24 mr={2} color="blue.dark" />,
      };
    case 'success':
      return {
        bg: 'green.pale',
        text: 'green.dark',
        icon: <SvgCheck24 mr={2} color="green.dark" />,
      };
    case 'warning':
      return {
        bg: 'yellow.pale',
        text: 'yellow.dark',
        icon: <SvgWarning24 mr={2} color="yellow.dark" />,
      };
    case 'danger':
      return {
        bg: 'primary.pale',
        text: 'primary.darkest',
        icon: <SvgDanger24 mr={2} color="primary.darkest" />,
      };
    case 'none':
      return {
        bg: 'gray.lightest',
        text: 'black',
        icon: <></>,
      };
  }
};

export default function Alert({
  status = 'info',
  title = null,
  children,
  ...props
}) {
  const { bg, text, icon } = switchStatus(status);
  return title ? (
    <Card
      bg="white"
      p={0}
      borderRadius="xs"
      border="2px solid"
      borderColor={bg}
      {...props}>
      <Box
        px={4}
        py={2}
        flexDirection="row"
        bg={bg}
        style={{ alignItems: 'center' }}>
        {icon}
        <Heading level={6} mt={2} color={text}>
          {title}
        </Heading>
      </Box>
      <Box p={4}>
        <Text>{children}</Text>
      </Box>
    </Card>
  ) : (
    <Card bg={bg} p={4} flexDirection="row" borderRadius="xs" {...props}>
      <div>{icon}</div>
      <Text color={text}>{children}</Text>
    </Card>
  );
}
