import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M10 12c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 19c4.55 0 8.427-2.903 10-7-1.573-4.097-5.45-7-10-7-4.545 0-8.427 2.903-10 7 1.573 4.097 5.455 7 10 7zm0-2c-3.397 0-6.374-2.013-7.822-5C5.626 9.013 8.603 7 12 7c3.4 0 6.375 2.012 7.822 5-1.447 2.988-4.422 5-7.822 5z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgEye24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgEye24;
