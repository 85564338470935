import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { colors, shadows } from '../styles/constants';
import { resumeReparation } from '../utils/reparation';
import constants from '../utils/constants';
import { resetSlots as resetSlotsAction } from '../actions/course';

const ResumeCourseWrapper = styled.div`
  position: fixed;
  text-align: center;
  z-index: 3;

  top: auto;
  bottom: 10px;
  left: 10px;
  right: auto;
`;

const ResumeCourseElt = styled.div`
  display: inline-block;
  background-color: white;
  padding: 15px 20px;
  border-radius: 200px;
  border: 1px solid ${colors.lightgrey};
  cursor: pointer;

  img {
    vertical-align: middle;
    margin-right: 5px;
  }

  ${shadows.box}
`;

const ResumeCourse = ({ title, resetSlots, ...props }) => {
  const { t } = useTranslation();
  const resumeCourseHandler = () => {
    resetSlots();
    if (
      typeof window !== 'undefined' &&
      typeof window.dataLayer !== 'undefined'
    ) {
      window.dataLayer.push({
        event: 'form_resume',
        step: 'IDK',
      });
    }
    resumeReparation(props.state, props.history);
  };
  return (
    <ResumeCourseWrapper {...props}>
      <ResumeCourseElt onClick={resumeCourseHandler}>
        <img
          src={`${constants.CDN_BASE_URL}/img/pictos/rocket.png`}
          height={25}
          alt={title || t('resumecourse.text')}
        />{' '}
        {title || t('resumecourse.text')}
      </ResumeCourseElt>
    </ResumeCourseWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetSlots: () => dispatch(resetSlotsAction()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResumeCourse),
);
