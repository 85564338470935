import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/constants';
import constants from '../../utils/constants';

const SuccessLabelWrapper = styled.span`
  color: ${colors.green};
  font-weight: 500;
  vertical-align: middle;
`;

const SuccessLabel = ({ children, ...props }) => (
  <div {...props}>
    {!!children && (
      <img
        src={`${constants.CDN_BASE_URL}/img/pictos/check.png`}
        height={9}
        style={{ marginRight: 10 }}
      />
    )}
    <SuccessLabelWrapper>{children}</SuccessLabelWrapper>
  </div>
);

export default SuccessLabel;
