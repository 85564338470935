import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M14 31a1 1 0 011-1h15a1 1 0 110 2H15a1 1 0 01-1-1zM15 42a1 1 0 100 2h15a1 1 0 100-2H15zM25 37a1 1 0 011-1h15a1 1 0 110 2H26a1 1 0 01-1-1zM35 30a1 1 0 100 2h6a1 1 0 100-2h-6zM14 37a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
      fill="#010202"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9 24.4c.6.6 1.4.9 2.3.9.8 0 1.6-.3 2.4-.9l2.5-2.5c1.1.2 3.6.5 5.3-.8 2.1-1.6 3.3-4 3-6.2-.1-.4-.2-.8-.5-1.1-.2-.3-.6-.5-1-.6-.7-.2-1.5.1-2 .6l-2 2-1.1-.263c-.05-.15-.1-.334-.15-.518-.05-.185-.1-.369-.15-.519l2-2c.5-.6.7-1.3.6-2-.1-.4-.3-.7-.6-1-.3-.3-.7-.5-1.1-.5-2.2-.2-4.6.9-6.2 3-1.3 1.7-1 4.2-.8 5.4l-2.5 2.5c-1.2 1.2-1.2 3.3 0 4.5zm4.9-11.2c1.1-1.4 2.6-2.2 4-2.2.1 0 .2 0 .4-.1l-.1.1-2.6 2.5.4 3.5 3.6 1 2.8-2.8c0-.1.1-.1.1-.1.2 1.5-.7 3.1-2.2 4.3-1.2.9-3.4.5-4.2.3l-.4-.1-.4.3-3.1 3.1c-.5.5-1.2.5-1.7 0s-.5-1.2 0-1.7l2.9-2.9.3-.5-.1-.5c-.2-.8-.6-3 .3-4.2z"
      fill="#010202"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48 44a8 8 0 01-8 8H16a8 8 0 01-8-8V12a8 8 0 018-8h14c9.941 0 18 8.059 18 18v22zM16 6h14a4 4 0 014 4v2.857a6 6 0 006 6h2a4 4 0 014 4V44a6 6 0 01-6 6H16a6 6 0 01-6-6V12a6 6 0 016-6zm29.49 11.976A16.034 16.034 0 0035.094 6.828c.574.92.906 2.007.906 3.172v2.857a4 4 0 004 4h2c1.302 0 2.506.415 3.49 1.119z"
      fill="#010202"
    />
    <path
      d="M50 37a3 3 0 10-6 0v5h-3a3 3 0 100 6h3v5a3 3 0 106 0v-5h3a3 3 0 100-6h-3v-5z"
      fill="#1F77F9"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgRecordaddColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgRecordaddColor56;
