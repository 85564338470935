import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.623 43.626l.381.374.409-.4C30.829 37.296 40 28.29 40 19.766 40 11.062 32.841 4 24.005 4 15.168 4 8 11.062 8 19.767c0 8.535 9.196 17.555 15.623 23.859zM24.074 26c3.329 0 6.027-2.686 6.027-6s-2.698-6-6.027-6c-3.328 0-6.027 2.686-6.027 6s2.699 6 6.027 6z"
      fill="#d5ff4f"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgPinGoogle = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPinGoogle;
