import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 57 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12.88 22.423C15.323 15.199 22.16 10 30.207 10c10.101 0 18.29 8.189 18.29 18.29 0 10.101-8.189 18.29-18.29 18.29-8.41 0-15.496-5.677-17.631-13.41a1 1 0 00-1.928.533C13.018 42.28 20.876 48.58 30.208 48.58c11.206 0 20.29-9.084 20.29-20.29S41.414 8 30.208 8c-8.825 0-16.332 5.634-19.125 13.5a3.238 3.238 0 01-.206-.544c-.13-.433-.252-1.002-.43-1.84L8.478 9.853a1 1 0 10-1.956.416l1.977 9.303c.168.788.306 1.438.462 1.958.162.54.365 1.025.708 1.448a4 4 0 002.073 1.347c.527.14 1.052.13 1.612.058.538-.07 1.188-.208 1.976-.375l9.303-1.977a1 1 0 10-.416-1.957l-9.263 1.97c-.838.177-1.407.297-1.855.355-.08.01-.153.018-.22.024z"
      fill="#000"
    />
    <path
      d="M34.649 33.88a3.037 3.037 0 00-.019 4.293l4.678 4.722-4.92 4.922a3.037 3.037 0 000 4.294 3.033 3.033 0 004.292 0l4.9-4.904 4.731 4.774a3.033 3.033 0 004.291.018 3.037 3.037 0 00.019-4.293l-4.75-4.793 4.549-4.55a3.037 3.037 0 000-4.294 3.033 3.033 0 00-4.29 0l-4.53 4.532-4.66-4.703a3.033 3.033 0 00-4.291-.018z"
      fill="#d5ff4f"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgCancelColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgCancelColor56;
