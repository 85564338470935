import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as api from '../../utils/api';
import { useInterval } from '../../utils/utils';
// Modals
import {
  UpdatePhotos,
  UpdateDetails,
  UpdateDisponibility,
  UpdateAdresse,
  ProblemModal,
  CancelModal,
  ContactModal,
} from '../../components/customerArea/courseDetails/Modals';
import NoFixerModal from '../../modals/NoFixerModal';
// Components
import Box from '../../components/shared/atoms/Box';
import Row from '../../components/shared/atoms/Row';
import CourseSkeleton from '../CourseSkeleton';
import Link from '../../components/shared/atoms/Link';
import Heading from '../../components/shared/atoms/Heading';
import Container from '../../components/shared/atoms/Container';
import CourseBanner from '../../components/customerArea/courseDetails/CourseBanner';
import SideActions from '../../components/customerArea/courseDetails/SideActions';
import CourseDetails from '../../components/customerArea/courseDetails/CourseDetails';
import Alert from '../../components/shared/molecules/Alert';
import RequestCard from '../../components/customerArea/courseDetails/RequestCard';
import AngleLeft16 from '../../components/icons/AngleLeft16';

const Course = ({ match }) => {
  const { t } = useTranslation();
  const [relatedPhotos, setRelatedPhotos] = useState([]);
  const { client } = useSelector((s) => s);
  const history = useHistory();
  const [course, setCourse] = useState(false);
  const [requests, setRequests] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [additionalProps, setAdditionalProps] = useState({});
  const [waitinglistSent, setWaitingListSent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showAlertProblem, setShowAlertProblem] = useState(false);

  const _submitWaitingList = async (values, { setError, setSubmitting }) => {
    const waiting = await api.post(`/waitinglist`, {
      position: course.position,
      product_id: course.product_id,
      ...values,
    });
    if (waiting.ok) setWaitingListSent(true);
    else {
      setError(t('wronginformations'));
      setSubmitting(false);
    }
  };

  const getRelatedPhotos = async () => {
    const getRelatedPhotos = await api.get(
      `/courses/${match.params.cid}/photos`,
    );
    setRelatedPhotos(getRelatedPhotos);
  };

  const getCourse = async () => {
    const getCourse = await api.get(`/courses/${match.params.cid}`);

    if (getCourse.error_message) {
      history.push(`/login${window.location.search}?redir=${match.params.cid}`);
      return;
    }
    setCourse(getCourse);

    setLoading(false);
  };

  const getRequest = async () => {
    let requests = await api.get(`/requests?course_ids[]=${match.params.cid}`);
    if (requests.error_message) {
      return;
    }
    requests = requests.map((r) => {
      r.data = JSON.parse(r.data);
      return r;
    });
    setRequests(requests.filter((r) => r.seen == 0));
  };

  const cancelCourse = async (cancelCategories) => {
    const cancelCategorie = Object.values(cancelCategories).find(
      (c) => c.isChecked === true,
    );
    await api.put(`/courses/${course.id}`, {
      status: 'ANNULE',
    });
    setCourse({ ...course, state: 'cancelled', status: 'ANNULE' });
    if (cancelCategorie)
      await api.put(`/courses/${course.id}/ctags`, { id: cancelCategorie.id });
  };

  // Modal States and Functions
  const propsModal = {
    course,
    setCourse,
    setShowAlertProblem,
    showModal,
    getRelatedPhotos,
    getCourse,
    getRequest,
    cancelCourse,
    additionalProps,
  };
  const openModal = (which, additionalProps = {}) => {
    setShowModal(which);
    setAdditionalProps(additionalProps);
  };

  useEffect(() => {
    getCourse();
    getRelatedPhotos();
    getRequest();
  }, []);

  useInterval(() => {
    getCourse();
    getRelatedPhotos();
    getRequest();
  }, 300000);

  if (loading) {
    return <CourseSkeleton />;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    await _submitWaitingList();
    window.location.href = '/';
  };

  return (
    <>
      {/*  All Modal */}
      <UpdatePhotos {...{ ...propsModal, openModal }} />
      <UpdateDetails {...{ ...propsModal, openModal }} />
      <UpdateAdresse {...{ ...propsModal, openModal }} />
      <UpdateDisponibility {...{ ...propsModal, openModal }} />
      <CancelModal {...{ ...propsModal, openModal }} />
      <ProblemModal {...{ ...propsModal, openModal }} />
      <ContactModal {...{ ...propsModal, openModal }} />
      <NoFixerModal
        open={showModal === 'NO_FIXER'}
        waitinglistSent={waitinglistSent}
        onSubmit={handleSubmit}
        close={() => (window.location.href = '/')}
        fixer={null}
      />

      {/*  -------- */}
      <Container fluid>
        <Container mt={{ xs: 3, lg: 5 }}>
          <Link to="/account" before={<AngleLeft16 />} color="blue.electric">
            {t('cta.back')}
          </Link>
        </Container>
      </Container>
      <Container fluid mt={4}>
        <CourseBanner course={course} openModal={openModal} />
        <Container mt={{ xs: 7, lg: 10 }}>
          {showAlertProblem == 'success' && (
            <Alert mb={5} status="success">
              {t('course.alert.success')}
            </Alert>
          )}
          {showAlertProblem == 'failure' && (
            <Alert mb={5} status="danger">
              {t('course.alert.failure')}
            </Alert>
          )}
          <Row gap={56} md mt={{ xs: 7, lg: 10 }} mb={9}>
            <Box style={{ flex: 5 }}>
              <CourseDetails
                course={course}
                client={client}
                openModal={openModal}
                relatedPhotos={relatedPhotos}
              />
            </Box>
            <Box style={{ flex: 3 }} mt={{ xs: -4, lg: 0 }} mb={7}>
              <SideActions course={course} openModal={openModal} />
              {requests.length > 0 && (
                <>
                  <Heading level={5} mt={9}>
                    {t('course.request.title')}
                  </Heading>
                  {requests.map((request) => {
                    return <RequestCard key={request.id} request={request} />;
                  })}
                </>
              )}
            </Box>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Course;
