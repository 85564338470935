import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M8 7.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" fill="#1D1D1D" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.059 13.936a92.493 92.493 0 01-.917.924l-.14.14-.132-.131-.31-.31c-.2-.201-.407-.409-.616-.622C4.873 11.822 2.5 9.107 2.5 6.519 2.5 3.472 4.964 1 8.002 1 11.039 1 13.5 3.472 13.5 6.519c0 2.587-2.373 5.303-4.441 7.417zM5.62 10.172c.712.948 1.555 1.856 2.38 2.7.826-.844 1.668-1.752 2.38-2.7C11.427 8.777 12 7.54 12 6.52 12 4.295 10.205 2.5 8.002 2.5A4.011 4.011 0 004 6.519c0 1.02.573 2.258 1.62 3.653z"
      fill="#1D1D1D"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgPin16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPin16;
