import {
  SET_ADDRESS,
  TOGGLE_PB_ID,
  SELECT_DATE,
  SET_AVAILABLE,
  SET_PRODUCT_ID,
  SET_BIKE_DETAILS,
  SET_COURSE_DETAILS,
  UPDATE_BIKES,
  UPDATE_BIKE_DESCRIPTION,
  ADD_NEW_BIKE,
  SET_POSITION_INPUT,
  SET_POSITION_LOADING,
  SET_CODEPROMO,
  SET_FIXED,
  SET_FIXER_ID,
  SET_FIXER_CODE,
  SET_PHOTOS,
  RESET_COURSE,
  PUSH_SLOT,
  POP_SLOT,
  SET_SLOT,
  RESET_PHOTOS,
  SET_PLACE_ID,
  RESET_POSITION_INPUT,
  RESET_SLOTS,
  SET_SHOPS,
  SET_SELECTED_SHOP,
  SET_BOOKING_UUID,
  SET_IS_IN_ZONE,
} from '../actions/course.jsx';

export const defaultCourse = {
  position: '',
  positionInput: '',
  positionLoading: false,
  positionError: '',
  date: false,
  bike_details: '',
  bikes: [],
  course_details: '',
  available_min: '',
  available_max: '',
  product_id: 1,
  codepromo: '',
  fixed: 1,
  slots: [],
  selectedShop: null,
  photos: [],
  placeId: '',
  fixerId: '',
  fixerCode: null,
  shops: [],
  zone_id: null,
  booking_uuid: '', // Add the new field to the default state
  isInZone: null,
};

export const selectFirstQuestionID = (state) => {
  const { product_id } = state.course;
  if (state.course.selectedShop) {
    const { first_questions_ids } = state.course.selectedShop;
    if (!first_questions_ids) {
      return null;
    }
    if (!first_questions_ids.hasOwnProperty(product_id)) {
      return null;
    }
    return first_questions_ids[product_id];
  }
  return null;
};

export default function course(state = defaultCourse, action) {
  switch (action.type) {
    case SET_POSITION_INPUT:
      return { ...state, positionInput: action.position };
    case RESET_POSITION_INPUT:
      return { ...state, ...action.payload };
    case SET_POSITION_LOADING:
      return { ...state, positionLoading: action.loading };
    case SET_PLACE_ID:
      return { ...state, placeId: action.id, slots: [] };
    case SET_SHOPS:
      return { ...state, shops: action.shops };
    case SET_SELECTED_SHOP:
      const { shop } = action;
      let addedState = {
        selectedShop: shop
          ? {
            ...shop,
          }
          : null,
      };

      if (action.shop && action.shop !== null) {
        addedState = {
          ...addedState,
          ...{
            placeId: '',
            position: shop.address,
            positionInput: shop.address,
            positionError: '',
            fixerId: defaultCourse.fixerId,
          },
        };
      }
      return {
        ...state,
        ...addedState,
      };
    case RESET_SLOTS:
      return { ...state, slots: [] };
    case SET_ADDRESS:
      const positionInputt = action.error
        ? state.positionInput
        : action.position;
      return {
        ...state,
        position: action.position,
        positionError: action.error,
        positionInput: positionInputt,
      };
    case TOGGLE_PB_ID:
      const pbIDs = [...state.pbIDs];
      ~pbIDs.indexOf(action.id)
        ? pbIDs.splice(pbIDs.indexOf(action.id), 1)
        : pbIDs.push(action.id);
      return { ...state, pbIDs };
    case SELECT_DATE:
      return { ...state, date: action.date };
    case SET_BIKE_DETAILS:
      return { ...state, bike_details: action.bike_details };
    case UPDATE_BIKE_DESCRIPTION:
      const { index, description } = action.payload;
      const updatedBikes = state.bikes.map((bike, i) =>
        i === index ? { ...bike, description } : bike,
      );
      return {
        ...state,
        bikes: updatedBikes,
      };
    case UPDATE_BIKES:
      return {
        ...state,
        bikes: action.bikes,
      };
    case ADD_NEW_BIKE:
      return {
        ...state,
        bikes: [...state.bikes, action.payload],
      };
    case SET_COURSE_DETAILS:
      return { ...state, course_details: action.course_details };
    case SET_CODEPROMO:
      return { ...state, codepromo: action.codepromo };
    case SET_FIXED:
      return { ...state, fixed: action.fixed };
    case SET_FIXER_CODE:
      return { ...state, fixerCode: action.fixerCode };
    case SET_FIXER_ID:
      return { ...state, fixerId: action.payload };
    case SET_PHOTOS:
      return { ...state, photos: action.photos };
    case RESET_PHOTOS:
      return { ...state, photos: [] };
    case 'SET_ZONE_ID':
      return { ...state, zone_id: action.zone_id };
    case SET_PRODUCT_ID:
      return {
        ...state,
        product_id: 1,
      };
    case SET_AVAILABLE:
      const { minMax, val } = action;
      const newState = { ...state };
      newState[`available_${minMax}`] = val;
      return newState;
    case PUSH_SLOT:
      const slotss = [...state.slots];
      const { available_min, available_max } = action;
      slotss.push({ available_min, available_max });
      return { ...state, slots: slotss };
    case POP_SLOT:
      let slots = [...state.slots];
      slots = slots.filter(
        (s) =>
          s.available_min !== action.available_min &&
          s.available_max !== action.available_max,
      );
      return { ...state, slots };
    case SET_SLOT:
      return {
        ...state,
        slots: [
          {
            available_min: action.available_min,
            available_max: action.available_max,
          },
        ],
      };
    case RESET_COURSE:
      const toReset = {
        date: defaultCourse.date,
        available_min: defaultCourse.available_min,
        available_max: defaultCourse.available_max,
        isActiveFavorite: defaultCourse.isActiveFavorite,
      };

      if (action.full) {
        toReset.bike_details = defaultCourse.bike_details;
        toReset.course_details = defaultCourse.course_details;
        toReset.codepromo = defaultCourse.codepromo;
        toReset.fixed = defaultCourse.fixed;
        toReset.slots = defaultCourse.slots;
        toReset.photos = defaultCourse.photos;
        toReset.fixerId = defaultCourse.fixerId;
      }

      return { ...state, ...toReset };
    case SET_BOOKING_UUID:
      return { ...state, booking_uuid: action.bookingUUID };
    case SET_IS_IN_ZONE:
      return { ...state, isInZone: action.payload };
    default:
      return state;
  }
}
