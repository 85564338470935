/* eslint-disable no-alert */
import React from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
// Components
import Heading from '../shared/atoms/Heading';
import HelpCard from '../shared/molecules/HelpCard';
import Text from '../shared/atoms/Text';
import Box from '../shared/atoms/Box';
// icons
import CardsCheckout from '../shared/molecules/CardsCheckout';
import ArrowRight24 from '../icons/ArrowRight24';

const FirstLetterUpperCase = styled.span`
  text-transform: capitalize;
`;

const StyledSpan = styled.span`
  color: ${(p) => p.theme.colors.blue.electric};
  box-shadow: inset 0 -10px 0 ${(p) => p.theme.colors.yellow.lighter};
`;

export default function RepairCheckoutWithPayment({
  cbs,
  _setCard,
  card,
  client,
  stripePayment,
  setStripePayment,
  loading,
}) {
  const { t } = useTranslation();
  return (
    <>
      <Heading level={2} mb={{ xs: 4, lg: 5 }}>
        {t('laststep')}{' '}
        <FirstLetterUpperCase>
          {client?.prenom?.replace(/\s/g, '') || null}
        </FirstLetterUpperCase>
        . {t('repaircheckout.headings.1')}
      </Heading>
      <Box flexDirection="row" mb={5}>
        <ArrowRight24 />
        <Text ml={2} color="black" fontSize="lg">
          {t('whyfillmycard.info')}
        </Text>
      </Box>
      <Heading level={5}>{t('repaircheckout.headings.2')}</Heading>
      <CardsCheckout
        {...{
          cbs,
          _setCard,
          card,
          client,
          stripePayment,
          setStripePayment,
          loading,
        }}
      />
      <HelpCard title={t('whyfillmycard')} p={5}>
        <Text color="gray.darkest">
          {t('whyfillmycard.reasons.1')}
          <b>
            <Trans
              i18nKey="whyfillmycard.reasons.2"
              components={{ color: <StyledSpan /> }}
            />
          </b>
        </Text>
      </HelpCard>
    </>
  );
}
