import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
// Components
import Box from '../atoms/Box';
import Text from '../atoms/Text';
import Link from '../atoms/Link';
// Icons
import SvgEdit16 from '../../icons/Edit16';

const StyledBox = styled(Box)`
  max-width: 700px;
  display: ${(p) => (p.disabled ? 'none' : 'flex')};
`;

const StyledUl = styled.ul`
  list-style: none;
  margin: 0;
`;
const StyledLi = styled.li`
  display: flex;
  flex-direction: row;
  min-height: 50px;
  padding-left: ${(p) => (p.notInFunnel ? '0px' : '32px')};
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    padding-left: 0;
  }
`;

const Progress = styled.div`
  ${(p) => {
    return css`
      margin-top: 4px;
      margin-bottom: -4px;
      height: ${p.$end ? '5%' : '100%'};
      width: 2px;
      background-color: ${p.theme.colors.gray.main};
      border-radius: 10%;
    `;
  }}
`;

const StyledFiller = styled.div`
  ${(p) => {
    if (p.first) {
      return css`
        margin-top: 4px;
        margin-bottom: -4px;
        height: ${p.valided ? '100%' : '0%'};
        width: 100%;
        background-color: ${p.valided
          ? p.theme.colors.blue.electric
          : 'white'};
        border-radius: inherit;
        transition: all 1s ease;
      `;
    }
    return css`
      margin-top: 4px;
      margin-bottom: -4px;
      height: ${p.valided
        ? '100%'
        : p.progressHeight
          ? `${p.progressHeight}px`
          : '0%'};
      width: 100%;
      background-color: ${p.theme.colors.blue.electric};
      border-radius: inherit;
      transition: all 1s ease;
    `;
  }}
`;

const StyledCheck = styled.div`
  position: absolute;
  width: 16px;
  height: 10px;
  left: 0;
  top: 6px;
  border-radius: 24px;
  box-shadow: 0 0 0 5px ${(p) => p.shadowColor || 'white'};
  ${(p) => {
    if (p.checkColor) {
      return css`
        background-color: ${p.checkColor === 'black'
          ? p.theme.colors[p.checkColor]
          : p.theme.colors[p.checkColor].main};
      `;
    }
    return css`
      background-color: ${p.inProgress || p.valided
        ? p.theme.colors.blue.electric
        : p.theme.colors.gray.main};
    `;
  }}
  ${(p) => {
    if (p.animation) {
      return css`
        transition: all 2s ease;
      `;
    }
  }}
`;

const Container = styled(Box)`
  min-width: 16px;
  position: relative;
  align-items: center;
`;

const StyledLink = styled(Link)`
  :hover {
    color: ${(p) =>
    p.$valided
      ? p.theme.colors.black
      : p.$inProgress
        ? p.theme.colors.black
        : p.theme.colors.gray.dark} !important;
    cursor: ${(p) => (p.$valided ? 'pointer' : 'default')} !important;
    opacity: 1;
    & > .icon-link > path {
      fill: ${(p) => p.theme.colors.black} !important;
    }
  }
`;

const StyledImage = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 8px;
  margin-right: 8px;
`;

const TextDiv = styled.div`
  font-size: ${(p) => (p.notInFunnel ? '1em' : '0.875em')};
  line-height: 1.5;
  padding-left: 24px;
  letter-spacing: '-0.01em';
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    font-size: 1em;
    line-height: 1.5;
  }
`;

const SideBar = ({
  inProgress,
  width,
  valided,
  title,
  checkColor = false,
  shadowColor = false,
  animation = true,
  content = [],
  link = false,
  first = false,
  end = false,
  diag = false,
  questions = [],
  photosDisplay = [],
  notInFunnel = false,
  colorTitle = 'gray.darkest',
  disabled = false,
}) => {
  const progressHeight =
    diag && inProgress && !!questions.length
      ? questions.length * 19 * 2 + 15
      : null;
  return (
    <StyledBox width={width} disabled={disabled}>
      <StyledUl>
        <StyledLi notInFunnel>
          <Container>
            <Progress first={first} $end={end}>
              <StyledFiller {...{ valided, first, progressHeight }}>
                <StyledCheck
                  {...{
                    inProgress,
                    valided,
                    checkColor,
                    animation,
                    shadowColor,
                    $end: { end },
                  }}
                />
              </StyledFiller>
            </Progress>
          </Container>
          <Box width="100%" mb={notInFunnel ? 6 : 4}>
            {link ? (
              <StyledLink
                $inProgress={inProgress}
                $valided={valided}
                variant="medium"
                color={
                  inProgress ? 'black' : valided ? 'gray.darker' : 'gray.dark'
                }
                after={link && valided && <SvgEdit16 />}
                pl={inProgress ? 4 : 5}
                to={valided && link}
                hoverIcon>
                <Text
                  color={colorTitle}
                  variant={{ xs: 'large', md: 'medium' }}
                  fontWeight="bold">
                  {title}&nbsp;
                </Text>
              </StyledLink>
            ) : (
              <Text
                pl={5}
                variant={{ xs: 'large', md: 'medium' }}
                fontWeight="bold">
                {title}&nbsp;
              </Text>
            )}
            {content.map((text, index) => (
              <TextDiv key={index} notInFunnel>
                {text}
              </TextDiv>
            ))}
            {photosDisplay && (
              <Box flexDirection="row" pl={5}>
                {photosDisplay.map((photo) => (
                  <StyledImage key={photo} src={photo} />
                ))}
              </Box>
            )}
          </Box>
        </StyledLi>
      </StyledUl>
    </StyledBox>
  );
};

SideBar.propTypes = {
  valided: PropTypes.bool,
  inProgress: PropTypes.bool,
  content: PropTypes.array,
  photosDisplay: PropTypes.array,
  questions: PropTypes.array,
  first: PropTypes.bool,
  end: PropTypes.bool,
  diag: PropTypes.bool,
};

export default SideBar;
