import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M6 5a1 1 0 10-2 0v1.044c0 1.363 0 2.447.071 3.321.074.896.227 1.66.583 2.359a6 6 0 002.622 2.622c.7.356 1.463.51 2.359.583.874.071 1.958.071 3.321.071h5.624c-.147.153-.327.333-.55.556l-2.737 2.737a1 1 0 001.414 1.414l2.765-2.764c.372-.373.694-.695.937-.981.257-.303.485-.631.616-1.035a3 3 0 000-1.854c-.131-.404-.36-.732-.616-1.035-.243-.286-.565-.608-.937-.98l-2.765-2.765a1 1 0 10-1.414 1.414l2.737 2.737c.223.223.403.403.55.556H13c-1.417 0-2.419 0-3.203-.065-.771-.063-1.243-.182-1.613-.371a4 4 0 01-1.748-1.748c-.189-.37-.308-.842-.371-1.613C6 8.419 6 7.417 6 6V5z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgArrowEnterRight24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgArrowEnterRight24;
