import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { useHistory, useLocation } from 'react-router';

import LogoSelector from './signupLanding/LogoSelector';
import PartenaireContext from '../contexts/PartenaireContext';
import Separator from './Separator';
import ToastContainer from './ToastContainer';
import Box from './shared/atoms/Box';
import Card from './shared/atoms/Card';
import List from './shared/atoms/List';
import Text from './shared/atoms/Text';
import Link from './shared/atoms/Link';
import Container from './shared/atoms/Container';
import Button from './shared/atoms/Button';

import SvgAngleDown from './icons/AngleDown16';
import SvgAngleUp from './icons/AngleUp16';
import SvgQuestion from './icons/Question24';
import SvgPrice from './icons/Price24';
import SvgBuildingColor32 from './icons/BuildingColor32';
import SvgHelpColor32 from './icons/HelpColor32';
import WrenchkeyColor32 from './icons/WrenchkeyColor32';
import BikeHelmet from './icons/BikeHelmet';

import { useScrollYPosition } from '../hooks/useScrollPosition';

import constants from '../utils/constants';
import useDeviceWidth from '../hooks/useDeviceWidth';
import { FeaturesContext } from '../contexts/FeaturesContext';
import WidgetHeader from './WidgetHeader';
import { colors } from '../styles/constants';
import useResaShop from '../hooks/useResaShop';
import CobrandLogos from './CobrandLogos';

const AnimationBox = styled(Box)`
  animation: fadeIn ease 0.3s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Dropdown = styled(List)`
  position: relative;
  .dropdown-label {
    cursor: pointer;
  }
  .menu-mobile {
    width: 100%;
    max-height: 200px;
  }
  /* React Transition Group Stuff (mobile) */
  .menu-mobile-enter {
    opacity: 0;
    max-height: 0;
  }
  .menu-mobile-enter-active {
    opacity: 1;
    transition: opacity 300ms, max-height 300ms;
    max-height: 200px;
  }
  .menu-mobile-exit {
    opacity: 1;
    max-height: 200px;
  }
  .menu-mobile-exit-active {
    opacity: 0;
    max-height: 0;
    transition: opacity 300ms, max-height 300ms;
  }
  .menu {
    &:after {
      content: ' ';
      position: absolute;
      left: 50%;
      top: 2rem;
      border-top: none;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: 10px solid white;
      transform: translate(-50%, 0);
    }
    padding-top: 2rem;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 14rem;
  }
  /* React Transition Group Stuff */
  .menu-enter {
    opacity: 0;
    transform: translate(-50%, 0.4rem);
  }
  .menu-enter-active {
    opacity: 1;
    transition: opacity 300ms, transform 300ms;
    transform: translate(-50%, 0);
  }
  .menu-exit {
    opacity: 1;
  }
  .menu-exit-active {
    opacity: 0;
    transform: translate(-50%, 0.4rem);
    transition: opacity 300ms, transform 300ms;
  }
`;

const StyledLink = styled(Link)`
  @media (max-width: 1035px) {
    line-height: 18px;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    line-height: 1.5;
  }
`;

const StyledCard = styled(Card)`
  height: 5rem;
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    height: 65px;
  }
`;

const StyledImg = styled.img`
  height: 30px;
  width: auto;
  max-width: 300px;
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    height: 24px;
  }
`;

const StyledText = styled(Text)`
  z-index: 100;
  cursor: pointer;
  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    :hover {
      color: ${(p) => p.theme.colors.blue.electric};
    }
  }
`;

const NavigationList = ({ children, direction = 'row', ...props }) => (
  <List
    flexDirection={direction}
    alignItems={direction === 'row' ? 'center' : 'flex-start'}
    {...props}>
    {children}
  </List>
);

const NavigationItem = ({
  before,
  label,
  variant,
  href,
  to,
  fontWeight,
  ...props
}) => (
  <List item mr={4} {...props}>
    <StyledLink
      before={before}
      color="black"
      variant={variant}
      href={href}
      onClick={(e) => {
        e.preventDefault();
        window.location.href = to;
      }}
      fontWeight={fontWeight}>
      {label}
    </StyledLink>
  </List>
);

const DropdownMenu = ({ before, label, children, variant = 'small' }) => {
  const [open, setOpen] = useState(false);
  const { isDesktop } = useDeviceWidth();

  const mouseHandler = (isEntering) => {
    if (isDesktop) {
      setOpen(isEntering);
    }
  };

  const clickHandler = () => {
    if (!isDesktop) {
      setOpen((s) => !s);
    }
  };
  return (
    <Dropdown
      width={{ xs: '100%', lg: 'auto' }}
      item
      mr={4}
      onClick={clickHandler}
      flexDirection={{ xs: 'column', lg: 'row' }}
      onMouseEnter={() => mouseHandler(true)}
      onMouseLeave={() => mouseHandler(false)}>
      <Box
        width="100%"
        flexDirection="row"
        alignItems="center"
        justifyContent={{ xs: 'space-between', lg: 'flex-start' }}
        className="dropdown-label">
        <Box flexDirection="row" alignItems="center">
          {before && React.cloneElement(before, { mr: 3 })}
          <StyledText
            fontWeight={!isDesktop ? '600' : '500'}
            color="black"
            variant={variant}>
            {label}
          </StyledText>
        </Box>
        {open ? <SvgAngleUp ml={2} /> : <SvgAngleDown ml={2} />}
      </Box>
      <CSSTransition
        in={open}
        timeout={300}
        classNames={isDesktop ? 'menu' : 'menu-mobile'}
        unmountOnExit>
        <Box
          className={isDesktop ? 'menu' : 'menu-mobile'}
          position={{ xs: 'block', lg: 'absolute' }}>
          {isDesktop ? (
            <Card mt={2} py={4} boxShadow="md">
              {children}
            </Card>
          ) : (
            children
          )}
        </Box>
      </CSSTransition>
    </Dropdown>
  );
};

function HeaderBase({ noMenu = true, partenaire, cobranding }) {
  const { t } = useTranslation();
  const client = useSelector((s) => s.client);
  const history = useHistory();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const { isDesktop } = useDeviceWidth();
  const y = useScrollYPosition();
  const isInFunnel =
    pathname.includes('/reparation') && !pathname.includes('/entreprise');
  const { isFeatureOn } = useContext(FeaturesContext);
  const shop = useResaShop();
  const autodiag = useSelector((state) => state.autodiag.autodiag);

  const clickHandler = (to) => {
    if (!isDesktop && open) {
      setOpen(false);
    }
    history.push(to);
  };

  // Ensure Mobile Menu close after each user action
  React.useEffect(() => {
    setOpen(false);
  }, [history.location.pathname, history.length]);

  const NavigationItemWithMenu = () => (
    <DropdownMenu
      variant={isDesktop ? 'medium' : 'large'}
      fontWeight={isDesktop ? null : 'bold'}
      label={t('header.texts.1')}>
      <NavigationList direction="column" ml={{ xs: 5, lg: 0 }}>
        <NavigationItem
          to="/tarifs/velo"
          label={t('header.texts.3')}
          variant={isDesktop ? 'small' : 'large'}
          fontWeight={isDesktop ? null : 'bold'}
          mb={{ xs: 2, lg: 0 }}
        />
        <Separator
          axe="horizontal"
          my={3}
          display={{ xs: 'none', lg: 'block' }}
        />
        <NavigationItem
          to="https://faq.roulezjeunesse.com/fr/"
          label={t('header.texts.2')}
          variant={isDesktop ? 'small' : 'large'}
          fontWeight={isDesktop ? null : 'bold'}
          my={{ xs: 5, lg: 0 }}
          target="_blank"
        />
      </NavigationList>
    </DropdownMenu>
  );

  const NavContent = ({ display }) => (
    <NavigationList
      display={display}
      height="100%"
      ml={{ xs: 0, md: 7 }}
      width={{ md: '50%', lg: '100%' }}
      justifyContent={{ xs: 'flex-start', lg: 'flex-end' }}
      flexDirection={{ xs: 'column', lg: 'row' }}
      alignItems={{ xs: 'flex-start', lg: 'center' }}>
      {isInFunnel ? (
        <>
          <NavigationItemWithMenu />
          {isDesktop ? (
            <NavigationItem
              fontWeight="bold"
              ml={4}
              to={client.id ? '/account' : '/login'}
              label={
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
                  <BikeHelmet style={{ marginBottom: '4px' }} />
                </Box>
              }
            />
          ) : (
            <List
              flexDirection="column"
              item
              width={{ xs: '100%', lg: 'auto' }}
              mb={0}>
              <NavigationItem
                fontWeight="bold"
                to={client.id ? '/account' : '/login'}
                label={
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%" mt={8}>
                    <BikeHelmet style={{ marginBottom: '4px' }} />
                  </Box>
                }
              />
            </List>
          )}
        </>
      ) : (
        <Fragment>
          <NavigationItem
            variant={isDesktop ? 'medium' : 'large'}
            fontWeight={isDesktop ? null : 'bold'}
            to="/nous-rejoindre"
            label={t('header.texts.4')}
            mb={{ xs: 7, md: 5, lg: 0 }}
          />
          <NavigationItem
            variant={isDesktop ? 'medium' : 'large'}
            fontWeight={isDesktop ? null : 'bold'}
            to="/entreprises"
            label={t('roulezjeunessebusiness')}
            mb={{ xs: 7, md: 5, lg: 0 }}
          />
          <NavigationItem
            variant={isDesktop ? 'medium' : 'large'}
            fontWeight={isDesktop ? null : 'bold'}
            href="https://blog.roulezjeunesse.com"
            label={t('header.texts.5')}
            mb={{ xs: 7, md: 5, lg: 0 }}
          />
          {/* <NavigationBlogItemWithMenu /> */}
          <NavigationItemWithMenu />
          <Separator
            axe="vertical"
            display={{ xs: 'none', lg: 'block' }}
            mx={3}
            height="60%"
          />
          {isDesktop && (
            <>
              <NavigationItem
                fontWeight="bold"
                ml={4}
                to={client.id ? '/account' : '/login'}
                label={
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%" mt={2}>
                    <BikeHelmet style={{ marginBottom: '8px' }} />
                  </Box>
                }
              />
              <List
                flexDirection="column"
                item
                width={{ xs: '100%', lg: 'auto' }}
                mb={0}>
                <Button
                  fullWidth="lg"
                  color="primary.main"
                  variant={!isDesktop || y > 500 ? 'filled' : 'outlined'}
                  onClick={() => clickHandler('/reparation')}>
                  {t('button.book')}
                </Button>
              </List>
            </>
          )}
          {!isDesktop && (
            <List
              flexDirection="column"
              item
              width={{ xs: '100%', lg: 'auto' }}
              mb={0}>
              <NavigationItem
                fontWeight="bold"
                to={client.id ? '/account' : '/login'}
                label={
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%" mt={8}>
                    <BikeHelmet style={{ marginBottom: '4px' }} />
                  </Box>
                }
              />
              <Button
                fullWidth="lg"
                color="primary.main"
                variant={!isDesktop || y > 500 ? 'filled' : 'outlined'}
                onClick={() => clickHandler('/reparation')}>
                {t('button.book')}
              </Button>
            </List>
          )}
        </Fragment>
      )}
    </NavigationList>
  );

  if (isFeatureOn('ISWIDGET')) {
    return <WidgetHeader isInFunnel={isInFunnel} />;
  }

  return (
    <Fragment>
      <StyledCard
        p={0}
        borderRadius="none"
        zIndex={998}
        style={{
          borderBottom: y > 20 ? '1px solid rgba(0, 0, 0, .1)' : 'none',
        }}
        height="5em"
        width="100%"
        bg="white"
        overflow="visible"
        position={{ xs: 'block', lg: 'sticky' }}
        top={'-2px'}
        alignItems="flex-start">
        <Container
          fluid
          fullWidth={false}
          style={{
            height: '100%',
            borderBottom: '1px solid rgba(0, 0, 0, .1)',
          }}>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            alignItems="center"
            px={{ xs: 5, md: 0 }}
            style={{ position: 'relative', height: '100%' }}>
            <Link
              {...(partenaire && !cobranding
                ? {
                  href: 'https://www.roulezjeunesse.com',
                }
                : { to: '/' })}
              style={{ marginTop: '-8px' }}
              onClick={(e) => {
                e.preventDefault();
                window.location.assign('/');
              }}>
              <Box
                style={{ minWidth: '159px', flexGrow: 1 }}
                mt={'9px'}
                flexDirection="row"
                alignItems="center">
                {isInFunnel && shop && shop.profile_picture ? (
                  <CobrandLogos url={shop.profile_picture} />
                ) : (
                  <LogoSelector partenaire={partenaire} />
                )}
                <StyledImg
                  src={`${constants.CDN_BASE_URL}/img/logos/roulezjeunessecom-main-logo.svg`}
                  className="logo"
                  alt={t('roulezjeunesse.texts.alt')}
                  style={{
                    ...(isInFunnel && shop && shop.profile_picture
                      ? { height: '24px' }
                      : {}),
                  }}
                />
              </Box>
            </Link>
            {!noMenu && (
              <>
                <NavContent display={{ xs: 'none', lg: 'flex' }} />
                <Box display={{ xs: 'flex', lg: 'none' }}>
                  <div
                    className={`burger ${open ? 'open' : ''}`}
                    onClick={() => setOpen((s) => !s)}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Box>
              </>
            )}
            <ToastContainer autoDelete={true} dismissTime={10000} />
          </Box>
        </Container>
      </StyledCard>
      {open && (
        <>
          <Separator
            axe="horizontal"
            bg="gray.light"
            margin="0"
            display={{ xs: 'block' }}
          />
          <AnimationBox
            height="80vh"
            p={5}
            display={{ xs: 'flex', lg: 'none' }}
            alignItems={{ md: 'center', lg: 'stretch' }}>
            <NavContent display={{ xs: 'flex', lg: 'none' }} />
          </AnimationBox>
        </>
      )}
    </Fragment>
  );
}

const Header = (props) => (
  <PartenaireContext.Consumer>
    {({ partenaire, cobranding }) => (
      <HeaderBase partenaire={partenaire} cobranding={cobranding} {...props} />
    )}
  </PartenaireContext.Consumer>
);

export default Header;