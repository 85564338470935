import React from 'react';

const BikeHelmet = ({ style }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 60 57"
        style={{ width: '26px', height: '25px', ...style }}
    >
        <path
            fill="#d5ff4f"
            d="M50.36,11.89c-3.62-3.94-8.31-6.61-13.8-7.94l-8.18,24.14c1.78.14,3.33.61,4.95,1.11,1.89.59,3.85,1.19,6.47,1.31,5.44.23,11.19-1.85,17.05-6.17-.77-3.36-2.53-8.16-6.48-12.46Z"
        />
        <path
            fill="#314959"
            d="M59.97,24.69c-.78-3.93-2.69-9.68-7.41-14.81C46.62,3.41,38.16,0,28.11,0h-.36C23.18,0,11.05.03,4.26,8.68l-.04.04c-.15.15-.27.32-.3.43l-.21.27c-.16.21-.32.42-.47.65C.12,14.89-.78,21.13.69,27.65c1.89,8.41,7.61,13.27,9.98,14.98.34.24.75.34,1.18.25.41-.09.77-.34.98-.7,1.36-2.3,2.81-4.29,4.31-5.91l12.15,8.11-.92,1.59c-.2.34-.25.75-.15,1.13.1.39.35.71.7.91l5.67,3.28c.68.4,1.65.15,2.05-.54l.62-1.08,11.05,7.11c.24.15.52.24.81.24.51,0,.98-.26,1.26-.69.22-.34.29-.74.21-1.13-.08-.39-.32-.73-.65-.94l-11.17-7.19,1.15-2c.2-.34.25-.75.15-1.13-.1-.38-.35-.71-.7-.91l-1.95-1.13,3.32-8.37h.01c6.04,0,12.32-2.47,18.6-7.31l.07-.04c.45-.35.66-.91.56-1.47ZM34.8,47.94l-3.09-1.78.75-1.3.1-.11c.06-.09.09-.18.1-.23l.83-1.44,3.09,1.78-1.78,3.09ZM34.79,40.38l-1.1-.64c-.33-.2-.73-.26-1.14-.15-.09.02-.18.06-.26.1h-.29l.14.08c-.2.13-.38.31-.5.52l-.86,1.49-11.41-7.62c2.08-1.68,4.25-2.69,6.47-3,2.47-.36,4.37.23,6.59.92,1.54.48,3.2.96,5.17,1.23l-2.81,7.07ZM39.78,30.52c-2.61-.12-4.57-.73-6.47-1.31-2.44-.76-4.75-1.47-7.9-1.02-5.29.76-10.09,4.47-14.28,11.01-2.36-1.99-6.14-6.02-7.53-12.2-.18-.78-.4-1.97-.51-3.46.22.14.44.27.67.4,1.57.88,3.2,1.69,4.82,2.41.75.33,1.65-.03,1.97-.76.33-.75,0-1.64-.76-1.97-1.57-.7-3.11-1.47-4.58-2.29-.78-.43-1.51-.89-2.16-1.3.22-2.93,1.03-5.57,2.41-7.87,1.74,1.75,3.42,3.26,5.14,4.61,2.27,1.79,4.64,3.37,7.24,4.83.72.39,1.65.11,2.03-.58.4-.72.15-1.63-.57-2.04-2.47-1.38-4.71-2.88-6.85-4.57-1.73-1.36-3.47-2.94-5.19-4.69C13.31,3.01,23.78,2.99,27.76,2.98h.35c9.19,0,16.88,3.08,22.25,8.91,3.96,4.3,5.71,9.1,6.48,12.46-5.86,4.32-11.61,6.4-17.05,6.17Z"
        />
        <path
            fill="#314959"
            d="M31.91,6.24c-.82-.12-1.57.46-1.68,1.29s.46,1.58,1.28,1.69c.09.01,9.56,1.38,13.43,8.05.28.48.78.75,1.3.75.26,0,.51-.07.75-.2.72-.42.96-1.33.55-2.05-4.61-7.96-15.18-9.46-15.62-9.52Z"
        />
    </svg>
);

export default BikeHelmet;