import {
  GET_PREDICTIONS,
  SET_LOADING,
  SET_AC_ADDRESS,
} from '../actions/places.jsx';

const dataState = {
  loading: false,
  predictions: [],
  address: '',
  zones: [],
};

export default function places(state = dataState, action) {
  switch (action.type) {
    case GET_PREDICTIONS:
      return { ...state, predictions: action.predictions };
    case SET_LOADING:
      return { ...state, loading: action.value };
    case SET_AC_ADDRESS:
      return { ...state, address: action.input };
    case 'SET_ZONES':
      return { ...state, zones: action.zones };
    default:
      return state;
  }
}
