export const disabledAgendaCheckbox = (slots) => {
  return slots.filter((slot) => slot.full).length === slots.length;
};

export const valueAgendaCheckBox = (slots, disabled) => {
  return (
    !disabled &&
    slots.filter((slot) => slot.full || slot.checked).length === slots.length
  );
};
