import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
// Components
import Link from '../shared/atoms/Link';

const StyledUl = styled.ul`
  margin: 0;
`;

const StyledLi = styled.li`
  ${space}
`;

const AppSectionLinks = ({ links }) => (
  <StyledUl>
    {links.map((link, idx) => (
      <StyledLi key={idx} mb={{ xs: 4, lg: 2 }}>
        {link.href ? (
          <Link
            variant="small"
            color="black"
            href={link.href}
            target={link.blank ? '_blank' : '_self'}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = link.href;
            }}>
            {link.label}
          </Link>
        ) : (
          <Link color="black" to={link.to} variant="small">
            {link.label}
          </Link>
        )}
      </StyledLi>
    ))}
  </StyledUl>
);

export default AppSectionLinks;
