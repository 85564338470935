import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx={26} cy={23} r={6} fill="#FFE249" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.502 10.5a6.002 6.002 0 11-12.003 0 6.002 6.002 0 0112.003 0zm-1.8 0a4.202 4.202 0 11-8.404 0 4.202 4.202 0 018.404 0zM13 13a4 4 0 11-8 0 4 4 0 018 0zm-1.8 0a2.2 2.2 0 11-4.4 0 2.2 2.2 0 014.4 0z"
      fill="#000"
    />
    <path
      d="M13.123 24.337a6.1 6.1 0 015.87-4.437h3.015a6.1 6.1 0 015.869 4.437l.257.908a.9.9 0 001.732-.49l-.257-.909a7.9 7.9 0 00-7.601-5.746h-3.016a7.9 7.9 0 00-7.6 5.746l-.258.909a.9.9 0 001.732.49l.257-.908zM8.063 20.9a4.1 4.1 0 00-4.05 3.458l-.124.783a.9.9 0 01-1.778-.282l.124-.783A5.9 5.9 0 018.063 19.1H11v1.8H8.063z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgTeamColor32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgTeamColor32;
