import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx={16} cy={16} r={6} fill="#1F77F9" />
    <path
      d="M16.9 3a.9.9 0 10-1.8 0v3.2l-2.56-1.92a.9.9 0 10-1.08 1.44l3.64 2.73v5.991l-5.263-3.038-.543-4.51a.9.9 0 00-1.788.215l.382 3.17L5.192 8.72a.9.9 0 00-.9 1.559l2.704 1.56-2.95 1.26a.9.9 0 10.708 1.656l4.19-1.79L14.2 16l-9.908 5.72a.9.9 0 00.9 1.56l9.908-5.721v5.991l-3.64 2.73a.9.9 0 101.08 1.44l2.56-1.92V29a.9.9 0 101.8 0v-3.2l2.56 1.92a.9.9 0 101.08-1.44l-3.64-2.73v-5.991l5.261 3.037.543 4.512a.9.9 0 001.788-.216l-.382-3.17 2.698 1.557a.9.9 0 00.9-1.558l-2.705-1.562 2.949-1.26a.9.9 0 10-.708-1.655l-4.19 1.79L17.8 16l5.056-2.919 4.388 1.875a.9.9 0 00.708-1.655l-3.148-1.345 2.904-1.676a.9.9 0 00-.9-1.56l-2.672 1.544.356-2.956a.9.9 0 10-1.788-.216l-.517 4.297L16.9 14.44V8.45l3.64-2.73a.9.9 0 00-1.08-1.44L16.9 6.2V3z"
      fill="#1D1D1D"
    />
    <path
      d="M8.4 25l.598-4.964L4.4 18.072"
      stroke="#1D1D1D"
      strokeWidth={1.8}
      strokeLinecap="round"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = true;
const SvgWinter32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgWinter32;
