import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 17c0 7.18-5.82 13-13 13S2 24.18 2 17 7.82 4 15 4s13 5.82 13 13zm-5.769 8.943a11.478 11.478 0 004.269-8.918l-7.785 4.752a.749.749 0 01-.198.084 5.974 5.974 0 01-2.116.974l5.83 3.108zm-1.384.962A11.446 11.446 0 0115 28.5a11.47 11.47 0 01-4.257-.814l-.157-6.622a6.016 6.016 0 002.031 1.444c.03.023.064.045.1.063l8.13 4.334zm.137-9.47a5.98 5.98 0 01-.256 1.356l5.218-3.185a.747.747 0 01.457-.107C25.668 9.857 20.843 5.5 15 5.5c-1.382 0-2.707.244-3.934.69l7.32 5.856a6.03 6.03 0 011.008.869l-.122-4.94a.75.75 0 111.5-.037l.213 8.637a6.108 6.108 0 01.014.549l.003.13a.751.751 0 01-.018.182zm-3.017-3.818a.756.756 0 01-.111-.074l-.36-.288A4.48 4.48 0 0015 12.5c-.459 0-.902.069-1.32.197l-.691.277A4.5 4.5 0 0015 21.5a4.5 4.5 0 004.498-4.375l-.011-.475a4.49 4.49 0 00-1.52-3.033zM9 17c0-.965.228-1.876.632-2.683l-6.13 2.453L3.5 17c0 4.246 2.301 7.955 5.725 9.947l-.229-9.656c0-.04.001-.08.006-.118A6.118 6.118 0 019 17zm3.373-5.396l-8.715 3.488A11.513 11.513 0 019.606 6.84a.747.747 0 00.214.274l4.867 3.893a5.968 5.968 0 00-2.314.596z"
      fill="#000"
    />
    <path
      d="M17.088 8.343a3.716 3.716 0 115.255-5.255l6.569 6.569a3.716 3.716 0 11-5.255 5.255l-6.569-6.569z"
      fill="#d5ff4f"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgBrakeColor32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgBrakeColor32;
