import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { readableslots } from '../../../utils/utils';
// Components
import Box from '../../shared/atoms/Box';
import Heading from '../../shared/atoms/Heading';
import Text from '../../shared/atoms/Text';
import Link from '../../shared/atoms/Link';
import Separator from '../../Separator';

const StyledBox = styled(Box)`
  border: 1px solid ${(p) => p.theme.colors.gray.light};
`;

const AppointmentDetails = ({ course, openModal, setModification }) => {
  const { t } = useTranslation();
  return (
    <Box mb={9}>
      <Heading level={4} mb={4}>
        {t('appointmentdetails.heading')}
      </Heading>
      <StyledBox borderRadius="xs" p={4} mb={3}>
        <Box justifyContent="space-between">
          <Box flexDirection="row" justifyContent="space-between" mb={1}>
            <Text fontWeight="bold">{t('disponibilities')}</Text>
            {setModification && (
              <Link
                color="blue.electric"
                onClick={() => openModal('UPDATE_DISPONIBILITY')}>
                {t('modify.cta')}
              </Link>
            )}
          </Box>
          <Box>
            <div>{readableslots(course.slots)}</div>
          </Box>
        </Box>
        <Separator axe="horizontal" bg="gray.light" my={4} />
        <Box justifyContent="space-between">
          <Box flexDirection="row" justifyContent="space-between" mb={1}>
            <Text fontWeight="bold">{t('address')}</Text>
            {setModification && (
              <Link
                color="blue.electric"
                onClick={() => openModal('UPDATE_ADRESSE')}>
                {t('modify.cta')}
              </Link>
            )}
          </Box>
          <Box>
            <Text>{course.position}</Text>
          </Box>
        </Box>
      </StyledBox>
    </Box>
  );
};

export default AppointmentDetails;
