import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12.505 26.643l1.88-6.096 20.693 15.594-5.342 3.487-17.231-12.985zM9.984 37.95l1.775-6.175 13.105 9.875-5.447 3.409-9.433-7.109zM6.906 49.489l1.99-6.013 5.867 4.42-5.232 3.571-2.625-1.978z"
      fill="#E2BEFF"
    />
    <circle cx={42.5} cy={14.5} r={2.5} fill="#FFE249" />
    <circle cx={19.5} cy={10.5} r={2.5} fill="#28D898" />
    <path
      d="M49.002 31a1 1 0 10-2 0v2H46a1 1 0 100 2h1.002v2a1 1 0 102 0v-2h1.002a1 1 0 100-2h-1.002v-2z"
      fill="#8A38F3"
    />
    <path
      d="M11.002 5a1 1 0 10-2 0v2H8a1 1 0 000 2h1.002v2a1 1 0 102 0V9h1.002a1 1 0 100-2h-1.002V5z"
      fill="#d5ff4f"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.892 18.948a14 14 0 00.731-11.897l-.669-1.682a1 1 0 011.86-.738l.668 1.681a16 16 0 01-.835 13.597l-.243.443 3.243 2.473 3.064-3.939a1 1 0 011.578 1.228l-3.051 3.923 1.35 1.03 1.47-1.034a16 16 0 0112.797-2.505l1.37.316a1 1 0 01-.45 1.949l-1.369-.316a14 14 0 00-11.198 2.192l-.952.67 5.922 4.514c1.719 1.311 1.53 3.955-.359 5.007L10.477 51.65c-2.285 1.273-4.99-.783-4.376-3.325l7.63-31.581c.509-2.105 3.013-2.995 4.735-1.681l5.33 4.062.096-.177zm-9.326 2.856l-.843 3.49 16.839 12.877 3.253-1.813-19.249-14.554zm.512-2.12a.819.819 0 01.025.018l20.5 15.5c.04.03.077.063.111.098l2.132-1.187a1 1 0 00.12-1.67l-20.712-15.79a1 1 0 00-1.579.56l-.597 2.471zm-2.747 11.372l.879-3.637 15.447 11.813-2.976 1.658-13.086-9.694a1 1 0 00-.264-.14zm-.499 2.064l11.932 8.838-3.148 1.754a1.349 1.349 0 00-.023-.017l-9.5-7a1.026 1.026 0 00-.108-.07l.847-3.505zm-1.354 5.606l8.218 6.056-3.244 1.807-5.82-4.365.846-3.498zM9.12 44.34l4.418 3.314-4.035 2.248c-.762.425-1.664-.26-1.46-1.108l1.077-4.454z"
      fill="#000"
    />
    <path
      d="M36.85 13.7a1 1 0 01.2 1.4l-1.5 2a1 1 0 01-1.6-1.2l1.5-2a1 1 0 011.4-.2zM40.997 5.082a1 1 0 10-1.994-.164l-.16 1.952a1.706 1.706 0 01-2.379 1.425 3.706 3.706 0 00-5.12 2.733l-.328 1.792a1 1 0 001.968.36l.328-1.792a1.706 1.706 0 012.356-1.258c2.328 1.01 4.96-.567 5.168-3.096l.16-1.952z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgConfettis56Color = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgConfettis56Color;
