import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M16.093 7.22a5.205 5.205 0 00-5.521.974A5.25 5.25 0 009.7 9.227l.492.538-.029-.03-.48-.525a5.208 5.208 0 00-.877 3.075c.02.573-.146 1.157-.57 1.544l-1.668 1.528c-.07.063-.134.13-.195.198a2.605 2.605 0 00-.215.177l-.691.63a2.575 2.575 0 003.472 3.804l.69-.632c.06-.053.115-.108.167-.166.088-.062.174-.13.255-.206l1.67-1.527c.423-.388 1.018-.502 1.588-.432a5.208 5.208 0 004.16-1.334 5.205 5.205 0 001.452-5.432 6.454 6.454 0 01-7.036.635l4.208-3.852z"
      fill="#d5ff4f"
    />
    <path d="M17.527 15.816l.012-.01.012-.012-.024.022z" fill="#d5ff4f" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.2 13a9.2 9.2 0 11-18.4 0 9.2 9.2 0 0118.4 0zm-3.746 9.555A10.95 10.95 0 0113 24C6.925 24 2 19.075 2 13S6.925 2 13 2s11 4.925 11 11a10.95 10.95 0 01-1.445 5.454l6.566 6.566a2.9 2.9 0 01-4.101 4.101l-6.566-6.566zm1.508-1.038l6.33 6.331a1.1 1.1 0 101.556-1.555l-6.33-6.331c-.466.568-.988 1.09-1.556 1.555z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgSearchColor32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgSearchColor32;
