import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.415 3H9c-1.088.002-1.872.026-2.545.245a5 5 0 00-3.21 3.21c-.246.756-.246 1.65-.245 2.96v2.228c0 1.084 0 1.958.058 2.666.06.729.185 1.369.487 1.961a5 5 0 002.185 2.185c.592.302 1.232.428 1.961.487C8.4 19 9.273 19 10.357 19H12a1 1 0 100-2h-1.6c-1.137 0-1.929 0-2.546-.051-.605-.05-.953-.142-1.216-.276a3 3 0 01-1.311-1.311c-.134-.263-.226-.611-.276-1.216C5.001 13.529 5 12.736 5 11.6v-2c0-1.567.012-2.113.147-2.527a3 3 0 011.926-1.926C7.487 5.012 8.033 5 9.6 5h.102a1 1 0 01.998 1v1a4 4 0 004 4H16a1 1 0 011 1 1 1 0 102 0v-.175c0-1.692 0-2.745-.238-3.637a7 7 0 00-4.95-4.95C12.92 3 11.867 3 10.175 3h-.76zm3.131 2.05c.1.298.154.618.154.95v1a2 2 0 002 2H16c.32 0 .627.05.916.143a3.574 3.574 0 00-.086-.437 5 5 0 00-3.536-3.536 4.213 4.213 0 00-.748-.12z"
      fill="#000"
    />
    <path
      d="M15 18a1 1 0 011-1h1v-1a1 1 0 112 0v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 01-1-1z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgFilePlus24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgFilePlus24;
