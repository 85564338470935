import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import Box from '../../shared/atoms/Box';
import Text from '../../shared/atoms/Text';

const Step = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  ::before {
    position: absolute;
    content: '';
    border-bottom: 2px solid ${(p) => p.theme.colors.gray.main};
    width: 100%;
    top: 7px;
    left: -50%;
    z-index: 2;
  }
  ::after {
    position: absolute;
    content: '';
    border-bottom: 2px solid ${(p) => p.theme.colors.gray.main};
    width: 100%;
    top: 7px;
    left: 50%;
    z-index: 2;
  }
  ${(p) => {
    if (p.isCompleted) {
      return css`
        ::after {
          position: absolute;
          content: '';
          border-bottom: 2px solid ${p.theme.colors.blue.electric};
          width: 100%;
          top: 7px;
          left: 50%;
          z-index: 3;
          transition: all 3s ease;
        }
      `;
    }
  }};
  :first-child::before {
    content: none;
  }
  :last-child::after {
    content: none;
  }
  ${(p) => p.isActive && 'color: black'};
`;
const Counter = styled(Box)`
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 16px;
  border-radius: 10px;
  background: ${(p) => p.theme.colors.gray.main};
  margin-bottom: 6px;
  box-shadow: 0 0 0 5px white;
  ${(p) =>
    (p.isActive || p.isCompleted) &&
    `background-color: ${p.theme.colors.blue.electric}`};
`;

const BuyGiftCardSteps = ({ steps, ...props }) => {
  const { t } = useTranslation();
  return (
    <Box flexDirection="row" {...props} mt={{ xs: 4, md: 0 }}>
      <Step
        isActive={steps === 'CUSTOMIZATION'}
        isCompleted={steps === 'RECAP' || steps === 'CONFIRMATION'}>
        <Counter
          isActive={steps === 'CUSTOMIZATION'}
          isCompleted={steps === 'RECAP' || steps === 'CONFIRMATION'}
        />
        <Text
          color={steps === 'CUSTOMIZATION' ? 'black' : 'gray.darker'}
          fontWeight={1}
          fontSize={{ xs: 10, md: 'md' }}>
          {t('buygiftcard.texts.1')}
        </Text>
      </Step>
      <Step isActive={steps === 'RECAP'} isCompleted={steps === 'CONFIRMATION'}>
        <Counter
          isActive={steps === 'RECAP'}
          isCompleted={steps === 'CONFIRMATION'}
        />
        <Text
          color={
            steps === 'RECAP'
              ? 'black'
              : steps === 'CUSTOMIZATION'
                ? 'gray.main'
                : 'gray.darker'
          }
          fontWeight={1}
          fontSize={{ xs: 10, md: 'md' }}>
          {t('buygiftcard.texts.2')}
        </Text>
      </Step>
      <Step isActive={steps === 'CONFIRMATION'}>
        <Counter isActive={steps === 'CONFIRMATION'} />
        <Text
          color={steps === 'CONFIRMATION' ? 'black' : 'gray.main'}
          fontWeight={1}
          fontSize={{ xs: 10, md: 'md' }}>
          {t('buygiftcard.texts.3')}
        </Text>
      </Step>
    </Box>
  );
};
export default BuyGiftCardSteps;
