import styled, { css } from 'styled-components';
import { device } from '../../styles/constants.jsx';

export const Row = styled.div`
    display:flex;
    display:-ms-flexbox;
    flex-direction:row;
    -ms-flex-direction:row;
    align-items: stretch;
    -ms-align-items: stretch;
     
    > * {
      flex:1;
      -ms-flex:1;
      :not(:last-child) {
        margin-right:${(props) => (props.gutter ? props.gutter : 10)}px;
      }
    }
    
    ${(props) =>
      !!props.between &&
      css`
        justify-content: space-between;
        > * {
          flex: none;
          -ms-flex: none;
          align-items: center;
          -ms-align-items: center;
        }
      `}
    
    ${(props) =>
      !!props.lg &&
      css`
        @media ${device.lg} {
          display: block;

          > * {
            flex: 1;
            -ms-flex: 1;
            :not(:last-child) {
              margin-right: 0;
              margin-bottom: 10px;
            }
          }
        }
      `}        
    ${(props) =>
      !!props.md &&
      css`
        @media ${device.md} {
          display: block;

          > * {
            flex: 1;
            -ms-flex: 1;
            :not(:last-child) {
              margin-right: 0;
              margin-bottom: 10px;
            }
          }
        }
      `}    
    ${(props) =>
      !!props.sm &&
      css`
        @media ${device.sm}{
            ${
              props.reverse
                ? css`
                    flex-direction: column-reverse;
                    -ms-flex-direction: column-reverse;
                  `
                : css`
                    display: block;
                  `
            };
            
             > * {
                flex:1;
                -ms-flex:1;
                :not(:last-child) {
                    margin-right:0;
                }
                :not(:${props.reverse ? `first` : `last`}-child) {
                    margin-bottom:10px;
                }
            }
        }
    `}    
    ${(props) =>
      !!props.xs &&
      css`
        @media ${device.xs} {
          display: block;

          > * {
            flex: 1;
            -ms-flex: 1;
            :not(:last-child) {
              margin-right: 0;
              margin-bottom: 10px;
            }
          }
        }
      `}
`;

export const Column = styled.div`
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  -ms-flex-direction: column;

  > * {
    flex: 1;
    -ms-flex: 1;

    :not(:last-child) {
      margin-bottom: ${(props) => (props.gutter ? props.gutter : 10)}px;
    }
  }
`;
