import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/constants.jsx';

const ProgressWrapper = styled.div`
  position: absolute;
  bottom: 0;
  background-color: ${colors.white};
  height: 4px;
  width: 100%;
`;

const ProgressBar = styled.div`
  background-color: ${(p) => p.theme.colors.blue.electric};
  transition: 0.5s ease;
  height: 4px;
  width: ${(props) => props.value}%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const Progress = ({ value, ...props }) => (
  <ProgressWrapper {...props}>
    <ProgressBar value={value} />
  </ProgressWrapper>
);

export default Progress;
