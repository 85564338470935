import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M8.002 1a.75.75 0 01.75.75v7.091a3.29 3.29 0 00.162-.154c.246-.248.539-.598.97-1.115l1.292-1.551a.75.75 0 011.153.96l-1.315 1.577c-.403.485-.737.886-1.035 1.185-.307.31-.631.571-1.033.718a2.75 2.75 0 01-1.889 0c-.402-.147-.726-.408-1.033-.718-.298-.3-.632-.7-1.036-1.185L3.674 6.981a.75.75 0 011.153-.96l1.292 1.551c.431.517.724.867.97 1.115.06.06.114.112.162.154V1.75a.75.75 0 01.75-.75z"
      fill="#000"
    />
    <path
      d="M3.5 9.75a.75.75 0 00-1.5 0v.533c0 .673 0 1.224.037 1.671.037.464.118.882.317 1.273a3.25 3.25 0 001.42 1.42c.391.2.81.28 1.273.318.448.036.998.036 1.672.036h2.565c.674 0 1.224 0 1.672-.036.463-.038.882-.119 1.272-.318a3.25 3.25 0 001.42-1.42c.2-.391.28-.81.318-1.273.037-.447.037-.998.037-1.671V9.75a.75.75 0 00-1.5 0v.501c0 .713 0 1.202-.032 1.581-.03.37-.085.57-.159.714a1.75 1.75 0 01-.765.764c-.144.074-.343.13-.713.16-.38.03-.869.031-1.581.031H6.75c-.712 0-1.202 0-1.58-.031-.371-.03-.57-.086-.714-.16a1.75 1.75 0 01-.765-.764c-.074-.144-.13-.343-.16-.714-.03-.38-.031-.868-.031-1.58V9.75z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgDownload16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgDownload16;
