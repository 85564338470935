import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      className="recolor"
      d="M13 42a9 9 0 100-18 9 9 0 000 18z"
      fill="#d5ff4f"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.286 16H16a1 1 0 110-2h8a1 1 0 110 2h-3.6l1.029 3H36v-3h-1a1 1 0 010-2h6.5a2.618 2.618 0 110 5.237 1 1 0 110-2 .618.618 0 100-1.237H38v2.795l2.395 5.588a9 9 0 11-1.838.789l-1.576-3.677L25.438 34h-14.17l8.273-14.338L18.286 16zm3.828 5h12.602l-9.189 9.955L22.114 21zm1.657 11l-3.37-9.828L14.732 32h9.04zM43 26c-.626 0-1.233.082-1.81.237l2.73 6.37a1 1 0 01-1.84.787l-2.729-6.369A7 7 0 1043 26z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = true;
const SvgBikeColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      .recolor {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgBikeColor56;
