import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import Box from '../../shared/atoms/Box';
import Heading from '../../shared/atoms/Heading';
import Text from '../../shared/atoms/Text';
import Link from '../../shared/atoms/Link';
import Separator from '../../Separator';
import AppointmentDetails from './AppointmentDetails';
import CourseChat from './CourseChat';
import CourseBill from './CourseBill';

const StyledBox = styled(Box)`
  border: 1px solid ${(p) => p.theme.colors.gray.light};
`;

const PhotosContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.4rem;
  .displayed-photo {
    height: 6rem;
    position: relative;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      overflow: hidden;
      border-radius: 4px;
    }
  }
`;

const CourseDetails = ({ course, client, relatedPhotos, openModal }) => {
  const { t } = useTranslation();
  const setModification =
    course.state !== 'finished' && course.state !== 'cancelled';
  const bike_details_split = course.bike_details.split('\n');
  return (
    <Box>
      {course.state === 'finished' &&
        course.prix > 0 &&
        course.company_id == 0 && <CourseBill course={course} />}
      {course.state === 'scheduled' || course.state === 'finished' ? (
        <CourseChat client={client} course={course} />
      ) : (
        <AppointmentDetails
          course={course}
          openModal={openModal}
          setModification={setModification}
        />
      )}
      <Box>
        <Heading level={4} mb={4}>
          {t('coursedetails.heading')}
        </Heading>
        <StyledBox borderRadius="xs" p={4} mb={3}>
          <Box justifyContent="space-between">
            <Box flexDirection="row" justifyContent="space-between" mb={1}>
              <Text fontWeight="bold">{t('selfdiagnosis')}</Text>
            </Box>
            <Box>
              {bike_details_split.map((detail, index) => (
                <Text key={index}>{detail}</Text>
              ))}
            </Box>
          </Box>
          <Separator axe="horizontal" bg="gray.light" my={4} />
          <Box justifyContent="space-between">
            <Box flexDirection="row" justifyContent="space-between" mb={1}>
              <Text fontWeight="bold">{t('details')}</Text>
              {setModification && (
                <Link
                  color="blue.electric"
                  onClick={() => openModal('UPDATE_DETAILS')}>
                  {t('modify.cta')}
                </Link>
              )}
            </Box>
            <Box>
              {course.course_details ? (
                <Text>{course.course_details}</Text>
              ) : course.state === 'finished' ||
                course.state === 'cancelled' ? (
                <Text color="gray.dark">{t('coursedetails.texts.1')}</Text>
              ) : (
                <Text color="gray.dark">{t('coursedetails.texts.2')}</Text>
              )}
            </Box>
          </Box>
          <Separator axe="horizontal" bg="gray.light" my={4} />
          <Box justifyContent="space-between">
            <Box flexDirection="row" justifyContent="space-between" mb={1}>
              <Text fontWeight="bold">{t('pictures')}</Text>
              {setModification && (
                <Link
                  color="blue.electric"
                  onClick={() => openModal('UPDATE_PHOTOS')}>
                  {t('modify.cta')}
                </Link>
              )}
            </Box>
            <Box>
              {relatedPhotos.length ? (
                <PhotosContainer>
                  {relatedPhotos.map(({ small }, index) => (
                    <div className="displayed-photo" key={index}>
                      <img src={small} />
                    </div>
                  ))}
                </PhotosContainer>
              ) : course.state === 'finished' ||
                course.state === 'cancelled' ? (
                <Text color="gray.dark">{t('coursedetails.texts.3')}</Text>
              ) : (
                <Text color="gray.dark">{t('coursedetails.texts.4')}</Text>
              )}
            </Box>
          </Box>
        </StyledBox>
      </Box>
    </Box>
  );
};

export default CourseDetails;
