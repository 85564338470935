import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 7.025c0 2.775-2.239 5.025-5 5.025S7 9.8 7 7.025C7 4.25 9.239 2 12 2s5 2.25 5 5.025zm-1.8 0c0 1.79-1.441 3.225-3.2 3.225-1.759 0-3.2-1.435-3.2-3.225S10.241 3.8 12 3.8c1.759 0 3.2 1.435 3.2 3.225zM11.075 13.16a8.9 8.9 0 00-7.524 4.147l-.712 1.127.55.509c1.293 1.193 4.63 3.052 8.611 3.052 3.981 0 7.318-1.859 8.61-3.052l.551-.509-.712-1.127a8.9 8.9 0 00-7.524-4.147h-1.85zM12 20.195c-2.954 0-5.495-1.19-6.806-2.112a7.1 7.1 0 015.881-3.123h1.85a7.1 7.1 0 015.881 3.123c-1.311.922-3.852 2.113-6.806 2.113z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgUserAlt24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgUserAlt24;
