import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { color } from 'styled-system';

const StyledLine = styled.hr`
  ${(p) => {
    return css`
      background-color: ${p.bg || 'transparent'};
      width: 100%;
      flex: 1;
      border-bottom: 1px ${p.property};
      border-color: ${color}
      margin: 0;
    `;
  }}
`;

const Line = ({ property, bg, color, ...rest }) => {
  return <StyledLine {...{ property, bg, color, ...rest }} />;
};

Line.propTypes = {
  property: PropTypes.string,
  bg: PropTypes.string,
  color: PropTypes.string,
};

export default Line;
