import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M9.439 21C6.462 20.983 4 14.907 4 11.812c0-5.055 3.792-6.161 5.253-6.161.659 0 1.362.258 1.982.487.434.16.883.325 1.132.325.15 0 .502-.14.812-.264.663-.263 1.487-.591 2.448-.591h.005c.717 0 2.891.157 4.198 2.12l.306.46-.44.332c-.63.475-1.778 1.341-1.778 3.058 0 2.032 1.3 2.814 1.925 3.19.276.165.561.337.561.71 0 .245-1.95 5.492-4.781 5.492-.693 0-1.183-.209-1.615-.392-.437-.186-.814-.346-1.438-.346-.315 0-.715.149-1.138.307-.578.216-1.232.461-1.974.461h-.02zM15.927 1c.073 2.66-1.828 4.504-3.728 4.388C11.886 3.266 14.1 1 15.927 1z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgApple24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgApple24;
