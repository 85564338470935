import React, { cloneElement } from 'react';
import styled, { css } from 'styled-components';
import Box from './Box';
import Text from './Text';

const TooltipBoxTop = styled(Box)`
  position: relative;
  ${(p) => css`
    .tooltip-text {
      position: absolute;
      visibility: hidden;
      z-index: 1;
      bottom: 150%;
      margin-left: -118px;
    }
    .tooltip-text:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: ${p.theme.colors.gray.darkest} transparent transparent
        transparent;
    }
  `}

  &:hover .tooltip-text {
    visibility: visible;
  }
`;

const TooltipBoxBottom = styled(Box)`
  position: relative;
  ${(p) => css`
    .tooltip-text {
      position: absolute;
      visibility: hidden;
      z-index: 1;
      top: 150%;
      margin-left: -118px;
    }
    .tooltip-text:after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent ${p.theme.colors.gray.darkest}
        transparent;
    }
  `}

  &:hover .tooltip-text {
    visibility: visible;
  }
`;
const TooltipBoxLeft = styled(Box)`
  position: relative;
  ${(p) => css`
    .tooltip-text {
      position: absolute;
      visibility: hidden;
      z-index: 1;
      top: -23px;
      left: -260px;
    }
    .tooltip-text:after {
      content: '';
      position: absolute;
      bottom: 45%;
      left: 102%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent
        ${p.theme.colors.gray.darkest};
    }
  `}

  &:hover .tooltip-text {
    visibility: visible;
  }
`;
const TooltipBoxRight = styled(Box)`
  position: relative;
  ${(p) => css`
    .tooltip-text {
      position: absolute;
      visibility: hidden;
      z-index: 1;
      top: -23px;
      left: 25px;
    }
    .tooltip-text:after {
      content: '';
      position: absolute;
      bottom: 45%;
      right: 100%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent ${p.theme.colors.gray.darkest} transparent
        transparent;
    }
  `}

  &:hover .tooltip-text {
    visibility: visible;
  }
`;

const IconTooltip = ({ icon, tooltipText = '', direction, ...props }) => {
  switch (direction) {
    case 'bottom':
      return (
        <TooltipBoxBottom>
          {icon}
          <Box
            className="tooltip-text"
            bg="gray.darkest"
            p={2}
            borderRadius="xxs"
            width="250px">
            <Text color="white" fontWeight={2} fontSize="xs" lineHeight="copy">
              {tooltipText}
            </Text>
          </Box>
        </TooltipBoxBottom>
      );
    case 'left':
      return (
        <TooltipBoxLeft>
          {icon}
          <Box
            className="tooltip-text"
            bg="gray.darkest"
            p={2}
            borderRadius="xxs"
            width="250px">
            <Text color="white" fontWeight={2} fontSize="xs" lineHeight="copy">
              {tooltipText}
            </Text>
          </Box>
        </TooltipBoxLeft>
      );
    case 'right':
      return (
        <TooltipBoxRight>
          {icon}
          <Box
            className="tooltip-text"
            bg="gray.darkest"
            p={2}
            borderRadius="xxs"
            width="250px">
            <Text color="white" fontWeight={2} fontSize="xs" lineHeight="copy">
              {tooltipText}
            </Text>
          </Box>
        </TooltipBoxRight>
      );
    default:
      return (
        <TooltipBoxTop>
          {icon}
          <Box
            className="tooltip-text"
            bg="gray.darkest"
            p={2}
            borderRadius="xxs"
            width="250px">
            <Text color="white" fontWeight={2} fontSize="xs" lineHeight="copy">
              {tooltipText}
            </Text>
          </Box>
        </TooltipBoxTop>
      );
  }
};

export default IconTooltip;
