import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Modal from '../../../modals/Modal';
import Heading from '../../shared/atoms/Heading';
import Box from '../../shared/atoms/Box';
import Text from '../../shared/atoms/Text';
import Button from '../../shared/atoms/Button';
import LoveColor56 from '../../icons/LoveColor56';
import Copy16 from '../../icons/Copy16';
import Check16 from '../../icons/Check16';
import Message16 from '../../icons/Message16';
import Facebook from '../../icons/Facebook24';
import Twitter from '../../icons/Twitter24';
import { colors } from '../../../styles/theme';

const ShareSponsorshipModal = ({ referCode, ...props }) => {
  const { t } = useTranslation();
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);

  const mailTo = () => {
    window.open(t('sharesponsorshipmodal.mailto', { referCode }), '_blank');
  };
  const facebookShare = () => {
    window.open(t('sharesponsorshipmodal.facebook', { referCode }), '_blank');
  };
  const twitterShare = () => {
    window.open(t('sharesponsorshipmodal.twitter', { referCode }), '_blank');
  };

  return (
    <Modal {...props}>
      <Box maxWidth={600}>
        <Heading level={5}>{t('sharesponsorshipmodal.heading')}</Heading>
        <Text mb={4}>
          <Trans i18nKey="sharesponsorshipmodal.subheading" />
        </Text>
        <Text variant="small" mb={5}>
          {t('sharesponsorshipmodal.text')}
        </Text>
        <Box
          bg="white"
          borderRadius="sm"
          flexDirection="row"
          borderStyle="dashed"
          borderWidth="1px"
          alignItems="center"
          mb={2}
          p={3}>
          <Text fontWeight={2} textAlign="center" style={{ width: '100%' }}>
            {referCode}
          </Text>
        </Box>
        <Button
          color="primary.main"
          fullWidth
          before={isCopiedToClipboard ? <Check16 /> : <Copy16 />}
          onClick={() => {
            navigator.clipboard.writeText(referCode);
            setIsCopiedToClipboard(true);
            setTimeout(() => {
              setIsCopiedToClipboard(false);
            }, 2000);
          }}>
          {isCopiedToClipboard
            ? t('sharesponsorshipmodal.copied')
            : t('sharesponsorshipmodal.copy')}
        </Button>
        <Button
          color="primary.main"
          fullWidth
          before={<Message16 />}
          onClick={() => mailTo()}>
          {t('sharesponsorshipmodal.share.email')}
        </Button>
        <Button
          color={colors.facebook}
          fullWidth
          before={<Facebook />}
          onClick={() => facebookShare()}>
          {t('sharesponsorshipmodal.share.facebook')}
        </Button>
        <Button
          color={colors.twitter}
          fullWidth
          before={<Twitter />}
          onClick={() => twitterShare()}>
          {t('sharesponsorshipmodal.share.twitter')}
        </Button>
      </Box>
    </Modal>
  );
};

export default ShareSponsorshipModal;
