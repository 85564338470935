import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { colors } from '../../../styles/theme';
import Box from '../../shared/atoms/Box';
import Heading from '../../shared/atoms/Heading';
import Text from '../../shared/atoms/Text';
import Input from '../../shared/atoms/Input';
import Button from '../../shared/atoms/Button';
import GiftCardAmount from '../buyGiftcard/GiftCardAmount';
import IconButton from '../../shared/atoms/IconButton';
import useDeviceWidth from '../../../hooks/useDeviceWidth';
import TextGradiant from '../../shared/atoms/TextGradiant';
// Icons
import ArrowRight16 from '../../icons/ArrowRight16';
import ArrowRight24 from '../../icons/ArrowRight24';
import ArrowLeft24 from '../../icons/ArrowLeft24';

const AbsolutePrice = styled(Heading)`
  position: absolute;
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    right: 30px;
    top: 15px;
  }
  right: 35px;
  top: 20px;
`;
const AbsoluteCurrency = styled(Text)`
  position: absolute;
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    right: 16px;
    top: 17px;
  }
  right: 21px;
  top: 22px;
`;

const StyledCardBox = styled(Box)`
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 10px 50px rgba(0, 0, 0, 0.15));
`;
const PersonalizeYourGiftcard = () => {
  const { isMobile } = useDeviceWidth();
  const { t } = useTranslation();
  const history = useHistory();
  const [giftcard, setGiftcard] = useState({
    giftcardChoice: 1,
    recipientName: '',
    errorName: false,
    message: '',
    amount: 20,
    customAmount: '',
    errorAmount: false,
    code: '',
    created_at: '',
    id: '',
  });
  return (
    <Box
      py={{ xs: 6, xl: 10 }}
      px={{ xs: 5, xl: 10 }}
      bg="gray.lightest"
      borderRadius={{ xs: null, sm: 'lg', xl: 'xl' }}>
      <Box maxWidth={{ xs: '100%', xl: '55%' }}>
        <Heading mb={7} textAlign={{ xs: 'center', xl: 'start' }} level={2}>
          <Trans
            i18nKey="personalizeyourgift.heading"
            components={{
              gradiant: <TextGradiant />,
            }}
          />
        </Heading>
      </Box>
      <Box flexDirection={{ xs: 'column-reverse', xl: 'row' }}>
        <Box
          flex={1}
          mr={{ xs: 0, xl: 10 }}
          maxWidth={{ xs: '90%', xl: '100%' }}
          alignSelf={{ xs: 'start', md: 'center' }}
          mt={{ xs: 4, sm: 5, xl: 0 }}>
          <Text fontSize="xl" color="black" lineHeight="copy" fontWeight={1}>
            {t('personalizeyourgift.texts.1')}
          </Text>
          <GiftCardAmount
            setGiftcard={setGiftcard}
            giftcard={giftcard}
            justifyContent="start"
            paddingX={4}
            paddingY="12px"
            mt={5}
            width="100%"
            customInputWidth={isMobile ? 90 : false}
          />
          <Input
            borderColor={colors.gray.light}
            mt={4}
            placeholder={t('personalizeyourgift.texts.2')}
            value={giftcard.recipientName}
            onChange={(e) =>
              setGiftcard({ ...giftcard, recipientName: e.target.value })
            }
          />
          <Button
            after={<ArrowRight16 />}
            mt={5}
            onClick={() =>
              history.push({
                pathname: '/carte-cadeau/acheter',
                state: { giftcardFromLanding: giftcard },
              })
            }>
            {t('personalizeyourgift.cta')}
          </Button>
        </Box>
        <StyledCardBox flex={1}>
          <Box flexDirection="row" alignItems="center" justifyContent="center">
            <Box mr={{ xs: 2, sm: 4 }}>
              <IconButton
                variant="filledWhite"
                noFocusStyle
                icon={<ArrowLeft24 />}
                style={{ padding: 8 }}
                onClick={
                  giftcard.giftcardChoice === 1
                    ? () =>
                        setGiftcard({
                          ...giftcard,
                          giftcardChoice: 6,
                        })
                    : () =>
                        setGiftcard({
                          ...giftcard,
                          giftcardChoice: giftcard.giftcardChoice - 1,
                        })
                }
              />
            </Box>
            <Box position="relative">
              <img
                src={`/static/img/pictos/giftcard/gift-card-${giftcard.giftcardChoice}.png`}
                style={{ width: 450 }}
              />
              <AbsolutePrice
                level={3}
                color={giftcard.giftcardChoice === 1 ? 'white' : 'black'}>
                {giftcard.customAmount
                  ? giftcard.customAmount
                  : giftcard.amount}
              </AbsolutePrice>
              <AbsoluteCurrency
                fontSize="lg"
                color={giftcard.giftcardChoice === 1 ? 'white' : 'black'}
                fontWeight={2}>
                €
              </AbsoluteCurrency>
            </Box>
            <Box ml={{ xs: 2, sm: 4 }}>
              <IconButton
                variant="filledWhite"
                noFocusStyle
                style={{ padding: 8 }}
                icon={<ArrowRight24 />}
                onClick={
                  giftcard.giftcardChoice === 6
                    ? () =>
                        setGiftcard({
                          ...giftcard,
                          giftcardChoice: 1,
                        })
                    : () =>
                        setGiftcard({
                          ...giftcard,
                          giftcardChoice: giftcard.giftcardChoice + 1,
                        })
                }
              />
            </Box>
          </Box>
        </StyledCardBox>
      </Box>
    </Box>
  );
};

export default PersonalizeYourGiftcard;
