/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import {
  setAddress,
  setPlaceId,
  setPosition,
  setProductId,
  setSelectedShop,
} from '../actions/course';
import Separator from '../components/Separator';
import Box from '../components/shared/atoms/Box';
import Container from '../components/shared/atoms/Container';
import Header from '../components/workshopStandalone/Header';
import InformationsWorkshop from '../components/workshopStandalone/InformationsWorkshop';
import Maps from '../components/workshopStandalone/Maps';
import ReservationBox from '../components/workshopStandalone/ReservationBox';
import SectionInformations from '../components/workshopStandalone/SectionInformations';
import * as api from '../utils/api';
import { parse_all_shops } from '../utils/utils';
import Danger24 from '../components/icons/Danger24';
import Calendar24 from '../components/icons/Calendar24';
import Text from '../components/shared/atoms/Text';
import ToolKitFix from '../components/shared/ToolkitFix';

const StyledText = styled(Text)`
  z-index: 2;
`;

const Spring = styled(ToolKitFix)`
  width: 100px;
  height: 100px;
  bottom: -10px;
  right: 70px;
  transform: rotate(340deg);
  position: absolute;
  z-index: 1;
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    display: none;
  }
`;
const Hexa = styled(ToolKitFix)`
  width: 100px;
  height: 100px;
  bottom: -10px;
  right: -20px;
  transform: rotate(340deg);
  position: absolute;
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    display: none;
  }
`;

export default function WorkshopStandalone({ shopData }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [shop, setShop] = useState(shopData || {});
  const refMaps = useRef(null);
  const refHours = useRef(null);
  const [product, setProduct] = useState('1');
  const { id, name } = useParams();

  const metaDescription = React.useMemo(() => {
    if (shop && shop.city && shop.postal_code) {
      return t('standalone.meta.description', { name, city: `${shop.city} ${shop.postal_code}` });
    }
    return name;
  }, [shop, name, t]);

  const getShop = async ({ id }) => {
    setLoading(true);
    const shopItem = await api.get(`/shops/${id}`);
    const parsed = parse_all_shops({ shopItem });
    setShop({ ...parsed[0].value });
    setLoading(false);
  };

  useEffect(() => {
    if (!shopData) {
      getShop({ id });
    } else {
      setLoading(false);
    }
  }, [id, shopData]);

  const metaTitle = shop.city && shop.postal_code
    ? t('standalone.meta.title', { name: shop.name, city: `${shop.city} ${shop.postal_code}` })
    : name;

  const scrollToRef = (index) => {
    const refScroll =
      index === '2'
        ? refMaps.current.offsetTop - 80
        : refHours.current.offsetTop - 80;

    window.scrollTo({
      top: refScroll,
      behavior: 'smooth',
    });
  };

  const createAppointment = () => {
    dispatch(setPlaceId(''));
    dispatch(setAddress(shop.address));
    dispatch(setPosition(shop.address, ''));
    dispatch(setProductId(product));
    dispatch(setSelectedShop(shop));
    history.push(`/reparation/symptoms`);
  };

  const BookableOnly = ({ children }) =>
    shop && shop.bookable ? (
      <React.Fragment>{children}</React.Fragment>
    ) : (
      <React.Fragment />
    );

  return (
    <>
      <Helmet
        title={metaTitle}
        meta={[
          {
            name: 'description',
            content: metaDescription,
          },
        ]}
      />
      <Container fluid>
        <Container pt={4}>
          <Header loading={loading} {...shop} scrollToRef={scrollToRef} />
          <Box mb={4}>
            <Text style={{ lineHeight: '1.5' }}>
              {!loading && shop.name} est un atelier de réparation de vélos situé au {!loading && shop.address.split(/\d{5}/)[0]} dans la ville de {!loading && shop.city}. Cet atelier est répertorié dans notre annuaire, facilitant ainsi la recherche de professionnels de la réparation de vélos près de chez vous. L'entretien régulier de votre vélo est essentiel pour garantir sa performance et votre sécurité lors de vos déplacements. Grâce à notre plateforme, vous pouvez facilement accéder aux coordonnées de {!loading && shop.name} pour toute demande de réparation ou d'entretien. Vous avez également la possibilité de consulter leur site web {!loading && shop.website} ou de les contacter directement au {!loading && shop.phone} pour obtenir plus d'informations. Utilisez notre carte interactive pour localiser {!loading && shop.name} et planifier votre visite. Un bon entretien contribue à prolonger la durée de vie de votre vélo et à optimiser vos trajets quotidiens.
            </Text>
          </Box>

          <BookableOnly>
            <Box flexDirection={{ xs: 'column', md: 'row' }} mb={7}>
              <div>
                <Box
                  flex={5}
                  p={5}
                  mr={{ xs: 0, md: 4 }}
                  bg="gray.lightest"
                  borderRadius="xs"
                  style={{ position: 'relative', overflow: 'hidden' }}>
                  <Spring name="spring" color="yellow.main" />
                  <Hexa name="hexa" color="violet.pale" />
                  <Box>
                    <StyledText
                      mb={4}
                      color="black"
                      fontWeight="bold"
                      variant="large">
                      {t('standalone.section.information.title')}
                    </StyledText>
                    <Box flexDirection="row">
                      <Danger24 color="gray.darker" />
                      <StyledText mb={4} color="black" variant="medium" ml={2}>
                        {t('standalone.section.information.text.1')}
                      </StyledText>
                    </Box>
                    <Box flexDirection="row">
                      <Calendar24 color="gray.darker" />
                      <StyledText mb={4} color="black" variant="medium" ml={2}>
                        {t('standalone.section.information.text.2')}
                      </StyledText>
                    </Box>
                    <Box flexDirection="row">
                      <Danger24 color="gray.darker" />
                      <StyledText mb={4} color="black" variant="medium" ml={2}>
                        {t('standalone.section.information.text.3')}
                      </StyledText>
                    </Box>
                  </Box>
                </Box>
              </div>
              <ReservationBox
                {...shop}
                createAppointment={createAppointment}
                product={product}
                setProduct={setProduct}
              />
            </Box>
          </BookableOnly>
          <Separator axe="horizontal" my={2} />
          <div ref={refMaps}>
            <Maps {...shop} />
          </div>
          <BookableOnly>
            <Separator axe="horizontal" my={2} />
            <SectionInformations
              {...shop}
              product={product}
              setProduct={setProduct}
            />
          </BookableOnly>
          <Separator axe="horizontal" my={2} />
          <div ref={refHours}>
            <InformationsWorkshop
              {...shop}
              loading={loading}
              setProduct={setProduct}
              product={product}
              createAppointment={createAppointment}
              bookable={shop && shop.bookable}
            />
          </div>
          <Separator axe="horizontal" my={2} />
        </Container>
      </Container>
    </>
  );
}
