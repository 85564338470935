import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12.003 7.378a4.618 4.618 0 100 9.237 4.618 4.618 0 100-9.237zm0 7.616a2.997 2.997 0 110-5.995 2.997 2.997 0 110 5.995z"
      fill="#000"
    />
    <path
      d="M15.711 3.057c-1.656-.078-5.758-.074-7.415 0-1.457.068-2.741.42-3.777 1.455-1.731 1.731-1.51 4.064-1.51 7.484 0 3.501-.195 5.78 1.51 7.484 1.738 1.738 4.104 1.51 7.484 1.51 3.468 0 4.665.003 5.891-.472 1.667-.648 2.926-2.138 3.049-4.814.078-1.657.073-5.758 0-7.416-.149-3.16-1.844-5.075-5.232-5.231zm2.621 15.278c-1.135 1.135-2.709 1.034-6.35 1.034-3.75 0-5.254.055-6.352-1.045-1.263-1.258-1.035-3.277-1.035-6.34 0-4.143-.425-7.127 3.734-7.34.955-.034 1.237-.045 3.642-.045l.034.022c3.996 0 7.132-.418 7.32 3.74.043.948.053 1.233.053 3.635-.001 3.706.07 5.219-1.046 6.34z"
      fill="#000"
    />
    <path
      d="M16.804 8.275a1.08 1.08 0 100-2.159 1.08 1.08 0 000 2.159z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgInstagram24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgInstagram24;
