import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Box from '../../shared/atoms/Box';
import Text from '../../shared/atoms/Text';
import Heading from '../../shared/atoms/Heading';

// Icons
import ArrowPersona1 from '../../icons/ArrowPersona1';
import ArrowPersona2 from '../../icons/ArrowPersona2';
import ArrowPersona3 from '../../icons/ArrowPersona3';

const StyledArrowPersona1 = styled(ArrowPersona1)`
  position: absolute;
  right: -190px;
  top: 90px;
`;

const StyledArrowPersona2 = styled(ArrowPersona2)`
  position: absolute;
  left: -100px;
  top: 70px;
`;

const StyledArrowPersona3 = styled(ArrowPersona3)`
  position: absolute;
  right: -150px;
  top: -80px;
`;

const StyledImgPersona = styled.img`
  width: 120px;
  height: 120px;
  @media (max-width: 350px) {
    width: 75px;
    height: 75px;
  }
`;

const StyledSubtitle = styled(Text)`
  font-size: ${(p) => p.theme.fontSizes.md};
  @media (max-width: 350px) {
    font-size: ${(p) => p.theme.fontSizes.sm};
  }
`;

const StyledText = styled(Text)`
  @media (max-width: 350px) {
    font-size: ${(p) => p.theme.fontSizes.xs};
    line-height: 18px;
  }
`;

const WhoIsYourFriend = () => {
  const { t } = useTranslation();
  return (
    <Box mt={{ xs: 10, lg: 12 }}>
      <Box
        flexDirection="row"
        bg="white"
        alignItems="center"
        borderRadius={100}
        p={2}
        maxWidth={450}
        boxShadow="lg"
        position="relative"
        alignSelf={{ xs: 'center', md: 'flex-start' }}>
        <StyledImgPersona src="/static/img/photos/giftcard/persona-1.png" />
        <Box ml={2} py={{ xs: 0, md: 2 }} px={2}>
          <Heading level={6} mb="0px">
            {t('whoisyourfriend.headings.1')}
          </Heading>
          <StyledSubtitle color="black" fontWeight={2}>
            {t('whoisyourfriend.texts.1')}
          </StyledSubtitle>
          <StyledText>{t('whoisyourfriend.texts.2')}</StyledText>
        </Box>
        <StyledArrowPersona1 display={{ xs: 'none', md: 'block' }} />
      </Box>
      <Box
        flexDirection="row"
        bg="white"
        alignItems="center"
        borderRadius={100}
        p={2}
        maxWidth={450}
        boxShadow="lg"
        position="relative"
        alignSelf={{ xs: 'center', md: 'flex-end' }}
        mt={4}>
        <StyledImgPersona src="/static/img/photos/giftcard/persona-2.png" />
        <Box ml={2} py={{ xs: 0, md: 2 }} px={2}>
          <Heading level={6} mb="0px">
            {t('whoisyourfriend.headings.2')}
          </Heading>
          <StyledSubtitle color="black" fontWeight={2}>
            {t('whoisyourfriend.texts.3')}
          </StyledSubtitle>
          <StyledText>{t('whoisyourfriend.texts.4')}</StyledText>
        </Box>
        <StyledArrowPersona2 display={{ xs: 'none', md: 'block' }} />
      </Box>
      <Box maxWidth={700} alignSelf="center">
        <Heading mt={7} textAlign="center">
          {t('whoisyourfriend.headings.3')}
        </Heading>
      </Box>
      <Box
        flexDirection="row"
        bg="white"
        alignItems="center"
        borderRadius={100}
        p={2}
        maxWidth={450}
        boxShadow="lg"
        position="relative"
        alignSelf="center">
        <StyledImgPersona src="/static/img/photos/giftcard/persona-3.png" />
        <Box ml={2} py={{ xs: 0, md: 2 }} px={2}>
          <Heading level={6} mb="0px">
            {t('whoisyourfriend.headings.4')}
          </Heading>
          <StyledSubtitle color="black" fontWeight={2}>
            {t('whoisyourfriend.texts.5')}
          </StyledSubtitle>
          <StyledText>{t('whoisyourfriend.texts.6')}</StyledText>
        </Box>
        <StyledArrowPersona3 display={{ xs: 'none', md: 'block' }} />
      </Box>
    </Box>
  );
};
export default WhoIsYourFriend;
