import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 352 127"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#cargo-roulezjeunesse_svg__clip0)">
      <path
        d="M285.129 88.186H180.695V38.243h77.97l55.204 11.7-28.74 38.243z"
        fill="#d5ff4f"
      />
      <path
        d="M106.141 89.043c0 19.692-15.65 35.674-35.285 35.674-19.35 0-35.286-15.982-35.286-35.674s15.651-35.675 35.286-35.675c19.35 0 35.285 15.983 35.285 35.675z"
        stroke="#000"
        strokeWidth={4}
      />
      <path
        d="M349.722 99.317c0 14.27-11.383 25.685-25.326 25.685-13.943 0-25.326-11.415-25.326-25.685s11.383-25.685 25.326-25.685c14.228 0 25.326 11.415 25.326 25.685zM178.988 88.472l-.569-86.189h-21.911"
        stroke="#000"
        strokeWidth={4}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M116.031 14.56h-30.66c-.997 0-1.994-.75-1.994-2s.748-2 1.994-2h30.66c.997 0 1.994.75 1.994 2s-.997 2-1.994 2z"
        fill="#000"
      />
      <path
        d="M101.303 14.555l36.708 74.773"
        stroke="#000"
        strokeWidth={4}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M31 53h39"
        stroke="#FFE249"
        strokeWidth={4}
        strokeLinecap="round"
      />
      <path
        d="M15.084 71.063h21.342"
        stroke="#1F77F9"
        strokeWidth={4}
        strokeLinecap="round"
      />
      <path
        d="M20.486 90.47h33.578"
        stroke="#8A38F3"
        strokeWidth={4}
        strokeLinecap="round"
      />
      <path
        d="M2.277 105.881h19.35"
        stroke="#28D898"
        strokeWidth={4}
        strokeLinecap="round"
      />
      <path
        d="M176.711 32.82h-67.44L72.562 89.9h194.355c11.951 0 23.049-5.423 30.163-14.84l15.651-20.264 12.236 44.807"
        stroke="#000"
        strokeWidth={4}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M206.876 59.076c1.257 0 2.277-1.15 2.277-2.568 0-1.419-1.02-2.569-2.277-2.569-1.257 0-2.276 1.15-2.276 2.569 0 1.418 1.019 2.568 2.276 2.568zM199.191 69.636h-6.83l4.838-4.852 2.845 2.854c.854.571.285 1.998-.853 1.998zM211.427 69.636h-3.7c-.569 0-1.422-.285-1.707-.856l-6.26-6.564.569-.571c.854-.856 2.561-.856 3.415 0l7.683 7.99zM223.664 66.497l.854 1.997c-.854.571-1.992 1.142-3.13 1.142-3.13 0-5.122-1.998-5.122-4.852 0-2.568 1.992-4.851 5.122-4.851 1.138 0 2.276.285 3.13 1.141l-.854 1.998c-.569-.57-1.138-.856-1.992-.856-1.707 0-2.845 1.141-2.845 2.568s1.138 2.569 2.845 2.569c.569 0 1.423-.286 1.992-.856zM234.76 60.218l-5.407 12.843h-2.561l1.992-4.281-3.415-8.277h2.561l2.277 5.137 2.276-5.137h2.277v-.285zM242.443 66.497l.854 1.997c-.854.571-1.992 1.142-3.13 1.142-3.13 0-5.122-1.998-5.122-4.852 0-2.568 1.992-4.851 5.122-4.851 1.138 0 2.276.285 3.13 1.141l-.854 1.998c-.569-.57-1.138-.856-1.991-.856-1.708 0-2.846 1.141-2.846 2.568s1.138 2.569 2.846 2.569c.569 0 1.422-.286 1.991-.856zM247.282 56.508V69.35h-2.276V56.508h2.276zM258.382 64.784c0 2.569-2.276 4.852-4.837 4.852-2.562 0-4.838-1.998-4.838-4.852 0-2.568 2.276-4.851 4.838-4.851 2.561 0 4.837 2.283 4.837 4.851zm-2.276 0a2.555 2.555 0 00-2.561-2.568 2.555 2.555 0 00-2.562 2.568 2.555 2.555 0 002.562 2.569 2.555 2.555 0 002.561-2.569zM260.371 60.218c0-1.998.569-3.996 2.845-3.996.569 0 1.139 0 1.423.286v1.998c-1.992-.286-1.992.57-1.992 1.712h1.992v1.998h-1.992v6.85h-2.276v-6.85h-1.139v-1.998h1.139zM266.918 60.218h2.276v8.847h-2.276v-8.847zM280.007 69.35h-2.846l-1.991-2.853-1.992 2.853h-2.846l3.415-4.851-3.13-4.281h2.845l1.708 2.568 1.707-2.568h2.846l-3.131 4.28 3.415 4.852z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="cargo-roulezjeunesse_svg__clip0">
        <path fill="#fff" d="M0 0h352v127H0z" />
      </clipPath>
    </defs>
  </svg>
);

const CAN_SET_COLOR = true;
const SvgCargoRoulezjeunesse = styled(SVG)`
  min-height: 120px;
  min-width: 300px;
  max-height: 120px;
  max-width: 300px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgCargoRoulezjeunesse;
