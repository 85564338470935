import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 237 194"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0a2 2 0 00-2 2v130.287c0 17.673 14.327 32 32 32h22.857l-3.356 28.997 42.11-28.997H234.95a2 2 0 100-4H92.367l-35.88 24.707 2.86-24.707H32c-15.464 0-28-12.536-28-28V2a2 2 0 00-2-2z"
      fill="#d5ff4f"
    />
  </svg>
);

const CAN_SET_COLOR = true;
const SvgIllustrationMessageBubble = styled(SVG)`
  min-height: 194px;
  min-width: 237px;
  max-height: 194px;
  max-width: 237px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgIllustrationMessageBubble;
