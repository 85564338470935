import { useState, useEffect } from 'react';
import * as api from './api';

export const useStripeSetupIntent = () => {
  const [intent, setIntent] = useState(false);
  const [key, setKey] = useState(0); // used to reload intent

  useEffect(() => {
    (async () => {
      const itt = await api.post('/setup_intents');
      try {
        setIntent(itt.intent.client_secret);
      } catch (e) {
        console.error(
          `couldn't get stripe intent. Received : ${JSON.stringify(itt)}`,
        );
      }
    })();
  }, [key]);

  const reloadIntent = () => setKey(key + 1);

  return [intent, reloadIntent];
};

export const useStripePaymentIntent = ({ client_id, amount, ...props }) => {
  const [intent, setIntent] = useState(false);
  const [key, setKey] = useState(0); // used to reload intent

  useEffect(() => {
    (async () => {
      const params = { client_id, amount };
      if (props.course_id) {
        params.course_id = props.course_id;
      }
      const itt = await api.post('/payment_intents', params);
      try {
        setIntent(itt.intent.client_secret);
      } catch (e) {
        console.log(e);
        console.error(
          `couldn't get stripe intent. Received : ${JSON.stringify(itt)}`,
        );
      }
    })();
  }, [key]);

  const reloadIntent = () => setKey(key + 1);

  return [intent, reloadIntent];
};
