import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import SectionBackground from '../shared/molecules/SectionBackground';
import Row from '../shared/atoms/Row';
import Box from '../shared/atoms/Box';
import Heading from '../shared/atoms/Heading';
import Text from '../shared/atoms/Text';
import SideBar from '../shared/molecules/SideBar';
// Icons
import PlayBtn from '../icons/doNotDelete/PlayBtn';
// Hooks
import useAlert from '../../hooks/useModal';

const StyledImg = styled.img`
  border-radius: 24px;
  max-width: 450px;
`;

const StyledPlay = styled(PlayBtn)`
  left: 50%;
  margin-left: -50px;
  top: 50%;
  margin-top: -50px;
  height: 100px;
  width: 100px;
  position: absolute;
`;

const StyledBox = styled(Box)`
  position: relative;
  max-width: 450px;
  cursor: pointer;
`;

const Steps = ({
  title,
  text,
  img,
  product,
  steps = [],
  colorShadow = false,
  ...props
}) => {
  const showModal = useAlert();
  const { t } = useTranslation();
  const openModal = () => {
    showModal({
      width: 'large',
      content: (
        <div
          style={{
            marginTop: '40px',
            borderRadius: '16px',
            overflow: 'hidden',
            width: '100%',
            display: 'flex',
            height: '100%',
          }}>
          <iframe
            width="100%"
            height="550"
            frameBorder="0"
            src="https://www.youtube.com/embed/fpyTjkui91w?autoplay=1"></iframe>
        </div>
      ),
      showModal: true,
      showButton: [
        {
          text: t(`close.cta`),
          variant: 'neutral',
        },
      ],
    });
  };
  return (
    <SectionBackground
      bg="gray.lightest"
      py={10}
      px={{ xs: 5, lg: 10 }}
      mb={10}
      mt={11}>
      <Row md gap={56} {...props}>
        <Box>
          <Heading level={2}>{title}</Heading>
          <Text mt={-2}>{text}</Text>
          <StyledBox onClick={openModal} mt={4}>
            <StyledImg
              src="/static/img/photos/recruitmentFixer/video-image-app-staff.jpg"
              alt={t('app.roulezjeunesse')}
            />
            <StyledPlay />
          </StyledBox>
        </Box>
        <Box>
          {steps.map((step, index) => {
            const lastIndex = steps.length - 1;
            return (
              <SideBar
                key={product.productLabelMain + index}
                title={step.title}
                colorTitle="black"
                content={[step.text]}
                animation={false}
                checkColor="primary"
                shadowColor={colorShadow}
                end={index === lastIndex}
              />
            );
          })}
        </Box>
      </Row>
    </SectionBackground>
  );
};

export default Steps;
