import React from 'react';
import styled from 'styled-components';
import { layout, shadow, border, space } from 'styled-system';

const StyledImage = styled.img`
  object-fit: ${(p) => p.fit};
  object-position: 50% 50%;
  overflow: hidden;
  background-color: ${(p) => p.theme.colors.gray.lightest};
  ${layout};
  ${shadow};
  ${border};
  ${space};
`;

export default function Image({ src, alt, fit = 'cover', ...props }) {
  return (
    <StyledImage
      p={0}
      overflow="hidden"
      as="img"
      fit={fit}
      borderRadius="sm"
      height={'100%'}
      width={'100%'}
      src={src}
      alt={alt}
      {...props}
    />
  );
}
