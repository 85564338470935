import React from 'react';
import styled, { css } from 'styled-components';
import {
  space,
  layout,
  color as colorSystem,
  flexbox,
  position,
} from 'styled-system';
import Box from './Box';

const StyledBadge = styled(Box)`
  ${space};
  ${layout};
  ${colorSystem};
  ${flexbox};
  ${position};
`;

/**
 * @param {string} color Any Theme color or HEX value
 * @param {icon} string Any icon in the design system

 */

export default function BadgeIcon({ color = 'primary.main', icon, ...props }) {
  return (
    <StyledBadge display="inline-block" verticalAlign="middle" {...props}>
      <Box
        bg={color}
        width="24px"
        height="24px"
        borderRadius="100%"
        justifyContent="center"
        alignItems="center"
        p={2}>
        {icon}
      </Box>
    </StyledBadge>
  );
}
