import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  useStripe,
  useElements,
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as api from '../utils/api';
import constants from '../utils/constants';
import { isDev, isStaging } from '../utils/utils';
// Components
import Box from './shared/atoms/Box';
import Text from './shared/atoms/Text';

const stripePromise = loadStripe(
  isDev() || isStaging()
    ? constants.STRIPE_KEY_TEST
    : constants.STRIPE_KEY_LIVE,
);

const StyledSpan = styled.span`
  color: ${(p) => p.theme.colors.primary.main};
`;

const StyledBox = styled(Box)`
  .elementStripe {
    font-family: DraftC, Arial, sans-serif;
    font-size: 1em !important;
    font-weight: 400;
    padding: 12px 16px;
    border: 1px solid ${(p) => p.theme.colors.gray.light};
    font-size: 16px;
    background-color: ${(p) => p.theme.colors.gray.lightest};
    border-radius: 16px;
    ::placeholder {
      color: #626a68;
      width: 100%;
    }
  }
  .elementStripeError {
    padding: 12px 16px;
    border: 1px solid ${(p) => p.theme.colors.primary.darkest};
    font-size: 16px;
    background-color: ${(p) => p.theme.colors.primary.lighter};
    border-radius: 16px;
    ::placeholder {
      color: #626a68;
    }
  }
`;

const PayFormNotLoggedIn = ({
  stripePayment,
  setStripePayment,
  setLoading = () => {},
  amount,
  clientNotConnected,
  giftcard,
  setGiftcard,
}) => {
  const stripe = useStripe();
  const { t } = useTranslation();
  const elements = useElements();
  const { expiry, cvc, number, message, state } = stripePayment;
  const setError = (error) => {
    setLoading(false);
    setStripePayment({
      ...stripePayment,
      state: 'error',
      message: error,
    });
  };

  const handleServerResponse = (response) => {
    if (response.error_message) {
      // Show error from server on payment form
      setError(response.error_message);
    } else if (response.requires_action) {
      // Use Stripe.js to handle required card action
      stripe
        .handleCardAction(response.payment_intent_client_secret)
        .then(handleStripeJsResult);
    } else {
      // Show success message
      setGiftcard({
        ...giftcard,
        amount: response.giftcard.amount,
        customAmount: '',
        id: response.giftcard.id,
        code: response.giftcard.code,
        created_at: response.giftcard.created_at,
      });
      setStripePayment({
        ...stripePayment,
        state: 'succes',
      });

      setLoading(false);
    }
  };

  const stripePaymentMethodHandler = (result) => {
    if (result.error_message) {
      // Show error in payment form
      setError(result.error_message);
    } else {
      // Otherwise send paymentMethod.id to your server
      api
        .post('/giftcards', {
          amount,
          email: clientNotConnected.email,
          first_name: clientNotConnected.prenom,
          last_name: clientNotConnected.nom,
          payment_method_id: result.paymentMethod.id,
          giftcard_url: `https://www.roulezjeunesse.com/static/img/pictos/giftcard/gift-card-${giftcard.giftcardChoice}.png`,
          recipient_name: giftcard.recipientName,
          message: giftcard.message,
        })
        .then((rep) => {
          handleServerResponse(rep);
        });
    }
  };

  const handleStripeJsResult = (result) => {
    if (result.error_message) {
      setError(result.error_message);
    } else {
      api
        .post('/giftcards', {
          amount,
          email: clientNotConnected.email,
          first_name: clientNotConnected.prenom,
          last_name: clientNotConnected.nom,
          payment_intent_id: result.paymentIntent.id,
          giftcard_url: `https://www.roulezjeunesse.com/static/img/pictos/giftcard/gift-card-${giftcard.giftcardChoice}.png`,
          recipient_name: giftcard.recipientName,
          message: giftcard.message,
        })
        .then((confirmResult) => {
          return confirmResult.json();
        })
        .then(handleServerResponse);
    }
  };
  const _doSend = async () => {
    setLoading(true);
    const cardNumber = elements.getElement(CardNumberElement);
    const method = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
      billing_details: {
        name: `${clientNotConnected.prenom} ${clientNotConnected.nom}`,
        email: clientNotConnected.email,
      },
    });
    stripePaymentMethodHandler(method);
  };

  const setDefaultStripePayment = (e, value) => {
    const { complete } = e;
    setStripePayment({ ...stripePayment, [value]: false, complete });
  };

  useEffect(() => {
    const { state } = stripePayment;
    if (state === 'send') _doSend();
  }, [stripePayment]);

  return (
    <Box width="100%" m={0}>
      <StyledBox m={0} data-testid="card-number-element">
        <Text mb={1} as="label" color="black" variant="small" fontWeight="400">
          {t('number.card')}
          <StyledSpan>*</StyledSpan>
        </Text>
        <CardNumberElement
          onChange={(e) => setDefaultStripePayment(e, 'number')}
          className={number ? 'elementStripeError' : 'elementStripe'}
        />
        {number && (
          <Text
            mt={1}
            variant="caption"
            fontWeight="bold"
            data-testid="error-card-number-element"
            color="primary.darkest">
            {message}
          </Text>
        )}
        <Box mt={4} flexDirection={{ xs: 'column', lg: 'row' }}>
          <Box
            mr={{ xs: 0, lg: 2 }}
            width="100%"
            data-testid="card-expiry-element">
            <Text
              as="label"
              mb={1}
              color="black"
              variant="small"
              fontWeight="400">
              {t('expiration')}
              <StyledSpan>*</StyledSpan>
            </Text>
            <CardExpiryElement
              onChange={(e) => setDefaultStripePayment(e, 'expiry')}
              className={expiry ? 'elementStripeError' : 'elementStripe'}
            />
            {expiry && (
              <Text
                mt={1}
                variant="caption"
                fontWeight="bold"
                color="primary.darkest">
                {message}
              </Text>
            )}
          </Box>
          <Box
            ml={{ xs: 0, lg: 2 }}
            mt={{ xs: 4, lg: 0 }}
            width="100%"
            data-testid="card-cvc-element">
            <Text
              as="label"
              mb={1}
              color="black"
              variant="small"
              fontWeight="400">
              {t('security.code')}
              <StyledSpan>*</StyledSpan>
            </Text>
            <CardCvcElement
              onChange={(e) => setDefaultStripePayment(e, 'cvc')}
              className={cvc ? 'elementStripeError' : 'elementStripe'}
            />
            {cvc && (
              <Text
                mt={1}
                variant="caption"
                fontWeight="bold"
                color="primary.darkest">
                {message}
              </Text>
            )}
          </Box>
        </Box>
        {!cvc && !expiry && !number && message && state === 'error' && (
          <Text
            mt={1}
            variant="caption"
            fontWeight="bold"
            color="primary.darkest">
            {message}
          </Text>
        )}
      </StyledBox>
    </Box>
  );
};

const ElementedForm = ({ ...props }) => {
  return (
    <Elements stripe={stripePromise}>
      <PayFormNotLoggedIn {...props} />
    </Elements>
  );
};

export default ElementedForm;
