import React from 'react';
import styled from 'styled-components';
import { compose, shadow, border, position } from 'styled-system';
import propTypes from '@styled-system/prop-types';

import Box from './Box';

export const StyledCard = styled(Box)(compose(shadow, border, position));

/**
 * An extended Box to display content
 * @param radius {string} radius same as borderRadius xs | sm | md | lg
 * @param {string} as html element
 * @param {string} any extends Box properties (space, layout, color, flexbox,position)
 * @param {string} any Styled System shadow, border properties
 */
function Card({ radius = 'sm', children, ...props }) {
  return (
    <StyledCard
      bg="white"
      p={5}
      borderRadius={radius}
      overflow="hidden"
      position="relative"
      {...props}>
      {children}
    </StyledCard>
  );
}

Card.propTypes = {
  ...propTypes.shadow,
  ...propTypes.border,
  ...propTypes.position,
};

export default Card;
