import React from 'react';
import styled, { css } from 'styled-components';
import Box from './Box';
import Check24 from '../../icons/Check24';
import { lightenDarken } from '../../../utils/utils';

const StyledBox = styled(Box)`
  cursor: pointer;
  ${(p) => {
    if (p.color === 'black') {
      return css`
        background-color: ${p.theme.colors[p.color]};
      `;
    }
    if (p.subColor) {
      return css`
        background-color: ${p.theme.colors[p.color][p.subcolor]};
      `;
    }
    return css`
      background-color: ${p.theme.colors[p.color].main};
    `;
  }}
  :hover {
    opacity: 1 !important;
    ${(p) => {
      if (p.color === 'black') {
        return css`
          background-color: ${lightenDarken(
            p.hex || p.theme.colors[p.color],
            30,
          )};
        `;
      }
      if (p.subColor) {
        return css`
          background-color: ${lightenDarken(
            p.hex || p.theme.colors[p.color][p.subColor],
            -10,
          )};
        `;
      }
      return css`
        background-color: ${lightenDarken(
          p.hex || p.theme.colors[p.color].main,
          -10,
        )};
      `;
    }}


`;

const ColorCheck = ({
  color,
  subColor,
  colorChecked,
  setColorChecked,
  ...props
}) => {
  const colorWithSubColor = subColor
    ? `${color}.${subColor}`
    : color === 'black'
    ? color
    : `${color}.main`;
  const handleColor = () => {
    colorWithSubColor === colorChecked
      ? setColorChecked('')
      : setColorChecked(colorWithSubColor);
  };
  return (
    <StyledBox
      width={40}
      height={40}
      borderRadius="100%"
      justifyContent="center"
      alignItems="center"
      onClick={() => handleColor()}
      {...{ color, subColor }}
      {...props}>
      {' '}
      <Box
        borderRadius="100%"
        borderColor="white"
        borderStyle="solid"
        borderWidth="2px"
        display={colorWithSubColor === colorChecked ? 'flex' : 'none'}
        p={1}>
        <Check24 color="white" style={{ cursor: 'pointer' }} />
      </Box>
    </StyledBox>
  );
};

export default ColorCheck;
