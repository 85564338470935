import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M12 7.5a1 1 0 100-2 1 1 0 000 2z" fill="#000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.128 14.375L12 14.5l-.118-.117c-1.987-1.97-4.83-4.789-4.83-7.456C7.052 4.207 9.268 2 12 2c2.733 0 4.947 2.207 4.947 4.927 0 2.664-2.836 5.478-4.82 7.448zM12 11.948c-.63-.65-1.25-1.334-1.785-2.04-.928-1.227-1.364-2.228-1.364-2.98a3.14 3.14 0 013.15-3.128 3.137 3.137 0 013.146 3.127c0 .753-.436 1.755-1.363 2.98-.535.707-1.155 1.39-1.784 2.041z"
      fill="#000"
    />
    <path
      d="M15.675 12.9a.9.9 0 100 1.8h1.629a.9.9 0 010 1.8H6.749a2.75 2.75 0 000 5.498h5.355a.9.9 0 100-1.8H6.749a.95.95 0 110-1.898h10.555a2.7 2.7 0 000-5.4h-1.629z"
      fill="#000"
    />
    <path d="M16 21.2a1 1 0 11-2 0 1 1 0 012 0z" fill="#000" />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgPinmove24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPinmove24;
