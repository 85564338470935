import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      className="recolor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.106 22.817a3 3 0 01-1.61-5.77l7.573-2.61 3.532 4.41-3.491 5.663 11.668-3.385a3 3 0 111.672 5.762l-19.14 5.554-3.39-4.455 3.186-5.17z"
      fill="#FFE249"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.5 6.9a1 1 0 011-1h6.37l4.592 32.857h.038c3.038 0 5.5 2.517 5.5 5.621C50 47.483 47.538 50 44.5 50S39 47.483 39 44.378c0-2.382 1.45-4.418 3.496-5.237l-.502-3.598-8.019 9.835H16.913C16.452 48.006 14.203 50 11.5 50 8.462 50 6 47.483 6 44.378s2.462-5.621 5.5-5.621c2.704 0 4.952 1.994 5.413 4.621h16.112l8.59-10.537L38.13 7.9H33.5a1 1 0 01-1-1zM15 44.378C15 46.42 13.392 48 11.5 48S8 46.42 8 44.378c0-2.041 1.608-3.621 3.5-3.621s3.5 1.58 3.5 3.621zm33 0C48 46.42 46.392 48 44.5 48S41 46.42 41 44.378c0-2.041 1.608-3.621 3.5-3.621s3.5 1.58 3.5 3.621z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = true;
const SvgScooterColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      .recolor {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgScooterColor56;
