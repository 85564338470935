import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import * as api from '../../../../utils/api';
import useAlert from '../../../../hooks/useModal';
// Components
import Box from '../../../shared/atoms/Box';
import Heading from '../../../shared/atoms/Heading';
import Button from '../../../shared/atoms/Button';
import Text from '../../../shared/atoms/Text';
import DateTimeModule from '../../../dateTimeModule/DateTimeModule';
import Modal from '../../../../modals/Modal';
import Spinner from '../../../Spinner';
// Icons
import CalendarColor56 from '../../../icons/CalendarColor56';
import { getVersion } from '../../../../utils/utils';

const StyledModal = styled(Modal)`
  max-height: 80vh;
  margin-bottom: 24px;
`;

const UpdateDisponibility = ({
  course,
  setCourse,
  showModal,
  openModal,
  getRequest,
  additionalProps,
}) => {
  const { t } = useTranslation();
  const { slots, position, product_id, capabilities } = course;
  const [handleSlots, setHandleSlots] = useState(slots);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const showModalDate = useAlert();
  const history = useHistory();
  const isShop = !!course.fixer?.shop_id;

  const { isReopen = false, fixer_id = false } = additionalProps;

  const reopen = async () => {
    setLoading(true);
    if (course.status === 'ANNULE') {
      const newCourseParameters = {
        client: { id: course.client_id },
        date: course.date,
        available_min: course.available_min,
        available_max: course.available_max,
        position: course.position,
        status: '',
        paiement: course.paiement,
        course_details: course.course_details,
        bike_details: course.bike_details,
        enregistrement_type: 'REOPEN',
        company_id: course.company_id,
        product_id: course.product_id,
        codepromo: course.codepromo,
        pm_id: course.pm_id,
        capabilities: course.capabilities,
        slots: handleSlots,
        version: getVersion(),
      };

      if (course.fixer?.shop_id) {
        newCourseParameters.shop_id = course.fixer.shop_id;
      }

      const rep = await api.post('/courses', newCourseParameters);
      if (rep.id) {
        openModal(false);
        setErrorMsg('');
        history.push(`/courses/${rep.id}`);
      } else {
        setErrorMsg(rep.error_message);
      }
    }
    setLoading(false);
  };
  const modifier = async () => {
    setLoading(true);
    if (!course.fixer || course.fixer?.shop_id) {
      const courseParameters = {};
      if (course.fixer?.shop_id) {
        // fixer shop on the course modif date directly
        courseParameters.date = handleSlots[0].available_min;
      } else {
        // no fixer on the course modif slots directly
        courseParameters.slots = handleSlots;
      }
      const courseUpdated = await api.put(
        `/courses/${course.id}`,
        courseParameters,
      );
      if (courseUpdated.id) {
        setCourse(courseUpdated);
        openModal(false);
        setErrorMsg('');
      } else {
        setErrorMsg(courseUpdated.error_message);
      }
    } else {
      // fixer on the course, send him a notif
      const request = await api.post(`/courses/${course.id}/requests`, {
        slots: handleSlots,
      });
      if (Number(request.ok) === 1) {
        getRequest();
        openModal(false);
        setErrorMsg('');
      } else {
        setErrorMsg(t('updatemodal.error'));
      }
    }
    setLoading(false);
  };
  const warning = () => {
    if (handleSlots.length === 0) {
      showModalDate({
        title: t('selectslots'),
        content: t(`getanappointmentfaster`, { label: 5 - handleSlots.length }),
        showModal: true,
        showButton: [
          {
            text: t('addslots'),
            width: '200px',
          },
        ],
      });
    } else if (handleSlots.length < 5 && !isShop) {
      showModalDate({
        title: t('doyouhavemoredisponibility'),
        content: t(`getanappointmentfaster`, { label: 5 - handleSlots.length }),
        showModal: true,
        showButton: [
          {
            text: t('continue'),
            variant: 'neutral',
            width: '200px',
            color: 'black',
            onClick: () => (isReopen ? reopen() : modifier()),
          },
          {
            text: t('addslots'),
            width: '200px',
          },
        ],
      });
    } else {
      isReopen ? reopen() : modifier();
    }
  };

  const toggleSlot = (available_min, available_max) => {
    if (isShop) {
      setHandleSlots([{ available_min, available_max }]);
    } else {
      setHandleSlots((currentSlots) => {
        return currentSlots.find(
          (s) =>
            s.available_min === available_min &&
            s.available_max === available_max,
        )
          ? currentSlots.filter((s) => {
            return (
              s.available_min !== available_min &&
              s.available_max !== available_max
            );
          })
          : [...currentSlots, { available_min, available_max }];
      });
    }
  };

  // const setSlot = (available_min, available_max) => {
  //   setHandleSlots([{ available_min, available_max }]);
  // };

  return (
    <StyledModal
      zIndex="1000"
      hasCloseAction={true}
      width="medium"
      overflow="auto"
      open={showModal === 'UPDATE_DISPONIBILITY'}
      onRequestClose={() => {
        openModal(false);
        setHandleSlots(slots);
      }}>
      <Box mb={4}>
        <CalendarColor56 />
      </Box>
      <Heading mb={4} level={5}>
        {t('updatedisponibilitymodal.heading', {
          label: isReopen ? t('relaunch') : t('modify'),
        })}
      </Heading>

      <DateTimeModule
        slots={handleSlots}
        toggleSlot={toggleSlot}
        position={position}
        product_id={product_id}
        toContinue={false}
        fixerId={fixer_id}
        shopId={course.fixer?.shop_id}
        capabilities={isReopen ? capabilities.map((c) => ({ id: c.id })) : null}
        noDispoModalOpen={() => {
          openModal('NO_FIXER');
        }}
      />
      {errorMsg && (
        <Text fontWeight={2} color="primary.darkest" mt={1}>
          {errorMsg}
        </Text>
      )}
      <Box alignItems="flex-end" justifyContent="flex-end" flexDirection="row">
        <Button
          disabled={handleSlots.length === 0 || loading}
          size="compact"
          color="blue.electric"
          onClick={() => warning()}
          style={{ width: '100%' }}>
          {loading ? <Spinner small /> : t('save')}
        </Button>
      </Box>
    </StyledModal>
  );
};

export default UpdateDisponibility;
