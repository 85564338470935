import React from 'react';
import { useTranslation } from 'react-i18next';
// Components
import styled, { css } from 'styled-components';
import BeReminded from '../shared/BeReminded';
// Icons
import Rocket24 from '../../icons/Rocket24';
import Team24 from '../../icons/Team24';
import Megaphone24 from '../../icons/Megaphone24';
import Pen24 from '../../icons/Pen24';
import Calendar24 from '../../icons/Calendar24';
import Invoice24 from '../../icons/Invoice24';
import Book24 from '../../icons/Book24';
import Thumbup24 from '../../icons/ThumbUp24';
import Smiley24 from '../../icons/Smiley24';

const StyledSpanInfos = styled.span`
  ${(p) =>
    css`
      font-size: ${p.theme.fontSizes.sm};
      font-weight: ${p.theme.fontWeights[1]};
    `}
`;

const SectionBeReminded = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <>
      <BeReminded
        id={'credits-reparation'}
        type="employeurs"
        title={t('bereminded.titles.1')}
        text={t('bereminded.subtitles.1')}
        textBold={t('bereminded.subtitles.bold.1')}
        textButton={t('callme.cta')}
        labelButton={t('bereminded.cta.labels.1')}
        infos={[
          {
            icon: <Calendar24 />,
            text: <StyledSpanInfos>{t('bereminded.texts.1')}</StyledSpanInfos>,
          },
          {
            icon: <Rocket24 />,
            text: <StyledSpanInfos>{t('bereminded.texts.2')}</StyledSpanInfos>,
          },
          {
            icon: <Team24 />,
            text: <StyledSpanInfos>{t('bereminded.texts.3')}</StyledSpanInfos>,
          },
        ]}
        img="/static/img/photos/business/image-section-2_entreprise.png"
      />
      <BeReminded
        id={'ateliers-reparation'}
        reverse={true}
        type="employeurs"
        title={t('bereminded.titles.2')}
        text={t('bereminded.subtitles.2')}
        textBold={t('bereminded.subtitles.bold.2')}
        textButton={t('callme.cta')}
        labelButton={t('bereminded.cta.labels.2')}
        infos={[
          {
            icon: <Pen24 />,
            text: <StyledSpanInfos>{t('bereminded.texts.4')}</StyledSpanInfos>,
          },
          {
            icon: <Book24 />,
            text: <StyledSpanInfos>{t('bereminded.texts.5')}</StyledSpanInfos>,
          },
          {
            icon: <Invoice24 />,
            text: <StyledSpanInfos>{t('bereminded.texts.6')}</StyledSpanInfos>,
          },
        ]}
        img="/static/img/photos/business/image-section-4_entreprise.png"
      />
      <BeReminded
        id={'programme-partenaire'}
        type="employeurs"
        title={t('bereminded.titles.3')}
        text={t('bereminded.subtitles.3')}
        textBold={t('bereminded.subtitles.bold.3')}
        textButton={t('callme.cta')}
        labelButton={t('bereminded.cta.labels.2')}
        infos={[
          {
            icon: <Thumbup24 />,
            text: <StyledSpanInfos>{t('bereminded.texts.7')}</StyledSpanInfos>,
          },
          {
            icon: <Megaphone24 />,
            text: <StyledSpanInfos>{t('bereminded.texts.8')}</StyledSpanInfos>,
          },
          {
            icon: <Smiley24 />,
            text: <StyledSpanInfos>{t('bereminded.texts.9')}</StyledSpanInfos>,
          },
        ]}
        img="/static/img/photos/business/hero-image_entreprise.png"
      />
    </>
  );
};
export default SectionBeReminded;
