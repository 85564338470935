import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import SectionBackground from '../../shared/molecules/SectionBackground';
import Container from '../../shared/atoms/Container';
import Heading from '../../shared/atoms/Heading';
import Box from '../../shared/atoms/Box';
import Text from '../../shared/atoms/Text';
// Icons
import TeamColor32 from '../../icons/TeamColor32';
import QuestionColor32 from '../../icons/QuestionColor32';
import PinMoveColor32 from '../../icons/PinMoveColor32';
import SearchColor32 from '../../icons/SearchColor32';

const StyledImg = styled.img`
  height: auto;
  width: auto;
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    max-height: 450px;
    max-width: 300px;
  }
`;

const Informations = ({ style, icon, title, text }) => (
  <Box
    flexDirection={{ xs: 'row', md: 'column' }}
    style={{ flex: 1 }}
    {...style}>
    {icon}
    <Box ml={{ xs: 2, md: 0 }}>
      <Heading level={6} my={2}>
        {title}
      </Heading>
      <Text color="black">{text}</Text>
    </Box>
  </Box>
);

const SupportAndService = () => {
  const { t } = useTranslation();
  return (
    <SectionBackground alignItems="center" mb={{ xs: 5, md: '160px' }}>
      <Container>
        <Box
          flexDirection={{ xs: 'column-reverse', md: 'row' }}
          alignItems="center">
          <Box
            justifyContent="center"
            width={{ xs: '100%', md: '40%' }}
            alignItems={{ xs: 'center', md: 'flex-start' }}>
            <StyledImg
              src="/static/img/photos/business/image-section-1_home-business.png"
              alt={t('roulezjeunessebusiness')}
            />
          </Box>
          <Box
            width={{ xs: '100%', md: '60%' }}
            ml={{ xs: 0, md: 11 }}
            mb={{ xs: 5, md: 0 }}>
            <Heading level={2} mb={5}>
              {t('supportandservice.heading')}
            </Heading>
            <Box>
              <Box flexDirection={{ xs: 'column', md: 'row' }} mb={5}>
                <Informations
                  style={{ mr: '12px', mb: { xs: 5, md: 0 } }}
                  icon={<TeamColor32 />}
                  title={t('supportandservice.titles.1')}
                  text={t('supportandservice.texts.1')}
                />
                <Informations
                  style={{ mr: '12px' }}
                  icon={<QuestionColor32 />}
                  title={t('supportandservice.titles.2')}
                  text={t('supportandservice.texts.2')}
                />
              </Box>
              <Box flexDirection={{ xs: 'column', md: 'row' }}>
                <Informations
                  style={{ mr: '12px', mb: { xs: 5, md: 0 } }}
                  icon={<PinMoveColor32 />}
                  title={t('supportandservice.titles.3')}
                  text={t('supportandservice.texts.3')}
                />
                <Informations
                  style={{ mr: '12px' }}
                  icon={<SearchColor32 />}
                  title={t('supportandservice.titles.4')}
                  text={t('supportandservice.texts.4')}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </SectionBackground>
  );
};

export default SupportAndService;
