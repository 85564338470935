import React from 'react';
import { useTranslation } from 'react-i18next';
import constants from '../../utils/constants';

const MainImageSelector = ({ partenaire, ...props }) => {
  const { t } = useTranslation();
  switch (partenaire) {
    case 'ratp':
      return (
        <img
          src={`${constants.CDN_BASE_URL}/img/hab/resa.png`}
          style={{ maxHeight: 350 }}
          alt={t('mainimageselector.text')}
        />
      );
    default:
      return (
        <img
          src={`${constants.CDN_BASE_URL}/img/hab/2.png`}
          style={{ maxHeight: 350 }}
          alt={t('mainimageselector.text')}
        />
      );
  }
};

export default MainImageSelector;
