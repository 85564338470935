import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Box from '../shared/atoms/Box';
import Badge from '../shared/atoms/Badge';
import Text from '../shared/atoms/Text';
import Switch from '../shared/atoms/Switch';
import Button from '../shared/atoms/Button';

import StarFill24 from '../icons/StarFill24';
import Switch16 from '../icons/Switch16';

import { setFixerId } from '../../actions/course';

import RenewFixerModal from '../../modals/RenewFixerModal';

export default function RenewFixerEncart({ fixers, fixer, ...rest }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fixerId = useSelector((s) => s.course.fixerId);
  const [showModal, setShowModal] = useState(false);

  const handleSwitch = () => {
    if (!fixerId) {
      setShowModal(true);
    } else {
      dispatch(setFixerId(''));
    }
  };

  const onClose = () => {
    setShowModal(false);
  };

  const onConfirm = (id) => {
    if (id) {
      dispatch(setFixerId(id));
    }
    setShowModal(false);
  };

  if (fixers === null) {
    return null;
  }

  return (
    <Fragment>
      <RenewFixerModal
        fixers={fixers}
        open={showModal}
        close={onClose}
        confirm={onConfirm}
      />
      <Box bg="orange.lighter" p={4} borderRadius="xs" {...rest}>
        <Box flexDirection="row" alignItems="flex-start" mb={2}>
          <Text
            as="label"
            variant="medium"
            flex={1}
            mb={1}
            mr={{ xs: 4, md: 0 }}>
            <b>{t('renewfixerencart.texts.2')}</b>
          </Text>
          <Switch value={!!fixerId} onChange={handleSwitch} />
        </Box>
        <Text>{t('renewfixerencart.texts.3')}</Text>
        {!!fixerId && fixer && fixer.id && (
          <Box
            mt={5}
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}>
            <Box flexDirection="row" alignItems="center" mb={{ xs: 3, md: 0 }}>
              <StarFill24 color="blue.electric" mr={2} mt="-2px" />
              <Text color="black" mr={4}>
                <b>{t('renewfixerencart.texts.4', { prenom: fixer.prenom })}</b>
              </Text>
            </Box>
            <Button
              onClick={() => setShowModal(true)}
              mt={1}
              inline
              color="blue.electric" // Modified color to blue.electric
              variant="link">
              {t('renewfixerencart.cta')}
            </Button>
            <Switch16 color="blue.electric" style={{ marginLeft: 4 }} />
          </Box>
        )}
      </Box>
    </Fragment>
  );
}
