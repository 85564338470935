import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Radio from '../atoms/Radio';
import Text from '../atoms/Text';
import Box from '../atoms/Box';

import { setProductId } from '../../../actions/course.jsx';
import { resetDiag } from '../../../actions/diag';

function ProductSelect({ label = null, bigLabel = false }) {
  const { t } = useTranslation();
  const id = useSelector((s) => s.course.product_id);
  const dispatch = useDispatch();

  const onProductSelect = (e) => {
    dispatch(setProductId(e.target.value));
    resetDiag();
  };

  return (
    <Box flex={1} justifyContent="center" mr={{ xs: 0, md: 6 }}>
      {label && (
        <Text color="black" variant={bigLabel ? 'medium' : 'caption'} py="2px">
          <b>{label}</b>
        </Text>
      )}
      <Box flexDirection="row" alignItems="center">
        <Radio
          color="black"
          value={'1'}
          checked={id.toString() === '1'}
          label={t('Bike')}
          onChange={onProductSelect}
          name="product_1"
        />
        <Radio
          color="black"
          checked={id.toString() === '2'}
          value={'2'}
          label={t('scooter.uppercase')}
          name="product_2"
          onChange={onProductSelect}
        />
      </Box>
    </Box>
  );
}

export default ProductSelect;
