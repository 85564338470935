import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M28 45c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15v30z"
      fill="#28D898"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 5a1 1 0 011-1h10a1 1 0 110 2h-4v3.023a20.886 20.886 0 0110.65 3.502l2.507-2.508a1 1 0 011.414 1.414l-2.296 2.296C45.99 17.578 49 23.437 49 30c0 11.598-9.402 21-21 21S7 41.598 7 30c0-6.619 3.062-12.522 7.847-16.371l-2.199-2.198a1 1 0 011.415-1.414l2.42 2.42A20.885 20.885 0 0127 9.023V6h-4a1 1 0 01-1-1zm6 6C17.507 11 9 19.507 9 30s8.507 19 19 19 19-8.507 19-19-8.507-19-19-19zm1 19.83a3.001 3.001 0 10-2 0V44a1 1 0 102 0V30.83z"
      fill="#1D1D1D"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = true;
const SvgChrono56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgChrono56;
