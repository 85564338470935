/* eslint-disable prefer-destructuring */
import i18n from '../i18n';

const strapiDevisElement = ({
  price,
  price_en,
  name,
  name_en,
  id,
  estimated_duration,
}) => {
  const lang = i18n.language;

  // const prio = element ? element.attributes.prio : null;

  const getName = () => {
    return name;
  };

  const getPrice = () => {
    return price;
  };

  return {
    id,
    // prio,
    price: getPrice(),
    description: getName(),
    name_en,
    price_en,
    estimated_duration,
  };
};

export default strapiDevisElement;
