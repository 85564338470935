import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M0 42a9 9 0 100-18v18z" fill="#1F77F9" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 34v-3l6.541-11.338L5.286 16H3a1 1 0 110-2h8a1 1 0 110 2H7.4l1.029 3H23v-3h-1a1 1 0 010-2h6.5a2.618 2.618 0 011.09 5H36v-3h-1a1 1 0 010-2h6.5a2.618 2.618 0 110 5.237 1 1 0 110-2 .618.618 0 100-1.237H38v2.795l2.395 5.588A9 9 0 1136.5 39.225a9 9 0 11-10.943-14.054l-1.576-3.676L12.438 34H0zm27.5-15.763c0 .306.137.58.353.763h-2.765L25 18.795V16h3.5a.618.618 0 110 1.237 1 1 0 00-1 1zm-.105 6.146l-2.013-4.696L25.96 21h8.756l-2.932 3.177a9.043 9.043 0 00-4.39.206zm9.105 2.392a9.024 9.024 0 00-2.635-1.905l3.116-3.375 1.576 3.677a9.035 9.035 0 00-2.057 1.603zM39 33a8.959 8.959 0 00-1.268-4.609 7.035 7.035 0 011.62-1.366l2.729 6.37a1 1 0 101.838-.789l-2.73-6.37a7 7 0 11-3.458 11.373A8.959 8.959 0 0039 33zM9.114 21h12.602l-9.189 9.955L9.114 21zm1.657 11l-3.37-9.828L1.732 32h9.04zM30 26c-.626 0-1.233.082-1.81.237l2.73 6.37a1 1 0 11-1.84.787l-2.729-6.369A7 7 0 1030 26z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgFleetColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgFleetColor56;
