import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M22 5.924a8.55 8.55 0 01-2.363.647 4.077 4.077 0 001.804-2.266 8.195 8.195 0 01-2.6.993 4.099 4.099 0 00-7.093 2.804c0 .324.028.637.096.934-3.409-.166-6.425-1.8-8.451-4.288A4.128 4.128 0 002.83 6.82c0 1.42.732 2.679 1.821 3.407A4.049 4.049 0 012.8 9.722v.046a4.119 4.119 0 003.285 4.028 4.09 4.09 0 01-1.075.135c-.263 0-.527-.015-.776-.07.531 1.624 2.038 2.818 3.831 2.857a8.239 8.239 0 01-5.084 1.748c-.336 0-.659-.015-.981-.056a11.544 11.544 0 006.29 1.84c7.545 0 11.67-6.25 11.67-11.668 0-.18-.006-.356-.015-.53A8.18 8.18 0 0022 5.925z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgTwitter24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgTwitter24;
