import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import Box from '../../shared/atoms/Box';
import Text from '../../shared/atoms/Text';
// width: 90px;

const StyledInput = styled('input')`
  ${(p) => {
    if (p.customInputWidth) {
      return css`
        width: ${p.customInputWidth}px;
        min-width: 0;
        @media (max-width: ${p.theme.breakpoints.sm}) {
          max-width: 60px;
        }
      `;
    }
    return css`
      flex: 1;
      min-width: 0;
    `;
  }}
  color:white;
  &,
  input,
  textarea {
    box-sizing: border-box;
    padding-left: 0px;
    color: ${(p) => (p.value ? 'white' : 'black')}!important;
    background-color: transparent !important;
    border: none;
    outline: none !important;
    font-size: ${(p) => p.theme.fontSizes.md} !important;
    font-weight: ${(p) => p.theme.fontWeights[0]};
    resize: none;
    -moz-appearance: textfield !important;
    }
    ::-webkit-inner-spin-button, 
    ::-webkit-outer-spin-button { 
        -webkit-appearance: none !important; 
        margin: 0; 
    }
    input[type="number"] {
        -moz-appearance: textfield !important;
    }
    ${(p) =>
    p.error &&
    css`
        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus,
        :-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px ${p.theme.colors.primary.pale} inset !important;
        }
        color: ${p.theme.colors.gray.darker};
        :focus {
          color: ${p.theme.colors.gray.darker};
        }
        ::placeholder {
          color: ${p.theme.colors.gray.darker};
        }
      `}
    ${(p) =>
    p.disabled &&
    css`
        ::placeholder {
          color: ${p.theme.colors.gray.main};
        }
        color: ${p.theme.colors.gray.dark};
      `}
  }
`;

const StyledBox = styled(Box)`
  cursor: pointer;
  :hover {
    box-shadow: inset 0px 0px 0px 1px ${(p) => p.theme.colors.blue.electric};
  }
  ${(p) => {
    if (p.isWrapping)
      return css`
        @media (max-width: 464px) {
          margin-top: 8px;
        }
      `;
    if (p.isLastBoxBeforeInput)
      return css`
        @media (max-width: 371px) {
          margin-top: 8px;
        }
      `;
  }}
`;

const GiftCardAmount = ({
  giftcard,
  setGiftcard,
  customInputWidth = false,
  paddingX = false,
  paddingY = false,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Box
        flexDirection="row"
        flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
        justifyContent={{ xs: 'start', sm: 'center' }}
        alignSelf="center"
        mt={4}
        width="85%"
        {...props}>
        <StyledBox
          borderColor="gray.light"
          bg={giftcard.amount === 20 ? 'blue.electric' : 'white'}
          borderWidth="1px"
          borderRadius="xs"
          borderStyle="solid"
          py={paddingY || 2}
          px={paddingX || 3}
          mr={2}
          justifyContent="center"
          onClick={() =>
            setGiftcard({
              ...giftcard,
              amount: 20,
              customAmount: '',
              errorAmount: false,
            })
          }>
          <Text
            color={giftcard.amount === 20 ? 'white' : 'black'}
            fontWeight={1}>
            {t('20€')}
          </Text>
        </StyledBox>
        <StyledBox
          borderColor="gray.light"
          bg={giftcard.amount === 50 ? 'blue.electric' : 'white'}
          borderWidth="1px"
          borderRadius="xs"
          borderStyle="solid"
          py={paddingY || 2}
          px={paddingX || 3}
          mr={2}
          justifyContent="center"
          onClick={() =>
            setGiftcard({
              ...giftcard,
              amount: 50,
              customAmount: '',
              errorAmount: false,
            })
          }>
          <Text
            color={giftcard.amount === 50 ? 'white' : 'black'}
            fontWeight={1}>
            {t('50€')}
          </Text>
        </StyledBox>
        <StyledBox
          borderColor="gray.light"
          bg={giftcard.amount === 100 ? 'blue.electric' : 'white'}
          borderWidth="1px"
          borderRadius="xs"
          borderStyle="solid"
          py={paddingY || 2}
          px={paddingX || 3}
          mr={2}
          justifyContent="center"
          onClick={() =>
            setGiftcard({
              ...giftcard,
              amount: 100,
              customAmount: '',
              errorAmount: false,
            })
          }>
          <Text
            color={giftcard.amount === 100 ? 'white' : 'black'}
            fontWeight={1}>
            {t('100€')}
          </Text>
        </StyledBox>
        <StyledBox
          borderColor="gray.light"
          bg={giftcard.amount === 150 ? 'blue.electric' : 'white'}
          borderWidth="1px"
          borderRadius="xs"
          borderStyle="solid"
          py={paddingY || 2}
          px={paddingX || 3}
          mr={2}
          justifyContent="center"
          isLastBoxBeforeInput={true}
          onClick={() =>
            setGiftcard({ ...giftcard, amount: 150, customAmount: '' })
          }>
          <Text
            color={giftcard.amount === 150 ? 'white' : 'black'}
            fontWeight={1}>
            {t('150€')}
          </Text>
        </StyledBox>
        <StyledBox
          borderColor="gray.light"
          bg={isFocused || giftcard.customAmount ? 'blue.electric' : 'white'}
          borderWidth="1px"
          borderRadius="xs"
          borderStyle="solid"
          flexDirection="row"
          alignItems="center"
          py={paddingY || 2}
          px={paddingX || 3}
          flex={customInputWidth ? null : 1}
          isWrapping={true}
          minWidth={0}>
          <StyledInput
            customInputWidth={customInputWidth}
            placeholder={isFocused ? '' : 'Autre'}
            isFocused={isFocused}
            value={giftcard.customAmount}
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              setGiftcard({
                ...giftcard,
                customAmount: e.target.value,
                amount: 0,
                errorAmount: false,
              });
            }}
            onFocus={() => {
              setIsFocused(true);
              setGiftcard({
                ...giftcard,
                amount: 0,
              });
            }}
            onBlur={() => setIsFocused(false)}
          />
          <Text
            mr={2}
            color={isFocused || giftcard.customAmount ? 'white' : 'black'}>
            {t('€')}
          </Text>
        </StyledBox>
      </Box>
      {!!giftcard.errorAmount && (
        <Text
          textAlign="center"
          mt={2}
          variant="caption"
          fontWeight="bold"
          color="primary.darkest">
          {giftcard.errorAmount}
        </Text>
      )}
    </>
  );
};

export default GiftCardAmount;
