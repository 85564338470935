import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import i18n from '../../../../i18n';
import * as api from '../../../../utils/api';
import Modal from '../../../../modals/Modal';
import Text from '../../../shared/atoms/Text';
import Input from '../../../shared/atoms/Input';
import Button from '../../../shared/atoms/Button';
import SurprisedFaceColor56 from '../../../icons/SurprisedFaceColor56';
import Dropdown from '../../../Dropdown';

const savCategories = [
  {
    value: 0,
    type: 'MECA',
    label: i18n.t('problemmodal.categories.placeholder'),
  },
  {
    value: 1,
    type: 'MECA',
    label: i18n.t('problemmodal.categories.1'),
  },
  {
    value: 2,
    type: 'CONTACT',
    label: i18n.t('problemmodal.categories.2'),
  },
  {
    value: 3,
    type: 'INVOICE',
    label: i18n.t('problemmodal.categories.3'),
  },
  {
    value: 4,
    type: 'OTHER',
    label: i18n.t('problemmodal.categories.4'),
  },
];

const min = 10;

const validationSchema = Yup.object().shape({
  type: Yup.string().required(),
  course_id: Yup.number().required(),
  description: Yup.string()
    .min(
      min,
      i18n.t(`problemmodal.errors.1`, {
        label: min,
      }),
    )
    .required(),
});
const ProblemModal = ({
  course,
  setShowAlertProblem,
  showModal,
  openModal,
  ...props
}) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [category, setCategory] = useState(savCategories[0]);
  const course_id = course.id;

  const handleChange = (e) => setDescription(e.target.value);

  const handleCategoryChange = (value) => {
    setCategory(savCategories.find((e) => e.value === value));
  };

  const handleSubmit = () => {
    setErrorMessage('');
    const { type } = category;
    validationSchema
      .validate({ type, description, course_id })
      .then(async (formData) => {
        try {
          await api.post('/support', formData);
          setShowAlertProblem('success');
          openModal(false);
        } catch (err) {
          setShowAlertProblem('failure');
          openModal(false);
        }
      })
      .catch((err) => setErrorMessage(err.message));
  };
  return (
    <Modal
      zIndex="1002"
      hasCloseAction={true}
      open={showModal === 'PROBLEM_COURSE'}
      onRequestClose={() => {
        openModal(false);
        setDescription('');
        setCategory(savCategories[0]);
        setErrorMessage('');
      }}>
      <SurprisedFaceColor56 mb={2} />
      <Text fontWeight={2} mb={4}>
        {t('problemmodal.title')}
      </Text>
      <Text mb={4}>{t('problemmodal.subtitle')}</Text>
      <Dropdown
        onChange={handleCategoryChange}
        options={savCategories}
        value={category.label}
      />
      <Input
        as="textarea"
        onChange={handleChange}
        value={description}
        error={!!errorMessage}
        placeholder={t('problemmodal.texts.1')}
        rows="3"
      />
      {errorMessage ? (
        <Text color="primary.darkest" mt={1}>
          {errorMessage}
        </Text>
      ) : (
        <Text color="gray.dark" mt={1}>
          {t('problemmodal.errors.2')}
        </Text>
      )}
      <Button
        fullWidth
        size="compact"
        color="blue.electric"
        mt={5}
        disabled={category.value === 0}
        onClick={() => handleSubmit()}>
        {t('send')}
      </Button>
    </Modal>
  );
};

export default ProblemModal;
