import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
// Components
import Row from '../../components/shared/atoms/Row';
import Box from '../../components/shared/atoms/Box';
import Separator from '../../components/Separator';
import HelpCard from '../../components/shared/molecules/HelpCard';
import Container from '../../components/shared/atoms/Container';
import Text from '../../components/shared/atoms/Text';
import Heading from '../../components/shared/atoms/Heading';
import AccountNav from '../../components/customerArea/shared/AccountNav';
import DisplayCards from '../../components/customerArea/Cartes/DisplayCards';
// Icons
import AddCards from '../../components/customerArea/Cartes/AddCards';

const PaymentMethods = () => {
  const { t } = useTranslation();
  const { client } = useSelector((s) => s);
  const [newCard, setNewCard] = useState(false);
  const [hasDeleteCard, setHasDeleteCard] = useState(false);

  return (
    <Container>
      <Container fluid pb={10} pt={4}>
        <Helmet title={t('paymentmethods.meta.title')} />
        <Row md gap={56}>
          <AccountNav />
          <Box>
            <Heading level={2} mb={6}>
              {t('paymentmethods.headings.1')}
            </Heading>
            <Heading level={5} mb={4}>
              {t('paymentmethods.headings.2')}
            </Heading>
            <DisplayCards
              client={client}
              newCard={newCard}
              hasDeleteCard={hasDeleteCard}
              setHasDeleteCard={setHasDeleteCard}
            />
            <Separator axe="horizontal" bg="gray.light" margin="24px 0px" />
            <AddCards
              setNewCard={setNewCard}
              setHasDeleteCard={setHasDeleteCard}
            />
            <HelpCard title={t('paymentmethods.help.title')} p={5}>
              <Text color="gray.darkest">
                <Trans i18nKey="paymentmethods.help.text" />
              </Text>
            </HelpCard>
          </Box>
        </Row>
      </Container>
    </Container>
  );
};

export default PaymentMethods;
