import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.234 13.188l-1.82-7.681-8.123-4.69a3 3 0 00-4.098 1.098l-2.742 4.75a3 3 0 001.098 4.097l8.123 4.69 7.562-2.264zM29 13a2 2 0 100-4 2 2 0 000 4z"
      fill="#8A38F3"
    />
    <path d="M13 44a9 9 0 100-18 9 9 0 000 18z" fill="#8A38F3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.92 10.606a1 1 0 10-1.84.788L32.199 21h-10.77L20.4 18H24a1 1 0 100-2h-8a1 1 0 100 2h2.286l1.255 3.662L11.269 36h14.169L36.98 23.495l1.576 3.677a9 9 0 101.838-.789L38 20.795V18h3.5a.618.618 0 110 1.237 1 1 0 000 2 2.618 2.618 0 100-5.237H35a1 1 0 100 2h1v3h-1.626l-4.455-10.394zM34.715 23H22.114l3.413 9.955L34.716 23zm-14.314 1.172L23.772 34h-9.04l5.67-9.828zm20.787 4.065a7 7 0 11-1.838.789l2.73 6.368a1 1 0 101.838-.788l-2.73-6.37z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgBrandColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgBrandColor56;
