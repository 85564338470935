import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M16 22a5 5 0 100-10 5 5 0 000 10z" fill="#28D898" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.328 29.946C27.432 26.174 28 16.776 28 6.942c0 0-8.608-1.603-12.015-4.942C12.485 5.43 4 6.942 4 6.942c0 9.833.567 19.232 11.67 23.004a1.02 1.02 0 00.658 0zM6.008 8.56c.869-.204 2.02-.5 3.274-.896 2.07-.652 4.669-1.648 6.708-3.086 2.017 1.405 4.61 2.396 6.675 3.051 1.275.405 2.447.711 3.328.921-.043 4.31-.304 8.2-1.536 11.46-1.292 3.42-3.685 6.228-8.458 7.932-4.772-1.704-7.165-4.512-8.457-7.932-1.23-3.258-1.492-7.144-1.535-11.45z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.46 10.73a1 1 0 01.428 1.348L18.05 17.55c-.213.41-.4.773-.58 1.057-.191.3-.428.605-.778.828a2.5 2.5 0 01-1.68.367c-.41-.056-.753-.235-1.051-.428-.283-.183-.605-.434-.969-.717l-1.607-1.252a1 1 0 011.23-1.577l1.575 1.226c.405.316.658.512.858.642a.89.89 0 00.235.124.5.5 0 00.336-.073.89.89 0 00.161-.211c.128-.202.276-.486.513-.942l2.82-5.438a1 1 0 011.347-.427z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgShield32Color = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgShield32Color;
