import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.256 19.376l-2.059.552a1 1 0 01-.518-1.932l2.725-.73.049-.013 4.558-1.221a1 1 0 11.517 1.932l-3.23.865 1.62 2.407 13.267-3.555-.71-2.65-.867.232a1 1 0 01-.517-1.932l5.956-1.596a2.535 2.535 0 111.313 4.898 1 1 0 01-.518-1.932.535.535 0 10-.277-1.034l-3.158.846.67 2.501 3.564 4.53a8.539 8.539 0 11-1.571 1.237l-2.301-2.925L28.287 34.1l-13.092 3.508 4.077-15.234-2.016-2.998zm4.841 3.614l11.366-3.045-5.882 11.198-5.484-8.153zm4.054 9.612l-5.398-8.026-2.73 10.204 8.128-2.178zm16.257-10.09a6.55 6.55 0 00-1.518.618l3.996 5.08a1 1 0 01-1.571 1.236l-3.997-5.079a6.538 6.538 0 103.09-1.855z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.412 19.191a1 1 0 011.397.22l4 5.5a1 1 0 11-1.618 1.177l-4-5.5a1 1 0 01.22-1.397z"
      fill="#8A38F3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.737 48.465a1 1 0 001.228-.702l1.5-5.5a1 1 0 00-1.93-.526l-1.5 5.5a1 1 0 00.702 1.228z"
      fill="#FFE249"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.44 29.295l4.558 3.691a1 1 0 11-1.259 1.554l-2.265-1.835-1.65 5.3-3.472-2.954a1 1 0 111.296-1.523l1.167.992 1.625-5.225z"
      fill="#28D898"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = true;
const SvgBikeproblem56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgBikeproblem56;
