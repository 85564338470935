import React from 'react';
import styled from 'styled-components';

import Radio from '../atoms/Radio';
import Box from '../atoms/Box';

const StyledBox = styled(Box)`
  cursor: pointer;
  box-sizing: border-box;
  box-shadow: ${(p) =>
    p.$selected
      ? `inset 0px 0px 0px 2px ${p.theme.colors.blue.electric}`
      : `inset 0px 0px 0px 1px ${p.theme.colors.gray.main}`};
  &:hover {
    box-shadow: ${(p) =>
    p.$selected
      ? `inset 0px 0px 0px 2px ${p.theme.colors.blue.electric}`
      : `inset 0px 0px 0px 1px ${p.theme.colors.blue.electric}`};
  }
`;

const Select = ({ selected, withRadio = true, children, ...rest }) => (
  <StyledBox
    flexDirection="row"
    justifyContent="space-between"
    alignItems="center"
    borderRadius="xs"
    $selected={selected}
    width="100%"
    p={4}
    my={2}
    {...rest}>
    {children}
    {withRadio && (
      <div>
        <Radio checked={selected} onChange={() => { }} />
      </div>
    )}
  </StyledBox>
);

export default Select;
