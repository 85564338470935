const zonesSection = (t) => {
  return [
    { slug: 'paris', full: 'Paris' },
    { slug: 'bordeaux', full: 'Bordeaux' },
    { slug: 'strasbourg', full: 'Strasbourg' },
    { slug: 'lyon', full: 'Lyon' },
    { slug: 'toulouse', full: 'Toulouse' },
    { slug: 'lille', full: 'Lille' },
    { slug: 'nantes', full: 'Nantes' },
    { slug: '', full: t('seeallcities') },
  ];
};

export default zonesSection;
