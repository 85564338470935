import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import Box from '../shared/atoms/Box';
import Text from '../shared/atoms/Text';
// Icons
import ArrowUpOrnament from '../icons/ArrowUpOrnament';

const StyledIcon = styled(ArrowUpOrnament)`
  margin-right: -350px;
  margin-bottom: -17px;
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    display: none;
  }
`;

const VideoPresentation = ({ link, subtitle }) => {
  const { t } = useTranslation();
  return (
    <Box style={{ width: '100%' }}>
      <div
        style={{
          borderRadius: '16px',
          overflow: 'hidden',
          width: '100%',
          display: 'flex',
          height: '100%',
          position: 'relative',
          paddingBottom: '56.25%',
        }}>
        <iframe
          width="100%"
          height="550"
          frameBorder="0"
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
          }}
          src={`${link}?autoplay=1&border=0&modestbranding=1&loop=1&mute=1`}
        />
      </div>
      {subtitle && (
        <Box mt={{ xs: 4, lg: 5 }} alignItems="center">
          <StyledIcon />
          <Text variant="notice2" textAlign="center" mt={4} maxWidth="35%">
            {subtitle}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default VideoPresentation;
