import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setBookingUUID } from '../actions/course.jsx'; // Adjust the path as necessary

const TallyForm = () => {
    const dispatch = useDispatch();
    const iframeRef = useRef(null);

    // Retrieve the position from localStorage
    const stateData = localStorage.getItem('state');
    const appState = stateData ? JSON.parse(stateData) : {};
    const position = appState?.course?.position || '';
    console.log('position:', position);

    // Generate a UUID
    const generateUUID = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };

    // Use state to store the UUID
    const [bookingUUID] = useState(generateUUID);

    useEffect(() => {
        // Dispatch the bookingUUID to the Redux store only once
        dispatch(setBookingUUID(bookingUUID));

        // Dynamically load the Tally script
        const script = document.createElement('script');
        script.src = "https://tally.so/widgets/embed.js";
        script.async = true;
        script.onload = () => {
            if (typeof Tally !== 'undefined') {
                Tally.loadEmbeds();
            } else {
                console.error('Tally is not defined. Ensure the script is loaded correctly.');
            }
        };
        document.body.appendChild(script);

        // Cleanup script on component unmount
        return () => {
            document.body.removeChild(script);
        };
    }, [dispatch, bookingUUID]);

    useEffect(() => {
        const handlePostMessage = (event) => {
            if (event.origin !== "https://feedback.roulezjeunesse.com") return; // Ensure the message is from the expected origin
            if (event.data && event.data.height && iframeRef.current) {
                iframeRef.current.style.height = `${event.data.height}px`;
            }
        };

        window.addEventListener("message", handlePostMessage);

        return () => {
            window.removeEventListener("message", handlePostMessage);
        };
    }, []);

    console.log('booking-uuid:', bookingUUID);

    return (
        <div style={{ width: '100%', overflow: 'hidden' }}>
            <iframe
                ref={iframeRef}
                data-tally-src={`https://feedback.roulezjeunesse.com/diagnostic?alignLeft=1&embed=1&hideTitle=1&transparentBackground=1&dynamicHeight=1&position=${position}&booking-uuid=${bookingUUID}`}
                width="100%"
                height="100%"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                style={{ display: 'block', overflow: 'hidden' }}
            ></iframe>
        </div>
    );
};

export default TallyForm;
