import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M8 21.5a6 6 0 100-12 6 6 0 000 12z" fill="#28D898" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.13 7.75H18.5a.75.75 0 010-1.5H23a.75.75 0 010 1.5h-1.275l.873 2.415a6 6 0 013.996 11.247l.946 2.838H29a.75.75 0 010 1.5h-4.5a.75.75 0 010-1.5h1.46l-.79-2.364a6.034 6.034 0 01-2.16.033.753.753 0 01-.197-.03l-.07-.02a5.962 5.962 0 01-.835-.244c-3.284-.952-7.343-2.095-10.69-3.03a2261.14 2261.14 0 00-5.972-1.66l-.084-.023a4.001 4.001 0 011.232-7.893l14.556.999-.82-2.268zM8.5 13a2.5 2.5 0 01-2.99 2.452l-.349-.096a2.501 2.501 0 01.99-4.851v.001l.112.008A2.5 2.5 0 018.5 13zm.788-2.279c.449.647.712 1.432.712 2.279 0 1.332-.65 2.511-1.651 3.238l3.272.912c2.262.632 4.852 1.36 7.309 2.06A5.972 5.972 0 0118 16c0-1.813.804-3.438 2.075-4.539l-10.787-.74zM24 20.5c.235 0 .465-.018.69-.053l-1.404-4.21a.75.75 0 111.423-.474l1.405 4.21a4.5 4.5 0 10-3.727.229l.716.209c.29.058.59.089.897.089z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgTransmissioncolor32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgTransmissioncolor32;
