import React, { useState } from 'react';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import Dropdown from '../components/Dropdown';
import Box from '../components/shared/atoms/Box';
import { getDomain } from '../utils/utils';

const LANGUES = [
  {
    value: 'fr',
    label: '🇫🇷 France',
  },
  {
    value: 'uk',
    label: '🇬🇧 England',
  },
];

const ChangeCountry = ({ ...props }) => {
  const { domain } = getDomain();
  let index;
  if (domain === 'fr') {
    index = 0;
  } else if (domain === 'uk') {
    index = 1;
  }
  const [langue, setLangue] = useState(LANGUES[index]);
  const handleLanguageChange = (value) => {
    if (value === 'fr') {
      window.location.href = 'https://www.roulezjeunesse.fr';
    } else if (value === 'uk') {
      window.location.href = 'https://www.roulezjeunesse.co.uk';
    }
  };
  return <div />;
  return (
    <Box {...props} width="55%" alignSelf="flex-end">
      <Dropdown
        onChange={handleLanguageChange}
        options={LANGUES}
        value={langue.label}
      />
    </Box>
  );
};

export default ChangeCountry;
