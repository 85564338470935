import React from 'react';
import styled from 'styled-components';
import Box from '../shared/atoms/Box';
import Text from '../shared/atoms/Text';
import Heading from '../shared/atoms/Heading';
import Button from '../shared/atoms/Button';
import ToolKitFix from '../shared/ToolkitFix';
import SvgAngleright16 from '../icons/AngleRight16';

const ToolkitSPRING = styled(ToolKitFix)`
  position: absolute;
  z-index: 1;
  width: 320px;
  height: 370px;
  right: -40px;
  top: 30px;
  transform: rotate(15deg);
`;

const PromoMiddlePicture = () => {
  return (
    <Box
      flexDirection={{ xs: 'column', lg: 'row' }}
      bg="green.main"
      borderRadius="lg"
      position="relative"
      overflow="hidden"
      zIndex={0}
      mb={10}
      mt={{ xs: 0, lg: '-60px' }}>
      <ToolkitSPRING name="hexa" color="green.lighter" />
      <Box
        zIndex={2}
        maxWidth={{ xs: null, md: '450px', lg: '350px' }}
        alignSelf="center"
        mt={{ xs: 4, lg: 0 }}
        ml={{ xs: 4, lg: 7, xl: 10 }}>
        <Heading level={3} color="white">
          À Noël, faites plaisir à votre vélo !
        </Heading>
        <Text color="white" fontSize="lg">
          Du 16 au 24 décembre, 10€ offerts sur toutes les prestations avec le
          code <strong>NOELSANSROULETTES</strong>
        </Text>
      </Box>
      <Box zIndex={2} alignSelf="center">
        <img
          src="/static/img/photos/Home/banner-img-selle.png"
          style={{ width: '100%', height: 'auto', maxWidth: 450 }}
        />
      </Box>
      <Box
        zIndex={2}
        alignSelf="center"
        ml={{ xs: 0, xl: 8 }}
        mr={{ xs: 0, lg: 7, xl: 10 }}
        mt={{ mt: 5, lg: 0 }}
        mb={{ xs: 8, lg: 0 }}>
        <Button
          variant="filledWhite"
          after={<SvgAngleright16 />}
          to={'/reparation?cp=NOELSANSROULETTES'}>
          Prendre rendez-vous
        </Button>
      </Box>
    </Box>
  );
};

export default PromoMiddlePicture;
