import React from 'react';
import styled, { css } from 'styled-components';
import {
  space,
  layout,
  color as colorSystem,
  flexbox,
  position,
} from 'styled-system';
import Text from './Text';
import Box from './Box';
import Link from './Link';

const StyledBadge = styled(Box)`
  white-space: nowrap;
  display: inline-block;
  border-radius: ${(p) => p.theme.radii.xxs};
  margin-right: 8px;
  cursor: default;
  line-height: 0;
  vertical-align: middle;
  .icon-left {
    position: relative;
    margin-right: 4px;
    margin-top: -2px;
  }
  .icon-right {
    position: relative;
    margin-left: 4px;
    margin-top: -2px;
  }
  line-height: 0;
  ${(p) =>
    p.$clickable &&
    css`
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    `};
  ${(p) =>
    p.light
      ? css`
          color: ${p.theme.colors[p.$color].main};
          background-color: ${p.theme.colors[p.$color].pale};
        `
      : css`
          color: ${p.theme.colors.white};
          background-color: ${p.$subColor
            ? p.theme.colors[p.$color][p.$subColor]
            : p.theme.colors[p.$color].main};
        `}
  ${space};
  ${layout};
  ${colorSystem};
  ${flexbox};
  ${position};
`;

const getColor = (light, color) => {
  if (light) {
    return `${color}.dark`;
  }
  return color === 'yellow' || color === 'gray' ? 'black' : 'white';
};

/**
 * @param {string} color Any Theme color or HEX value
 * @param {boolean} light true or false
 * @param {string} size large | regular | small
 * @param {string} before Named SVG Icon left postion
 * @param {string} after Named SVG Icon right postion
 * @param {string} styledText object with style for text
 * @param {string} any extend Styled System space props
 */

const sizes = {
  large: 'small',
  regular: 'caption',
  small: 'caption2',
};

const pad = {
  large: { x: '6px', y: '4px' },
  regular: { x: '6px', y: '4px' },
  small: { x: '4px', y: '2px' },
};

export default function Badge({
  size = 'regular',
  color = 'primary',
  subColor = false,
  light = false,
  before,
  after,
  to,
  onClick,
  children,
  styledText,
  as,
  ...props
}) {
  return (
    <StyledBadge
      as="div"
      display="inline-block"
      $color={color}
      $subColor={subColor}
      $clickable={!!to || !!onClick}
      light={light}
      $size={size}
      py={pad[size].y}
      px={pad[size].x}
      onClick={onClick}
      {...props}>
      {before &&
        React.cloneElement(before, {
          color: getColor(light, color),
          $pointer: !!to,
          className: 'icon-left',
        })}
      {to ? (
        <Link to={to} color={getColor(light, color)}>
          {children}
        </Link>
      ) : (
        <Text
          as={as || 'span'}
          variant={sizes[size]}
          fontWeight="600"
          color={getColor(light, color)}
          style={{ fontVariantNumeric: 'tabular-nums' }}
          {...styledText}>
          {children}
        </Text>
      )}
      {after &&
        React.cloneElement(after, {
          color: getColor(light, color),
          $pointer: !!to,
          className: 'icon-right',
        })}
    </StyledBadge>
  );
}
