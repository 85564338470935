import React from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
// Hook
import useDeviceWidth from '../../../hooks/useDeviceWidth';
// Components
import SectionBackground from '../../shared/molecules/SectionBackground';
import Box from '../../shared/atoms/Box';
import Text from '../../shared/atoms/Text';
// Icons
import IllustrationMessageBubble from '../../icons/doNotDelete/IllustrationMessageBubble';

const StyledSpan = styled.span`
  color: ${(p) => p.theme.colors.primary.main};
  font-family: LTSaeada;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  font-style: italic;
  ${(p) => p.theme.mediaQueries.desktop} {
    font-size: 28px;
    line-height: 36px;
  }
`;

const StyledImgBackMarket = styled.img`
  max-width: 130px;
  width: 100%;
  margin-bottom: 24px;
`;

const StyledImg = styled.img`
  max-height: 300px;
`;

const StyledIllustration = styled(IllustrationMessageBubble)`
  margin-top: -181px;
  margin-left: -20px;
`;

const StyledSectionBackground = styled(SectionBackground)`
  padding: 80px;
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    padding: 40px 24px 40px 48px;
  }
`;

const StyleImgMobile = styled.img`
  max-width: 60px;
  width: 60px;
`;

const NameOfCustomerReviews = ({ ...rest }) => {
  const { t } = useTranslation();
  return (
    <Box {...rest}>
      <Text variant="large" fontWeight="bold" color="black">
        Caroline Soyer
      </Text>
      <Text variant="medium">{t('b2b.customerreview.customerjob')}</Text>
    </Box>
  );
};

const CustomerReviews = () => {
  const { isMobile, isTablet } = useDeviceWidth();
  const { t } = useTranslation();
  return (
    <StyledSectionBackground bg="yellow.lighter" mb={{ xs: 9, lg: 11 }}>
      <Box flexDirection={{ xs: 'column-', lg: 'row' }}>
        {!isMobile && !isTablet && (
          <Box width="40%" justifyContent="center" alignItems="center">
            <StyledImg
              src="/static/img/photos/business/caroline-soyer-desktop.png"
              alt={t('customerreview')}
            />
          </Box>
        )}
        <Box
          width={{ xs: '100%', lg: '60%' }}
          justifyContent="center"
          alignItems="flex-start"
          ml={{ xs: 0, lg: 10 }}>
          <StyledImgBackMarket
            src="/static/img/logos/logo-backmarket2.png"
            alt="BackMarket"
          />
          <Text variant="notice" mb={6}>
            <Trans
              i18nKey="b2b.customerreview.review"
              components={{ styledspan: <StyledSpan /> }}
            />
          </Text>
          <StyledIllustration />
          {isMobile || isTablet ? (
            <Box
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              mt={2}>
              <StyleImgMobile
                src="/static/img/photos/business/caroline-soyer-mobile.png"
                alt={t('customerreview')}
              />
              <NameOfCustomerReviews ml={2} />
            </Box>
          ) : (
            <NameOfCustomerReviews />
          )}
        </Box>
      </Box>
    </StyledSectionBackground>
  );
};

export default CustomerReviews;
