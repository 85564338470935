import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.072 5.01L2.054 10.79c-.382 2.177 1.327 4.16 3.581 4.16h2.473s-.371.726-.566 2c-.069.452-.116.973-.116 1.555 0 2.667 2.835 4.339 4.773 3.056 1.17-.775 1.378-1.667 1.34-3.334-.03-1.273 1.222-1.938 2.414-2.572.273-.145.544-.29.795-.438.166-.099.355-.156.549-.156H19a3 3 0 003-3v-5a3 3 0 00-3-3h-1.784c-.142 0-.283-.031-.413-.088-3.268-1.44-6.595-1.912-10.15-1.912-1.772 0-3.281 1.244-3.581 2.95zm1.97.348c.126-.72.778-1.298 1.612-1.298 3.37 0 6.403.448 9.343 1.742l.003.001v7.548a3.18 3.18 0 00-.273.145c-.207.123-.419.235-.677.372-.1.054-.207.11-.324.174-.378.203-.835.457-1.265.765-.846.605-1.963 1.698-1.922 3.465.018.795-.039 1.114-.095 1.268l-.002.005c-.02.054-.056.154-.347.347-.196.13-.533.183-.954-.078a1.523 1.523 0 01-.715-1.31c0-.906.14-1.613.268-2.074a4.88 4.88 0 01.204-.589l1.48-2.893H5.634c-1.063 0-1.77-.91-1.612-1.813l1.018-5.777zM18 13.06h1a1 1 0 001-1v-5a1 1 0 00-1-1h-1v7z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgThumbup241 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgThumbup241;
