import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M7.5 26a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM30.095 4.086L31.46.819c.269-.642-.626-1.13-1.058-.58L27 4.581l1.905 1.333-1.365 3.267c-.269.642.626 1.131 1.058.58L32 5.419l-1.905-1.333z"
      fill="#8A38F3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.382 7.9H8a.9.9 0 010-1.8h4a.9.9 0 010 1.8h-.69l1.231 3.2h7.622V8c0-.034.002-.067.005-.1H20a.9.9 0 110-1.8h4.106a1.844 1.844 0 110 3.688.9.9 0 110-1.8.044.044 0 100-.088h-2.149a.893.893 0 01.006.1v2.795l1.546 4.294a5.5 5.5 0 11-1.694.61l-.779-2.163-5.063 7.364h-.952a.97.97 0 01-.042 0H6.044l4.713-9.426L9.382 7.9zm2.25 5.85L8.956 19.1h4.734l-2.058-5.35zm3.774 4.798l3.883-5.648h-6.056l2.173 5.648zm8.247 2.257l-1.215-3.377a3.7 3.7 0 101.694-.61l1.215 3.377a.9.9 0 11-1.694.61z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgEBike32Color = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgEBike32Color;
