import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M7.776 18.677a5 5 0 015-5h33.63a8 8 0 018 8v17.971a9 9 0 01-9 9h-29.63a8 8 0 01-8-8V18.676z"
      fill="#FFBBB3"
    />
    <path
      d="M12 27.5a1 1 0 10-2 0V29a1 1 0 102 0v-1.5zM29 26.5a1 1 0 011 1V29a1 1 0 11-2 0v-1.5a1 1 0 011-1zM16 27.5a1 1 0 10-2 0V29a1 1 0 102 0v-1.5zM33 26.5a1 1 0 011 1V29a1 1 0 11-2 0v-1.5a1 1 0 011-1zM20 27.5a1 1 0 10-2 0V29a1 1 0 102 0v-1.5zM37 26.5a1 1 0 011 1V29a1 1 0 11-2 0v-1.5a1 1 0 011-1zM24 27.5a1 1 0 10-2 0V29a1 1 0 102 0v-1.5zM41 26.5a1 1 0 011 1V29a1 1 0 11-2 0v-1.5a1 1 0 011-1zM11 38a1 1 0 100 2h7a1 1 0 100-2h-7zM10 34a1 1 0 011-1h22a1 1 0 110 2H11a1 1 0 01-1-1zM22 38a1 1 0 100 2h4a1 1 0 100-2h-4z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 18a8 8 0 018-8h32a8 8 0 018 8v20a8 8 0 01-8 8H12a8 8 0 01-8-8V18zm8-6h32a6 6 0 016 6v20a6 6 0 01-6 6H12a6 6 0 01-6-6V18a6 6 0 016-6z"
      fill="#000"
    />
    <path
      d="M10 19a3 3 0 013-3h4a3 3 0 013 3v2a3 3 0 01-3 3h-4a3 3 0 01-3-3v-2z"
      fill="#FFE249"
    />
    <path
      d="M48 35a3 3 0 10-6 0v5h-3a3 3 0 100 6h3v5a3 3 0 106 0v-5h3a3 3 0 100-6h-3v-5z"
      fill="#1F77F9"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgCreditcardColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgCreditcardColor56;
