import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Box from '../../../shared/atoms/Box';
import Heading from '../../../shared/atoms/Heading';
import Text from '../../../shared/atoms/Text';
import Button from '../../../shared/atoms/Button';
import Separator from '../../../Separator';

const StyledImg = styled.img`
  width: 56px;
  height: 56px;
  margin-right: 16px;
`;
const Services = () => {
  const { t } = useTranslation();
  return (
    <Box
      borderStyle="solid"
      borderWidth="1px"
      borderColor="gray.light"
      borderRadius="sm"
      width={{ xs: '100%', lg: '80%' }}
      alignSelf="center"
      p={4}>
      <Box flexDirection="row">
        <StyledImg src="/static/img/pictos/carnetSante/paravol_logo.png" />
        <Box>
          <Heading level={5}>{t('bike.services.titles.1')}</Heading>
          <Text>{t('bike.services.texts.1')}</Text>
          <Box flexDirection="row" mt={3}>
            <Button
              size="compact"
              color="blue.electric"
              mr={2}
              href="/reparation">
              {t('bike.services.cta.1')}
            </Button>
            <Button
              size="compact"
              variant="neutral"
              href="https://reparations.roulezjeunesse.com/marquage-paravol"
              target="_blank">
              {t('findoutmore')}
            </Button>
          </Box>
        </Box>
      </Box>
      <Separator axe="horizontal" my={4} />
      <Box flexDirection="row">
        <StyledImg src="/static/img/pictos/carnetSante/cfx-certif-logo.png" />

        <Box>
          <Heading level={5}>{t('bike.services.titles.2')}</Heading>
          <Text>{t('bike.services.texts.2')}</Text>
          <Box flexDirection="row" mt={3}>
            <Button
              size="compact"
              color="blue.electric"
              mr={2}
              to="/reparation">
              {t('bike.services.cta.2')}
            </Button>
            <Button
              size="compact"
              variant="neutral"
              href="https://reparations.roulezjeunesse.com/certification-technique-roulezjeunesse"
              target="_blank">
              {t('findoutmore')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Services;
