import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M11 5.705a3.927 3.927 0 00-.38.311c-.356.325-.782.782-1.405 1.452L6.233 10.68a1 1 0 11-1.466-1.36L7.78 6.076c.586-.631 1.068-1.15 1.495-1.539.44-.401.891-.728 1.434-.914a4 4 0 012.584 0c.543.186.993.513 1.434.914.426.388.909.908 1.495 1.539l3.012 3.244a1 1 0 11-1.466 1.36l-2.982-3.212c-.623-.67-1.048-1.127-1.405-1.452a3.938 3.938 0 00-.38-.31V20a1 1 0 11-2 0V5.705z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgArrowUp24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgArrowUp24;
