import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx={28} cy={28} r={16} fill="#FFE249" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50 28c0 12.15-9.85 22-22 22S6 40.15 6 28 15.85 6 28 6s22 9.85 22 22zm2 0c0 13.255-10.745 24-24 24S4 41.255 4 28 14.745 4 28 4s24 10.745 24 24zm-29.737-4.376A9.559 9.559 0 0130.976 18c1.119 0 2.19.192 3.186.544a1 1 0 00.666-1.886A11.538 11.538 0 0030.976 16c-5.002 0-9.261 3.178-10.87 7.624H16.9a1 1 0 100 2h2.68a11.637 11.637 0 000 3.866H16.9a1 1 0 100 2h3.205c1.61 4.446 5.87 7.624 10.871 7.624 1.35 0 2.646-.231 3.852-.658a1 1 0 00-.666-1.886 9.538 9.538 0 01-3.186.544 9.559 9.559 0 01-8.713-5.624h6.367a1 1 0 100-2h-7.015a9.596 9.596 0 010-3.866h7.015a1 1 0 100-2h-6.367z"
      fill="#1D1D1D"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = true;
const SvgPrice56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPrice56;
