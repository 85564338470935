import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 252 154"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#illustration-bike-traveling_svg__clip0)">
      <path
        d="M235.5 25.723C235.5 36 231.627 35 225 35s-12-4.769-12-11.255C213 17.258 216.873 13 223.5 13s12 6.237 12 12.723zM68.661 47.661c0 5.06-3.606 8.561-8.666 8.561a9.161 9.161 0 01-9.161-9.16c0-5.06 3.606-8.562 8.666-8.562a9.161 9.161 0 019.161 9.161z"
        fill="#1F77F9"
      />
      <path
        d="M110.011 140.989c10.715 0 19.4-8.686 19.4-19.4 0-10.714-8.685-19.4-19.4-19.4-10.714 0-19.4 8.686-19.4 19.4 0 10.714 8.686 19.4 19.4 19.4z"
        fill="#FFE249"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M161 36a2 2 0 110-4h8.087a16.16 16.16 0 01-.087-1.72c0-7.732 6.039-14.14 13.675-14.805C185.001 6.574 193.137 0 202.8 0c7.533 0 14.074 3.957 17.752 9.92 9.605.137 17.448 7.94 17.448 17.649 0 2.595-.522 5.065-1.598 7.299L235.857 36H161zm25.265-18.231C187.652 9.959 194.527 4 202.8 4c6.461 0 12.02 3.594 14.875 8.938l.66 1.234 1.387-.197.012-.002.053-.007a8.419 8.419 0 01.476-.047l.022-.001h.009c7.539 0 13.706 6.09 13.706 13.651 0 1.596-.248 3.08-.729 4.431h-60.153a11.648 11.648 0 01-.118-1.72c0-5.931 4.922-10.861 10.984-10.861h1.988l.293-1.65zM42.282 28.278a16.65 16.65 0 0114.078 7.69c7.604.232 13.795 6.415 13.795 14.117 0 2.073-.42 4.056-1.291 5.854l-.547 1.128H30.942l-.598-.696 1.516-1.304c-1.516 1.304-1.517 1.303-1.517 1.303l-.001-.002-.002-.002-.007-.008-.018-.021-.023-.028-.035-.041a19.443 19.443 0 01-.834-1.11 23.393 23.393 0 01-1.815-3.094c-1.245-2.556-2.427-6.218-1.716-10.193 1.382-7.736 8.209-13.593 16.39-13.593zM29.829 42.574c1.042-5.83 6.212-10.296 12.453-10.296 4.871 0 9.056 2.692 11.205 6.69l.66 1.229L55.528 40l.008-.001.039-.005a6.721 6.721 0 01.342-.034h.001c5.644.002 10.237 4.532 10.237 10.124a9.81 9.81 0 01-.442 2.982H32.845l-.139-.196c-.424-.61-.981-1.49-1.502-2.559-1.058-2.171-1.876-4.931-1.375-7.738zm26.09-2.613h-.004.003z"
        fill="#000"
      />
      <path
        d="M24.52 139.391L36.986 150H67a2 2 0 110 4H35.514l-11.034-9.391L13.094 154H2a2 2 0 110-4h9.657l12.863-10.609z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.377 154h35.567c.205 0 .403-.031.589-.088C237.622 152.666 251.1 138.407 251.1 121c0-18.225-14.775-33-33-33-2.44 0-4.817.265-7.105.767l-5.054-16.493V55.871h7.603a3.594 3.594 0 010 7.188 2 2 0 000 4 7.594 7.594 0 000-15.188H197.6a2 2 0 100 4h4.341v14.704h-62.233L134.54 55.87h10.93a2 2 0 000-4h-22.495a2 2 0 100 4h7.325l5.773 16.424-11.18 19.574A32.855 32.855 0 00109.074 88L109 88H71.5a2 2 0 100 4h22.114c-10.27 5.593-17.239 16.483-17.239 29s6.97 23.407 17.239 29H79a2 2 0 100 4h30l.074-.001.192.001h.109c17.358 0 31.586-13.402 32.901-30.423H156.35l.553-.56c.023-.022.045-.044.067-.068l46.04-46.587 4.136 13.5C194.305 94.377 185.1 106.613 185.1 121c0 12.517 6.969 23.407 17.238 29h-17.961a2 2 0 100 4zM218.1 92c-2.032 0-4.015.209-5.929.606l8.697 28.385a2.001 2.001 0 01-3.825 1.172l-8.723-28.472C197.115 97.703 189.1 108.415 189.1 121c0 16.016 12.983 29 29 29 16.016 0 29-12.984 29-29s-12.984-29-29-29zm-61.759 25.895l-15.227-43.32h58.039l-42.812 43.32zm-13.996 1.682h10.347l-14.864-42.29-9.525 16.677c8.146 5.714 13.592 15.017 14.042 25.613zm-16.036-22.122c6.948 5.006 11.591 13.013 12.032 22.122h-24.667l12.635-22.122zm-3.401-2.11l-16.125 28.232h31.479C136.958 138.386 124.523 150 109.375 150c-16.016 0-29-12.984-29-29s12.984-29 29-29c4.888 0 9.493 1.21 13.533 3.345z"
        fill="#000"
      />
      <path
        d="M145 32a2 2 0 100 4h6a2 2 0 100-4h-6zM162.744 152a2 2 0 012-2h9.7a2 2 0 110 4h-9.7a2 2 0 01-2-2zM62.5 102.045a2 2 0 100 4h10.4a2 2 0 000-4H62.5z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="illustration-bike-traveling_svg__clip0">
        <path fill="#fff" d="M0 0h251.1v154H0z" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgIllustrationBikeTraveling = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgIllustrationBikeTraveling;
