import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import Heading from '../shared/atoms/Heading';
import Button from '../shared/atoms/Button';
import Box from '../shared/atoms/Box';
import Row from '../shared/atoms/Row';
// Icons
import AngleRight16 from '../icons/AngleRight16';
import SectionBackground from '../shared/molecules/SectionBackground';

const StyledImg = styled.img`
  max-width: 450px;
  width: 100%;
`;

const JoinTheNetwork = ({ ...rest }) => {
  const { t } = useTranslation();
  return (
    <SectionBackground
      mb={{ sm: 0, md: 8, lg: 10 }}
      py={7}
      px={{ xs: 5, lg: 10 }}
      bg="primary.main"
      alignItems="center"
      {...rest}>
      <Row gap={72} md>
        <Box alignItems="center" justifyContent="center">
          <Heading color="white" mb={5} level={2}>
            {t('jointhenetwork.heading')}
          </Heading>
          <Button
            mb={5}
            target="_blank"
            variant="filledWhite"
            href="https://feedback.roulezjeunesse.com/formulaire-reseau?source=site_roulezjeunesse"
            after={<AngleRight16 />}
            animation>
            {t('button.recruit')}
          </Button>
        </Box>
        <Box alignItems="center" justifyContent="center">
          <StyledImg src="/static/img/photos/product/Fixer-trott.png" />
        </Box>
      </Row>
    </SectionBackground>
  );
};

export default JoinTheNetwork;
