import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from '../styles/constants';

const FixedHeightPhotoWrap = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${(props) => props.src});
  background-size: ${(props) => (props.contain ? 'contain' : 'cover')};
  background-position: center;
  background-repeat: no-repeat;

  ${(props) =>
    props.mdheight &&
    `
        @media ${device.md}{
            height:${props.mdheight}px;
        }
    `}
`;

const FullSizeA = styled(Link)`
  display: block;
  height: 100%;
  width: 100%;
`;

const FullSizeDiv = styled.div`
  display: block;
  height: 100%;
  width: 100%;
`;

const FixedHeightPhoto = ({
  contain = false,
  src,
  children,
  href = '',
  mdheight,
  ...props
}) => {
  const Wrapper = href ? FullSizeA : FullSizeDiv;

  return (
    <Wrapper to={href} {...props}>
      <FixedHeightPhotoWrap contain={contain} mdheight={mdheight} src={src}>
        {children}
      </FixedHeightPhotoWrap>
    </Wrapper>
  );
};

export default FixedHeightPhoto;
