import React from 'react';
import styled from 'styled-components';
// Components
import Text from '../atoms/Text';
import Heading from '../atoms/Heading';
import Button from '../atoms/Button';
import Box from '../atoms/Box';
import Container from '../atoms/Container';
import ToolkitFix from '../ToolkitFix';
// Icons
import AngleRight16 from '../../icons/AngleRight16';
import SectionBackground from '../molecules/SectionBackground';
import NewMainForm from '../main-form/NewMainForm';

const AllenKey = styled(ToolkitFix)`
  width: 150px;
  height: 150px;
  bottom: -61px;
  left: 262px;
  transform: rotate(45deg);
  position: absolute;
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    display: none;
  }
`;

const Square = styled(ToolkitFix)`
  width: 250px;
  height: 250px;
  bottom: -73px;
  right: -64px;
  transform: rotate(68deg);
  position: absolute;
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    width: 150px;
    height: 150px;
    bottom: -66px;
    right: -38px;
  }
`;

const Hexa = styled(ToolkitFix)`
  width: 250px;
  height: 250px;
  top: -46px;
  left: -59px;
  transform: rotate(36deg);
  position: absolute;
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    width: 120px;
    height: 120px;
    top: -12px;
    left: -10px;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    width: 120px;
    height: 120px;
    top: -38px;
    left: -38px;
  }
`;

const MaintainPleasure = ({
  title,
  text,
  buttonText,
  link = '/reparation',
  mainForm = false,
  ...rest
}) => {
  return (
    <SectionBackground
      mb={{ sm: 0, md: 8, lg: 10 }}
      py={{ xs: 7, lg: 9 }}
      bg="primary.main"
      alignItems="center"
      style={{ position: 'relative', overflow: 'hidden' }}
      {...rest}>
      <AllenKey name="allen-key" color="yellow.lighter" />
      <Square name="square" color="violet.main" />
      <Hexa name="hexa" color="green.lighter" />
      <Container style={{ zIndex: 1 }}>
        <Box
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
          mb={5}>
          <img
            src="/static/img/logos/logo-blanc.png"
            height={40}
            className="logo"
            style={{ align: 'center' }}
          />
        </Box>
        <Heading color="white" mb={3} center level={2}>
          {title}
        </Heading>
        <Text mb={5} color="white" textAlign="center">
          {text}
        </Text>
        <Box alignItems="center" justifyContent="center" flexDirection="row">
          {mainForm ? (
            <NewMainForm />
          ) : (
            <Button
              mb={5}
              variant="filledWhite"
              {...(link ? { href: link } : { to: link })}
              after={<AngleRight16 />}
              animation>
              {buttonText}
            </Button>
          )}
        </Box>
      </Container>
    </SectionBackground>
  );
};

export default MaintainPleasure;
