// src/reducers/autodiag.js
import { SET_AUTODIAG } from '../actions/autodiag';

const initialState = {
    autodiag: 'v2',
};

export default function autodiagReducer(state = initialState, action) {
    switch (action.type) {
        case SET_AUTODIAG:
            return { ...state, autodiag: action.payload };
        default:
            return state;
    }
}