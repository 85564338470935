import React from 'react';
import Box from './Box';

export default function List({ item = false, children, ...props }) {
  return (
    <Box
      as={item ? 'li' : 'ul'}
      flexDirection={item ? 'row' : 'column'}
      alignItems={item ? 'center' : 'flex-start'}
      {...props}>
      {children}
    </Box>
  );
}
