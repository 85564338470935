import React, { createContext, useState, useEffect } from 'react';
import * as api from '../utils/api';

const defaultFlags = {};

export const FeaturesContext = createContext({
  isFeatureOn: () => null,
});

export default function FeaturesProvider({ children }) {
  const [flags, setFlags] = useState(defaultFlags);

  useEffect(() => {
    (async () => {
      const configs = await api.get('/configs');
      if (!configs.error_message) {
        setFlags((flags) => ({ ...flags, ...configs }));
      }
    })();
  }, []);

  const isFeatureOn = (feature) => {
    return flags.hasOwnProperty(feature.toUpperCase()) && !!flags[feature];
  };

  const getFeature = (feature) => {
    return isFeatureOn(feature) ? flags[feature.toUpperCase()] : false;
  };

  const setWidget = (val) => {
    setFlags((flags) => {
      return { ...flags, ISWIDGET: val };
    });
  };

  return (
    <FeaturesContext.Provider
      value={{ flags, isFeatureOn, getFeature, setWidget }}>
      {children}
    </FeaturesContext.Provider>
  );
}
