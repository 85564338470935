import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space, layout } from 'styled-system';

const SVG = (props) => (
  <svg
    width="100"
    height="300"
    viewBox="0 0 95 262"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.503 3.101A1.5 1.5 0 011.9 1.504l13.47-.911a1.5 1.5 0 11.201 2.993l-9.875.668c40.118 35.585 66.438 71.525 79.253 118.852 6.553 24.2 8.677 58.488 9.177 86.621.25 14.088.094 26.671-.125 35.732-.11 4.532-.235 8.183-.333 10.703a472.296 472.296 0 01-.156 3.655l-.01.193-.002.049v.016L92 260l-1.498-.077v-.003l.012-.247.035-.746c.03-.657.072-1.628.12-2.881.098-2.507.222-6.144.332-10.659.218-9.031.374-21.571.124-35.607-.5-28.116-2.625-62.079-9.073-85.89C69.44 77.309 43.55 41.85 3.742 6.532l.665 9.836a1.5 1.5 0 01-2.993.203L.504 3.1zM92 260l-1.498-.077a1.5 1.5 0 102.996.153L92 260z"
      fill="#1D1D1D"
    />
  </svg>
);

const SIZE = 256;
const CAN_SET_COLOR = true;
const SvgArrowPersona1 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space};
  ${layout};
`;
export default SvgArrowPersona1;
