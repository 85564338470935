import i18n from '../i18n';
import { prioSort } from '../utils/utils';
import strapiAnswer from './StrapiAnswer';

const strapiQuestion = ({ id, answers, content, content_en }) => {
  const lang = i18n.language;

  const getContent = () => {
    return content;
  };
  const getStrapiAnswers = () => {
    return answers.map((answer) => strapiAnswer(answer));
  };
  const sortedAnswers = () => {
    const sorted =
      content && answers.length ? [...getStrapiAnswers()].sort(prioSort) : [];
    return sorted;
  };

  return {
    id,
    answers,
    name: content,
    content_en,
    getContent,
    sortedAnswers,
    getStrapiAnswers,
  };
};

export default strapiQuestion;
