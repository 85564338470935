import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';
import constants from '../../../utils/constants';
import { formatDate } from '../../../utils/reparation';
import ProgressCourse from './ProgressCourse';
// Shared components
import Row from '../../shared/atoms/Row';
import Box from '../../shared/atoms/Box';
import Heading from '../../shared/atoms/Heading';
import Text from '../../shared/atoms/Text';
import ToolKitFix from '../../shared/ToolkitFix';
import Button from '../../shared/atoms/Button';
import Back16 from '../../icons/Back16';
import LottieAnimation from '../../lotties/Lottie';
import Calendar from '../../lotties/Calendar.json';
import MagnifyingGlass from '../../lotties/MagnifyingGlass.json';
import BikeRepaired from '../../lotties/BikeRepaired.json';
import TrottRepaired from '../../lotties/TrottRepaired.json';
import Canceled from '../../lotties/Canceled.json';

const StyledToolKitOcto = styled(ToolKitFix)`
  position: absolute;
  z-index: 1;
  transform: rotate(-15deg);
  left: 50%;
  top: 50px;
  width: 720px;
  height: 720px;
`;
const StyledBox = styled(Box)`
  z-index: 0;
  position: relative;
  overflow: hidden;
`;
const StyledRow = styled(Row)`
  z-index: 2;
  align-items: center;
`;

const CourseBanner = ({ course, openModal }) => {
  const { t } = useTranslation();
  switch (course.state) {
    case 'dispatched':
      return (
        <StyledBox
          bg="gray.lightest"
          borderRadius={{ xs: 'none', md: 'xl' }}
          p={{ xs: 4, md: 10 }}>

          <StyledRow>
            <Box style={{ flex: 5 }}>
              <Box
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between">
                <Text>
                  <Trans
                    i18nKey="coursebanner.upheading"
                    values={{
                      date: formatDate(course.date, 'DD MMM YYYY'),
                      courseId: course.id,
                    }}
                  />
                </Text>
                <Box display={{ xs: 'block', md: 'none' }}>
                </Box>
              </Box>
              <Heading level={2} mt={2}>
                {t('coursebanner.heading.dispatched')}
              </Heading>
              <Text mt={-4}>{t('coursebanner.subheading.dispatched')}</Text>
              <ProgressCourse statut={course.state} />
            </Box>
            <Box display={{ xs: 'none', md: 'block' }} style={{ flex: 2 }}>
              <img
                src={`${constants.CDN_BASE_URL}/img/photos/cycle_toolbox.jpeg`}
                alt={t('close.cta')}
                height={300}
                width={280}
                style={{ marginRight: 10, borderRadius: '50px' }} />
            </Box>
          </StyledRow>
        </StyledBox>
      );

    case 'scheduled':
      return (
        <StyledBox
          bg="gray.lightest"
          borderRadius={{ xs: 'none', md: 'xl' }}
          p={{ xs: 4, md: 10 }}>
          <StyledRow>
            <Box style={{ flex: 5 }}>
              <Box
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between">
                <Text>
                  <Trans
                    i18nKey="coursebanner.upheading"
                    values={{
                      date: formatDate(course.date, 'DD MMM YYYY'),
                      courseId: course.id,
                    }}
                  />
                </Text>
                <Box display={{ xs: 'block', md: 'none' }}>
                  <LottieAnimation lotti={Calendar} width={56} height={56} />
                </Box>
              </Box>
              <Heading level={2} mt={2}>
                {t('coursebanner.heading.scheduled', {
                  date: moment(course.date).format('LLL'),
                })}
              </Heading>
              <Text mt={-4}>
                {t('coursebanner.subheading.scheduled', {
                  label: course.fixer.prenom,
                })}
              </Text>
              <ProgressCourse statut={course.state} />
            </Box>
            <Box display={{ xs: 'none', md: 'block' }} style={{ flex: 3 }}>
              <LottieAnimation lotti={Calendar} width={230} height={230} />
            </Box>
          </StyledRow>
        </StyledBox>
      );
    case 'finished':
      return (
        <StyledBox
          bg="gray.lightest"
          borderRadius={{ xs: 'none', md: 'xl' }}
          p={{ xs: 4, md: 10 }}>
          <StyledToolKitOcto ToolKitSpring name="octo" color="gray.light" />
          <StyledRow>
            <Box style={{ flex: 5 }}>
              <Box
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between">
                <Text>
                  <Trans
                    i18nKey="coursebanner.upheading"
                    values={{
                      date: formatDate(course.date, 'DD MMM YYYY'),
                      courseId: course.id,
                    }}
                  />
                </Text>
                <Box display={{ xs: 'block', md: 'none' }}>
                  {course.product_id === 1 && (
                    <LottieAnimation
                      lotti={BikeRepaired}
                      width={56}
                      height={56}
                    />
                  )}
                  {course.product_id === 2 && (
                    <LottieAnimation
                      lotti={TrottRepaired}
                      width={56}
                      height={56}
                    />
                  )}
                </Box>
              </Box>
              <Heading level={2} mt={2}>
                {course.product_id === 1 && (
                  <>
                    {t('coursebanner.heading.finished.bike', {
                      label: course.client.prenom,
                    })}
                  </>
                )}
                {course.product_id === 2 && (
                  <>
                    {t('coursebanner.heading.finished.scooter', {
                      label: course.client.prenom,
                    })}
                  </>
                )}
              </Heading>
              <Text mt={-4}>{t('coursebanner.text.finished')}</Text>
              <ProgressCourse statut={course.state} />
            </Box>
            <Box display={{ xs: 'none', md: 'block' }} style={{ flex: 3 }}>
              {course.product_id === 1 && (
                <LottieAnimation
                  lotti={BikeRepaired}
                  width={230}
                  height={230}
                />
              )}
              {course.product_id === 2 && (
                <LottieAnimation
                  lotti={TrottRepaired}
                  width={230}
                  height={230}
                />
              )}
            </Box>
          </StyledRow>
        </StyledBox>
      );
    case 'cancelled':
      const isNoFixer = course.ctags.some((ctag) => ctag.id === 5);

      return (
        <StyledBox
          bg="gray.lightest"
          borderRadius={{ xs: 'none', md: 'xl' }}
          p={{ xs: 4, md: 10 }}>
          <StyledToolKitOcto ToolKitSpring name="octo" color="gray.light" />

          <StyledRow>
            <Box style={{ flex: 5 }}>
              <Box
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between">
                <Text>
                  <Trans
                    i18nKey="coursebanner.upheading"
                    values={{
                      date: formatDate(course.date, 'DD MMM YYYY'),
                      courseId: course.id,
                    }}
                  />
                </Text>
                <Box display={{ xs: 'block', md: 'none' }}>
                  <LottieAnimation lotti={Canceled} width={56} height={56} />
                </Box>
              </Box>
              <Heading level={2} mt={2}>
                {t('coursebanner.heading.cancelled')}
              </Heading>
              <Text mt={-4}>
                {isNoFixer
                  ? t('coursebanner.subheadings.1.cancelled')
                  : t('coursebanner.subheadings.2.cancelled')}
              </Text>
              <Button
                before={<Back16 />}
                color="blue.electric"
                mt={4}
                onClick={() =>
                  openModal('UPDATE_DISPONIBILITY', {
                    isReopen: true,
                  })
                }>
                {t('relaunchrequest')}
              </Button>
            </Box>
            <Box display={{ xs: 'none', md: 'flex' }} style={{ flex: 3 }}>
              <LottieAnimation lotti={Canceled} width={230} height={230} />
            </Box>
          </StyledRow>
        </StyledBox>
      );
    default:
      return <div />;
  }
};

export default CourseBanner;
