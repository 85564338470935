import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M6.99 13.878l-.627 2.339-2.29.048A8.96 8.96 0 013 12c0-1.492.363-2.9 1.006-4.139l2.04.374.893 2.026A5.35 5.35 0 006.649 12c.001.66.12 1.294.34 1.878z"
      fill="#FBBB00"
    />
    <path
      d="M20.843 10.319a9.015 9.015 0 01-.04 3.56 8.998 8.998 0 01-3.169 5.14v-.001l-2.568-.131-.364-2.269a5.364 5.364 0 002.308-2.739h-4.812v-3.56h8.645z"
      fill="#518EF8"
    />
    <path
      d="M17.634 19.018A8.962 8.962 0 0112 21a8.999 8.999 0 01-7.927-4.735l2.916-2.387a5.351 5.351 0 007.714 2.74l2.93 2.4z"
      fill="#28B446"
    />
    <path
      d="M17.745 5.072l-2.916 2.387a5.353 5.353 0 00-7.89 2.802l-2.932-2.4A8.998 8.998 0 0112 3c2.184 0 4.186.778 5.745 2.072z"
      fill="#F14336"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = false;
const SvgGoogleColor24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgGoogleColor24;
