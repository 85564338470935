import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx={16} cy={16} r={5} fill="#FFE249" />
    <path
      d="M16 1.1a.9.9 0 01.9.9v3a.9.9 0 11-1.8 0V2a.9.9 0 01.9-.9z"
      fill="#1D1D1D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 25a9 9 0 100-18 9 9 0 000 18zm0-1.8a7.2 7.2 0 100-14.4 7.2 7.2 0 000 14.4z"
      fill="#1D1D1D"
    />
    <path
      d="M16.9 27a.9.9 0 10-1.8 0v3a.9.9 0 101.8 0v-3zM5.9 16a.9.9 0 01-.9.9H2a.9.9 0 110-1.8h3a.9.9 0 01.9.9zM30 16.9a.9.9 0 100-1.8h-3a.9.9 0 100 1.8h3zM26.636 26.636a.9.9 0 01-1.272 0l-2-2a.9.9 0 111.272-1.272l2 2a.9.9 0 010 1.272zM7.364 8.636a.9.9 0 001.272-1.272l-2-2a.9.9 0 00-1.272 1.272l2 2zM8.636 23.364a.9.9 0 00-1.272 0l-2 2a.9.9 0 001.272 1.272l2-2a.9.9 0 000-1.272zM25.364 5.364a.9.9 0 011.272 1.272l-2 2a.9.9 0 01-1.272-1.272l2-2z"
      fill="#1D1D1D"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = true;
const SvgSummer32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgSummer32;
