import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx={16} cy={16} r={13} fill="#d5ff4f" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.667 23.89a7.91 7.91 0 015.345-4.555A5.972 5.972 0 0110 16v-2H9v-1a7.001 7.001 0 0114 0v1h-1v2a5.972 5.972 0 01-1.012 3.335 7.91 7.91 0 015.345 4.555 13.057 13.057 0 01-1.371 1.528 6.1 6.1 0 00-5.515-4.506A5.972 5.972 0 0116 22a5.972 5.972 0 01-3.447-1.088 6.1 6.1 0 00-5.515 4.506 13.076 13.076 0 01-1.371-1.528zM20.2 16v-2h-8.4v2a4.2 4.2 0 108.4 0zm-1.3-7.317A5.203 5.203 0 0121.104 12H10.896A5.203 5.203 0 0113.1 8.683V10a.9.9 0 101.8 0V8a.903.903 0 00-.004-.083 5.221 5.221 0 012.208 0A.94.94 0 0017.1 8v2a.9.9 0 101.8 0V8.683z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgBiker32Color = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgBiker32Color;
