import React from 'react';

import Skeleton from 'react-loading-skeleton';
import Box from './shared/atoms/Box';
import Row from './shared/atoms/Row';

const PayFormSkeleton = () => {
  return (
    <Box mt={5}>
      <Skeleton style={{ borderRadius: 16 }} height={40} />
      <Row gap={16} mt={6}>
        <Skeleton style={{ borderRadius: 16 }} height={40} />
        <Skeleton style={{ borderRadius: 16 }} height={40} />
      </Row>
    </Box>
  );
};

export default PayFormSkeleton;
