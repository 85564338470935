import React from 'react';
import styled from 'styled-components';
import { color, space, layout } from 'styled-system';

const StyledSeparatorH = styled.hr`
  ${(p) => (p.width ? p.width : '100%')};
  border: none;
  margin: ${(p) => p.margin || '20px 0'};
  background-color: ${(p) => p.theme.colors.gray.light};
  height: 1px;
  ${color}
  ${space}
  ${layout}
`;

const StyledSeparatorV = styled.hr`
  background-color: ${(p) => p.theme.colors.green.roulezjeunesse};
  width: ${(p) => (p.width ? p.width : '1px')};
  height: ${(p) => (p.height ? p.height : '100%')};
  margin: ${(p) => p.margin || '0 20px'};
  ${color}
  ${space}
  ${layout}
`;

const Separator = ({ axe, ...props }) => (
  <>
    {axe === 'horizontal' ? (
      <StyledSeparatorH {...props} />
    ) : (
      <StyledSeparatorV {...props} />
    )}
  </>
);

export default Separator;
