import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 57 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <ellipse cx={28.5} cy={51} rx={20} ry={5} fill="#1F77F9" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.47 23.231a7.385 7.385 0 11-14.77 0 7.385 7.385 0 0114.77 0zm-1.8 0a5.585 5.585 0 11-11.17 0 5.585 5.585 0 0111.17 0z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.016 51.519l-.51.481-.478-.45C19.995 43.988 8.5 33.164 8.5 22.92 8.5 12.476 17.46 4 28.506 4S48.5 12.475 48.5 22.92c0 10.228-11.463 21.036-19.484 28.599zm-.511-1.993c-3.78-3.564-8.1-7.705-11.612-12.112C12.956 32.47 10.3 27.506 10.3 22.92 10.3 13.562 18.359 5.8 28.506 5.8 38.652 5.8 46.7 13.56 46.7 22.92c0 4.586-2.656 9.55-6.592 14.494-3.51 4.408-7.828 8.548-11.603 12.112z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgPinColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPinColor56;
