import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '../../styles/constants';
import constants from '../../utils/constants';

const ErrorLabelWrapper = styled.span`
  color: ${colors.orange};
  font-weight: 500;
  vertical-align: middle;
`;

const ErrorLabel = ({ children, picto = true, ...props }) => {
  const { t } = useTranslation();
  return (
    <div {...props}>
      {!!children && picto && (
        <img
          src={`${constants.CDN_BASE_URL}/img/pictos/close.png`}
          alt={t('close.cta')}
          height={9}
          style={{ marginRight: 10 }}
        />
      )}
      <ErrorLabelWrapper>{children}</ErrorLabelWrapper>
    </div>
  );
};

export default ErrorLabel;
