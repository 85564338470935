import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.009 19.447l1.52-.8a1.01 1.01 0 01.941 0l4.251 2.235a1.01 1.01 0 001.466-1.066l-.812-4.733a1.01 1.01 0 01.29-.894l3.44-3.352a1.01 1.01 0 00-.56-1.724l-4.753-.69a1.01 1.01 0 01-.76-.553l-2.126-4.307a1.01 1.01 0 00-1.812 0L8.969 7.87a1.01 1.01 0 01-.761.553l-4.753.69a1.01 1.01 0 00-.56 1.724l3.44 3.352c.237.232.346.566.29.894l-.812 4.734a1.013 1.013 0 001.472 1.065l2.724-1.43v-.006zm.753-10.692L12 6.246l1.238 2.509a3.01 3.01 0 002.267 1.647l2.768.402-2.003 1.953a3.01 3.01 0 00-.866 2.664l.473 2.757-2.476-1.301a3.01 3.01 0 00-2.802 0l-.59.31V9.719c.31-.263.568-.59.753-.964z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgStarquarter24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgStarquarter24;
