import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import moment from 'moment';
import Box from '../shared/atoms/Box';
import Heading from '../shared/atoms/Heading';
import Image from '../shared/atoms/Image';
import Text from '../shared/atoms/Text';
import DisplayHoursShop from '../repair/DisplayHoursShop';
import Link from '../shared/atoms/Link';
import { slugify } from '../../utils/utils';

// Icons
import Hour24 from '../icons/Hour24';
import Pin24 from '../icons/Pin24';

const Cover = styled(Box)`
  position: relative;
  background-color: white;
  width: 100%;
  height: 266px;
  ${(p) =>
    !p.cover_image &&
    css`
      border: 1px dashed ${p.theme.colors.gray.dark};
    `};
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    height: 180px;
  }
`;

const Profil = styled(Box)`
  position: absolute;
  background-color: white;
  width: 150px;
  height: 150px;
  border: 4px solid white;
  bottom: -11px;
  left: 48px;
  ${(p) =>
    !p.profil &&
    css`
      border: 1px dashed ${p.theme.colors.gray.dark};
      background-color: white;
    `}
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    width: 100px;
    height: 100px;
  }
`;
const BoxWithBorder = styled(Box)`
  border: 1px solid ${(p) => p.theme.colors.gray.light};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-top: -55px;
  padding-top: 35px;
`;

export default function Header({
  details,
  address,
  name,
  scrollToRef,
  cover_image,
  profile_picture,
  city,
}) {
  const { open_hours } = details ? details[0] : {};
  const { t } = useTranslation();
  const today = moment().locale('en').format('ddd').toLowerCase();
  const dow = moment().day();

  return (
    <Box mb={7}>
      {city && (
        <Box mb={3}>
          <Link color="blue.electric" to={`/annuaire/atelier-velo-trottinette/${slugify(city)}`}>
            ← Retour à l'annuaire de {city}
          </Link>
        </Box>
      )}
      <Heading level={1}>
        {t('standalone.header.title', { name, city })}
      </Heading>
      <Box
        alignItems="center"
        style={{ position: 'relative', marginBottom: '31px' }}>
        <Cover borderRadius="xs" height="100%" cover_image={cover_image}>
          {cover_image && (
            <Image
              src={cover_image}
              style={{ objectFit: 'cover', borderRadius: '16px' }}
              height={266}
            />
          )}
        </Cover>
        <Profil borderRadius="xs" profil={profile_picture}>
          <Box style={{ position: 'relative', height: '100%', width: '100%' }}>
            {profile_picture && (
              <Image
                src={profile_picture}
                style={{ objectFit: 'cover', borderRadius: '12px' }}
              />
            )}
          </Box>
        </Profil>
      </Box>
      <BoxWithBorder p={5}>
        <Box flexDirection={{ xs: 'column', md: 'row' }} mb={4} mt={3}>
          <Box flexDirection="row">
            <Hour24 mr={1} />
            <DisplayHoursShop
              {...{
                today_open_hours: open_hours ? open_hours[today] : null,
                open_hours,
                index: dow,
                today,
                heading: false,
                openHoursColor: 'black',
                detailsHoursText: 'open.today',
                detailsHoursDropdown: false,
                hideDetailsHours: false,
              }}
            />
          </Box>
          <Box flexDirection="row">
            <Text mx={2}>•</Text>
            <Link color="blue.electric" onClick={() => scrollToRef('1')}>
              {t('standalone.header.cta.2')}
            </Link>
          </Box>
        </Box>
        <Box flexDirection="row">
          <Pin24 mr={1} />
          <Text color="black" fontWeight="bold" onClick={() => scrollToRef('2')} style={{ cursor: 'pointer' }}>
            {address}
          </Text>
        </Box>
      </BoxWithBorder>
    </Box>
  );
}
