import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Modal from '../../../../modals/Modal';
import Text from '../../../shared/atoms/Text';
import Button from '../../../shared/atoms/Button';
import Box from '../../../shared/atoms/Box';
import Messaging56Color from '../../../icons/Messaging56Color';
import Link from '../../../shared/atoms/Link';

const DisplayFaq = ({ whatDoYouNeed, setWhatDoYouNeed, course }) => {
  const { t } = useTranslation();
  const category = Object.values(whatDoYouNeed).find((c) => c.display === true);
  const returnAndReset = () => {
    setWhatDoYouNeed({
      modify: {
        display: false,
        link:
          'https://faq.roulezjeunesse.com/fr/article/comment-modifier-une-reservation-heure-adresse-details-photos-jgzbsl/',
      },
      cancel: {
        display: false,
        link:
          'https://faq.roulezjeunesse.com/fr/article/comment-annuler-mon-rendez-vous-1gsr5bc/',
      },
      price: {
        display: false,
        link: 'https://faq.roulezjeunesse.com/fr/article/comment-connaitre-le-prix-dune-intervention-sksbur/',
      },
    });
  };
  return (
    <>
      <Text fontWeight={2} mb={4}>
        {whatDoYouNeed.modify.display && t('modifyappointment')}
        {whatDoYouNeed.cancel.display && t('cancelappointment')}
        {whatDoYouNeed.price.display && t('contactmodal.howmuch')}
      </Text>
      <Box>
        <Link href={category.link} target="_blank" color="blue.electric">
          {t('contactmodal.texts.1')}
        </Link>
        <Text>
          <Trans
            i18nKey="contactmodal.texts.2"
            components={{
              reactLink: (
                <Link
                  color="black"
                  href={`mailto:bonjour@roulezjeunesse.com?subject=Course #${course.id}`}
                  target="_blank"
                />
              ),
            }}
          />
        </Text>
        <Link mt={4} color="blue.electric" onClick={() => returnAndReset()}>
          {t('cta.back')}
        </Link>
      </Box>
    </>
  );
};
const ContactModal = ({ course, showModal, openModal, ...props }) => {
  const { t } = useTranslation();
  const [whatDoYouNeed, setWhatDoYouNeed] = useState({
    modify: {
      display: false,
      link:
        'https://faq.roulezjeunesse.com/fr/article/comment-modifier-une-reservation-heure-adresse-details-photos-jgzbsl/',
    },
    cancel: {
      display: false,
      link:
        'https://faq.roulezjeunesse.com/fr/article/comment-annuler-mon-rendez-vous-1gsr5bc/',
    },
    price: {
      display: false,
      link: 'https://faq.roulezjeunesse.com/fr/article/comment-connaitre-le-prix-dune-intervention-sksbur/',
    },
  });

  return (
    <Modal
      zIndex="1002"
      width="small"
      hasCloseAction={true}
      open={showModal === 'CONTACT_COURSE'}
      onRequestClose={() => {
        openModal(false);
        setWhatDoYouNeed({
          modify: {
            display: false,
            link:
              'https://faq.roulezjeunesse.com/fr/article/comment-modifier-une-reservation-heure-adresse-details-photos-jgzbsl/',
          },
          cancel: {
            display: false,
            link:
              'https://faq.roulezjeunesse.com/fr/article/comment-annuler-mon-rendez-vous-1gsr5bc/',
          },
          price: {
            display: false,
            link: 'https://faq.roulezjeunesse.com/fr/article/comment-connaitre-le-prix-dune-intervention-sksbur/',
          },
        });
      }}>
      {whatDoYouNeed.price.display ||
        whatDoYouNeed.cancel.display ||
        whatDoYouNeed.modify.display ? (
        <DisplayFaq
          whatDoYouNeed={whatDoYouNeed}
          setWhatDoYouNeed={setWhatDoYouNeed}
          course={course}
        />
      ) : (
        <>
          <Text fontWeight={2} mb={4}>
            {t('contactmodal.title')}
          </Text>
          <Button
            variant="neutral"
            fullWidth
            size="compact"
            onClick={() =>
              setWhatDoYouNeed({
                ...whatDoYouNeed,
                modify: { ...whatDoYouNeed.modify, display: true },
              })
            }>
            {t('modifyappointment')}
          </Button>
          <Button
            variant="neutral"
            fullWidth
            size="compact"
            onClick={() =>
              setWhatDoYouNeed({
                ...whatDoYouNeed,
                cancel: { ...whatDoYouNeed.cancel, display: true },
              })
            }>
            {t('cancelappointment')}
          </Button>
          <Button
            variant="neutral"
            fullWidth
            size="compact"
            onClick={() =>
              setWhatDoYouNeed({
                ...whatDoYouNeed,
                price: { ...whatDoYouNeed.price, display: true },
              })
            }>
            {t('contactmodal.howmuch')}
          </Button>
          <Button
            fullWidth
            color="primary.main"
            size="compact"
            onClick={() =>
              window.open(
                `mailto:bonjour@roulezjeunesse.com?subject=Course #${course.id}`,
                '_blank',
              )
            }>
            {t('contactmodal.cta')}
          </Button>
        </>
      )}
    </Modal>
  );
};

export default ContactModal;
