import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.69 13.305l-.134.045a1.5 1.5 0 01-.959-2.843l4.13-1.393 1.75 2.22-2.045 3.246 6.675-1.895a1.5 1.5 0 01.82 2.886l-10.44 2.964-1.68-2.242 1.883-2.988z"
      fill="#FFE249"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 5a1 1 0 011-1h3.867l2.122 14.854.002.008.452 3.17a3 3 0 11-1.965.382l-.15-1.046L19.468 26H9.83a3.001 3.001 0 110-2h8.703l4.415-5.299L21.133 6H19a1 1 0 01-1-1zM8 25a1 1 0 10-2 0 1 1 0 002 0zm18 0a1 1 0 11-2 0 1 1 0 012 0z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgScooter32Color = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgScooter32Color;
