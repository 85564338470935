import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import Row from '../../shared/atoms/Row';
import Box from '../../shared/atoms/Box';
import Heading from '../../shared/atoms/Heading';
import Text from '../../shared/atoms/Text';
import Button from '../../shared/atoms/Button';
import LoveColor32 from '../../icons/LoveColor32';
import ToolKitFix from '../../shared/ToolkitFix';
import Upload16 from '../../icons/Upload16';
import Copy16 from '../../icons/Copy16';
import Check16 from '../../icons/Check16';

const StyledToolKitSpring = styled(ToolKitFix)`
  position: absolute;
  z-index: 1;
  transform: rotate(45deg);
  right: -20px;
  bottom: -50px;
  height: 200px;
  width: 180px;
`;
const StyledBox = styled(Box)`
  z-index: 0;
  position: relative;
  overflow: hidden;
`;
const StyledRow = styled(Row)`
  z-index: 2;
  align-items: center;
`;

function Sponsorship({ referCode, setShareModal, ...props }) {
  const { t } = useTranslation();
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);

  return (
    <StyledBox
      bg="orange.lighter"
      borderRadius="sm"
      p={{ xs: 4, lg: 5 }}
      mb={{ xs: 6, lg: 9 }}>
      <StyledRow md gap={24}>
        <Box style={{ flex: 2 }}>
          <Text>
            <Trans i18nKey="sponsorship.subeahding" />
          </Text>
        </Box>
        <Box>
          <Box
            bg="orange.lighter"
            borderRadius="sm"
            flexDirection="row"
            borderStyle="dashed"
            borderWidth="1px"
            borderColor="yellow.dark"
            alignItems="center"
            mb={2}
            p={2}
            onClick={() => {
              navigator.clipboard.writeText(referCode);
              setIsCopiedToClipboard(true);
              setTimeout(() => {
                setIsCopiedToClipboard(false);
              }, 2000);
            }}
            style={{ cursor: 'pointer' }}>
            <Text
              fontWeight={2}
              color="black"
              textAlign="center"
              style={{ width: '100%' }}>
              {referCode}
            </Text>
            {isCopiedToClipboard ? (
              <Check16 style={{ position: 'absolute', right: 35 }} />
            ) : (
              <Copy16 style={{ position: 'absolute', right: 35 }} />
            )}
          </Box>
          <Button
            fullWidth
            size="compact"
            variant="filledWhite"
            before={<Upload16 />}
            onClick={() => setShareModal(true)}>
            {' '}
            {t('sponsorship.sharecode')}
          </Button>
        </Box>
      </StyledRow>
    </StyledBox>
  );
}

export default Sponsorship;
