import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12 8.2a.8.8 0 01.8.8v4.341a.8.8 0 01-1.6 0V9a.8.8 0 01.8-.8zM13 16.5a1 1 0 11-2 0 1 1 0 012 0z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.408 4.488c1.157-1.986 4.027-1.986 5.184 0l6.995 12.003c1.166 2-.277 4.51-2.592 4.51H5.005c-2.315 0-3.758-2.51-2.592-4.51L9.408 4.488zm3.456 1.007l6.995 12.003a1 1 0 01-.864 1.503H5.005a1 1 0 01-.864-1.503l6.995-12.003a1 1 0 011.728 0z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgDanger24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgDanger24;
