import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.694 4.752a1.1 1.1 0 011.554-.058l1.985 1.843c.848.787 1.534 1.424 2.045 1.985.525.577.938 1.15 1.17 1.83a5.1 5.1 0 010 3.296c-.232.68-.645 1.252-1.17 1.83-.511.561-1.197 1.198-2.045 1.985l-1.985 1.843a1.1 1.1 0 11-1.496-1.612l1.949-1.81c.891-.828 1.509-1.403 1.95-1.887.433-.476.622-.787.715-1.06a2.9 2.9 0 000-1.874c-.093-.273-.282-.585-.715-1.06-.441-.484-1.059-1.06-1.95-1.887l-1.95-1.81a1.1 1.1 0 01-.057-1.554z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgAngleright24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgAngleright24;
