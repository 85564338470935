import { selectDevisType } from '../reducers/diag';
import { setProductId } from './course';

export const PUSH_Q = 'PUSH_Q';
export const POP_Q = 'POP_Q';
export const RESET_DIAG = 'RESET_DIAG';
export const SET_DEVIS = 'SET_DEVIS';
export const PREFILL_DIAG = 'PREFILL_DIAG';
export const PUSH_IN_PROGRESS_Q = 'PUSH_IN_PROGRESS_Q';
export const SET_AUTODIAG = 'SET_AUTODIAG';

export function pushQ(q) {
  return (dispatch, getState) => {
    dispatch({
      type: PUSH_Q,
      q,
    });
    const devis = selectDevisType(getState());
    const productId = getState().course.product_id;
    if (devis && Number(devis) !== Number(productId)) {
      dispatch(setProductId(devis));
    }
  };
}

export function pushInProgressQ(q) {
  return (dispatch) => {
    dispatch({
      type: PUSH_IN_PROGRESS_Q,
      q,
    });
  };
}

export function popQ(id = false) {
  return (dispatch) => {
    dispatch({
      type: POP_Q,
      id,
    });
  };
}

export function resetDiag() {
  return (dispatch) => {
    dispatch({
      type: RESET_DIAG,
    });
  };
}

export function setDevis(devis) {
  return (dispatch) => {
    dispatch({
      type: SET_DEVIS,
      payload: devis,
    });
  };
}

export function prefillDiag(str) {
  return async (dispatch, getState) => {
    const ids = JSON.parse(str);
    const store = getState();

    if (store.diag.questions.length > 0) {
      dispatch({
        type: RESET_DIAG,
      });
    }

    for (const { q, a } of ids) {
      const res = await fetch(
        `https://api.roulezjeunesse.com/autodiags/questions/${q}`,
      );
      const question = await res.json();

      const userAnswer = question.answers.find(
        (answer) => answer.id === Number(a),
      );
      dispatch(pushQ({ q: question, a: userAnswer }));

      if (!userAnswer.questions && userAnswer.quote) {
        const res2 = await fetch(
          `https://api.roulezjeunesse.com/autodiags/quotes/${userAnswer.quote.id}`,
        );
        const devis = await res2.json();
        dispatch(setDevis(devis));
      }
    }
  };
}
