import React, { useEffect, useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import i18n from '../../i18n';
import Box from '../shared/atoms/Box';
import Card from '../shared/atoms/Card';
import Text from '../shared/atoms/Text';
import Input from '../shared/atoms/Input';
import Button from '../shared/atoms/Button';
import Loader from '../blocks/Loader';
import ChatMessage from './ChatMessage';
import { ChatCtx } from '../../contexts/ChatContext';
import FilePlus24 from '../icons/FilePlus24';
import ChatMediaModal from '../../modals/ChatMediaModal';

const _getDayHour = (date) => {
  // const { t } = useTranslation();
  return moment(date).calendar(null, {
    lastDay: i18n.t('chat.lastday'),
    sameDay: i18n.t('chat.sameday'),
  });
};

function ChatModule({ fixer, channelId, isReadOnly = false }) {
  const { t } = useTranslation();
  const { id: clientId } = useSelector((s) => s.client);
  const {
    channelLoading,
    messages,
    setActiveChannel,
    onSendMessage,
    onSendMedia,
    mediaLoading,
    connectionState,
    moreMessagesLoading,
    getPrevMessages,
    hasPrev,
  } = useContext(ChatCtx);
  const [text, setText] = useState('');
  const [mediaModal, setMediaModal] = useState(null);
  const [scrollToRefId, setScrollToRefId] = useState(null);
  const inputFile = useRef(null);

  const refs = messages.reduce((acc, curr) => {
    acc[curr._id] = React.createRef();
    return acc;
  }, {});

  const isDisconnected = connectionState !== 'connected';

  const scrollTo = React.useCallback(
    (id) => {
      if (refs[id].current) {
        refs[id].current.scrollIntoView({
          block: 'nearest',
          inline: 'end',
        });
      }
    },
    [refs],
  );

  const sendMessage = () => {
    onSendMessage(text);
    setText('');
  };

  const sendMedia = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const formData = new FormData();
    for (let i = 0; i <= e.target.files.length; i++) {
      formData.append('file', e.target.files[i]);
    }
    onSendMedia(formData);
  };

  const handleMedia = () => {
    inputFile.current.click();
  };

  const handleInputKeyDown = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      sendMessage();
    }
  };

  const handleScroll = (e) => {
    if (e.target.scrollTop === 0 && !moreMessagesLoading) {
      setScrollToRefId(messages[0]._id);
      getPrevMessages();
    }
  };

  useEffect(() => {
    if ((!channelLoading && messages.length) || mediaLoading) {
      const lastMsgId = scrollToRefId || messages[messages.length - 1]._id;
      scrollTo(lastMsgId);
    }
  }, [channelLoading, messages, mediaLoading, scrollTo, scrollToRefId]);

  useEffect(() => {
    setActiveChannel(channelId);
  }, [setActiveChannel, channelId]);

  if (!messages.length) {
    return null;
  }

  return (
    <Card height="500px" radius="md" p={4} overflowX="hidden">
      <ChatMediaModal
        media={mediaModal}
        open={!!mediaModal}
        onRequestClose={() => setMediaModal(null)}
      />
      <Card
        p={0}
        bg="transparent"
        radius="none"
        borderBottom="1px solid"
        borderColor="gray.light">
        <Text fontWeight="bold" color="black" pb={2}>
          {t('chat.title')}
        </Text>
      </Card>
      {channelLoading ? (
        <Loader />
      ) : (
        <Box height="100%" position="relative" overflowX="hidden">
          <div onScroll={handleScroll} style={{ overflowY: 'auto' }}>
            {!hasPrev && (
              <Box alignItems="center" width="100%" mt={'2rem'}>
                <Text color="gray.darker" variant="small" fontWeight={2}>
                  {t('chat.start.text')}
                </Text>
              </Box>
            )}
            {moreMessagesLoading && (
              <Loader height="3rem" style={{ marginTop: '2rem' }} xs />
            )}
            <Box height="100%" p={4} position="relative">
              {messages.length ? (
                messages.map((message, index, arr) => {
                  const day =
                    index === 0
                      ? _getDayHour(message.createdAt)
                      : !moment(arr[index - 1].createdAt).isSame(
                        message.createdAt,
                        'hour',
                      )
                        ? _getDayHour(message.createdAt)
                        : null;
                  return (
                    <div key={message._id}>
                      {day && (
                        <Box alignItems="center" mt={5} mb={3}>
                          <Text
                            color="gray.main"
                            // width="auto"
                            height="auto">
                            <b>{day}</b>
                          </Text>
                        </Box>
                      )}
                      <div ref={refs[message._id]}>
                        <ChatMessage
                          fixer={fixer}
                          message={message}
                          isFirstMessage={
                            index === 0 ||
                            message.author !== messages[index - 1].author
                          }
                          isOwnMessage={message.author === `client_${clientId}`}
                          setMediaModal={setMediaModal}
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <Box height="100%" justifyContent="center" alignItems="center">
                  <Text color="gray.darker">{t('chat.nomessages')}</Text>
                </Box>
              )}
              {mediaLoading && (
                <Box alignItems="center" bottom={0}>
                  <Text color="gray.darker">{t('chat.imageloading')}</Text>
                </Box>
              )}
            </Box>
          </div>
        </Box>
      )}
      {!isReadOnly && (
        <Box p={0} flexDirection="row">
          <Input
            py={3}
            style={{ flex: 1, resize: 'none' }}
            name="course-chat-input"
            bg={isDisconnected ? 'gray.light' : 'white'}
            placeholder={
              isDisconnected ? t('chat.connection') : t('chat.saysomething')
            }
            before={
              <Box width="2rem" alignItems="center" pl={3}>
                {mediaLoading ? (
                  <Loader height="100%" width="100%" grey small />
                ) : (
                  <FilePlus24
                    color={isDisconnected ? 'gray.light' : 'blue.electric'}
                    onClick={handleMedia}
                  />
                )}
              </Box>
            }
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={handleInputKeyDown}
            type="text"
            as="textarea"
            rows={1}
            isBig
            withPadding
            disabled={isDisconnected}
            after={
              isDisconnected ? null : (
                <Button
                  m="6px"
                  size="compact"
                  color="blue.electric"
                  onClick={sendMessage}
                  disabled={!text}>
                  {t('send')}
                </Button>
              )
            }
          />
          <input
            id="chat-file-upload"
            accept="video/*,image/*"
            type="file"
            ref={inputFile}
            onChange={sendMedia}
            style={{ display: 'none' }}
            multiple
          />
        </Box>
      )}
    </Card>
  );
}

export default ChatModule;
