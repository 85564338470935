import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.659 5.753a1 1 0 10-1.317-1.506l-2.32 2.03A10.46 10.46 0 0012 5C7.455 5 3.573 7.903 2 12c.69 1.8 1.826 3.368 3.268 4.562l-1.926 1.685a1 1 0 001.317 1.506l2.32-2.031A10.459 10.459 0 0012 19c4.55 0 8.427-2.903 10-7a11.081 11.081 0 00-3.267-4.562l1.925-1.685zm-5.27 1.952A8.469 8.469 0 0012 7c-3.397 0-6.374 2.013-7.822 5a9.019 9.019 0 002.62 3.224l3.24-2.836a2.003 2.003 0 012.607-2.281l2.745-2.402zm-1.427 3.907l3.242-2.836A9.016 9.016 0 0119.822 12C18.375 14.988 15.4 17 12 17a8.464 8.464 0 01-3.39-.706l2.745-2.4a2.002 2.002 0 002.607-2.282z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgEyecrossed24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgEyecrossed24;
