import React from 'react';
import styled from 'styled-components';
// Components shared
import SectionBackground from '../../shared/molecules/SectionBackground';
import Container from '../../shared/atoms/Container';
import Box from '../../shared/atoms/Box';
import Heading from '../../shared/atoms/Heading';
import Text from '../../shared/atoms/Text';
import Button from '../../shared/atoms/Button';
import Row from '../../shared/atoms/Row';
// Icons
import AngleRight16 from '../../icons/AngleRight16';

const StyledBox = styled(Box)`
  border: 1px solid ${(p) => p.theme.colors.gray.light};
`;

const StyledButton = styled(Button)`
  width: 400px;
  align-self: center;
  justify-content: flex-end;
  text-align: center;
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    align-self: flex-start;
    width: 100%;
  }
`;

const StyleImg = styled.img`
  object-fit: contain;
  max-height: 470px;
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    max-height: 350px;
  }
`;

const Badge = ({ bg, labelButton, textButton, type, url }) => (
  <Box
    flexDirection={{ xs: 'column', md: 'row' }}
    justifyContent="space-between"
    alignItems="center"
    p={4}
    borderRadius="xs"
    bg={bg}>
    <Text
      fontWeight={1}
      color="black"
      mr={{ xs: 0, md: 4 }}
      mb={{ xs: 4, md: 0 }}>
      {labelButton}
    </Text>
    <StyledButton
      href={
        url || `https://feedback.roulezjeunesse.com/contact-b2b?type=${type}`
      }
      target="_blank"
      after={<AngleRight16 />}
      animation>
      {textButton}
    </StyledButton>
  </Box>
);

const BeReminded = ({
  title,
  text,
  textBold,
  url = false,
  textButton,
  labelButton,
  infos,
  img,
  reverse = null,
  type,
  setRef,
  bg = 'green.lighter',
  ...props
}) => (
  <div ref={setRef} {...props}>
    <SectionBackground alignItems="center" mb={{ xs: 7, md: '112px' }}>
      <Container>
        <Box
          flexDirection={{ xs: 'column', md: reverse ? 'row-reverse' : 'row' }}
          alignItems="center">
          <Box
            width={{ xs: '100%', md: '30%', lg: '40%' }}
            justifyContent="center">
            <StyleImg src={img} alt="RoulezJeunesse entreprise" />
          </Box>
          <Box
            width={{ xs: 'auto', md: '70%', lg: '60%' }}
            ml={!reverse ? { xs: 0, md: 9 } : null}
            mr={reverse ? { xs: 0, md: 9 } : null}
            py={7}>
            <Heading level={3}>{title}</Heading>
            <Text variant="medium" mb={3}>
              {text}
            </Text>
            {textBold && (
              <Text fontWeight="bold" mb={5}>
                {textBold}
              </Text>
            )}
            {infos ? (
              <StyledBox px={2} pt={4} pb={2} borderRadius="sm">
                <Row
                  gap={16}
                  flexDirection="row"
                  justifyContent="space-between"
                  mb={4}>
                  {infos.map((info, index) => (
                    <Box
                      key={index}
                      flexDirection={{ xs: 'column', sm: 'row' }}
                      style={{ flex: 1 }}>
                      {info.icon}
                      <Text ml={1} mt={{ xs: 1, sm: 0 }} color="black">
                        {info.text}
                      </Text>
                    </Box>
                  ))}
                </Row>
                <Badge {...{ bg, labelButton, textButton, type, url }} />
              </StyledBox>
            ) : (
              <Badge {...{ bg, labelButton, textButton, type, url }} />
            )}
          </Box>
        </Box>
      </Container>
    </SectionBackground>
  </div>
);

export default BeReminded;
