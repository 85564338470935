import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setBikeDetails, setSelectedShop } from '../../actions/course';
import { resetDiag } from '../../actions/diag';
import BaseBlock from '../../components/blocks/BaseBlock';
import Button from '../../components/shared/atoms/Button';
import InfoText from '../../components/InfoText';
import SuccessLabel from '../../components/blocks/SuccessLabel';
import ErrorLabel from '../../components/blocks/ErrorLabel';
import Heading from '../../components/shared/atoms/Heading';
import Alert from '../../components/shared/molecules/Alert';
import DevisRecap from '../../components/shared/organisms/DevisRecap';
import Box from '../../components/shared/atoms/Box';
import Text from '../../components/shared/atoms/Text';

import AngleRight16 from '../../components/icons/AngleRight16';
import useResaShop from '../../hooks/useResaShop';
import useFirstQuestionID from '../../hooks/useFirstQuestionID';
import course from '../../reducers/course';

const StyledButton = styled(Button)`
  z-index: 1001;
  width: 200px;
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    width: 100%;
  }
`;

const StyledBox = styled(Box)`
  border: 1px solid ${(p) => p.theme.colors.gray.light};
  padding-top: 10px;
  margin-bottom: 20px;
  transition: transform 150ms ease-in;
  width: 100%;
`;

const RepairSymptoms = ({ history, ...props }) => {
  const { t } = useTranslation();

  const { questions, devis } = useSelector((s) => s.diag);
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const { firstQuestionID, loading } = useFirstQuestionID();
  const { course_details } = useSelector((s) => s.course);

  useEffect(() => {
    if (!questions.length && !devis) {
      history.push(`/reparation/symptoms`);
    }
    if (!firstQuestionID && !loading) {
      history.push('/reparation/date');
    }
  }, []);

  const handleCancelClick = () => {
    setLoaded(true);
    if (devis.id) {
      dispatch(resetDiag());
      history.push(`/reparation/symptoms/`);
    } else {
      dispatch(setBikeDetails(' '));
      history.push('/reparation/date');
    }
  };

  const handleConfirmClick = () => {
    if (devis.id && !devis.isNotSupported) {
      dispatch(setBikeDetails(' '));
      history.push('/reparation/date');
    } else {
      history.push(
        `/reparation/symptoms/${questions.length ? questions.slice(-1)[0].q.id : ''
        }`,
      );
    }
  };

  if (history.location.pathname !== '/reparation/resume') {
    return <div />;
  }

  return (
    <div>
      <Heading level={2} as="h1" mb={{ xs: 4, lg: 5 }}>
        {(devis.id || loaded) && !devis.isNotSupported
          ? t(`repairsymptoms.headings.1`)
          : t(`repairsymptoms.headings.2`)}
      </Heading>
      {(devis.id || loaded) && <DevisRecap questions={questions} />}
      {devis.Contenu && (
        <Alert status="warning" mb={4} mt={3}>
          <span dangerouslySetInnerHTML={{ __html: devis.Contenu }} />
        </Alert>
      )}
      <StyledBox borderRadius="sm" p={4}>
        <Text mt={2} fontWeight={2} variant="large">
          {t('repairdetails.placeholder')}
        </Text>
        <Text color="gray.darkest">{course_details}</Text>
      </StyledBox>
      <Box
        flexDirection={{ xs: 'column', lg: 'row' }}
        justifyContent="flex-end">
        <StyledButton
          variant="neutral"
          onClick={handleCancelClick}
          mr={{ xs: 0, lg: 4 }}
          mb={{ xs: 4, lg: 0 }}>
          {devis.id || loaded ? t('modify.cta') : t('repairsymptoms.ctas.1')}
        </StyledButton>
        {!devis.id && (
          <React.Fragment>
            <ErrorLabel>
              <small>{t('repairsymptoms.texts.1')}</small>
            </ErrorLabel>
            <InfoText>
              <small>{t('repairsymptoms.texts.2')}</small>
            </InfoText>
          </React.Fragment>
        )}

        <StyledButton
          onClick={handleConfirmClick}
          after={<AngleRight16 color="blue.electric" />}
          animation>
          {devis.id && !devis.isNotSupported
            ? t('repairsymptoms.ctas.2')
            : t('repairsymptoms.ctas.3')}
        </StyledButton>
        {!devis.id && (
          <React.Fragment>
            <SuccessLabel>
              <small>{t('repairsymptoms.texts.3')}</small>
            </SuccessLabel>
            <InfoText>
              <small>{t('repairsymptoms.texts.4')}</small>
            </InfoText>
          </React.Fragment>
        )}
      </Box>
    </div>
  );
};

export default RepairSymptoms;
