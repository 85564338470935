import { SET_CLIENT, UNSET_CLIENT } from '../actions/client.jsx';

export const defaultClient = {
  id: false,
};

export default function clientReducer(state = defaultClient, action) {
  switch (action.type) {
    case UNSET_CLIENT:
      return defaultClient;
    case SET_CLIENT:
      return { ...state, ...action.client };
    default:
      return state;
  }
}

export function selectIsLoggedIn(state) {
  return !!state.client.id;
}
