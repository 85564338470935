import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M44.173 26L52 33.725V44.5a8 8 0 01-8 8H15a7 7 0 01-7-7V43l14.593-10.698 8.662 4.874L44.173 26z"
      fill="#A6F2C4"
    />
    <circle cx={14} cy={14} r={11} fill="#FFE249" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50 42a8 8 0 01-8 8H14a8 8 0 01-8-8V14a8 8 0 018-8h18c9.941 0 18 8.059 18 18v18zM14 8h18a4 4 0 014 4v2.857a6 6 0 006 6h2a4 4 0 014 4V42a6 6 0 01-6 6H14a6 6 0 01-6-6V14a6 6 0 016-6zm33.49 11.976A16.034 16.034 0 0037.094 8.828c.574.92.906 2.007.906 3.172v2.857a4 4 0 004 4h2c1.302 0 2.506.415 3.49 1.119z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.429 29.679h1.096l1.394 2.789-1.304 2.37a6.286 6.286 0 103.942 6.269H28.132l5.85-7.066.499 1.246a6.286 6.286 0 101.857-.743l-1.052-2.63V29.68h1.977a.079.079 0 110 .158 1 1 0 100 2 2.079 2.079 0 100-4.158h-4.692a1 1 0 100 2h.715v1.428h-9.81l-.715-1.428H24a1 1 0 100-2h-2.837a.97.97 0 00-.042 0H19.43a1 1 0 100 2zm3.121 11.428a4.286 4.286 0 11-2.91-4.496l-2.474 4.496h5.384zm-1.211-3.436l-.79 1.436h1.725a4.29 4.29 0 00-.935-1.436zm.992-1.803a6.287 6.287 0 012.042 3.24h.866l-2.233-4.466-.675 1.226zm9.828-2.76h-7.684l2.893 5.785 4.791-5.786zm4.936 3.33a4.286 4.286 0 11-1.857.743l1.548 3.87a1 1 0 001.857-.744l-1.548-3.87z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgImageColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgImageColor56;
