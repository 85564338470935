import * as Sentry from '@sentry/browser';
import { getLang, isDev, isStaging, serialize } from './utils.jsx';
import { loadStore } from './reparation';

let fetch;
if (typeof window === 'undefined') {
  fetch = require('node-fetch');
} else {
  require('whatwg-fetch');
}

const API_BASE_URL =
  isStaging() || isDev()
    ? 'https://staging-api.roulezjeunesse.com'
    : 'https://api.roulezjeunesse.com';

export function getAuthParameters() {
  const store = loadStore();
  if (store && store.client && store.client.id) {
    return {
      id: store.client.id,
      token: store.client.token,
    };
  }

  return false;
}

async function callAPI(method, route, data, customAuth = false) {
  const { header } = getLang();

  if (typeof window === 'undefined') {
    // Server-side
    const url = `${API_BASE_URL}${route}`;
    const options = {
      method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Country-Code': header,
      },
    };

    if (customAuth) {
      options.headers['Authorization'] = `Bearer ${customAuth.token}`;
    } else {
      const auth = getAuthParameters();
      if (auth) {
        options.headers['Authorization'] = `Bearer ${auth.token}`;
      }
    }

    if (method !== 'GET' && data) {
      options.body = JSON.stringify(data);
    }

    try {
      const response = await fetch(url, options);
      return await response.json();
    } catch (e) {
      console.log('caught error', e);
      Sentry.captureException(e);
    }
  } else {
    // Client-side
    const xhr = new XMLHttpRequest();

    if (method === 'GET' && data) route = `${route}?${serialize(data)}`;

    xhr.open(method, API_BASE_URL + route);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('X-Country-Code', header);

    try {
      if (customAuth) {
        xhr.setRequestHeader('Authorization', `Bearer ${customAuth.token}`);
      } else {
        const auth = getAuthParameters();
        if (auth) {
          xhr.setRequestHeader('Authorization', `Bearer ${auth.token}`);
        }
      }

      xhr.send(method !== 'GET' && data ? JSON.stringify(data) : null);

      const promise = () =>
        new Promise((resolve, reject) => {
          xhr.onload = () => {
            resolve(JSON.parse(xhr.response));
          };

          xhr.onerror = () => {
            reject(JSON.parse(xhr.response));
          };
        });
      const httpResponse = await promise();
      return httpResponse;
    } catch (e) {
      console.log('caught error', e);
      Sentry.captureMessage('response fetch catch start ---- ');
      Sentry.captureMessage(JSON.stringify(e.message));
      Sentry.captureException(e);
      Sentry.captureMessage('response fetch catch end ----');
    }
  }
}

export function post(route, data) {
  return callAPI('POST', route, data);
}

export function get(route, data, customAuth) {
  return callAPI('GET', route, data, customAuth);
}
export function put(route, data) {
  return callAPI('PUT', route, data);
}
export function del(route, data) {
  return callAPI('DELETE', route, data);
}

export async function postImage(route, data, name = 'photo') {
  const formData = new FormData();

  formData.append(name, data);

  try {
    const oReq = new XMLHttpRequest();

    oReq.open('POST', API_BASE_URL + route);

    const auth = getAuthParameters();

    if (auth) {
      oReq.setRequestHeader('Authorization', `Bearer ${auth.token}`);
    }

    return new Promise((resolve, reject) => {
      oReq.onreadystatechange = () => {
        if (oReq.readyState == XMLHttpRequest.DONE) {
          const data = JSON.parse(oReq.responseText);
          resolve(data);
        }
      };
      oReq.send(formData);
    });
  } catch (e) {
    Sentry.captureException(e);
    console.warn('caught error', e);
  }
}
