import React from 'react';

import Skeleton from 'react-loading-skeleton';
import Row from '../shared/atoms/Row';

const DateTimeModuleSkeleton = () => {
  return (
    <>
      <Row xs gap={24}>
        <Skeleton height={350} style={{ borderRadius: 16 }} />
        <Skeleton height={350} style={{ borderRadius: 16 }} />
      </Row>
      <Skeleton height={80} style={{ borderRadius: 16, marginTop: 16 }} />
      <Row xs gap={24} mt={4}>
        <Skeleton height={350} style={{ borderRadius: 16 }} />
        <Skeleton height={350} style={{ borderRadius: 16 }} />
      </Row>
    </>
  );
};

export default DateTimeModuleSkeleton;
