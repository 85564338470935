import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as api from '../../utils/api';
import { CoursesCtx } from '../../contexts/CoursesContext';
// My appointments components
import AllCourses from '../../components/customerArea/myAppointments/AllCourses';
import AccountNav from '../../components/customerArea/shared/AccountNav';
import Sponsorship from '../../components/customerArea/myAppointments/Sponsorship';
import ShareSponsorshipModal from '../../components/customerArea/myAppointments/ShareSponsorshipModal';
import SkeletonCourses from '../../components/customerArea/myAppointments/SkeletonCourses';
// Shared components
import Row from '../../components/shared/atoms/Row';
import Box from '../../components/shared/atoms/Box';
import Container from '../../components/shared/atoms/Container';
import Heading from '../../components/shared/atoms/Heading';
import Button from '../../components/shared/atoms/Button';
import EmptyState from '../../components/shared/molecules/EmptyState';

function Account({ history }) {
  const { t } = useTranslation();
  const [shareModal, setShareModal] = useState(false);
  const client = useSelector((s) => s.client);
  const [referCode, setReferCode] = useState(false);
  const { courses, loading, getCourses } = useContext(CoursesCtx);

  useEffect(() => {
    const getReferCode = async () => {
      const { code } = await api.get(`/clients/${client.id}/referCode`);
      setReferCode(code);
    };
    if (!client.id) {
      history.push(`/login${window.location.search}`);
    } else {
      getReferCode();
      getCourses();
    }
  }, []);

  return (
    <Container>
      <Container fluid pb={10} pt={4}>
        <Helmet title={t('account.meta.title')} />
        <ShareSponsorshipModal
          open={shareModal}
          onRequestClose={() => setShareModal(false)}
          referCode={referCode}
        />
        <Row md gap={56}>
          <AccountNav />
          <Box mt={{ xs: -7, lg: 0 }}>
            <Sponsorship setShareModal={setShareModal} referCode={referCode} />
            <Heading level={2} mb={{ xs: 4, lg: 6 }}>
              {t('myappointments')}
            </Heading>
            {loading ? (
              <SkeletonCourses />
            ) : (
              <div>
                {!courses.length ? (
                  <Box alignItems="center">
                    <EmptyState
                      width="60%"
                      title={t('account.nocourse.titles.1')}
                      subTitle={t('account.nocourse.subtitles.1')}
                      cta={
                        <Button mt={2} alignSelf="center" to="/reparation">
                          {t('cta.book')}
                        </Button>
                      }
                    />
                  </Box>
                ) : (
                  <AllCourses courses={courses} />
                )}
              </div>
            )}
          </Box>
        </Row>
      </Container>
    </Container>
  );
}

export default Account;
