import {
  PUSH_Q,
  POP_Q,
  RESET_DIAG,
  SET_DEVIS,
  PUSH_IN_PROGRESS_Q,
} from '../actions/diag';
import { parseCapabilitiesFromURL } from '../utils/utils';

export const initialState = {
  questions: [],
  inProgressQuestion: {},
  devis: {},
};

export function selectDevisType(state) {
  if (state.diag.questions.length && state.diag.devis.id) {
    return state.diag.questions[0].a.text === 'Une trottinette' ||
      state.diag.questions[0].a.question === 6
      ? 2
      : 1;
  }
  return null;
}

export function selectCapabilities(state, url) {
  const { capabilities } = state.diag.devis;
  const reducedCapabilities = state.diag.questions.reduce((acc, q) => {
    return acc.concat(q.a.capabilities || []);
  }, []);

  // Parse capabilities from the URL
  const queryCapabilities = parseCapabilitiesFromURL(url);
  if (queryCapabilities.length > 0 ||
    (state.diag.devis.id &&
      (capabilities?.length > 0 || reducedCapabilities.length > 0))
  ) {
    const capabilitesSet = new Set();
    if (capabilities) {
      for (const capability of capabilities) {
        capabilitesSet.add(capability.id);
      }
    }
    if (reducedCapabilities) {
      for (const capability of reducedCapabilities) {
        capabilitesSet.add(capability.id);
      }
    }
    if (queryCapabilities) {
      for (const capability of queryCapabilities) {
        capabilitesSet.add(capability.id);
      }
    }
    return [...capabilitesSet].map((c) => ({ id: c }));
  }
  return [];
}

export function selectLastQID(state) {
  if (state.diag.questions.length >= 1) {
    return state.diag.questions.slice(-1)[0].q.id;
  }
  return state.course.selectedShop?.firstQID || '';
}

export function selectDevisID(state) {
  return state.diag.devis.id || null;
}

export function selectQuestionsLight(state) {
  const { questions } = state.diag;
  return questions.map((item) => ({
    q: { name: item.q.name, id: item.q.id },
    a: { content: item.a.content, id: item.a.id },
  }));
}

export default function configs(state = initialState, action) {
  switch (action.type) {
    case RESET_DIAG:
      return initialState;
    case PUSH_Q:
      return { ...state, questions: [...state.questions, action.q] };
    case PUSH_IN_PROGRESS_Q:
      return { ...state, inProgressQuestion: action.q };
    case SET_DEVIS:
      return {
        ...state,
        devis: { ...action.payload },
      };
    case POP_Q:
      const newQs = [...state.questions];
      const { id = false } = action;
      if (id && !isNaN(id)) {
        while (newQs.length > 0 && id != newQs[newQs.length - 1].q.id) {
          newQs.pop();
        }
      }
      !!newQs.length && newQs.pop();
      return { ...state, questions: newQs, devis: {} };
    default:
      return state;
  }
}
