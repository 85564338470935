import React, { useEffect, useState, useContext } from 'react';
import { useSelector, ReactReduxContext, useDispatch } from 'react-redux';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import useFirstQuestionID from '../../hooks/useFirstQuestionID';
// Components
import GlobalSidebar from '../../components/repair/GlobalSidebar';
import RepairCheckout from './RepairCheckout';
import RepairDate from './RepairDate';
import RepairDefault from './RepairDefault';
import RepairDetails from './RepairDetails';
import RepairLogin from './RepairLogin';
import RepairSymptoms from './RepairSymptoms';
import { CoursesCtx } from '../../contexts/CoursesContext.jsx';
import { selectLastQID, selectDevisID } from '../../reducers/diag';
import DiagWrapper from '../DiagWrapper.jsx';
import useDeviceWidth from '../../hooks/useDeviceWidth';
import Box from '../../components/shared/atoms/Box';
import Container from '../../components/shared/atoms/Container';
import RepairSkeleton from '../../components/repair/RepairSkeleton';

import Link from '../../components/shared/atoms/Link';
import SVGAngleLeft16 from '../../components/icons/AngleLeft16';
import { shouldRedirectToStep, stepForPage } from '../../utils/reparation';
import useResaShop from '../../hooks/useResaShop';
import { setAutodiag } from '../../actions/autodiag';
import TallyForm from '../../components/TallyForm'; // Import the TallyForm component
import RepairAutodiag from './RepairAutodiag'; // Import the new scene

const duration = 1000;

const extractPageName = (path) => path.split('/').filter(Boolean)[1] || null;
const displayPath = (page, path) =>
  `${path}/${page}${page === 'symptoms' ? '/:qid' : ''}`;

const slideOut = keyframes`
  0% { opacity: 1;
  }
  25% { opacity: .1; }
  40% { opacity: 0; }
  100% { opacity: 0; 
  }
`;

const slideIn = keyframes`
  0%, 25% { opacity: 0; 
  }
  75% { opacity: 0;  }
  85% { opacity: .2;
   }
  100% { opacity: 1; 
  }
`;

const urlForGoBack = [
  '/reparation/resume',
  '/reparation/date',
  '/reparation/details',
  '/reparation/informations',
];

const ContentBox = styled(Box)`
  display: flex;
  display: -ms-flexbox;
  -ms-flex-direction: column;
  padding-top: ${(p) => (p.hasTopPad ? '5rem' : '1rem')};
  overflow-y: visible;
  position: relative;
  margin-right: 0 !important;
  .slide-repair {
    position: relative;
  }
  .slide-exit-active {
    margin-top: 33px;
    animation: ${slideOut} ${duration}ms forwards;
    position: absolute;
    @media (max-width: ${(p) => p.theme.breakpoints.md}) {
      width: 100%;
      padding: 0 0.5rem;
    }
  }
  .slide-enter-active {
    margin-top: 32px;
    animation: ${slideIn} ${duration}ms forwards;
    position: absolute;
    @media (max-width: ${(p) => p.theme.breakpoints.md}) {
      width: 100%;
      padding: 0 0.5rem;
    }
  }
`;

const RelativeContainer = styled(Container)`
  position: relative;
`;

function Repair(props) {
  const { isMobile } = useDeviceWidth();
  const { photos, positionError, lastQID, devisID } = useSelector((s) => ({
    ...s.course,
    lastQID: selectLastQID(s),
    devisID: selectDevisID(s),
  }));

  const { questions } = useSelector((s) => s.diag);
  const { store } = useContext(ReactReduxContext);
  const history = useHistory();
  const location = useLocation();
  const [isAutodiagV2, setIsAutodiagV2] = useState(false);
  const initialPage = extractPageName(location.pathname);
  const {
    firstQuestionID,
    loading: firstQuestionLoading,
  } = useFirstQuestionID();

  const [progress, setProgress] = useState(0);
  const [page, setPage] = useState(initialPage);
  const { getCourses } = useContext(CoursesCtx);
  const { t } = useTranslation();
  const hasBackLink = page && page !== 'checkout';

  // Determine if the current path is for RepairAutodiag
  const isAutodiag = location.pathname.includes('/reparation/autodiag');

  const handleGoBack = () => {
    const { pathname } = location;
    const step = urlForGoBack.indexOf(pathname);
    if (step === 0 || step === -1) {
      if (!questions.length) {
        history.replace(`/reparation`);
      } else {
        history.replace(
          `/reparation/symptoms/${questions[questions.length - 1].q?.id}`,
        );
      }
    } else if (step === 1 && !firstQuestionID) {
      history.replace(`/reparation`);
    } else {
      history.replace(`${urlForGoBack[step - 1]}`);
    }
  };

  const dispatch = useDispatch();
  // Access the current autodiag state
  const currentAutodiag = useSelector((state) => state.autodiag.autodiag);


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isAutodiagV2 = queryParams.get('autodiag') === 'v2' || currentAutodiag;

    // Only update if it's not already set to 'v2'
    if (isAutodiagV2) {
      dispatch(setAutodiag('v2'));
    } else {
      dispatch(setAutodiag(null));
    }

    if (!isAutodiagV2) {
      (async () => {
        const redir = await shouldRedirectToStep(
          stepForPage(history.location.pathname),
          store.getState()
        );
        if (redir) {
          history.push(`/reparation${redir}`);
        } else if (positionError) {
          console.log(positionError);
          history.push(`/reparation`);
        }
      })();
    }
  }, [location.search, dispatch, history, store, positionError]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isAutodiagV2 = queryParams.get('autodiag') === 'v2' || currentAutodiag;

    if (queryParams.get('autodiag') === 'v2' || isAutodiagV2) {
      setIsAutodiagV2(true);
    }
  }, [location.search]);

  const repairStepsRoutes = [
    {
      page: 'resume',
      Component: isAutodiagV2 ? RepairDefault : RepairSymptoms,
    },
    {
      page: 'symptoms',
      Component: DiagWrapper,
    },
    // Add the new route conditionally before 'date'
    ...(isAutodiagV2
      ? [
        {
          page: 'autodiag',
          Component: RepairAutodiag,
        },
      ]
      : []),
    {
      page: 'date',
      Component: RepairDate,
    },
    // Conditionally include RepairDetails
    ...(isAutodiagV2
      ? []
      : [
        {
          page: 'details',
          Component: RepairDetails,
        },
      ]),
    {
      page: 'informations',
      Component: RepairLogin,
    },
    {
      page: 'checkout',
      Component: RepairCheckout,
    },
    {
      page: '',
      Component: RepairDefault,
    },
  ];


  const _onPageChange = (currentPage) => {
    switch (currentPage) {
      case 'fixed':
        return;
      case 'resume':
        setProgress(25);
        break;
      case 'symptoms':
        setProgress(25);
        break;
      case 'date':
        setProgress(50);
        break;
      case 'details':
        setProgress(60);
        break;
      case 'informations':
        setProgress(75);
        break;
      case 'checkout':
        setProgress(90);
        break;
      default:
        setProgress(0);
    }
  };

  useEffect(() => {
    const newPage = extractPageName(props.location.pathname);
    if (newPage !== page) {
      setPage(newPage);
    }
    _onPageChange(newPage);
  }, [page, props.location.pathname]);
  if (firstQuestionLoading) {
    return <RepairSkeleton />;
  }
  return (
    <RelativeContainer fluid>
      <Container fluid={isMobile}>
        <Box flexDirection={{ xs: 'column-reverse', lg: 'row' }}>
          <Box
            width={{ xs: 1, lg: '66%' }}
            pr={{ xs: 0, lg: 6 }}
            mt={{ xs: hasBackLink ? 3 : 2, lg: hasBackLink ? 3 : 6 }}>
            <ContentBox px={{ xs: 5, lg: 0 }} py={{ xs: 10, lg: 8 }}>
              {hasBackLink && (
                <Link
                  onClick={handleGoBack}
                  color="blue.electric"
                  variant="small"
                  mb={3}
                  ml="-6px"
                  before={<SVGAngleLeft16 />}>
                  {isAutodiag ? t('cta.back.autodiag') : t('cta.back')}
                </Link>
              )}
              {repairStepsRoutes.map(({ page: subPage, Component }) => (
                <Route
                  key={subPage}
                  exact
                  path={displayPath(subPage, props.match.path)}>
                  {({ match }) => (
                    <CSSTransition
                      in={match !== null}
                      timeout={duration}
                      classNames="slide"
                      unmountOnExit>
                      <div className="slide">
                        <Component
                          getCourses={
                            subPage === 'checkout' ? getCourses : null
                          }
                          {...props}
                        />
                      </div>
                    </CSSTransition>
                  )}
                </Route>
              ))}
              {/* Redirect to /reparation/autodiag if isAutodiagV2 */}
              <Route exact path={'/reparation/symptoms'}>
                <Redirect
                  to={
                    isAutodiagV2
                      ? '/reparation/autodiag'
                      : !devisID
                        ? !!lastQID || firstQuestionID
                          ? `/reparation/symptoms/${lastQID || firstQuestionID}`
                          : `/reparation/date`
                        : '/reparation/resume'
                  }
                />
              </Route>
            </ContentBox>
          </Box>
          <GlobalSidebar {...{ progress, photos, etape: 5 }} />
        </Box>
      </Container>
    </RelativeContainer>
  );
}

export default Repair;
