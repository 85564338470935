import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/constants';
import constants from '../../utils/constants';

const InfoLabelWrapper = styled.span`
  color: ${colors.blue};
  font-weight: 500;
  vertical-align: middle;
`;

const InfoLabel = ({ children, nopicto = false, ...props }) => (
  <div {...props}>
    {!!children && !nopicto && (
      <img
        src={`${constants.CDN_BASE_URL}/img/pictos/wait.png`}
        height={9}
        style={{ marginRight: 10 }}
      />
    )}
    <InfoLabelWrapper>{children}</InfoLabelWrapper>
  </div>
);

export default InfoLabel;
