import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// Components
import Box from './Box';
import Container from './Container';
import Heading from './Heading';
import Button from './Button';
import ToolkitFix from '../ToolkitFix';
// Icons
import AngleRight16 from '../../icons/AngleRight16';

const ToolkitBG = styled(ToolkitFix)`
  position: absolute;
  right: 12rem;
  top: 3rem;
  transform: rotate(-15deg);
`;

const BodyBand = ({ text, textButton, link, ...rest }) => {
  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems={{ xs: 'flex-start', lg: 'center' }}
      bg="green.lighter"
      p={{ xs: '16px 16px 16px 16px', md: '16px 16px 16px 24px' }}
      width="100%"
      style={{ position: 'relative', overflow: 'hidden' }}
      {...rest}>
      <Box flexDirection="row" alignItems="center" style={{ zIndex: 2 }}>
        <Heading mt={2} level={5} color="black">
          {text}
        </Heading>
      </Box>
      <ToolkitBG name="hexa" color="violet.lighter" />
      <Button
        variant="outlinedBlack"
        to={link}
        after={<AngleRight16 />}
        style={{ zIndex: 2 }}
        animation>
        {textButton}
      </Button>
    </Box>
  );
};

BodyBand.propTypes = {
  text: PropTypes.string,
  textButton: PropTypes.string,
  link: PropTypes.string,
};

export default BodyBand;
