import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { unsetClient, setClient } from '../../actions/client';
import * as api from '../../utils/api';
// Components
import AccountNav from '../../components/customerArea/shared/AccountNav';
import Row from '../../components/shared/atoms/Row';
import Container from '../../components/shared/atoms/Container';
import Box from '../../components/shared/atoms/Box';
import Heading from '../../components/shared/atoms/Heading';
import Separator from '../../components/Separator';
import Informations from '../../components/customerArea/myAccount/Informations';
import Password from '../../components/customerArea/myAccount/Password';
import Deconnexion from '../../components/customerArea/myAccount/Deconnexion';

function MyAccount() {
  const { t } = useTranslation();
  const [updatePassword, setUpdatePassword] = useState(false);
  const [updateInformations, setUpdateInformations] = useState(false);
  const { client } = useSelector((s) => s);
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = () => {
    dispatch(unsetClient());
    history.push('/');
  };

  const submitPassword = async (
    values,
    { setSubmitting, setErrors, resetForm },
  ) => {
    setSubmitting(true);
    const { current_password, new_password } = values;
    const response = await api.put(`/clients/${client.id}`, {
      current_password,
      password: new_password,
    });
    if (response.id) {
      setUpdatePassword(true);
      resetForm({
        current_password: '',
        new_password: '',
        new_password_confirm: '',
      });
    } else {
      setErrors(client.errors);
    }
    setSubmitting(false);
  };

  const submitInformations = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    const response = await api.put(`/clients/${client.id}`, values);
    if (response.id) {
      dispatch(setClient(client));
      setUpdateInformations(true);
    } else {
      setErrors(client.errors);
    }
    setSubmitting(false);
  };
  return (
    <Container>
      <Container fluid pb={10} pt={4}>
        <Helmet title={t('account.meta.title')} />
        <Row md gap={56}>
          <AccountNav />
          <Box>
            <Heading level={2}>{t('myaccount')}</Heading>
            <Informations
              client={client}
              submitInformations={submitInformations}
              updateInformations={updateInformations}
            />
            <Separator axe="horizontal" bg="gray.lighter" margin="40px 0px" />
            <Password
              submitPassword={submitPassword}
              updatePassword={updatePassword}
            />
            <Separator axe="horizontal" bg="gray.lighter" margin="40px 0px" />
            <Deconnexion logout={logout} />
          </Box>
        </Row>
      </Container>
    </Container>
  );
}

export default MyAccount;
