import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 4.92946C9.46562 4.92946 4.40435 9.43073 4.40435 14.7143H2.70435C2.70435 8.25094 8.78728 3.22946 16 3.22946C23.2127 3.22946 29.2956 8.25094 29.2956 14.7143H27.5956C27.5956 9.43073 22.5343 4.92946 16 4.92946Z"
      fill="#161717"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.4456 19.15C28.9151 19.15 29.2956 19.5306 29.2956 20V21.0248C29.2956 22.7566 27.8917 24.1606 26.1599 24.1606H17C16.5306 24.1606 16.15 23.78 16.15 23.3106C16.15 22.8411 16.5306 22.4606 17 22.4606H26.1599C26.9528 22.4606 27.5956 21.8178 27.5956 21.0248V20C27.5956 19.5306 27.9762 19.15 28.4456 19.15Z"
      fill="#161717"
    />
    <path
      d="M26.1429 15.2857C26.1429 14.0233 27.1662 13 28.4286 13V13C29.691 13 30.7143 14.0233 30.7143 15.2857V18.7143C30.7143 19.9767 29.691 21 28.4286 21V21C27.1662 21 26.1429 19.9767 26.1429 18.7143V15.2857Z"
      fill="#5B9BFA"
    />
    <path
      d="M5.85712 15.2857C5.85712 14.0233 4.83377 13 3.5714 13V13C2.30904 13 1.28569 14.0233 1.28569 15.2857V18.7143C1.28569 19.9767 2.30904 21 3.5714 21V21C4.83377 21 5.85712 19.9767 5.85712 18.7143V15.2857Z"
      fill="#5B9BFA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8571 15.1501C10.6006 15.1501 10.3578 15.2659 10.1964 15.4653C10.035 15.6647 9.97233 15.9263 10.0258 16.1772C10.5996 18.8707 13.0796 20.8501 16 20.8501C18.9204 20.8501 21.4003 18.8707 21.9742 16.1772C22.0276 15.9263 21.9649 15.6647 21.8035 15.4653C21.6422 15.2659 21.3994 15.1501 21.1428 15.1501H10.8571ZM16 19.1501C14.258 19.1501 12.778 18.2007 12.0628 16.8501H19.9372C19.222 18.2007 17.7419 19.1501 16 19.1501Z"
      fill="#161717"
    />
    <path
      d="M19.1429 12.7072C18.8233 12.7072 18.5643 12.9663 18.5643 13.2858C18.5643 13.7552 18.1837 14.1358 17.7143 14.1358C17.2449 14.1358 16.8643 13.7552 16.8643 13.2858C16.8643 12.0274 17.8844 11.0072 19.1429 11.0072C20.4013 11.0072 21.4214 12.0274 21.4214 13.2858C21.4214 13.7552 21.0409 14.1358 20.5714 14.1358C20.102 14.1358 19.7214 13.7552 19.7214 13.2858C19.7214 12.9663 19.4624 12.7072 19.1429 12.7072Z"
      fill="#161717"
    />
    <path
      d="M12.2786 13.2858C12.2786 12.9663 12.5376 12.7072 12.8572 12.7072C13.1767 12.7072 13.4357 12.9663 13.4357 13.2858C13.4357 13.7552 13.8163 14.1358 14.2857 14.1358C14.7552 14.1358 15.1357 13.7552 15.1357 13.2858C15.1357 12.0274 14.1156 11.0072 12.8572 11.0072C11.5987 11.0072 10.5786 12.0274 10.5786 13.2858C10.5786 13.7552 10.9591 14.1358 11.4286 14.1358C11.898 14.1358 12.2786 13.7552 12.2786 13.2858Z"
      fill="#161717"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27 17C27 23.0751 22.0752 28 16.0001 28C9.92493 28 5.00006 23.0751 5.00006 17C5.00006 10.9249 9.92493 6 16.0001 6C22.0752 6 27 10.9249 27 17ZM25.3 17C25.3 22.1362 21.1363 26.3 16.0001 26.3C10.8638 26.3 6.70006 22.1362 6.70006 17C6.70006 11.8637 10.8638 7.7 16.0001 7.7C21.1363 7.7 25.3 11.8637 25.3 17Z"
      fill="#161717"
    />
    <rect
      x="13.7143"
      y="21.9621"
      width="4.57143"
      height="2.69689"
      rx="1.34844"
      fill="#5B9BFA"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = true;
const SvgSupport32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgSupport32;
