import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.003 35C5.98 29.385 0 25.295 0 19.855 0 14.414 4.48 10 10.003 10S20 14.414 20 19.855c0 5.44-5.98 9.53-9.997 15.145z"
      fill="#28D898"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 31a3 3 0 00-3 3v4a3 3 0 003 3h4a3 3 0 003-3v-4a3 3 0 00-3-3h-4zm4 2h-4a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 00-1-1z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47 24.783a6.253 6.253 0 003.005-5.347c0-1.03-.261-2.044-.758-2.947l-6.322-11.49H13.084L6.762 16.49a6.113 6.113 0 00-.757 2.947A6.253 6.253 0 009 24.777v12.068c0 1.922 0 3.426.099 4.634.1 1.23.308 2.24.773 3.153a8 8 0 003.496 3.496c.913.465 1.924.673 3.153.773 1.208.099 2.712.099 4.634.099h13.69c1.922 0 3.426 0 4.634-.099 1.23-.1 2.24-.308 3.153-.773a8 8 0 003.496-3.496c.465-.913.673-1.924.773-3.153.099-1.208.099-2.712.099-4.634V24.783zM8.763 17l5.503-10h27.477l5.503 10H8.763zm-.735 2a4.253 4.253 0 004.227 4.691 4.253 4.253 0 004.25-4.255V19H8.027zm10.476 0v.436a4.253 4.253 0 004.25 4.255 4.253 4.253 0 004.25-4.255V19h-8.5zm10.5 0v.436a4.253 4.253 0 004.25 4.255 4.253 4.253 0 004.25-4.255V19h-8.5zm10.5 0v.436a4.253 4.253 0 004.25 4.255A4.253 4.253 0 0047.981 19h-8.477zm4.25 6.691a6.243 6.243 0 01-5.25-2.86 6.243 6.243 0 01-5.25 2.86 6.243 6.243 0 01-5.25-2.86 6.243 6.243 0 01-5.25 2.86 6.243 6.243 0 01-5.25-2.86A6.243 6.243 0 0111 25.565V36.8c0 1.977 0 3.399.092 4.516.09 1.105.264 1.824.562 2.408a6 6 0 002.622 2.622c.584.298 1.303.472 2.408.562 1.117.091 2.54.092 4.516.092H29V36a6 6 0 1112 0v10.63c.269-.079.506-.173.724-.284a6 6 0 002.622-2.622c.298-.584.472-1.302.562-2.408.091-1.117.092-2.54.092-4.516V25.567a6.277 6.277 0 01-1.245.124zM34.8 47H31V36a4 4 0 118 0v10.93c-1.067.07-2.405.07-4.2.07z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgShopColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgShopColor56;
