import React from 'react';
import moment from 'moment';
import { defaultCourse } from '../reducers/course';
import { defaultClient } from '../reducers/client';
import ErrorLabel from '../components/blocks/ErrorLabel';
import SuccessLabel from '../components/blocks/SuccessLabel';
import InfoLabel from '../components/blocks/InfoLabel';
import { initialState } from '../reducers/diag';

export const PRODUCT = 'PRODUCT';
const POSITION = 'POSITION';
// const FIXED = 'FIXED';
const DATE = 'DATE';
const SYMPTOMS = 'SYMPTOMS';
const DETAILS = 'DETAILS';
const LOGIN = 'LOGIN';
const CHECKOUT = 'CHECKOUT';

/* STEPSDETAILS : steps elements are scenes in the funnel. They are ordered in this object */
const STEPSORDER = [
  PRODUCT,
  POSITION,
  // FIXED,
  SYMPTOMS,
  DATE,
  DETAILS,
  LOGIN,
  CHECKOUT,
];

const STEPSDETAILS = {
  PRODUCT: {
    path: '/',
    test: (store) => !!store?.course.product_id,
  },
  POSITION: {
    path: '/',
    test: (store) => !!store.course.position,
  },
  // FIXED: {
  //   path: '/fixed',
  //   test: (store) => store.course.fixed !== false,
  // },
  SYMPTOMS: {
    path: '/symptoms',
    test: (store) => !!store.diag.devis?.id,
  },
  DATE: {
    path: '/date',
    test: (store) => store.course.slots.length,
  },
  DETAILS: {
    path: '/details',
    test: (store) =>
      !!store.course.course_details || store.diag.questions.length,
  },
  LOGIN: {
    path: '/informations',
    test: (store) => !!store.client.id,
  },
  CHECKOUT: {
    path: '/checkout',
    test: () => false,
  },
};

export function stepId(step) {
  return STEPSORDER.indexOf(step);
}

function trimParameters(path) {
  if (path === '/') {
    return '/';
  }
  return `/${path.split('/')[1]}`;
}

export async function shouldRedirectToStep(step, state) {
  const idx = stepId(step);
  for (let i = 0; i < idx; i++) {
    const isValid = await STEPSDETAILS[STEPSORDER[i]].test(state);
    if (!isValid) return trimParameters(STEPSDETAILS[STEPSORDER[i]].path);
  }

  return false;
}

export function stepForPage(page) {
  let step = '/';

  const pageName = page.split('/').slice(-1)[0] || '';

  Object.keys(STEPSDETAILS).some((s) => {
    if (STEPSDETAILS[s].path === `/${pageName}`) {
      step = s;
      return true;
    }
    return false;
  });
  return step;
}

export function dumpStore(store) {
  try {
    const storableStore = {
      course: store.course,
      client: { id: false },
      diag: store.diag,
      lang: store.lang,
    };

    if (store.client.id) storableStore.client.id = store.client.id;
    if (store.client.token) storableStore.client.token = store.client.token;

    const serializedStore = JSON.stringify(storableStore);
    localStorage.setItem('state', serializedStore);
  } catch (err) {
    //
  }
}

export function loadStore() {
  try {
    const serializedStore = localStorage.getItem('state');
    if (serializedStore === null) return undefined;
    /* we get defaultcourse and merge stored store */
    const storedDiag = Array.isArray(JSON.parse(serializedStore).diag)
      ? {}
      : JSON.parse(serializedStore).diag;
    return {
      course: {
        ...defaultCourse,
        ...JSON.parse(serializedStore).course,
        photos: [],
        positionLoading: false,
      },
      client: { ...defaultClient, ...JSON.parse(serializedStore).client },
      diag: { ...initialState, ...storedDiag },
      lang: JSON.parse(serializedStore).lang,
    };
  } catch (err) {
    return undefined;
  }
}

export async function firstInvalidStep(store) {
  const invalidStep = STEPSORDER[0];

  for (let i = 0; i <= STEPSORDER.length - 1; i++) {
    const isValid = await STEPSDETAILS[STEPSORDER[i]].test(store);
    if (!isValid) return STEPSORDER[i];
  }

  return invalidStep;
}

export async function resumeReparation(store, router, forceDetails = false) {
  let redirect = await firstInvalidStep(store);
  if (forceDetails && (redirect === CHECKOUT || redirect === LOGIN)) {
    redirect = DETAILS;
  }
  if (redirect === DETAILS) {
    redirect = DATE;
  }

  router.push(`/reparation${STEPSDETAILS[redirect].path}`);
}

export function readableStatus(course) {
  switch (course.status) {
    case 'TERMINE':
      return <SuccessLabel>Terminée</SuccessLabel>;
    case 'ANNULE':
      return <ErrorLabel>Annulée</ErrorLabel>;
    default:
      if (course.fixer_id) return <InfoLabel>Intervention à venir</InfoLabel>;
      return <InfoLabel>En attente</InfoLabel>;
  }
}
export function readableDate(date) {
  return moment(date).format('dddd DD MMMM YYYY');
}
export function readableDateChat(date) {
  return moment(date).format('dddd DD/MM');
}
export function formatDate(date, format) {
  return moment(date).format(format);
}
export function readableHour(date) {
  return moment(date).format('LT');
}
export function readableZeroedHour(hour) {
  return moment().hour(hour).minute(0).format('LT');
}
export function readableHourWithAddTime(date, time, value) {
  return moment(date).add(time, value).format('HH[h]mm');
}
export function readableDatetime(date) {
  return moment(date).format('dddd DD MMMM YYYY HH[h]mm');
}
