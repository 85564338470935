import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M8.001 4.85a.65.65 0 01.65.65v3a.65.65 0 01-1.3 0v-3a.65.65 0 01.65-.65zM8.748 10.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.41 2.99c1.158-1.986 4.027-1.986 5.184 0l3.998 6.86c1.166 2-.277 4.51-2.592 4.51H4.005c-2.315 0-3.758-2.51-2.592-4.51L5.41 2.99zm3.888.755l3.998 6.86A1.5 1.5 0 0112 12.86H4.005a1.5 1.5 0 01-1.296-2.256l3.997-6.86a1.5 1.5 0 012.592 0z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgDanger16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgDanger16;
