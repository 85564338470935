/* eslint-disable no-alert */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import BaseBlock from '../../components/blocks/BaseBlock';
import CardPicker from '../../components/blocks/CardPicker';
import ContainerSection from '../../components/blocks/ContainerSection';
import { Row } from '../../components/blocks/Grid';
import { Dropzone } from '../../components/Dropzone';
import InfoText from '../../components/InfoText';
import * as api from '../../utils/api';
import constants from '../../utils/constants';

const SubscribePay = ({ client, ...props }) => {
  const { t } = useTranslation();
  const [photos, setPhotos] = useState([]);
  if (!client.id)
    return <Redirect to={'/login?redir=/abonnement/inscription'} />;

  const _return = async (pm_id) => {
    if (!photos.length) return alert(t('subscribepay.alert.1'));

    const imgUploaded = await api.postImage(
      `/clients/${client.id}/subscriptions/photo`,
      photos[0],
    );
    if (imgUploaded.error_message) return alert(t('subscribepay.alert.2'));

    const ret = await api.post(`/clients/${client.id}/subscriptions`, {
      pm_id,
    });

    if (typeof ret.error_message !== 'undefined') {
      alert(ret.error_message);
    } else {
      props.history.push('/abonnement/confirmation');
    }
  };

  return (
    <div>
      <Helmet
        title={t('subscribepay.meta')}
        meta={[
          {
            name: 'description',
            content: t('subscribepay.meta'),
          },
        ]}
      />
      <ContainerSection>
        <h1>{t('subscribepay.headings.1')}</h1>
        <Row xs>
          <Dropzone
            photos={photos}
            setPhotos={(arr) =>
              setPhotos(arr.length ? [arr[arr.length - 1]] : [])
            }
            text={t('subscribepay.texts.1')}
            limit={1}
          />
          <div></div>
        </Row>
        <Row sm gutter={50}>
          <div>
            <CardPicker onSuccess={_return} />
          </div>
          <div>
            <BaseBlock>
              <ul>
                <li>
                  {' '}
                  <img
                    src={`${constants.CDN_BASE_URL}/img/pictos/check.png`}
                    height={9}
                    style={{ marginRight: 10 }}
                  />{' '}
                  {t('subscribepay.texts.2')}
                </li>
                <li>
                  <img
                    src={`${constants.CDN_BASE_URL}/img/pictos/check.png`}
                    height={9}
                    style={{ marginRight: 10 }}
                  />{' '}
                  {t('subscribepay.texts.3')}
                  <br />
                  <InfoText style={{ marginLeft: 23 }}>
                    {t('subscribepay.texts.4')}
                  </InfoText>
                </li>
                <li>
                  <img
                    src={`${constants.CDN_BASE_URL}/img/pictos/check.png`}
                    height={9}
                    style={{ marginRight: 10 }}
                  />{' '}
                  {t('subscribepay.texts.5')}
                </li>
              </ul>
            </BaseBlock>
          </div>
        </Row>
      </ContainerSection>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    client: state.client,
  };
};

export default connect(mapStateToProps, null)(SubscribePay);
