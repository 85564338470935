import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M11 16a1 1 0 112 0 1 1 0 01-2 0zM11.184 9.973v.003a.85.85 0 01-1.696.11l.848-.058-.848.057v-.008l-.002-.01v-.03a3.537 3.537 0 01.219-1.329c.136-.354.37-.753.771-1.064.411-.318.936-.494 1.562-.494.609 0 1.227.194 1.704.618.491.436.775 1.065.775 1.803 0 .751-.298 1.313-.616 1.697a3.234 3.234 0 01-.446.44 8.111 8.111 0 01-.248.19c-.183.152-.232.224-.265.3-.044.104-.096.312-.096.802a.85.85 0 01-1.7 0c0-.571.054-1.053.234-1.473.191-.444.484-.726.762-.955l.009-.008.01-.007c.024-.02.05-.039.075-.058.265-.202.58-.443.58-.928 0-.281-.096-.438-.203-.532-.12-.107-.316-.189-.575-.189-.294 0-.442.077-.522.14a.742.742 0 00-.226.33 1.706 1.706 0 00-.107.618v.035z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 21l.936-5A9 9 0 118 20.064L3 21zm5.29-3.025l.6.298a7 7 0 10-3.164-3.164l.299.6-.522 2.788 2.787-.522z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgQuestion24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgQuestion24;
