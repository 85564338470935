/* eslint-disable prefer-destructuring */
import i18n from '../i18n';
import { prioSort } from '../utils/utils';
import strapiDevisElement from './StrapiDevisElement';

const strapiDevis = ({
  content,
  content_en,
  id,
  name,
  name_en,
  quote_elements,
  capabilities,
}) => {
  const lang = i18n.language;

  // const answers = devis ? devis.data.attributes.answers.data : null;
  // const capabilities = devis ? devis.data.attributes.capabilities.data : null;

  const getName = () => {
    return name;
  };
  const getContent = () => {
    return content;
  };
  const getDevisElements = () => {
    const elements = quote_elements.map((devisElement) => {
      return strapiDevisElement(devisElement);
    });
    return elements;
  };

  const devisElementsSorted = () => {
    const sorted = getDevisElements(quote_elements).sort(prioSort);
    return sorted;
  };

  return {
    Contenu: getContent(),
    Nom: getName(),
    content,
    content_en,
    id,
    name,
    name_en,
    quote_elements,
    getName,
    getDevisElements,
    devisElementsSorted,
    capabilities,
  };
};

export default strapiDevis;
