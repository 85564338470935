import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Box from '../shared/atoms/Box';
import Text from '../shared/atoms/Text';
import Button from '../shared/atoms/Button';
import Dropdown from '../Dropdown';

const ContainerBox = styled(Box)`
  border: 1px solid ${(p) => p.theme.colors.gray.lightest};
  position: relative;
  flex: 2;
  min-width: 300px;
  height: 100%;
  bottom: 140px;
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    bottom: -24px;
    min-width: auto !important;
  }
`;

export default function ReservationBox({
  createAppointment,
  product,
  setProduct,
  products = [],
}) {
  const { t } = useTranslation();
  const value = products.filter((p) => p.id == product);
  return (
    <ContainerBox
      p={5}
      borderRadius="xs"
      boxShadow="sm"
      ml={{ xs: 0, md: 4 }}
      mr={{ xs: 0, md: 5 }}
      bg="white">
      <Text color="black" fontWeight="bold" mb={4}>
        {t('standalone.reservation.title')}
      </Text>
      <Box>
        <Dropdown
          value={value[0] ? value[0].name : ''}
          onChange={(value) => setProduct(value)}
          options={products.map((product) => {
            return { value: product.id, label: product.name };
          })}
        />
      </Box>
      <Button fullWidth onClick={createAppointment}>
        {t('cta.book')}
      </Button>
    </ContainerBox>
  );
}
