import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M9.5 17a6.5 6.5 0 100-13 6.5 6.5 0 000 13z" fill="#FFE249" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 7.75h1.505l1.816 4.236L2.43 21.75h11.716a2 2 0 002.636 1.092l1.408 1.408H17.5a.75.75 0 000 1.5h4a.75.75 0 000-1.5h-1.19l-2.468-2.469a1.994 1.994 0 00.095-1.281l6.121-7.394 4.268 8.724a.75.75 0 101.348-.66L24.75 11.106V8a.747.747 0 00-.043-.25h3.171c.13 0 .232.047.294.103.062.055.078.108.078.147 0 .04-.016.092-.078.147a.442.442 0 01-.294.103.75.75 0 100 1.5c.95 0 1.872-.705 1.872-1.75s-.921-1.75-1.872-1.75H23a.75.75 0 000 1.5h.293a.747.747 0 00-.043.25v3.25H11.637l-1.5-3.5H12a.75.75 0 000-1.5H9.017a.75.75 0 00-.034 0H7a.75.75 0 000 1.5zm8.06 11.485a1.99 1.99 0 011.947.037l5.398-6.522H12.28l2.78 6.485zm-4.07-5.69l2.873 6.705H5.57l5.418-6.704z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgBikeFrameColor32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgBikeFrameColor32;
