import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.502 17.267C8.49 14.512 5.5 12.505 5.5 9.835 5.5 7.165 7.74 5 10.502 5 13.262 5 15.5 7.166 15.5 9.835c0 2.67-2.99 4.677-4.998 7.432z"
      fill="#28D898"
    />
    <path
      d="M21.75 13.7a.9.9 0 01.9.9v2a.9.9 0 11-1.8 0v-2a.9.9 0 01.9-.9zM22.65 8.2a.9.9 0 10-1.8 0v2a.9.9 0 101.8 0v-2zM21.75 20.1a.9.9 0 01.9.9v2a.9.9 0 01-1.8 0v-2a.9.9 0 01.9-.9zM26.15 14.6a.9.9 0 00-1.8 0v2a.9.9 0 101.8 0v-2zM25.25 7.3a.9.9 0 01.9.9v2a.9.9 0 11-1.8 0v-2a.9.9 0 01.9-.9zM26.15 21a.9.9 0 10-1.8 0v2a.9.9 0 001.8 0v-2z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 3h12v26h-12v-8h-7v7a1 1 0 01-2 0v-7h-5v-9h14V3zm0 11v5h-12v-5h12zm2 13V5h8v22h-8z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgBuildingColor32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgBuildingColor32;
