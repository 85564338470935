import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47 13.766S33.371 11.246 27.977 6C22.437 11.389 9 13.766 9 13.766c0 15.452.898 30.222 18.478 36.149a1.631 1.631 0 001.042 0C46.101 43.988 47 29.219 47 13.765zM11.004 15.4a71.459 71.459 0 006.004-1.588c3.518-1.1 7.839-2.78 10.973-5.183 3.09 2.346 7.408 4.02 10.93 5.129a75.31 75.31 0 006.085 1.632c-.04 7.141-.398 13.756-2.512 19.311-2.2 5.778-6.339 10.5-14.485 13.278-8.146-2.778-12.285-7.5-14.483-13.278-2.113-5.553-2.472-12.164-2.512-19.3zm16.877 32.62z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.356 43.94C40.385 39.764 41 29.359 41 18.47c0 0-9.298-1.77-13-5.455L27.985 13a2.929 2.929 0 01-.018.018C24.166 16.802 15 18.47 15 18.47c0 10.887.614 21.293 12.643 25.469a1.087 1.087 0 00.713 0zm-11.35-23.855a48.41 48.41 0 003.678-1.022c2.272-.732 5.108-1.848 7.305-3.452 2.171 1.568 5.002 2.678 7.27 3.413 1.446.47 2.769.818 3.735 1.051-.04 4.847-.314 9.256-1.681 12.954-1.429 3.865-4.078 7.016-9.314 8.915-5.235-1.899-7.884-5.05-9.312-8.915-1.366-3.696-1.64-8.101-1.68-12.944zM28.3 42.05z"
      fill="#000"
    />
    <path
      d="M37.257 46C44.847 46 51 39.847 51 32.257c0-7.59-6.153-13.743-13.743-13.743-7.59 0-13.743 6.153-13.743 13.743 0 7.59 6.153 13.743 13.743 13.743z"
      fill="#28D898"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.012 26.882c.69.67.704 1.773.033 2.463l-9.492 9.753-2.491.005-4.442-4.528a1.742 1.742 0 012.487-2.44l3.193 3.256 8.25-8.476a1.742 1.742 0 012.462-.033z"
      fill="#fff"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgWarrantyColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgWarrantyColor56;
