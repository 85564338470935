import React from 'react';
import { useSelector } from 'react-redux';

import Box from '../../components/shared/atoms/Box';
import Heading from '../../components/shared/atoms/Heading';

function RepairConfirmation({ ...props }) {
  const client = useSelector((state) => state.client);

  return (
    <Box>
      <Heading>Votre rendez-vous est confirmé</Heading>
      <Box>
        Vous recevrez un récapitulatif des informations par email à{' '}
        {client.email}.
      </Box>
      <Box mt={1}>
        <p>
          Pour toute demande de modification ou annulation, veuillez suivre les
          instructions indiquée dans l'email ou accéder directement à{' '}
          <a href={'https://www.roulezjeunesse.com/account'}>roulezjeunesse</a>.
        </p>
      </Box>
    </Box>
  );
}

export default RepairConfirmation;
