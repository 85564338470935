import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.008 38.488A18.913 18.913 0 0123 42C12.507 42 4 33.493 4 23S12.507 4 23 4s19 8.507 19 19c0 3.946-1.203 7.611-3.262 10.648l11.19 8.538c2.656 2.026 2.919 5.933.559 8.297-2.403 2.409-6.387 2.084-8.37-.682l-8.11-11.313zm1.93-4.46A16.962 16.962 0 0123 40c-9.389 0-17-7.611-17-17S13.611 6 23 6s17 7.611 17 17a16.92 16.92 0 01-2.855 9.433l-.013-.01-1.21 1.593.016.012zm1.592 1.215a19.128 19.128 0 01-1.955 2l8.164 11.39a3.507 3.507 0 005.335.435 3.524 3.524 0 00-.356-5.289L37.53 35.243z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.77 18.9L24.86 11.61l-10.77 4.067-1.86 11.355 8.912 7.289 10.77-4.067 1.86-11.355zm-11.516 8.608a4.604 4.604 0 005.29-3.798 4.604 4.604 0 00-3.803-5.286 4.604 4.604 0 00-5.289 3.798 4.604 4.604 0 003.802 5.286z"
      fill="#B0BDFF"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgMagnifyingglassColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgMagnifyingglassColor56;
