import React, { createContext, useState } from 'react';
import Modal from '../modals/Modal';
// Components
import Box from '../components/shared/atoms/Box';
import Heading from '../components/shared/atoms/Heading';
import Link from '../components/shared/atoms/Link';
import Button from '../components/shared/atoms/Button';
// Icons
import SvgAngleRight16 from '../components/icons/AngleRight16';

export const ModalCtx = createContext({});

const ModalProvider = ({ children }) => {
  const [showModal, setShowModalState] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [showList, setShowList] = useState(false);
  const [showButton, setShowButton] = useState([]);
  const [width, setWidth] = useState([]);
  const [icon, setIcon] = useState(false);
  const [onClose, setOnClose] = useState(null);

  const setShowModal = (val) => {
    setShowModalState(val);
    if (!val && onClose) {
      onClose();
    }
  };

  const showAlert = ({
    title,
    content,
    showModal,
    showList,
    showButton,
    width = 'small',
    icon = false,
    onClose = null,
  }) => {
    setWidth(width);
    setTitle(title);
    setContent(content);
    setShowList(showList);
    setShowButton(showButton);
    setShowModal(showModal);
    setIcon(icon);
    setOnClose(onClose);
  };

  const handleChange = (onClick) => {
    if (onClick) {
      onClick();
    }
    setShowModal(false);
  };

  return (
    <ModalCtx.Provider value={{ showAlert, setShowModal }}>
      <Modal
        zIndex="1002"
        width={width}
        hasCloseAction={true}
        open={showModal}
        onRequestClose={() => setShowModal(false)}>
        {icon && <Box mb={4}>{icon}</Box>}
        <Heading mb={4} level={5} data-testid="title-modal-context">
          {title}
        </Heading>
        {content}&nbsp;
        {showList && (
          <Link after={<SvgAngleRight16 />} to="/reparateur-a-domicile">
            <b>Découvrir la liste</b>
          </Link>
        )}
        {showButton && (
          <Box
            alignItems="flex-end"
            justifyContent="flex-end"
            flexDirection="row"
            mt={5}>
            {showButton.map((button, index) => (
              <Button
                size="compact"
                ml={4}
                key={index}
                disabled={button.disabled || false}
                color={button.color}
                variant={button.variant ? button.variant : 'filled'}
                data-testid={button.datatestid || ''}
                onClick={() => handleChange(button.onClick)}
                style={{ alignSelf: 'flex-end', width: button.width }}>
                {button.text}
              </Button>
            ))}
          </Box>
        )}
      </Modal>
      {children}
    </ModalCtx.Provider>
  );
};

export default ModalProvider;
