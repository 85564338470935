import React from 'react';
import { useTranslation } from 'react-i18next';
import Separator from '../Separator';
import Box from '../shared/atoms/Box';
import Heading from '../shared/atoms/Heading';
import Text from '../shared/atoms/Text';
import OpenHours from './OpenHours';
import Link from '../shared/atoms/Link';
// Icons
import Phone24 from '../icons/Phone24';
import Message24 from '../icons/Message24';
import Dropdown from '../Dropdown';
import Button from '../shared/atoms/Button';

const DisplayInformation = ({ icon, text, value, linkText, url }) => (
  <Box flexDirection={{ xs: 'column', md: 'row' }} mb={4}>
    <Box flex={1} flexDirection="row" mb={{ xs: 2, md: 0 }}>
      {icon}
      <Text ml={2} variant="medium" color="gray.darker">
        {text}
      </Text>
    </Box>
    <Box flex={3} flexDirection="row">
      <Text color="black" fontWeight="bold">
        {value}
      </Text>
      <Box flexDirection="row">
        <Text mx={1}>•</Text>
        <Link color="blue.electric" href={url}>
          {linkText}
        </Link>
      </Box>
    </Box>
  </Box>
);

export default function InformationsWorkshop({
  details,
  phone,
  website,
  email,
  bookable,
  loading,
  products = [],
  product,
  note,
  setProduct,
  createAppointment,
}) {
  const { open_hours } = details ? details[0] : {};
  const { t } = useTranslation();
  const regex = new RegExp('^https?://');
  // const addHtml = regex.test(website);

  const value = products.filter((p) => p.id == product);
  return (
    <Box mt={6} mb={6}>
      <Box flexDirection={{ xs: 'column', md: 'row' }} mb={6}>
        <Box flex={2} mb={{ xs: 4, md: 0 }} mr={4}>
          <Heading level={5} mb={4}>
            {t('standalone.informations.workshop.title.1')}
          </Heading>
          <OpenHours open_hours={open_hours} loading={loading} />
        </Box>
        <Box flex={3}>
          <Heading level={5} mb={4}>
            {t('standalone.informations.workshop.title.2')}
          </Heading>
          <Box flexDirection="row">
            <Separator
              axe="vertical"
              width="6px"
              m={0}
              color="gray.lightest"
              style={{ height: 'inherit' }}
            />
            <Text ml={4}>{note || t('chat.nomessages')}</Text>
          </Box>
        </Box>
      </Box>
      <Separator axe="horizontal" my={2} />
      <Box mt={6} flexDirection={{ xs: 'column', md: 'row' }}>
        {!!bookable && (
          <Box bg="gray.lightest" borderRadius="xs" p={5} flex={2} mr={4}>
            <Text color="black" fontWeight="bold" mb={4}>
              {t('standalone.reservation.title')}
            </Text>
            <Box style={{ minWidth: '200px' }}>
              <Dropdown
                bg="#F1F5F4"
                value={value[0] ? value[0].name : ''}
                onChange={(value) => setProduct(value)}
                options={products.map((product) => {
                  return { value: product.id, label: product.name };
                })}
              />
            </Box>
            <Button fullWidth onClick={createAppointment}>
              {t('cta.book')}
            </Button>
          </Box>
        )}
        <Box flex={6} mt={{ xs: 5, md: 0 }}>
          <Box>
            <Heading level={5} mb={4}>
              {t('standalone.informations.workshop.title.3')}
            </Heading>
            {phone && (
              <DisplayInformation
                icon={<Phone24 color="gray.darker" />}
                text={t('phone')}
                linkText={t('standalone.informations.workshop.cta.1')}
                value={phone}
                url={`tel:${phone}`}
              />
            )}
            {email && (
              <DisplayInformation
                icon={<Message24 color="gray.darker" />}
                text={t('E-mail')}
                linkText={t('standalone.informations.workshop.cta.2')}
                value={email}
                url={`mailto:${email}`}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
