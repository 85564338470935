import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// Compoenents
import Box from './Box';
import Text from './Text';

const StyledBox = styled(Box)`
  display: block;
  border-bottom: 2px solid
    ${(p) =>
      p.selected ? p.theme.colors.primary.main : p.theme.colors.gray.main};
  cursor: pointer;
  &:hover > p {
    transition: border-bottom 0.3s ease;
    color: ${(p) => p.theme.colors.primary.main};
  }
`;

const MenuTab = ({ value, onChange, selected, ...rest }) => (
  <Box flexDirection="row" {...rest}>
    {value.map((element) => (
      <StyledBox
        key={element.value}
        pb={3}
        onClick={() => onChange(element.value)}
        selected={selected === element.value}>
        <Text
          fontWeight="600"
          px={5}
          color={selected === element.value ? 'primary.main' : 'gray.darkest'}>
          {element.text}
        </Text>
      </StyledBox>
    ))}
  </Box>
);

MenuTab.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  selected: PropTypes.string,
};

export default MenuTab;
