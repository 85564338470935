import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { colors, device } from '../styles/constants.jsx';
import Spinner from './Spinner';

const FakeFloat = styled.div`
  &&& {
    display: flex;
    flex: none;
    justify-content: flex-end;
    ${(p) =>
      p.left &&
      css`
        justify-content: flex-start;
      `}
    ${(p) =>
      p.centered &&
      css`
        justify-content: center;
      `}
  }
`;

const ButtonWrapper = styled.button`
  background-color: white;
  min-width: 220px;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
  font-weight: 500;
  padding: 10px 30px;
  text-align: center;
  ${(props) => !!props.fullwidth && `width:100%;`}
  border-radius: ${({ isRounded }) => (isRounded ? '25px' : '4px')};
  @media ${device.sm} {
    width: 100%;
    text-align:center;
  }
  &, &:active, &:focus{
  border-radius: ${({ isRounded }) => (isRounded ? '25px' : '4px')};
  border: none;
  outline: none !important;
  font-weight: 100;
  padding: 0;
  font-size: 1.3em;
  line-height: 1.3em;
  font-weight: 500;
  border-radius: 4px;
  padding: 10px 30px;
  cursor: pointer;
      ${(p) =>
        !p.inline &&
        css`
          float: right;
        `}
          
      ${(p) =>
        !!p.default &&
        css`
          background-color: white;
          color: ${colors.blue} !important;
          border: 1px solid ${colors.lightgrey};
        `}

      ${(p) =>
        !!p.secondary &&
        css`
          background-color: ${colors.blue};
          color: #314959 !important;
        `}  
      ${(props) =>
        !!props.main &&
        css`
          background-color: ${colors.orange};
          color: #314959 !important;
        `}
      ${(props) =>
        !!props.ternary &&
        css`
          background-color: ${colors.green};
          color: white;
        `}
        ${(props) =>
          !!props.stacked &&
          css`
            margin-top: 10px;
          `}
        
      ${(props) =>
        !!props.disabled &&
        !props.noDisabledStyle &&
        css`
          background-color: ${colors.lightgrey};
          color: #6c7275 !important;
          cursor: not-allowed;
        `}
  }
  ${(p) =>
    !p.inline &&
    css`
      float: right;
    `}
  ${(p) =>
    !!p.default &&
    css`
      background-color: white;
      color: ${colors.blue} !important;
      border: 1px solid ${colors.lightgrey} !important;
    `}
  ${(p) =>
    !!p.main &&
    css`
      background-color: ${colors.orange};
      color: #314959 !important;
    `}
  ${(p) =>
    !!p.secondary &&
    css`
      background-color: ${colors.blue};
      color: #314959 !important;
    `}
  ${(props) =>
    !!props.ternary &&
    css`
      background-color: ${colors.green};
      color: white;
    `}
  ${(props) =>
    !!props.stacked &&
    css`
      margin-top: 10px;
    `}
  ${(props) =>
    !!props.disabled &&
    !props.noDisabledStyle &&
    css`
      background-color: ${colors.lightgrey};
      color: #6c7275 !important;
      cursor: not-allowed;
    `}
  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        opacity: 0.8;
      }
    `}
  }
`;

function ButtonLinkWrapper({ to, ...rest }) {
  return to ? (
    <Link to={to}>
      <BaseButton {...rest} />
    </Link>
  ) : (
    <BaseButton {...rest} />
  );
}

function BaseButton({
  children,
  loading,
  default: isDefault,
  left = false,
  nofloat = false,
  centered = false,
  main = false,
  secondary = false,
  ternary = false,
  stacked,
  inline,
  disabled,
  fullwidth,
  href,
  onClick,
  style,
  rounded = false,
}) {
  const tag = href ? 'a' : 'button';
  const uri = href || undefined;
  const type = href ? undefined : 'submit';
  return (
    <FakeFloat nofloat={nofloat} left={left} centered={centered}>
      <ButtonWrapper
        as={tag}
        type={type}
        main={main}
        href={uri}
        stacked={stacked}
        default={isDefault}
        secondary={secondary}
        inline={inline}
        ternary={ternary}
        onClick={onClick}
        fullwidth={fullwidth}
        isRounded={rounded}
        disabled={!!disabled || !!loading}
        style={style}>
        {loading ? <Spinner small grey /> : children}
      </ButtonWrapper>
    </FakeFloat>
  );
}

BaseButton.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  left: PropTypes.bool,
  nofloat: PropTypes.bool,
  centered: PropTypes.bool,
  main: PropTypes.bool,
  default: PropTypes.bool,
  secondary: PropTypes.bool,
  ternary: PropTypes.bool,
  stacked: PropTypes.bool,
  disabled: PropTypes.bool,
  to: PropTypes.string,
  href: PropTypes.string,
};

export default ButtonLinkWrapper;
