import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { testFileType } from '../../../utils/utils';
import Box from '../atoms/Box';
import Text from '../atoms/Text';

import PicturePlus32 from '../../icons/PicturePlus32';
import Picture32 from '../../icons/Picture32';
import Trash24 from '../../icons/Trash24';

import useDeviceWidth from '../../../hooks/useDeviceWidth';

const GridPhotoWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  .uploaded-photos-container {
    height: 10rem;
    position: relative;
    overflow: hidden;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
    button {
      cursor: pointer;
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
      padding: 0.5rem;
      border: none;
      background-color: white;
      border-radius: 8px;
    }
  }
  ${(p) => p.theme.mediaQueries.tablet} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Zone = styled(Box)`
  z-index: 1001;
  grid-column: 1 / 3;
  ${(p) => p.theme.mediaQueries.tablet} {
    grid-column: auto;
  }
  opacity: ${(props) => (props.dragging ? 0.5 : 1)};
  :hover {
    cursor: pointer;
  }
`;

const getArray = (mobile, max) => {
  const col = mobile ? 2 : 4;
  const rest = mobile ? 4 : 3;
  let length;
  if (max >= rest) {
    length = col - (max % rest);
  } else {
    length = rest - max < 0 ? 0 : rest - max;
  }
  const ret = Array.from({ length }, (_, i) => i + 1);
  return ret;
};

const Dropzone = ({ setPhotos, photos, ...rest }) => {
  const [photosDisplayable, setPhotosDisplayable] = useState([]);
  const fileInputRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const { isMobile } = useDeviceWidth();
  const { t } = useTranslation();
  const placeholders = getArray(isMobile, photosDisplayable.length);

  const _saveFileList = async (arr) => {
    const newPhotos = [...photos];

    for (let i = 0; i < arr.length; i++) {
      if (!testFileType(arr[i].name)) {
        alert(t('dropzone.texts.1'));
        continue;
      }

      if (arr[i].size > 8 * 1e6) {
        alert(t('dropzone.texts.2'));
        continue;
      }
      newPhotos.push(arr[i]);
    }

    setPhotos(newPhotos);
  };
  const _onFilesAdded = (evt) => {
    const { files } = evt.target;
    _saveFileList(files);
  };

  const _popPhoto = async (event, index) => {
    event.preventDefault();
    const newPhotos = photos.filter((_, i) => i !== index);
    setPhotos(newPhotos);
  };

  const _readFile = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (evt) => {
        resolve(evt.target.result);
      };
      reader.readAsDataURL(file);
    });
  };

  const openFileDialog = () => fileInputRef.current.click();
  const onDragOver = (e) => e.preventDefault() || setDragging(true);
  const onDragLeave = (e) => e.preventDefault() || setDragging(false);

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    _saveFileList(e.dataTransfer.files);
    setDragging(false);
  };

  useEffect(() => {
    const displayPhotosRelatedAndNew = async () => {
      const newPhotosDisplayable = await Promise.all(
        photos.map(async (photo) => await _readFile(photo)),
      );
      setPhotosDisplayable(newPhotosDisplayable);
    };
    displayPhotosRelatedAndNew();
  }, [photos]);

  return (
    <Box {...rest}>
      <input
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
        multiple
        onChange={_onFilesAdded}
      />
      <GridPhotoWrapper>
        <Zone
          bg="blue.electric"
          p={4}
          borderRadius="xs"
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          onClick={openFileDialog}
          dragging={dragging}
          justifyContent="center"
          alignItems="center"
          flexDirection={{ xs: 'row', md: 'column' }}
          height={{ xs: 'auto', md: '10rem' }}>
          <div>
            <PicturePlus32
              color="white"
              mb={{ xs: 0, md: 2 }}
              mr={{ xs: 2, md: 0 }}
            />
          </div>
          <Text
            color="white"
            textAlign="center"
            variant="small"
            fontWeight="bold">
            <b>{t('newdropzone.texts.1')}</b>
          </Text>
        </Zone>
        {photosDisplayable.map((p, i) => (
          <Box borderRadius="xs" className="uploaded-photos-container" key={i}>
            <img src={p} />
            <button onClick={(e) => _popPhoto(e, i)}>
              <Trash24 $pointer />
            </button>
          </Box>
        ))}
        {placeholders.map((i) => (
          <Box
            key={i}
            height="10rem"
            borderRadius="xs"
            border="1px dashed"
            borderColor="gray.dark"
            justifyContent="center"
            alignItems="center"
            p={{ xs: '10px', md: 4 }}>
            <div>
              <Picture32 color="gray.dark" mb={2} />
            </div>
            <Text
              color="gray.dark"
              textAlign="center"
              variant="small"
              fontWeight="bold">
              <b>
                {t('newdropzone.texts.2')}
                {photosDisplayable.length + i}
              </b>
            </Text>
          </Box>
        ))}
      </GridPhotoWrapper>
    </Box>
  );
};

export default Dropzone;
