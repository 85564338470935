import React from 'react';
import Footer from './Footer.jsx';
import Header from './Header.jsx';

export default function HeaderFooter({
  color,
  noMenu = false,
  withCP = false,
  partenaire,
  children,
  location: { pathname },
}) {
  const noFooter =
    pathname.includes('/reparation') && !pathname.includes('/entreprise');
  return (
    <div>
      <Header color={color} noMenu={noMenu} withCP={withCP} />
      {children}
      {noFooter ? null : <Footer />}
    </div>
  );
}
