import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M23.2 18.862a.208.208 0 01-.2-.205v-1.483a1 1 0 00-1.65-.759l-4.464 3.826a1 1 0 000 1.518l4.463 3.826a1 1 0 001.651-.76v-1.503c0-.102.077-.188.18-.193C25.768 23 28.161 26.199 30 28c0-3.721-3.026-8.958-6.8-9.138z"
      fill="#8A38F3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.716 4.25c-1.371 0-2.447 0-3.311.07-.88.073-1.607.221-2.265.557A5.75 5.75 0 002.627 7.39c-.336.658-.484 1.385-.556 2.265C2 10.519 2 11.595 2 12.966v3.068c0 1.371 0 2.447.07 3.311.073.88.221 1.607.557 2.265a5.75 5.75 0 002.513 2.513c.658.336 1.385.485 2.265.556.864.071 1.94.071 3.311.071H16a.75.75 0 000-1.5h-5.25c-1.413 0-2.427 0-3.223-.066-.787-.064-1.295-.188-1.706-.397a4.25 4.25 0 01-1.858-1.857c-.21-.412-.333-.92-.397-1.707C3.5 18.427 3.5 17.413 3.5 16v-3c0-1.164 0-2.057.037-2.78L15 14.04l10.97-3.656c.03.694.03 1.539.03 2.616v4.25a.75.75 0 001.5 0v-4.284c0-1.371 0-2.447-.07-3.311-.072-.88-.221-1.607-.557-2.265a5.75 5.75 0 00-2.513-2.513c-.658-.336-1.385-.484-2.265-.556-.864-.071-1.94-.071-3.311-.071h-8.068zm15.09 4.607a3.2 3.2 0 00-.27-.786 4.25 4.25 0 00-1.857-1.858c-.411-.21-.919-.333-1.706-.397-.796-.065-1.81-.066-3.223-.066h-8c-1.413 0-2.427 0-3.223.066-.787.064-1.295.188-1.706.397a4.25 4.25 0 00-1.858 1.858c-.094.185-.171.39-.233.632L15 12.459l10.807-3.602z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgAnswer32Color = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgAnswer32Color;
