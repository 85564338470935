import React from 'react';
import styled, { css } from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import {
  variant as systemVariant,
  space,
  flexbox,
  position,
} from 'styled-system';
import propTypes from '@styled-system/prop-types';
import { colors } from '../../../styles/theme';
import Spinner from '../../Spinner';
import Box from './Box';
import { analyticsTrack, lightenDarken } from '../../../utils/utils';

const buttonSize = systemVariant({
  prop: 'size',
  key: 'buttonSizes',
});

const Button = styled('button')`
  cursor: pointer;
  border-radius: 50px;
  border: none;
  transition: color 0.2s, background-color 0.2s, box-shadow 0.2s, border 0.2s ease;
  font-size: 1em;
  padding: ${(p) => (p.inline ? '0' : '18px 24px')};
  outline: none;
  text-align: center;
  font-weight: 600;
  line-height: 1em;
  :hover {
    font-weight: 600;
    font-family: "DraftC";
    ${(p) =>
    p.animation &&
    css`
        & > .icon-right {
          right: -4px;
          transition: all 150ms ease;
        }
        .icon-left {
          left: -4px;
          transition: all 150ms ease;
        }
      `}
  }
  ${(p) =>
    p.iconButton &&
    css`
      padding: 1em;
      display: flex;
      justify-content:center:
      align-items:center:
    `}
  ${(p) => {
    switch (p.variant) {
      case 'disabled':
        return css`
          display: none;
        `;
      case 'link':
        return css`
          color: ${p.hex || p.theme.colors.primary.main};
          background-color: transparent;
          &:hover:enabled {
            color: ${lightenDarken(p.hex || p.theme.colors.primary.main, 30)};
          }
          &:disabled {
            opacity: 0.6;
          }
          &:hover .icon-left,
          &:hover .icon-right {
            opacity: 0.6;
          }
        `;
      case 'linkBlack':
        return css`
          color: ${p.hex || p.theme.colors.black};
          background-color: transparent;
          &:hover:enabled {
            opacity: 0.2;
            & > .icon-left,
            .icon-right {
              opacity: 0.2;
            }
          }
          &:disabled {
            opacity: 0.6;
          }
        `;
      case 'filledWhite':
        return css`
          color: ${p.hex || p.theme.colors.black};
          background-color: ${p.theme.colors.white};
          &:hover {
            color: ${p.hex || p.theme.colors.black};
            background-color: ${p.theme.colors.gray.light};
            & > .icon-left,
            .icon-right {
              opacity: 0.8;
            }
          }
          ${!p.noFocusStyle &&
          `
          &:active,
          &:focus {
            color: ${p.theme.colors.white};
            background-color: ${lightenDarken(
            p.hex || p.theme.colors.white,
            -20,
          )};
          }
          `}
          &:disabled {
            opacity: 0.4;
          }
        `;
      case 'filledGrayLightest':
        return css`
          color: ${p.hex || p.theme.colors.black};
          background-color: ${p.theme.colors.gray.lightest};
          &:hover {
            color: ${p.hex || p.theme.colors.black};
            background-color: ${p.theme.colors.gray.light};
            & > .icon-left,
            .icon-right {
              opacity: 0.8;
            }
          }
          ${!p.noFocusStyle &&
          `
          &:active,
          &:focus {
            color: ${p.theme.colors.gray.lightest};
            background-color: ${lightenDarken(
            p.hex || p.theme.colors.gray.lightest,
            -20,
          )};
          }
          `}
          &:disabled {
            opacity: 0.4;
          }
        `;
      case 'outlinedBlack':
        return css`
          color: ${p.hex || p.theme.colors.black} !important;
          box-shadow: inset 0px 0px 0px 2px ${p.theme.colors.black};
          background-color: transparent;
          &:hover {
            ${p.noHover &&
          css`
              color: ${p.theme.colors.primary.main} !important;
              box-shadow: inset 0px 0px 0px 2px ${p.theme.colors.primary.main};
              & > .icon-left > path,
              .icon-right > path {
                fill: ${p.theme.colors.primary.main} !important;
                transition: all 150ms ease;
              }
            `}
          }
          &:disabled {
            opacity: 0.6;
          }
        `;
      case 'neutral':
        return css`
          color: ${p.hex || p.theme.colors.black} !important;
          box-shadow: inset 0px 0px 0px 1px ${p.theme.colors.gray.main};
          background-color: white;
          &:hover {
            box-shadow: inset 0px 0px 0px 1px ${p.theme.colors.black};
            & > .icon-left > path,
            .icon-right > path {
              transition: all 150ms ease;
            }
          }
          &:disabled {
            opacity: 0.6;
          }
        `;
      case 'outlinedWhite':
        return css`
          color: ${p.hex || p.theme.colors.white} !important;
          background-color: transparent;
          box-shadow: inset 0px 0px 0px 2px ${p.theme.colors.white};
          &:hover:enabled {
            opacity: 0.2;
            & > .icon-left,
            .icon-right {
              opacity: 0.2;
              transition: all 150ms ease;
            }
          }
          ${!p.noFocusStyle &&
          `
          &:active,
          &:focus {
            color: ${lightenDarken(
            p.hex || p.theme.colors.white,
            -10,
          )} !important;
          }
          `}
          &:disabled {
            opacity: 0.6;
          }
        `;
      case 'filled':
      default:
        return css`
          color: #314959 !important;
          background-color: ${p.hex || p.theme.colors.primary.main};
          &:hover:enabled {
            opacity: 1 !important;
            background-color: ${lightenDarken(
          p.hex || p.theme.colors.primary.main,
          -10,
        )};
          }
          ${!p.noFocusStyle &&
          `
          &:active,
          &:focus {
            box-shadow: 0 0 0 2px
              ${lightenDarken(p.hex || p.theme.colors.primary.main, -10)};
            background-color: ${lightenDarken(
            p.hex || p.theme.colors.primary.main,
            -10,
          )};
          }`}
          &:disabled {
            opacity: 0.6;
            background-color: ${p.hex || p.theme.colors.primary.main};
          }
        `;
    }
  }};
  ${(p) => {
    switch (typeof p.fullWidth) {
      case 'string':
        return css`
          @media (max-width: ${p.theme.breakpoints[p.fullWidth]}) {
            width: 100%;
            margin: 4px 0;
          }
        `;
      case 'boolean':
        return (
          p.fullWidth &&
          css`
            width: 100%;
            margin: 4px 0;
          `
        );
      default:
    }
  }}
  &:disabled {
    cursor: not-allowed;
  }

  ${(p) => css`
    .icon-left {
      position: relative;
      ${!p.iconButton &&
    css`
        margin-right: ${p.variant !== 'link' && p.variant !== 'linkBlack'
        ? '4px'
        : 0};
        left: -4px;
      `}
    }
    .icon-right {
      position: relative;
      ${!p.iconButton &&
    css`
        ${p.variant !== 'link' && p.variant !== 'linkBlack' ? '4px' : 0};
        right: -4px;
      `}
    }
    ${p.disabled &&
    css`
      & > .icon-left,
      .icon-right {
        cursor: not-allowed;
      }
    `}
  `}
${(p) =>
    !p.iconButton &&
    css`
    svg {
      transform: scale(1.2);
    }
  `}
  ${buttonSize}
  ${space}
  ${flexbox}
  ${position}
`;

Button.propTypes = {
  ...propTypes.buttonStyle,
  ...propTypes.space,
};

const getIconColor = (v, hex) => {
  switch (v) {
    case 'filled':
    case 'outlinedWhite':
      return 'white';
    case 'outlinedBlack':
    case 'filledWhite':
    default:
      return hex;
    case 'link':
      return colors.primary.main;
  }
};

/**
 * @param {string} color Any Theme color or HEX value
 * @param {string} size compact
 * @param {boolean} loading
 * @param {string} variant outlined | outlinedBlack | outlinedWhite
 * @param {string | boolean} fullWidth true | false | sm | md | lg
 * @param {string} before Named SVG Icon left postion
 * @param {string} after Named SVG Icon right postion
 * @param {string} any extend Styled System space or flexbox props
 */
function AppButton({
  fullWidth = false,
  target = '_self',
  before,
  after,
  to,
  href,
  children,
  onClick = () => { },
  loading = false,
  variant = 'filled',
  noFocusStyle = false,
  noHover = false,
  ...props
}) {
  const location = useLocation();
  const history = useHistory();
  const isChildrenString =
    typeof children === 'string' || children instanceof String
      ? children
      : 'Button';

  let hex = props.color;
  if (props.color && props.color.includes('.')) {
    const [key, subKey] = props.color.split('.');
    hex = colors[key][subKey];
  }

  const handleClick = (e) => {
    if (to) {
      window.location.href = to;
      analyticsTrack('Call To Action Clicked', {
        click_url: location.pathname,
        click_text: isChildrenString,
        category: 'Call to Action',
        action: 'Clicked',
        label: isChildrenString,
      });
    } else {
      onClick(e);
      analyticsTrack('Call To Action Clicked', {
        click_url: location.pathname,
        click_text: isChildrenString,
        category: 'Call to Action',
        action: 'Clicked',
        label: isChildrenString,
      });
    }
  };

  return (
    <Button
      as={to || href ? 'a' : 'button'}
      href={href || to}
      target={target}
      fullWidth={fullWidth}
      noHover={noHover}
      hex={hex}
      onClick={handleClick}
      alignSelf="flex-start"
      variant={variant}
      noFocusStyle={noFocusStyle}
      {...props}
      disabled={!!loading || props.disabled}>
      {before &&
        React.cloneElement(before, {
          color: getIconColor(variant, hex),
          $pointer: true,
          className: 'icon-left',
        })}
      {loading && !!children ? (
        <Box alignItems="center">
          <Spinner xs />
        </Box>
      ) : (
        children
      )}
      {after &&
        React.cloneElement(after, {
          color: getIconColor(variant, hex),
          $pointer: true,
          className: 'icon-right',
        })}
    </Button>
  );
}
export default AppButton;
