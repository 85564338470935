import React from 'react';
import styled, { css } from 'styled-components';
import Box from '../shared/atoms/Box';

const StyledButton = styled(Box)`
  cursor: pointer;
  padding: 1rem;
  border-radius: ${(p) => p.theme.radii.xs};
  border: 1px solid ${(p) => p.theme.colors.gray.light};
  font-weight: 500;
  color: black;
  &:hover {
    border-color: ${(p) => p.theme.colors.blue.electric};
  }
  ${(p) =>
    p.$loading ||
    (p.$disabled &&
      css`
        color: ${p.theme.colors.gray.main};
      `)}
`;

const DiagButton = ({ index, ...props }) => (
  <StyledButton data-testid={index} {...props} />
);
export default DiagButton;
