import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.535 7.615a2 2 0 00-1.326.031c-.174.066-.389.202-.737.549-.355.354-.777.85-1.392 1.577l-.317.374a1 1 0 11-1.526-1.292l.345-.408c.58-.685 1.056-1.247 1.479-1.668.437-.436.888-.794 1.44-1.003a4 4 0 012.652-.062c.562.182 1.029.518 1.486.933.443.4.945.94 1.556 1.596l.537.577a1 1 0 11-1.464 1.362l-.507-.544c-.648-.696-1.093-1.172-1.464-1.509-.365-.33-.586-.456-.762-.513z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgAngleup16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgAngleup16;
