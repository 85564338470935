import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M1 26h12" stroke="#1F77F9" strokeWidth={2} strokeLinecap="round" />
    <path d="M3 20h3" stroke="#28D898" strokeWidth={2} strokeLinecap="round" />
    <path d="M4 32h7" stroke="#FFE249" strokeWidth={2} strokeLinecap="round" />
    <path d="M1 37h6" stroke="#8A38F3" strokeWidth={2} strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 12a9 9 0 00-9 9v1.706a1 1 0 102 0V21a6.97 6.97 0 011.394-4.192L25.13 28.544 14.31 40.197A6.982 6.982 0 0112 35v-5.235a1 1 0 10-2 0V35a9 9 0 009 9h24a9 9 0 009-9V21a9 9 0 00-9-9H19zm-3.007 29.323L26.545 29.96l4.467 4.468 4.38-4.536 10.615 11.432A6.972 6.972 0 0143 42H19a6.972 6.972 0 01-3.007-.677zm31.697-1.126A6.982 6.982 0 0050 35V21a7 7 0 00-7-7H19a6.97 6.97 0 00-4.192 1.394l16.18 16.18L44.28 17.804a1 1 0 011.438 1.39l-8.936 9.255L47.69 40.197z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgMessageColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgMessageColor56;
