import React from 'react';
import styled from 'styled-components';
// Components
import { space } from 'styled-system';
import Heading from '../atoms/Heading';
import Box from '../atoms/Box';
import Container from '../atoms/Container';
import SectionBackground from './SectionBackground';

const StyledImg = styled.img`
  width: 10%;
  height: 80%;
  ${space};
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    flex: 1;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    width: 50%;
    padding: 0 5% 35px;
  }
`;

const StyledHeading = styled(Heading)`
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    width: 100%;
    text-align: center;
    :after {
      content: ' :';
    }
  }
`;

const SectionImage = ({ values, title, ...rest }) => {
  return (
    <SectionBackground mb={{ xs: 7, md: 10 }} bg="transparent" {...rest}>
      <Container>
        <Box
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center">
          <StyledHeading color="black" level={3} mb={{ xs: 5, lg: 0 }}>
            {title}
          </StyledHeading>
          {values.map((value, index) => (
            <StyledImg
              key={index}
              src={value.src}
              alt={value.alt}
              {...value.style}
            />
          ))}
        </Box>
      </Container>
    </SectionBackground>
  );
};

export default SectionImage;
