import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#Happy32_Color_svg__clip0)">
      <circle
        cx={16.667}
        cy={16}
        r={14}
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.213 19.427c.594.58.607 1.534.029 2.13l-8.175 8.439-2.146.004-3.825-3.918a1.512 1.512 0 01.02-2.13 1.495 1.495 0 012.122.02l2.75 2.817 7.104-7.333a1.495 1.495 0 012.121-.029z"
        fill="#28D898"
      />
      <path
        d="M16.667 19c3.456 0 6.338-2.147 7-5h-14c.662 2.853 3.544 5 7 5z"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinejoin="round"
      />
      <path
        d="M13.667 10.5a1.5 1.5 0 00-3 0M22.667 10.5a1.5 1.5 0 00-3 0"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.42 3a.75.75 0 00-1.5 0v2.25h-1.253a.75.75 0 000 1.5h1.252V9a.75.75 0 001.5 0V6.75h1.252a.75.75 0 000-1.5H29.42V3z"
        fill="#1F77F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.17 19.75a.75.75 0 00-1.5 0V22H3.416a.75.75 0 000 1.5h1.252v2.25a.75.75 0 001.5 0V23.5h1.252a.75.75 0 000-1.5H6.17v-2.25z"
        fill="#FFE249"
      />
    </g>
    <defs>
      <clipPath id="Happy32_Color_svg__clip0">
        <path
          fill="#fff"
          transform="matrix(1 0 0 -1 .667 32)"
          d="M0 0h32v32H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgHappy32Color = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgHappy32Color;
