import React from 'react';
import Section from './Section';

const ContainerSection = ({ children, ...props }) => (
  <Section {...props}>
    <div className="container">{children}</div>
  </Section>
);

export default ContainerSection;
