import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import Box from '../shared/atoms/Box';
import SectionBackground from '../shared/molecules/SectionBackground';
import ToolkitFix from '../shared/ToolkitFix';
import MenuTab from '../shared/atoms/MenuTab';
import Heading from '../shared/atoms/Heading';
import Container from '../shared/atoms/Container';
import Text from '../shared/atoms/Text';
import PriceCard from '../shared/PriceCard';

const AllenKey = styled(ToolkitFix)`
  width: 150px;
  height: 150px;
  top: 135px;
  left: 125px;
  transform: rotate(32deg);
  position: absolute;
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    display: none;
  }
`;

const Square = styled(ToolkitFix)`
  width: 200px;
  height: 200px;
  top: 267px;
  right: 117px;
  transform: rotate(68deg);
  position: absolute;
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    width: 150px;
    height: 150px;
    bottom: -66px;
    right: -38px;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    display: none;
  }
`;

const StyledBox = styled(Box)`
  ${(p) => css`
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    ${p.theme.mediaQueries.tablet} {
      padding-left: 40px;
      padding-right: 40px;
    }
    ${p.theme.mediaQueries.desktop} {
      padding-left: 80px;
      padding-right: 80px;
    }
  `}
`;

const StyledPriceCardTablet = styled(PriceCard)`
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    display: none;
  }
`;

const StyledPriceCard = styled(PriceCard)`
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    display: none;
  }
`;

const DiscoverOurPrices = ({ tab, setTab, ...rest }) => {
  const { t } = useTranslation();

  // TODO to be removed
  return ("Tarifs Vélos");

  return (
    <>
      <SectionBackground
        bg="gray.lightest"
        alignItems="center"
        style={{ position: 'relative' }}
        {...rest}>
        <Container>
          <Box
            px={{ xs: 0, md: '139px', ld: '271px' }}
            alignItems="center"
            style={{ position: 'relative' }}>
            <AllenKey name="allen-key" color="gray.light" />
            <Square name="square" color="gray.light" />
            <Box alignItems="center" style={{ zIndex: 1 }}>
              <Box width={{ xs: '100%', xl: '75%' }}>
                <Heading level={2} as="h1" mt={10} mb={5} textAlign="center">
                  {t('discoverourprices.heading')}
                </Heading>
              </Box>
              <Text
                variant="large"
                mb={9}
                color="gray.darkest"
                textAlign="center">
                {t('discoverourprices.texts.1')}
              </Text>
              <MenuTab
                mb={{ xs: '270px', md: '225px', lg: '240px' }}
                value={[
                  { text: t('discoverourprices.texts.2'), value: '1' },
                  { text: t('discoverourprices.texts.3'), value: '2' },
                ]}
                onChange={(value) => setTab(value)}
                selected={tab}
              />
            </Box>
          </Box>
        </Container>
      </SectionBackground>
      <SectionBackground>
        <StyledBox
          bg="transparent"
          flexDirection={{ xs: 'row' }}
          mt="-250px"
          width={1}>
          {tab === '1' ? (
            <>
              <PriceCard
                bg="transparent"
                boxShadow="lg"
                title={t('discoverourprices.titles.1')}
                image="/static/img/photos/pneu-creve-velo.png"
                text={t('discoverourprices.texts.4')}
                link="/reparation"
                price="23"
                mr={{ xs: 0, sm: 3, lg: 0 }}
              />
              <StyledPriceCardTablet
                bg="transparent"
                boxShadow="lg"
                title={t('discoverourprices.titles.2')}
                image="/static/img/photos/frein-use-velo.png"
                text={t('discoverourprices.texts.5')}
                link="/reparation"
                price="24"
                ml={{ xs: 0, sm: 3, lg: 0 }}
              />
              <StyledPriceCard
                bg="transparent"
                boxShadow="lg"
                title={t('discoverourprices.titles.3')}
                image="/static/img/photos/revision-velo.png"
                text={t('discoverourprices.texts.6')}
                link="/reparation"
                price="54"
                textPrice={false}
              />
            </>
          ) : (
            <>
              <PriceCard
                bg="transparent"
                boxShadow="lg"
                title={t('discoverourprices.titles.4')}
                image="/static/img/photos/pneu-use.png"
                text={t('discoverourprices.texts.7')}
                link="/reparation"
                price="102"
                textPrice={false}
              />
              <StyledPriceCardTablet
                bg="transparent"
                boxShadow="lg"
                title={t('discoverourprices.titles.5')}
                image="/static/img/photos/pneu-plat.png"
                text={t('discoverourprices.texts.8')}
                link="/reparation"
                price="76"
                textPrice={false}
              />
              <StyledPriceCard
                bg="transparent"
                boxShadow="lg"
                title={t('discoverourprices.titles.6')}
                image="/static/img/photos/frein-use.png"
                text={t('discoverourprices.texts.9')}
                link="/reparation"
                price="38"
              />
            </>
          )}
        </StyledBox>
      </SectionBackground>
    </>
  );
};

export default DiscoverOurPrices;
