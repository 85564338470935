import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 42 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.637 56.868a1.5 1.5 0 002.091.355l11.01-7.812a1.5 1.5 0 10-1.736-2.447l-8.193 5.814.01-.09c.251-2.214.529-5.317.636-8.889.213-7.105-.243-16.215-3.034-23.84-2.884-7.88-9.537-12.729-15.265-15.582a49.06 49.06 0 00-7.57-2.995c-.993-.3-1.81-.513-2.381-.65a28.145 28.145 0 00-.85-.19l-.05-.01L2.29.527 2.284.527 2 2 2.283.527a1.5 1.5 0 00-.565 2.946m0 0l.006.001.035.007.148.031c.134.03.335.074.596.137.523.125 1.282.322 2.214.604a46.062 46.062 0 017.101 2.809c5.43 2.705 11.284 7.093 13.785 13.927 2.594 7.087 3.062 15.741 2.853 22.72a105.758 105.758 0 01-.58 8.31l-5.604-7.897a1.5 1.5 0 10-2.447 1.736l7.812 11.01"
      fill="#1D1D1D"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = true;
const SvgArrowSeasonBlock = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgArrowSeasonBlock;
