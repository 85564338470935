import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from '../../components/shared/atoms/Container';
import Heading from '../../components/shared/atoms/Heading';
import Text from '../../components/shared/atoms/Text';
import Box from '../../components/shared/atoms/Box';
import * as api from '../../utils/api';
import { slugify } from '../../utils/utils';
import styled from 'styled-components';

// Styled component for letter links
const LetterLink = styled(Link)`
  color: black;
  margin: 0 8px;
  text-decoration: none;
  &:after {
    content: '-';
    margin-left: 8px;
  }
  &:last-child:after {
    content: '';
  }
`;

const AnnuaireLetter = () => {
    const { letter } = useParams();
    const [cities, setCities] = useState([]);

    useEffect(() => {
        const fetchCities = async () => {
            const response = await api.get(`/shops/cities/l/${letter}`);
            if (response) {
                setCities(response);
            }
        };
        fetchCities();
    }, [letter]);

    return (
        <Container>
            <Helmet
                title={`Ateliers de réparation dans les villes commençant par "${letter.toUpperCase()}"`}
                meta={[
                    {
                        name: 'description',
                        content: `Liste des ateliers de réparation dont la ville commence par la lettre "${letter.toUpperCase()}".`,
                    },
                ]}
            />
            <Heading level={1} mt={4} mb={4}>
                Ateliers de réparation de vélos dans les villes commençant par "{letter.toUpperCase()}"
            </Heading>
            <Box my={4} flexDirection="row" justifyContent="center" flexWrap="wrap">
                {Array.from(Array(26)).map((_, index) => {
                    const letter = String.fromCharCode(65 + index); // Generates letters A-Z
                    return (
                        <LetterLink
                            key={letter}
                            to={`/annuaire/atelier-velo-trottinette/letter/${letter.toLowerCase()}`}>
                            {letter}
                        </LetterLink>
                    );
                })}
            </Box>
            {cities.length > 0 ? (
                <Box as="ul" display="flex" flexDirection="row" flexWrap="wrap">
                    {cities.map((city, index) => (
                        <Box as="li" key={index} mb={2} width="50%">
                            <Link
                                to={`/annuaire/atelier-velo-trottinette/${slugify(city)}`}
                                style={{ minWidth: 'auto', color: 'black' }}>
                                {city}
                            </Link>
                        </Box>
                    ))}
                </Box>
            ) : (
                <Text>Aucune ville trouvée pour cette lettre.</Text>
            )}
        </Container>
    );
};

export default AnnuaireLetter; 