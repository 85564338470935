import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  SET_CODEPROMO,
  SET_SELECTED_SHOP,
  setAddress,
  setFixerFromCode,
  setProductId,
} from './actions/course';
import { setClient, unsetClient } from './actions/client';
import { prefillDiag as prefillDiagAction } from './actions/diag';

import RoutesForReal from './routesforreal';

const Routes = (props) => <RoutesForReal {...props} />;

const mapStateToProps = (state) => {
  return {
    date: state.course.date,
    position: state.course.position,
    client: state.client,
    slots: state.course.slots,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAddress: async (input) => {
      await dispatch(setAddress(input));
    },
    setProductId: async (input) => {
      await dispatch(setProductId(input));
    },
    setCodepromo: (val) => {
      dispatch({
        type: SET_CODEPROMO,
        codepromo: val,
      });
    },
    setFixerCode: (val) => dispatch(setFixerFromCode(val)),
    setClient: (client) => {
      dispatch(setClient(client));
    },
    setSelectedShop: (shop) => {
      dispatch({
        type: SET_SELECTED_SHOP,
        shop,
      });
    },
    unsetClient: () => {
      dispatch(unsetClient());
    },
    dispatch,
    prefillDiag: async (queryString) => {
      await dispatch(prefillDiagAction(queryString));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
