import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import * as api from '../../../../utils/api';
import Link from '../../../shared/atoms/Link';
import Text from '../../../shared/atoms/Text';
import SideBar from '../../../shared/molecules/SideBar';
import Box from '../../../shared/atoms/Box';
import Separator from '../../../Separator';
import Eye16 from '../../../icons/Eye16';
import ArrowRight16 from '../../../icons/ArrowRight16';
import EmptyState from '../../../shared/molecules/EmptyState';
import { formatDate } from '../../../../utils/reparation';
import { uniquePrestaPiece } from '../../../../utils/utils';
import { getAuthParameters } from '../../../../utils/api';
import { fixerPhotoUrl } from '../../../../utils/fixers';
import { colors } from '../../../../styles/theme';
import { readableReportElement } from '../../../../utils/ReportElements';

const FixerPhoto = styled('img')`
  width: 24px;
  border-radius: 100%;
  margin-right: 4px;
  margin-left: 4px;
`;

const CoursePhoto = styled('img')`
  width: 56px;
  height: 56px;
  object-fit: cover;
  border-radius: 16px;
`;

const InterventionDetails = ({ course, bike, ...props }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [relatedPhotos, setRelatedPhotos] = useState([]);
  const prestations = Object.values(uniquePrestaPiece(course.prestations));
  const { elements: bikeDetails, fixer_commentaire } = course.velos.filter(
    (velo) => velo.id === bike.id,
  )[0];

  const getRelatedPhotos = async () => {
    const getRelatedPhotos = await api.get(`/courses/${course.id}/photos`);
    setRelatedPhotos(getRelatedPhotos);
    setLoading(false);
  };

  const getBill = () => {
    const courseid = course.id;
    const auth = getAuthParameters();
    if (auth) {
      window.open(
        `https://api.roulezjeunesse.com/courses/${courseid}/invoice?ctk=${encodeURIComponent(
          auth.token,
        )}&cti=${auth.id}`,
        '_blank',
      );
    }
  };

  useEffect(() => {
    getRelatedPhotos();
  }, []);
  if (loading) {
    return (
      <>
        <Skeleton style={{ height: 200, borderRadius: 24 }} />
      </>
    );
  }
  return (
    <Box
      borderRadius="sm"
      borderColor="gray.light"
      borderStyle="solid"
      borderWidth="1px"
      p={2}
      {...props}>
      <Box
        bg="gray.lighter"
        borderRadius="xs"
        p={3}
        flexDirection="row"
        justifyContent="space-between">
        <Text fontWeight={2} color="black">
          {t('intervention.details')}
        </Text>
        {course.prix > 0 && (
          <Link
            fontWeight="bold"
            color="blue.electric"
            before={<Eye16 />}
            onClick={() => getBill()}>
            {t('invoice.download')}
          </Link>
        )}
      </Box>
      <Box flexDirection={{ xs: 'column', md: 'row' }} p={2}>
        <Box width="60%">
          {!prestations.length ? (
            <Text mt={3} fontWeight={1}>
              {t('bike.history.texts.7')}
            </Text>
          ) : (
            <>
              {' '}
              <Text fontWeight={1}>{t('bike.history.texts.1')}</Text>
              {prestations.map((prestation) => (
                <Text key={prestation.prestaPiece.id} mt={2}>
                  <ArrowRight16 mb={1} mr={1} />
                  {prestation.prestaPiece.nom}
                </Text>
              ))}
            </>
          )}
          {!bikeDetails.length && !fixer_commentaire ? (
            <Text mt={3} fontWeight={1}>
              {t('bike.history.texts.6')}
            </Text>
          ) : (
            <>
              <Text mt={3} fontWeight={1}>
                {t('bike.history.texts.5')}
              </Text>
              <Text mt={3}>{fixer_commentaire}</Text>
              {bikeDetails.map((element) => {
                const { type, value } = readableReportElement(element, t);
                return (
                  <Text key={element.id} mt={2}>
                    <ArrowRight16 mb={1} mr={1} />
                    {type || element.element_type} :{' '}
                    {value || element.element_value}
                  </Text>
                );
              })}
            </>
          )}
        </Box>
        <Separator
          axe="horizontal"
          display={{ xs: 'flex', md: 'none' }}
          my={4}
        />
        <Box flexDirection="row">
          <Separator display={{ xs: 'none', md: 'flex' }} mr={4} />
          <Box>
            <Text fontWeight={1}>{t('bike.history.texts.2')}</Text>
            <Box flexDirection="row" flexWrap="wrap">
              {relatedPhotos.length ? (
                relatedPhotos.map((photo, index) => (
                  <Box key={index} mr={index + (1 % 3) === 0 ? 0 : 2} mb={2}>
                    <CoursePhoto src={photo.small} />{' '}
                  </Box>
                ))
              ) : (
                <Text color="gray.dark">{t('bike.history.texts.3')}</Text>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box flexDirection="row" p={2}>
        <Text color="black" mt={2} fontWeight={1}>
          {t('bike.history.texts.4')}
          <FixerPhoto src={fixerPhotoUrl(course.fixer)} />
          {course.fixer.prenom}
        </Text>
      </Box>
    </Box>
  );
};

const InterventionsHistory = ({ bike, courses, loading }) => {
  const { t } = useTranslation();
  if (loading) {
    return (
      <>
        <Skeleton style={{ height: 200, borderRadius: 24 }} />
        <Skeleton style={{ height: 200, borderRadius: 24, marginTop: 32 }} />
      </>
    );
  }
  const vehiculeLabel =
    bike.properties.type === 'velo' || bike.properties.type === 'vae'
      ? t('bike')
      : t('scooter');
  return (
    <Box alignItems="center">
      {!courses.length ? (
        <>
          <EmptyState
            width="50%"
            title={t('bike.history.emptystate.title')}
            subTitle={t(`bike.history.emptystate.subtitle`, {
              vehicule: vehiculeLabel,
            })}
          />
        </>
      ) : (
        <>
          {courses.map((course, index) => {
            const lastIndex = courses.length - 1;
            return (
              <SideBar
                key={course.id}
                width="100%"
                checkColor="black"
                animation={false}
                title={
                  <span style={{ color: colors.black }}>
                    {formatDate(course.date, 'DD MMM YYYY')}
                  </span>
                }
                content={[
                  <InterventionDetails
                    key={course.id}
                    course={course}
                    bike={bike}
                    mt={2}
                  />,
                ]}
                end={index === lastIndex}
              />
            );
          })}
        </>
      )}
    </Box>
  );
};
export default InterventionsHistory;
