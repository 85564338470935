import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../styles/constants';

export default function CobrandLogos({ url }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <img
        src={url}
        height="40"
        className="logo"
        alt={t('roulezjeunesse.texts.alt')}
      />
      <span
        style={{
          margin: 10,
          marginBottom: 3,
          marginRight: 8,
          marginTop: 8,
          fontSize: 12,
          color: colors.darkgrey,
          fontWeight: 600,
        }}>
        x
      </span>
    </React.Fragment>
  );
}
