/* eslint-disable prettier/prettier */
/* eslint-disable prefer-template */
import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';

import constants from './constants';
import Text from '../components/shared/atoms/Text';
import i18n from '../i18n';
import { size } from '../styles/constants';

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Se souvenir de la dernière fonction de rappel.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Configurer l’intervalle.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function to2(nb) {
  return parseFloat(nb).toFixed(2);
}

export function debounce(callback, time) {
  let interval;
  return (...args) => {
    clearTimeout(interval);
    interval = setTimeout(() => {
      interval = null;
      callback(...args);
    }, time);
  };
}

export function serialize(obj, prefix) {
  const str = [];
  let p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? prefix + '[' + p + ']' : p;
      const v = obj[p];
      str.push(
        v !== null && typeof v === 'object'
          ? serialize(v, k)
          : encodeURIComponent(k) + '=' + encodeURIComponent(v),
      );
    }
  }
  return str.join('&');
}

export const googleKey = 'AIzaSyDjcNaS-p0KQPMFt7cEOgKXdxlMgTAO-Gg';

export async function googleGeocode(position) {
  const googleUrl = 'https://maps.google.com/maps/api/geocode/json';

  const req = await fetch(
    googleUrl +
    '?latlng=' +
    position.coords.latitude +
    ',' +
    position.coords.longitude +
    '&key=' +
    googleKey,
  );

  return await req.json();
}

export async function googleReverseGeocode(position) {
  const googleUrl = 'https://maps.google.com/maps/api/geocode/json';

  const req = await fetch(
    googleUrl + '?address=' + position + '&key=' + googleKey,
  );

  return await req.json();
}

export function to2Int(i) {
  return ('0' + i).slice(-2);
}

const STEP = 15;

export function hoursRangeFromMinMax(minMax) {
  let { min, max, openv = undefined } = minMax;
  let start = 0;
  const end = 1;
  const table = [];
  if (min % 1 !== 0) {
    min = ~~min;
    start += 1;
  }
  const nb = max - min;

  for (let i = start; i < (60 / STEP) * nb + end; i++) {
    const absoluteidx = i + (min * 60) / STEP; // i starts from start, absolute from 0h
    table.push({
      hour:
        to2Int(min + Math.floor((i * STEP) / 60)) +
        ':' +
        to2Int((i * STEP) % 60),
      disabled: typeof openv !== 'undefined' && openv[absoluteidx] == 0,
    });
  }

  return table;
}

export function getMaxHourFromOpenV(disp) {
  const { max, openv } = disp;
  if (typeof openv === 'undefined') return intToHour(max);
  const idx = openv.lastIndexOf(1);
  return (
    to2Int(Math.floor((idx * STEP) / 60)) + ':' + to2Int((idx * STEP) % 60)
  );
}

export function getMinHourFromOpenV(disp) {
  const { min, openv } = disp;
  if (typeof openv === 'undefined') return intToHour(min);
  const idx = openv.findIndex((i) => i == 1);
  return (
    to2Int(Math.floor((idx * STEP) / 60)) + ':' + to2Int((idx * STEP) % 60)
  );
}

export function currencyFormat(amount, currency) {
  if (currency === '£') {
    return '£' + amount;
  }
  return amount + '€';
}

export function getDomain(hostname = false) {
  let domain =
    hostname ||
    (typeof window !== 'undefined' && window && window.location
      ? window.location.hostname.slice(-2)
      : 'fr');
  domain = domain.slice(-2);
  switch (domain) {
    case 'fr':
      return { domain: 'fr', lang: 'fr', moment: 'fr', header: 'FR' };
    case 'om':
      return { domain: 'fr', lang: 'fr', moment: 'fr', header: 'FR' };
    case 'uk':
      return { domain: 'uk', lang: 'en', moment: 'en', header: 'UK' };
    default:
      return { domain: 'fr', lang: 'fr', moment: 'fr', header: 'FR' };
    // return { domain: 'uk', lang: 'en', moment: 'en-gb', header: 'UK' };
  }
}

export function getLang() {
  const lang = i18n.language;

  switch (lang) {
    case 'fr':
      return { lang: 'fr', moment: 'fr', header: 'FR', currency: '€' };
    case 'en':
      return { lang: 'en', moment: 'en', header: 'UK', currency: '£' };
    default:
      return { lang: 'fr', moment: 'fr', header: 'FR', currency: '€' };
  }
}

export function isLocal() {
  const hostname =
    typeof window !== 'undefined' &&
    window &&
    window.location &&
    window.location.hostname;
  return hostname === 'localhost';
}

export function isStaging() {
  const hostname =
    typeof window !== 'undefined' &&
    window &&
    window.location &&
    window.location.hostname;
  return hostname === 'staging.roulezjeunesse.com';
}

export function isDev() {
  return false;
  //return isLocal();
}

export function readableProduct(
  product_id,
  pron = false,
  cap = false,
  possessif = false,
) {
  const prod = constants.products.find((p) => product_id == p.id);
  let { name } = prod;
  if (cap) {
    name = name.charAt(0).toUpperCase() + name.slice(1);
  }
  return `${pron ? `${prod.pron} ` : possessif ? `${prod.possessif} ` : ``
    }${name}`;
}

export function intToHour(hour) {
  return to2Int(hour) + ':00';
}
export function hourToInt(hour) {
  // return to2Int(hour)+'00'
}

export function isABVariant(type = 'B') {
  if (typeof window === 'undefined') return false;
  if (typeof window.abUserType === 'undefined') return false;
  return window.abUserType === type;
}

export function GTMAnalyticsSendDL(dl) {
  if (typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined')
    window.dataLayer.push(dl);
}

export function aggloSlots(slots) {
  return slots
    .sort(
      (a, b) => moment(a.available_min).unix() - moment(b.available_min).unix(),
    )
    .reduce((acc, item) => {
      const date = moment(item.available_min).format('YYYY-MM-DD');
      const min = moment(item.available_min).format('HH:mm');
      const max = moment(item.available_max).format('HH:mm');
      if (typeof acc[date] === 'undefined') {
        acc[date] = [{ min, max }];
        return acc;
      }
      let found = false;

      acc[date].forEach((ts) => {
        if (ts.max === min) {
          ts.max = max;
          found = true;
        }
      });

      if (!found) acc[date].push({ min, max });

      return acc;
    }, {});
}

export function readableslots(slots, day = false) {
  const dayedSlots = aggloSlots(slots);
  const lang = i18n.language;
  return (
    <>
      {lang === 'fr' && (
        <div>
          {Object.keys(dayedSlots).map((dd) => {
            return (
              <div key={dd} style={{ display: 'flex' }}>
                <Text>
                  Le <b>&nbsp;{moment(dd).format('dddd DD/MM')}&nbsp;</b>
                  {dayedSlots[dd].map((audd, index) => (
                    <span key={audd.min}>
                      Entre {audd.min} et {audd.max}
                      {dayedSlots[dd].length !== index + 1 && ', '}
                    </span>
                  ))}
                  <br />
                </Text>
              </div>
            );
          })}
        </div>
      )}
      {lang === 'en' && (
        <div>
          {Object.keys(dayedSlots).map((dd) => {
            return (
              <div key={dd} style={{ display: 'flex' }}>
                <Text>
                  <b>&nbsp;{moment(dd).format('dddd DD/MM')}&nbsp;</b>
                  {dayedSlots[dd].map((audd, index) => (
                    <span key={audd.min}>
                      Between {audd.min} and {audd.max}
                      {dayedSlots[dd].length !== index + 1 && ', '}
                    </span>
                  ))}
                  <br />
                </Text>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export function newReadableslots(slots, day = false, isShop = false) {
  const dayedSlots = aggloSlots(slots);
  const lang = i18n.language;

  return (
    <>
      {lang === 'fr' && (
        <div>
          {Object.keys(dayedSlots).map((dd) => {
            return isShop ? (
              <div key={dd}>
                <Text>
                  Le {moment(dd).format('dddd DD/MM')}
                  {dayedSlots[dd].map((audd, index) => (
                    <span key={index}>{` à ${audd.max}`}</span>
                  ))}
                </Text>
              </div>
            ) : (
              <div key={dd}>
                <Text>
                  Le {moment(dd).format('dddd DD/MM')}
                  {dayedSlots[dd].map((audd, index) => (
                    <span key={index}>
                      {`${dayedSlots[dd].length === index + 1 && index > 0
                        ? ' et'
                        : ','
                        } de ${audd.min} à ${audd.max}`}
                    </span>
                  ))}
                </Text>
              </div>
            );
          })}
        </div>
      )}
      {lang === 'en' && (
        <div>
          {Object.keys(dayedSlots).map((dd) => {
            return isShop ? (
              <div key={dd}>
                <Text>
                  {moment(dd).format('dddd DD/MM')}
                  {dayedSlots[dd].map((audd, index) => (
                    <span key={index}>{` at ${audd.max}`}</span>
                  ))}
                </Text>
              </div>
            ) : (
              <div key={dd}>
                <Text>
                  {moment(dd).format('dddd DD/MM')}
                  {dayedSlots[dd].map((audd, index) => (
                    <span key={index}>
                      {`${dayedSlots[dd].length === index + 1 && index > 0
                        ? ' and'
                        : ','
                        } from ${audd.min} to ${audd.max}`}
                    </span>
                  ))}
                </Text>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export function readableQuestions(questions, inProgressQuestion, progress) {
  return (
    <div>
      {questions.map((question) => {
        const { a } = question;
        return (
          <div key={a.id}>
            <span>{a.content}</span>
          </div>
        );
      })}
      {inProgressQuestion && inProgressQuestion.content !== 'noDisplay' && (
        <div>
          {progress ? (
            <b style={{ color: '#313534' }}>{inProgressQuestion.name}</b>
          ) : (
            <span>{inProgressQuestion.name}</span>
          )}
        </div>
      )}
    </div>
  );
}

export const testFileType = (fileName) => {
  if (!fileName) return false;

  const dots = fileName.split('.');
  const fileType = dots[dots.length - 1].toLowerCase();

  return !!~['png', 'jpg', 'jpeg'].indexOf(fileType);
};

export const diagToText = (questions) => {
  let text = '';
  for (const question of questions)
    text += `${question.q.name} ${question.a.content}\n`;
  return text;
};

export const readFile = async (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      resolve(evt.target.result);
    };
    reader.readAsDataURL(file);
  });
};

export function prioSort(a, b) {
  const aprio = a.prio || 0;
  const bprio = b.prio || 0;
  return aprio === bprio ? 0 : aprio > bprio ? -1 : 1;
}

export const detectCardType = (number) => {
  const re = {
    maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$/,
    amex: /^3[47][0-9]{13}$/,
  };

  for (const key in re) {
    if (re[key].test(number)) {
      return key;
    }
  }
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const uniquePrestaPiece = (prestaPiece) => {
  const uniquePrestaPiece = {};
  prestaPiece.forEach((i) => {
    const { id } = i;
    uniquePrestaPiece[id] = uniquePrestaPiece[id] || { prestaPiece: i, nb: 0 };
    uniquePrestaPiece[id].nb++;
  });
  return uniquePrestaPiece;
};

export function lightenDarken(color, percent) {
  if (color) {
    const num = parseInt(color.replace('#', ''), 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) + amt;
    const B = ((num >> 8) & 0x00ff) + amt;
    const G = (num & 0x0000ff) + amt;
    return `#${(
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1)}`;
  }
  return color;
}

export const slugify = (str) =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace("'", '')
    .toLocaleLowerCase();

const nameOfDay = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

export const parse_all_shops = (shops) =>
  Object.entries(shops).reduce((acc, [key, value]) => {
    if (value.details[0].open_hours) {
      const week =
        typeof value.details[0].open_hours === 'object'
          ? value.details[0].open_hours
          : JSON.parse(value.details[0].open_hours);
      const week_in_order = nameOfDay.reduce((acc, day) => {
        const index = Object.keys(week).indexOf(day);
        if (index === -1) return null;
        return { ...acc, [day]: week[day] };
      }, {});
      value.details[0].open_hours = week_in_order;
    } else {
      value.details[0].open_hours = null;
    }
    acc.push({ value });
    return acc;
  }, []);

export const getSeason = (month) => {
  switch (month + 1) {
    case 2 || 3 || 4:
      return 'SPRING';
    case 5 || 6 || 7:
      return 'SUMMER';
    case 8 || 9 || 10:
      return 'AUTUMN';
    default:
      return 'WINTER';
  }
};

export const randomPassword = () => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getEstimatedDuration = (quote_elements) => {
  if (!quote_elements || !quote_elements.length) return null;
  const filterNoDuration = quote_elements.filter(
    (e) => e.estimated_duration > 0,
  );
  if (filterNoDuration.length === 0) return null;
  return (
    filterNoDuration.reduce(
      (a, b) => a.estimated_duration + b.estimated_duration,
    ) / filterNoDuration.length
  );
};

export const analyticsTrack = (event, data) => {
  const analytics = Analytics({
    app: 'roulezjeunesse-front',
    plugins: [
      segmentPlugin({
        writeKey: 'MkoKQVRhOS1gND56LeqpvQJMIkRoF8bx',
      }),
    ],
  });
  analytics.track(event, data);
};

export const analyticsIdentify = (userId, data) => {
  const analytics = Analytics({
    app: 'roulezjeunesse-front',
    plugins: [
      segmentPlugin({
        writeKey: 'MkoKQVRhOS1gND56LeqpvQJMIkRoF8bx',
      }),
    ],
  });
  analytics.identify(userId, data);
};

export const analyticsPage = () => {
  const analytics = Analytics({
    app: 'roulezjeunesse-front',
    plugins: [
      segmentPlugin({
        writeKey: 'MkoKQVRhOS1gND56LeqpvQJMIkRoF8bx',
      }),
    ],
  });
  analytics.page();
};

export const getVersion = () => {
  return typeof window !== 'undefined' &&
    window &&
    window.innerWidth < size.sm.replace('px', '')
    ? 'frontm_3.2.3'
    : 'front_3.2.3';
};

export function parseCapabilitiesFromURL(url) {
  const urlParams = new URLSearchParams(url);
  const capabilities = [];
  let index = 0;
  while (true) {
    const capabilityId = urlParams.get(`capabilities[${index}][id]`);
    if (capabilityId === null) break;
    const parsedId = parseInt(capabilityId, 10);
    if (parsedId !== 0) {
      capabilities.push({ id: parsedId });
    }
    index++;
  }
  return capabilities;
}
