import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M20.707 18.7a.9.9 0 100 1.8h2.05a1.443 1.443 0 110 2.886H9.307a3.307 3.307 0 100 6.614h6.824a.9.9 0 000-1.8H9.307a1.507 1.507 0 110-3.014h13.45a3.243 3.243 0 100-6.486h-2.05z"
      fill="#000"
    />
    <path d="M19.514 30a1 1 0 100-2 1 1 0 000 2z" fill="#000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.745 20c-2.952-4.043-7.34-6.987-7.34-10.905C8.406 5.178 11.694 2 15.746 2s7.335 3.178 7.335 7.095c0 3.918-4.388 6.862-7.335 10.905z"
      fill="#8A38F3"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgPinmoveColor32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPinmoveColor32;
