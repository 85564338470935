import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { GTMAnalyticsSendDL } from '../../utils/utils';
import * as api from '../../utils/api';
import { setSelectedShop, setPosition } from '../../actions/course.jsx';
// Components
import Box from '../shared/atoms/Box';
import Button from '../shared/atoms/Button';
import Text from '../shared/atoms/Text';
import Link from '../shared/atoms/Link';
import DisplayHoursShop from './DisplayHoursShop';
import showModalFiche, { showModalMeetShop } from './showModalDisplayShop';
import DisplayInformationsShop from './DisplayInformationsShop';
// Hooks
import { useContact } from '../../hooks/useModal';
// Icons
import PinShop24 from '../icons/doNotDelete/PinShop24';
import BooxiModal from './BooxiModal';
import { isShopCycloBookable } from '../../hooks/useBookableShops';

const StyledBox = styled(Box)`
  border-bottom: 1px solid ${(p) => p.theme.colors.gray.main};
`;

const StyledButton = styled(Button)`
  width: 'auto';
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    width: 50%;
  }
`;

const StyledLink = styled(Link)`
  align-self: flex-end;
`;

const ShopItem = ({ shop, index, today, annuaire, indexShopClick, refs }) => {
  const { t } = useTranslation();
  const { showAlert: showModal } = useContact();
  const { value } = shop;
  const distanceKm = value.distance / 1000;
  let { open_hours, booxi_api_key } = value.details[0];
  const { selectedShop } = useSelector((s) => s.course);
  const dispatch = useDispatch();
  const history = useHistory();
  booxi_api_key = false; // hide booxi
  const [displayBooxi, setDisplayBooxi] = useState(false);
  const today_open_hours = open_hours ? open_hours[today] : null;

  const showFiche = () => {
    GTMAnalyticsSendDL({
      event: 'site_event',
      eventCategory: 'annuaire',
      eventAction: 'Voir fiche',
      eventLabel: value.name,
      eventValue: value.id,
    });
    showModalFiche(value, open_hours, today, showModal, t);
  };

  const submitEmail = async ({ values, shop }) => {
    const { email, message, telephone } = values;
    const sendEmail = await api.post(`/shops/${shop.id}/contact`, {
      email,
      message,
      telephone,
    });
    if (sendEmail.ok) {
      showModalMeetShop({
        shop: value,
        showModal,
        submitEmail,
        t,
        sendEmail: 'success',
        showFiche,
      });
    } else {
      showModalMeetShop({
        shop: value,
        showModal,
        submitEmail,
        t,
        sendEmail: 'error',
        showFiche,
      });
    }
  };

  const openBooxi = () => {
    GTMAnalyticsSendDL({
      event: 'site_event',
      eventCategory: 'annuaire',
      eventAction: 'Reserver',
      eventLabel: value.name,
      eventValue: value.id,
    });
    setDisplayBooxi(!displayBooxi);
  };

  const ShowMeetShop = () => {
    GTMAnalyticsSendDL({
      event: 'site_event',
      eventCategory: 'annuaire',
      eventAction: 'Reserver',
      eventLabel: value.name,
      eventValue: value.id,
    });

    showModalMeetShop({
      shop: value,
      showModal,
      submitEmail,
      t,
      sendEmail: false,
      showFiche,
    });
  };

  const goToWorkshop = (shop) => {
    dispatch(setPosition(shop.address));
    history.push('/reparation');
  };

  return (
    <StyledBox
      px={2}
      py={5}
      key={index}
      bg={indexShopClick === index ? 'gray.lightest' : 'transparent'}
      itemScope
      itemType={'https://schema.org/LocalBusiness'}>
      <Box key={index} flexDirection="row">
        <div ref={refs[index]} />
        <Box mr={4} alignItems="center" width="8%">
          <PinShop24 number={index + 1} />
          {!annuaire && (
            <Text
              variant="caption"
              fontWeight="bold"
              color="black"
              textAlign="center">
              {`${distanceKm.toFixed(1)} km`}
            </Text>
          )}
        </Box>
        <Box
          justifyContent="space-between"
          flexDirection={{ xs: 'column', md: 'row' }}
          flex={1}>
          <Box width={{ xs: '100%', sm: '68%' }} mr={2}>
            <Text fontWeight="bold" color="black" mb={1} itemProp={'name'}>
              {value.name}
            </Text>
            <div itemScope itemType={'https://schema.org/PostalAddress'}>
              <Text color="black" mb={1} itemProp={'address'}>
                {value.address}
              </Text>
            </div>

            <DisplayHoursShop
              {...{
                today_open_hours,
                index,
                open_hours,
                today,
              }}
            />
          </Box>
          <Box
            flexDirection={{ xs: 'row-reverse', md: 'column' }}
            justifyContent={{ xs: 'flex-end', md: 'flex-start' }}
            ml={{ xs: '-50px', md: 0 }}>
            {value.bookable ? (
              <StyledButton
                size="compact"
                onClick={() => goToWorkshop(value)}
                mb={{ xs: 0, md: 2 }}>
                {t('button.book')}
              </StyledButton>
            ) : value.email ? (
              <StyledButton
                size="compact"
                onClick={() => ShowMeetShop()}
                mb={{ xs: 0, md: 2 }}>
                {t('contact')}
              </StyledButton>
            ) : (
              <div />
            )}
            <StyledButton
              size="compact"
              variant="neutral"
              alignSelf="flex-end"
              mr={{ xs: 2, md: 0 }}
              to={`/ateliers-reparation/${value.id}/${value.name}`}>
              {t('shopitem.texts.1')}
            </StyledButton>
          </Box>
        </Box>
      </Box>
      {displayBooxi && (
        <BooxiModal
          booxiApiKey={booxi_api_key}
          onClose={() => setDisplayBooxi(false)}
        />
      )}
    </StyledBox>
  );
};

export default ShopItem;
