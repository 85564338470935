import React from 'react';
// import { Helmet } from 'react-helmet';
// import styled from 'styled-components';
// import ContainerSection from '../../components/blocks/ContainerSection';
// import Card from '../../components/blocks/Card';
// import BaseBlock from '../../components/blocks/BaseBlock';
// import InfoText from '../../components/InfoText';
// import Button from '../../components/Button';
// import constants from '../../utils/constants';
// import { colors } from '../../styles/constants';
// import { Row } from '../../components/blocks/Grid';

// const CardTitle = styled.h2`
//   font-size: 4em;
// `;

const Subscribe = () => {
  return <div />;
};

export default Subscribe;
