import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { debounce } from '../../../utils/utils';
// Modal
import CardModal from './CardModal';
// Components
import Button from '../../shared/atoms/Button';
import Box from '../../shared/atoms/Box';
import Text from '../../shared/atoms/Text';
import Heading from '../../shared/atoms/Heading';
import Alert from '../../shared/molecules/Alert';
// Icons
import Plus16 from '../../icons/Plus16';

const StyledStripe = styled.img`
  width: 82px;
  height: 18px;
  margin-right: 8px;
`;

const StyledCard = styled.img`
  width: 27px;
  height: 18px;
  margin-right: 4px;
`;

const AddCards = ({ setNewCard, setHasDeleteCard }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [stripePayment, setStripePayment] = useState({
    cvc: false,
    number: false,
    expiry: false,
    payment_method: '',
    message: '',
    state: null,
    complete: false,
  });

  const sendRequest = () => {
    const { state } = stripePayment;
    if (state === 'error') {
      setStripePayment({
        cvc: false,
        number: false,
        expiry: false,
        message: '',
        state: null,
      });
    } else {
      setStripePayment({ ...stripePayment, state: 'send' });
      const debounceShowModal = debounce(() => setShowModal(false), 4000);
      debounceShowModal();
    }
  };

  useEffect(() => {
    const { state } = stripePayment;
    if (state === 'succes') setNewCard(true);
    setHasDeleteCard(false);
    if (state === 'error') console.error(state, stripePayment);
  }, [stripePayment]);

  const openModal = () => {
    setStripePayment({
      cvc: false,
      number: false,
      expiry: false,
      payment_method: '',
      message: '',
      state: null,
      complete: false,
    });
    setShowModal(true);
  };

  return (
    <>
      <CardModal
        {...{
          showModal,
          setShowModal,
          stripePayment,
          setStripePayment,
          sendRequest,
        }}
      />
      <Heading level={5} mb={4}>
        {t('addnewcard.cta')}
      </Heading>
      {stripePayment.state === 'error' && (
        <Alert status="danger" mb={5}>
          {t('addcards.alert')} {stripePayment.message}
        </Alert>
      )}
      <Box flexDirection="row" mb={2}>
        <StyledStripe src="/static/img/logos/stripe.png" alt="Stripe" />
        <StyledCard src="/static/img/logos/Visa.png" alt="Visa" />
        <StyledCard src="/static/img/logos/Mastercard.png" alt="Mastercard" />
        <StyledCard src="/static/img/logos/CB.png" alt="CB" />
      </Box>
      <Text mb={4}>{t('addcards.text')}</Text>
      <Button
        before={<Plus16 />}
        mb={7}
        onClick={openModal}>
        {t('addcard')}
      </Button>
    </>
  );
};

export default AddCards;
