import loadable from '@loadable/component';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';

import Loader from './components/blocks/Loader';
import HeadBand from './components/HeadBand';
import HeaderFooter from './components/HeaderFooter';
import ResumeCourse from './components/ResumeCourse';
import About from './scenes/About';
import Avis from './scenes/Avis';
import DiagWrapper from './scenes/DiagWrapper';
import Home from './scenes/Home';
import Login from './scenes/Login';
import Press from './scenes/press/Press';
import PressRelease from './scenes/press/PressRelease';
import NotFound from './scenes/NotFound';
import PayByLink from './scenes/PayByLink';
import ProductLanding from './scenes/ProductLanding';
import AnnuaireCity from './scenes/Annuaire/AnnuaireCity';
import RecuitmentFixer from './scenes/RecuitmentFixer';
import ResetPassword from './scenes/ResetPassword';
import Certification from './scenes/Certification';
import RedirectWithStatus from './utils/RedirectWithStatus';
// User Area
import Account from './scenes/customerArea/Account';
import MyAccount from './scenes/customerArea/MyAccount';
import PaymentMethods from './scenes/customerArea/PaymentMethods';
import BikeHome from './scenes/customerArea/BikeHome';
import MyBike from './scenes/customerArea/MyBike';
import Course from './scenes/customerArea/Course';
import Business from './scenes/business/Business';
import Fleets from './scenes/business/Fleets';
import BusinessHome from './scenes/business/BusinessHome';

import Subscribe from './scenes/subscription/Subscribe';
import SubscribeConfirmation from './scenes/subscription/SubscribeConfirmation';
import SubscribePay from './scenes/subscription/SubscribePay';
import Prices from './scenes/Prices';
import Repair from './scenes/repair/Repair';
import Brand from './scenes/business/Brand';

import * as api from './utils/api';
import AnnuaireHome from './scenes/Annuaire/AnnuaireHome';
import AddGiftCard from './scenes/giftcard/AddGiftCard';
import BuyGiftCard from './scenes/giftcard/BuyGiftCard';
import LandingGiftCard from './scenes/giftcard/LandingGiftCard';
import RepairWidget from './scenes/RepairWidget/RepairWidget';
import WorkshopStandalone from './scenes/WorkshopStandalone';
import RepairConfirmation from './scenes/repair/RepairConfirmation';
import { FeaturesContext } from './contexts/FeaturesContext';
import i18n from './i18n';
import { setLang } from './actions/lang';
import { analyticsPage } from './utils/utils';
import AnnuaireLetter from './scenes/Annuaire/AnnuaireLetter';

const Legal = loadable(() => import('./scenes/Legal'), {
  fallback: <Loader />,
});

const Cities = loadable(() => import('./scenes/Cities'), {
  fallback: <Loader />,
});

const City = loadable(() => import('./scenes/City'), {
  fallback: <Loader />,
});

export const Routes = () => (
  <Switch>
    {/* <Route exact path="/" component={Home} /> */}
    <Route path="/reparation/confirmation" component={RepairConfirmation} />
    <Route path="/reparation" component={Repair} />
    {/* <RedirectWithStatus status={301} from="/professionnels" to="/entreprises" /> */}
    {/* <Route exact path="/entreprises" component={BusinessHome} /> */}
    {/* <Route path="/entreprises/marques" component={Brand} /> */}
    {/* <Route path="/entreprises/reparation-en-entreprise" component={Business} />
    <Route path="/entreprises/flottes" component={Fleets} />
    <Route path="/tarifs/:product" component={Prices} /> */}
    {/* <Route path="/tarifs" component={Prices} /> */}
    {/* <Route path="/reparateur-a-domicile/:slug" component={City} /> */}
    {/* <Route path="/reparateur-a-domicile" component={Cities} /> */}
    {/* <Route
      exact
      path={[
        '/produits/velo',
        '/produits/velo-electrique',
        '/produits/trottinette',
      ]}
      component={ProductLanding}
    /> */}
    {/* <Route path="/mentions-legales" component={Legal} /> */}
    {/* <Route path="/a-propos" component={About} /> */}
    {/* <Route path="/avis" component={Avis} /> */}
    {/* <Route path="/presse" exact component={Press} /> */}
    {/* <Route path="/presse/communiques-de-presse" component={PressRelease} /> */}
    <Route path="/login" component={Login} />\
    <Route
      path="/fixers/:code"
      exact
      component={({ match }) => {
        if (typeof window !== 'undefined') {
          window.location.href = `/?fixer_code=${match.params.code}`;
        }
        return <div />;
      }}
    />
    <Route path="/account" exact component={Account} />
    <Route path="/account/informations" exact component={MyAccount} />
    <Route path="/account/cartes" component={PaymentMethods} />
    {/* <Route path="/account/carnet-entretien" exact component={BikeHome} />
    <Route path="/account/carnet-entretien/:id" exact component={MyBike} /> */}
    {/* <Route exact path="/boutiques/:id/:name" component={WorkshopStandalone} /> */}
    <Route
      path="/courses/:cid"
      key={typeof window === 'undefined' ? 1 : window.location.href}
      exact={true}
      component={Course}
    />
    <Route
      path="/courses/:cid/mercipourvotredemande"
      key={typeof window === 'undefined' ? 1 : window.location.href}
      exact={true}
      component={Course}
    />
    <Route
      path="/reset_password/:token/:veriftoken"
      component={ResetPassword}
    />
    {/* <RedirectWithStatus
      status={301}
      from="/rejoignez-nous"
      to="/devenir-reparateur-velo"
    /> */}
    {/* <Route path="/devenir-reparateur-velo" component={RecuitmentFixer} /> */}
    <Route
      path="/paiement/:client_id/:course_id/:token"
      component={PayByLink}
    />
    <Route
      path="/atelier-paiement/:client_id/:course_id/:token"
      component={(props) => <PayByLink isAtelier={true} {...props} />}
    />
    <Route path="/devis-reparation/:qid" component={DiagWrapper} />
    {/* <Route path="/abonnement" exact component={Subscribe} />
    <Route path="/abonnement/inscription" component={SubscribePay} />
    <Route path="/abonnement/confirmation" component={SubscribeConfirmation} /> */}
    {/* <Route path="/certification/:id" component={Certification} />
    <Route path="/certification" component={Certification} /> */}
    <Route
      exact
      path="/annuaire/atelier-velo-trottinette"
      component={AnnuaireHome}
    />
    <Route
      path="/annuaire/atelier-velo-trottinette/letter/:letter"
      component={AnnuaireLetter}
    />
    <Route
      path="/annuaire/atelier-velo-trottinette/:slug"
      component={AnnuaireCity}
    />
    <Route
      path="/ateliers-reparation/:id/:name"
      component={WorkshopStandalone}
    />

    {/* <Route path="/carte-cadeau" exact component={LandingGiftCard} />
    <Route path="/carte-cadeau/activer" component={AddGiftCard} />
    <Route path="/carte-cadeau/acheter" component={BuyGiftCard} /> */}
    <Route component={NotFound} />
  </Switch>
);

class RoutesForReal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prefilled: false,
    };
  }

  componentDidMount() {
    // fills Position with geoloc if no position
    this._prefillFields();
    this._loadUser();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (
        typeof window !== 'undefined' &&
        typeof window.dataLayer !== 'undefined'
      ) {
        window.dataLayer.push({
          event: 'pageview',
          url: window.location.pathname + window.location.search,
        });
        window.dataLayer.push({
          pageview: window.location.pathname + window.location.search,
        });
        analyticsPage();
      }
    }
  }

  render() {
    const { slots } = this.props;
    const { prefilled } = this.state;

    if (typeof window !== 'undefined' && !prefilled)
      // on browser, wait for prefill of query parameters
      return <Loader />;

    return (
      <div>
        <Helmet
          title="Roulez Jeunesse - Réparation de vélos à domicile en 48h"
          meta={[
            {
              name: 'description',
              content:
                'Réparez votre vélo avec Roulez Jeunesse. Réparateurs pros et passionnés, intervention à domicile en 48h, prestations assurées par AXA. Annulation gratuite.',
            },
          ]}
        />
        <HeadBand />
        <Switch>
          <Route path="/widget" component={RepairWidget} />
          {/* <Route
            exact
            path="/ateliers-reparation/:id/:name"
            component={WorkshopStandalone}
          /> */}
          <HeaderFooter withCP>{Routes()}</HeaderFooter>
        </Switch>
      </div>
    );
  }

  async _prefillFields() {
    if (typeof window === 'undefined') return;

    /* we can prefill parameters via url query params */
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('client_id') && urlParams.has('client_token')) {
      const client = await api.get(
        `/clients/${urlParams.get('client_id')}`,
        {},
        { token: urlParams.get('client_token') },
      );
      if (client && client.id && client.token) {
        this.props.setClient(client);
      }
    }

    if (urlParams.has('cp')) this.props.setCodepromo(urlParams.get('cp'));
    if (urlParams.has('fixer_code')) {
      this.props.setFixerCode(urlParams.get('fixer_code'));
    }
    if (urlParams.has('position'))
      await this.props.setAddress(urlParams.get('position'));
    if (urlParams.has('product_id'))
      await this.props.setProductId(urlParams.get('product_id'));
    if (urlParams.has('diag_ids')) {
      let diag_ids = urlParams.get('diag_ids');
      try {
        diag_ids = atob(diag_ids);
      } catch (e) {
        console.log('diag_ids not in b64. Skipping...');
      }
      await this.props.prefillDiag(diag_ids);
    }

    if (urlParams.has('shop_id')) {
      const shop = await api.get(`/shops/${urlParams.get('shop_id')}`);
      if (!shop.error_message) {
        await this.props.setSelectedShop(shop);
      }
    }
    if (urlParams.has('widget') && !!urlParams.get('widget')) {
      const { setWidget } = this.context;
      setWidget(true);
    }

    if (urlParams.has('lang') && i18n.language !== urlParams.get('lang')) {
      this.props.dispatch(setLang(urlParams.get('lang')));
    }

    this.setState({ prefilled: true });
  }

  async _loadUser() {
    if (this.props.client.id) {
      const client = await api.get(`/clients/${this.props.client.id}`);
      if (client.id) this.props.setClient(client);
      else this.props.unsetClient();
    }
  }
}

RoutesForReal.contextType = FeaturesContext;

export default RoutesForReal;
