import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
// Components
import DiscoverOurSolution from '../../components/business/brand/DiscoverOurSolution';
import OfferServiceEntryForNewBike from '../../components/business/brand/OfferServiceEntryForNewBike';
import BeReminded from '../../components/business/shared/BeReminded';
import MaintainPleasure from '../../components/shared/organisms/MaintainPleasure';
import WeTellYouEverything from '../../components/shared/molecules/WeTellYouEverything';
import CustomerReviews from '../../components/business/shared/CustomerReviews';
import HandleCustomerService from '../../components/business/brand/HandleCustomerService';
// Components shared
import Container from '../../components/shared/atoms/Container';
import Hero from '../../components/business/shared/Hero';
import ToolkitFix from '../../components/shared/ToolkitFix';
import SectionImage from '../../components/shared/molecules/SectionImage';
import Link from '../../components/shared/atoms/Link';

const StyledSpan = styled.span`
  color: ${(p) => p.theme.colors.primary.main};
  font-family: LTSaeada;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  font-style: italic;
  ${(p) => p.theme.mediaQueries.desktop} {
    font-size: 28px;
    line-height: 36px;
  }
`;

const StyledToolKitSpring = styled(ToolkitFix)`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 54px;
  width: 104px;
  height: 104px;
  transform: rotate(30deg);
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    top: -44px;
    right: -40px;
    left: auto;
    bottom: auto;
  }
`;
const Brand = () => {
  const { t } = useTranslation();
  return (
    <Container fluid>
      <Hero
        type="marques"
        title={t('brand.heading')}
        bg="violet.lighter"
        text={t('brand.subheading')}
        img="/static/img/photos/hero-image_marque.png"
        toolkit={<StyledToolKitSpring name="spring" color="violet.main" />}
      />
      <SectionImage
        title={t('brand.titles.1')}
        values={[
          { src: '/static/img/logos/logo-cowboy.png' },
          {
            src: '/static/img/logos/logo-super73.png',
            style: { pl: { sm: '5%', lg: 0 } },
          },
          {
            src: '/static/img/logos/logo-voltaire.png',
            style: { px: { sm: '5%', lg: 0 } },
          },
          { src: '/static/img/logos/logo-matebike.png' },
        ]}
      />
      <DiscoverOurSolution />
      <BeReminded
        bg="violet.lighter"
        type="marques"
        setRef={null}
        title={t('brand.titles.2')}
        text={t('brand.texts.2')}
        textBold={null}
        textButton={t('callme.cta')}
        labelButton={<span>{t('brand.cta.text')}</span>}
        infos={false}
        img="/static/img/photos/image-section-1_marque.png"
      />
      <OfferServiceEntryForNewBike />
      <CustomerReviews
        bg="violet.lighter"
        img="/static/img/logos/logo-cowboy.png"
        review={
          <span>
            <Trans
              i18nKey="business.customer.review"
              components={{ styledspan: <StyledSpan /> }}
            />
          </span>
        }
        name="Lindsay Rush"
        job={
          <span>
            <Trans i18nKey="business.customer" />
          </span>
        }
      />
      <BeReminded
        bg="violet.lighter"
        type="marques"
        reverse={true}
        setRef={null}
        title={t('brand.titles.3')}
        text={t('brand.texts.3')}
        textBold={null}
        textButton={t('callme.cta')}
        labelButton={<span>{t('brand.cta.text')}</span>}
        infos={false}
        img="/static/img/photos/image-section-2_marque.png"
      />
      <HandleCustomerService />
      <WeTellYouEverything
        values={[
          {
            title: t('brand.questions.1'),
            text: (
              <span>
                <Trans
                  i18nKey="brand.answers.1"
                  components={{
                    reactLink: (
                      <Link
                        to="/reparateur-a-domicile/"
                        color="gray.darkest"
                        style={{ fontWeight: 600, textDecoration: 'underline' }}
                      />
                    ),
                  }}
                />
              </span>
            ),
          },
          {
            title: t('brand.questions.2'),
            text: (
              <span>
                <Trans i18nKey="brand.answers.2" />
              </span>
            ),
          },
          {
            title: t('brand.questions.3'),
            text: t('brand.answers.3'),
          },
          {
            title: t('brand.questions.4'),
            text: (
              <span>
                <Trans i18nKey="brand.answers.4" />
              </span>
            ),
          },
          {
            title: t('brand.questions.5'),
            text: (
              <span>
                <Trans i18nKey="brand.answers.5" />
              </span>
            ),
          },
          {
            title: t('brand.questions.6'),
            text: (
              <span>
                <Trans i18nKey="brand.answers.6" />
              </span>
            ),
          },
        ]}
      />
      <MaintainPleasure
        title={t('maintainpleasure.titles.1')}
        text={t('maintainpleasure.texts.1')}
        buttonText={t('contactus')}
        link="https://feedback.roulezjeunesse.com/contact-b2b?type=marques"
      />
    </Container>
  );
};
export default Brand;
