import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Helmet from 'react-helmet';
import SignupForm from '../../components/connexionModule/SignupForm';
import ResponsiveHide from '../../components/ResponsiveHide';
import Row from '../../components/shared/atoms/Row';
import Box from '../../components/shared/atoms/Box';
import * as api from '../../utils/api';
import { setClient } from '../../actions/client';
import TitleSelector from '../../components/signupLanding/TitleSelector';
import SubitleSelector from '../../components/signupLanding/SubtitleSelector';
import InfoTextSelector from '../../components/signupLanding/InfoTextSelector';
import MainImageSelector from '../../components/signupLanding/MainImageSelector';
import StepsExplained from '../../components/shared/organisms/StepsExplained';
import Happy32Color from '../../components/icons/Happy32Color';
import Problem32Color from '../../components/icons/Problem32Color';
import Calendar32Color from '../../components/icons/Calendar32Color';
import Heading from '../../components/shared/atoms/Heading';
import Image from '../../components/shared/atoms/Image';
import Text from '../../components/shared/atoms/Text';
import Reviews from '../../components/shared/organisms/Reviews';
import Footer from '../../components/Footer';
import Container from '../../components/shared/atoms/Container';

const SignupLandingHome = ({ partenaire }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const logAndRedirect = (client) => {
    dispatch(setClient(client));

    history.push('/merci');
  };

  const doSignup = async (values, { setSubmitting, setErrors }) => {
    switch (partenaire) {
      case 'ratp':
        values.acquisition_type = 'RATPLANDING';
        break;
      case 'fnac':
      case 'darty':
        values.acquisition_type = 'FNACDARTY';
        break;
      case 'quizz':
        values.acquisition_type = 'GERMINALQUIZZ';
        break;
      case 'communautes':
        values.acquisition_type = 'GERMINALCOMMUNAUTES';
        break;
      case 'inscription':
        values.acquisition_type = 'LANDING_ACQ';
        break;
      case 'clientscdiscount':
        values.acquisition_type = 'LANDING_CDISCOUNT';
        break;
      default:
        values.acquisition_type = 'LANDING';
        break;
    }

    try {
      const rep = await api.post('/clients', values);

      if (rep.id) logAndRedirect(rep);
      else if (rep.errors) {
        const serializedErrors = {};
        for (const k in rep.errors) {
          serializedErrors[k] = rep.errors[k].reduce((i, b) => b + i, '');
        }
        setErrors(serializedErrors);
      }
    } catch (e) {
      console.log('ERREUR CORS', e);
      history.push('/merci');
    }

    setSubmitting(false);
  };

  return (
    <Container fluid>
      <Helmet
        meta={[
          {
            name: 'robots',
            content: 'noindex,follow',
          },
        ]}
      />
      {partenaire !== 'inscription' && (
        <Box>
          <TitleSelector partenaire={partenaire} />
          <SubitleSelector partenaire={partenaire} />
        </Box>
      )}
      <Container>
        <Row md gap={50} mt={4}>
          <Box>
            {partenaire === 'inscription' && (
              <Box mb={5}>
                <TitleSelector partenaire={partenaire} />
                <SubitleSelector partenaire={partenaire} />
                <Box mt={8}>
                  <Heading level={6} textAlign="center" color="black">
                    {t('signuplanding.headings.1')}
                  </Heading>
                  <Text color="black">
                    {t('signuplanding.texts.4')}
                    <br />
                    <br />
                    {t('signuplanding.texts.5')}
                  </Text>
                </Box>
                <Box mt={8}>
                  <Heading level={6} textAlign="center" color="black">
                    {t('signuplanding.headings.2')}
                  </Heading>
                  <StepsExplained
                    mt={5}
                    icons={[
                      <Problem32Color key={1} />,
                      <Calendar32Color key={2} />,
                      <Happy32Color key={3} />,
                    ]}
                    headings={[
                      t('signuplanding.headings.3'),
                      t('signuplanding.headings.4'),
                      t('signuplanding.headings.5'),
                    ]}
                    texts={[
                      t('signuplanding.texts.6'),
                      t('signuplanding.texts.7'),
                      t('signuplanding.texts.8'),
                    ]}
                  />
                </Box>
                <Box mt={8}>
                  <Text>
                    {t('signuplanding.texts.9')}
                    <br />
                    <br />
                    {t('signuplanding.texts.10')}
                  </Text>
                </Box>
              </Box>
            )}
            {partenaire !== 'inscription' && (
              <ResponsiveHide md>
                <div>
                  <MainImageSelector partenaire={partenaire} />
                </div>
              </ResponsiveHide>
            )}
          </Box>
          <Box>
            <Box mb={6}>
              <Image
                src="/static/img/photos/illustration-login-2.png"
                alt="login"
              />
            </Box>
            <div>
              <SignupForm
                withTitle={false}
                withSwitch={false}
                submit={doSignup}
                text={<span />}
                hasTextCheckbox={t('signuplanding.texts.11')}
              />
            </div>
            <Box mt={5} mb={5}>
              <InfoTextSelector partenaire={partenaire} />
              {partenaire !== 'inscription' && (
                <>
                  <br />
                  <small>
                    <a
                      href="https://www.roulezjeunesse.com/mentions-legales"
                      target={'_blank'}>
                      {t('legal.headings.1')}
                    </a>
                  </small>
                </>
              )}
            </Box>
          </Box>
        </Row>
      </Container>
      <Reviews mb={5} mt={8} />
    </Container>
  );
};

export default SignupLandingHome;
