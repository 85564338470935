import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3a4 4 0 014 4v1.5a2 2 0 002 2h1.5A3.5 3.5 0 0121 14h-2a1.5 1.5 0 00-1.5-1.5H16a4 4 0 01-4-4V7a2 2 0 00-2-2V3z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 19h3.077c1.939 0 2.453-.023 2.815-.136a3 3 0 001.972-1.972c.113-.362.136-.876.136-2.815 0-3.012-.023-4.063-.272-4.862a6 6 0 00-3.943-3.943C13.986 5.022 12.935 5 9.923 5c-1.939 0-2.453.023-2.816.136a3 3 0 00-1.971 1.971C5.023 7.47 5 7.984 5 9.923V13c0 1.433.002 2.388.061 3.121.058.71.161 1.035.266 1.241a3 3 0 001.311 1.311c.206.105.53.208 1.24.266.734.06 1.689.061 3.122.061zM3.226 6.512C3 7.24 3 8.134 3 9.923V13c0 2.8 0 4.2.545 5.27a5 5 0 002.185 2.185C6.8 21 8.2 21 11 21h3.077c1.79 0 2.684 0 3.41-.226a5 5 0 003.287-3.287c.226-.726.226-1.62.226-3.41 0-2.863 0-4.295-.362-5.457a8 8 0 00-5.258-5.258C14.218 3 12.786 3 9.923 3c-1.79 0-2.684 0-3.41.226a5 5 0 00-3.287 3.286z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgFile24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgFile24;
