import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
// Components
import Heading from '../shared/atoms/Heading';
import Box from '../shared/atoms/Box';
import Button from '../shared/atoms/Button';
import Text from '../shared/atoms/Text';

const AppPictoContainer = styled(Box)`
  overflow: hidden;
  position: relative;
  top: 0;
`;

const AppPicto = styled.img`
  position: absolute;
  width: 66%;
  left: 0;
  bottom: 0;
  height: auto;
  max-width: 250px;
  ${(p) => p.theme.mediaQueries.tablet} {
    width: 30%;
    max-width: 250px;
  }
  ${(p) => p.theme.mediaQueries.desktop} {
    width: 42%;
    max-width: 300px;
  }
`;

export default function ReferralRequest() {
  const { t } = useTranslation();
  return (
    <Box
      bg="orange.lighter"
      mt={6}
      borderRadius="xs"
      style={{ overflow: 'hidden' }}>
      <Box
        style={{ position: 'relative' }}
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
        alignItems="center">
        <AppPictoContainer
          width={{ md: '35%', lg: '55%' }}
          height={{ xs: 175, lg: 250 }}
          pr={{ xs: 0, md: 5 }}
        />
        <LazyLoad>
          <AppPicto
            src="/static/img/photos/referencement-shop.png"
            alt={t('referralrequest.texts.1')}
          />
        </LazyLoad>
        <Box width={{ xs: 1, md: 2 / 3 }} py={5} pr={5} pl={{ xs: 5, md: 0 }}>
          <Heading level={5} color="black" mb={2}>
            {t('referralrequest.texts.2')}
          </Heading>
          <Text color="black" mb={2}>
            {t('referralrequest.texts.3')}
          </Text>
          <Button
            mt={2}
            mb={2}
            size="compact"
            variant="filled"
            color="primary.main"
            href="https://feedback.roulezjeunesse.com/ajout-modif-annuaire">
            {t('referralrequest.texts.1')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
