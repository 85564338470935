import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M14.116 3.688l4.743-.678a1 1 0 01.282 1.98l-14 2a1 1 0 11-.282-1.98l5.297-.757a2 2 0 013.96-.565zM5.929 8.003a1 1 0 011.068.926l.54 7.542c.056.801.096 1.344.16 1.763.061.407.135.615.219.762a2 2 0 00.865.805c.152.074.365.132.775.165.423.033.967.034 1.77.034h1.348c.803 0 1.347 0 1.77-.034.41-.033.623-.091.775-.165a2 2 0 00.865-.805c.084-.147.158-.355.22-.762.063-.419.103-.962.16-1.763l.539-7.542a1 1 0 011.994.142l-.541 7.582c-.054.752-.098 1.374-.175 1.88-.08.528-.205 1.01-.462 1.458a4 4 0 01-1.73 1.611c-.466.225-.956.315-1.487.358-.511.04-1.135.04-1.889.04h-1.426c-.754 0-1.378 0-1.889-.04-.531-.043-1.02-.133-1.487-.358a4 4 0 01-1.73-1.61c-.257-.45-.382-.931-.462-1.459-.077-.506-.121-1.128-.175-1.88L5.003 9.07a1 1 0 01.926-1.068z"
      fill="#000"
    />
    <path
      d="M14.07 10.153a.85.85 0 01.777.918l-.5 6a.85.85 0 01-1.694-.142l.5-6a.85.85 0 01.918-.776zM9.93 10.153a.85.85 0 01.917.776l.5 6a.85.85 0 01-1.694.142l-.5-6a.85.85 0 01.776-.918z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgTrash24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgTrash24;
