/* eslint-disable no-console */
import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useState,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';

import TwilioService from '../utils/twilio-chat-service';
import * as api from '../utils/api';
import { ToastCtx } from './ToastContext';
import { CoursesCtx } from './CoursesContext';

export const ChatCtx = createContext({});

export default function ChatProvider({ children }) {
  const { id: userId } = useSelector((s) => s.client);

  const { showToast } = useContext(ToastCtx);
  const [channels, setChannels] = useState([]);
  const { courses } = useContext(CoursesCtx);
  const [messages, setMessages] = useState({});
  const [activeChannelId, setActiveChannelId] = useState(null);
  const [clientLoading, setClientLoading] = useState(true);
  const [channelLoading, setChannelLoading] = useState(true);
  const [mediaLoading, setMediaLoading] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  const [moreMessagesLoading, setMoreMessagesLoading] = useState(false);
  const [connectionState, setConnectionState] = useState('');
  const channelPaginator = useRef();
  const chatClientChannel = useRef();
  const totalUnreadCount = useRef();
  const chatMessagesPaginator = useRef();

  const setActiveChannel = useCallback(
    (channelId) => {
      if (channelId) {
        const channel = channels.find((e) => e.id === channelId);
        if (channel) {
          setActiveChannelId(channel.id);
        }
      } else {
        setActiveChannelId(null);
      }
    },
    [channels],
  );

  const getToken = async () => {
    try {
      const { token } = await api.post('/chats/tokens');
      return token;
    } catch (error) {
      throw new Error(`Error fetching chat token: ${error}`);
    }
  };

  const getPrevMessages = async () => {
    if (hasPrev) {
      setMoreMessagesLoading(true);
      const paginator = await chatMessagesPaginator.current.prevPage();
      const parsedMessages = await TwilioService.getInstance().parseMessages(
        paginator.items,
      );
      setMessages((prev) => ({
        ...prev,
        [activeChannelId]: [...parsedMessages, ...prev[activeChannelId]],
      }));
      setHasPrev(paginator.hasPrevPage);
      setMoreMessagesLoading(false);
      chatMessagesPaginator.current = paginator;
    }
  };

  // ********************* //
  // Client Hanlders
  // ********************* //
  const setClientEvents = useCallback(
    (client) => {
      client.on('messageAdded', (message) => {
        setChannels((prev) =>
          prev.map((channel) =>
            channel._id === message.channel.sid
              ? {
                  ...channel,
                  unreadCount:
                    message.state.author === `client_${userId}`
                      ? channel.unreadCount
                      : channel.unreadCount + 1,
                  lastMessageTime: message.dateCreated,
                }
              : channel,
          ),
        );
      });
      client.on('connectionStateChanged', (connectionString) => {
        setConnectionState(connectionString);
      });
      return client;
    },
    [userId],
  );

  const getSubscribedChannels = useCallback(async (client) => {
    const paginator = await client.getSubscribedChannels();
    channelPaginator.current = paginator;
    const newChannels = await TwilioService.getInstance().parseChannels(
      channelPaginator.current.items,
    );
    setChannels(newChannels);
  }, []);

  // ********************* //
  // Channel Hanlders
  // ********************* //
  const getMessagesHistory = useCallback(
    async (paginator) => {
      chatMessagesPaginator.current = paginator;
      const newMessages = await TwilioService.getInstance().parseMessages(
        paginator.items,
      );
      setMessages((prev) => ({ ...prev, [activeChannelId]: newMessages }));
      setHasPrev(paginator.hasPrevPage);
    },
    [activeChannelId],
  );

  const setChannelEvents = useCallback(
    (channel) => {
      chatClientChannel.current = channel;
      chatClientChannel.current.on('messageAdded', async (message) => {
        const newMessage = await TwilioService.getInstance().parseMessage(
          message,
        );
        setMessages((prev) => ({
          ...prev,
          [activeChannelId]: prev[activeChannelId]
            ? [...prev[activeChannelId], newMessage]
            : [newMessage],
        }));
      });
      return chatClientChannel.current;
    },
    [activeChannelId],
  );

  const onSendMessage = useCallback(async (text) => {
    if (text && text.trim().length) {
      await chatClientChannel.current.sendMessage(text.trim());
      chatClientChannel.current.setAllMessagesConsumed();
    }
  }, []);

  const onSendMedia = useCallback(async (formData) => {
    setMediaLoading(true);
    await chatClientChannel.current.sendMessage(formData);
    setMediaLoading(false);
  }, []);

  // ********************* //
  // Client Connect
  // ********************* //
  useEffect(() => {
    if (userId) {
      getToken()
        .then((token) => TwilioService.getInstance().getChatClient(token))
        .then(() => TwilioService.getInstance().addTokenListener(getToken))
        .then(setClientEvents)
        .then(getSubscribedChannels)
        .catch((e) => console.log('💥 Erreur connexion Client Twilio ', e))
        .finally(() => setClientLoading(false));
      return () => TwilioService.getInstance().clientShutdown();
    }
  }, [setClientEvents, getSubscribedChannels, userId]);

  const receiveChannelMessages = (currentChannel) => {
    const channelId = currentChannel.channelState.uniqueName;
    currentChannel.setAllMessagesConsumed();
    setChannels((prev) =>
      prev.map((channel) =>
        channel.id === channelId ? { ...channel, unreadCount: 0 } : channel,
      ),
    );
    totalUnreadCount.current = 0;
    return currentChannel.getMessages();
  };

  // ********************* //
  // Channel Connect
  // ********************* //
  useEffect(() => {
    if (activeChannelId) {
      TwilioService.getInstance()
        .getChatClient()
        .then((client) => client.getChannelByUniqueName(activeChannelId))
        .then((channel) => setChannelEvents(channel))
        .then(receiveChannelMessages)
        .then(getMessagesHistory)
        .catch((e) => console.log('💥 Erreur connexion Channel Twilio ', e))
        .finally(() => setChannelLoading(false));
    }
  }, [activeChannelId, setChannelEvents, getMessagesHistory]);

  // ********************* //
  // In App Notification
  // ********************* //
  useEffect(() => {
    if (channels.length) {
      const count = channels.reduce((a, c) => a + c.unreadCount, 0);
      const unreadChannels = channels.filter((c) => c.unreadCount > 0);
      if (
        count !== totalUnreadCount.current &&
        count > 0 &&
        !window.location.href.includes('/courses/') &&
        !window.location.href.includes('/account') &&
        unreadChannels
      ) {
        const lastCourses = courses.filter(
          (c) =>
            c.fixer_id === unreadChannels[0].id.slice(-2) &&
            c.state === 'scheduled',
        );
        totalUnreadCount.current = count;
        showToast({
          type: 'primary.main',
          unique: true,
          title: 'Nouveau message',
          description: `Vous avez ${count} message${
            count > 1 ? 's' : ''
          } non lu`,
          to: lastCourses.length ? `/courses/${lastCourses[0].id}` : '/account',
          btnLabel: 'Voir les messages',
        });
      }
    }
  }, [channels, showToast]);

  return (
    <ChatCtx.Provider
      value={{
        channels,
        clientLoading,
        channelLoading,
        mediaLoading,
        setActiveChannel,
        onSendMessage,
        onSendMedia,
        connectionState,
        messages: messages[activeChannelId] || [],
        moreMessagesLoading,
        getPrevMessages,
        hasPrev,
      }}>
      {children}
    </ChatCtx.Provider>
  );
}
