import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M8.708 1.357a4.53 4.53 0 014.966 7.373l-1.847 1.847.057.057c.429.429.643.643.657.827a.5.5 0 01-.173.42c-.14.119-.444.119-1.05.119H10.05c-.28 0-.42 0-.527-.055a.5.5 0 01-.218-.218c-.055-.107-.055-.247-.055-.527V9.931c0-.605 0-.908.12-1.049a.5.5 0 01.42-.173c.183.014.397.228.826.657l.15.15 1.847-1.847a3.028 3.028 0 00-3.321-4.93.75.75 0 11-.584-1.382zM5.234 6.484l.15.15c.429.429.643.643.827.657a.5.5 0 00.42-.173c.119-.14.119-.444.119-1.05V4.8c0-.28 0-.42-.054-.527a.5.5 0 00-.219-.218C6.37 4 6.23 4 5.95 4H4.681c-.605 0-.908 0-1.049.12a.5.5 0 00-.173.42c.014.183.228.397.657.826l.057.057L2.326 7.27a4.528 4.528 0 004.966 7.373.75.75 0 00-.584-1.381A3.03 3.03 0 013.387 8.33l1.847-1.847z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgSwitch16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgSwitch16;
