import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import useDropdown from '../hooks/useDropdown';
import { colors } from '../styles/constants.jsx';
import Box from './shared/atoms/Box';
import Text from './shared/atoms/Text';
import AngleUp24 from './icons/AngleUp24';
import AngleDown24 from './icons/AngleDown24';

const StyledDropdown = styled.div`
  background: ${(p) => (p.bg ? p.bg : 'white')};
  width: 100%;
  min-height: 32px;
  position: relative;
  ${(p) =>
    !p.inline &&
    css`
      margin-bottom: 1rem;
    `}
  ${(p) =>
    p.isOpen &&
    css`
      border-bottom: none;
    `}
  .drop-item {
    padding: 0.5rem 1rem;
    &:hover {
      background: ${colors.lightgrey};
      color: inherit;
    }
  }
  .active {
    background: ${colors.blue};
    color: white;
  }
`;

const StyledInput = styled(Box)`
  cursor: pointer;
  ${(p) =>
    p.inline &&
    css`
      font-weight: 300;
    `}
`;
const StyledDropOptions = styled(Box)`
  position: absolute;
  z-index: 1002;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
`;

function Dropdown({ options, value, activeId, onChange, inline, ...props }) {
  const [containerRef, isOpen, open, close] = useDropdown();
  const onItemClick = (id) => {
    close();
    onChange(id);
  };

  return (
    <StyledDropdown
      ref={containerRef}
      inline={inline}
      isOpen={isOpen}
      {...props}>
      <StyledInput
        flexDirection="row"
        justifyContent="space-between"
        borderColor="gray.light"
        borderWidth="1px"
        borderRadius="xs"
        borderStyle="solid"
        bg="white"
        p={3}
        onClick={isOpen ? close : open}>
        <Text fontWeight={2}>{value}</Text>
        {isOpen ? <AngleUp24 /> : <AngleDown24 />}
      </StyledInput>
      <StyledDropOptions
        mt={2}
        bg="white"
        boxShadow="lg"
        borderWidth="1px"
        borderRadius="xs"
        display={isOpen ? 'block' : 'none'}>
        {options
          .filter((o) => o.value !== 0)
          .map((opt) => (
            <div
              key={opt.value}
              onClick={() => onItemClick(opt.value)}
              className={`drop-item ${opt.value === activeId && 'active'}`}>
              <Text fontWeight={2}>{opt.label}</Text>
            </div>
          ))}
      </StyledDropOptions>
    </StyledDropdown>
  );
}

Dropdown.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  activeId: PropTypes.number,
  options: PropTypes.array,
};

export default Dropdown;
