import React from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Field, Form, Formik } from 'formik';
// Components
import { Trans } from 'react-i18next';
import Text from '../shared/atoms/Text';
import Link from '../shared/atoms/Link';
import Badge from '../shared/atoms/Badge';
import OpenHoursShop from './OpenHoursShop';
import Button from '../shared/atoms/Button';
import Input from '../shared/atoms/Input';
import Box from '../shared/atoms/Box';
// Icons
import ShopColor56 from '../icons/ShopColor56';
import Pin24 from '../icons/Pin24';
import Phone24 from '../icons/Phone24';
import Web24 from '../icons/Web24';
import Hour24 from '../icons/Hour24';
import MessageColor56 from '../icons/MessageColor56';
import Alert from '../shared/molecules/Alert';

const ContactValidationSchema = Yup.object().shape({
  message: Yup.string().required('Un message est requis'),
  email: Yup.string()
    .required("L'email est requis")
    .email("L'email est invalide"),
});

const StyledLink = styled(Link)`
  text-decoration-line: underline;
`;

const showModalFiche = (shop, open_hours, today, showModal, t) =>
  showModal({
    title: shop.name,
    content: (
      <>
        <Box flexDirection="row" alignItems="center" mb={2}>
          <Badge bg="gray.lightest" mr={4}>
            <Pin24 />
          </Badge>
          {shop.address ? (
            <Text>{shop.address}</Text>
          ) : (
            <Text fontStyle="italic" color="gray.darker">
              {t('displayshopmodal.texts.1')}
            </Text>
          )}
        </Box>
        <Box flexDirection="row" alignItems="center" mb={2}>
          <Badge bg="gray.lightest" mr={4}>
            <Phone24 />
          </Badge>
          {shop.phone ? (
            <Text>{shop.phone}</Text>
          ) : (
            <Text fontStyle="italic" color="gray.darker">
              {t('displayshopmodal.texts.2')}
            </Text>
          )}
        </Box>
        <Box flexDirection="row" alignItems="center" mb={2}>
          <Badge bg="gray.lightest" mr={4}>
            <Web24 />
          </Badge>
          {shop.website ? (
            <StyledLink href={shop.website} color="black">
              {shop.website}
            </StyledLink>
          ) : (
            <Text fontStyle="italic" color="gray.darker">
              {t('displayshopmodal.texts.3')}
            </Text>
          )}
        </Box>
        <Box flexDirection="row" alignItems="flex-start" mb={2}>
          <Badge bg="gray.lightest" mr={4}>
            <Hour24 />
          </Badge>
          {open_hours ? (
            <OpenHoursShop {...{ open_hours, today }} />
          ) : (
            <Text fontStyle="italic" color="gray.darker">
              {t('hoursnotspecified')}
            </Text>
          )}
        </Box>
      </>
    ),
    showModal: true,
    showButton: [
      {
        text: t('close.cta')
      },
    ],
  });

export const showModalMeetShop = ({
  shop,
  showModal,
  submitEmail,
  t,
  sendEmail,
  showFiche,
}) =>
  showModal({
    title: `Contacter ${shop.name}`,
    content: (
      <>
        <Box alignItems="center" mb={5}>
          <Text mb={4}>{t('displayshopmodal.texts.5')}</Text>
          {sendEmail === 'success' && (
            <Alert status="success" style={{ width: '100%' }}>
              {t('send.mail.success')}
            </Alert>
          )}
          {sendEmail === 'error' && (
            <Alert status="danger" style={{ width: '100%' }}>
              <Trans
                i18nKey="send.mail.error"
                components={{
                  reactLink: (
                    <Link
                      style={{ textDecoration: 'underline' }}
                      onClick={showFiche}
                    />
                  ),
                }}
              />
            </Alert>
          )}
        </Box>
        <Box flexDirection="row" alignItems="center" width="100%">
          <Formik
            initialValues={{ email: '', telephone: '', message: '' }}
            validationSchema={ContactValidationSchema}
            onSubmit={(values, { setSubmitting }) =>
              submitEmail({ values, shop })
            }>
            {({ isSubmitting, setFieldValue, submitCount, errors }) => (
              <Form style={{ width: '100%' }}>
                <Field
                  name="email"
                  render={({ field }) => (
                    <Input
                      label={t('E-mail')}
                      colorLabel="gray.darkest"
                      importantLabel={true}
                      mb={{ xs: 2, lg: 4 }}
                      color="black"
                      bg="white"
                      placeholder={t('email')}
                      onChange={(e) => {
                        const value = e.target.value.toLowerCase().trim();
                        setFieldValue('email', value);
                      }}
                      error={!!submitCount && errors.email}
                      {...field}
                    />
                  )}
                />
                <Field
                  name="telephone"
                  render={({ field }) => (
                    <Input
                      label={t('phone')}
                      colorLabel="gray.darkest"
                      mb={{ xs: 2, lg: 4 }}
                      color="black"
                      bg="white"
                      placeholder="012356789"
                      onChange={(e) => setFieldValue('telephone', e)}
                      {...field}
                    />
                  )}
                />
                <Field
                  name="message"
                  render={({ field }) => (
                    <Input
                      label={t('message')}
                      colorLabel="gray.darkest"
                      importantLabel={true}
                      as="textarea"
                      mb={{ xs: 2, lg: 4 }}
                      color="black"
                      bg="white"
                      rows="3"
                      onChange={(e) => setFieldValue('message', e)}
                      placeholder={t('displayshopmodal.texts.4')}
                      error={!!submitCount && errors.message}
                      {...field}
                    />
                  )}
                />
                <Box mt={5}>
                  <Button
                    disabled={isSubmitting || sendEmail}
                    style={{ width: '100%' }}>
                    {t('send')}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </>
    ),
    showModal: true,
    icon: <MessageColor56 />,
  });

export default showModalFiche;
