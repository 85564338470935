import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
// Components shared
import Container from '../../shared/atoms/Container';
import Box from '../../shared/atoms/Box';
import Row from '../../shared/atoms/Row';
import Heading from '../../shared/atoms/Heading';
import Text from '../../shared/atoms/Text';
import Link from '../../shared/atoms/Link';

const StyledImgUnboxing = styled.img`
  border-radius: 16px;
  width: 100%;

  height: auto;
  align-self: center;
`;
const StyledImgScreenShot = styled.img`
  width: 100%;
  min-height: 240px;
`;
const OfferServiceEntryForNewBike = () => {
  const { t } = useTranslation();
  return (
    <Container mb={{ xs: 7, md: 10 }}>
      {/* Main Row */}
      <Row md gap={24}>
        {/* Left Box */}
        <Box style={{ flex: 2 }}>
          <Row
            md
            border="1px solid"
            borderColor="gray.light"
            borderRadius="lg"
            p={5}
            gap={24}>
            <Box justifyContent="center">
              <Heading level={4}>{t('serviceentry.titles.1')}</Heading>
              <Text>{t('serviceentry.texts.1')}</Text>
            </Box>
            <Box>
              <StyledImgUnboxing src="/static/img/pictos/image-section-1-1_marque.png"></StyledImgUnboxing>
            </Box>
          </Row>
          <Row md gap={24} mt={5}>
            <Box bg="yellow.main" borderRadius="lg" p={5}>
              <StyledImgScreenShot src="/static/img/photos/business/image-section-1-5_marque.png" />
              <Heading level={4} mt={5}>
                {t('serviceentry.titles.1')}
              </Heading>
              <Text>{t('serviceentry.texts.2')}</Text>
            </Box>
            <Box
              border="1px solid"
              borderColor="gray.light"
              borderRadius="lg"
              p={5}>
              <Heading level={4}>{t('serviceentry.titles.3')}</Heading>
              <Text mb={5}>{t('serviceentry.texts.3')}</Text>
              <StyledImgUnboxing src="/static/img/pictos/image-section-1-2_marque.png" />
            </Box>
          </Row>
        </Box>
        {/* Right Box */}
        <Box>
          <Box bg="violet.lighter" borderRadius="lg" px={5} pt={5}>
            <Heading level={4}>{t('serviceentry.titles.4')}</Heading>
            <Text>{t('serviceentry.texts.4')}</Text>
            <StyledImgScreenShot src="/static/img/photos/business/image-section-1-4_marque.png" />
          </Box>
          <Box
            border="1px solid"
            borderColor="gray.light"
            borderRadius="lg"
            p={5}
            mt={5}>
            <Heading level={4}>{t('serviceentry.titles.5')}</Heading>
            <Text mb={5}>
              <Trans
                i18nKey="serviceentry.texts.5"
                components={{
                  reactLink: <Link to="/tarifs/velo" color="#2e414b" />,
                }}
              />
            </Text>
            <StyledImgUnboxing src="/static/img/pictos/image-section-1-3_marque.png" />
          </Box>
        </Box>
      </Row>
    </Container>
  );
};

export default OfferServiceEntryForNewBike;
