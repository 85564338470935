import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import Container from '../components/shared/atoms/Container';
import Box from '../components/shared/atoms/Box';
import Heading from '../components/shared/atoms/Heading';
import Text from '../components/shared/atoms/Text';
import Button from '../components/shared/atoms/Button';
// Icons
import AngleLeft16 from '../components/icons/AngleLeft16';

const Title = styled(Heading)`
  font-size: 80px;
  font-weight: 400;
  margin: 0;
`;

const StyledImg = styled.img`
  width: 415px;
  margin-left: 60px;
`;

const NotFound = (props) => {
  const { t } = useTranslation();
  if (typeof props.staticContext !== 'undefined') {
    // eslint-disable-next-line no-param-reassign
    props.staticContext.statusCode = 404;
  }
  return (
    <Container fluid>
      <Box
        bg="gray.lightest"
        alignItems="center"
        borderRadius="xl"
        flexDirection="row"
        p={{ l: '80px', xl: '175px' }}
        mb={9}>
        <Box maxWidth="453px">
          <Title color="black">{t('error')}</Title>
          <Heading level={5} color="black" my={4}>
            {t('notfound.heading')}
          </Heading>
          <Text my={4}>{t('error.404')}</Text>
          <Text mb={5}>{t('notfound.texts.1')}</Text>
          <Button to="/" before={<AngleLeft16 />}>
            {t('notfound.accueil.cta')}
          </Button>
        </Box>
        <StyledImg src="/static/img/pictos/404-error-bike.jpg" style={{ borderRadius: '50px' }} />
      </Box>
    </Container>
  );
};

export default NotFound;
