import React from 'react';
import TallyForm from '../../components/TallyForm';

function RepairAutodiag(props) {
    return (
        <div>
            <TallyForm {...props} />
        </div>
    );
}

export default RepairAutodiag;
