import React from 'react';
import { useTranslation } from 'react-i18next';
// Components
import Box from '../atoms/Box';
import Text from '../atoms/Text';
import Container from '../atoms/Container';
import Heading from '../atoms/Heading';
import Button from '../atoms/Button';
import SectionBackground from '../molecules/SectionBackground';
// Icon
import AngleRight16 from '../../icons/AngleRight16';
import Alert from '../molecules/Alert';

const Explanation = ({ image, title, paragraph, ...rest }) => (
  <Box alignItems="center" flex="1" {...rest}>
    {image}
    <Heading level={5} mt={4} mb="6px" textAlign="center">
      {title}
    </Heading>
    <Text textAlign="center">{paragraph}</Text>
  </Box>
);

export const Content = ({
  content,
  title,
  levelTitle,
  breakPoints = 'lg',
  t,
  ...props
}) => (
  <>
    <Box alignItems="center" {...props}>
      <Box width={{ xs: 1, [breakPoints]: content ? '70%' : 1 }}>
        <Heading
          level={levelTitle || 2}
          mt={2}
          mb={5}
          textAlign="center"
          color="black">
          {content
            ? t(`how.headings.1`, { city: content.city })
            : title || t('how.headings.2')}
        </Heading>
      </Box>
    </Box>

    <Box flexDirection={{ xs: 'column', [breakPoints]: 'row' }} mb={6}>
      <Explanation
        mr={{ xs: 0, [breakPoints]: 4 }}
        title={content ? t('availablesixseven') : t('how.headings.3')}
        paragraph={content ? content.texts[0] : t('how.contents.1')}
      />
      <Explanation
        mx={4}
        my={{ xs: 7, [breakPoints]: 0 }}
        title={content ? t('how.headings.4') : t('how.headings.5')}
        paragraph={content ? content.texts[1] : t('how.contents.2')}
      />
      <Explanation
        ml={{ xs: 0, [breakPoints]: 4 }}
        title={content ? t('how.headings.6') : t('how.headings.7')}
        paragraph={content ? content.texts[2] : t('how.contents.3')}
      />
    </Box>
  </>
);

const HowItWorks = ({
  content = null,
  isLanding = false,
  bg = null,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <SectionBackground alignItems="center" bg={bg} p={bg && 10} {...rest}>
      <Container>
        <Content content={content} t={t} />
        {content && content[3] && (
          <Box alignItems="center" mb={6}>
            <Box width={{ xs: 1, lg: 3 / 4 }}>
              <Alert title={t('how.alert')}>{content[3]}</Alert>
            </Box>
          </Box>
        )}
        <Box alignItems="center" justifyContent="center" flexDirection="row">
          <Button
            href={isLanding ? 'https://www.roulezjeunesse.com/' : null}
            minWidth="250px"
            after={<AngleRight16 />}
            animation
            to={isLanding ? false : '/reparation'}>
            {t('button.book')}
          </Button>
        </Box>
      </Container>
    </SectionBackground>
  );
};

export default HowItWorks;
