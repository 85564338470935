import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M19.273 10a6 6 0 016-6H46a6 6 0 016 6v12a6 6 0 01-6 6h-2.182v7.636L34 28h-8.727a6 6 0 01-6-6V10z"
      fill="#1D859E"
    />
    <mask id="Messaging_56_Color_svg__a" fill="#fff">
      <path d="M36.727 26.364a6 6 0 00-6-6H10a6 6 0 00-6 6v12a6 6 0 006 6h2.182V52L22 44.364h8.727a6 6 0 006-6v-12z" />
    </mask>
    <path
      d="M12.182 44.364v-2h2v2h-2zm0 7.636l1.228 1.579-3.228 2.51V52h2zM22 44.364l-1.228-1.58.542-.42H22v2zm8.727-22H10v-4h20.727v4zM6 26.364v12H2v-12h4zm28.727 12v-12h4v12h-4zm-24.727 4h2.182v4H10v-4zm4.182 2V52h-4v-7.636h4zm-3.228 6.057l9.818-7.636 2.456 3.157-9.818 7.637-2.456-3.158zM22 42.364h8.727v4H22v-4zm16.727-4a8 8 0 01-8 8v-4a4 4 0 004-4h4zM6 38.364a4 4 0 004 4v4a8 8 0 01-8-8h4zm4-16a4 4 0 00-4 4H2a8 8 0 018-8v4zm20.727-4a8 8 0 018 8h-4a4 4 0 00-4-4v-4z"
      fill="#000"
      mask="url(#Messaging_56_Color_svg__a)"
    />
    <path
      d="M29.91 16.41a1.5 1.5 0 11-3.001-.001 1.5 1.5 0 013 0zM37 16.41a1.5 1.5 0 11-3-.001 1.5 1.5 0 013 0zM42.59 17.91a1.5 1.5 0 100-3.001 1.5 1.5 0 000 3z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgMessaging56Color = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgMessaging56Color;
