import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.267 16.688c-.747 1.133-.755 1.906-.527 2.302.229.396.903.776 2.257.695 1.292-.076 2.926-.575 4.563-1.52 1.636-.944 2.885-2.11 3.597-3.191.747-1.132.755-1.906.526-2.302-.228-.396-.902-.775-2.256-.695-1.292.076-2.926.575-4.563 1.52-1.637.944-2.885 2.11-3.597 3.191zm-1.571-1.034c.905-1.373 2.392-2.726 4.228-3.786 1.835-1.06 3.751-1.671 5.393-1.769 1.579-.093 3.22.289 3.996 1.632.776 1.343.286 2.957-.585 4.277-.905 1.373-2.393 2.727-4.228 3.786-1.835 1.06-3.751 1.671-5.393 1.769-1.579.094-3.221-.289-3.997-1.632-.775-1.343-.285-2.956.586-4.277z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.464 13.588c-.611.928-.578 1.502-.433 1.753.145.251.626.567 1.735.502 1.048-.063 2.386-.468 3.733-1.246 1.348-.778 2.368-1.734 2.946-2.61.611-.928.578-1.503.433-1.754-.145-.251-.626-.567-1.735-.501-1.048.062-2.386.468-3.733 1.245-1.348.778-2.368 1.734-2.946 2.61zm-1.57-1.035c.77-1.168 2.03-2.312 3.575-3.204 1.546-.893 3.167-1.412 4.564-1.495 1.334-.079 2.784.24 3.475 1.438.692 1.198.244 2.613-.492 3.73-.77 1.167-2.03 2.31-3.576 3.204-1.546.892-3.166 1.411-4.563 1.494-1.335.08-2.784-.24-3.476-1.438-.692-1.198-.244-2.612.492-3.729z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.602 10.382c-.477.724-.403 1.1-.341 1.206.06.106.349.358 1.214.307.803-.048 1.845-.361 2.903-.972 1.058-.61 1.85-1.357 2.294-2.028.477-.724.402-1.1.34-1.206-.06-.106-.349-.359-1.214-.307-.803.047-1.845.36-2.903.971s-1.85 1.357-2.293 2.03zM6.03 9.348c.635-.964 1.667-1.898 2.923-2.623 1.257-.726 2.582-1.152 3.734-1.22 1.09-.066 2.346.19 2.954 1.243.609 1.053.202 2.269-.4 3.18-.635.965-1.667 1.898-2.923 2.624-1.257.725-2.581 1.152-3.734 1.22-1.09.065-2.346-.19-2.954-1.244-.608-1.053-.202-2.268.4-3.18z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.553 7.938c.028.024.184.144.691.114.559-.033 1.305-.254 2.074-.697.769-.444 1.333-.98 1.641-1.448.28-.424.253-.62.247-.656-.028-.024-.185-.144-.692-.114-.558.033-1.305.254-2.074.697-.768.444-1.333.98-1.64 1.448-.28.424-.254.62-.247.656zm-1.325-1.69c.501-.76 1.305-1.484 2.272-2.042.967-.559 1.996-.893 2.904-.947.846-.05 1.909.142 2.433 1.05.525.909.16 1.925-.307 2.633-.5.76-1.304 1.483-2.272 2.041-.967.559-1.995.893-2.903.947-.846.05-1.91-.142-2.434-1.05-.524-.909-.159-1.925.307-2.633z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgTool624 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgTool624;
