import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.9 4a.9.9 0 00-1.8 0V4.238c0 .808 0 1.469.044 2.006.045.556.142 1.058.381 1.527A3.9 3.9 0 004.23 9.475c.47.239.971.336 1.527.381.537.044 1.198.044 2.006.044h4.05c-.174.189-.402.417-.711.726l-.737.738a.9.9 0 101.272 1.272l.738-.737.025-.025c.374-.375.693-.694.934-.977.253-.298.471-.614.597-1a2.9 2.9 0 000-1.793c-.126-.386-.344-.703-.597-1.001-.24-.283-.56-.602-.934-.977l-.025-.025-.738-.737a.9.9 0 00-1.272 1.272l.737.738c.309.309.537.537.71.726H7.8c-.855 0-1.442 0-1.897-.038-.445-.036-.684-.103-.856-.19a2.1 2.1 0 01-.918-.919c-.088-.172-.155-.411-.191-.856C3.9 5.642 3.9 5.055 3.9 4.2V4z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgArrowEnterRight16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgArrowEnterRight16;
