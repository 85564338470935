import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M8 12.294a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM9.25 15.044a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM12 12.294a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM13.25 15.044a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM16.25 12.294a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2.1a.9.9 0 01.9.9v1.013C9.5 4 10.192 4 11 4h2.917c.55 0 1.021 0 1.433.006V3a.9.9 0 111.8 0v1.144c.162.032.313.072.457.121a5 5 0 013.128 3.128c.265.781.265 1.751.265 3.69 0 .774 0 1.438-.009 2.017H21c0 .09-.005.18-.013.268-.027 1.298-.11 2.149-.358 2.882a7 7 0 01-4.379 4.378c-.73.248-1.579.331-2.87.358a2.88 2.88 0 01-.28.014v-.009c-.579.009-1.242.009-2.017.009H10.5c-2.33 0-3.495 0-4.413-.38a5 5 0 01-2.706-2.707C3 16.994 3 15.83 3 13.5V12c0-2.8 0-4.2.545-5.27A5 5 0 015.73 4.545c.394-.2.833-.328 1.37-.408V3a.9.9 0 01.9-.9zm11.2 8.983c0 .886-.002 1.591-.013 2.165a.85.85 0 01-.837.702h-1.306a2.9 2.9 0 00-2.9 2.9v1.305c0 .52-.38.952-.877 1.032-.578.01-1.289.013-2.184.013H10.5c-1.19 0-1.991-.001-2.613-.043-.606-.042-.908-.116-1.112-.2a3.2 3.2 0 01-1.731-1.732c-.085-.204-.16-.506-.2-1.112C4.8 15.491 4.8 14.69 4.8 13.5V12c0-1.43.001-2.394.062-3.137.06-.723.165-1.077.287-1.316A3.2 3.2 0 016.547 6.15c.134-.068.303-.131.553-.184V7a.9.9 0 001.8 0V5.813C9.47 5.8 10.154 5.8 11 5.8h2.917c.585 0 1.053.002 1.433.008V7a.9.9 0 101.8 0v-.986a3.2 3.2 0 011.88 1.957c.146.43.17 1.027.17 3.112zm-.282 4.606a5.2 5.2 0 01-3.06 3.168 2.85 2.85 0 00.086-.701V16.85a1.1 1.1 0 011.1-1.1h1.306c.195 0 .385-.021.568-.061z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgCalendar24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgCalendar24;
