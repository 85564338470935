import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M11.094 3.563a1.01 1.01 0 011.812 0l2.125 4.307c.148.298.432.505.761.553l4.753.69a1.01 1.01 0 01.56 1.724l-3.44 3.352a1.01 1.01 0 00-.29.894l.812 4.734a1.01 1.01 0 01-1.466 1.065l-4.25-2.235a1.01 1.01 0 00-.941 0l-4.25 2.235a1.01 1.01 0 01-1.467-1.066l.812-4.733a1.01 1.01 0 00-.29-.894l-3.44-3.352a1.01 1.01 0 01.56-1.724l4.753-.69a1.01 1.01 0 00.76-.553l2.126-4.307z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgStarfill24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgStarfill24;
