import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { currencyFormat, uniquePrestaPiece } from '../../../utils/utils';
import { getAuthParameters } from '../../../utils/api';
// Components
import Box from '../../shared/atoms/Box';
import Text from '../../shared/atoms/Text';
import Heading from '../../shared/atoms/Heading';
import Link from '../../shared/atoms/Link';
import Separator from '../../Separator';
// Icons
import Download16 from '../../icons/Download16';
import { yieldBonusType } from '../../../utils/slots';

const CourseBill = ({ course }) => {
  const { t } = useTranslation();
  const prestations = Object.values(uniquePrestaPiece(course.prestations));
  const pieces = Object.values(uniquePrestaPiece(course.pieces));

  const getBill = () => {
    const courseid = course.id;
    const auth = getAuthParameters();
    if (auth) {
      window.location = `https://api.roulezjeunesse.com/courses/${courseid}/invoice?ctk=${encodeURIComponent(
        auth.token,
      )}&cti=${auth.id}`;
    }
  };
  return (
    <Box mb={9}>
      <Heading level={4} mb={4}>
        {t('coursebill.heading')}
      </Heading>
      <Box
        p={{ xs: 4, lg: 5 }}
        borderColor="gray.light"
        borderWidth="1px"
        borderRadius="md"
        borderStyle="solid">
        {prestations.map((prestation, index) => {
          return (
            <Box
              key={prestation.prestaPiece.id}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              mt={index == 0 ? 0 : 2}>
              <Box flexDirection="row" alignItems="center">
                <Box
                  borderColor="gray.light"
                  borderWidth="1px"
                  borderRadius="xxs"
                  borderStyle="solid"
                  width={21}
                  height={21}
                  alignItems="center"
                  justifyContent="center"
                  mr={2}>
                  {prestation.nb}
                </Box>
                <Text>{prestation.prestaPiece.nom}</Text>
              </Box>
              <Box ml={4}>
                <Text>
                  {currencyFormat(
                    prestation.prestaPiece.prix * prestation.nb,
                    prestation.prestaPiece.currency,
                  )}
                </Text>
              </Box>
            </Box>
          );
        })}
        {pieces.map((prestation, index) => {
          return (
            <Box
              key={prestation.prestaPiece.id}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              mt={2}>
              <Box flexDirection="row" alignItems="center">
                <Box
                  borderColor="gray.light"
                  borderWidth="1px"
                  borderRadius="xxs"
                  borderStyle="solid"
                  width={21}
                  height={21}
                  alignItems="center"
                  justifyContent="center"
                  mr={2}>
                  {prestation.nb}
                </Box>
                <Text>{prestation.prestaPiece.modele}</Text>
              </Box>
              <Box ml={4}>
                <Text>
                  {currencyFormat(
                    prestation.prestaPiece.prix_vente *
                    prestation.nb *
                    prestation.nb,
                    prestation.prestaPiece.currency,
                  )}
                </Text>
              </Box>
            </Box>
          );
        })}
        {!!course.fee_service && (
          <Box flexDirection="row" justifyContent="space-between">
            <Text>Frais de service</Text>
            <Text fontWeight={2}>{course.fee_service}€</Text>
          </Box>
        )}
        {!!course.fee_delivery && (
          <Box flexDirection="row" justifyContent="space-between">
            <Text>Frais de déplacement</Text>
            <Text fontWeight={2}>{course.fee_delivery}€</Text>
          </Box>
        )}
        {!!course.yield_bonus && (
          <Box flexDirection="row" justifyContent="space-between">
            <Text>Supplément créneau {yieldBonusType(course.date)}</Text>
            <Text fontWeight={2}>{course.yield_bonus}€</Text>
          </Box>
        )}
        <Text textAlign="right" mt={4} color="black">
          <Trans
            i18nKey="coursebill.texts.1"
            components={{ strong: <strong /> }}
            values={{
              prix: course.prix + course.reduction,
            }}
          />
        </Text>
        <Separator axe="horizontal" my={4} />
        <Box>
          <Box flexDirection="row" justifyContent="space-between">
            <Text>{t('coursebill.texts.2')}</Text>
            <Text fontWeight={2}>
              {currencyFormat(course.tip, course.currency)}
            </Text>
          </Box>
          {!!course.reduction && (
            <Box flexDirection="row" justifyContent="space-between">
              <Text>{t('reduction')}</Text>
              <Text fontWeight={2}>- {course.reduction}€</Text>
            </Box>
          )}
        </Box>
        <Separator axe="horizontal" my={4} />
        <Box
          flexDirection={{ xs: 'column-reverse', lg: 'row' }}
          justifyContent={{ lg: 'space-between' }}
          alignItems="flex-end">
          <Link
            before={<Download16 />}
            color="blue.electric"
            fontWeight="bold"
            onClick={() => getBill()}
            mt={{ xs: 4, lg: 0 }}>
            {t('invoice.download')}
          </Link>
          <Text color="black" fontSize="lg">
            <Trans
              i18nKey="coursebill.texts.3"
              values={{
                prix: course.prix,
              }}
            />
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default CourseBill;
