import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.752 9.194a1.1 1.1 0 011.554.057l1.81 1.95c.828.891 1.403 1.509 1.887 1.95.476.433.787.622 1.06.715a2.9 2.9 0 001.874 0c.273-.093.585-.282 1.06-.715.484-.441 1.06-1.059 1.887-1.95l1.81-1.95a1.1 1.1 0 011.612 1.498l-1.843 1.984c-.787.848-1.424 1.534-1.985 2.045-.578.525-1.15.938-1.83 1.17a5.1 5.1 0 01-3.296 0c-.68-.232-1.253-.645-1.83-1.17-.561-.511-1.198-1.197-1.985-2.045l-1.843-1.985a1.1 1.1 0 01.058-1.554z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgAngledown24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgAngledown24;
