import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g
      clipPath="url(#Happy_Color_56_svg__clip0)"
      fillRule="evenodd"
      clipRule="evenodd">
      <path
        d="M50 27.5C50 39.335 40.19 49 28 49S6 39.335 6 27.5 15.81 6 28 6s22 9.665 22 21.5zm2 0C52 40.479 41.255 51 28 51S4 40.479 4 27.5 14.745 4 28 4s24 10.521 24 23.5zM17 22a1 1 0 00-.978 1.207C17.21 28.823 22.369 33 28.5 33c6.13 0 11.29-4.177 12.478-9.793A1 1 0 0040 22H17zm11.5 9c-4.762 0-8.766-2.952-10.184-7h20.368c-1.418 4.048-5.422 7-10.184 7zM35 17a2 2 0 00-2 2 1 1 0 11-2 0 4 4 0 018 0 1 1 0 11-2 0 2 2 0 00-2-2zm-16 2a2 2 0 114 0 1 1 0 102 0 4 4 0 00-8 0 1 1 0 102 0z"
        fill="#000"
      />
      <path
        d="M55.172 31.885a2.867 2.867 0 01.055 4.054L39.604 51.992 35.503 52l-7.31-7.453a2.867 2.867 0 114.093-4.015l5.255 5.359 13.577-13.95a2.867 2.867 0 014.054-.056z"
        fill="#28D898"
      />
      <path
        d="M6.007 16a1 1 0 10-2 0v2H3a1 1 0 100 2h1.007v2a1 1 0 102 0v-2H7a1 1 0 100-2h-.993v-2z"
        fill="#FFE249"
      />
      <path
        d="M20 37a1 1 0 10-2 0v1.966h-1a1 1 0 100 2h1V43a1 1 0 102 0v-2.034h1a1 1 0 100-2h-1V37z"
        fill="#d5ff4f"
      />
      <path
        d="M52.002 5a1 1 0 10-2 0v2H49a1 1 0 100 2h1.002v2a1 1 0 102 0V9h1.002a1 1 0 100-2h-1.002V5z"
        fill="#1F77F9"
      />
    </g>
    <defs>
      <clipPath id="Happy_Color_56_svg__clip0">
        <path fill="#fff" d="M0 0h56v56H0z" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgHappyColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgHappyColor56;
