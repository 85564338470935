import moment from 'moment';
import i18n from '../i18n';

export const SET_LANG = 'SET_LANG';

const langs = ['fr', 'en'];

export function setLang(lang) {
  if (!~langs.indexOf(lang)) {
    lang = 'fr';
  }
  i18n.changeLanguage(lang);
  moment.locale(lang === 'en' ? 'en' : 'fr');
  return (dispatch) => {
    dispatch({
      type: SET_LANG,
      lang,
    });
  };
}
