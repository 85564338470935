/* eslint-disable no-case-declarations */
import React from 'react';
import { useTranslation } from 'react-i18next';
import CourseCard from './CourseCard';
import Box from '../../shared/atoms/Box';
import Heading from '../../shared/atoms/Heading';
import EmptyState from '../../shared/molecules/EmptyState';
import Button from '../../shared/atoms/Button';
import { ChatCtx } from '../../../contexts/ChatContext';

export default function AllCourses({ courses }) {
  const { t } = useTranslation();
  const { channels } = React.useContext(ChatCtx);
  const pendingCourses = courses.filter((c) => c.status === '');
  const pastCourses = courses.filter(
    (c) => c.status === 'TERMINE' || c.status === 'ANNULE',
  );
  return (
    <Box>
      {pendingCourses.length ? (
        <Box>
          <Heading level={4} mb={{ xs: 2, lg: 4 }}>
            {t('pendingrequest')}
          </Heading>
          {pendingCourses.map((course) => (
            <CourseCard key={course.id} course={course} channels={channels} />
          ))}
        </Box>
      ) : (
        <Box>
          <Heading level={4}>{t('pendingrequest')}</Heading>
          <Box alignItems="center">
            <EmptyState
              width="60%"
              title={t('account.nocourse.titles.1')}
              subTitle={t('account.nocourse.subtitles.1')}
              cta={
                <Button mt={2} alignSelf="center" to="/reparation">
                  {t('button.book')}
                </Button>
              }
            />
          </Box>
        </Box>
      )}
      {!!pastCourses.length && (
        <Box mt={10}>
          <Heading level={4}>{t('previousrequest')}</Heading>
          {pastCourses.map((course) => (
            <CourseCard key={course.id} course={course} channels={channels} />
          ))}
        </Box>
      )}
    </Box>
  );
}
