import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Spinner from '../Spinner';

const StyledLoader = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Loader({ height = '100vh', width = '100%', ...rest }) {
  return (
    <StyledLoader height={height} width={width}>
      <Spinner {...rest} />
    </StyledLoader>
  );
}

Loader.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};
