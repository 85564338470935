import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M16 13a1 1 0 00-1 1v1a1 1 0 001 1h1a1 1 0 001-1v-1a1 1 0 00-1-1h-1z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.545 6.73C2 7.8 2 9.2 2 12c0 2.8 0 4.2.545 5.27a5 5 0 002.185 2.185C5.8 20 7.2 20 10 20h4c2.8 0 4.2 0 5.27-.545a5 5 0 002.185-2.185C22 16.2 22 14.8 22 12c0-2.8 0-4.2-.545-5.27a5 5 0 00-2.185-2.185C18.2 4 16.8 4 14 4h-4c-2.8 0-4.2 0-5.27.545A5 5 0 002.545 6.73zM14 6h-4c-1.433 0-2.388.002-3.121.061-.71.058-1.035.161-1.241.266a3 3 0 00-1.311 1.311c-.105.206-.208.53-.266 1.24L4.052 9h15.896a12.27 12.27 0 00-.01-.121c-.057-.71-.16-1.035-.265-1.241a3 3 0 00-1.311-1.311c-.206-.105-.53-.208-1.24-.266C16.387 6.001 15.432 6 14 6zM4 12l.001-1H20L20 12c0 1.433-.002 2.388-.061 3.121-.058.71-.161 1.035-.266 1.241a3 3 0 01-1.311 1.311c-.206.105-.53.208-1.24.266-.734.06-1.689.061-3.122.061h-4c-1.433 0-2.388-.002-3.121-.061-.71-.058-1.035-.161-1.241-.266a3 3 0 01-1.311-1.311c-.105-.206-.208-.53-.266-1.24C4.001 14.387 4 13.432 4 12z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgCreditcard24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgCreditcard24;
