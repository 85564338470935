import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from './Modal';

import Box from '../components/shared/atoms/Box';
import Heading from '../components/shared/atoms/Heading';
import Image from '../components/shared/atoms/Image';
import Text from '../components/shared/atoms/Text';

const brakes = (t) => [
  { name: t('brakes.name.1'), uri: 'v-brake.png' },
  { name: t('brakes.name.2'), uri: 'route.png' },
  { name: t('brakes.name.3'), uri: 'mafac.png' },
  { name: t('brakes.name.4'), uri: 'cantilever.png' },
  { name: t('brakes.name.5'), uri: 'meca.png' },
  { name: t('brakes.name.6'), uri: 'hydro.png' },
  { name: t('brakes.name.7'), uri: 'magura.png' },
  { name: t('brakes.name.8'), uri: 'tambour.png' },
];

const StyledImage = styled(Image)`
  border: 1px solid ${(p) => p.theme.colors.gray.light};
`;

export default function BrakesModal({ open, close }) {
  const { t } = useTranslation();
  const translateBrakes = brakes(t);
  const [current, setCurrent] = useState(translateBrakes[0].uri);
  return (
    <Modal open={open} onRequestClose={close} width="large">
      <Box
        maxHeight={{ xs: '100%', md: '85vh' }}
        flexDirection={{ xs: 'column', md: 'row' }}>
        <Box
          width={{ xs: 1, md: 1 / 2 }}
          maxHeight={{ xs: '40vh', md: '100%' }}>
          <StyledImage
            src={`/static/img/photos/${current}`}
            alt={t('brake')}
            borderRadius="xs"
          />
        </Box>
        <Box
          width={{ xs: 1, md: 1 / 2 }}
          p={{ xs: 0, md: 5 }}
          pr={{ xs: 0, md: 5 }}
          pl={{ xs: 0, md: 6 }}
          mt={{ xs: 4, lg: 0 }}>
          <Heading level={3}>{t('brakes.headings.1')}</Heading>
          <Box
            flexDirection="row"
            flexWrap="wrap"
            ml={{ xs: 0, md: '-8px' }}
            mr={{ xs: 0, md: '-8px' }}>
            {translateBrakes.map(({ name, uri }) => (
              <Box
                key={name}
                width={{ xs: 1 / 3, md: 1 / 2, lg: 1 / 3 }}
                p={2}
                onClick={() => setCurrent(uri)}>
                <Image
                  src={`/static/img/photos/${uri}`}
                  borderRadius="xs"
                  height="6rem"
                  border="2px solid"
                  borderColor={
                    current === uri ? 'blue.electric' : 'gray.light'
                  }
                />
                <Text
                  mt={2}
                  variant="small"
                  color={current === uri ? 'black' : 'gray.darker'}
                  fontWeight="500">
                  {name}
                </Text>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
