import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M6.487 14.702a2.696 2.696 0 01-2.688-2.703c0-1.495 1.207-2.7 2.688-2.7.686 0 1.506.386 2.417 1.075.86.65 1.668 1.464 2.38 2.18l.042.043a.9.9 0 00.035.038l.102.103c.694.699 1.583 1.595 2.547 2.324.991.75 2.21 1.44 3.503 1.44A4.494 4.494 0 0022.001 12c0-2.482-2.006-4.503-4.488-4.503-1.058 0-2.058.462-2.91 1.022-.265.173-.522.36-.77.555a.9.9 0 001.11 1.417c.216-.17.432-.327.647-.468.728-.477 1.374-.726 1.923-.726a2.696 2.696 0 012.688 2.703c0 1.495-1.207 2.7-2.688 2.7-.686 0-1.506-.386-2.417-1.075-.86-.65-1.668-1.464-2.38-2.18l-.042-.043a.9.9 0 00-.035-.038l-.102-.103c-.694-.699-1.583-1.595-2.547-2.324-.991-.75-2.21-1.44-3.503-1.44A4.494 4.494 0 001.999 12c0 2.482 2.006 4.503 4.488 4.503 1.058 0 2.058-.462 2.91-1.021.265-.174.522-.361.77-.556a.9.9 0 10-1.11-1.417c-.216.17-.432.327-.647.468-.728.477-1.374.726-1.923.726z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgInfinite24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgInfinite24;
