import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import SectionBackground from '../shared/molecules/SectionBackground';
import Box from '../shared/atoms/Box';
import Heading from '../shared/atoms/Heading';
import ToolkitFix from '../shared/ToolkitFix';

const ToolkitSPRING = styled(ToolkitFix)`
  position: absolute;
  transform: rotate(-114.35deg);
  right: 12%;
  top: 0px;
  width: 519px;
  height: 572px;
`;

const Hero = () => {
  const { t } = useTranslation();
  return (
    <SectionBackground
      bg="primary.main"
      px={{ xs: '20px', md: 10 }}
      py={10}
      height="350px"
      mb={11}
      justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
      style={{ position: 'relative', overflow: 'hidden' }}>
      <Box
        alignItems={{ xs: 'center', md: 'flex-start' }}
        justifyContent={{ xs: 'center', md: 'flex-end' }}
        height="100%">
        <Heading level={1} color="white" style={{ marginBottom: 0, zIndex: 1 }}>
          {t('press')} &
          <br />
          {t('newsandpressrelease.heading')}
        </Heading>
        <ToolkitSPRING name="spring" color="blue.lighter" />
      </Box>
    </SectionBackground>
  );
};

export default Hero;
