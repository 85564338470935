import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.928 19.049l1.018-5.778c.382-2.178-1.327-4.16-3.581-4.16h-2.473s.371-.726.566-2a10.3 10.3 0 00.116-1.555c0-2.667-2.835-4.339-4.773-3.056-1.17.774-1.378 1.667-1.34 3.333.03 1.274-1.221 1.939-2.414 2.573-.273.145-.544.289-.795.438A1.079 1.079 0 016.703 9H5a3 3 0 00-3 3v5a3 3 0 003 3h1.784c.142 0 .283.03.413.088C10.465 21.527 13.792 22 17.347 22c1.772 0 3.281-1.244 3.581-2.951zm-1.97-.347C18.833 19.42 18.18 20 17.347 20c-3.37 0-6.403-.448-9.343-1.742L8 18.256v-7.548c.095-.044.186-.093.273-.144.207-.123.419-.236.677-.373l.324-.173a11.68 11.68 0 001.265-.765c.846-.606 1.963-1.698 1.922-3.466-.018-.794.039-1.113.095-1.267l.002-.006c.02-.054.056-.154.347-.346.196-.13.533-.184.954.077.408.254.715.72.715 1.31 0 .906-.14 1.614-.268 2.074a4.883 4.883 0 01-.204.59l-1.48 2.892h5.743c1.063 0 1.77.91 1.612 1.814l-1.018 5.777zM6 11H5a1 1 0 00-1 1v5a1 1 0 001 1h1v-7z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgThumbup24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgThumbup24;
