import { SET_LANG } from '../actions/lang.jsx';

export default function langReducer(state = 'fr', action) {
  switch (action.type) {
    case SET_LANG:
      return action.lang;
    default:
      return state;
  }
}
