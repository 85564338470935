import React from 'react';
import styled from 'styled-components';
import useDeviceWidth from '../../hooks/useDeviceWidth';
// Components
import Box from '../shared/atoms/Box';
import Text from '../shared/atoms/Text';
import Button from '../shared/atoms/Button';
// Icons
import ArrowEnterRight16 from '../icons/ArrowEnterRight16';
import Calendar16 from '../icons/Calendar16';

const ContainerBox = styled(Box)`
  border: 1px solid ${(p) => p.theme.colors.gray.light};
`;

export default function ChooseRepairFormat({
  onClick,
  distance,
  title,
  subtitle,
  timeInfo,
  icon,
  buttonText,
}) {
  const { isMobile } = useDeviceWidth();
  return (
    <ContainerBox
      flexDirection={{ xs: 'column', md: 'row' }}
      borderRadius="xs"
      mb={5}
      p={4}
      justifyContent="space-between">
      <Box flexDirection="row" mb={{ xs: 4, md: 0 }}>
        <Box mr={4}>
          {icon}
          <Text
            variant="caption"
            color="black"
            fontWeight="600"
            textAlign="center">
            {distance}
          </Text>
        </Box>
        <Box>
          <Text fontWeight="bold">{title}</Text>
          <Box flexDirection="row" alignItems="center">
            <ArrowEnterRight16 mb={1} />
            <Text ml={1}>{subtitle}</Text>
          </Box>
          <Box flexDirection="row" alignItems="center">
            <Calendar16 mb={1} />
            <Text ml={1}>{timeInfo}</Text>
          </Box>
        </Box>
      </Box>
      <Button
        size="compact"
        onClick={onClick}
        style={{ alignSelf: 'center' }}
        fullWidth={isMobile}>
        {buttonText}
      </Button>
    </ContainerBox>
  );
}
