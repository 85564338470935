import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from './Text';
import Box from './Box';

const Fill = styled.div`
  background: white;
  width: 0;
  height: 0;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.2s ease-in, height 0.2s ease-in;
  pointer-events: none;
  z-index: 1;

  &::before {
    content: '';
    opacity: 0;
    width: 8px;
    position: absolute;
    height: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 6px solid ${(p) => p.theme.colors.blue.electric};
    border-radius: 100%;
  }
`;

const Input = styled.input`
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ ${Fill} {
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      transition: width 0.1s ease-out, height 0.1s ease-out;

      &::before {
        opacity: 1;
      }
    }
  }
`;

const RadioElement = styled(Box)`
  margin: 5px;
  cursor: pointer;
  width: ${(p) => p.width};
  height: ${(p) => p.width};
  position: relative;
  label {
    margin-left: 25px;
  }
  &::before {
    content: '';
    border-radius: 100%;
    border: ${(p) =>
    p.checked
      ? `1px solid ${p.theme.colors.blue.electric}`
      : `2px solid ${p.theme.colors.gray.main}`};
    background: white;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
  }
`;

const LabelText = styled(Text)`
  font-size: 1em !important;
  color: ${(p) => p.theme.colors[p.$color] || p.theme.colors.gray.darkest};
`;

const Radio = ({
  name,
  checked,
  value,
  label,
  icon,
  onChange = () => { },
  color,
  readOnly,
  width = '20px',
}) => {
  return (
    <Fragment>
      <RadioElement checked={checked} width={width}>
        <Input
          type="radio"
          id={name}
          value={value}
          checked={checked}
          onChange={onChange}
          readOnly={readOnly}
        />
        <Fill />
      </RadioElement>
      {label && (
        <LabelText $color={color} as="label" htmlFor={name}>
          <b>{label}</b>
        </LabelText>
      )}
      {icon && icon}
    </Fragment>
  );
};

Radio.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.object,
};

export default Radio;
