import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M5.077 13.87l3.956-1.335-3.956 6.28 10-2.838"
      stroke="#8A38F3"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 7A.75.75 0 013 6.25h3.394a3.75 3.75 0 013.12 1.67l.22.33h12.669l.08-.14a3.75 3.75 0 013.24-1.86H29a.75.75 0 010 1.5h-3.277c-.8 0-1.54.425-1.943 1.116l-1.764 3.024a3.75 3.75 0 01-3.24 1.86H16.75v5.883l1.552 1.207a3.75 3.75 0 011.448 2.96v1.95h-1.5V23.8c0-.694-.32-1.35-.869-1.776L16 20.95l-1.381 1.075a2.25 2.25 0 00-.869 1.776v1.949h-1.5V23.8a3.75 3.75 0 011.448-2.96l1.552-1.207V13.75h-1.644a3.75 3.75 0 01-3.12-1.67l-2.22-3.328A2.25 2.25 0 006.395 7.75H3A.75.75 0 012.25 7zm19.278 2.75H10.735l.998 1.498a2.25 2.25 0 001.873 1.002h5.17c.801 0 1.541-.425 1.944-1.116l.808-1.384z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgHandleBarColor32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgHandleBarColor32;
