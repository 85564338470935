import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { setClient as setClientAction } from '../actions/client';
import { selectIsLoggedIn } from '../reducers/client';
import PartenaireContext from '../contexts/PartenaireContext';
import * as api from '../utils/api';
import { resumeReparation } from '../utils/reparation';
import Link from './shared/atoms/Link';
import { analyticsIdentify } from '../utils/utils';
// Components
import LoginForm from './connexionModule/LoginForm';
import ResetForm from './connexionModule/ResetForm';
import SignupForm from './connexionModule/SignupForm';

const SIGNUP = 'SIGNUP';
const LOGIN = 'LOGIN';
const RESET_PASSWORD = 'RESET_PASSWORD';
const RESET_PASSWORD_MAIL = 'RESET_PASSWORD_MAIL';

const ConnexionModuleBase = ({
  partenaire,
  tracking,
  redir = false,
  loginFirst,
  titleSignup,
  ctaLabelSignup,
  titleSignin,
  ctaLabelSignin,
  initialType = loginFirst ? LOGIN : SIGNUP,
  noPadding = false,
  bg = 'gray.lightest',
  closeModal = () => { },
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const isLoggedIn = useSelector((state) => selectIsLoggedIn(state));
  const history = useHistory();
  const [type, setType] = useState(initialType);
  const [loginemail, setLoginemail] = useState('');
  const [resetSend, setResetSend] = useState(false);
  const [email, setEmail] = useState();
  const product_id = useSelector((s) => s.course.product_id);

  useEffect(() => {
    if (isLoggedIn && type !== RESET_PASSWORD_MAIL) {
      if (redir) {
        if (redir === 'reparation') {
          resumeReparation(store, history);
        } else if (redir === 'giftcard') {
          closeModal();
        } else {
          history.push(redir);
        }
      } else {
        history.push('/account');
      }
    } else {
      console.log('not logged in');
    }
  }, [isLoggedIn]);

  const doLogin = async (values, { setSubmitting, setErrors }) => {
    const rep = await api.post('/clients/sessions', {
      email: values.email,
      password: values.password,
    });
    setEmail(values.email);
    setSubmitting(false);
    if (rep.error_message) setErrors({ password: rep.error_message });
    else if (!!rep.id && rep.token) {
      dispatch(setClientAction(rep));
      analyticsIdentify(rep.id, {
        email: rep.email,
        firstName: rep.prenom,
        lastName: rep.nom,
        phone: rep.tel,
      });
    }
  };

  const doReset = async (values, { setSubmitting, setErrors }) => {
    const resetObject = { email: values.email, type: 'RESET_PASSWORD' };
    if (redir) resetObject.redir = redir;
    await api.post('/auth_tokens', resetObject);
    setSubmitting(false);
    setResetSend(true);
    setType(LOGIN);
  };

  const _switch = (type) => {
    setType(type);
  };

  const doSignup = async (values, { setSubmitting, setErrors }) => {
    // if (values.password !== values.password_confirm) {
    //   setSubmitting(false);
    //   setErrors({
    //     password_confirm: 'La confirmation ne correspond pas au mot de passe.',
    //   });
    //   return;
    // }
    if (partenaire) {
      values.acquisition_type = partenaire.toUpperCase();
    }
    if (tracking) {
      values.acquisition_type = tracking.toUpperCase();
    }

    const rep = await api.post('/clients', values);

    setSubmitting(false);

    if (rep.id) {
      dispatch(setClientAction(rep));
      analyticsIdentify(rep.id, {
        email: rep.email,
        firstName: rep.prenom,
        lastName: rep.nom,
        phone: rep.tel,
      });
    } else if (rep.errors) {
      const serializedErrors = {};
      for (const k in rep.errors) {
        serializedErrors[k] = rep.errors[k].reduce((i, b) => b + i, '');
      }

      if (
        typeof rep.errors.email_already_in_use !== 'undefined' &&
        !!rep.errors.email_already_in_use
      )
        serializedErrors.email = (
          <span>
            {t('connexionmodule.text')}&nbsp;
            <Trans>
              <Link
                color="primary.darkest"
                fontWeight="bold"
                onClick={() => _switch(LOGIN)}
                style={{ borderBottom: '1px solid #8F1505' }}>
                Cliquez ici
              </Link>
              &nbsp;pour vous connecter !
            </Trans>
          </span>
        );

      setErrors(serializedErrors);
    }
  };

  return (
    <div>
      {type === SIGNUP && (
        <SignupForm
          submit={doSignup}
          _switch={_switch}
          product_id={product_id}
          withTitle={titleSignup}
          ctaLabel={ctaLabelSignup}
          bg={bg}
          noPadding={noPadding}
        />
      )}
      {type === LOGIN && (
        <LoginForm
          SIGNUP={SIGNUP}
          RESET_PASSWORD={RESET_PASSWORD}
          resetSend={resetSend}
          submit={doLogin}
          _switch={_switch}
          withTitle={titleSignin}
          ctaLabel={ctaLabelSignin}
          onChange={(e) => {
            setLoginemail({ loginemail: e.target.value });
          }}
          bg={bg}
          noPadding={noPadding}
        />
      )}
      {type === RESET_PASSWORD && (
        <ResetForm
          LOGIN={LOGIN}
          email={email}
          submit={doReset}
          _switch={_switch}
          defaultValue={loginemail}
          bg={bg}
          noPadding={noPadding}
        />
      )}
    </div>
  );
};

const ConnexionModule = (props) => (
  <PartenaireContext.Consumer>
    {({ partenaire, tracking }) => (
      <ConnexionModuleBase
        partenaire={partenaire}
        tracking={tracking}
        {...props}
      />
    )}
  </PartenaireContext.Consumer>
);

export default ConnexionModule;
