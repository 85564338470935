import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.415 3.44c-.681-.315-1.36-.409-2.032-.29-.655.114-1.243.42-1.772.806-.948.69-1.875 1.767-2.815 2.857l-.232.27a17.334 17.334 0 00-2.691 4.29c-.31.719-.547 1.427-.672 2.07-.12.62-.16 1.284.032 1.853.096.284.122.537.119.742-.011.652.209 1.664 1.114 2.135 1.716.893 4.748 2.235 7.4 2.586 3.884.514 7.724-.51 9.333-1.008 1.043-.322 1.461-1.15 1.61-1.772a3.416 3.416 0 00.082-1.039l-.002-.02-.002-.021v-.014l.001-.188a23.053 23.053 0 00-.166-2.746c-.096-.772-.25-1.615-.51-2.337-.242-.672-.656-1.476-1.426-1.832-1.176-.545-2.352-.288-3.242.139a6.509 6.509 0 00-1.55 1.062 5.573 5.573 0 00-2.53-.304 5.897 5.897 0 00-2.16.68V9.906a.1.1 0 01.074-.097l2.434-.644c.583-.155 1.14-.606 1.3-1.309.142-.62.223-1.453.029-2.264-.201-.84-.71-1.68-1.726-2.151zM6.926 8.26l.176-.205C8.105 6.893 8.9 5.973 9.67 5.411c.392-.285.726-.437 1.025-.489.283-.05.59-.021.963.151.418.194.631.515.732.937.106.44.073.959-.024 1.409a.112.112 0 01-.014.004l-1.99.527a.9.9 0 00-1.795.066l-.06 1.779a1.912 1.912 0 00-.003.11v2.81a10.166 10.166 0 00-.713.902c-.05.072-.1.144-.146.218a.909.909 0 00.288 1.241.9.9 0 001.24-.288l.002-.004a8.238 8.238 0 01.904-1.122l.158-.113c.66-.476 1.329-.958 2.426-1.08a3.772 3.772 0 011.81.242c.143.069.284.144.423.223l.452.276c.315.207.669.47.997.782a.9.9 0 101.24-1.306 8.653 8.653 0 00-.915-.751c.2-.144.42-.28.652-.391.618-.296 1.198-.365 1.707-.13.073.034.277.219.489.81.194.54.329 1.233.418 1.948a21.322 21.322 0 01.152 2.697v.043l-.001.01v.001l-.001.069.008.062.002.022a1.625 1.625 0 01-.037.484c-.062.256-.17.403-.39.471-1.55.479-5.082 1.405-8.568.944-2.332-.309-5.137-1.532-6.804-2.399-.002 0-.037-.019-.078-.113a.99.99 0 01-.067-.394 3.995 3.995 0 00-.213-1.348c-.044-.13-.07-.428.029-.934.094-.482.282-1.063.557-1.7.55-1.274 1.41-2.68 2.401-3.827z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgMuscle24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgMuscle24;
