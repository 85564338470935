import React, { useContext, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { resumeReparation } from '../../utils/reparation';

import {
  setDevis as actionSetDevis,
  pushInProgressQ,
} from '../../actions/diag';
import {
  resetSlots as actionResetSlots,
  setCourseDetails,
} from '../../actions/course';
import useStrapi from '../../hooks/useStrapi';

import ArrowRight16 from '../icons/ArrowRight16';
import AngleRight16 from '../icons/AngleRight16';
import Heading from '../shared/atoms/Heading';
import Text from '../shared/atoms/Text';
import Button from '../shared/atoms/Button';
import DottedList from '../shared/atoms/DottedList';
import Alert from '../shared/molecules/Alert';
import Input from '../shared/atoms/Input';
import HelpCard from '../shared/molecules/HelpCard';
import DottedRow from '../shared/molecules/DottedRow';
import Box from '../shared/atoms/Box';
import Badge from '../shared/atoms/Badge';
import DevisContentSkeleton from './DevisContentSkeleton';
import strapiDevis from '../../hooks/StrapiDevis';
import Card from '../shared/atoms/Card';
import { FeaturesContext } from '../../contexts/FeaturesContext';
import useResaShop from '../../hooks/useResaShop';
import useFirstQuestionID from '../../hooks/useFirstQuestionID';
import { currencyFormat, getLang } from '../../utils/utils';
import { useCurrentZone } from '../../hooks/useZones';

const PriceEmphasize = styled.span`
  color: ${(p) => p.theme.colors.blue.electric};
  position: relative;
  &:after {
    z-index: -1;
    position: absolute;
    content: ' ';
    bottom: 8px;
    margin: 0 auto;
    left: 0;
    right: 0;
    height: 8px;
    background: ${(p) => p.theme.colors.yellow.lighter};
  }
`;

const StyledAlert = styled(Alert)`
  a {
    text-decoration: underline;
    color: ${(p) => p.theme.colors.yellow.dark};
    :hover {
      text-decoration: underline;
      color: ${(p) => p.theme.colors.yellow.dark};
      opacity: 80%;
    }
  }
`;

const DevisContent = ({
  devisid,
  storeDevis,
  setDevis,
  resetSlots,
  ...props
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { unsetFirstQID } = useFirstQuestionID();
  const { isFeatureOn, getFeature, flags } = useContext(FeaturesContext);
  const [loading, devis] = useStrapi(`quotes/${devisid}`);
  const isResaShop = useResaShop();
  const StrapiDevis = devis && strapiDevis(devis);
  const devisElements = devis && StrapiDevis.getDevisElements();
  const isNotSupported = devis && devisElements.length === 0;
  const currentZone = useCurrentZone();
  const { course_details, photos, selectedShop } = useSelector((s) => s.course);
  const dispatch = useDispatch();

  const handleChange = (e) => dispatch(setCourseDetails(e.target.value));

  const submit = (e) => {
    e.preventDefault();

    resetSlots();
    resumeReparation(props.state, props.history, true);

    const form = e.target;
    if (!form.checkValidity()) {
      // Optionally, show an error message to the user
      return;
    }

    dispatch(setCourseDetails(course_details));
    history.push('/reparation/informations');
  };

  useEffect(() => {
    if (isResaShop && devis) {
      if (isResaShop?.id !== devis?.shop_id) {
        unsetFirstQID();
        history.push(`/reparation`);
      }
    }
    if (devis && storeDevis.id !== devis.id && StrapiDevis) {
      setDevis({ ...StrapiDevis, isNotSupported });
    }
  }, [devis, setDevis, storeDevis, isNotSupported, StrapiDevis]);

  if (!loading && !StrapiDevis) {
    return <Redirect to="/reparation" />;
  }
  return (
    <div>
      {loading || !devis ? (
        <DevisContentSkeleton />
      ) : (
        <React.Fragment>
          {!isNotSupported && (
            <>
              <Heading level={5}>
                {devisElements.length === 1
                  ? t('deviscontent.headings.1')
                  : t('deviscontent.headings.2')}
              </Heading>
            </>
          )}
          <ul>
            {StrapiDevis.devisElementsSorted().map((quote) => {
              const { id, description, price, estimated_duration } = quote;
              return (
                <DottedRow
                  key={id}
                  styledLine={{ marginTop: '12px' }}
                  mb={2}
                  before={
                    <>
                      <div>
                        <ArrowRight16 mr={2} />
                      </div>
                      <Text color="black">{description}</Text>
                      {estimated_duration > 0 && (
                        <Text ml={1} variant="small">
                          ({estimated_duration}min)
                        </Text>
                      )}
                    </>
                  }
                  after={
                    <Badge size="large" color="turquoise">
                      {price}
                    </Badge>
                  }
                />
              );
            })}
          </ul>
          {(isFeatureOn('FEE_SERVICE') || isFeatureOn('FEE_DELIVERY')) &&
            currentZone &&
            !currentZone.light &&
            !isResaShop && (
              <Card bg="white" pr={1} padding={1} mt={4}>
                {getFeature('FEE_SERVICE') > 0 && (
                  <DottedRow
                    styledLine={{ marginTop: '12px' }}
                    mb={2}
                    before={
                      <Text color="black">•{` ${t('servicecosts')} :`}</Text>
                    }
                    after={
                      <Badge
                        size="large"
                        color={'gray'}
                        backgroundColor={'white'}>
                        4€
                      </Badge>
                    }
                  />
                )}
                {getFeature('FEE_DELIVERY') > 0 && (
                  <DottedRow
                    styledLine={{ marginTop: '12px' }}
                    mb={2}
                    before={
                      <Text color="black">•{` ${t('travelcosts')} :`}</Text>
                    }
                    after={
                      <Badge
                        size="large"
                        color={'gray'}
                        backgroundColor={'white'}>
                        6€
                      </Badge>
                    }
                  />
                )}
              </Card>
            )}
          {StrapiDevis.Contenu && (
            <StyledAlert status="warning" mb={4} mt={4}>
              <span dangerouslySetInnerHTML={{ __html: StrapiDevis.Contenu }} />
            </StyledAlert>
          )}
          {!isNotSupported && (
            <Box as="form" onSubmit={submit} mt={4}>
              {devis.Contenu && (
                <StyledAlert status="warning">
                  <span dangerouslySetInnerHTML={{ __html: devis.Contenu }} />
                </StyledAlert>
              )}
              <Heading level={5} mt={3}>
                <Trans
                  i18nKey="repairdetails.course_details_label"
                  components={{
                    styledspan: <small style={{ colors: 'black' }} />,
                  }}
                />
              </Heading>
              <Input
                as="textarea"
                onChange={handleChange}
                value={course_details}
                placeholder={t('repairdetails.placeholder')}
                data-testid="input-course-details"
                rows="4"
                required={true}
                mb={4}
                mt={1}
              />
              <Button
                type="submit"
                alignSelf="flex-end"
                after={<AngleRight16 />}
                data-testid="button-disponibilites"
                fullWidth="md"
                animation
                zIndex={1001}>
                {t('deviscontent.cta')}
              </Button>
            </Box>
          )}
        </React.Fragment>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    state,
    storeDevis: state.diag.devis,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setDevis: (devisObj) => dispatch(actionSetDevis(devisObj)),
  resetSlots: () => dispatch(actionResetSlots()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DevisContent),
);
