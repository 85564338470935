import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.001 18.298C2.99 15.536 0 13.524 0 10.848 0 8.17 2.24 6 5.001 6 7.763 6 10 8.171 10 10.848c0 2.676-2.99 4.688-4.999 7.45z"
      fill="#8A38F3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 11.333L16 2 4 11.333V22c0 2.8 0 4.2.545 5.27a5 5 0 002.185 2.185C7.8 30 9.2 30 12 30h8c2.8 0 4.2 0 5.27-.545a5 5 0 002.185-2.185C28 26.2 28 24.8 28 22V11.333zm-12-6.8l10 7.779V22c0 1.433-.002 2.388-.061 3.121-.058.71-.161 1.035-.266 1.241a3 3 0 01-1.311 1.311c-.206.105-.53.208-1.24.266-.734.06-1.689.061-3.122.061h-8c-1.433 0-2.388-.002-3.121-.061-.71-.058-1.035-.161-1.241-.266a3 3 0 01-1.311-1.311c-.105-.206-.207-.53-.266-1.24C6.001 24.387 6 23.432 6 22v-9.688l10-7.778z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 28v-4a2 2 0 10-4 0v4h4zm-2-8a4 4 0 00-4 4v6h8v-6a4 4 0 00-4-4z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgHouse32Color = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgHouse32Color;
