import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M17 11.765l.881.182A.9.9 0 0017 10.865v.9zm-10 0v-.9a.9.9 0 00-.881 1.082L7 11.765zm.1-3.278a.9.9 0 101.8 0H7.1zm2 0a.9.9 0 101.8 0H9.1zm4.118 0a.9.9 0 101.8 0h-1.8zm2 0a.9.9 0 101.8 0h-1.8zM21.1 12a9.1 9.1 0 01-9.1 9.1v1.8c6.02 0 10.9-4.88 10.9-10.9h-1.8zM12 21.1A9.1 9.1 0 012.9 12H1.1c0 6.02 4.88 10.9 10.9 10.9v-1.8zM2.9 12A9.1 9.1 0 0112 2.9V1.1C5.98 1.1 1.1 5.98 1.1 12h1.8zM12 2.9a9.1 9.1 0 019.1 9.1h1.8c0-6.02-4.88-10.9-10.9-10.9v1.8zm4.119 8.682c-.386 1.86-2.073 3.283-4.119 3.283v1.8c2.891 0 5.321-2.014 5.881-4.718l-1.762-.365zM12 14.865c-2.045 0-3.733-1.422-4.119-3.283l-1.762.365c.56 2.704 2.99 4.718 5.881 4.718v-1.8zm5-4H7v1.8h10v-1.8zM8.9 8.487a.1.1 0 01.1-.1v-1.8a1.9 1.9 0 00-1.9 1.9h1.8zm.1-.1a.1.1 0 01.1.1h1.8a1.9 1.9 0 00-1.9-1.9v1.8zm6.018.1a.1.1 0 01.1-.1v-1.8a1.9 1.9 0 00-1.9 1.9h1.8zm.1-.1a.1.1 0 01.1.1h1.8a1.9 1.9 0 00-1.9-1.9v1.8z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgSmiley24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgSmiley24;
