import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.29 11.433a2.977 2.977 0 10-4.21-4.21 2.977 2.977 0 004.21 4.21zM16.159 10.3a1.377 1.377 0 10-1.947-1.946 1.377 1.377 0 001.947 1.946z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.337 7.908a.8.8 0 01.626-.352l2.488-.118c.505-.659 1.119-1.3 1.863-1.894 1.77-1.411 4.018-2.243 6.062-2.72 2.05-.479 3.962-.616 5.121-.592l.768.016.016.767c.024 1.16-.113 3.073-.592 5.122-.477 2.044-1.309 4.291-2.72 6.062a10.906 10.906 0 01-1.808 1.796l-.125 2.634a.8.8 0 01-.352.625l-3.94 2.654a.8.8 0 01-1.245-.625l-.15-3.092c-.296.01-.56.002-.786-.02a.8.8 0 01-.489-.23l-3.502-3.502a.8.8 0 01-.23-.49 5.905 5.905 0 01-.022-.71l-3.011-.146a.8.8 0 01-.625-1.246l2.653-3.939zm7.605 10.076l.087 1.803 2.428-1.635.052-1.096c-.893.462-1.773.755-2.567.928zM6.44 9.135l.974-.046c-.451.891-.735 1.768-.9 2.557l-1.71-.083L6.44 9.135zm1.478 4.387l3.073 3.073c1.42.034 4.468-.561 6.726-3.394 1.206-1.512 1.965-3.505 2.414-5.427.348-1.49.5-2.897.54-3.933-1.035.04-2.442.193-3.932.54-1.922.45-3.915 1.209-5.427 2.414-2.833 2.259-3.428 5.306-3.394 6.727z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.645 16.869c-1.09-1.09-2.842-.942-3.906.122-.574.574-.825 1.382-.953 2.08-.125.68-.154 1.39-.176 1.925l-.004.079a.8.8 0 00.834.832l.083-.003c.534-.023 1.242-.052 1.92-.177.697-.128 1.505-.379 2.08-.953 1.063-1.064 1.213-2.814.122-3.905zM4.87 18.122c.534-.535 1.268-.496 1.642-.122.374.374.413 1.107-.122 1.642-.225.226-.637.401-1.237.511-.294.054-.6.087-.903.11.022-.304.055-.61.109-.904.11-.6.285-1.012.51-1.237z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgRocket24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgRocket24;
