import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.823 8.867L8.393 21.296l-5.262.696a1 1 0 01-1.122-1.123l.696-5.262 12.43-12.429a4.022 4.022 0 115.688 5.689zM4.163 19.838l.436-3.296L14.863 6.278l2.86 2.86L7.46 19.402l-3.296.436zM19.138 7.723l.27-.27a2.022 2.022 0 00-2.86-2.86l-.27.27 2.86 2.86z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgPen24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPen24;
