import React from 'react';
import ConnexionModule from '../ConnexionModule';
import Modal from '../../modals/Modal';

const LoginModal = ({
  showModal,
  setShowModal,
  addCard = () => {},
  titleSignup,
  ctaLabelSignup,
  titleSignin,
  ctaLabelSignin,
  loginFirst = false,
}) => {
  return (
    <Modal
      zIndex="1002"
      hasCloseAction={true}
      width="medium"
      overflow="scroll"
      open={showModal}
      onRequestClose={() => {
        setShowModal(false);
      }}>
      <ConnexionModule
        giftcard
        noPadding
        loginFirst={loginFirst}
        titleSignup={titleSignup}
        ctaLabelSignup={ctaLabelSignup}
        titleSignin={titleSignin}
        ctaLabelSignin={ctaLabelSignin}
        bg="white"
        redir="giftcard"
        closeModal={() => {
          setShowModal(false);
          addCard();
        }}
      />
    </Modal>
  );
};
export default LoginModal;
