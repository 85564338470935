import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

// Components
import Helmet from 'react-helmet';
import Container from '../components/shared/atoms/Container';
import CategoriesAndPrices from '../components/prices/CategoriesAndPrices';
import DiscoverOurPrices from '../components/prices/DiscoverOurPrices';
import BodyBand from '../components/shared/atoms/BodyBand';
import HowItWorks from '../components/shared/organisms/HowItWorks';

import { setProductId } from '../actions/course';

const StyledContainer = styled(Container)`
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const Prices = ({ history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const product_id = useSelector((s) => s.course.product_id);
  const [tab, setTab] = useState(product_id == '1' ? '1' : '2');

  useEffect(() => {
    history.push(`/tarifs/${tab === '1' ? 'velo' : 'trottinette'}`);
    dispatch(setProductId(tab));
  }, [tab]);

  // TODO to be removed
  return ("Tarifs");

  return (
    <Container fluid>
      <Helmet
        title={t('prices.meta.title')}
        meta={[
          {
            name: 'description',
            content: t(`prices.meta.title`),
          },
        ]}
      />
      <DiscoverOurPrices {...{ tab, setTab }} />
      <Container px={{ xs: 5, md: 0 }}>
        <StyledContainer>
          <BodyBand
            flexDirection={{ xs: 'column', lg: 'row' }}
            borderRadius="sm"
            mt={{ sm: '-25px', md: '-70px', lg: '-72px' }}
            text={t('price.body.text')}
            textButton={t('price.body.cta')}
            link="/reparation"
          />
          <CategoriesAndPrices {...{ tab, setTab, product_id }} />
        </StyledContainer>
      </Container>
      <HowItWorks py={10} bg="gray.lightest" />
    </Container>
  );
};

export default withRouter(Prices);
