import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <ellipse cx={16.015} cy={29} rx={12} ry={3} fill="#1F77F9" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.003 30l.286-.28C20.78 25.307 27.2 19.002 27.2 13.036 27.2 6.944 22.189 2 16.003 2 9.818 2 4.8 6.944 4.8 13.037c0 5.975 6.437 12.289 10.936 16.7l.267.263zm-5.702-9.07c1.71 2.236 3.776 4.359 5.701 6.264 1.924-1.905 3.989-4.027 5.698-6.263 2.17-2.839 3.5-5.529 3.5-7.894C25.2 8.075 21.111 4 16.003 4 10.894 4 6.8 8.076 6.8 13.037c0 2.365 1.33 5.055 3.5 7.894z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 15.526a2.308 2.308 0 100-4.616 2.308 2.308 0 000 4.616zm0 2a4.308 4.308 0 100-8.616 4.308 4.308 0 000 8.616z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgPin32Color = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPin32Color;
