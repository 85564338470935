import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Hook
import useDeviceWidth from '../../../hooks/useDeviceWidth';
// Components
import SectionBackground from '../../shared/molecules/SectionBackground';
import Container from '../../shared/atoms/Container';
import Heading from '../../shared/atoms/Heading';
import Box from '../../shared/atoms/Box';
import Button from '../../shared/atoms/Button';
import Text from '../../shared/atoms/Text';
import Link from '../../shared/atoms/Link';
import ToolkitFix from '../../shared/ToolkitFix';
// Icons
import BuildingColor56 from '../../icons/BuildingColor56';
import AngleRight16 from '../../icons/AngleRight16';
import BrandColor56 from '../../icons/BrandColor56';
import FleetColor56 from '../../icons/FleetColor56';
import Row from '../../shared/atoms/Row';

const StyledBox = styled(Box)`
  border: 1px solid ${(p) => p.theme.colors.gray.light};
  justify-content: space-between;
  transition: transform 150ms ease-in;
  :hover {
    cursor: pointer;
    transform: translate(0, -3px);
    button {
      color: ${(p) => p.theme.colors.black};
      background-color: ${(p) => p.theme.colors.gray.light};
      & > .icon-right {
        opacity: 0.8;
        right: -8px;
        transition: all 150ms ease;
      }
    }
  }
`;

const ToolkitSPRING = styled(ToolkitFix)`
  text-align: center;
  transform: rotate(335deg);
  margin-top: -30px;
`;

const ToolkitHEXA = styled(ToolkitFix)`
  text-align: center;
  transform: rotate(9deg);
`;

const Card = ({ logo, options, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Link to={options.url}>
      <StyledBox borderRadius="sm" p={2} {...rest}>
        <Box flexDirection="row" p={4} alignItems="center">
          {logo}
          <Heading level={5} ml={2} style={{ marginBottom: 0 }}>
            {options.title}
          </Heading>
        </Box>
        <Box bg={options.color} borderRadius="md" p={4}>
          <Text color="black" mb={4} variant="medium">
            {options.text}
          </Text>
          <Button
            after={<AngleRight16 />}
            variant="filledWhite"
            style={{ textAlign: 'center', width: '100%' }}
            animation>
            {t('discover.cta')}
          </Button>
        </Box>
      </StyledBox>
    </Link>
  );
};

const ContainerCard = ({ ...props }) => {
  const { isDesktopXL } = useDeviceWidth();
  const { t } = useTranslation();
  return (
    <Row lg noSize gap={!isDesktopXL ? 16 : 24}>
      <Row md gap={!isDesktopXL ? 16 : 24}>
        <Card
          logo={<BuildingColor56 ml={4} />}
          borderRadius="lg"
          options={{
            title: t('company'),
            text: t('whoareyou.texts.1'),
            color: 'green.lighter',
            url: '/entreprises/reparation-en-entreprise',
          }}
        />
        <Card
          logo={<BrandColor56 ml={4} />}
          borderRadius="lg"
          options={{
            title: t('brand'),
            text: t('whoareyou.texts.2'),
            color: 'violet.lighter',
            url: '/entreprises/marques',
          }}
        />
      </Row>
      <Row md gap={!isDesktopXL ? 16 : 24}>
        <Card
          logo={<FleetColor56 ml={4} />}
          borderRadius="lg"
          options={{
            title: t('fleetadministrator'),
            text: t('whoareyou.texts.3'),
            color: 'blue.lighter',
            url: '/entreprises/flottes',
          }}
        />
        <Box
          justifyContent="center"
          alignItems="center"
          style={{ alignSelf: 'center' }}
          display={{
            xs: 'none',
            lg: 'flex',
            xl: 'none',
          }}>
          <ToolkitHEXA name="hexa" color="stone" />
          <ToolkitSPRING name="spring" color="blue.main" />
        </Box>
      </Row>
    </Row>
  );
};

const WhoAreYou = () => {
  const { t } = useTranslation();
  return (
    <SectionBackground alignItems="center" mb={{ xs: 7, md: '100px' }}>
      <Container>
        <Heading level={2} mb={{ xs: 4, md: 5 }}>
          {t('whoareyou.heading')}
        </Heading>
        <ContainerCard />
      </Container>
    </SectionBackground>
  );
};

export default WhoAreYou;
