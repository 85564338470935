import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const shape = css`
  width: 42px;
  height: 24px;
  border-radius: 15px;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
  ${shape}
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  ${shape}
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${(p) => p.theme.colors.gray.main};
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: ${(p) => p.theme.shadows.md};
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  margin: 0;
  opacity: 0;
  z-index: 1;
  ${shape}
  &:checked + ${CheckBoxLabel} {
    background: ${(p) => p.theme.colors.blue.electric};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

const Switch = ({ value, onChange, ...rest }) => {
  const handler = (e) => {
    e.preventDefault();
    onChange(!value);
  };
  return (
    <CheckBoxWrapper {...rest}>
      <CheckBox type="checkbox" checked={value} onClick={handler} readOnly />
      <CheckBoxLabel htmlFor="checkbox" onClick={handler} />
    </CheckBoxWrapper>
  );
};

Switch.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Switch;
