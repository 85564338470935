import React from 'react';
import styled from 'styled-components';

import Box from './Box';

const getGap = (p) =>
  typeof p.$gap === 'number' ? `${p.theme.space[p.$gap]}px` : p.$gap;

const StyledBox = styled(Box)`
  & > *:not(:last-child) {
    margin-bottom: ${(p) => (p.$noGapMobile ? 0 : getGap(p))};
    margin-right: 0;
  }
  & > *:not(:first-child) {
    margin-top: ${(p) => (p.$noGapMobile ? 0 : getGap(p))};
    margin-left: 0;
  }
  ${(p) => p.theme.mediaQueries.desktop} {
    & > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: ${(p) => getGap(p)};
    }
    & > *:not(:first-child) {
      margin-top: 0;
      margin-left: ${(p) => getGap(p)};
    }
  }
`;

export default function Grid({
  item,
  width = 1 / 2,
  gap = 6,
  children,
  noGapMobile = false,
  ...rest
}) {
  if (item) {
    return (
      <Box width={{ xs: 1, lg: width }} {...rest}>
        {children}
      </Box>
    );
  }
  return (
    <StyledBox
      $noGapMobile={noGapMobile}
      $gap={gap}
      flexDirection={{ xs: 'column', lg: 'row' }}
      {...rest}>
      {children}
    </StyledBox>
  );
}
