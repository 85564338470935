import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Box from '../shared/atoms/Box';
import Heading from '../shared/atoms/Heading';
import SearchMap from '../shared/organisms/SearchMap';
import Text from '../shared/atoms/Text';
import Link from '../shared/atoms/Link';

const SearchMapContainer = styled(Box)`
  height: 30vh;
  position: -webkit-relative;
  position: relative;
  margin: 0 -1.5rem;
  ${(p) => p.theme.mediaQueries.desktop} {
    height: 530px;
    margin: 0;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.xs}) {
    height: 40vh;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    max-height: 310px;
  }
`;

export default function Maps({ name, address }) {
  const { t } = useTranslation();
  return (
    <Box mt={5} mb={5}>
      <Heading level={5}>{t('standalone.maps.title')}</Heading>
      <SearchMapContainer>
        {!!address && (
          <SearchMap
            zones={[]}
            shops={[]}
            overridePosition={address}
            inputSearch={false}
            borderRadius={{ xs: 'none', md: 'sm', lg: 'sm' }}
          />
        )}
      </SearchMapContainer>
      <Box mt={4}>
        <Text color="black" fontWeight="bold" mb={1}>
          {name}
        </Text>
        <Box flexDirection="row">
          <Link
            color="blue.electric"
            fontWeight="bold"
            target="_bank"
            href={`https://maps.google.com?q=${address}`}>
            {address} ⤴
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
