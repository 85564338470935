import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M7 13c0 3.2 4.167 7.333 6 8.5l7-1c1.667-1.333 5-4.8 5-8 0-4-3.5-9.5-9-9.5S7 9 7 13z"
      fill="#FFE249"
    />
    <path
      d="M14 21v-9.5a1 1 0 00-1-1h-1a1 1 0 00-1 1v1a1 1 0 001 1h8a1 1 0 001-1v-1a1 1 0 00-1-1h-1a1 1 0 00-1 1V21"
      stroke="#000"
      strokeWidth={1.5}
    />
    <path
      d="M16 3c-4.97 0-9 3.97-9 8.869 0 2.705 1.229 5.127 3.167 6.754 1.223 1.027 2.233 2.412 2.233 4.01V26a3 3 0 003 3h1.2a3 3 0 003-3v-3.367c0-1.598 1.01-2.983 2.233-4.01C23.771 16.996 25 14.574 25 11.87 25 6.971 20.97 3 16 3zM12.5 22l7.5-1.5M12.5 25.5l7-1.5"
      stroke="#000"
      strokeWidth={1.5}
    />
    <path
      d="M2 20.8l2.908-2.112M29.908 20.8L27 18.688M1.201 5l3.13 1.767M30.707 5l-3.13 1.767M1 12.767h2M30.908 12.767h-2"
      stroke="#FFE249"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgLightbulbColor32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgLightbulbColor32;
