import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M17.975 22.778a1 1 0 00-1.95.444C17.183 28.304 22.171 32 28 32c5.83 0 10.817-3.696 11.975-8.778a1 1 0 00-1.95-.444C37.103 26.825 33.031 30 28 30c-5.031 0-9.103-3.175-10.025-7.222zM35 17a2 2 0 01-2-2 1 1 0 10-2 0 4 4 0 008 0 1 1 0 10-2 0 2 2 0 01-2 2zM19 15a2 2 0 104 0 1 1 0 112 0 4 4 0 01-8 0 1 1 0 112 0z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52 28c0 13.255-10.745 24-24 24S4 41.255 4 28 14.745 4 28 4s24 10.745 24 24zm-2 0c0 12.15-9.85 22-22 22S6 40.15 6 28 15.85 6 28 6s22 9.85 22 22z"
      fill="#000"
    />
    <path
      d="M6.007 16a1 1 0 10-2 0v2H3a1 1 0 100 2h1.007v2a1 1 0 102 0v-2H7a1 1 0 100-2h-.993v-2z"
      fill="#FFE249"
    />
    <path
      d="M20 37a1 1 0 10-2 0v1.966h-1a1 1 0 100 2h1V43a1 1 0 102 0v-2.034h1a1 1 0 100-2h-1V37z"
      fill="#d5ff4f"
    />
    <path
      d="M47.002 27a1 1 0 10-2 0v2H44a1 1 0 100 2h1.002v2a1 1 0 102 0v-2h1.002a1 1 0 100-2h-1.002v-2z"
      fill="#1F77F9"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgPeacefulColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPeacefulColor56;
