import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.617 7.927a.75.75 0 10-1.234-.853l-1.231 1.78a8.018 8.018 0 01-.324.45.6.6 0 01-.076.084.25.25 0 01-.14.03.601.601 0 01-.104-.042 8.02 8.02 0 01-.484-.271l-.646-.378a.75.75 0 00-.756 1.296l.667.39c.207.12.399.232.567.313.182.089.39.168.634.188.35.028.7-.05 1.006-.224.212-.12.367-.28.495-.438.118-.145.244-.327.38-.524l1.246-1.801zM7.615 9.419h-.002z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 2.5a.75.75 0 00-1.5 0v1.016c-.724.029-1.207.105-1.612.311a3 3 0 00-1.311 1.311C2 5.78 2 6.62 2 8.3v.9c0 1.68 0 2.52.327 3.162a3 3 0 001.311 1.311C4.28 14 5.12 14 6.8 14h2.4c1.68 0 2.52 0 3.162-.327a3 3 0 001.311-1.311C14 11.72 14 10.88 14 9.2v-.9c0-1.68 0-2.52-.327-3.162a3 3 0 00-1.311-1.311c-.405-.206-.888-.282-1.612-.31V2.5a.75.75 0 00-1.5 0v1h-2.5v-1zM6.8 5h2.4c.865 0 1.423.001 1.848.036.408.033.559.09.633.127a1.5 1.5 0 01.655.656c.038.074.095.225.128.633.035.425.036.983.036 1.848v.9c0 .865-.001 1.423-.036 1.848-.033.408-.09.559-.128.633a1.5 1.5 0 01-.655.655c-.074.038-.225.095-.633.128-.425.035-.983.036-1.848.036H6.8c-.865 0-1.423-.001-1.848-.036-.408-.033-.559-.09-.633-.128a1.5 1.5 0 01-.656-.655c-.037-.074-.094-.225-.127-.633C3.5 10.623 3.5 10.065 3.5 9.2v-.9c0-.865.001-1.423.036-1.848.033-.408.09-.559.127-.633a1.5 1.5 0 01.656-.656c.074-.037.225-.094.633-.127C5.377 5 5.935 5 6.8 5z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgCalendarCheck16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgCalendarCheck16;
