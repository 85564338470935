import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';
/**
 * @param {string} color
 * Component generated with svgr,
 * if modification needed please go to src/utils/icon-template.js
 */

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M4.9 5c0-1.16.94-2.1 2.1-2.1h6A6.1 6.1 0 0 1 19.1 9v10a2.1 2.1 0 0 1-2.1 2.1H7A2.1 2.1 0 0 1 4.9 19V5Z"
      stroke="#161717"
      strokeWidth={1.8}
    />
    <path
      d="M10.1 2.9h.7a2 2 0 0 1 2 2v1a3 3 0 0 0 3 3h1.3a2 2 0 0 1 2 2"
      stroke="#161717"
      strokeWidth={1.8}
    />
    <path
      d="M8 13.1a.9.9 0 1 0 0 1.8v-1.8Zm4 1.8a.9.9 0 1 0 0-1.8v1.8Zm3-1.8a.9.9 0 1 0 0 1.8v-1.8Zm1 1.8a.9.9 0 1 0 0-1.8v1.8Zm-8 0h4v-1.8H8v1.8Zm7 0h1v-1.8h-1v1.8ZM8 16.1a.9.9 0 1 0 0 1.8v-1.8Zm4 1.8a.9.9 0 1 0 0-1.8v1.8Zm3-1.8a.9.9 0 1 0 0 1.8v-1.8Zm1 1.8a.9.9 0 1 0 0-1.8v1.8Zm-8 0h4v-1.8H8v1.8Zm7 0h1v-1.8h-1v1.8ZM8 10.1a.9.9 0 1 0 0 1.8v-1.8Zm2 1.8a.9.9 0 1 0 0-1.8v1.8Zm-2 0h2v-1.8H8v1.8Z"
      fill="#161717"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgInvoice24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgInvoice24;
