import React, { useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import {
  popQ as popQAction,
  pushQ as pushQAction,
  resetDiag as resetDiagAction,
} from '../actions/diag';
import { setProductId as setProductIdAction } from '../actions/course';
import { Row } from '../components/blocks/Grid';
import DiagContent from '../components/diag/DiagContent';
import { device } from '../styles/constants';
import { selectLastQID } from '../reducers/diag.jsx';

import Link from '../components/shared/atoms/Link';
import SVGAngleLeft16 from '../components/icons/AngleLeft16';

const FullPageWrapper = styled(Row)`
  > :first-child {
    flex: 5;
    padding: 2rem;
    ${(p) =>
    p.noPad &&
    css`
        padding: 0;
      `}
  }
  > :last-child {
    flex: 2;
  }
  min-height: calc(100vh - 79px);
  .diag-content-wrapper {
    text-align: left;
  }
  .diag-sidebar-wrapper {
    padding: 1rem 2rem;
  }
  @media ${device.md} {
    height: auto;
    > :first-child {
      padding: 1rem 2rem;
      ${(p) =>
    p.noPad &&
    css`
          padding: 0;
        `}
    }
  }
`;

// Diag API => First QID = 35. Next => vélo QID = 10 / trottinette QID = 6
const DiagWrapper = ({
  popQ,
  pushQ,
  lastQID,
  firstQID,
  questions,
  history,
}) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { qid } = useParams();
  const isFromMainResa = () => match?.path === '/reparation/symptoms/:qid';
  const isFirstQ = firstQID === qid;

  const doPushQ = ({ question }) => {
    const { quote, question: nextQuestion } = question.answer;
    if (!quote && !nextQuestion) {
      history.push('/reparation/date');
      return;
    }
    const nextId = quote ? `d${quote.id}` : nextQuestion.id;
    const url = isFromMainResa()
      ? `/reparation/symptoms/${nextId}`
      : `/devis-reparation/${nextId}`;
    history.push(url);
    pushQ({ q: question, a: question.answer });
  };

  const popQWithRouter = () => {
    const url = isFromMainResa()
      ? `/reparation/symptoms/${lastQID}`
      : `/devis-reparation/${lastQID}`;
    history.push(url);
    popQ(lastQID);
  };

  const handleFirstQClick = () => {
    history.push('/');
  };

  useEffect(() => {
    if (questions.map((q) => q.q.id).some((id) => id === Number(qid))) {
      popQ(Number(qid));
    }
  }, [qid, questions, popQ]);

  // devis hors funnel reset anciens devis
  useEffect(() => {
    if (qid && !qid.match(/^(([d][0-9]+)|([0-9]+))$/gm)) {
      history.push('/');
    }
  }, [qid]);

  return (
    <div>
      <Helmet
        title={t('maintainpleasure.titles.2')}
        meta={[
          {
            name: 'description',
            content: t('maintainpleasure.texts.2'),
          },
        ]}
      />
      <FullPageWrapper noPad={isFromMainResa()} sm>
        <div>
          {!isFromMainResa() && (
            <Link
              onClick={isFirstQ ? handleFirstQClick : popQWithRouter}
              color="blue.electric"
              before={<SVGAngleLeft16 />}>
              {isFirstQ ? t('diagwrapper.links.1') : t('diagwrapper.links.2')}
            </Link>
          )}
          {qid && (
            <DiagContent
              onNavigate={doPushQ}
              questionid={qid}
              // popQ={popQWithRouter}
              className="diag-content-wrapper"
            />
          )}
        </div>
        {/* {!!questions.length && !isFromMainResa() && (
          <div className="diag-sidebar-wrapper">
            <DiagSidebar questions={questions} popTo={popQ} />
          </div>
        )} */}
      </FullPageWrapper>
      {/* {!isFromMainResa() && (
        <div style={{ textAlign: 'center' }}>
          <img
            src={'/static/img/hab/foot.png'}
            style={{ margin: '0 auto', textAlign: 'center' }}
          />
        </div>
      )} */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    questions: state.diag.questions,
    productId: state.course.product_id,
    lastQID: selectLastQID(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  pushQ: (input) => dispatch(pushQAction(input)),
  popQ: (id) => dispatch(popQAction(id)),
  setProductId: (id) => dispatch(setProductIdAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DiagWrapper);
