import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
// Components
import Button from '../shared/atoms/Button';
import Input from '../shared/atoms/Input';
import Box from '../shared/atoms/Box';
import WrapperForm from './WrapperForm';
// Icons
import Message24 from '../icons/Message24';
import AngleLeft16 from '../icons/AngleLeft16';

// validationSchema
import { resetValidationSchema } from './ValidationSchema';

const ResetForm = ({ _switch, email, bg, noPadding, ...props }) => {
  const { t } = useTranslation();
  const withTitle = t('resetform.titles.1');
  return (
    <WrapperForm
      withTitle={withTitle}
      text={t('resetform.texts.1')}
      withSwitch={true}
      bg={bg}
      noPadding={noPadding}
      {...props}>
      <Formik
        initialValues={{ email }}
        validationSchema={resetValidationSchema}
        onSubmit={props.submit}>
        {({ isSubmitting, setFieldValue, submitCount, errors }) => (
          <Form>
            <Field
              name="email"
              render={({ field }) => (
                <Input
                  mb={{ xs: 2, lg: 4 }}
                  color="black"
                  bg="white"
                  before={<Message24 />}
                  placeholder={t('email')}
                  data-testid="input-email"
                  onChange={(e) => {
                    const value = e.target.value.toLowerCase().trim();
                    setFieldValue('email', value);
                  }}
                  error={!!submitCount && errors.email}
                  {...field}
                />
              )}
            />
            <Box mt={5} flexDirection="column" justifyContent="space-between">
              <Button
                disabled={isSubmitting}
                fullWidth
                alignSelf="flex-end"
                data-testid="reset-form-on-submit"
                mb={4}
                animation>
                {t('send')}
              </Button>
              <Button
                variant="neutral"
                fullWidth
                before={<AngleLeft16 />}
                onClick={() => _switch('LOGIN')}
                alignSelf="flex-end"
                animation>
                {t('resetform.goback.cta')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </WrapperForm>
  );
};
export default ResetForm;
