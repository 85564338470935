import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12 2a8 8 0 00-8 8v16a8 8 0 008 8h5.113L20 38l2.887-4H28a8 8 0 008-8V10a8 8 0 00-8-8H12z"
      fill="#d5ff4f"
    />
    <path
      d="M24.762 13.683c1.3 0 2.353-1.123 2.353-2.508s-1.054-2.508-2.353-2.508c-1.3 0-2.352 1.123-2.352 2.508s1.053 2.508 2.352 2.508zM17.34 24.667h-6.674l4.744-4.979 2.732 2.914c.714.762.207 2.065-.802 2.065zM25.704 24.667h3.63l-7.622-8.137a2.229 2.229 0 00-3.283-.014l-.685.72L24 23.913a2.338 2.338 0 001.705.754z"
      fill="#fff"
    />
  </svg>
);

const SIZE = 40;
const CAN_SET_COLOR = true;
const SvgPinCyclofix = styled(SVG)`
  min-height: ${(p) => p.size || SIZE}px;
  min-width: ${(p) => p.size || SIZE}px;
  max-height: ${(p) => p.size || SIZE}px;
  max-width: ${(p) => p.size || SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPinCyclofix;
