import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.774 2.25c-1.282 0-2.28 0-3.085.051-.818.052-1.488.16-2.108.397a6.75 6.75 0 00-3.883 3.883c-.238.62-.345 1.29-.397 2.108-.051.805-.051 1.803-.051 3.085v3.66c0 1.651 0 2.937.084 3.968.086 1.047.262 1.897.652 2.662a6.75 6.75 0 002.95 2.95c.765.39 1.615.566 2.662.652 1.031.084 2.316.084 3.968.084h3.89a.75.75 0 000-1.5H12.6c-1.692 0-2.917 0-3.88-.08-.954-.077-1.585-.228-2.103-.492a5.25 5.25 0 01-2.175-2.072l4.13-2.834 4.206 3.208 6.343-5.916 1.327 1.444a.75.75 0 101.104-1.015l-2.349-2.557-6.522 6.084-4.063-3.1-4.685 3.216a9.734 9.734 0 01-.104-.856c-.078-.963-.079-2.188-.079-3.88v-3.6c0-1.314 0-2.264.048-3.016.048-.746.14-1.247.3-1.665a5.25 5.25 0 013.02-3.02c.42-.161.92-.253 1.666-.3.752-.049 1.702-.049 3.016-.049 1.44 0 2.596 0 3.561.029A2.25 2.25 0 0117.25 6v1.5A3.75 3.75 0 0021 11.25h1a2.25 2.25 0 012.235 1.99c.015.836.015 1.808.015 2.96v.713a.75.75 0 101.5 0v-.74c0-.796 0-1.517-.005-2.173h.005v-.5c0-.119-.006-.236-.016-.353-.011-.583-.03-1.11-.06-1.59-.077-1.209-.234-2.172-.572-3.051a9.75 9.75 0 00-5.608-5.608c-.88-.338-1.842-.495-3.05-.572a29.976 29.976 0 00-.95-.044A3.786 3.786 0 0015 2.25h-1v.005c-.656-.005-1.377-.005-2.173-.005h-.053zm6.443 1.822c.338.563.533 1.223.533 1.928v1.5A2.25 2.25 0 0021 9.75h1c.752 0 1.453.222 2.04.603a6.455 6.455 0 00-.338-1.31 8.25 8.25 0 00-4.746-4.745 5.758 5.758 0 00-.739-.226z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 13a3 3 0 100-6 3 3 0 000 6zm1.5-3a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
      fill="#000"
    />
    <path
      d="M20.25 25a.75.75 0 01.75-.75h3.25V21a.75.75 0 011.5 0v3.25H29a.75.75 0 010 1.5h-3.25V29a.75.75 0 01-1.5 0v-3.25H21a.75.75 0 01-.75-.75z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = true;
const SvgPictureplus32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPictureplus32;
