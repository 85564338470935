/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-alert */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RESET_COURSE, SET_CODEPROMO } from '../../actions/course';
import { selectCapabilities, selectQuestionsLight } from '../../reducers/diag';
import PartenaireContext from '../../contexts/PartenaireContext';
import useResaShop from '../../hooks/useResaShop';
import {
  aggloSlots,
  diagToText,
  getEstimatedDuration,
  GTMAnalyticsSendDL,
  getLang,
  getVersion,
} from '../../utils/utils';

import { FeaturesContext } from '../../contexts/FeaturesContext';
import * as api from '../../utils/api';

// Components
import Separator from '../../components/Separator';
import Input from '../../components/shared/atoms/Input';
import Box from '../../components/shared/atoms/Box';
import Heading from '../../components/shared/atoms/Heading';
import Button from '../../components/shared/atoms/Button';
import StepsExplained from '../../components/shared/organisms/StepsExplained';
import RepairCheckoutWithPayment from '../../components/repair/RepairCheckoutWithPayment';
import RepairCheckoutWithoutPayment from '../../components/repair/RepairCheckoutWithoutPayment';

// Hooks
import useAlert from '../../hooks/useModal';
import useEstimatedDuration from '../../hooks/useEstimatedDuration';

// icons
import Cancel32Color from '../../components/icons/Cancel32Color';
import Answer32Color from '../../components/icons/Answer32Color';
import Shield32Color from '../../components/icons/Shield32Color';
import SurprisedFaceColor56 from '../../components/icons/SurprisedFaceColor56';
import { useCurrentZone } from '../../hooks/useZones';

const StyledButton = styled(Button)`
  min-width: 270px;
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    min-width: 100%;
  }
`;

const RepairCheckout = ({ getCourses }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const showModal = useAlert();
  const { t } = useTranslation();
  const {
    codepromo,
    client,
    course,
    questions,
    capabilities,
    ligtherQuestions,
    devis,
  } = useSelector((s) => ({
    codepromo: s.course.codepromo,
    client: s.client,
    course: s.course,
    devis: s.diag.devis,
    questions: s.diag.questions,
    capabilities: selectCapabilities(s, new URLSearchParams(window.location.search)),
    ligtherQuestions: selectQuestionsLight(s),
  }));
  const estimated_duration = useEstimatedDuration();
  const { isFeatureOn } = useContext(FeaturesContext);
  const isResaShop = useResaShop();
  const { lang } = getLang();
  const { selectedShop } = course;
  const { partenaire } = useContext(PartenaireContext);
  const [cbs, setCbs] = useState([]);
  const [card, setCard] = useState(null);
  const [loading, setLoading] = useState(true);
  const zone = useCurrentZone();

  const [stripePayment, setStripePayment] = useState({
    cvc: false,
    number: false,
    expiry: false,
    payment_method: '',
    message: '',
    state: null,
    complete: false,
  });

  const _setCard = (id) => {
    setCard(id);
  };

  const _fetchCards = async () => {
    if (!client || !client.id) {
      alert(
        'Erreur de chargement client. Veuillez recharger la page et réessayer.',
      );
      return;
    }

    const clientCbs = await api.get(`/clients/${client.id}/sources`);
    let clientCard = clientCbs.length ? clientCbs[0].id : '__ADD__';
    const defaultCard = clientCbs.find((c) => c.default);
    if (typeof defaultCard !== 'undefined') {
      clientCard = defaultCard.id;
    }
    setCbs(clientCbs);
    setCard(clientCard);
    setLoading(false);
  };

  const _sendPic = async (id, file) => {
    await api.postImage(`/courses/${id}/photos`, file);
  };

  const getCourseParams = () => {
    // backward compatibility with date/min/max
    const aggloSlts = aggloSlots(course.slots);
    const defaultDate = Object.keys(aggloSlts)[0];
    const defaultSlots = aggloSlts[defaultDate];

    console.log('getCourseParams', course);

    if (course.bikes && course.bikes.length > 0) {
      course.course_details = `${course.course_details}\n\n${course.bikes
        .map((bike) => bike.description)
        .filter((description) => description.trim() !== '')
        .join('\n\n')}`;
      course.course_details = `!Course multi-velos!\n\n${course.course_details}`;
    }

    const courseParams = {
      position: course.position,
      booking_uuid: course.booking_uuid,
      bike_details: diagToText(questions),
      bikes: course.bikes,
      course_details: course.course_details,
      product_id: course.product_id,
      date: moment(defaultDate).format(`YYYY-MM-DD ${defaultSlots[0].min}:00`),
      available_min: defaultSlots[0].min,
      available_max: defaultSlots[defaultSlots.length - 1].max,
      codepromo: course.codepromo,
      client: { id: client.id },
      enregistrement_type: 'FORMULAIRE',
      paiement: 'ONLINE',
      noclient: course.fixed === 0 ? 1 : 0,
      lieu_type: partenaire,
      slots: course.slots,
      capabilities,
      autodiag: ligtherQuestions,
    };

    if (estimated_duration > 0) {
      courseParams.ed = estimated_duration;
      if (getEstimatedDuration(devis.quote_elements) > 0) {
        courseParams.ed = getEstimatedDuration(devis.quote_elements);
      }
    }
    if (course.fixerId) {
      courseParams.fixer_id = course.fixerId;
    } else if (isResaShop) {
      courseParams.shop_id = selectedShop.id;
      courseParams.paiement = 'ONSITE';
    }
    if (lang === 'en' && !isResaShop) {
      courseParams.paiement = 'ONSITE';
    }
    return courseParams;
  };

  const _createCourse = async (pm_id = '') => {
    let pmid = pm_id;
    setLoading(true);
    if (card !== '__ADD__') {
      pmid = card;
    }

    const { photos } = course;

    const courseParams = getCourseParams();
    courseParams.version = getVersion();
    courseParams.pm_id = pmid;

    const newCourse = await api.post(`/courses`, courseParams);

    if (newCourse.id) {
      if (photos && photos.length)
        await Promise.all(photos.map((file) => _sendPic(newCourse.id, file)));

      GTMAnalyticsSendDL({
        event: 'Purchase',
        course_id: newCourse.id,
      });
      GTMAnalyticsSendDL({
        event: 'pageview',
        url: '/reparation/confirmation',
      });

      getCourses();
      // timeout to prevent weird race conditions
      setTimeout(() => {
        // when in widget, show only a static thank you page
        if (isFeatureOn('ISWIDGET')) {
          history.push(`/reparation/confirmation`);
        } else {
          history.push(`/courses/${newCourse.id}/mercipourvotredemande`);
        }
      }, 200);
      setTimeout(() => {
        // ugly hein
        dispatch({
          type: RESET_COURSE,
          full: true,
        });
      }, 300);
    } else if (newCourse.error_message) {
      showModal({
        title: newCourse.error_message,
        content: Object.values(newCourse.errors || []).join(','),
        showModal: true,
        icon: <SurprisedFaceColor56 />,
        showButton: [
          {
            text: t(`close.cta`),
          },
        ],
      });
      setStripePayment({ ...stripePayment, state: null });

      setLoading(false);
    }
  };

  useEffect(() => {
    _fetchCards();
  }, []);

  useEffect(() => {
    const { state, payment_method, complete } = stripePayment;
    setLoading(!complete && card === '__ADD__');
    if (state !== null) {
      setLoading(true);
    }
    if (state === 'error') {
      setLoading(false);
    }
    if (
      state === 'succes' ||
      (card && card !== '__ADD__' && state === 'send')
    ) {
      _createCourse(payment_method);
    } else if (state === 'send' && card === '__ADD__') {
      // _createCourse();
    }
  }, [stripePayment]);

  const sendRequest = () => {
    if (isResaShop || lang === 'en') {
      _createCourse();
      return;
    }
    const { state } = stripePayment;
    if (state === 'error') {
      setStripePayment({
        cvc: false,
        number: false,
        expiry: false,
        message: '',
        state: null,
      });
    } else {
      setStripePayment({ ...stripePayment, state: 'send' });
    }
  };

  return (
    <div>
      {isResaShop || (zone && zone.light) ? (
        <RepairCheckoutWithoutPayment client={client} />
      ) : (
        <RepairCheckoutWithPayment
          {...{
            cbs,
            _setCard,
            card,
            client,
            stripePayment,
            setStripePayment,
            loading,
          }}
        />
      )}
      {!!card && (
        <Box>
          {!isResaShop && !(zone && zone.light) && (
            <>
              <Separator axe="horizontal" bg="gray.lighter" margin="32px 0px" />
              <Heading level={5}>{t('discountcode')}</Heading>
              <Input
                width={{ xs: '100%', lg: '50%' }}
                name="codepromo"
                label={t('repaircheckout.discount.label')}
                placeholder={t('promocode')}
                onChange={(e) =>
                  dispatch({
                    type: SET_CODEPROMO,
                    codepromo: e.target.value,
                  })
                }
                value={codepromo}
                colorLabel="#313534"
              />
              <Separator axe="horizontal" bg="gray.lighter" margin="32px 0px" />
              <StepsExplained
                headings={[
                  t('repaircheckout.steps.titles.1'),
                  t('repaircheckout.steps.titles.2'),
                  t('repaircheckout.steps.titles.3'),
                ]}
                texts={[
                  t('repaircheckout.steps.texts.1'),
                  t('repaircheckout.steps.texts.2'),
                  t('repaircheckout.steps.texts.3'),
                ]}
              />
              <Separator axe="horizontal" bg="gray.lighter" margin="32px 0px" />
            </>
          )}
          <StyledButton
            mt={isResaShop || (!isResaShop && lang === 'en') ? 5 : 0}
            alignSelf="flex-end"
            onClick={sendRequest}
            data-testid="button-card-element-submit"
            disabled={loading}
            zIndex={1001}>
            {t('sendrequest')}
          </StyledButton>
        </Box>
      )}
    </div>
  );
};

export default RepairCheckout;
