import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { colors, device } from '../styles/constants';
import Card from './blocks/Card';
import { testFileType } from '../utils/utils';
import Button from './shared/atoms/Button';

const GridPhotoWrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  .uploaded-photos-container {
    height: 10rem;
    position: relative;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      overflow: hidden;
      border-radius: 4px;
    }
    button {
      position: absolute;
      bottom: 0.3rem;
      right: 0.3rem;
      padding: 0.2rem 0.6rem;
    }
  }
  @media ${device.xs} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Zone = styled(Card)`
  background-color: ${colors.blue};
  width: 100%;
  opacity: ${(props) => (props.dragging ? 0.5 : 1)};
  text-align: center;
  padding: 20px;

  :hover {
    cursor: pointer;
  }
`;

export const Dropzone = ({ setPhotos, photos, text, limit = -1, ...props }) => {
  const { t } = useTranslation();
  const [photosDisplayable, setPhotosDisplayable] = useState([]);
  const fileInputRef = useRef(null);
  const [dragging, setDragging] = useState(false);

  const _onFilesAdded = (evt) => {
    const { files } = evt.target;
    _saveFileList(files);
  };

  useEffect(() => {
    (async () => {
      const newPhotosDisplayable = [];

      for (let i = 0; i < photos.length; i++) {
        newPhotosDisplayable.push(await _readFile(photos[i]));
      }
      setPhotosDisplayable(newPhotosDisplayable);
    })();
  }, [photos]);

  const _saveFileList = async (arr) => {
    const newPhotos = [...photos];

    for (let i = 0; i < arr.length; i++) {
      if (!testFileType(arr[i].name)) {
        alert(t('dropzone.texts.1'));
        continue;
      }

      if (arr[i].size > 8 * 1e6) {
        alert(t('dropzone.texts.2'));
        continue;
      }

      newPhotos.push(arr[i]);
    }

    setPhotos(newPhotos);
  };

  const _popPhoto = async (event, index) => {
    event.preventDefault();
    const newPhotos = photos.filter((_, i) => i !== index);
    setPhotos(newPhotos);
  };

  const _readFile = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (evt) => {
        resolve(evt.target.result);
      };
      reader.readAsDataURL(file);
    });
  };

  const openFileDialog = () => fileInputRef.current.click();
  const onDragOver = (e) => e.preventDefault() || setDragging(true);
  const onDragLeave = (e) => e.preventDefault() || setDragging(false);

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    _saveFileList(e.dataTransfer.files);
    setDragging(false);
  };

  return (
    <div>
      <input
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
        multiple
        onChange={_onFilesAdded}
      />
      <Zone
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        onClick={openFileDialog}
        dragging={dragging}>
        <img
          src={'/static/img/pictos/download.png'}
          style={{ marginBottom: 10 }}
        />
        <div style={{ color: '#ffffff' }}>
          <b>{text || t('dropzone.texts.3')}</b>
        </div>
      </Zone>
      <GridPhotoWrapper>
        {photosDisplayable.map((p, i) => (
          <div className="uploaded-photos-container" key={i}>
            <img src={p} />
            <Button color="blue.main" onClick={(e) => _popPhoto(e, i)}>
              <small>{t('delete')}</small>
            </Button>
          </div>
        ))}
      </GridPhotoWrapper>
    </div>
  );
};

export default Dropzone;
