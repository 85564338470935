import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import Row from '../shared/atoms/Row';
import Box from '../shared/atoms/Box';
import Heading from '../shared/atoms/Heading';
import Text from '../shared/atoms/Text';
import SideBar from '../shared/molecules/SideBar';

const StyledSpanTitle = styled.span`
  ${(p) => css`
    font-size: ${p.theme.fontSizes.lg};
  `}
`;
const Steps = ({ product, steps = [], ...props }) => {
  const { t } = useTranslation();
  return (
    <Row md gap={56} {...props}>
      <Box>
        <Heading level={3}>
          {t('steps.headings.1', {
            product: product.productLabelMain,
          })}
        </Heading>
        <Text mt={-2}>
          {t('steps.texts.1', { product: product.productLabelMain })}
        </Text>
        <Box mt={4}>
          <img src={product.stepsImg} alt={product.productLabelMain} />
        </Box>
      </Box>
      <Box justifyContent="center">
        {steps.map((step, index) => {
          const lastIndex = steps.length - 1;
          return (
            <SideBar
              key={product.productLabelMain + index}
              title={
                <StyledSpanTitle>
                  {t('step')} {index + 1}
                </StyledSpanTitle>
              }
              content={[step]}
              animation={false}
              checkColor="primary"
              end={index === lastIndex}
              notInFunnel
            />
          );
        })}
      </Box>
    </Row>
  );
};

export default Steps;
