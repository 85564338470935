import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import Box from '../atoms/Box.jsx';
import Button from '../atoms/Button.jsx';
import Text from '../atoms/Text.jsx';
import Card from '../atoms/Card.jsx';
import Heading from '../atoms/Heading.jsx';
import Surprised64 from '../../icons/Surprised64';

import { selectLastQID, selectDevisID } from '../../../reducers/diag.jsx';
import SvgAngleright16 from '../../icons/AngleRight16.jsx';

const StyledButton = styled(Button)`
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    margin: 0 !important;
  }
`;

export const StyledCard = styled(Card)`
  position: absolute;
  z-index: 20;
  visibility: hidden;
  opacity: 0;
  bottom: -2rem;
  left: ${(p) => (p.$fixed ? '2.5rem' : '1.5rem')};
  right: ${(p) => (p.$fixed ? '2rem' : '1.5rem')};
  z-index: 100;
  transition: 400ms ease;
  ${(p) =>
    p.isVisible &&
    css`
      visibility: visible;
      opacity: 1;
      bottom: ${p.$fixed ? '3rem' : '1.5rem'};
    `};
`;

export default function ResultCard({
  position,
  positionError,
  positionInput,
  showResult = true,
  fixedLayout,
  ...props
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const { lastQID, devisID } = useSelector((s) => ({
    lastQID: selectLastQID(s),
    devisID: selectDevisID(s),
  }));
  const url = devisID
    ? `/reparation/resume`
    : `/reparation/symptoms/${lastQID}`;

  const handleClick = () => {
    history.push(url);
  };

  return (
    <StyledCard
      $fixed={fixedLayout}
      isVisible={(showResult && !!position) || positionError}
      boxShadow="md"
      p={4}
      {...props}>
      {position && (
        <Box flexDirection={{ xs: 'column', lg: 'row' }}>
          <StyledButton
            fullWidth
            onClick={handleClick}
            after={<SvgAngleright16 color="blue.electric" />}
            animation>
            {t('resultcard.cta')}
          </StyledButton>
        </Box>
      )}
      {positionError && (
        <Box flexDirection="row">
          <Surprised64 size={70} mr={4} />
          <Box>
            <Heading level={6} mb={2}>
              {t('resultcard.heading')}
            </Heading>
            <Text variant="small">{t('resultcard.text')}</Text>
          </Box>
        </Box>
      )}
    </StyledCard>
  );
}
