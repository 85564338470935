import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Modal from './Modal';

import Box from '../components/shared/atoms/Box';
import Text from '../components/shared/atoms/Text';
import Input from '../components/shared/atoms/Input';

import Button from '../components/shared/atoms/Button';
import Heading from '../components/shared/atoms/Heading';
// Icons
import ProblemColor56 from '../components/icons/ProblemColor56';
import HappyColor56 from '../components/icons/HappyColor56';

const StyledButton = styled(Button)`
  width: ${(p) => (p.fixer ? 'auto' : '100%')};
`;

export default function NoFixerModal({
  fixer,
  open,
  close,
  waitinglistSent,
  onSubmit,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const ActionRow = ({ isSubmitting }) => (
    <Box>
      <StyledButton
        disabled={isSubmitting}
        fixer={fixer}
        size={fixer ? 'compact' : null}
        alignSelf={fixer ? 'flex-end' : 'auto'}
        onClick={fixer ? close : () => null}
        type={'submit'}>
        {fixer ? t('nofixermodal.cta.1') : t('nofixermodal.cta.2')}
      </StyledButton>
      {!fixer && (
        <StyledButton
          variant="neutral"
          onClick={() => (window.location.href = '/')}
          mt={2}>
          {t('nofixermodal.cta.3')}
        </StyledButton>
      )}
    </Box>
  );
  return (
    <Modal
      open={open}
      onRequestClose={close}
      hasCloseAction={!fixer}
      width={waitinglistSent ? 'small' : 'medium'}>
      <div>
        {waitinglistSent ? (
          <Box>
            <Heading level={4} mt={4} mb={5}>
              {t('nofixermodal.titles.1')}
            </Heading>
            <Text mb={5}>{t('nofixermodal.texts.4')}</Text>
            <Box alignSelf="flex-end">
              <Button onClick={close}>{t('close.cta')}</Button>
            </Box>
          </Box>
        ) : (
          <div>
            <Heading level={5}>
              {fixer
                ? t(`nofixermodal.headings.1`, {
                  prenom: fixer.prenom,
                })
                : t('nofixermodal.headings.2')}
            </Heading>
            {fixer ? (
              <Text mb={5}>
                {t('nofixermodal.texts.1', { prenom: fixer.prenom })}
              </Text>
            ) : (
              <Text color="black" mb={5}>
                {t('nofixermodal.texts.2')}
              </Text>
            )}
            {fixer ? (
              <ActionRow />
            ) : (
              <Formik
                initialValues={{ email: '' }}
                onSubmit={onSubmit}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .required('email.invalid')
                    .email('email.invalid'),
                  comment: Yup.string()
                    .required('required.information')
                    .max(1023, 'long.description'),
                })}>
                {({ isSubmitting, setFieldValue, submitCount, errors }) => (
                  <Form style={{ width: '100%' }}>
                    <Field
                      name="email"
                      render={({ field }) => (
                        <Input
                          label="E-mail"
                          colorLabel="gray.darkest"
                          importantLabel={true}
                          mb={{ xs: 2, lg: 4 }}
                          color="black"
                          bg="white"
                          placeholder={t('Email')}
                          onChange={(e) => {
                            const value = e.target.value.toLowerCase().trim();
                            setFieldValue('email', value);
                          }}
                          error={!!submitCount && errors.email}
                          {...field}
                        />
                      )}
                    />
                    <Field
                      name="comment"
                      render={({ field }) => (
                        <Input
                          label={t('Message')}
                          colorLabel="gray.darkest"
                          importantLabel={true}
                          as="textarea"
                          mb={{ xs: 2, lg: 4 }}
                          color="black"
                          bg="white"
                          rows="3"
                          onChange={(e) => setFieldValue('comment', e)}
                          placeholder={t('nofixermodal.texts.3')}
                          error={!!submitCount && errors.comment}
                          {...field}
                        />
                      )}
                    />
                    <ActionRow isSubmitting={isSubmitting} />
                  </Form>
                )}
              </Formik>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}
