import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M9 24c0-8.69 8.348-16 19-16s19 7.31 19 16h2c0-10.087-9.56-18-21-18S7 13.913 7 24h2zM48.5 33a1 1 0 10-2 0v1a3 3 0 01-3 3H29a1 1 0 100 2h14.5a5 5 0 005-5v-1z"
      fill="#000"
    />
    <path
      d="M44 25a4 4 0 018 0v6a4 4 0 01-8 0v-6zM12 25a4 4 0 00-8 0v6a4 4 0 008 0v-6z"
      fill="#A6F2C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44 28c0 8.837-7.163 16-16 16s-16-7.163-16-16 7.163-16 16-16 16 7.163 16 16zm2 0c0 9.941-8.059 18-18 18s-18-8.059-18-18 8.059-18 18-18 18 8.059 18 18zm-27-4a1 1 0 00-.978 1.208C18.976 29.686 23.108 33 28 33c4.892 0 9.024-3.314 9.978-7.792A1 1 0 0037 24H19zm9 7c-3.522 0-6.488-2.098-7.653-5h15.306c-1.165 2.902-4.131 5-7.653 5zm5.5-11a1.5 1.5 0 00-1.5 1.5 1 1 0 11-2 0 3.5 3.5 0 117 0 1 1 0 11-2 0 1.5 1.5 0 00-1.5-1.5zM21 21.5a1.5 1.5 0 013 0 1 1 0 102 0 3.5 3.5 0 10-7 0 1 1 0 102 0z"
      fill="#000"
    />
    <path
      d="M24 38a2 2 0 012-2h4a2 2 0 110 4h-4a2 2 0 01-2-2z"
      fill="#A6F2C4"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgSupportColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgSupportColor56;
