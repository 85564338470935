import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 27a3 3 0 013-3h12a3 3 0 110 6H3a3 3 0 01-3-3z"
      fill="#1F77F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2C8.268 2 2 8.268 2 16s6.268 14 14 14 14-6.268 14-14S23.732 2 16 2zM4 16c0-.545.036-1.082.107-1.608L7.323 16l-3.216 1.608A12.11 12.11 0 014 16zm.413-3.132L9 15.16l2.392-1.195-.833-2.223L5.66 9.906c-.54.916-.964 1.91-1.247 2.962zm7.509 2.51L10.677 16l1.25.625.088-.232.004.002a4.057 4.057 0 01-.002-.77l-.002.001-.093-.248zm1.621 3.779a4.023 4.023 0 01-.7-.7l-.42 1.12 1.12-.42zm-2.145-1.12l-.84 2.24L5.67 22.11a11.93 11.93 0 01-1.257-2.978L9 16.838l2.398 1.2zm2.564 2.565l-2.238.84L9.89 26.33c.92.546 1.92.972 2.978 1.257L15.16 23l-1.199-2.398zM9.86 22.14l-1.245 3.32a12.069 12.069 0 01-2.074-2.075L9.86 22.14zM16 20c-.133 0-.265-.006-.395-.02l.002.005-.232.087.625 1.25.625-1.25-.232-.087.002-.004c-.13.012-.262.019-.395.019zm2.457-.843a4.03 4.03 0 00.7-.7l.42 1.12-1.12-.42zm1.615-2.532l-.087-.232-.004.002a4.057 4.057 0 00.002-.77l.002.001.093-.248 1.245.622-1.25.625zm.53 1.413l2.398-1.2 4.587 2.294a11.927 11.927 0 01-1.257 2.978l-4.889-1.834-.84-2.238zm4.857 5.347L22.14 22.14l1.245 3.32a12.073 12.073 0 002.074-2.075zm-5.183-1.944l-2.238-.84-1.2 2.399 2.294 4.587a11.927 11.927 0 002.978-1.257l-1.834-4.889zM16 24.677l1.608 3.216a12.118 12.118 0 01-3.216 0L16 24.677zM8.61 6.545A12.071 12.071 0 006.53 8.63l3.33 1.249-1.25-3.334zm1.274-.872l1.84 4.905 2.23.836L15.161 9l-2.293-4.587a11.93 11.93 0 00-2.984 1.26zM16 4c-.545 0-1.082.036-1.608.107L16 7.323l1.608-3.216A12.11 12.11 0 0016 4zm.838 5l2.294-4.587a11.93 11.93 0 012.984 1.26l-1.84 4.905-2.23.836L16.839 9zm-.204 2.944l-.21.078a4.055 4.055 0 00-.849 0l-.208-.078.633-1.267.634 1.267zm-3.107.912l-1.104-.414.415 1.108c.2-.259.432-.492.689-.694zm6.05-.414l-1.104.414c.257.202.488.435.689.694l.415-1.108zM22.14 9.88l3.33-1.249a12.07 12.07 0 00-2.08-2.085L22.14 9.88zm-.699 1.864l4.899-1.837c.54.916.964 1.91 1.247 2.962L23 15.16l-2.392-1.195.833-2.223zm6.452 2.649L24.677 16l3.216 1.608a12.118 12.118 0 000-3.216zM14 16a2 2 0 114 0 2 2 0 01-4 0z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgWheelcolor32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgWheelcolor32;
