import { useSelector } from 'react-redux';

export function isShopCycloBookable(shop, productId) {
  return (
    shop.bookable &&
    shop.products &&
    ~shop.products
      .map((p) => parseInt(p.id, 10))
      .indexOf(parseInt(productId, 10))
  );
}

export default function useBookableShops() {
  const { shops } = useSelector((s) => s.course);
  const productId = useSelector((s) => s.course.product_id);

  const bookableShops = [];
  if (shops) {
    shops.forEach((shop, i) => {
      if (isShopCycloBookable(shop.value, productId)) {
        bookableShops.push(shop);
      }
    });
  }

  return bookableShops;
}
