import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 57 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#Record_edit_Color_56_svg__clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2 25.3c-.9 0-1.7-.3-2.3-.9-1.2-1.2-1.2-3.3 0-4.5l2.5-2.5c-.2-1.2-.5-3.7.8-5.4 1.6-2.1 4-3.2 6.2-3 .4 0 .8.2 1.1.5.3.3.5.6.6 1 .1.7-.1 1.4-.6 2l-2 2c.05.15.1.334.15.519.05.184.1.368.15.518l1.1.263 2-2c.5-.5 1.3-.8 2-.6.4.1.8.3 1 .6.3.3.4.7.5 1.1.3 2.2-.9 4.6-3 6.2-1.7 1.3-4.2 1-5.3.8l-2.5 2.5c-.8.6-1.6.9-2.4.9zM24.8 11c-1.4 0-2.9.8-4 2.2-.9 1.2-.5 3.4-.3 4.2l.1.5-.3.5-2.9 2.9c-.5.5-.5 1.2 0 1.7s1.2.5 1.7 0l3.1-3.1.4-.3.4.1c.8.2 3 .6 4.2-.3 1.5-1.2 2.4-2.8 2.2-4.3 0 0-.1 0-.1.1L26.5 18l-3.6-1-.4-3.5 2.6-2.5.1-.1c-.2.1-.3.1-.4.1z"
        fill="#000"
      />
      <path
        d="M15.5 30a1 1 0 100 2h15a1 1 0 100-2h-15zM14.5 43a1 1 0 011-1h15a1 1 0 110 2h-15a1 1 0 01-1-1zM26.5 36a1 1 0 100 2h15a1 1 0 100-2h-15zM34.5 31a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1zM15.5 36a1 1 0 100 2h6a1 1 0 100-2h-6z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.5 44a8 8 0 01-8 8h-24a8 8 0 01-8-8V12a8 8 0 018-8h14c9.941 0 18 8.059 18 18v22zm-32-38h14a4 4 0 014 4v2.857a6 6 0 006 6h2a4 4 0 014 4V44a6 6 0 01-6 6h-24a6 6 0 01-6-6V12a6 6 0 016-6zm29.49 11.976A16.034 16.034 0 0035.594 6.828c.574.92.906 2.007.906 3.172v2.857a4 4 0 004 4h2c1.302 0 2.506.415 3.49 1.119z"
        fill="#000"
      />
      <path
        d="M55.58 31.671a3.361 3.361 0 00-4.751-4.751l4.751 4.751zM54.167 33.086l-4.753-4.753-9.239 9.238L38.5 44l6.429-1.675 9.238-9.239z"
        fill="#1F77F9"
      />
    </g>
    <defs>
      <clipPath id="Record_edit_Color_56_svg__clip0">
        <path fill="#fff" transform="translate(.5)" d="M0 0h56v56H0z" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgRecordeditColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgRecordeditColor56;
