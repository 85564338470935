import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import Box from '../shared/atoms/Box';
import Button from '../shared/atoms/Button';
import InfoText from '../InfoText';
import Text from '../shared/atoms/Text';
import Badge from '../shared/atoms/Badge';
// Icons
import AngleRight16 from '../icons/AngleRight16';

const StyledButton = styled(Button)`
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    width: 100%;
  }
`;

const SelectedSlots = ({ slots, fixed, submit, toContinue, shopId }) => {
  const { t } = useTranslation();
  return (
    <Box
      justifyContent={shopId ? 'flex-end' : 'space-between'}
      flexDirection={{ xs: 'column', md: 'row' }}
      my={3}
      disabled={!slots.length}>
      {fixed === 0 ? (
        <div>
          <InfoText>{t('selectedslots.texts.1')}</InfoText>
        </div>
      ) : null}
      {!shopId && (
        <Box justifyContent="center" mb={{ xs: 5, lg: 0 }}>
          <Box flexDirection="row" alignItems="center">
            <Badge
              bg={slots.length < 5 ? 'primary.pale' : 'green.pale'}
              as="div"
              py={1}
              px={2}
              mb={0}>
              <Text
                color={slots.length < 5 ? 'primary.darkest' : 'green.dark'}
                variant="large"
                fontWeigth="bold">
                {slots.length}
              </Text>
            </Badge>
            <Text variant="medium" fontWeight="500" color="black">
              {slots.length === ''
                ? t('selectedslots')
                : t('selectedslots_plural')}
            </Text>
          </Box>
          {slots.length < 5 && (
            <Text variant="small">{t('selectedslots.addmoreslots')}</Text>
          )}
        </Box>
      )}
      {toContinue && (
        <StyledButton
          after={<AngleRight16 />}
          disabled={!slots.length}
          data-testid="submit-course-date"
          onClick={submit}
          style={{ minWidth: '200px' }}
          zIndex={1001}>
          {t('continue')}
        </StyledButton>
      )}
    </Box>
  );
};

export default SelectedSlots;
