import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.247 3.336a1 1 0 01-.083 1.411L9.7 5.16c-.71.632-1.196 1.064-1.541 1.428-.338.356-.469.574-.53.75a2 2 0 000 1.325c.061.175.192.393.53.749.345.363.83.796 1.541 1.428l.464.413a1 1 0 01-1.328 1.494l-.497-.441c-.67-.596-1.22-1.085-1.631-1.518-.424-.447-.77-.906-.967-1.463a4 4 0 010-2.65c.196-.557.543-1.016.967-1.463.41-.433.96-.922 1.63-1.518l.498-.441a1 1 0 011.411.083z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgAngleleft16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgAngleleft16;
