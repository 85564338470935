import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Box from '../../shared/atoms/Box';
import Text from '../../shared/atoms/Text';
// Icons
import Winter from '../../icons/Winter32';
import Spring from '../../icons/Spring32';
import Summer from '../../icons/Summer32';
import Autumn from '../../icons/Autumn32';

const Season = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    min-width: 120px;
    ::before {
      position: absolute;
      content: '';
      border-bottom: 6px solid ${(p) => p.theme.colors.gray.lighter};
      width: 100%;
      top: 17px;
      left: -50%;
      z-index: 2;
    }
    ::after {
      position: absolute;
      content: '';
      border-bottom: 2px solid ${(p) => p.theme.colors.gray.lighter};
      width: 100%;
      top: 17px;
      left: 50%;
      z-index: 2;
    }

    :first-child::before {
      content: none;
    }
    :last-child::after {
      content: none;
    }
  }

  ${(p) => p.isActive && 'color: black'};
`;
const Icon = styled(Box)`
  position: relative;
  cursor: pointer;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-bottom: 6px;
  box-shadow: 0 0 0 7px white;
  ${(p) =>
    (p.isActive || p.isCompleted) &&
    `box-shadow: 0 0 0 3px white, 0 0 0 5px black;`};
  :hover {
    box-shadow: 0 0 0 3px white, 0 0 0 5px black;
  }
`;

const Seasons = ({ season, setSeason, ...props }) => {
  const { t } = useTranslation();
  return (
    <Box flexDirection="row" justifyContent="space-between" {...props}>
      <Season mr={{ xs: 4, md: 0 }}>
        <Icon
          bg="green.lighter"
          isActive={season === 'SPRING'}
          onClick={() => setSeason('SPRING')}>
          <Spring style={{ cursor: 'pointer' }} />
        </Icon>
        <Text
          color={season === 'SPRING' ? 'black' : 'gray.darker'}
          fontWeight={2}
          fontSize={{ xs: 'xs', md: 'md' }}>
          {t('spring')}
        </Text>
      </Season>
      <Season mr={{ xs: 4, md: 0 }}>
        <Icon
          bg="yellow.lighter"
          isActive={season === 'SUMMER'}
          onClick={() => setSeason('SUMMER')}>
          <Summer style={{ cursor: 'pointer' }} />
        </Icon>
        <Text
          color={season === 'SUMMER' ? 'black' : 'gray.darker'}
          fontWeight={2}
          fontSize={{ xs: 'xs', md: 'md' }}>
          {t('summer')}
        </Text>
      </Season>
      <Season mr={{ xs: 4, md: 0 }}>
        <Icon
          bg="primary.lighter"
          isActive={season === 'AUTUMN'}
          onClick={() => setSeason('AUTUMN')}>
          <Autumn style={{ cursor: 'pointer' }} />
        </Icon>
        <Text
          color={season === 'AUTUMN' ? 'black' : 'gray.darker'}
          fontWeight={2}
          fontSize={{ xs: 'xs', md: 'md' }}>
          {t('autumn')}
        </Text>
      </Season>
      <Season>
        <Icon
          bg="blue.lighter"
          isActive={season === 'WINTER'}
          onClick={() => setSeason('WINTER')}>
          <Winter style={{ cursor: 'pointer' }} />
        </Icon>
        <Text
          color={season === 'WINTER' ? 'black' : 'gray.darker'}
          fontWeight={2}
          fontSize={{ xs: 'xs', md: 'md' }}>
          {t('winter')}
        </Text>
      </Season>
    </Box>
  );
};
export default Seasons;
