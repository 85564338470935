import styled from 'styled-components';
import React from 'react';
import { colors } from '../../styles/constants';
import ErrorLabel from './ErrorLabel';

const InputGroupWrapper = styled.div`
    ${(props) =>
      !props.stacked && `border-bottom: 1px solid ${colors.lightgrey};`}
    
    ${(props) =>
      props.isCodePromo &&
      `
        border:1px solid ${colors.blue};
        border-radius:4px;
        padding:10px;
        color:${colors.blue};
    `}

    ${(props) =>
      props.error &&
      `
        border-color:${colors.orange} !important
    `}
    ${(props) =>
      props.valid &&
      `
        /*border-color:${colors.green}*/
    `}
`;

const InputGroup = ({
  children,
  label,
  error,
  valid,
  name,
  stacked = false,
  ...props
}) => (
  <div {...props}>
    <InputGroupWrapper
      error={error}
      valid={valid}
      className={'input-group'}
      isCodePromo={name && name === 'codepromo'}
      style={stacked ? { display: 'block' } : {}}
      stacked={stacked}>
      <label htmlFor={name}>{label}</label>
      {children}
    </InputGroupWrapper>
    {error ? <ErrorLabel>{error}</ErrorLabel> : <span>&nbsp;</span>}
  </div>
);

export default InputGroup;
