import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.775 11.565A2.921 2.921 0 0021 9.185v-.067a2.9 2.9 0 00-.266-1.215L19.13 4.429l-.029-.047A2.925 2.925 0 0016.616 3H7.383a2.925 2.925 0 00-2.485 1.382l-.029.047-1.602 3.474A2.9 2.9 0 003 9.118v.067c0 .982.484 1.852 1.227 2.382v5.536a3.9 3.9 0 003.9 3.9h7.748a3.9 3.9 0 003.9-3.9v-5.538zM4.901 8.657l1.553-3.365c.208-.307.556-.492.93-.492h9.232c.373 0 .72.185.93.492l1.552 3.365a1.1 1.1 0 01.101.46v.068a1.125 1.125 0 11-2.25 0v-.506a.9.9 0 00-1.8 0v.506a1.125 1.125 0 01-2.25 0v-.506a.9.9 0 10-1.8 0v.506a1.125 1.125 0 11-2.25 0v-.506a.9.9 0 00-1.8 0v.506a1.125 1.125 0 01-2.249 0v-.067c0-.16.035-.316.101-.461zm13.074 3.451a2.914 2.914 0 01-1.925-.812 2.915 2.915 0 01-2.025.814c-.786 0-1.5-.31-2.025-.814a2.915 2.915 0 01-2.025.814c-.786 0-1.5-.31-2.025-.814a2.914 2.914 0 01-1.923.812v4.995c0 1.16.94 2.1 2.1 2.1H9V16.75a3 3 0 116 0v2.453h.875a2.1 2.1 0 002.1-2.1v-4.995zM13.2 19.2v-2.45a1.2 1.2 0 00-2.4 0v2.45h2.4z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgShop24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgShop24;
