import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Modal from './Modal';

import Box from '../components/shared/atoms/Box';
import Button from '../components/shared/atoms/Button';
import Avatar from '../components/shared/atoms/Avatar';
import Heading from '../components/shared/atoms/Heading';
import Select from '../components/shared/molecules/Select';

import { fixerPhotoUrl } from '../utils/fixers';
import Text from '../components/shared/atoms/Text';
import Link from '../components/shared/atoms/Link';
import useAlert from '../hooks/useModal';
import Input from '../components/shared/atoms/Input';
import { SET_FIXER_CODE, setFixerFromCode } from '../actions/course';

const SelectItem = ({ id, prenom, photo_id, selected, date, onClick, t }) => (
  <Select
    onClick={onClick}
    width={{ xs: '100%', md: '40rem' }}
    selected={id === selected}>
    <Box flexDirection="row">
      <Avatar src={fixerPhotoUrl({ photo_id })} mr={4} />
      <Box>
        <Text variant="large" color="black">
          <b>{prenom}</b>
        </Text>
        {date ? (
          <Text>
            {t('renewmodal.texts.1')} <b>{moment(date).format('DD/MM/YYYY')}</b>
          </Text>
        ) : (
          <Text>{t('renewfixer.texts.2')}</Text>
        )}
      </Box>
    </Box>
  </Select>
);

const AddFixerAlert = ({ addFixer, close }) => {
  const [input, setInput] = useState('');
  const { t } = useTranslation();
  return (
    <Box>
      {t('renewfixer.texts.1')}
      <Input
        placeholder={t('renewfixer.texts.3')}
        mt={2}
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />
      <Box flexDirection="row" justifyContent="flex-end" mt={5}>
        <Button
          onClick={close}
          variant="neutral"
          mr={2}
          color="black"
          width="100px">
          {t('button.cancel')}
        </Button>
        <Button
          onClick={() => addFixer(input)}
          color="blue.electric"
          width="100px">
          {t('button.valid')}
        </Button>
      </Box>
    </Box>
  );
};

export default function RenewFixerModal({
  fixers = null,
  open,
  close,
  confirm,
  ...rest
}) {
  const { t } = useTranslation();
  const fixerId = useSelector((s) => s.course.fixerId);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(fixerId.length ? fixerId : null);
  const showAlert = useAlert();

  const addFixer = (code) => {
    dispatch(setFixerFromCode(code));
    showAlert({ showModal: false });
    close();
  };

  const showPrompt = () =>
    showAlert({
      title: t('renewfixer.link'),
      content: (
        <AddFixerAlert
          addFixer={addFixer}
          close={() => showAlert({ showModal: false })}
        />
      ),
      showModal: true,
      onClose: () => {
        if (!fixers.length) {
          close();
        }
      },
    });

  useEffect(() => {
    if (!open) {
      return;
    }

    if (!fixers.length) {
      showPrompt();
    }
  }, [open]);

  return (
    <Modal onRequestClose={close} open={open} {...rest}>
      <Box maxHeight={{ xs: '100%', md: '35rem' }}>
        <Box alignItems="center">
          <Heading level={4}>{t('renewmodal.headings.1')}</Heading>
        </Box>
        <Box style={{ overflow: 'auto' }}>
          {fixers &&
            fixers.map(({ fixer, date }) => (
              <SelectItem
                key={fixer.id}
                onClick={() => setSelected(fixer.id)}
                selected={selected}
                t={t}
                date={date}
                {...fixer}
              />
            ))}
        </Box>
        <Box>
          <Link color="blue.electric" onClick={showPrompt}>
            {t('renewfixer.link')}
          </Link>
        </Box>

        <Box flexDirection="row" justifyContent="flex-end" mt={5}>
          <Button
            onClick={close}
            variant="neutral"
            mr={2}
            color="black"
            width="100px">
            {t('button.cancel')}
          </Button>
          <Button
            onClick={() => confirm(selected)}
            color="primary.main"
            width="100px">
            {t('button.valid')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
