import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M13.508 13.294a.75.75 0 00-.312-1.467l-4.467.95c-.56.119-.936.198-1.23.236l-.112.012A9.754 9.754 0 0116.5 6.75c5.385 0 9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75c-4.483 0-8.26-3.026-9.4-7.148a.75.75 0 10-1.445.4c1.313 4.755 5.67 8.248 10.845 8.248 6.213 0 11.25-5.037 11.25-11.25S22.713 5.25 16.5 5.25c-4.673 0-8.68 2.849-10.38 6.903a3.729 3.729 0 01-.013-.044c-.085-.284-.165-.66-.284-1.22l-.95-4.467a.75.75 0 10-1.467.312l.956 4.497c.11.523.204.959.308 1.309.11.365.25.7.488.994a2.75 2.75 0 001.425.926c.366.098.729.09 1.107.04.362-.046.798-.139 1.32-.25l4.498-.956z"
      fill="#000"
    />
    <path
      d="M19.44 26.44a1.5 1.5 0 102.122 2.12l2.444-2.441 2.433 2.434a1.5 1.5 0 102.12-2.122l-2.432-2.433 2.428-2.427a1.5 1.5 0 10-2.121-2.121l-2.428 2.426-2.436-2.438a1.5 1.5 0 10-2.121 2.122l2.436 2.437-2.444 2.443z"
      fill="#d5ff4f"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgCancel32Color = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgCancel32Color;
