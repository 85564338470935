import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import ConnexionModule from '../../components/ConnexionModule';
import { selectIsLoggedIn } from '../../reducers/client';

const StyledImg = styled.img`
  border-radius: 24px;
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    border-radius: 16px;
  }
`;

const RepairLogin = () => {
  const { t } = useTranslation();
  const isLoggedIn = useSelector((state) => selectIsLoggedIn(state));
  const autodiag = useSelector((state) => state.autodiag.autodiag);

  console.log('autodiag:', autodiag); // Debugging line

  // if (autodiag !== 'v2') {
  //   console.log('returning autodiag:', autodiag); // Debugging line
  //   return <Redirect to={'/reparation/symptoms'} />;
  // }

  return (
    <div>
      {isLoggedIn ? (
        <Redirect to={'/reparation/checkout'} />
      ) : (
        <ConnexionModule
          redir={'reparation'}
          titleSignin={t('repairlogin.titlesignin')}
          ctaLabelSignin={t('repairlogin.ctasignin')}
          titleSignup={t('repairlogin.titlesignup')}
          ctaLabelSignup={t('repairlogin.ctasignup')}
        />
      )}
    </div>
  );
};

export default RepairLogin;
