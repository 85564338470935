import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15a3 3 0 100-6 3 3 0 000 6zm1.5-3a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.996 16.75H28v-1.5c0-.181-.013-.36-.038-.534-.065-2.021-.242-3.38-.723-4.543a10 10 0 00-5.412-5.412c-1.03-.427-2.217-.614-3.881-.697A3.77 3.77 0 0017.25 4h-2v.004C14.565 4 13.819 4 13 4c-2.796 0-4.193 0-5.296.457a6 6 0 00-3.247 3.247C4 8.807 4 10.204 4 13v5.4c0 3.36 0 5.04.654 6.324a6 6 0 002.622 2.622C8.56 28 10.24 28 13.6 28H19c2.796 0 4.194 0 5.296-.457a6 6 0 003.247-3.247C28 23.194 28 21.796 28 19c0-.819 0-1.565-.004-2.25zM13.6 26.5H19c1.418 0 2.407 0 3.18-.054.761-.052 1.204-.149 1.542-.289a4.5 4.5 0 002.435-2.435c.14-.338.238-.78.29-1.541.01-.155.018-.317.025-.49l-4.464-3.71-6.962 5.967-4.568-3.201L5.9 23.634l-.144-.227c.066.253.144.458.234.636a4.5 4.5 0 001.967 1.966c.395.202.917.34 1.824.414.926.076 2.114.077 3.819.077zm12.9-6.737V19c0-1.683 0-3.026-.034-4.142A2.25 2.25 0 0024.25 13h-1a3.75 3.75 0 01-3.75-3.75v-1.5a2.25 2.25 0 00-1.744-2.193C16.526 5.501 15.003 5.5 13 5.5c-1.418 0-2.407 0-3.18.054-.761.051-1.204.149-1.542.289a4.5 4.5 0 00-2.435 2.435c-.14.338-.238.78-.29 1.541-.052.774-.053 1.763-.053 3.181v5.4c0 1.616.001 2.768.066 3.672l4.956-3.126 4.432 3.106 7.038-6.033 4.508 3.744zM20.513 5.9c.31.546.487 1.177.487 1.85v1.5a2.25 2.25 0 002.25 2.25h1c.725 0 1.402.206 1.976.562a6.382 6.382 0 00-.373-1.315 8.5 8.5 0 00-4.6-4.6 5.694 5.694 0 00-.74-.247z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = true;
const SvgPicture32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPicture32;
