import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.44 12.56L28 6.165 12.56 12.56 6.165 28l6.395 15.44L28 49.835l15.44-6.395L49.835 28 43.44 12.56zM28 4l-16.97 7.03L4 28l7.03 16.97L28 52l16.97-7.03L52 28l-7.03-16.97L28 4zm2.212 13.22a1 1 0 01.812 1.158L28.447 32.99a1 1 0 11-1.97-.348l2.577-14.611a1 1 0 011.158-.811zm-3.28 18.596a1 1 0 01.812 1.158l-.094.532a1 1 0 01-1.97-.348l.094-.53a1 1 0 011.159-.812z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.293 13.865a1 1 0 001.414 0l3.158-3.158a1 1 0 00-1.414-1.414l-3.158 3.158a1 1 0 000 1.414z"
      fill="#FFE249"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.809 27.347l4.557 3.692a1 1 0 01-1.259 1.554l-2.265-1.835-1.649 5.3-3.473-2.954a1 1 0 011.296-1.523l1.167.992 1.626-5.226z"
      fill="#28D898"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.102 17.598a1 1 0 011.3.557l2.105 5.263a1 1 0 01-1.857.743l-2.105-5.263a1 1 0 01.557-1.3z"
      fill="#8A38F3"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgProblemColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgProblemColor56;
