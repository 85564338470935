import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 18.2c.332 0 1.116-.316 1.926-1.934.331-.664.618-1.463.834-2.366H9.24c.216.903.503 1.702.834 2.366.81 1.618 1.594 1.934 1.926 1.934zm-3.078-6.1A17.9 17.9 0 018.8 12c0-.73.043-1.433.122-2.1h6.156c.08.667.122 1.37.122 2.1s-.043 1.433-.122 2.1H8.922zm7.683 1.8c-.295 1.392-.743 2.623-1.302 3.608a8.233 8.233 0 003.912-3.608h-2.61zm3.324-1.8h-3.04a19.805 19.805 0 000-4.2h3.04A8.21 8.21 0 0120.2 12a8.21 8.21 0 01-.271 2.1zm-12.819 0H4.071A8.212 8.212 0 013.8 12c0-.726.094-1.43.271-2.1h3.04a19.789 19.789 0 000 4.2zm-2.325 1.8h2.61c.295 1.392.743 2.623 1.302 3.608A8.233 8.233 0 014.785 15.9zM9.24 8.1h5.52a11.143 11.143 0 00-.834-2.366C13.116 4.116 12.332 3.8 12 3.8c-.332 0-1.116.316-1.926 1.934A11.147 11.147 0 009.24 8.1zm7.365 0h2.61a8.233 8.233 0 00-3.912-3.608c.56.985 1.007 2.216 1.302 3.608zM8.697 4.492c-.56.985-1.007 2.216-1.302 3.608h-2.61a8.233 8.233 0 013.912-3.608z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgWeb24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgWeb24;
