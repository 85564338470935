import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import Box from '../../shared/atoms/Box';
import Heading from '../../shared/atoms/Heading';
import Text from '../../shared/atoms/Text';
import Button from '../../shared/atoms/Button';
import SectionBackground from '../../shared/molecules/SectionBackground';
import Container from '../../shared/atoms/Container';
// Icons
import AngleRight16 from '../../icons/AngleRight16';
import LightBulbColor32 from '../../icons/LightBulbColor32';

const StyledBox = styled(Box)`
  border: 1px solid ${(p) => p.theme.colors.gray.light};
`;

const StyledButton = styled(Button)`
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    align-self: auto;
  }
`;

const ConvinceMyManager = () => {
  const { t } = useTranslation();
  return (
    <SectionBackground alignItems="center" mb={{ xs: 7, md: 10 }}>
      <Container>
        <StyledBox
          flexDirection={{ xs: 'column-reverse', md: 'row' }}
          p={{ xs: 4, md: 7 }}
          borderRadius="lg">
          <Box width={{ xs: '100%', md: '50%' }}>
            <Box flexDirection="row" alignItems="center" mb={4}>
              <LightBulbColor32 />
              <Heading level={5} ml={2} style={{ marginBottom: '0px' }}>
                {t('convincemymanager.title')}
              </Heading>
            </Box>
            <Text mb={4}>{t('convincemymanager.text')}</Text>
            <StyledButton
              target="_blank"
              href="https://feedback.roulezjeunesse.com/reference-salaries"
              after={<AngleRight16 />}
              animation>
              {t('convincemymanager.cta')}
            </StyledButton>
          </Box>
          <Box
            width={{ xs: '100%', md: '50%' }}
            justifyContent="center"
            ml={{ xs: 0, md: 6 }}
            mb={{ xs: 5, md: 0 }}>
            <img
              src="/static/img/photos/business/image-section-1_home-entreprise.png"
              alt={t('roulezjeunessecompany')}
            />
          </Box>
        </StyledBox>
      </Container>
    </SectionBackground>
  );
};

export default ConvinceMyManager;
