import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import Slider from '../shared/atoms/Slider';
import Box from '../shared/atoms/Box';
import Row from '../shared/atoms/Row';
import Text from '../shared/atoms/Text';
import Heading from '../shared/atoms/Heading';
import SectionBackground from '../shared/molecules/SectionBackground';
import ToolkitFix from '../shared/ToolkitFix';
import ToolkitMove from '../shared/ToolkitMove';
// Icons
import Infos16 from '../icons/Infos16';

const Line = styled.div`
  width: 14px;
  border: 1px solid #c6d2cf;
  transform: rotate(90deg);
  margin-bottom: 4px;
`;

const StyledText = styled(Text)`
  padding: 4px 8px;
  border: 1px dashed black;
  border-radius: 8px;
  margin-left: 8px;
`;

const StyledToolFix = styled(ToolkitFix)`
  transform: rotate(14deg);
  margin-top: -73px;
  margin-left: 147px;
`;

const HowMuchCanWeEarn = () => {
  const [value, setValue] = useState(5);
  const [monthlyPay, setMonthlyPay] = useState(0);
  const { t } = useTranslation();
  const max = 45;

  useEffect(() => {
    if (value) {
      const calcul = Math.round(value * 24 * 4.3);
      setMonthlyPay(calcul);
    }
  }, [value]);

  return (
    <SectionBackground bg="gray.lightest" px={{ xs: 5, md: 10 }} pt={7}>
      <Row md gap={72} mb={6}>
        <Box>
          <Heading level={4} mb={2}>
            {t('howmuchcanweearn.heading')} *
          </Heading>
          <Row gap={4}>
            <Infos16 color="gray.darker" />
            <Text variant="small">{t('howmuchcanweearn.texts.1')}</Text>
          </Row>
          <ToolkitMove name="1" color="yellow.main" size="200" />
          <StyledToolFix name="spring" color="blue.lighter" size="64" />
        </Box>

        <Box pt="9px">
          <Box style={{ position: 'relative' }} width="100%" mb={7}>
            <Slider
              min={0}
              max={max}
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
            <Box flexDirection="row" mt="24px" style={{ position: 'relative' }}>
              <Box
                style={{
                  position: 'absolute',
                  left: `calc(${(0 / max) * 100}% - 7px)`,
                }}>
                <Line />
                <Text fontWeight="500" textAlign="center">
                  0
                </Text>
              </Box>
              <Box
                alignItems="center"
                style={{
                  position: 'absolute',
                  left: `calc(${(9 / max) * 100}% - 7px)`,
                }}>
                <Line />
                <Text fontWeight="500" textAlign="center">
                  9
                </Text>
              </Box>
              <Box
                alignItems="center"
                style={{
                  position: 'absolute',
                  left: `calc(${(18 / max) * 100}% - 7px)`,
                }}>
                <Line />
                <Text fontWeight="500" textAlign="center">
                  18
                </Text>
              </Box>
              <Box
                alignItems="center"
                style={{
                  position: 'absolute',
                  left: `calc(${(27 / max) * 100}% - 7px)`,
                }}>
                <Line />
                <Text fontWeight="500" textAlign="center">
                  27
                </Text>
              </Box>
              <Box
                alignItems="center"
                style={{
                  position: 'absolute',
                  left: `calc(${(36 / max) * 100}% - 7px)`,
                }}>
                <Line />
                <Text fontWeight="500" textAlign="center">
                  36
                </Text>
              </Box>
              <Box
                alignItems="center"
                style={{
                  position: 'absolute',
                  left: `calc(${(45 / max) * 100}% - 7px)`,
                }}>
                <Line />
                <Text fontWeight="500" textAlign="center">
                  45
                </Text>
              </Box>
            </Box>
          </Box>
          <Box flexDirection="row" alignItems="center" mb={2}>
            <Text fontWeight="500">{t('howmuchcanweearn.texts.2')}</Text>
            <StyledText fontWeight="600">{monthlyPay}€</StyledText>
          </Box>
          <Text variant="caption" color="gray.darker">
            {t('howmuchcanweearn.texts.3')}
          </Text>
        </Box>
      </Row>
    </SectionBackground>
  );
};

export default HowMuchCanWeEarn;
