import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import ConnexionModule from '../components/ConnexionModule';
import Row from '../components/shared/atoms/Row';
import Image from '../components/shared/atoms/Image';
import Container from '../components/shared/atoms/Container';

const StyledImage = styled(Image)`
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    display: none;
  }
`;
const Login = () => {
  const { t } = useTranslation();
  const [redir, setRedir] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('redir') && !!~urlParams.get('redir').indexOf('/'))
        setRedir(urlParams.get('redir'));
      else if (urlParams.has('redir'))
        setRedir(`/courses/${urlParams.get('redir')}`);
    }
  }, []);

  return (
    <div>
      <Helmet
        title={t('login.meta.title')}
        meta={[
          {
            name: 'description',
            content: t(`login.meta.description`),
          },
        ]}
      />
      <Container mt={5} mb={10}>
        <Row md gap={24} style={{ alignItems: 'stretch' }}>
          <ConnexionModule redir={redir || 'account'} loginFirst />
          <StyledImage
            borderRadius="md"
            src="/static/img/photos/login-side.jpg"
            alt={t('connect')}
          />
        </Row>
      </Container>
    </div>
  );
};

export default Login;
