import * as Yup from 'yup';
import i18n from '../../i18n';

export const loginValidationSchema = () => {
  return Yup.object().shape({
    password: Yup.string().min(8, i18n.t('password.istooshort')),
    email: Yup.string()
      .required(i18n.t('email.required'))
      .email(i18n.t('email.invalid')),
  });
};

export const resetValidationSchema = Yup.object().shape({
  password: Yup.string().min(8, i18n.t('password.istooshort')),
  email: Yup.string().email(i18n.t('email.invalid')),
});

export const updatePasswordValidationSchema = Yup.object().shape({
  password: Yup.string().min(8, i18n.t('password.eightcharacterminimum')),
  password_confirm: Yup.string()
    .min(8, i18n.t('password.eightcharacterminimum'))
    .oneOf([Yup.ref('password'), null], i18n.t('password.match')),
});
