import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router';

import BrakesModal from '../../modals/BrakesModal';
import Heading from '../shared/atoms/Heading';
import Box from '../shared/atoms/Box';
import DiagButton from './DiagButton';
import Vignette from './Vignette';
import Text from '../shared/atoms/Text';
import HelpCard from '../shared/molecules/HelpCard';
import Link from '../shared/atoms/Link';
import SvgAngleRight16 from '../icons/AngleRight16';
import useStrapi from '../../hooks/useStrapi';
import sizeTheVignettes from '../../utils/diag';
import { pushInProgressQ } from '../../actions/diag';
import strapiQuestion from '../../hooks/StrapiQuestion';
import useResaShop from '../../hooks/useResaShop';
import useFirstQuestionID from '../../hooks/useFirstQuestionID';

export default function QuestionContent({
  questionid,
  withHelper,
  onNavigate,
  ...props
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [questionLoading, question] = useStrapi(`questions/${questionid}`);
  const { unsetFirstQID } = useFirstQuestionID();
  const shop = useResaShop();
  const StrapiQuestion = strapiQuestion(question);
  const [showBrakesModal, setShowBrakesModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const questionError = !!question.error_message;
  useEffect(() => {
    setLoading(questionLoading);
  }, [questionLoading]);

  const brakeQid = StrapiQuestion.id == 123;
  const tireQid = StrapiQuestion.id == 119;

  useEffect(() => {
    if (shop && question) {
      if (shop?.id !== question?.shop_id) {
        unsetFirstQID();
        history.push(`/reparation/symptoms`);
      }
    }
    if (question) {
      dispatch(pushInProgressQ(StrapiQuestion));
    }
  }, [question, shop]);

  if (questionError) {
    return <Redirect to="/reparation" />;
  }
  return (
    <div
      key={StrapiQuestion.id}
      style={props.style ? props.style : {}}
      {...props}>
      <BrakesModal
        open={showBrakesModal}
        close={() => setShowBrakesModal(false)}
      />
      <Heading level={3} as="h1" mb={{ xs: 4, lg: 5 }}>
        {StrapiQuestion.getContent()}
      </Heading>
      {withHelper && <div style={{ marginBottom: 25 }}>{withHelper}</div>}
      <Box
        flexDirection="row"
        style={{ flexWrap: 'wrap' }}
        mr={{ xs: 0, lg: '-1rem' }}>
        {StrapiQuestion.sortedAnswers().map((answerSorted, index) => {
          const { content, image, id, getPictureUrl } = answerSorted;
          return (
            <Box
              key={id}
              width={{
                xs: brakeQid ? 1 / 2 : 1,
                lg: brakeQid ? 1 / 3 : 1 / 2,
              }}
              pr={{ xs: brakeQid && index % 2 === 0 ? 2 : 0, lg: 4 }}
              pb={{ xs: 2, lg: 4 }}>
              <DiagButton
                index={index}
                onClick={() =>
                  setLoading(id) ||
                  onNavigate({
                    question: { ...StrapiQuestion, answer: answerSorted },
                  })
                }
                $disabled={!!loading && loading !== id}
                $loading={!!loading && loading === id}>
                {!!image && <Vignette src={image} onLoad={sizeTheVignettes} />}
                <Text textAlign={image ? 'center' : 'left'} mt={image ? 4 : 0}>
                  {content}
                </Text>
              </DiagButton>
            </Box>
          );
        })}
      </Box>
      {brakeQid && (
        <HelpCard mt={6} title={t('questioncontent.titles.1')}>
          <Text>{t('questioncontent.texts.1')}</Text>
          <Link
            as="div"
            color="blue.electric"
            onClick={() => setShowBrakesModal(true)}
            after={<SvgAngleRight16 />}>
            {t('questioncontent.texts.2')}
          </Link>
        </HelpCard>
      )}
      {tireQid && (
        <HelpCard mt={6} title={t('questioncontent.titles.2')}>
          <Text>{t('questioncontent.texts.3')}</Text>
        </HelpCard>
      )}
    </div>
  );
}
