import React from 'react';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { colors } from '../../../styles/constants';

const _sizes = {
  small: '32px',
  medium: '48px',
  large: '64px',
};

const StyledAvatar = styled.img`
  border-radius: 50%;
  width: ${(p) => p.size};
  height: ${(p) => p.size};
  ${space};
  ${(p) =>
    p.bordered &&
    css`
      border: solid 3px ${colors.blue};
    `}
`;

export default function Avatar({
  src,
  alt,
  size = 'medium',
  bordered = false,
  ...props
}) {
  return (
    <StyledAvatar
      size={_sizes[size]}
      bordered={bordered}
      alt={alt}
      src={src}
      {...props}
    />
  );
}
