import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 6.539V6a3 3 0 016 0v.539a8.587 8.587 0 015.583 8.044v1.392a2 2 0 00.419 1.224l2.294 2.965c1.526 1.972.12 4.836-2.372 4.836H20.9a5.002 5.002 0 01-9.8 0H7.076c-2.493 0-3.898-2.864-2.372-4.836l2.294-2.965a2 2 0 00.419-1.224v-1.392A8.587 8.587 0 0113 6.54zm2-.481a8.676 8.676 0 012 0V6a1 1 0 10-2 0v.058zM16 8a6.583 6.583 0 00-6.583 6.583v1.392a4 4 0 01-.837 2.448l-2.294 2.965A1 1 0 007.076 23h17.848a1 1 0 00.79-1.612l-2.294-2.965a4 4 0 01-.837-2.448v-1.392A6.583 6.583 0 0016 8zm0 19a3.001 3.001 0 01-2.83-2h5.66A3.001 3.001 0 0116 27z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = true;
const SvgBell32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgBell32;
