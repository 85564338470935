import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.343 4.03a.17.17 0 00-.1-.03.18.18 0 00-.102.04l-.01.007c-1.522 1.13-2.39 3.314-2.063 5.824.139 1.071.974 2.34 1.941 3.447.93 1.065 1.827 1.806 1.835 1.813a1.002 1.002 0 01.364.796V21a1 1 0 01-2 0v-4.642a20.434 20.434 0 01-1.705-1.724c-.994-1.138-2.197-2.797-2.419-4.505-.397-3.062.627-6.03 2.85-7.684a2.17 2.17 0 012.495-.095c.682.438 1.098 1.219 1.098 2.067v3.424l1.488.59c.194-.071.446-.165.706-.263.265-.1.532-.204.752-.294V4.418c0-.849.416-1.63 1.1-2.068a2.17 2.17 0 012.493.095c2.223 1.653 3.247 4.622 2.85 7.684-.223 1.71-1.442 3.382-2.441 4.523a20.635 20.635 0 01-1.684 1.705V21a1 1 0 11-2 0v-5.061a1.003 1.003 0 01.356-.801l.008-.007.005-.004.026-.023a16.61 16.61 0 00.517-.464c.342-.32.8-.773 1.267-1.306.977-1.114 1.823-2.394 1.962-3.463.326-2.51-.54-4.694-2.063-5.824l-.01-.007a.18.18 0 00-.102-.04.17.17 0 00-.1.03l-.005.003c-.082.053-.18.183-.18.385V8.44l-.006.06a1.266 1.266 0 01-.435.817l-.05.043-.055.036c-.172.112-.459.233-.666.318-.25.103-.548.219-.83.325a71.865 71.865 0 01-1 .37l-.068.024-.024.01-.356.127L9.16 9.45l-.085-.055a1.216 1.216 0 01-.547-1.016V4.417c0-.2-.097-.332-.18-.384l-.004-.003zm7.437 11.735a.348.348 0 010 .002v-.002zm-7.555-.042l.002-.009.003-.016-.002.008-.003.017z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgWrenchkey24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgWrenchkey24;
