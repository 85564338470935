const daysWithOptiv = ({ setAverageOptiv, setDays, basicDays }) => {
  const newDays = Object.entries(basicDays).map(([key, value]) => {
    const { openv, optiv, slots } = value;
    const optivQuarterSplitInHour = [];
    const optivDay = optiv.slice(36, 80);
    const openvDay = openv.slice(36, 80);
    openvDay.forEach((openv, index) => {
      if (openv === 0 && optivDay[index] > 0) {
        optivDay[index] = 0;
      }
    });
    while (optivDay.length) {
      optivQuarterSplitInHour.push(optivDay.splice(0, 4));
    }
    const optivSum = optivQuarterSplitInHour.map((hour) => {
      const sum = hour.reduce((a, b) => a + b, 0);
      const hourWithNoZero = hour.filter((hour) => hour > 0);
      const avg = sum / hourWithNoZero.length || 0;
      if (avg < 0) return 0;
      return avg;
    });
    const [minValue, maxValue] = optivSum.reduce(
      (accumulator, currentValue) => {
        if (currentValue === 0) {
          return accumulator;
        }
        return [
          Math.min(currentValue, accumulator[0]),
          Math.max(currentValue, accumulator[1]),
        ];
      },
      [Number.MAX_VALUE, Number.MIN_VALUE],
    );
    const newSlots = slots.map((slot, index) => {
      slot.averageAvailabilities = optivSum[index];
      return slot;
    });

    return {
      ...value,
      newSlots,
      averageDay: [minValue, maxValue],
      date: key,
    };
  });
  const [minValue, maxValue] = newDays.reduce(
    (accumulator, currentValue) => {
      return [
        Math.min(currentValue.averageDay[0], accumulator[0]),
        Math.max(currentValue.averageDay[1], accumulator[1]),
      ];
    },
    [Number.MAX_VALUE, Number.MIN_VALUE],
  );
  setAverageOptiv(maxValue - ((maxValue - minValue) * 0.5) / 100);
  setDays(newDays);
};

export default daysWithOptiv;
