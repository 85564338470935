import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../atoms/Box';
import Text from '../atoms/Text';

const EmptyState = ({
  picto = '/static/img/pictos/no-ask.png',
  title,
  subTitle = null,
  cta = null,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Box alignItems="center" {...props}>
      <img style={{ maxWidth: 96 }} src={picto} />
      <Text
        color="black"
        fontSize="lg"
        fontWeight={1}
        mt={4}
        textAlign="center">
        {title || t('emptystate.text')}
      </Text>
      {subTitle && <Text textAlign="center">{subTitle}</Text>}
      {cta}
    </Box>
  );
};

export default EmptyState;
