import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translation/en.json';
import fr from './translation/fr.json';
import { getDomain } from './utils/utils';

i18n.use(initReactI18next).init({
  resources: { en: { translation: en }, fr: { translation: fr } },
  lng: getDomain().domain,
  compatibilityJSON: 'v3',
  keySeparator: false,
  nsSeparator: false,
  interpolation: {
    escapeValue: false,
    prefix: '{{',
    suffix: '}}',
  },
});

export default i18n;
