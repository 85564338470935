import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import LazyLoad from 'react-lazyload';
import Heading from '../shared/atoms/Heading';
import Box from '../shared/atoms/Box';
import Text from '../shared/atoms/Text';
import Badge from '../shared/atoms/Badge';
import Button from '../shared/atoms/Button';
import Container from '../shared/atoms/Container';
import AngleRight16 from '../icons/AngleRight16';

import {
  getContentfulData,
  resolveSingleEntry,
} from '../../sspr/contenful-service';

const StyledImg = styled.img`
  object-fit: cover;
  object-position: right;
  height: auto;
  border-radius: inherit;
  border-top-right-radius: 180px;
  border-bottom-right-radius: 180px;
  ${(p) => p.theme.mediaQueries.desktop} {
    height: 100%;
    width: 100%;
    max-height: auto;
  }
`;

export default function PromoLeftPill() {
  const { t } = useTranslation();
  const [ad, setAd] = useState();

  useEffect(() => {
    (async () => {
      const data = await getContentfulData('encarts&fields.type=home');
      const adData = resolveSingleEntry(data);
      setAd(adData);
    })();
  }, []);

  if (!ad) {
    return <div />;
  }

  return (
    <Container mt={{ xs: '-10px' }} mb={10} style={{ position: 'relative' }}>
      <Box
        borderRadius={{ xs: 'md', lg: '56px' }}
        bg="blue.lighter"
        style={{ position: 'relative', overflow: 'hidden' }}>
        <Box flexDirection={{ xs: 'column-reverse', lg: 'row' }}>
          <Box
            pr={{ xs: 5, lg: 3 }}
            width={{ xs: 1, lg: 1 / 2 }}
            style={{ position: 'relative' }}>
            <LazyLoad>
              <StyledImg
                src="/static/img/photos/promomars.png"
                alt={t('cycloplus.texts.1')}
              />
            </LazyLoad>
          </Box>
          <Box pl={{ xs: 5, lg: 3 }} width={{ xs: 1, lg: 1 / 2 }}>
            <Box py={5} pr={5}>
              <Box flexDirection="column" alignItems="flex-start">
                <Badge size="regular" color="primary" mb={1}>
                  {t('promotion')}
                </Badge>
                <Heading level={4}>{ad.fields.title}</Heading>
              </Box>
              <Text fontSize="lg" mb={4}>
                {ad.fields.content}
              </Text>
              {!!ad.fields.link && (
                <Button
                  animation
                  variant="filledWhite"
                  to={ad.fields.link}
                  after={<AngleRight16 />}>
                  Réserver
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
