import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import * as api from '../utils/api';
// Components
import Container from '../components/shared/atoms/Container';
import Button from '../components/shared/atoms/Button';
import Heading from '../components/shared/atoms/Heading';
import CardLogos from '../components/shared/atoms/CardLogos';
import Box from '../components/shared/atoms/Box';
import Row from '../components/shared/atoms/Row';
import Text from '../components/shared/atoms/Text';
import CourseBill from '../components/PayByLink/CourseBill';
import PayByLinkSkeleton from '../components/PayByLink/PayByLinkSkeleton';
import PayFormNew from '../components/PayFormNew';
import Alert from '../components/shared/molecules/Alert';
// Icons
import Message16 from '../components/icons/Message16';
import Success56Color from '../components/icons/Success56Color';
import AngleLeft16 from '../components/icons/AngleLeft16';

const StyledButton = styled(Button)`
  min-width: 270px;
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    min-width: 100%;
  }
`;

const Title = styled(Heading)`
  font-size: 80px;
  font-weight: 400;
  margin: 0;
`;

const StyledImg = styled.img`
  width: 415px;
  margin-left: 60px;
`;

const PayByLink = ({ isAtelier = false }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [course, setCourse] = useState(false);
  const { course_id, client_id, token } = useParams();

  const [stripePayment, setStripePayment] = useState({
    cvc: false,
    number: false,
    expiry: false,
    payment_method: '',
    message: '',
    state: null,
    complete: false,
  });

  const fetch = async () => {
    const decodedToken = decodeURIComponent(token);

    const course = await api.get(
      isAtelier
        ? `/ateliers/${course_id}/participants/${client_id}`
        : `/courses/${course_id}`,
      {},
      { id: client_id, token: decodedToken },
    );
    if (course.id) {
      setCourse(course);
    } else setError('error_link');
    setLoading(stripePayment.complete);
  };

  const intentSend = async () => {
    setStripePayment({ ...stripePayment, state: 'send' });
    if (
      (course.status === 'TERMINE' && course.paiement === 'ONSITE') ||
      course.paiement === 'ATELIER'
    ) {
      fetch();
    } else {
      // save
      const pay = await api.post('/pay', {
        course_id: course.id,
        tip: course.tip,
        slackbb: 'true',
      });
      if (pay && pay.ok == 1) {
        fetch();
      } else if (pay.error_message) {
        setError('error_cb');
      }
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  if (!course && !loading) {
    return (
      <Container fluid>
        <Box
          bg="gray.lightest"
          alignItems="center"
          borderRadius="xl"
          flexDirection="row"
          p={{ l: '80px', xl: '175px' }}
          mb={9}>
          <Box maxWidth="453px">
            <Title color="black">{t('error')}</Title>
            <Heading level={5} color="black" my={4}>
              {t('Votre lien de paiement semble erroné...')}
            </Heading>
            <Text mb={5}>{t('paybylink.texts.4')}</Text>
            <Button
              href={`mailto:bonjour@roulezjeunesse.com?subject=Course #${course.id}`}
              before={<Message16 />}>
              {t('paybylink.message.cta')}
            </Button>
          </Box>
          <StyledImg src="/static/img/pictos/404-error-bike.jpg" style={{ borderRadius: '50px' }} />
        </Box>
      </Container>
    );
  }

  return (
    <Container fluid>
      <Container mt={8}>
        {loading && !course ? (
          <PayByLinkSkeleton />
        ) : (
          <Box flexDirection={{ xs: 'column-reverse', lg: 'row' }}>
            <Box
              mb={10}
              width={{ xs: '100%', lg: 3 / 5 }}
              pr={{ xs: 0, lg: 5 }}>
              {stripePayment.state === 'succes' ||
                !!course.stripe_payment_id ? (
                <>
                  <Box flexDirection="row" mb={5} alignItems="center">
                    <Success56Color />
                    <Heading level={2} ml={4} style={{ marginBottom: 0 }}>
                      {t('paybylink.headings.1')}
                    </Heading>
                  </Box>
                  <Text mb={5}>{t('paybylink.texts.1')}</Text>
                  <Row>
                    <Button
                      variant="neutral"
                      fullWidth
                      before={<AngleLeft16 />}
                      to="/"
                      alignSelf="flex-end"
                      animation>
                      {t('paybylink.back.cta')}
                    </Button>
                    <Button
                      fullWidth
                      alignSelf="flex-end"
                      onClick={() =>
                        window.open(
                          `https://feedback.roulezjeunesse.com/satisfaction-post-intervention?course_id=${course.id}&fixer=${course.fixer.prenom}&fixer_id=${course.fixer.id}`,
                          '_blank',
                        )
                      }
                      animation>
                      {t('paybylink.aponion.cta')}
                    </Button>
                  </Row>
                </>
              ) : (
                <>
                  <Heading level={2}>
                    {t('paybylink.headings.2', {
                      label: course.client.prenom,
                    })}
                  </Heading>

                  {course.fin_date || course.paiement === 'ATELIER' ? (
                    <Text mb={6}>{t('paybylink.texts.2')}</Text>
                  ) : (
                    <Text mb={6}>{t('paybylink.texts.2')}</Text>
                  )}
                  {error === 'error_cb' && (
                    <Alert status="danger" borderRadius="xs" mb={4}>
                      {t('paybylink.alert')}
                    </Alert>
                  )}
                  <PayFormNew
                    client={course.client}
                    course={course}
                    setLoading={setLoading}
                    doPay={
                      (course.status === 'TERMINE' &&
                        course.paiement === 'ONSITE') ||
                      course.paiement === 'ATELIER'
                    }
                    amount={course.prix}
                    stripePayment={stripePayment}
                    setStripePayment={setStripePayment}
                  />
                  <CardLogos mt={4} mb={4} />
                  <StyledButton
                    alignSelf="flex-end"
                    onClick={intentSend}
                    data-testid="button-card-element-submit"
                    disabled={loading}>
                    {(course.status === 'TERMINE' &&
                      course.paiement === 'ONSITE') ||
                      course.paiement === 'ATELIER'
                      ? t(`paybylink.validation.cta`, {
                        label: course.prix,
                      })
                      : t('confirm.cta')}
                  </StyledButton>
                </>
              )}
            </Box>
            <Box width={{ xs: '100%', lg: 2 / 5 }} pl={{ xs: 0, lg: 5 }}>
              <CourseBill course={course} />
            </Box>
          </Box>
        )}
      </Container>
    </Container>
  );
};

export default PayByLink;
