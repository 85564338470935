import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Container from '../../components/shared/atoms/Container';
import Box from '../../components/shared/atoms/Box';
import Heading from '../../components/shared/atoms/Heading';
import Text from '../../components/shared/atoms/Text';
import Image from '../../components/shared/atoms/Image';
import Separator from '../../components/Separator';
import CityCard from '../../components/annuaire/CityCard';
import Row from '../../components/shared/atoms/Row';

// Styled component for letter links
const LetterLink = styled(Link)`
  color: black;
  margin: 0 8px;
  text-decoration: none;
  &:after {
    content: '-';
    margin-left: 8px;
  }
  &:last-child:after {
    content: '';
  }
`;

const AnnuaireHome = () => {
  const { t } = useTranslation();
  return (
    <Container fluid>
      <Container mb={11}>
        <Heading mt={4}>{t('homedirectory.heading')}</Heading>
        <Text variant="large" mt={-4} mb={5}>
          {t('homedirectory.text')}
        </Text>
        <Image
          src="/static/img/annuaire/hero-annuaire.jpg"
          mb={{ xs: 7, md: 10 }}
          style={{ borderRadius: 16, minHeight: 150 }}
        />
        <Box flexDirection="row">
          <Heading level={4} mr={2}>
            {t('homedirectory.title')}
          </Heading>
          <Box flex={1}>
            <Separator axe="horizontal" />
          </Box>
        </Box>
        <Row gap={24} sm mb={4}>
          <CityCard
            city="montpellier"
            label="Montpellier"
            url="/static/img/annuaire/montpellier.jpg"
            nombreAtelier={10}
          />
          <CityCard
            city="reims"
            label="Reims"
            url="/static/img/annuaire/reims.jpg"
            nombreAtelier={4}
          />
          <CityCard
            city="toulon"
            label="Toulon"
            url="/static/img/annuaire/toulon.jpg"
            nombreAtelier={6}
          />
          <CityCard
            city="saint-etienne"
            label="Saint-Etienne"
            url="/static/img/annuaire/saint-etienne.jpg"
            nombreAtelier={7}
          />
        </Row>
        <Row gap={24} sm mb={4}>
          <CityCard
            city="le-havre"
            label="Le Havre"
            url="/static/img/annuaire/le-havre.jpg"
            nombreAtelier={6}
          />
          <CityCard
            city="grenoble"
            label="Grenoble"
            url="/static/img/annuaire/grenoble.jpg"
            nombreAtelier={19}
          />
          <CityCard
            city="dijon"
            label="Dijon"
            url="/static/img/annuaire/dijon.jpg"
            nombreAtelier={5}
          />
          <CityCard
            city="angers"
            label="Angers"
            url="/static/img/annuaire/angers.jpg"
            nombreAtelier={9}
          />
        </Row>
        <Row gap={24} sm>
          <CityCard
            city="nimes"
            label="Nimes"
            url="/static/img/annuaire/nimes.jpg"
            nombreAtelier={11}
          />
          <CityCard
            city="clermont-ferrand"
            label="Clermont-Ferrand"
            url="/static/img/annuaire/clermont-ferrand.jpg"
            nombreAtelier={6}
          />
          <CityCard
            city="aix-en-provence"
            label="Aix-en-Provence"
            url="/static/img/annuaire/aix-en-provence.jpg"
            nombreAtelier={14}
          />
          <CityCard
            city="le-mans"
            label="Le Mans"
            url="/static/img/annuaire/le-mans.jpg"
            nombreAtelier={11}
          />
        </Row>
        <Box my={4} flexDirection="row" justifyContent="center" flexWrap="wrap">
          {Array.from(Array(26)).map((_, index) => {
            const letter = String.fromCharCode(65 + index); // Generates letters A-Z
            return (
              <LetterLink
                key={letter}
                to={`/annuaire/atelier-velo-trottinette/letter/${letter.toLowerCase()}`}>
                {letter}
              </LetterLink>
            );
          })}
        </Box>
      </Container>
    </Container>
  );
};

export default AnnuaireHome;
