import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';

import Text from '../atoms/Text.jsx';
import Box from '../atoms/Box.jsx';
import Heading from '../atoms/Heading.jsx';
import Container from '../atoms/Container.jsx';
import SvgStarfill24 from '../../icons/StarFill24.jsx';
import SvgStarHalf24 from '../../icons/StarHalf24';
import SectionBackground from '../molecules/SectionBackground.jsx';

const AppPictoContainer = styled(Box)`
  position: relative;
`;

const AppPicto = styled.img`
  position: absolute;
  bottom: 0;
  max-width: 600px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    max-width: 400px;
  }
`;

const StyledDiv = styled.div`
  width: 60%;
`;

const Rate = ({ ...props }) => (
  <Box flexDirection="row" alignItems="baseline" {...props}>
    <Heading level={2} color="white" m={0} mb={0} mr={2}>
      4.6
    </Heading>
    <Box flexDirection="row" style={{ position: 'relative', top: '3px' }}>
      <SvgStarfill24 color="yellow.main" />
      <SvgStarfill24 color="yellow.main" />
      <SvgStarfill24 color="yellow.main" />
      <SvgStarfill24 color="yellow.main" />
      <SvgStarHalf24 color="yellow.main" />
    </Box>
  </Box>
);

export default function CTANativeApp({ ...rest }) {
  const { t } = useTranslation();
  return (
    <SectionBackground bg="violet.main" overflow="hidden" {...rest}>
      <Container>
        <Box flexDirection={{ xs: 'column', lg: 'row' }}>
          <Box
            width={{ xs: 1, lg: 1 / 2 }}
            py={{ xs: 7, lg: 10 }}
            pr={{ xs: 0, lg: 5 }}>
            <Heading level={2} color="white" mb={4}>
              {t('ctanativeapp.heading')}
            </Heading>
            <Text color="white" variant="large" mb={5}>
              {t('ctanativeapp.texts.1')}
            </Text>
            <Rate mb={2} />
          </Box>
          <AppPictoContainer
            width={{ xs: 1, lg: 1 / 2 }}
            pl={{ xs: 0, lg: 5 }}
            height={{ xs: 250, md: 360, lg: 'auto' }}
            alignItems="center">
            <LazyLoad>
              <AppPicto
                src="/static/img/pictos/native-app.png"
                alt={t('ctanativeapp.texts.2')}
              />
            </LazyLoad>
          </AppPictoContainer>
        </Box>
      </Container>
    </SectionBackground>
  );
}
