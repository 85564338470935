import React from 'react';
import styled from 'styled-components';
import {
  compose,
  space,
  layout,
  color,
  flexbox,
  position,
  border,
  shadow,
} from 'styled-system';
import propTypes from '@styled-system/prop-types';

export const StyledBox = styled('div').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['height', 'width', 'display'].includes(prop) && defaultValidatorFn(prop),
})(compose(space, layout, color, flexbox, position, border, shadow));

const Box = ({ children, ...props }) => (
  <StyledBox
    display="flex"
    flexDirection="column"
    alignItems="stretch"
    {...props}>
    {children}
  </StyledBox>
);

Box.propTypes = {
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.color,
  ...propTypes.flexbox,
  ...propTypes.position,
};

export default Box;
