import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 116 116"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g opacity={0.8} filter="url(#play-btn_svg__filter0_dd)">
      <circle cx={58} cy={52} r={28} fill="#d5ff4f" />
    </g>
    <path
      d="M48.926 46.565c0-2.798 0-4.197.586-4.991a3 3 0 012.152-1.207c.984-.086 2.177.643 4.565 2.102l8.894 5.436c2.216 1.354 3.324 2.03 3.702 2.902a3 3 0 010 2.386c-.378.872-1.486 1.549-3.702 2.903l-8.894 5.436c-2.388 1.459-3.581 2.188-4.565 2.102a3 3 0 01-2.152-1.207c-.586-.794-.586-2.193-.586-4.991V46.565z"
      fill="#fff"
    />
    <defs>
      <filter
        id="play-btn_svg__filter0_dd"
        x={0}
        y={0}
        width={116}
        height={116}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={6} />
        <feGaussianBlur stdDeviation={15} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={5} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgPlayBtn = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPlayBtn;
