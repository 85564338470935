import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M11 5.776c-.185.208-.405.472-.69.814L7.768 9.64a1 1 0 11-1.536-1.28L8.8 5.277c.399-.479.739-.887 1.044-1.194.318-.321.673-.611 1.125-.777a3 3 0 012.06 0c.452.166.807.456 1.125.777.305.307.645.715 1.044 1.194l2.57 3.083a1 1 0 11-1.537 1.28L13.69 6.59a37.824 37.824 0 00-.69-.814V14a1 1 0 11-2 0V5.776z"
      fill="#000"
    />
    <path
      d="M5 13a1 1 0 011 1v.4c0 .997 0 1.684.044 2.217.043.522.122.808.228 1.018a2.5 2.5 0 001.093 1.092c.21.107.496.186 1.018.229.533.043 1.22.044 2.217.044h2.8c.997 0 1.684 0 2.217-.044.522-.043.808-.122 1.018-.229a2.5 2.5 0 001.092-1.092c.107-.21.186-.496.229-1.018.043-.533.044-1.22.044-2.217V14a1 1 0 112 0v.442c0 .944 0 1.714-.051 2.338-.053.646-.165 1.224-.44 1.763a4.5 4.5 0 01-1.966 1.966c-.539.275-1.117.387-1.763.44-.624.051-1.393.051-2.338.051h-2.884c-.944 0-1.714 0-2.338-.051-.646-.053-1.224-.165-1.763-.44a4.5 4.5 0 01-1.967-1.966c-.274-.539-.386-1.117-.439-1.763C4 16.156 4 15.386 4 14.442V14a1 1 0 011-1z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgUpload24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgUpload24;
