import React from 'react';
import styled from 'styled-components';
import { border, shadow } from 'styled-system';
import { colors } from '../../styles/constants';

const Card = styled.div`
  box-shadow: 3px 5px 12px 0 rgba(158, 167, 177, 0.1);
  background-color: white;
  border: 1px solid #eaeaea;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 15px;
  ${(props) =>
    !!props.selected &&
    `border-color:${colors.blue};box-shadow:3px 5px 12px 0 rgba(112, 180, 255, 0.1));`}
  ${border}
  ${shadow}
`;

export default Card;
