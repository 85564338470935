import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectFirstQuestionID } from '../reducers/course';
import * as api from '../utils/api';
import i18n from '../i18n';
import useResaShop from './useResaShop';

export default function useFirstQuestionID() {
  const shopFirstQID = useSelector(selectFirstQuestionID);
  const shop = useResaShop();
  const { product_id } = useSelector((s) => s.course);
  const [firstQuestionID, setFirstQuestionID] = useState(null);
  const [loading, setLoading] = useState(true);

  const getCycloFirstQID = async () => {
    const response = await api.get('/autodiags', { product_id });
    setFirstQuestionID(response.id);
    setLoading(false);
  };
  const unsetFirstQID = () => {
    setFirstQuestionID(null);
    setLoading(false);
  };
  useEffect(() => {
    if (shopFirstQID) {
      setFirstQuestionID(shopFirstQID);
      setLoading(false);
    } else {
      getCycloFirstQID();
    }
  }, [product_id, i18n.language, shop]);

  return { firstQuestionID, loading, unsetFirstQID };
}
