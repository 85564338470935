import useWindowSize from './useWindowSize.js';
import { size } from '../styles/constants.jsx';

export default function useDeviceWidth() {
  const { width = 1000 } = useWindowSize();
  const isMobile = width < size.sm.replace('px', '');
  const isTablet =
    width > size.sm.replace('px', '') && width <= size.md.replace('px', '');
  const isDesktop = width > size.md.replace('px', '');
  const isDesktopXL = width > size.lg.replace('px', '');
  return { isMobile, isDesktop, isDesktopXL, isTablet };
}
