import React from 'react';
import styled from 'styled-components';
import { lightenDarken } from '../../../../utils/utils';
import Box from '../../../shared/atoms/Box';
// Icons
import BikeColor56 from '../../../icons/doNotDelete/BikeColor56';
import EBikeColor56 from '../../../icons/doNotDelete/EBikeColor56';
import ScooterColor56 from '../../../icons/doNotDelete/ScooterColor56';
import Edit16 from '../../../icons/Edit16';
import Image from '../../../shared/atoms/Image';

const StyledMainBox = styled(Box)`
  position: relative;
`;

const StyledBikeBox = styled(Box)`
  margin: auto;
  position: absolute;
  bottom: -48px;
  left: 0;
  right: 0;
  width: 100px;
`;
const StyledUpdateBox = styled(Box)`
  position: absolute;
  right: -8px;
  bottom: -8px;
  cursor: pointer;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05), 0px 6px 30px rgba(0, 0, 0, 0.1);
  :hover {
    opacity: 1 !important;
    background-color: ${(p) =>
      lightenDarken(p.hex || p.theme.colors.white, -10)}
`;

const Banner = ({ openModal, bike, ...props }) => {
  const splittedColor = bike.properties.uiColor
    ? bike.properties.uiColor.split('.')
    : 'black';
  const backgroundColor =
    splittedColor[0] === 'black' ? 'gray.main' : `${splittedColor[0]}.pale`;
  return (
    <StyledMainBox height={200} borderRadius="md" {...props}>
      <Image
        src={`/static/img/pictos/carnetSante/Banner-texture-${splittedColor[0]}.jpg`}
      />
      <StyledBikeBox
        bg={backgroundColor}
        borderStyle="solid"
        borderColor="white"
        borderWidth="6px"
        borderRadius="md"
        p={4}>
        {bike.properties.type === 'velo' && (
          <BikeColor56 color={bike.properties.uiColor} />
        )}
        {bike.properties.type === 'vae' && (
          <EBikeColor56 color={bike.properties.uiColor} />
        )}
        {bike.properties.type === 'trottinette' && (
          <ScooterColor56 color={bike.properties.uiColor} />
        )}
        <StyledUpdateBox
          p={2}
          borderRadius="xxs"
          onClick={() => openModal('UPDATE')}>
          <Edit16 style={{ cursor: 'pointer' }} />
        </StyledUpdateBox>
      </StyledBikeBox>
    </StyledMainBox>
  );
};

export default Banner;
