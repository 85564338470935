import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.003 30C7.176 25.262 0 18.355 0 11.826 0 5.296 5.376 0 12.003 0 18.631 0 24 5.297 24 11.826 24 18.355 16.825 25.262 12.003 30z"
      fill="#1D859E"
    />
    <text
      x="50%"
      y="50%"
      dominantBaseline="middle"
      fontSize="14px"
      fontWeight="600"
      textAnchor="middle"
      fill="#fff">
      {props.number ? props.number : null}
    </text>
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgPinshop24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPinshop24;
