import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M5.2 8.649a.9.9 0 100-1.8.9.9 0 000 1.8zM8.9 7.749a.9.9 0 11-1.8 0 .9.9 0 011.8 0zM10.8 8.649a.9.9 0 100-1.8.9.9 0 000 1.8zM6.1 10.549a.9.9 0 11-1.8 0 .9.9 0 011.8 0zM8 11.449a.9.9 0 100-1.8.9.9 0 000 1.8z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 2.75a.75.75 0 00-1.5 0v.766c-.724.029-1.207.105-1.612.311a3 3 0 00-1.311 1.311C2 5.78 2 6.62 2 8.3v.9c0 1.68 0 2.52.327 3.162a3 3 0 001.311 1.311C4.28 14 5.12 14 6.8 14h2.4c1.68 0 2.52 0 3.162-.327a3 3 0 001.311-1.311C14 11.72 14 10.88 14 9.2v-.9c0-1.68 0-2.52-.327-3.162a3 3 0 00-1.311-1.311c-.405-.206-.888-.282-1.612-.31V2.75a.75.75 0 00-1.5 0v.75h-2.5v-.75zm2.5 2.75V5h-2.5v.5a.75.75 0 01-1.5 0v-.482c-.107.004-.206.01-.298.018-.408.033-.559.09-.633.127a1.5 1.5 0 00-.656.656c-.037.074-.094.225-.127.633C3.5 6.877 3.5 7.435 3.5 8.3v.9c0 .865.001 1.423.036 1.848.033.408.09.559.127.633a1.5 1.5 0 00.656.655c.074.038.225.095.633.128.425.035.983.036 1.848.036h2.4c.865 0 1.423-.001 1.848-.036.408-.033.559-.09.633-.128a1.5 1.5 0 00.655-.655c.038-.074.095-.225.128-.633.035-.425.036-.983.036-1.848v-.9c0-.865-.001-1.423-.036-1.848-.033-.408-.09-.559-.128-.633a1.5 1.5 0 00-.655-.656c-.074-.037-.225-.094-.633-.127a8.002 8.002 0 00-.298-.018V5.5a.75.75 0 01-1.5 0z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgCalendar16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgCalendar16;
