import React from 'react';
import { Trans } from 'react-i18next';
import Modal from './Modal';

import Image from '../components/shared/atoms/Image';
import Card from '../components/shared/atoms/Card';

export default function ChatMediaModal({ media, ...rest }) {
  return (
    <Modal {...rest}>
      {media ? (
        media.type.includes('video') ? (
          <Card maxWidth={700} p={0} borderRadius="md">
            <video
              width="auto"
              controls
              autoPlay
              muted
              crossOrigin="anonymous"
              src={media.url}
              type={media.type}>
              <p>
                <Trans
                  i18nKey="chatmediamodal.text"
                  components={{
                    lien: <a href={media.url} />,
                  }}
                />
              </p>
            </video>
          </Card>
        ) : (
          <Image src={media.url} fit="contain" />
        )
      ) : null}
    </Modal>
  );
}
