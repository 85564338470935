import { colors } from '../styles/theme';

export const latLngFromZone = (zone) => ({
  lat: Number(zone.lat),
  lng: Number(zone.lng),
});

export const makeCircle = (maps, map, zone) => {
  const circle = new maps.Circle({
    strokeColor: colors.primary.main,
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: colors.primary.main,
    fillOpacity: 0.05,
    center: latLngFromZone(zone),
    radius: Number(zone.radius),
    map,
  });
  return circle;
};

export const pictoUrl = (picto) =>
  picto === 'zones'
    ? '/static/img/svg/pin-google.svg'
    : picto === 'geoloc'
    ? '/static/img/svg/Circle geoloc 48.svg'
    : '/static/img/svg/Pin geoloc 60.svg';

export const makeMarker = (maps, map, zone, picto = null) => {
  const icon = {
    url: pictoUrl(picto),
    anchor: new maps.Point(
      picto === 'geoloc' ? 52 / 2 : 32 / 2,
      picto === 'geoloc' ? 52 / 2 : 39,
    ),
    strokeWeight: 0,
  };
  const marker = new maps.Marker({
    position: zone.nom ? latLngFromZone(zone) : zone,
    title: zone.nom ? zone.nom : 'Résultat de la recherche',
    icon,
    map,
    zIndex: picto === 'address' ? 100 : 1,
  });
  marker.addListener('click', () => {
    map.setZoom(11);
    map.setCenter(marker.getPosition());
  });
  return marker;
};
