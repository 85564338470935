import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M22.522 3.619a10.289 10.289 0 00-10.778 2.384 10.297 10.297 0 00-1.627 2.098l3.95 3.93 8.455-8.412z"
      fill="#B0BDFF"
    />
    <path
      d="M7.152 24.852a1.354 1.354 0 101.915-1.915 1.354 1.354 0 00-1.915 1.915z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.084 8.068a10.153 10.153 0 00-1.458 6.095c.088 1.121-.188 2.278-.987 3.073l-3.151 3.135a5.037 5.037 0 000 7.149 5.098 5.098 0 007.184 0l3.151-3.135c.8-.796 1.962-1.07 3.09-.982a10.287 10.287 0 008.069-2.973 10.175 10.175 0 002.922-8.624c-.1-.725-.28-1.44-.538-2.133-.538.464-1.105.875-1.695 1.234-4.707 2.862-10.88 2.397-15.11-1.395a12.84 12.84 0 01-.487-.46l-.99-.984zm7.983 13.34c-1.503-.116-3.316.227-4.655 1.559l-3.15 3.135a3.098 3.098 0 01-4.363 0 3.037 3.037 0 010-4.313l3.15-3.135c1.34-1.334 1.689-3.144 1.57-4.648a8.171 8.171 0 01.191-2.507c4.65 3.753 11.062 4.26 16.188 1.523a8.183 8.183 0 01-2.427 5.99 8.287 8.287 0 01-6.504 2.397z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgWrenchkeyColor32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgWrenchkeyColor32;
