import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { colors } from '../../../../styles/theme';
// Components
import Modal from '../../../../modals/Modal';
import Text from '../../../shared/atoms/Text';
import Input from '../../../shared/atoms/Input';
import Button from '../../../shared/atoms/Button';
import Heading from '../../../shared/atoms/Heading';
import Row from '../../../shared/atoms/Row';
import Box from '../../../shared/atoms/Box';
import Link from '../../../shared/atoms/Link';
import ColorCheck from '../../../shared/atoms/ColorCheck';
// Icons
import RecordAddColor56 from '../../../icons/RecordAddColor56';
import RecordEditColor56 from '../../../icons/RecordEditColor56';
import Bike56 from '../../../icons/Bike56';
import EBike56 from '../../../icons/EBike56';
import Scooter56 from '../../../icons/Scooter56';
import ArrowDown16 from '../../../icons/ArrowDown16';

const StyledBoxWhichVehicule = styled(Box)`
  cursor: pointer;
  ${(p) => {
    if (p.error) {
      return css`
        border-color: ${p.theme.colors.primary.darkest};
        background-color: ${p.theme.colors.primary.pale};
      `;
    }
    return css`
      border-color: ${p.thisVehicule === p.vehiculeType
        ? p.theme.colors.blue.electric
        : p.theme.colors.gray.light};
    `;
  }}

      border-width: ${(p) =>
    p.thisVehicule === p.vehiculeType ? '2px' : '1px'};
  :hover {
    box-shadow: inset 0px 0px 0px 1px ${(p) =>
    p.error ? p.theme.colors.primary.darkest : p.theme.colors.blue.electric};
`;

const RandomNames = [
  'Angela',
  'Fil de fer',
  'Edouard',
  'Georginette',
  'Bob',
  'Bernarda',
  'Bernardo',
  'Epona',
  'Pégase',
  'Walkyries',
  'Fantomas',
  'Ginette',
  'Titine',
  'Ernesto',
  'Nimbus 2000',
  'Wheeler',
  'Mon beau vélo',
  'Freddie',
  'Speedy Gonzales',
  'Bip bip',
  'Marcel',
  'McQueen',
  'Bicloune',
  'Rocket Man',
  'Flash',
  'Pile poil',
  'Hermès',
  'Savitar',
];

const CreateUpdateModal = ({
  showModal,
  openModal,
  createBike,
  updateBike,
  bike,
  ...props
}) => {
  const { t } = useTranslation();
  const [vehiculeType, setVehiculeType] = useState('');
  const [errorVehiculeType, setErrorVehiculeType] = useState(false);
  const [bikeName, setBikeName] = useState('');
  const [errorBikeName, setErrorBikeName] = useState(false);
  const [colorChecked, setColorChecked] = useState('primary.main');

  const generateName = () => {
    setBikeName(RandomNames[Math.floor(Math.random() * 28)]);
    setErrorBikeName(false);
  };

  const handleClick = () => {
    if (showModal === 'UPDATE') {
      if (bikeName === '') {
        setErrorBikeName(t('bikesspecs.createupdate.modal.errors.1'));
      } else {
        updateBike(bikeName, colorChecked);
        openModal(false);
        setVehiculeType('');
        setErrorVehiculeType(false);
        setBikeName('');
        setErrorBikeName(false);
        setColorChecked('primary.main');
      }
    }
    if (showModal === 'CREATE') {
      if (bikeName === '' || vehiculeType === '') {
        if (bikeName === '')
          setErrorBikeName(t('bikesspecs.createupdate.modal.errors.1'));
        if (vehiculeType === '') setErrorVehiculeType(true);
      } else {
        createBike(bikeName, vehiculeType, colorChecked);
        openModal(false);
        setVehiculeType('');
        setErrorVehiculeType(false);
        setBikeName('');
        setErrorBikeName(false);
        setColorChecked('primary.main');
      }
    }
  };

  useEffect(() => {
    if (bike && showModal === 'UPDATE') {
      setBikeName(bike.uid);
      setColorChecked(bike.properties.uiColor);
    }
  }, [bike, showModal]);

  return (
    <Modal
      zIndex="1002"
      width="medium"
      hasCloseAction={true}
      open={showModal === 'CREATE' || showModal === 'UPDATE'}
      onRequestClose={() => {
        openModal(false);
        setVehiculeType('');
        setErrorVehiculeType(false);
        setBikeName('');
        setErrorBikeName(false);
        setColorChecked('primary.main');
      }}>
      {showModal === 'UPDATE' && <RecordEditColor56 mb={4} />}
      {showModal === 'CREATE' && <RecordAddColor56 mb={4} />}
      <Heading level={4} mb={5}>
        {showModal === 'UPDATE'
          ? t('bikesspecs.createupdate.modal.headings.1')
          : t('bikesspecs.createupdate.modal.headings.2')}{' '}
        {t('bikesspecs.createupdate.modal.headings.3')}
      </Heading>
      {showModal === 'CREATE' && (
        <>
          <Text fontWeight={2} mb={2}>
            {t('bikesspecs.createupdate.modal.texts.1')}
            <span style={{ color: colors.primary.darkest }}> *</span>
          </Text>
          <Row gap={16}>
            <StyledBoxWhichVehicule
              borderStyle="solid"
              thisVehicule="velo"
              vehiculeType={vehiculeType}
              error={errorVehiculeType}
              borderRadius="xs"
              p={5}
              alignItems="center"
              onClick={() => {
                setVehiculeType('velo');
                setErrorVehiculeType(false);
              }}>
              <Bike56 />
              <Text color="black" fontWeight={1}>
                {t('bike.uppercase')}
              </Text>
            </StyledBoxWhichVehicule>
            <StyledBoxWhichVehicule
              borderStyle="solid"
              thisVehicule="vae"
              vehiculeType={vehiculeType}
              error={errorVehiculeType}
              borderRadius="xs"
              p={5}
              alignItems="center"
              onClick={() => {
                setVehiculeType('vae');
                setErrorVehiculeType(false);
              }}>
              <EBike56 />
              <Text color="black" fontWeight={1}>
                {t('v.a.e')}
              </Text>
            </StyledBoxWhichVehicule>
          </Row>
          {errorVehiculeType && (
            <Text
              pt={1}
              variant="caption"
              fontWeight="bold"
              color="primary.darkest">
              {t('bikesspecs.createupdate.modal.texts.2')}
            </Text>
          )}
        </>
      )}
      <Box flexDirection="row" justifyContent="space-between" mt={6}>
        <Text fontWeight={2} mb={2}>
          {showModal === 'UPDATE'
            ? t('bikesspecs.createupdate.modal.texts.3')
            : t('bikesspecs.createupdate.modal.texts.4')}
          <span style={{ color: colors.primary.darkest }}> *</span>
        </Text>
        <Text fontSize="sm">
          {t('bikesspecs.createupdate.modal.texts.5')} <ArrowDown16 />
        </Text>
      </Box>
      <Input
        error={errorBikeName}
        value={bikeName}
        placeholder={t('bikesspecs.createupdate.modal.placeholders.1')}
        onChange={(e) => {
          setBikeName(e.target.value);
          setErrorBikeName(false);
        }}
        after={
          <Link
            color="blue.electric"
            fontWeight={2}
            onClick={() => generateName()}>
            {t('bikesspecs.createupdate.modal.generatename')}
          </Link>
        }
        mb={6}
      />
      <Text fontWeight={2} mb={2}>
        {t('bikesspecs.createupdate.modal.texts.6')}
      </Text>
      <Box flexDirection="row" mb={6}>
        <ColorCheck
          color="primary"
          mr={5}
          colorChecked={colorChecked}
          setColorChecked={setColorChecked}
        />
        <ColorCheck
          color="blue"
          mr={5}
          colorChecked={colorChecked}
          setColorChecked={setColorChecked}
        />
        <ColorCheck
          color="green"
          mr={5}
          colorChecked={colorChecked}
          setColorChecked={setColorChecked}
        />
        <ColorCheck
          color="violet"
          mr={5}
          colorChecked={colorChecked}
          setColorChecked={setColorChecked}
        />
        <ColorCheck
          color="yellow"
          mr={5}
          colorChecked={colorChecked}
          setColorChecked={setColorChecked}
        />
        <ColorCheck
          color="black"
          mr={5}
          colorChecked={colorChecked}
          setColorChecked={setColorChecked}
        />
      </Box>
      <Box flexDirection="row" justifyContent="flex-end">
        <Button
          mr={2}
          variant="neutral"
          onClick={() => {
            openModal(false);
            setVehiculeType('');
            setErrorVehiculeType(false);
            setBikeName('');
            setErrorBikeName(false);
            setColorChecked('primary.main');
          }}>
          {t('button.cancel')}
        </Button>
        <Button color="blue.electric" onClick={() => handleClick()}>
          {showModal === 'UPDATE'
            ? t('update')
            : t('bikesspecs.createupdate.modal.create')}
        </Button>
      </Box>
    </Modal>
  );
};

export default CreateUpdateModal;
