import React from 'react';
import styled from 'styled-components';
import { layout } from 'styled-system';
import { useTranslation } from 'react-i18next';
// Components shared
import Box from '../../shared/atoms/Box';
import Button from '../../shared/atoms/Button';
import Container from '../../shared/atoms/Container';
import Heading from '../../shared/atoms/Heading';
import SectionBackground from '../../shared/molecules/SectionBackground';
import AngleRight16 from '../../icons/AngleRight16';
import Text from '../../shared/atoms/Text';
import WarrantyOut56 from '../../icons/WarrantyOutColor56';
import Warranty56 from '../../icons/WarrantyColor56';
import Row from '../../shared/atoms/Row';

const StyledSectionBackground = styled(SectionBackground)`
  ${layout}
  border-radius: ${(p) => p.theme.radii.lg};
  position: relative;
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    width: 100%;
  }
`;
const StyledWarrantyOut56 = styled(WarrantyOut56)`
  position: absolute;
  top: 24px;
  left: 16px;
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    top: 4px;
    left: 22px;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    top: 8px;
    left: 22px;
  }
`;
const StyledWarranty56 = styled(Warranty56)`
  position: absolute;
  top: 24px;
  left: 16px;
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    top: 4px;
    left: 22px;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.xs}) {
    top: 8px;
    left: 22px;
  }
`;

const DiscoverOurSolution = () => {
  const { t } = useTranslation();
  return (
    <Container mb={{ xs: 7, md: 10 }}>
      <Heading level={2}>{t('discoveroursolutions.brand.heading')}</Heading>
      {/* Main box */}
      <Row md gap={24}>
        {/* Out of warranty block */}
        <StyledSectionBackground
          bg="gray.lightest"
          mb={{ xs: 4, lg: 0 }}
          mr={{ xs: 0, md: 5 }}>
          <Box
            flexDirection="column"
            ml={{ xs: 4, md: 10 }}
            mr={4}
            py={{ xs: 4, md: 5 }}>
            <StyledWarrantyOut56 />
            <Heading level={5} ml={{ xs: 72, md: 0 }}>
              {t('dicoveroursolutions.brand.titles.1')}
            </Heading>
            <Text mt={{ xs: 5, md: 0 }}>
              {t('dicoveroursolutions.brand.texts.1')}
            </Text>
            <Button
              mt={16}
              animation
              href="https://feedback.roulezjeunesse.com/contact-b2b?type=marques"
              variant="outlinedBlack"
              after={<AngleRight16 />}>
              {t('discover.cta')}
            </Button>
          </Box>
        </StyledSectionBackground>
        {/* Still warrantied block */}
        <StyledSectionBackground bg="gray.lightest" mb={{ xs: 4, lg: 0 }}>
          <Box
            flexDirection="column"
            ml={{ xs: 4, md: 10 }}
            mr={4}
            py={{ xs: 4, md: 5 }}>
            <StyledWarranty56 />
            <Heading level={5} ml={{ xs: 72, md: 0 }}>
              {t('dicoveroursolutions.brand.titles.2')}
            </Heading>
            <Text mt={{ xs: 5, md: 0 }}>
              {t('dicoveroursolutions.brand.texts.2')}
            </Text>
            <Button
              mt={16}
              animation
              href="https://feedback.roulezjeunesse.com/contact-b2b?type=marques"
              variant="outlinedBlack"
              after={<AngleRight16 />}>
              {t('discover.cta')}
            </Button>
          </Box>
        </StyledSectionBackground>
      </Row>
    </Container>
  );
};

export default DiscoverOurSolution;
