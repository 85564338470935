import { loadableReady } from '@loadable/component';
import * as Sentry from '@sentry/browser';
import 'idempotent-babel-polyfill';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import React from 'react';
import { render } from 'react-dom';
import 'url-search-params-polyfill';
import App, { LandingApp } from './App';
import { getLang, isLocal } from './utils/utils';
import './i18n.js';

moment.locale(getLang().moment);

if (typeof window !== 'undefined') window.abUserType = 'A';

if (!isLocal()) {
  Sentry.init({
    dsn:
      'https://73fcbb4b685464137a989e6a458c6053@o4507419326611456.ingest.de.sentry.io/4507420475261008',
    whitelistUrls: [/.*\.roulezjeunesse\.com.*/],
  });
}

window.dataLayer = window.dataLayer || [];

if (typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined') {
  window.dataLayer.push({
    event: 'pageview',
    url: window.location.pathname + window.location.search,
    originalLocation: `${document.location.protocol}//${
      // needed for fake location
      document.location.hostname
    }${document.location.pathname}${document.location.search}`,
  });
  window.dataLayer.push({
    pageview: window.location.pathname + window.location.search,
  });
}

const [subdomain] = window.location.hostname.split('.');
const landingDomains = [
  'darty',
  'fnac',
  'ratp',
  'quizz',
  'communautes',
  'localhosts',
  'inscription',
  'clientscdiscount',
];
const partnerDomains = ['decathlon', 'cdiscount', 'localhosts']; // cobranding

if (landingDomains.includes(subdomain)) {
  const subD = subdomain === 'localhost' ? 'decathlon' : subdomain;
  loadableReady(() => {
    render(
      <LandingApp subdomain={subD} />,
      document.querySelectorAll('._home')[0],
    );
  });
} else {
  let partenaire = partnerDomains.includes(subdomain) ? subdomain : '';
  if (partenaire === 'localhost') {
    partenaire = 'decathlon';
  }

  loadableReady(() => {
    render(
      <App partenaire={partenaire} />,
      document.querySelectorAll('._home')[0],
    );
  });
}
