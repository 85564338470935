import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  createContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { googleGeocode } from '../utils/utils';
import { setAddress, setLoading, setPlaceId } from '../actions/course.jsx';

const NavigatorContext = createContext({});

export function NavigatorContextProvider({ ...props }) {
  const positionInput = useSelector((state) => state.course.positionInput);
  const dispatch = useDispatch();
  const [position, setPosition] = useState(null);
  const [isError, setIsError] = useState(false);
  const [callback, setCallback] = useState(() => () => null);
  const [isRequested, setIsRequested] = useState(false);
  const pInputRef = useRef(positionInput);

  const onPositionRequest = () => {
    setIsRequested(false);
    setIsError(false);

    const onError = () => {
      dispatch(setLoading(false));
      setIsError(true);
      pInputRef.current = positionInput;
    };

    const onSuccess = async (pos) => {
      const a = await googleGeocode(pos);
      if (a.status === 'OK' && a.results[0].formatted_address) {
        dispatch(setAddress(a.results[0].formatted_address));
        dispatch(setPlaceId(a.results[0].place_id));
        setPosition(a.results[0].formatted_address);
        setIsRequested(true);
        callback();
      }
    };

    if (
      navigator &&
      navigator.geolocation &&
      navigator.geolocation.getCurrentPosition
    ) {
      dispatch(setLoading(true));
      navigator.geolocation.getCurrentPosition(onSuccess, onError, {
        timeout: 5000,
      });
    } else {
      setIsError(true);
    }
  };

  useEffect(() => {
    if (
      positionInput &&
      pInputRef &&
      positionInput.length !== pInputRef.current.length &&
      isError
    ) {
      setIsError(false);
    }
  }, [isError, positionInput]);

  return (
    <NavigatorContext.Provider
      value={{
        isRequested,
        isError,
        setNavigatorPosition: onPositionRequest,
        position,
        setCallback,
      }}
      {...props}
    />
  );
}
export default function useNavigatorPosition() {
  return useContext(NavigatorContext);
}
