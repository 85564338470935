import React, { createContext, useState } from 'react';

export const ToastCtx = createContext({});

const _generateId = () => Math.floor(Math.random() * 1001 + 1);

const defaultOptions = {
  onClick: null,
  type: 'primary.main',
  title: 'Message',
  description: 'description...',
  unique: false,
};

const ToastProvider = ({ children }) => {
  const [list, setList] = useState([]);

  const showToast = (options) => {
    const newList = list;
    const id = _generateId();
    if (list.some((e) => e.unique)) {
      const oldUniqueIndex = list.findIndex((e) => e.unique);
      newList.splice(oldUniqueIndex, 1);
    }
    const toast = { ...defaultOptions, id, ...options };
    setList([...newList, toast]);
  };

  const deleteToast = (id) => {
    const listItemIndex = list.findIndex((e) => e.id === id);
    list.splice(listItemIndex, 1);
    setList([...list]);
  };

  return (
    <ToastCtx.Provider value={{ showToast, toastList: list, deleteToast }}>
      {children}
    </ToastCtx.Provider>
  );
};

export default ToastProvider;
