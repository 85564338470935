import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 33 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.413 4.243a1 1 0 00.635 1.69L1.775 24.594a1 1 0 000 2v-1l.001 1h.043c.027 0 .064 0 .112-.002a31.306 31.306 0 001.916-.111 37.713 37.713 0 004.99-.785c3.915-.892 8.798-2.699 12.294-6.4 3.097-3.28 4.604-7.66 5.348-11.137.374-1.75.56-3.301.654-4.416a28.916 28.916 0 00.085-1.434c.107.077.232.181.382.323.351.33.768.795 1.38 1.476l1.498 1.672a1 1 0 101.49-1.335l-1.5-1.672-.028-.032c-.575-.641-1.048-1.17-1.468-1.564-.434-.41-.878-.745-1.418-.94A4 4 0 0024.97.194c-.546.175-1.001.494-1.45.888-.433.38-.924.892-1.52 1.513l-.03.03-1.557 1.62zm.661 1.692a.998.998 0 00.78-.305l1.557-1.619c.634-.66 1.068-1.109 1.43-1.427.147-.13.27-.227.377-.3l-.002.063c-.011.285-.033.705-.076 1.23a33.512 33.512 0 01-.617 4.164c-.71 3.325-2.114 7.289-4.846 10.18-3.1 3.285-7.533 4.97-11.283 5.825a35.737 35.737 0 01-4.724.743 29.31 29.31 0 01-1.777.104l-.092.001H1.775l19.3-18.66z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgArrowUpOrnament = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgArrowUpOrnament;
