import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 14a4 4 0 100-8 4 4 0 000 8zm0-1.8a2.2 2.2 0 100-4.4 2.2 2.2 0 000 4.4z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm6.036-4.45a8.2 8.2 0 10-12.071 0 6.9 6.9 0 014.578-2.416c.347-.034.708-.034 1.296-.034h.322c.588 0 .95 0 1.296.035a6.9 6.9 0 014.579 2.416zm-1.357 1.185a5.1 5.1 0 00-3.403-1.809c-.245-.025-.51-.026-1.167-.026h-.218c-.656 0-.922.001-1.167.026a5.1 5.1 0 00-3.403 1.809A8.162 8.162 0 0012 20.2c1.74 0 3.352-.541 4.68-1.465z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgUser24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgUser24;
