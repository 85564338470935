import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

// Components
import Box from './atoms/Box';
import Text from './atoms/Text';
import Link from './atoms/Link';
import Heading from './atoms/Heading';
// Icon
import AngleRight16 from '../icons/AngleRight16';

const StyledBox = styled(Box)`
  justify-content: center;
  z-index: 1;
  flex: 1;
  transition: transform 150ms ease-in;
  :hover {
    cursor: pointer;
    transform: translate(0, -3px);
  }
`;

const StyledText = styled.div`
  display: flex;
  height: 100%;
`;

const StyledRectangle = styled.div`
  position: absolute;
  background-color: ${(p) => p.theme.colors.primary.main};
  border-radius: 50px;
  width: 150px;
  height: 100px;
  transform: translate(30%, 7%) rotate(135deg);
  z-index: -1;
  bottom: 0;
  right: 0;
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    transform: translate(30%, 28%) rotate(135deg);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const AnimationLink = styled(Link)`
  animation: ${fadeIn} 0.2s linear;
`;

const StyledPrice = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 33%;
  margin-right: 16px;
  div {
    position: absolute;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    flex: 1;
  }
`;

const StyledImg = styled.img`
  margin: 16px;
  border-radius: 24px;
`;

const PriceCard = ({
  image,
  title,
  text,
  link,
  price,
  boxShadow = null,
  textPrice = true,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const handleChange = () => {
    setHover(!hover);
  };

  const handleLink = () => {
    history.push(link);
  };
  return (
    <Box
      bg="gray.lightest"
      alignItems="stretch"
      px={{ xs: '0px', lg: '12px' }}
      py={{ xs: '12px', lg: '0px' }}
      style={{ flex: 1 }}
      {...props}>
      <StyledBox
        onMouseEnter={handleChange}
        onMouseLeave={handleChange}
        onClick={handleLink}
        bg="white"
        boxShadow={boxShadow}
        borderRadius="lg"
        style={{ overflow: 'hidden' }}>
        <LazyLoad>
          <StyledImg src={image} />
        </LazyLoad>
        <Heading level={5} mb={3} mt={0} mx={4} color="black">
          {title}
        </Heading>
        <StyledText>
          <Box width={2 / 3} pl={4} pb={5} mt={0} style={{ flex: 3 }}>
            <Text color="gray.darkest">{text}</Text>
          </Box>
          <StyledPrice>
            <StyledRectangle />
            {hover ? (
              <AnimationLink
                fontWeight="bold"
                mb={4}
                color="white"
                to={link}
                after={<AngleRight16 />}
                hover="no">
                {t('reserve')}
              </AnimationLink>
            ) : (
              <div>
                {textPrice && (
                  <Text
                    color="white"
                    variant="caption"
                    fontWeight="bold"
                    textAlign="end">
                    {t('from')}
                  </Text>
                )}
                <Heading as="p" mb={4} level={2} color="white">
                  {t('pricecard.price', { price })}
                </Heading>
              </div>
            )}
          </StyledPrice>
        </StyledText>
      </StyledBox>
    </Box>
  );
};

PriceCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  price: PropTypes.string,
  boxShadow: PropTypes.string,
  textPrice: PropTypes.bool,
};

export default PriceCard;
