/* eslint-disable no-case-declarations */
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';
import { fixerPhotoUrl } from '../../../utils/fixers';

// Components
import Text from '../../shared/atoms/Text';
import Badge from '../../shared/atoms/Badge';
import Row from '../../shared/atoms/Row';
import Box from '../../shared/atoms/Box';
import BadgeIcon from '../../shared/atoms/BadgeIcon';
import Button from '../../shared/atoms/Button';
import Separator from '../../Separator';
import Search16 from '../../icons/Search16';
import CalendarCheck16 from '../../icons/CalendarCheck16';
import Time16 from '../../icons/Time16';
import Check16 from '../../icons/Check16';
import NotificationDot16 from '../../icons/NotificationDot16';

const FixerPhoto = styled('img')`
  width: 24px;
  border-radius: 100%;
  margin-right: 4px;
`;

const CourseCard = ({ course, channels }) => {
  const { t } = useTranslation();
  const channel = channels.find((e) => e.id.endsWith(`_${course.fixer_id}`));
  return (
    <Box
      borderColor="gray.light"
      borderWidth="1px"
      borderRadius="sm"
      borderStyle="solid"
      key={course.id}
      mb={5}
      p={{ xs: 2, lg: 5 }}>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        pt={{ xs: 2, lg: 0 }}
        px={{ xs: 2, lg: 0 }}>
        <Box flexDirection={{ xs: 'column', lg: 'row' }}>
          <Text mr={1} color="black">
            {t('request')} <b>#{course.id}</b>
          </Text>
        </Box>
        {channel && channel.unreadCount > 0 && course.state == 'scheduled' && (
          <Badge before={<NotificationDot16 />} light>
            {t('coursecard.newmessage')}
          </Badge>
        )}
        {course.state === 'finished' && (
          <Box flexDirection={{ xs: 'column', lg: 'row' }}>
            <Text mr={{ xs: 0, lg: 1 }} fontWeight={500}>
              {t('coursecard.total')}
            </Text>
            <Text textAlign="right" fontWeight={2}>
              {course.prix}
              {course.currency}
            </Text>
          </Box>
        )}
      </Box>
      <Separator mt={4} axe="horizontal" />
      <Row md gap={16}>
        <Box style={{ flex: 3 }} pt={{ xs: 2, lg: 0 }} px={{ xs: 2, lg: 0 }}>
          {course.state === 'dispatched' && (
            <Box flexDirection="row">
              <BadgeIcon
                icon={<Search16 color="white" />}
                color="blue.dark"
                mr={2}
              />
              <Text fontWeight={2} mb={2} variant="large" color="black">
                {t('coursecard.lookingforfixer')}
              </Text>
            </Box>
          )}
          {course.state === 'scheduled' && (
            <Box flexDirection="row">
              <BadgeIcon
                icon={<CalendarCheck16 color="white" />}
                color="blue.dark"
                mr={2}
              />
              <Text fontWeight={2} mb={2} variant="large" color="black">
                {moment(course.date).format('LLL')}
              </Text>
            </Box>
          )}
          {course.state === 'finished' && (
            <Box flexDirection="row">
              <BadgeIcon
                icon={<Check16 color="white" />}
                color="green.dark"
                mr={2}
              />
              <Text fontWeight={2} mb={2} variant="large" color="black">
                {moment(course.date).format('LLL')}
              </Text>
            </Box>
          )}
          {course.state === 'cancelled' && (
            <Box flexDirection="row">
              <BadgeIcon
                icon={<Time16 color="white" />}
                color="primary.darker"
                mr={2}
              />
              <Text
                fontWeight={2}
                mb={2}
                color="primary.darker"
                variant="large">
                {t('coursecard.finished.text')}
              </Text>
            </Box>
          )}
          <Text>
            <b style={{ fontWeight: 500 }}>{t('address')}</b> :{' '}
            {course.position}
          </Text>
          {course.fixer_id && (
            <Text mt={2}>
              <b style={{ fontWeight: 500 }}>{t('fixer')}</b> :{' '}
              <FixerPhoto src={fixerPhotoUrl(course.fixer)} />
              {course.fixer.prenom}
            </Text>
          )}
        </Box>
        <Box>
          <Button
            fullWidth
            to={`/courses/${course.id}`}>
            {t('seedetails')}
          </Button>
        </Box>
      </Row>
    </Box>
  );
};

export default CourseCard;
