import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#Sad_Color_56_svg__clip0)">
      <path
        d="M40.02 30.203a1 1 0 101.96-.406C40.652 23.395 34.887 18.6 28 18.6c-6.887 0-12.653 4.795-13.98 11.197a1 1 0 101.96.406C17.11 24.737 22.051 20.6 28 20.6c5.948 0 10.889 4.137 12.02 9.603zM33.652 13.4a1 1 0 011.366-.366l3.048 1.76a1 1 0 11-1 1.731l-3.048-1.76a1 1 0 01-.366-1.365zM21.879 14.766a1 1 0 10-1-1.732l-3.048 1.76a1 1 0 001 1.731l3.048-1.76z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52 28c0 13.255-10.745 24-24 24S4 41.255 4 28 14.745 4 28 4s24 10.745 24 24zm-2 0c0 12.15-9.85 22-22 22S6 40.15 6 28 15.85 6 28 6s22 9.85 22 22z"
        fill="#000"
      />
      <path
        d="M37.149 36.88a3.037 3.037 0 00-.019 4.293l4.678 4.722-4.92 4.922a3.037 3.037 0 000 4.294 3.033 3.033 0 004.292 0l4.9-4.904 4.731 4.774a3.033 3.033 0 004.291.018 3.037 3.037 0 00.019-4.293l-4.75-4.793 4.549-4.55a3.037 3.037 0 000-4.294 3.033 3.033 0 00-4.29 0l-4.53 4.532-4.66-4.703a3.033 3.033 0 00-4.291-.018z"
        fill="#d5ff4f"
      />
      <path
        d="M8.491 7.679C8.78 3.926 7.02 1.805 7.02 1.805a2.498 2.498 0 00-3.516-.381 2.498 2.498 0 00-.411 3.512c.006-.004.04.031.106.098.422.428 2.106 2.135 5.292 2.645zM6.636 11.259C4.57 9.336 2.503 9.376 2.503 9.376a1.873 1.873 0 00-1.867 1.883c0 1.042.834 1.883 1.867 1.883 0-.005.038-.009.107-.016.448-.048 2.237-.237 4.026-1.867zM52.484 6.464s-2.074 1.252-5.298.51c.86-2.703 2.565-3.943 2.992-4.254.066-.048.102-.074.099-.08a2.196 2.196 0 013 .818c.61 1.058.256 2.4-.793 3.006z"
        fill="#1F77F9"
      />
    </g>
    <defs>
      <clipPath id="Sad_Color_56_svg__clip0">
        <path fill="#fff" d="M0 0h56v56H0z" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgSadColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgSadColor56;
