import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React from 'react';
import NotFound from './scenes/NotFound';
import SignupLandingHome from './scenes/signupLanding/SignupLandingHome';
import SignupLandingConfirmation from './scenes/signupLanding/SignupLandingConfirmation';
import Header from './components/Header';

const LandingSignupRoutes = ({ partenaire, ...props }) => (
  <Router>
    <div>
      <Helmet title="Roulezjeunesse, réparation à domicile de vélo, trottinette" />
      <Header noMenu={true} partenaire={partenaire} />
      <div>
        <Switch>
          <Route
            exact
            path="/"
            component={() => <SignupLandingHome partenaire={partenaire} />}
          />
          <Route
            exact
            path="/merci"
            component={() => (
              <SignupLandingConfirmation partenaire={partenaire} />
            )}
          />
          <Route component={NotFound} />
        </Switch>
      </div>
    </div>
  </Router>
);

export default LandingSignupRoutes;
