import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M14.2 1h-2.269c-.605 0-.908 0-1.049.12a.5.5 0 00-.173.42c.014.183.228.397.657.826l.604.604-5.5 5.5a.75.75 0 001.06 1.06l5.5-5.5.604.604c.429.429.643.643.827.657a.5.5 0 00.42-.173c.119-.14.119-.444.119-1.05V1.8c0-.28 0-.42-.055-.527a.5.5 0 00-.218-.219C14.62 1 14.48 1 14.2 1z"
      fill="#000"
    />
    <path
      d="M5.75 2A3.75 3.75 0 002 5.75v4.5A3.75 3.75 0 005.75 14h4.5A3.75 3.75 0 0014 10.25V8.382a.75.75 0 00-1.5 0v1.868a2.25 2.25 0 01-2.25 2.25h-4.5a2.25 2.25 0 01-2.25-2.25v-4.5A2.25 2.25 0 015.75 3.5h1.868a.75.75 0 000-1.5H5.75z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgExternallink16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgExternallink16;
