import React from 'react';
import styled from 'styled-components';

import Box from '../atoms/Box';

const LineDotted = styled.hr`
  background-color: #fff;
  width: 100%;
  flex: 1;
  border-bottom: 1px dashed ${(p) => p.theme.colors.gray.main};
  margin: 0 8px;
`;

const DottedRow = ({ before, after, styledLine, ...rest }) => (
  <Box as="li" flexDirection="row" alignItems="center" {...rest}>
    {before}
    <LineDotted style={styledLine} />
    {after}
  </Box>
);

export default DottedRow;
