import * as Yup from 'yup';
import i18n from '../../i18n';

const updateValidationSchema = Yup.object().shape({
  nom: Yup.string().required(i18n.t('customerarea.validation.lastname')),
  prenom: Yup.string().required(i18n.t('customerarea.validation.firstname')),
  tel: Yup.string().required(i18n.t('customerarea.validation.phone')),
  email: Yup.string()
    .required(i18n.t('email.required'))
    .email(i18n.t('email.invalid')),
});

export const updatePasswordValidationSchema = Yup.object().shape({
  current_password: Yup.string().min(
    8,
    i18n.t('password.eightcharacterminimum'),
  ),
  new_password: Yup.string().min(8, i18n.t('password.eightcharacterminimum')),
  new_password_confirm: Yup.string()
    .min(8, i18n.t('password.eightcharacterminimum'))
    .oneOf([Yup.ref('new_password'), null], i18n.t('password.match')),
});

export default updateValidationSchema;
