import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M7.5 24.25a5.5 5.5 0 100-11 5.5 5.5 0 000 11z"
      className="recolor"
      fill="#d5ff4f"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.536 9H8.5a.75.75 0 110-1.5h4a.75.75 0 010 1.5h-1.287l.75 1.5h8.787V9H20a.75.75 0 010-1.5h4.106a1.694 1.694 0 110 3.389.75.75 0 010-1.5.194.194 0 100-.389H22.25v2.106l.922 2.306a5.5 5.5 0 11-1.393.557l-.506-1.268-5.217 6.3H6.731l4.09-7.433L9.536 9zm3.178 3h7.191l-4.484 5.416L12.714 12zm1.072 5.5l-2.15-4.301L9.269 17.5h4.518zM24.5 14.75c-.261 0-.516.025-.763.073l1.46 3.649a.75.75 0 01-1.393.557l-1.46-3.649a4 4 0 102.156-.63z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = true;
const SvgBike32Color = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      .recolor {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgBike32Color;
