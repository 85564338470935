import React from 'react';
import { useTranslation } from 'react-i18next';
// Components
import AgendaCheckbox from './AgendaCheckbox';
import Box from '../shared/atoms/Box';
import Text from '../shared/atoms/Text';
import Line from '../shared/atoms/Line';

const DateAndAllSelect = ({
  disabledAM,
  disabledPM,
  valueAMSlots,
  valuePMSlots,
  fullSelect,
  dayID,
  amSlots,
  pmSlots,
  day,
  shopId,
}) => {
  const { t } = useTranslation();
  return (
    <Box p={4} pb={0}>
      <Box flexDirection="row">
        <Text
          variant="large"
          fontWeight="bold"
          color="black"
          style={{ textTransform: 'capitalize' }}>
          {day.format('dddd DD MMMM')}
        </Text>
        <Line
          color="gray.light"
          property="solid"
          style={{ marginTop: '18px', marginLeft: '4px' }}
        />
      </Box>
      {!shopId && (
        <Box flexDirection="row" mt={2}>
          <AgendaCheckbox
            mr={2}
            mb={0}
            width="100%"
            text={t('allmorning')}
            onClick={() => fullSelect(dayID, amSlots, valueAMSlots)}
            disabled={disabledAM}
            value={valueAMSlots}
          />
          <AgendaCheckbox
            ml={2}
            mb={0}
            width="100%"
            text={t('allafternoon')}
            onClick={() => fullSelect(dayID, pmSlots, valuePMSlots)}
            disabled={disabledPM}
            value={valuePMSlots}
          />
        </Box>
      )}
    </Box>
  );
};

export default DateAndAllSelect;
