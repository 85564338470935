import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// Components shared
import SectionBackground from '../../shared/molecules/SectionBackground';
import Box from '../../shared/atoms/Box';
import Heading from '../../shared/atoms/Heading';
import Text from '../../shared/atoms/Text';
import Button from '../../shared/atoms/Button';
// Icons
import AngleRight16 from '../../icons/AngleRight16';

const StyledButton = styled(Button)`
  width: 100%;
  text-align: center;
`;

const StyledBox = styled(Box)`
  z-index: 2;
  margin-bottom: -60px;
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    margin-bottom: 32px;
  }
`;

const StyledImg = styled.img`
  object-fit: contain;
  max-height: 549px;
  width: 100%;
  margin-bottom: -199px;
`;

const Hero = ({ bg, title, text, img, toolkit, type }) => {
  const { t } = useTranslation();
  return (
    <div style={{ overflow: 'hidden' }}>
      <SectionBackground
        bg={bg}
        px={{ xs: '20px', md: 10 }}
        py={7}
        mb={{ xs: '200px', md: '235px' }}
        justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
        style={{ position: 'relative' }}>
        <Box
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent="space-between">
          {toolkit}
          <StyledBox
            width={{ xs: '100%', sm: '75%', md: '50%' }}
            justifyContent="flex-end">
            <Heading level={1} mb={4}>
              {title}
            </Heading>
            <Box width={{ xs: '100%', md: '75%' }}>
              <Text variant="large" mb={5} color="black">
                {text}
              </Text>
              <StyledButton
                target="_blank"
                href={`https://feedback.roulezjeunesse.com/contact-b2b?type=${type}`}
                after={<AngleRight16 />}
                animation>
                {t('contactus')}
              </StyledButton>
            </Box>
          </StyledBox>
          <Box
            width={{ xs: '100%', md: '50%' }}
            justifyContent="flex-end"
            alignItems="flex-end">
            <StyledImg src={img} alt={t('roulezjeunessebusiness')} />
          </Box>
        </Box>
      </SectionBackground>
    </div>
  );
};

Hero.propTypes = {
  bg: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  img: PropTypes.string,
  toolkit: PropTypes.any,
};

export default Hero;
