import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    padding-left: 1rem;
    text-indent: -0.85rem;
    line-height: 1.8;
  }
  li::before {
    background-color: ${(p) => themeGet(`colors.${p.$dotColor}`, p.$dotColor)};
    border-radius: 50%;
    content: '';
    display: inline-block;
    margin-right: 0.5rem;
    margin-bottom: 3px;
    height: 6px;
    width: 6px;
  }
`;

export default function DottedList({
  dotColor = 'gray.darkest',
  children,
  ...rest
}) {
  return (
    <List $dotColor={dotColor} {...rest}>
      {children}
    </List>
  );
}
