import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import Box from '../shared/atoms/Box';
import Link from '../shared/atoms/Link';
import Heading from '../shared/atoms/Heading';
import Text from '../shared/atoms/Text';
import Button from '../shared/atoms/Button';
// Icons
import AngleRight16 from '../icons/AngleRight16';
import ArrowRight24 from '../icons/ArrowRight24';
import Search24 from '../icons/Search24';
import ExternalLink16 from '../icons/ExternalLink16';
import VideoPresentation from './VideoPresentation';

const StyledLink = styled(Link)`
  font-weight: bold;
  text-decoration: underline;
`;

const DisplayText = ({ title, text }) => (
  <Box flexDirection="row" mb={4}>
    <ArrowRight24 />
    <Box ml={2}>
      <Heading level={5}>{title}</Heading>
      <Text>{text}</Text>
    </Box>
  </Box>
);

const WhoAreTheRepairers = () => {
  const { t } = useTranslation();
  return (
    <Box flexDirection={{ xs: 'column', lg: 'row' }} mt={10}>
      <Box
        justifyContent="center"
        mb={{ xs: 7, lg: 0 }}
        width={{ xs: '100%', lg: '50%' }}>
        <Heading level={2}>{t('whoaretherepairers.headings.1')}</Heading>
        <DisplayText
          title={t('whoaretherepairers.titles.1')}
          text={t('whoaretherepairers.texts.1')}
        />
        <DisplayText
          title={t('whoaretherepairers.titles.2')}
          text={t('whoaretherepairers.texts.2')}
        />
        <DisplayText
          title={t('whoaretherepairers.titles.3')}
          text={t('whoaretherepairers.texts.3')}
        />
        <Button
          after={<AngleRight16 />}
          mt={2}
          target="_blank"
          href="https://feedback.roulezjeunesse.com/formulaire-reseau?source=site_roulezjeunesse">
          {t('button.recruit')}
        </Button>
      </Box>
      <Box
        justifyContent="center"
        px={{ xs: 0, md: 10 }}
        alignItems="center"
        width={{ xs: '100%', lg: '50%' }}>
        <VideoPresentation link={'https://www.youtube.com/embed/SMjlE8A-5qI'} />
      </Box>
    </Box>
  );
};

export default WhoAreTheRepairers;
