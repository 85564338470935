import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// Components
import Box from './Box';
import Text from './Text';

const StyledValue = styled(Box)`
  border-radius: 8px;
  position: absolute;
  background-color: white;
  box-shadow: 24px;
  left: ${(p) => `calc(${p.pourcentage}% - 16px)`};
  top: -40px;
  z-index: 1;
`;

const StyledInput = styled.input`
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  pointer-events: none;
  position: absolute;
  height: 0;
  left: -20px;
  width: calc(100% + 28px);
  outline: none;
  z-index: 3;
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: ${(p) => p.theme.colors.primary.main};
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 0 4px white;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  ::-moz-range-thumb {
    -webkit-appearance: none;
    background-color: ${(p) => p.theme.colors.primary.main};
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 0 4px white;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
`;

const StyledSlider = styled.div`
  position: relative;
  width: 100%;
`;
const StyledTrack = styled.div`
  border-radius: 3px;
  height: 5px;
  position: absolute;
  background-color: ${(p) => p.theme.colors.gray.light};
  width: 100%;
  z-index: 1;
`;
const StyledRange = styled.div`
  border-radius: 3px;
  height: 5px;
  position: absolute;
  background-color: ${(p) => p.theme.colors.primary.main};
  left: 0;
  width: ${(p) => `${p.pourcentage}%`};
  z-index: 2;
`;

const Slider = ({ min, max, onChange, value }) => {
  const pourcentage = (value / max) * 100;
  return (
    <>
      <StyledInput
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={onChange}
      />
      <StyledSlider>
        <StyledValue pourcentage={pourcentage} boxShadow="sm" py="2px" px="6px">
          <Text fontWeight="500" color="black">
            {value}h
          </Text>
        </StyledValue>
        <StyledTrack />
        <StyledRange pourcentage={pourcentage} />
      </StyledSlider>
    </>
  );
};

Slider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Slider;
