import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import LazyLoad from 'react-lazyload';

// Components
import Box from './atoms/Box';
import Text from './atoms/Text';
import Card from './atoms/Card';
import Heading from './atoms/Heading';
import Button from './atoms/Button';
import Container from './atoms/Container';
// Icon
import AngleRight16 from '../icons/AngleRight16';
import SectionBackground from './molecules/SectionBackground';

const StyledImg = styled.img`
  border-radius: 24px;
  width: 100%;
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    width: 50%;
    border-radius: 20px;
    margin-right: 10px;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

const StyledText = styled(Text)`
  text-align: center;
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    text-align: start;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    text-align: center;
  }
`;

const StyledCard = styled(Card)`
  :hover {
    cursor: pointer;
    transform: translate(0, -3px);
    transition: transform 150ms ease-in;
  }
`;

const StyledButton = styled(Button)`
  ${(p) =>
    p.hover &&
    css`
      color: ${p.theme.colors.primary.main} !important;
      box-shadow: inset 0px 0px 0px 2px ${p.theme.colors.primary.main};
      & > .icon-left > path,
      .icon-right > path {
        fill: ${p.theme.colors.primary.main} !important;
        transition: all 150ms ease;
      }
    `}
`;

const ContainerCard = ({ link, title, paragraph, alt, img, ...rest }) => {
  const [hover, setHover] = useState(false);
  const { t } = useTranslation();

  const handleChange = () => {
    setHover(!hover);
  };
  return (
    <StyledCard
      boxShadow="md"
      borderRadius={{ xs: 'md', lg: 'lg' }}
      p={4}
      onMouseEnter={handleChange}
      onMouseLeave={handleChange}
      {...rest}>
      <Box
        alignItems="center"
        flexDirection={{ xs: 'column', md: 'row', lg: 'column' }}>
        <LazyLoad>
          <StyledImg src={img} alt={alt} />
        </LazyLoad>
        <Box
          alignItems={{ xs: 'center', md: 'flex-start', lg: 'center' }}
          ml={{ sm: 0, md: '10px', mg: 0 }}>
          <Heading level={4} mb={1} mt={{ xs: 3, md: 0, lg: 3 }}>
            {title}
          </Heading>
          <StyledText mb={4}>{paragraph}</StyledText>
          <Box alignItems="center">
            <StyledButton
              mb={{ sm: 0, md: 5, lg: 5 }}
              variant="outlinedBlack"
              to={link}
              after={<AngleRight16 />}
              hover={hover}
              animation>
              {t('discover.cta')}
            </StyledButton>
          </Box>
        </Box>
      </Box>
    </StyledCard>
  );
};

const OurPrices = ({ ...rest }) => {
  const { t } = useTranslation();
  return (
    <SectionBackground bg="gray.lightest" alignItems="center" {...rest}>
      <Container py={{ xs: 7, md: 9 }}>
        <Heading
          color="black"
          style={{ textAlign: 'center' }}
          mx={{ xs: 5, sm: 0 }}
          level={2}
          mb={{ xs: '12px', lg: 5 }}>
          {t('ourrepairs.heading')}
        </Heading>
        <Box alignItems="stretch" flexDirection={{ xs: 'column', lg: 'row' }}>
          <ContainerCard
            link="/produits/velo"
            title={t('Bike')}
            paragraph={t('ourrepairs.texts.1')}
            img="/static/img/photos/velo_new.png"
            alt={t('Bike')}
          />
          <ContainerCard
            mx={{ xs: 0, lg: 5 }}
            my={{ xs: 5, lg: 0 }}
            link="/produits/velo-electrique"
            title={t('v.a.e')}
            paragraph={t('ourrepairs.texts.2')}
            img="/static/img/photos/vae-dacathlon.png"
            alt={t('v.a.e')}
          />
          <ContainerCard
            link="/produits/trottinette"
            title={t('scooter.uppercase')}
            paragraph={t('ourrepairs.texts.3')}
            img="/static/img/photos/trottinette_new.png"
            alt={t('scooter.uppercase')}
          />
        </Box>
      </Container>
    </SectionBackground>
  );
};

export default OurPrices;
