import React from 'react';
import { useTranslation } from 'react-i18next';
// Components
import StepsExplained from '../shared/organisms/StepsExplained';
import DisplayShop from './DisplayShop';

const InfosComponents = ({
  shops,
  isInZone,
  refs,
  indexShopClick,
  annuaire,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {isInZone && (
        <StepsExplained
          mt={9}
          headings={[
            t('how.headings.3'),
            t('how.headings.5'),
            t('how.headings.7'),
          ]}
          texts={[
            t('how.contents.1'),
            t('how.contents.2'),
            t('how.contents.3'),
          ]}
        />
      )}
      {shops.length && (
        <DisplayShop {...{ shops, refs, indexShopClick, annuaire }} />
      )}
    </>
  );
};

export default InfosComponents;
