import React from 'react';
import styled from 'styled-components';
// Components
import Box from '../shared/atoms/Box';
import Heading from '../shared/atoms/Heading';
import Text from '../shared/atoms/Text';
import Link from '../shared/atoms/Link';

const StyledImg = styled.img`
  border-radius: 24px;
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    border-radius: 16px;
  }
`;

const WrapperForm = ({
  children,
  withTitle,
  _switch,
  text,
  chooseSwitch,
  withSwitch,
  linkText,
  bg,
  noPadding,
  ...props
}) => {
  return (
    <Box
      bg={bg}
      height="100%"
      justifyContent="center"
      borderRadius={{ xs: 'sm', lg: 'md' }}
      py={noPadding ? 0 : { xs: 4, lg: 6 }}>
      <Box px={noPadding ? 0 : { xs: 4, lg: 7 }}>
        {withTitle && (
          <Box maxWidth="90%">
            <Heading level={3} as="h1">
              {withTitle}
            </Heading>
          </Box>
        )}
        {text && (
          <Text mb={5} variant="medium" color="black">
            {text}&nbsp;
            {linkText && withSwitch && (
              <Link
                color="blue.electric"
                onClick={() => _switch(chooseSwitch)}
                data-testid="link-connect-form"
                fontWeight="bold">
                {linkText}
              </Link>
            )}
          </Text>
        )}
        {children}
      </Box>
    </Box>
  );
};

export default WrapperForm;
