/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';

import { Field, Form, Formik } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import * as Yup from 'yup';
import i18n from '../../i18n';
import { randomPassword, readableProduct } from '../../utils/utils';
// Components
import Input from '../shared/atoms/Input';
import Button from '../shared/atoms/Button';
import Box from '../shared/atoms/Box';
import Text from '../shared/atoms/Text';
import Checkbox from '../shared/atoms/Checkbox';
import WrapperForm from './WrapperForm';
import Link from '../shared/atoms/Link';
// Icons
import UserAlt24 from '../icons/UserAlt24';
import Eye24 from '../icons/Eye24';
import Message24 from '../icons/Message24';
import Lock24 from '../icons/Lock24';
import Phone24 from '../icons/Phone24';
import AngleRight16 from '../icons/AngleRight16';
import { FeaturesContext } from '../../contexts/FeaturesContext';

const phoneRegExp = /^(0[67]\d{8}|\+\d{11})$/;
const signupValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, i18n.t('password.eightcharacterminimum'))
    .required(i18n.t('fieldrequired')),
  email: Yup.string()
    .email(i18n.t('email.invalid'))
    .required(i18n.t('fieldrequired')),
  prenom: Yup.string().required(i18n.t('fieldrequired')),
  nom: Yup.string().required(i18n.t('fieldrequired')),
  tel: Yup.string()
    .matches(phoneRegExp, i18n.t('phone.invalid'))
    .required(i18n.t('fieldrequired')),
});

const SignupForm = ({
  product_id = null,
  hasTextCheckbox = false,
  text,
  linkText,
  withSwitch = true,
  withTitle,
  ctaLabel,
  bg,
  noPadding,
  ...props
}) => {
  const { t } = useTranslation();
  const { isFeatureOn } = useContext(FeaturesContext);

  return (
    <WrapperForm
      withTitle={withTitle || t('signupform.titles.1')}
      text={text || t('signupform.texts.1')}
      linkText={linkText || t('signupform.switchtologin')}
      withSwitch={withSwitch}
      chooseSwitch="LOGIN"
      bg={bg}
      noPadding={noPadding}
      {...props}>
      <Formik
        initialValues={{
          prenom: '',
          nom: '',
          tel: '',
          email: '',
          password: isFeatureOn('ISWIDGET') ? randomPassword() : '',
          nloptin: 0,
        }}
        validationSchema={signupValidationSchema}
        onSubmit={props.submit}>
        {({ isSubmitting, values, setFieldValue, errors, submitCount }) => (
          <Form>
            <Box flexDirection={{ xs: 'column', lg: 'row' }}>
              <Field
                name="prenom"
                render={({ field }) => (
                  <Input
                    mr={{ xs: 0, lg: 2 }}
                    style={{ flex: 1 }}
                    mb={{ xs: 2, lg: 4 }}
                    color="black"
                    data-testid="input-prenom"
                    bg="white"
                    before={<UserAlt24 />}
                    placeholder={t('firstname.inputlabel')}
                    error={!!submitCount && errors.prenom}
                    {...field}
                  />
                )}
              />
              <Field
                name="nom"
                render={({ field }) => (
                  <Input
                    ml={{ xs: 0, lg: 2 }}
                    style={{ flex: 1 }}
                    mb={{ xs: 2, lg: 4 }}
                    color="black"
                    data-testid="input-nom"
                    bg="white"
                    before={<UserAlt24 />}
                    placeholder={t('lastname.inputlabel')}
                    error={!!submitCount && errors.nom}
                    {...field}
                  />
                )}
              />
            </Box>
            <Field
              name="tel"
              render={({ field }) => (
                <Input
                  mb={{ xs: 2, lg: 4 }}
                  color="black"
                  bg="white"
                  data-testid="input-tel"
                  before={<Phone24 />}
                  placeholder={t('phone.inputlabel')}
                  error={!!submitCount && errors.tel}
                  {...field}
                />
              )}
            />
            <Field
              name="email"
              render={({ field }) => (
                <Input
                  mb={{ xs: 2, lg: 4 }}
                  color="black"
                  bg="white"
                  data-testid="input-email"
                  before={<Message24 />}
                  placeholder={t('email.inputlabel')}
                  error={!!submitCount && errors.email}
                  {...field}
                />
              )}
            />
            {!isFeatureOn('ISWIDGET') ? (
              <Field
                name="password"
                render={({ field }) => (
                  <Input
                    mb={{ xs: 2, lg: 4 }}
                    color="black"
                    bg="white"
                    type="password"
                    data-testid="input-password"
                    before={<Lock24 />}
                    after={<Eye24 />}
                    placeholder={t('password.inputlabel')}
                    error={!!submitCount && errors.password}
                    {...field}
                  />
                )}
              />
            ) : (
              <div />
            )}
            {!isFeatureOn('ISWIDGET') ? (
              <Box
                mb={5}
                flexDirection="row"
                alignItems={{ xs: 'flex-start', lg: 'center' }}
                className="form-group">
                <Checkbox
                  id="nloptin"
                  type="checkbox"
                  className={'form-control'}
                  name={'doobl'}
                  checked={!!values.nloptin}
                  onChange={() =>
                    setFieldValue('nloptin', values.nloptin === 0 ? 1 : 0)
                  }
                />
                <Text
                  as="label"
                  fontWeight="500"
                  color="black"
                  variant="small"
                  htmlFor="nloptin"
                  ml={2}>
                  {hasTextCheckbox || (
                    <>
                      {t('signupform.newsletter.optin', {
                        label:
                          product_id &&
                          readableProduct(product_id, false, false, true),
                      })}
                    </>
                  )}
                </Text>
              </Box>
            ) : (
              <div />
            )}
            <Box mb={5}>
              <Button
                after={<AngleRight16 />}
                disabled={isSubmitting}
                data-testid="signup-form-on-submit"
                style={{ alignSelf: 'flex-end' }}
                fullWidth
                animation>
                {ctaLabel || t('signup')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <Text color="gray.darker" variant="caption">
        <Trans
          i18nKey="signupform.privacy"
          components={{
            reactLink: (
              <Link color="gray.darker" href="mailto:privacy@roulezjeunesse.com" />
            ),
          }}
        />
      </Text>
    </WrapperForm>
  );
};

export default SignupForm;
