import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';
/**
 * @param {string} color
 * Component generated with svgr,
 * if modification needed please go to src/utils/icon-template.js
 */

/**
 * @param {string} color
 * Component generated with svgr,
 * if modification needed please go to src/utils/icon-template.js
 */
const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M1.75 1.25a.75.75 0 0 0 0 1.5h12.5a.75.75 0 0 0 0-1.5H1.75ZM9 6a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5A.75.75 0 0 1 9 6ZM5.251 7.25a.75.75 0 0 0-1.5 0v2.001H1.75a.75.75 0 0 0 0 1.5h2.001v2.001a.75.75 0 0 0 1.5 0v-2h2.001a.75.75 0 0 0 0-1.5h-2V7.25ZM9 14a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5A.75.75 0 0 1 9 14ZM9.75 9.25a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5h-4.5Z"
      fill="#161717"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgAddSubTask16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgAddSubTask16;
