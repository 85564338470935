import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx={38} cy={18} r={12} fill="#A6F2C4" />
    <circle cx={18} cy={39} r={12} fill="#E2BEFF" />
    <path
      d="M24 19c0-7.18 5.82-13 13-13s13 5.82 13 13-5.82 13-13 13a1 1 0 100 2c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15a1 1 0 102 0zM11.893 38.589l-.26-.966a1 1 0 00.518 1.932l.014-.004.065-.015a11.741 11.741 0 011.295-.212c.866-.092 2.028-.125 3.24.097 1.213.223 2.416.704 3.335 1.146a17.666 17.666 0 011.386.745l.074.046.017.01.002.002a1 1 0 001.078-1.685l-.499.78.499-.78-.002-.001-.002-.002-.008-.005-.026-.016a15.225 15.225 0 00-.438-.26c-.293-.167-.71-.394-1.215-.636-1-.48-2.382-1.044-3.84-1.31-1.457-.268-2.822-.225-3.814-.12a13.736 13.736 0 00-1.54.254l-.097.023-.027.008-.01.002h-.002l-.002.001.259.966zM22.053 30.947a1 1 0 10-2 0v3.158a1 1 0 102 0v-3.158zM12.526 30.053a1 1 0 011 1v3.158a1 1 0 11-2 0v-3.158a1 1 0 011-1z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34 37c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15zm-2 0c0 7.18-5.82 13-13 13S6 44.18 6 37s5.82-13 13-13 13 5.82 13 13zM32 18a1 1 0 00-.982 1.19C31.763 23.04 35.023 26 39 26c3.977 0 7.237-2.96 7.982-6.81A1 1 0 0046 18H32zm7 6c-2.53 0-4.74-1.63-5.673-4h11.346c-.933 2.37-3.143 4-5.673 4z"
      fill="#000"
    />
    <path
      d="M34.2 13.78a.6.6 0 00-.6.6 1 1 0 11-2 0 2.6 2.6 0 115.2 0 1 1 0 11-2 0 .6.6 0 00-.6-.6zM43.388 14.38a.6.6 0 111.2 0 1 1 0 102 0 2.6 2.6 0 00-5.2 0 1 1 0 102 0z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgOpinionColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgOpinionColor56;
