import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M23 15a6 6 0 100-12 6 6 0 000 12z" fill="#d5ff4f" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.344 7.25l-.098-.558a3 3 0 015.908-1.041l.087.492a8.587 8.587 0 016.99 6.97l.24 1.37a2 2 0 00.625 1.132l2.775 2.522c1.845 1.677.958 4.742-1.497 5.175l-6.922 1.22a5.002 5.002 0 01-9.514 1.678l-1.14.2c-2.455.434-4.336-2.143-3.176-4.35l1.745-3.318a2 2 0 00.199-1.278l-.242-1.37a8.587 8.587 0 014.02-8.844zm1.882-.844a8.662 8.662 0 011.966-.367l-.007-.041a1 1 0 00-1.97.347l.011.061zm1.408 1.713a6.583 6.583 0 00-5.34 7.627l.242 1.37a4 4 0 01-.4 2.556l-1.744 3.319a1 1 0 001.059 1.45l17.576-3.099a1 1 0 00.499-1.725l-2.775-2.522a4 4 0 01-1.249-2.265l-.241-1.37a6.583 6.583 0 00-7.627-5.34zm.357 18.93a3 3 0 01-2.962-1.208l5.331-.94a3 3 0 01-2.37 2.149z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgBellDing32Color = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgBellDing32Color;
