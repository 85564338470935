import React from 'react';
import styled from 'styled-components';

const InfoTextW = styled.span`
  font-weight: 300;
`;

const InfoText = (props) => <InfoTextW {...props} />;

export default InfoText;
