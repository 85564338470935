import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M7.251 3.5a.75.75 0 000-1.5H6.72c-.674 0-1.224 0-1.672.037-.463.038-.882.118-1.272.317a3.25 3.25 0 00-1.42 1.42c-.2.391-.28.81-.318 1.273C2 5.495 2 6.045 2 6.72v2.566c0 .673 0 1.224.037 1.671.037.463.118.882.317 1.273a3.25 3.25 0 001.42 1.42c.391.199.81.28 1.273.318.448.036.998.036 1.672.036h.532a.75.75 0 000-1.5H6.75c-.712 0-1.202 0-1.58-.031-.371-.03-.57-.086-.714-.16a1.75 1.75 0 01-.765-.764c-.074-.145-.13-.344-.16-.714-.03-.38-.031-.868-.031-1.58V6.75c0-.712 0-1.201.032-1.58.03-.371.085-.57.159-.714a1.75 1.75 0 01.765-.765c.144-.073.343-.129.713-.16.38-.03.869-.03 1.581-.03h.501z"
      fill="#000"
    />
    <path
      d="M6 8.75h6.219l-.065.08c-.193.226-.464.498-.869.902l-.737.738a.75.75 0 101.06 1.06l.76-.758c.377-.378.693-.693.93-.972.246-.29.45-.59.568-.95a2.75 2.75 0 000-1.7c-.117-.36-.322-.66-.569-.95-.236-.279-.552-.594-.93-.972l-.758-.758a.75.75 0 10-1.06 1.06l.736.738c.405.404.676.676.869.903l.065.079H6a.75.75 0 000 1.5z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgExit16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgExit16;
