import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.45 12.6a.9.9 0 00-.878 1.103c.73 3.143 3.846 5.375 7.426 5.375s6.697-2.232 7.426-5.375a.9.9 0 00-.877-1.103H9.45zm6.548 4.678c-2.383 0-4.35-1.218-5.25-2.878h10.501c-.9 1.66-2.868 2.878-5.25 2.878z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.998 2C8.268 2 2 8.267 2 15.998c0 7.731 6.267 13.999 13.998 13.999 7.731 0 13.999-6.268 13.999-13.999C29.997 8.268 23.729 2 15.998 2zM3.8 15.998C3.8 9.261 9.261 3.8 15.998 3.8s12.199 5.461 12.199 12.198-5.462 12.199-12.199 12.199S3.8 22.735 3.8 15.998z"
      fill="#000"
    />
    <path
      d="M7.502 22.05a.75.75 0 10-1.5 0v2.25H4.75a.75.75 0 100 1.5h1.252v2.25a.75.75 0 001.5 0V25.8h1.252a.75.75 0 000-1.5H7.502v-2.25z"
      fill="#1F77F9"
    />
    <path
      d="M25.502 19.05a.75.75 0 10-1.5 0v2.25H22.75a.75.75 0 100 1.5h1.252v2.25a.75.75 0 001.5 0V22.8h1.252a.75.75 0 000-1.5h-1.252v-2.25z"
      fill="#28D898"
    />
    <path
      d="M11.28 1.745a2.88 2.88 0 013.527 2.037c.68 2.539-3.447 7.26-3.447 7.26S5.458 9.137 4.745 6.477c-.512-1.913.5-3.116 2.037-3.527a2.874 2.874 0 012.556.546 2.875 2.875 0 011.941-1.752zM25.608 3.25a2.88 2.88 0 012.037 3.527c-.68 2.54-6.614 4.564-6.614 4.564s-4.16-4.599-3.447-7.26c.512-1.912 1.991-2.448 3.527-2.036.926.248 1.621.92 1.941 1.751a2.875 2.875 0 012.556-.546z"
      fill="#d5ff4f"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgLoveColor32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgLoveColor32;
