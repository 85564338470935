import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M28 15a9.003 9.003 0 00-8.777 7H24a1 1 0 110 2h-5v3h5a1 1 0 110 2h-5v6h18v-6h-5a1 1 0 110-2h5v-3h-5a1 1 0 110-2h4.777A9.003 9.003 0 0028 15z"
      fill="#8A38F3"
    />
    <path
      d="M18.56 8.542A18.708 18.708 0 0128 6c10.481 0 19 8.613 19 19.265a19.336 19.336 0 01-5.705 13.762 1 1 0 101.41 1.419A21.335 21.335 0 0049 25.265C49 13.533 39.61 4 28 4a20.709 20.709 0 00-10.446 2.813 1 1 0 101.006 1.729zM13.697 12.583a1 1 0 00-1.514-1.306A21.342 21.342 0 007 25.264c0 5.944 2.41 11.322 6.295 15.18a1 1 0 001.41-1.418A19.336 19.336 0 019 25.265c0-4.859 1.773-9.294 4.697-12.682z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 33v3a9.004 9.004 0 005.924 8.46L24.133 50H17a1 1 0 100 2h22a1 1 0 100-2h-7.133l-.791-5.54A9.004 9.004 0 0037 36v-3H19zm16 3v-1H21v1a7 7 0 1014 0zm-5.153 14l-.724-5.07a9.084 9.084 0 01-2.246 0L26.153 50h3.694z"
      fill="#000"
    />
    <path
      d="M28 11c-7.755 0-14 6.128-14 13.636a13.3 13.3 0 001.892 6.85 1 1 0 01-1.716 1.027A15.3 15.3 0 0112 24.636C12 15.978 19.187 9 28 9c3.635 0 6.99 1.186 9.68 3.185a1 1 0 01-1.193 1.605A14.176 14.176 0 0028 11zM40.453 16.414a1 1 0 011.371.345A15.3 15.3 0 0144 24.636a15.3 15.3 0 01-2.176 7.877 1 1 0 01-1.716-1.026A13.3 13.3 0 0042 24.637a13.3 13.3 0 00-1.892-6.851 1 1 0 01.345-1.372z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgRadioColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgRadioColor56;
