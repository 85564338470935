import React from 'react';
import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

const switchTool = (name) => {
  switch (name) {
    case '1':
      return (
        <path
          d="M25.6875 59.2623L243.822 24.9787L183.077 121.284L325.688 80.5597"
          stroke="#B0BDFF"
          strokeWidth="50"
          strokeLinecap="round"
          strokeLinejoin="bevel"
        />
      );
    case '2':
      return (
        <path
          d="M118.375 68C118.375 95.6143 95.9893 118 68.375 118C40.7607 118 18.375 95.6143 18.375 68C18.375 40.3857 40.7607 18 68.375 18"
          stroke="#d5ff4f"
          strokeWidth="35"
          strokeLinecap="round"
          strokeLinejoin="bevel"
        />
      );
    case '3':
      return (
        <path
          d="M108.922 20L20.6936 120"
          stroke="#1F77F9"
          strokeWidth="40"
          strokeLinecap="round"
          strokeLinejoin="bevel"
        />
      );
    case '4':
      return (
        <path
          d="M75 75L336 307"
          stroke="#E2BEFF"
          strokeWidth="150"
          strokeLinecap="round"
          strokeLinejoin="bevel"
        />
      );
    case '5':
      return (
        <path
          d="M75 375V75H375"
          stroke="#FFE249"
          strokeWidth="150"
          strokeLinecap="round"
          strokeLinejoin="bevel"
        />
      );
    case '6':
      return (
        <path
          d="M111.74 18C126.685 42.8683 117.816 74.6677 91.9304 89.0253C66.045 103.383 32.9449 94.8627 18 69.9942"
          stroke="#28D898"
          strokeWidth="35"
          strokeLinecap="round"
          strokeLinejoin="bevel"
        />
      );
    default:
      return null;
  }
};

const switchViewBox = (name) => {
  switch (name) {
    case '1':
      return '0 0 351 146';
    case '2':
      return '0 0 136 136';
    case '3':
      return '0 0 129 140';
    case '4':
      return '0 0 411 382';
    case '5':
      return '0 0 450 450';
    case '6':
      return '0 0 137 114';
    default:
      return '0 0 0 0';
  }
};

const Svg = styled.svg`
  ${(p) => css`
    path {
      stroke: ${themeGet(`colors.${p.$color}`, p.$color)};
      stroke-width: ${themeGet(`space[${p.$stroke}]`, p.$stroke)};
    }
  `}
`;

export default function ToolKitMove({ name, size, stroke, color, ...props }) {
  const viewBox = switchViewBox(name);
  const [, , defaultWidth, defaultHeight] = viewBox.split(' ');

  return (
    <Svg
      width={size || defaultWidth}
      height={size ? '100%' : defaultHeight}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      $color={color}
      $stroke={stroke}
      {...props}>
      {switchTool(name)}
    </Svg>
  );
}
