import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M29 16c0 7.18-5.82 13-13 13S3 23.18 3 16 8.82 3 16 3s13 5.82 13 13z"
      fill="#E2BEFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.295 13.44A.9.9 0 019 13.1h14a.9.9 0 01.877 1.103C23.105 17.53 19.803 19.9 16 19.9s-7.105-2.37-7.877-5.697a.9.9 0 01.172-.763zm1.99 1.46c.95 1.844 3.105 3.2 5.715 3.2s4.765-1.357 5.716-3.2H10.284zM11.5 9.9a.6.6 0 00-.6.6.9.9 0 11-1.8 0 2.4 2.4 0 114.8 0 .9.9 0 11-1.8 0 .6.6 0 00-.6-.6zM20.5 9.9a.6.6 0 00-.6.6.9.9 0 11-1.8 0 2.4 2.4 0 114.8 0 .9.9 0 11-1.8 0 .6.6 0 00-.6-.6z"
      fill="#000"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgSmiley32Color = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgSmiley32Color;
