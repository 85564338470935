/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { setSelectedShop } from '../../actions/course.jsx';
// Components
import Box from '../shared/atoms/Box';
import Text from '../shared/atoms/Text';
import SearchMap from '../shared/organisms/SearchMap';
import Heading from '../shared/atoms/Heading';
import ChooseRepairFormat from './ChooseRepairFormat';
// Icons
import PinGeoloc60 from '../icons/PinGeoloc60';
import PinCyclofix from '../icons/PinCyclofix';
import { readableProduct } from '../../utils/utils';

const SearchMapContainer = styled(Box)`
  height: 30vh;
  position: -webkit-relative;
  position: relative;
  margin: 0 -1.5rem;
  ${(p) => p.theme.mediaQueries.desktop} {
    height: 530px;
    margin: 0;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.xs}) {
    height: 40vh;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    max-height: 310px;
  }
`;

export default function RepairDefaultShopBookable({
  zones,
  bookable,
  isInCycloZone,
  product_id,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedShop, shops } = useSelector((s) => s.course);

  const distanceKm = (distance) => (distance / 1000).toFixed(1);
  const { t } = useTranslation();

  const goToContinueAtHome = () => {
    history.push('/reparation/symptoms/');
  };

  const goToWorkshopDates = (shop) => {
    if (selectedShop?.id !== shop.id) {
      dispatch(setSelectedShop(shop));
    }
    goToContinueAtHome();
  };

  const goToRepairAtHomeDates = () => {
    dispatch(setSelectedShop(null));
    goToContinueAtHome();
  };

  return (
    <>
      <Heading level={2} as="h1" mb={{ xs: 4, lg: 5 }}>
        {isInCycloZone
          ? t('repairdefault.bookable.heading.1')
          : t('repairdefault.bookable.heading.2', {
            produit: readableProduct(product_id),
          })}
      </Heading>
      <Text mb={4}>
        {isInCycloZone
          ? t('repairdefault.bookable.texts.1')
          : t('repairdefault.bookable.texts.2')}
      </Text>
      {isInCycloZone ? (
        <>
          <ChooseRepairFormat
            distance={t('repairdefaultshop.distances.1')}
            title={t('repairdefaultshop.titles.1')}
            subtitle={t('repairdefaultshop.subtitles.1')}
            timeInfo={t('repairdefaultshop.timeInfos.1')}
            buttonText={t('repairdefaultshop.cta.1')}
            icon={<PinGeoloc60 size={40} />}
            onClick={goToRepairAtHomeDates}
          />
          {bookable.map((shop) => {
            const { value: shopValue } = shop;
            return (
              <ChooseRepairFormat
                key={shopValue.id}
                distance={t('repairdefaultshop.distances.2', {
                  distance: distanceKm(shopValue.distance),
                })}
                title={t('repairdefaultshop.titles.2', {
                  name: shopValue.name,
                })}
                subtitle={`${t('repairdefaultshop.subtitles.2')} : ${shopValue.address
                  }`}
                timeInfo={t('repairdefaultshop.timeInfos.2')}
                buttonText={t('repairdefaultshop.cta.2')}
                onClick={() => goToWorkshopDates(shopValue)}
              />
            );
          })}
        </>
      ) : (
        <>
          {bookable.map((shop) => {
            const { value: shopValue } = shop;
            return (
              <ChooseRepairFormat
                key={shopValue.id}
                distance={t('repairdefaultshop.distances.2', {
                  distance: distanceKm(shopValue.distance),
                })}
                title={t('repairdefaultshop.titles.2', {
                  name: shopValue.name,
                })}
                subtitle={`${t('repairdefaultshop.subtitles.2')} : ${shopValue.address
                  }`}
                timeInfo={t('repairdefaultshop.timeInfos.2')}
                buttonText={t('repairdefaultshop.cta.2')}
                onClick={() => goToWorkshopDates(shopValue)}
              />
            );
          })}
        </>
      )}
      <SearchMapContainer>
        <SearchMap
          zones={zones}
          shops={bookable}
          inputSearch={'light'}
          borderRadius={{ xs: 'none', md: 'sm', lg: 'sm' }}
        />
      </SearchMapContainer>
    </>
  );
}
