import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.354 5.737a1 1 0 011.41.117l.316.374c.615.727 1.037 1.223 1.392 1.577.348.347.563.483.737.549a2 2 0 001.326.031c.176-.057.397-.183.762-.513.371-.337.816-.813 1.464-1.51l.507-.543a1 1 0 111.464 1.362l-.537.577c-.611.657-1.113 1.196-1.556 1.596-.457.415-.924.75-1.486.933a4 4 0 01-2.651-.062c-.553-.21-1.004-.567-1.441-1.003-.423-.42-.9-.983-1.479-1.668l-.345-.408a1 1 0 01.117-1.41z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgAngledown16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgAngledown16;
