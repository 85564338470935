import moment from 'moment';

/*
Returns slots with yield bonuses applied

Rules

€5 Yield

- Every day from 6 PM to 8 PM (18h-19h & 19h-20h)
- All day on Saturdays

€10 Yield

- New slots from 7 AM to 8 AM every day (7h-8h)
- From 8 PM to 10 PM (20h-21h & 21h-22h)
- All day on Sundays from 7 AM to 10 PM
 */
export default function addSlotsBonus(day, slots) {
  const slotWithYield = (slot, yieldAmount) => ({ ...slot, yieldAmount });

  return slots.map((slot) => {
    const startDate = moment(day).hour(slot.min).minute(0).second(0);

    const isBetween7and8 = slot.min >= 7 && slot.min < 8;

    if (isBetween7and8) return slotWithYield(slot, 10);

    const isBetween20and22 = slot.min >= 20 && slot.min < 22;

    if (isBetween20and22) return slotWithYield(slot, 10);

    const isSunday = startDate.day() === 0;

    if (isSunday) return slotWithYield(slot, 10);

    const isBetween18and20 = slot.min >= 18 && slot.min < 20;

    if (isBetween18and20) return slotWithYield(slot, 5);

    const isSaturday = startDate.day() === 6;

    if (isSaturday) return slotWithYield(slot, 5);

    return slotWithYield(slot, 0);
  });
}

export function yieldBonusType(date) {
  const startDate = moment(date);
  const hour = startDate.format('HH');

  const isBetween7and8 = hour >= 7 && hour < 8;

  if (isBetween7and8) {
    return 'lève-tôt';
  }

  const isBetween20and22 = hour >= 20 && hour <= 22;

  if (isBetween20and22) {
    return 'nuit';
  }

  const isSunday = startDate.day() === 0;

  if (isSunday) {
    return 'dimanche';
  }

  const isBetween18and20 = hour >= 18 && hour < 20;

  if (isBetween18and20) {
    return 'soirée';
  }

  const isSaturday = startDate.day() === 6;

  if (isSaturday) {
    return 'samedi';
  }

  return '';
}
