import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M49.333 9.72l1.91-4.574c.378-.898-.875-1.583-1.48-.811L45 10.413l2.667 1.867-1.91 4.574c-.378.898.875 1.583 1.48.811L52 11.587 49.333 9.72z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.945 34A9.001 9.001 0 014 33a9 9 0 0112.605-8.249l2.936-5.09-1.255-3.66H15.5a1 1 0 110-2h8a1 1 0 110 2h-3.1l2.362 6.89 13.798-3.807L35.69 15H32a1 1 0 110-2h5.31l1.322 6.21 2.013 5.101a9 9 0 11-1.86.735l-1.522-3.856L25.438 34h-3.493zm-2.016 0A7.001 7.001 0 016 33a7 7 0 019.597-6.502L11.269 34h8.66zm-2.6-6.501L14.73 32h5.198a6.994 6.994 0 00-2.6-4.501zm1.007-1.747A8.992 8.992 0 0121.946 32h1.825l-3.37-9.828-2.065 3.58zm5.076-.966l2.115 6.169 8.362-9.06-10.477 2.89zM43 26a7.02 7.02 0 00-1.614.187l2.544 6.446a1 1 0 01-1.86.734l-2.544-6.445A7 7 0 1043 26z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgEBikeColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgEBikeColor56;
