import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../shared/atoms/Box';
import Text from '../../shared/atoms/Text';
import { formatDate } from '../../../utils/reparation';
import { readableslots } from '../../../utils/utils';

import Pen24 from '../../icons/Pen24';
import ArrowEnterRight16 from '../../icons/ArrowEnterRight16';

const RequestCard = ({ request }) => {
  const { t } = useTranslation();
  return (
    <Box
      flexDirection="row"
      borderColor="gray.light"
      borderWidth="1px"
      borderRadius="sm"
      borderStyle="solid"
      p={4}
      mb={4}>
      <Box mr={2}>
        <Pen24 />
      </Box>
      <Box>
        <Text variant="small" color="gray.darker">
          {formatDate(request.date, t('requestcard.date'))}
        </Text>
        {request.data.position && (
          <>
            <Text fontWeight={2}>{t('requestcard.titles.1')}</Text>
            <Text mt={1}>
              <ArrowEnterRight16 mr={1} />
              {request.data.position}
            </Text>
          </>
        )}
        {request.data.slots && (
          <>
            <Text fontWeight={2}>{t('requestcard.titles.2')}</Text>
            <Box flexDirection="row" alignItems="center" mt={1}>
              <ArrowEnterRight16 mr={1} />
              {readableslots(request.data.slots)}
            </Box>
          </>
        )}
        <Text variant="small" fontStyle="italic" mt={1}>
          {t('requestcard.text')}
        </Text>
      </Box>
    </Box>
  );
};
export default RequestCard;
