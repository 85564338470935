import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import Heading from '../atoms/Heading';
import Box from '../atoms/Box';
import Container from '../atoms/Container';
import Button from '../atoms/Button';

import SvgAngleRight16 from '../../icons/AngleRight16';
import SectionBackground from '../molecules/SectionBackground';

const AppPictoContainer = styled(Box)`
  overflow: hidden;
  position: relative;
`;

const AppPicto = styled.img`
  position: relative;
  max-width: 700px;
  width: 100%;
  left: 0;
  bottom: auto;
  top: 0;
  height: auto;
  margin-bottom: -48px;
  ${(p) => p.theme.mediaQueries.tablet} {
    max-width: 520px;
  }
  ${(p) => p.theme.mediaQueries.desktop} {
    max-width: 640px;
    top: 3rem;
    bottom: -1rem;
  }
`;

export default function BecomeFixer({ ...props }) {
  const { t } = useTranslation();
  return (
    <SectionBackground bg="stone" {...props}>
      <Container>
        <Box
          flexDirection={{ xs: 'column-reverse', lg: 'row' }}
          alignItems="center">
          <AppPictoContainer
            width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}
            height={{ xs: 250, md: 360, lg: 400 }}
            pr={{ xs: 0, lg: 5 }}>
            <LazyLoad>
              <AppPicto
                src="/static/img/photos/become-fixer.png"
                alt={t('becomefixer.texts.1')}
              />
            </LazyLoad>
          </AppPictoContainer>
          <Box
            width={{ xs: 1, md: 2 / 3, lg: 1 / 2 }}
            pt={{ xs: 7, lg: 0 }}
            pl={{ xs: 0, lg: 5 }}>
            <Heading level={2} color="black">
              {t('becomefixer.texts.2')}
            </Heading>
            <Button
              mt={2}
              variant="outlinedBlack"
              after={<SvgAngleRight16 />}
              mb={{ xs: 6, lg: 0 }}
              to="/devenir-reparateur-velo"
              animation>
              {t('becomefixer.cta')}
            </Button>
          </Box>
        </Box>
      </Container>
    </SectionBackground>
  );
}
