import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Box from '../../shared/atoms/Box';
import Heading from '../../shared/atoms/Heading';
import Text from '../../shared/atoms/Text';
import TextGradiant from '../../shared/atoms/TextGradiant';
import Seasons from './Seasons';
// Icons
import ArrowSeasonBlock from '../../icons/ArrowSeasonBlock';
import { getSeason } from '../../../utils/utils';

const seasonsData = (t) => {
  return {
    SPRING: {
      photoTitle: t('seasons.titles.1'),
      photoUrl: '/static/img/photos/giftcard/image-spring.png',
      prestaTitle: t('seasons.titles.2'),
      prestaSubtitle: t('seasons.texts.1'),
      prestaPrice: '45€',
    },
    SUMMER: {
      photoTitle: t('seasons.titles.3'),
      photoUrl: '/static/img/photos/giftcard/image-summer.png',
      prestaTitle: t('seasons.titles.4'),
      prestaSubtitle: t('seasons.texts.2'),
      prestaPrice: '48€',
    },
    AUTUMN: {
      photoTitle: t('seasons.titles.5'),
      photoUrl: '/static/img/photos/giftcard/image-autumn.png',
      prestaTitle: t('seasons.titles.6'),
      prestaSubtitle: t('seasons.texts.3'),
      prestaPrice: '24€',
    },
    WINTER: {
      photoTitle: t('seasons.titles.7'),
      photoUrl: '/static/img/photos/giftcard/image-winter.png',
      prestaTitle: t('seasons.titles.8'),
      prestaSubtitle: t('seasons.texts.4'),
      prestaPrice: '39€',
    },
  };
};

const AbsoluteSeasonBox = styled(Box)`
  position: absolute;
  top: -40px;
`;

const OrangeRectangle = styled(Box)`
  position: absolute;
  background-color: ${(p) => p.theme.colors.primary.main};
  width: 100px;
  height: 125px;
  border-radius: 50px;
  transform: rotate(-135deg);
  right: -16px;
  @media (max-width: 358px) {
    top: 60px;
  }
`;

const AbsolutePrice = styled(Text)`
  position: absolute;
  bottom: 16px;
  right: 16px;
`;

const StyledGiftcard = styled.img`
  @media (min-width: ${(p) => p.theme.breakpoints.xl}) {
    object-position: 0px 36px;
  }
`;

const AnimatedImage = styled.img`
  animation: fadeIn ease 1s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const AnimatedBox = styled(Box)`
  animation: fadeIn ease 1s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const ReallyUseful = () => {
  const { t } = useTranslation();
  const currentMonth = new Date().getMonth();
  const [season, setSeason] = useState(getSeason(currentMonth));
  const seasonsDataTranslate = seasonsData(t);
  return (
    <Box mt={{ xs: 7, sm: 12 }}>
      <Box maxWidth={700} alignSelf="center" mx={5}>
        <Heading level={2} textAlign="center">
          <Trans
            i18nKey="reallyuseful.texts.1"
            components={{
              gradiant: <TextGradiant />,
            }}
          />
        </Heading>
        <Text
          textAlign="center"
          color="black"
          fontSize="xl"
          lineHeight="copy"
          fontWeight={1}>
          {t('reallyuseful.texts.2')}
        </Text>
      </Box>
      <Box
        bg="gray.lightest"
        borderRadius={{ xs: null, md: 'lg' }}
        px={{ xs: 20, md: 177 }}
        position="relative"
        mt={100}>
        <AbsoluteSeasonBox
          bg="white"
          borderRadius={{ xs: 'sm', md: 'xl' }}
          alignSelf="center"
          boxShadow="md"
          pt={3}
          pb={2}
          width={{ xs: '92%', md: 'auto' }}
          px={{ xs: 4, sm: 9 }}>
          <Seasons season={season} setSeason={setSeason} />
        </AbsoluteSeasonBox>
        <Box
          flexDirection={{ xs: 'column-reverse', xl: 'row' }}
          mt={{ xs: 80, xl: 140 }}>
          <Box mr={{ xs: 0, xl: 12 }} mt={{ xs: 7, xl: 0 }} flex={1}>
            <Box
              flexDirection="row"
              justifyContent={{ xs: 'space-between', xl: 'start' }}
              maxWidth={{ xs: '100%', xl: 400 }}
              mt="auto"
              mb={4}>
              <Text fontSize="lg" fontWeight={2} color="black" mr={1}>
                {seasonsDataTranslate[season].photoTitle}
              </Text>
              <ArrowSeasonBlock mt={3} />
            </Box>
            <AnimatedImage
              key={season}
              src={seasonsDataTranslate[season].photoUrl}
            />
          </Box>
          <Box flex={1} mt={{ xs: 4, xl: 0 }}>
            <AnimatedBox
              key={season}
              bg="white"
              borderRadius="xs"
              boxShadow="sm"
              p={4}
              position="relative"
              overflow="hidden"
              mb={{ xs: 5, xl: -4 }}>
              <Box maxWidth="70%">
                <Heading level={6}>
                  {seasonsDataTranslate[season].prestaTitle}
                </Heading>
                <Text>{seasonsDataTranslate[season].prestaSubtitle}</Text>
              </Box>
              <OrangeRectangle />
              <AbsolutePrice color="white" fontSize="xxxl" fontWeight={2}>
                {seasonsDataTranslate[season].prestaPrice}
              </AbsolutePrice>
            </AnimatedBox>
            <StyledGiftcard src="/static/img/pictos/giftcard/gift-card-1.png" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReallyUseful;
