import React, { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
// Icons
import SvgTimes24 from '../components/icons/Times24';

const duration = 300;

const widthModal = {
  small: '550px',
  medium: '720px',
  large: '960px',
};

const ModalWrapper = styled.div`
  background: ${(p) => p.theme.colors.white};
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none; /* Firefox */
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  max-height: 100%;
  width: 100%;
  transform: none;
  padding: 24px;
  padding-top: ${(p) => (p.hasCloseAction ? '24px' : '80px')};
  position: absolute;
  border-radius: none;
  overflow-y: ${(p) => p.overflow};
  ${(p) =>
    css`
      ${p.theme.mediaQueries.tablet} {
        border-radius: ${p.theme.radii.sm};
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        height: auto;
        width: ${p.width ? p.width : 'auto'};
        overflow-y: ${(p) => p.overflow};
        outline: none;
        padding-top: ${(p) => (p.hasCloseAction ? '24px' : '80px')};
        transform: translate(-50%, -50%);
      }
    `}
  ${(p) =>
    p.width === '960px' &&
    css`
      @media (max-width: ${p.theme.breakpoints.lg}) {
        width: 100%;
      }
      @media (max-width: ${p.theme.breakpoints.md}) {
        height: 100%;
      }
      @media (max-width: ${p.theme.breakpoints.sm}) {
        padding-top: 72px !important;
      }
    `};
`;

const transition = css`
  transition: opacity ${duration}ms;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${(p) => p.zIndex};
  background-color: rgba(0, 0, 0, 0.7);
  &.modal-transition-enter {
    opacity: 0;
  }
  &.modal-transition-enter-active {
    ${transition}
    opacity: 1;
  }
  &.modal-transition-exit {
    opacity: 1;
  }
  &.modal-transition-exit-active {
    ${transition}
    opacity: 0;
  }
`;

const CloseModal = styled(SvgTimes24)`
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
  ${(p) => css`
    ${p.theme.mediaQueries.tablet} {
      right: 24px;
      top: 24px;
    }
    ${p.theme.mediaQueries.desktop} {
      right: 24px;
      top: 24px;
    }
  `}
`;

const Modal = ({
  hasCloseAction = true,
  open,
  zIndex = 1002,
  width,
  onRequestClose,
  overflow = 'auto',
  children,
}) => {
  const isFirstRun = useRef(true);

  const _overlayClick = (event) => {
    if (event.target === event.currentTarget) {
      event.preventDefault();
      onRequestClose();
    }
  };

  const _blockViewport = () => {
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
  };

  const _unblockViewport = () => {
    document.body.style.overflow = 'visible';
    document.body.style.height = 'initial';
  };

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    }
    if (open) {
      _blockViewport();
    }
    return () => {
      _unblockViewport();
    };
  }, [open]);

  return (
    <CSSTransition
      in={open}
      className="modal-transition"
      classNames="modal-transition"
      unmountOnExit
      timeout={duration}>
      <ModalOverlay onClick={_overlayClick} zIndex={zIndex}>
        <ModalWrapper
          hasCloseAction
          className="modal-wrapper"
          overflow={overflow}
          width={widthModal[width]}>
          {hasCloseAction && (
            <CloseModal color="black" onClick={onRequestClose} />
          )}
          {children}
        </ModalWrapper>
      </ModalOverlay>
    </CSSTransition>
  );
};

Modal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

export default Modal;
