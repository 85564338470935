import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import Box from '../shared/atoms/Box';
import Heading from '../shared/atoms/Heading';
import Link from '../shared/atoms/Link';
import Text from '../shared/atoms/Text';
import PressReleaseElement from './PressReleaseElement';
// Icons
import AngleLeft16 from '../icons/AngleLeft16';
// Pdf
import pressRelease from './pressRelease';

const StyledBox = styled(Box)`
  position: sticky;
  top: 100px;
  align-self: flex-start;
`;

const groupBy = (arr) => {
  return arr.reduce((acc, cur) => {
    const date = cur.date.split(' ')[1];
    acc[date] = [...(acc[date] || []), cur];
    return acc;
  }, {});
};

const PressRelease = () => {
  const { t } = useTranslation();
  const getPressByYear = groupBy(pressRelease);
  const years = pressRelease.reduce((acc, press) => {
    const date = press.date.split(' ')[1];
    if (!acc.find((element) => element === date)) {
      acc.push(date);
    }
    return acc;
  }, []);
  const [onChange, setOnChange] = useState(years ? years[0] : null);
  return (
    <Box bg="transparent" flexDirection={{ xs: 'column', md: 'row' }} my={7}>
      <Box style={{ flex: 2 }}>
        <Link
          to="/presse"
          color="blue.electric"
          before={<AngleLeft16 />}
          mb={4}>
          {t('displaypressrelease.cta')}
        </Link>
        <StyledBox mr={{ xs: 24, lg: 12 }}>
          <Box bg="gray.lightest" borderRadius="sm" p={5} mb={5}>
            <Heading level={5} color="black">
              {t('displaypressrelease.heading')}
            </Heading>
            {years.map((year, index) => (
              <Link
                key={index}
                color={year === onChange ? 'blue.electric' : 'black'}
                mb={2}
                onClick={() => setOnChange(year)}>
                {year}
              </Link>
            ))}
          </Box>
          <Text mt={2}>
            {t('displaypressrelease.texts.1')}
            <Link
              href="mailto:clement@roulezjeunesse.com"
              color="blue.electric"
              underline
              target="_blank">
              clement@roulezjeunesse.com
            </Link>
          </Text>
        </StyledBox>
      </Box>
      <Box style={{ flex: 4 }}>
        {getPressByYear[onChange].map((press, index) => {
          const lastIndex = getPressByYear[onChange].length - 1;
          return (
            <PressReleaseElement
              href={press.href}
              title={press.title}
              date={press.date}
              key={index}
              last={index === lastIndex}
            />
          );
        })}
      </Box>
    </Box>
  );
};
export default PressRelease;
