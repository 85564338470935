import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '../shared/atoms/Box';
import Modal from '../../modals/Modal';

const BooxiModal = ({ booxiApiKey, onClose }) => {
  useEffect(() => {
    const handler = (event) => {
      if (event.data === 'bookClose' || event.data === 'bookClose_booked') {
        onClose();
      }
    };

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, [onClose]);

  const [showModal, setShowModal] = useState(true);

  return (
    <Modal
      hasCloseAction={false}
      width="small"
      open={showModal}
      onRequestClose={() => {
        setShowModal(false);
        onClose();
      }}>
      <Box>
        <iframe
          frameBorder="0"
          height={500}
          src={`https://site.booxi.eu/book_v2.html?key=${booxiApiKey}&remote_origin=${window.location.origin.toString()}`}
        />
      </Box>
    </Modal>
  );
};

export default BooxiModal;
