import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { SSPRClientProvider } from 'react-sspr';
import { ThemeProvider } from 'styled-components';
import ScrollToTopOnMount from './components/ScrollToTopOnMount.jsx';
import PartenaireContext from './contexts/PartenaireContext';
import LandingSignupRoutes from './LandingSignupRoutes.jsx';
import Routes from './routes.jsx';
import configureStore from './utils/configureStore';
import CoursesProvider from './contexts/CoursesContext';
import ChatProvider from './contexts/ChatContext';
import ToastProvider from './contexts/ToastContext';
import ModalProvider from './contexts/ModalContext';

import theme from './styles/theme';
import { NavigatorContextProvider } from './hooks/useNavigatorPosition';
import FeaturesProvider from './contexts/FeaturesContext';
import { getDomain } from './utils/utils';

const storeParameters = { ssr: false };
if (window) {
  if (getDomain().domain === 'uk') {
    storeParameters.forceLang = 'en';
  }
}
const store = configureStore(storeParameters);

function setTrackingQueryParams() {
  if (window) {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('tracking')) {
      return urlParams.get('tracking');
    }
  }
  return null;
}

export function LandingApp({ subdomain }) {
  const tracking = setTrackingQueryParams();

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <ToastProvider>
          <ModalProvider>
            <ChatProvider>
              <PartenaireContext.Provider
                value={{ partenaire: subdomain, tracking, cobranding: false }}>
                <LandingSignupRoutes partenaire={subdomain} />
              </PartenaireContext.Provider>
            </ChatProvider>
          </ModalProvider>
        </ToastProvider>
      </Provider>
    </ThemeProvider>
  );
}

function App({ partenaire = '' }) {
  const tracking = setTrackingQueryParams();

  return (
    <ThemeProvider theme={theme}>
      <FeaturesProvider>
        <Provider store={store}>
          <CoursesProvider>
            <NavigatorContextProvider>
              <SSPRClientProvider>
                <PartenaireContext.Provider
                  value={{ partenaire, tracking, cobranding: !!partenaire }}>
                  <Router>
                    <ToastProvider>
                      <ModalProvider>
                        <ChatProvider>
                          <ScrollToTopOnMount>
                            <Routes />
                          </ScrollToTopOnMount>
                        </ChatProvider>
                      </ModalProvider>
                    </ToastProvider>
                  </Router>
                </PartenaireContext.Provider>
              </SSPRClientProvider>
            </NavigatorContextProvider>
          </CoursesProvider>
        </Provider>
      </FeaturesProvider>
    </ThemeProvider>
  );
}

export default App;
