import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
// Components
import Box from '../shared/atoms/Box';
import Heading from '../shared/atoms/Heading';
import Line from '../shared/atoms/Line';
import Link from '../shared/atoms/Link';
import Alert from '../shared/molecules/Alert';
import ReferralRequest from './ReferralRequest';
import ShopItem from './ShopItem';

const DisplayShop = ({
  shops,
  refs,
  indexShopClick,
  annuaire = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const today = moment(new Date()).locale('en').format('ddd').toLowerCase();
  const isInZone = useSelector((state) => state.course.isInZone);

  return (
    <Box mt={7} {...rest}>
      {!isInZone && (
        <Alert status="danger" mb={6} borderRadius="xs">
          {t('displayshop.texts.1')}
          <br />
          <Trans
            i18nKey="displayshop.texts.2"
            components={{
              annotation: <u />,
              url: <Link to={'/reparateur-a-domicile'} color="primary.darkest" />,
            }}
          />
          <br />- {t('displayshop.texts.3')}
        </Alert>
      )}
      <Box flexDirection="row">
        <Heading level={2}>
          {t('displayshop.texts.4', {
            count: shops.length,
          })}
        </Heading>
        <Line
          color="gray.main"
          property="solid"
          style={{ marginTop: '22px', marginLeft: '4px' }}
        />
      </Box>
      {shops.map((shop, index) => {
        return (
          <ShopItem
            key={index}
            shop={shop}
            index={index}
            annuaire={annuaire}
            today={today}
            indexShopClick={indexShopClick}
            refs={refs}
          />
        );
      })}
      <ReferralRequest />
    </Box>
  );
};

export default DisplayShop;
