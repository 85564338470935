import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space, layout } from 'styled-system';

const SVG = (props) => (
  <svg
    width="90px"
    height="170px"
    viewBox="0 0 89 166"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.81 155.087a1.5 1.5 0 00.277 2.103l10.712 8.217a1.5 1.5 0 001.826-2.38l-8.027-6.157a89.905 89.905 0 004.893-1.18c5.08-1.37 12.088-3.694 19.828-7.514 15.48-7.64 33.932-21.288 45.673-45.282 11.712-23.936 13.393-49.283 12.172-68.585-.611-9.66-1.95-17.832-3.139-23.593a132.146 132.146 0 00-1.56-6.726 81.565 81.565 0 00-.663-2.387l-.01-.033-.003-.01-.001-.002s-2.393.739 0-.001a1.5 1.5 0 00-2.867.886m0 0l.002.007.008.025.032.107a78.57 78.57 0 01.6 2.167c.397 1.519.942 3.748 1.524 6.573 1.166 5.652 2.482 13.682 3.083 23.176 1.202 19.006-.472 43.776-11.873 67.077-11.373 23.243-29.243 36.477-44.306 43.911-7.532 3.717-14.351 5.978-19.281 7.308-1.59.429-2.982.76-4.137 1.013l5.834-7.605a1.501 1.501 0 00-2.38-1.826L.81 155.087"
      fill="#1D1D1D"
    />
  </svg>
);

const SIZE = 180;
const CAN_SET_COLOR = true;
const SvgArrowPersona3 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space};
  ${layout};
`;
export default SvgArrowPersona3;
