import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.368 6C16 6 6 15.865 6 28s10 22 22.368 22c10.433 0 19.185-7.022 21.665-16.503a1 1 0 011.934.506C49.26 44.362 39.714 52 28.369 52 14.925 52 4 41.27 4 28S14.925 4 28.368 4c2.662 0 5.225.42 7.625 1.199a1 1 0 11-.616 1.902A22.688 22.688 0 0028.368 6z"
      fill="#000"
    />
    <path
      d="M31.002 10a1 1 0 10-2 0v2H28a1 1 0 100 2h1.002v2a1 1 0 102 0v-2h1.002a1 1 0 100-2h-1.002v-2z"
      fill="#1F77F9"
    />
    <path
      d="M52.002 43a1 1 0 10-2 0v2H49a1 1 0 100 2h1.002v2a1 1 0 102 0v-2h1.002a1 1 0 100-2h-1.002v-2z"
      fill="#d5ff4f"
    />
    <path
      d="M15.002 36a1 1 0 10-2 0v2H12a1 1 0 100 2h1.002v2a1 1 0 102 0v-2h1.002a1 1 0 100-2h-1.002v-2z"
      fill="#FFE249"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.639 9.28a4.535 4.535 0 01.086 6.393L30.2 40.987 23.762 41 12.287 29.247a4.535 4.535 0 01.062-6.393 4.486 4.486 0 016.364.062l8.25 8.45L48.275 9.368a4.486 4.486 0 016.364-.087z"
      fill="#28D898"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgSuccess56Color = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgSuccess56Color;
