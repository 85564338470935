import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
// Hook
import useDeviceWidth from '../../../hooks/useDeviceWidth';
// Components
import Box from '../atoms/Box';
import Text from '../atoms/Text';
import Heading from '../atoms/Heading';
import ToolkitFix from '../ToolkitFix';
import SectionTitle from './SectionTitle';
import SectionBackground from './SectionBackground';
import Container from '../atoms/Container';
import Button from '../atoms/Button';
// Icons
import AngleRight16 from '../../icons/AngleRight16';

const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledBox = styled(Box)`
  flex: 1;
`;

const StyledToolkitWrench = styled(ToolkitFix)`
  position: relative;
  left: -38px;
  top: -49px;
  transform: rotate(45deg);
`;

const StyledToolkitCircle = styled(ToolkitFix)`
  transform: rotate(45deg);
`;

const StyledContainerTool = styled.div`
  margin-top: 58px;
  margin-left: 20px;
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    margin-bottom: 16px;
    margin-left: 0px;
  }
`;

const WeTellYouEverything = ({
  values,
  home = false,
  product = false,
  giftcard = false,
  ...rest
}) => {
  const { isMobile } = useDeviceWidth();
  const { t } = useTranslation();
  return (
    <SectionBackground {...rest}>
      <Container>
        <Box flexDirection={{ xs: 'column', md: 'row' }}>
          <StyledBox pr={{ xs: 0, lg: 10 }} width={{ xs: 1, lg: 1 / 2 }}>
            {home && (
              <StyledDiv>
                <SectionTitle color="blue.main" tool="spring">
                  {t('"wetellyoueverything')}
                </SectionTitle>
              </StyledDiv>
            )}
            <Heading color="black" level={2} mt={2} mb={{ xs: 2, md: 5 }}>
              {giftcard ? (
                t('wetellyoueverything.texts.1')
              ) : (
                <span>
                  <Trans i18nKey="wetellyoueverything.texts.2" />
                </span>
              )}
            </Heading>
            {home ||
              product ||
              (giftcard && (
                <Button
                  mb={5}
                  variant="outlinedBlack"
                  href="https://faq.roulezjeunesse.com/"
                  after={<AngleRight16 />}
                  animation>
                  {t('faq.cta')}
                </Button>
              ))}
            <StyledContainerTool>
              <StyledToolkitCircle
                name="circle"
                color="blue.main"
                size={isMobile ? '88px' : '9em'}
              />
              <StyledToolkitWrench
                name="wrench"
                color="violet.lighter"
                size={isMobile ? '88px' : '9em'}
              />
            </StyledContainerTool>
          </StyledBox>
          <StyledBox pl={{ xs: 0, lg: 5 }} width={{ xs: 1, lg: 1 / 2 }}>
            {values.map((value, index) => (
              <Box mb={5} key={index}>
                <Text color="black" variant="large" fontWeight="bold" mb={2}>
                  {product || giftcard ? null : <>{index + 1}.&nbsp;</>}
                  {value.title}
                </Text>
                <Text>{value.text}</Text>
              </Box>
            ))}
          </StyledBox>
        </Box>
      </Container>
    </SectionBackground>
  );
};

export default WeTellYouEverything;
