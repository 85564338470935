import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path fill="#fff" d="M0 0h64v64H0z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 7.5C18.469 7.5 7.5 18.469 7.5 32S18.469 56.5 32 56.5 56.5 45.531 56.5 32 45.531 7.5 32 7.5zM4.5 32C4.5 16.812 16.812 4.5 32 4.5S59.5 16.812 59.5 32 47.188 59.5 32 59.5 4.5 47.188 4.5 32z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.67 25.922a3.649 3.649 0 11.001 7.297 3.649 3.649 0 010-7.297zm6.65 3.649a6.649 6.649 0 10-13.298 0 6.649 6.649 0 0013.297 0zM39.565 14.341a1.5 1.5 0 011.5 1.5v3.433a1.5 1.5 0 11-3 0V15.84a1.5 1.5 0 011.5-1.5zM23.776 14.341a1.5 1.5 0 011.5 1.5v3.433a1.5 1.5 0 01-3 0V15.84a1.5 1.5 0 011.5-1.5z"
      fill="#000"
    />
    <path
      d="M45.073 40.12a3.5 3.5 0 003.253-3.71v-.016a8.217 8.217 0 01.028-.873c.052-.628.184-1.394.463-2.103.274-.7.642-1.225 1.105-1.578.426-.324 1.172-.688 2.57-.688 1.244 0 2.214.39 2.82.921.547.478 1.004 1.238 1.004 2.534 0 2.265-1.519 3.403-2.716 4.3l-.34.256-.037.029-.037.03c-1.234 1.002-2.514 2.215-3.35 4.13-.792 1.815-1.035 3.914-1.035 6.434a3.5 3.5 0 107 0c0-2.179.233-3.134.45-3.633.172-.393.433-.749 1.3-1.459l.14-.098c1.03-.714 5.625-3.904 5.625-9.989 0-3.215-1.255-5.932-3.396-7.804-2.08-1.82-4.773-2.65-7.429-2.65-2.74 0-5.022.757-6.808 2.116-1.748 1.331-2.778 3.05-3.383 4.593-.602 1.535-.832 3.014-.922 4.068a15.212 15.212 0 00-.047 1.73c.001.053.003.096.005.131l.003.046.001.017v.011c.001.002.001.004 3.39-.23l-3.389.234a3.5 3.5 0 003.732 3.25zM52.265 62a3.09 3.09 0 110-6.178 3.09 3.09 0 010 6.178z"
      fill="#FFE249"
    />
    <path
      d="M9.758 5.714a1.5 1.5 0 10-2.306 1.918l2.198 2.643a1.5 1.5 0 102.306-1.919L9.758 5.714zM14.077 2.882a1.5 1.5 0 00-1.359 1.63l.27 2.973a1.5 1.5 0 002.987-.27l-.27-2.974a1.5 1.5 0 00-1.628-1.359zM3.963 11.401a1.5 1.5 0 011.84-1.054l2.793.759A1.5 1.5 0 117.809 14l-2.792-.759a1.5 1.5 0 01-1.054-1.84z"
      fill="#FFBBB3"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgSurprised64 = styled(SVG)`
  min-height: ${(p) => p.size || SIZE}px;
  min-width: ${(p) => p.size || SIZE}px;
  max-height: ${(p) => p.size || SIZE}px;
  max-width: ${(p) => p.size || SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgSurprised64;
