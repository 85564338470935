import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#Surprised_face_Color_56_svg__clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 29a7 7 0 11-14 0 7 7 0 0114 0zm-2 0a5 5 0 11-10 0 5 5 0 0110 0z"
        fill="#000"
      />
      <path
        d="M36 14a1 1 0 011 1v3a1 1 0 11-2 0v-3a1 1 0 011-1zM20 15a1 1 0 10-2 0v3a1 1 0 102 0v-3z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52 28c0 13.255-10.745 24-24 24S4 41.255 4 28 14.745 4 28 4s24 10.745 24 24zm-2 0c0 12.15-9.85 22-22 22S6 40.15 6 28 15.85 6 28 6s22 9.85 22 22z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.003 33.28a2.5 2.5 0 01-4.99.323l2.495-.17c-2.494.17-2.494.168-2.495.167v-.02l-.003-.034a8.978 8.978 0 01-.01-.403c0-.25.008-.594.041-1 .067-.8.24-1.918.686-3.076.449-1.163 1.212-2.458 2.51-3.462C42.563 24.58 44.267 24 46.33 24c1.997 0 4.01.636 5.556 2.01 1.587 1.41 2.512 3.449 2.512 5.862 0 4.566-3.386 6.967-4.188 7.536a6.65 6.65 0 00-.116.083c-.67.556-.896.855-1.047 1.205-.184.429-.362 1.206-.362 2.902a2.5 2.5 0 01-5 0c0-1.935.184-3.517.768-4.876.616-1.432 1.559-2.345 2.491-3.115l.026-.021.027-.021c.081-.064.168-.129.257-.197.903-.685 2.145-1.626 2.145-3.496 0-1.07-.377-1.718-.833-2.124-.496-.44-1.268-.748-2.236-.748-1.084 0-1.68.287-2.035.561-.384.297-.683.736-.903 1.306a6.246 6.246 0 00-.368 1.691 6.73 6.73 0 00-.022.704l.001.018zm0 0z"
        fill="#FFE249"
      />
      <path
        d="M46.2 52.665a2.332 2.332 0 110-4.663 2.332 2.332 0 010 4.663z"
        fill="#FFE249"
      />
      <path
        d="M12.04.004a1 1 0 00-.905 1.086l.34 3.761a1 1 0 001.993-.18L13.127.91a1 1 0 00-1.086-.906zM.035 10.12a1 1 0 011.228-.703l3.53.96a1 1 0 11-.524 1.93l-3.531-.96a1 1 0 01-.703-1.227zM5.81 3.265a1 1 0 00-1.538 1.28l2.78 3.341a1 1 0 001.537-1.279L5.81 3.265z"
        fill="#FFBBB3"
      />
    </g>
    <defs>
      <clipPath id="Surprised_face_Color_56_svg__clip0">
        <path fill="#fff" d="M0 0h56v56H0z" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgSurprisedfaceColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgSurprisedfaceColor56;
