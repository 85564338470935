import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { to2, uniquePrestaPiece } from '../../utils/utils';
import { readableHour } from '../../utils/reparation';
// Components
import Box from '../shared/atoms/Box';
import Text from '../shared/atoms/Text';
import Heading from '../shared/atoms/Heading';
import Separator from '../Separator';
import { yieldBonusType } from '../../utils/slots';

const CourseBill = ({ course }) => {
  const { t } = useTranslation();
  const prestations = Object.values(uniquePrestaPiece(course.prestations));
  const pieces = Object.values(uniquePrestaPiece(course.pieces));

  return (
    <Box mb={9}>
      <Box
        p={{ xs: 4, lg: 5 }}
        borderColor="gray.light"
        borderWidth="1px"
        borderRadius="md"
        borderStyle="solid">
        <Heading level={5} mb={5}>
          {t('intervention')} #{course.id}
        </Heading>
        <Box mb={5}>
          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}>
            <Text>{t('dated')}</Text>
            <Text>
              {moment(course.fin_date || course.date).format('DD MMMM YYYY')} à{' '}
              {readableHour(course.date)}
            </Text>
          </Box>
          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between">
            <Text>{t('coursebill.text.1')}</Text>
            <Text>{course.fixer.prenom}</Text>
          </Box>
        </Box>
        {!!prestations.length && (
          <Box>
            <Text fontWeight="bold" color="black">
              {t('coursebill.text.2')}
            </Text>
            <Separator axe="horizontal" my={4} />
          </Box>
        )}
        {prestations.map((prestation, index) => {
          return (
            <Box
              key={prestation.prestaPiece.id}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              mt={index == 0 ? 0 : 2}>
              <Box flexDirection="row" alignItems="start">
                <Box
                  borderColor="gray.light"
                  borderWidth="1px"
                  borderRadius="xxs"
                  borderStyle="solid"
                  width={21}
                  height={21}
                  alignItems="center"
                  justifyContent="center"
                  mr={2}>
                  {prestation.nb}
                </Box>
                <Text>{prestation.prestaPiece.nom}</Text>
              </Box>
              <Box ml={4}>
                <Text>{to2(prestation.prestaPiece.prix * prestation.nb)}€</Text>
              </Box>
            </Box>
          );
        })}
        {!!pieces.length && (
          <Box>
            <Separator axe="horizontal" my={4} />
            <Text fontWeight="bold" color="black">
              {t('coursebill.text.3')}
            </Text>
            <Separator axe="horizontal" my={4} />
          </Box>
        )}
        {pieces.map((prestation, index) => {
          return (
            <Box
              key={prestation.prestaPiece.id}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              mt={index == 0 ? 0 : 2}>
              <Box flexDirection="row" alignItems="center">
                <Box
                  borderColor="gray.light"
                  borderWidth="1px"
                  borderRadius="xxs"
                  borderStyle="solid"
                  width={21}
                  height={21}
                  alignItems="center"
                  justifyContent="center"
                  mr={2}>
                  {prestation.nb}
                </Box>
                <Text>{prestation.prestaPiece.modele}</Text>
              </Box>
              <Box ml={4}>
                <Text>
                  {to2(prestation.prestaPiece.prix_vente * prestation.nb)}€
                </Text>
              </Box>
            </Box>
          );
        })}
        {!!course.fee_service && (
          <Box flexDirection="row" justifyContent="space-between">
            <Text>Frais de service</Text>
            <Text fontWeight={2}>{course.fee_service}€</Text>
          </Box>
        )}
        {!!course.fee_delivery && (
          <Box flexDirection="row" justifyContent="space-between">
            <Text>Frais de déplacement</Text>
            <Text fontWeight={2}>{course.fee_delivery}€</Text>
          </Box>
        )}
        {!!course.yield_bonus && (
          <Box flexDirection="row" justifyContent="space-between">
            <Text>Supplément créneau {yieldBonusType(course.date)}</Text>
            <Text fontWeight={2}>{course.yield_bonus}€</Text>
          </Box>
        )}
        <Text textAlign="right" mt={4} color="black">
          {t('coursebill.text.5')} :{' '}
          <b>{to2(course.prix + course.reduction)}€</b>
        </Text>
        <Box>
          {!!course.reduction && (
            <>
              <Separator axe="horizontal" my={4} />
              <Box flexDirection="row" justifyContent="space-between">
                <Text>{t('coursebill.text.6')}</Text>
                <Text fontWeight={2} color="black">
                  - {course.reduction}€
                </Text>
              </Box>
            </>
          )}
        </Box>
        <Separator axe="horizontal" my={4} />
        <Box
          flexDirection={{ xs: 'column-reverse', lg: 'row' }}
          justifyContent="flex-end">
          <Text color="black" fontSize="lg">
            {t('coursebill.text.4')} : <b>{course.prix}€</b>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default CourseBill;
