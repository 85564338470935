import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isABVariant, isDev, getEstimatedDuration } from '../../utils/utils';
import * as api from '../../utils/api';
// Hooks
import useAlert from '../../hooks/useModal';
// Components
import Box from '../shared/atoms/Box';
// Icons
import LightBulbColor32 from '../icons/LightBulbColor32';
// Utils
import daysWithOptiv from './utils/daysWithOptiv';
import {
  disabledAgendaCheckbox,
  valueAgendaCheckBox,
} from './utils/agendaCheckBox';
import DateAndAllSelect from './DateAndAllSelect';
import ListSlots from './ListSlots';
import SelectedSlots from './SelectedSlots';
import HelpBox from './HelpBox';
import DateTimeModuleSkeleton from './DateTimeModuleSkeleton';
import MoreSlotsModal from '../../modals/MoreSlotsModal';
import Link from '../shared/atoms/Link';
import Separator from '../Separator';
import useEstimatedDuration from '../../hooks/useEstimatedDuration';
import addSlotsBonus, { yieldBonusType } from '../../utils/slots';
import { useCurrentZone } from '../../hooks/useZones';

const StyledContainer = styled(Box)`
  border-radius: 16px;
  width: calc(50% - 12px);
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    width: 100%;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const DateTimeModuleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  .item-container {
    width: 33%;
    padding: 0 0.5rem 0.5rem 0.5rem;
    @media (max-width: ${(p) => p.theme.breakpoints.md}) {
      width: 50%;
    }
  }
  .dateTimeModule-link {
    white-space: nowrap;
    cursor: pointer;
  }
`;

const DateTimeModule = ({
  fixed,
  slots,
  toggleSlot,
  position,
  product_id,
  fixerId,
  shopId,
  noDispoModalOpen,
  toContinue = true,
  capabilities,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const estimated_duration = useEstimatedDuration();

  const [days, setDays] = useState([]);
  const [basicDays, setBasicDays] = useState([]);
  const [averageOptiv, setAverageOptiv] = useState(null);
  const [loading, setLoading] = useState(true);
  const [moreSlots, setMoreSlots] = useState(false);
  const available_min = false;
  const available_max = false;
  const history = useHistory();
  const showModal = useAlert();
  const zone = useCurrentZone();

  const fetchConfigs = async (position, product_id, fixerId) => {
    if (!position || !product_id) {
      setLoading(false);
      return;
    }

    const MAX_DAYS = isDev() ? 10 : 6;
    const dateFrom = moment().format('YYYY-MM-DD');
    const dateTo = moment()
      .add(MAX_DAYS - 1, 'day')
      .endOf('day')
      .format('YYYY-MM-DD');
    try {
      const opts = {
        position,
        date: { from: dateFrom, to: dateTo },
        max: MAX_DAYS,
        product_id,
        capabilities,
      };
      if (fixerId) {
        opts.fixer_id = fixerId;
      } else if (shopId) {
        opts.shop_id = shopId;
      }
      if (estimated_duration > 0) {
        opts.estimated_duration = estimated_duration;
      }
      const days = await api.get('/zones/days', opts);

      if (days?.error_message) {
        setLoading(false);
        history.push('/');
        showModal({
          title: t('error'),
          content: days?.error_message,
          showModal: true,
          icon: <LightBulbColor32 />,
          showButton: [
            {
              text: t('close.cta'),
              color: 'blue.electric',
            },
          ],
        });
        return;
      }

      // no availabilities in the next days
      if (!Object.keys(days).length) {
        setLoading(false);
        noDispoModalOpen(true);
        return;
      }

      const minDate = Object.keys(days)[0]; // select firstDate
      if (
        !isDev() &&
        typeof window !== 'undefined' &&
        typeof window.dataLayer !== 'undefined'
      )
        window.dataLayer.push({
          event: 'availabilities',
          daydiff:
            moment(minDate)
              .startOf('day')
              .diff(moment().startOf('day'), 'days') + 1,
        });

      const HOUR_MIN = 7;
      const HOUR_MAX = 22;

      const newDays = {};

      // converts the openv vector to user-friendly time slots
      Object.keys(days)
        .slice(0, 6)
        .forEach((dID) => {
          const day = days[dID];
          day.slots = [];
          // loop on the 96 items
          for (let idx = 0; idx < day.openv.length; idx++) {
            const min = Math.floor(idx / 4);
            const max = Math.floor(idx / 4) + 1;

            if (min < HOUR_MIN || max > HOUR_MAX) continue;
            // check every quarter of an hour
            const quarterOfHourFree = {
              0: day.openv[4 * min],
              15: day.openv[4 * min + 1],
              30: day.openv[4 * min + 2],
              45: day.openv[4 * min + 3],
            };
            // Check if 1 in quarters
            if (day.openv[idx] > 0) {
              day.slots.push({
                min,
                max,
                ...quarterOfHourFree,
                full: false,
              });
              // add the missing quarters of an hour to spend a next hour
              idx = Math.floor(idx / 4) * 4 + 3;
            } else if ((idx + 1) % 4 === 0) {
              // a 0 in a 4th item ("Case") means the slot is full
              day.slots.push({
                min,
                max,
                ...quarterOfHourFree,
                full: true,
              });
            }
          }

          newDays[dID] = day;
        });

      // looks for slots in global state that are non longer available
      const deletableSlots = slots.filter((slot) => {
        const momentSlot = moment(slot.available_min);
        const daySlot = momentSlot.format('YYYY-MM-DD');
        const hourSlot = parseInt(momentSlot.format('H'));

        if (!newDays.hasOwnProperty(daySlot)) return true;
        return !newDays[daySlot].slots.some(
          (newDaysSlot) => newDaysSlot.min === hourSlot && !newDaysSlot.full,
        );
      });

      deletableSlots.forEach((deletableSlot) =>
        toggleSlot(deletableSlot.available_min, deletableSlot.available_max),
      );
      setBasicDays(newDays);
      setLoading(false);
    } catch (e) {
      history.push('/');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConfigs(position, product_id, fixerId);
  }, [position, product_id, fixerId]);

  useEffect(() => {
    daysWithOptiv({ setAverageOptiv, setDays, basicDays });
  }, [basicDays]);

  const openModal = ({ title, content, type = 'default' }) => {
    if (type === 'slots') {
      setMoreSlots(true);
      return;
    }
    showModal({
      title,
      content,
      showModal: true,
      icon: <LightBulbColor32 />,
      showButton: [
        {
          text: t('close.cta'),
          color: 'blue.electric',
        },
      ],
    });
  };

  const submit = (e) => {
    e.preventDefault();

    if (slots.length === 0) {
      showModal({
        title: t('selectslots'),
        content: t(`getanappointmentfaster`, { label: 5 - slots.length }),
        showModal: true,
        showButton: [
          {
            text: t('addslots'),
            width: '200px',
          },
        ],
      });
    } else if (
      slots.some((slot) => !!yieldBonusType(slot.available_min)) &&
      zone?.id == 1
    ) {
      showModal({
        title: t('yield.warning.head'),
        content: (
          <div>
            {t(`yield.warning`)}
            <br />
            {t(`yield.warning2`)}
          </div>
        ),
        showModal: true,
        showButton: [
          {
            text: t('modify.cta'),
            variant: 'neutral',
            width: '200px',
            color: 'black',
            datatestid: 'button-modal-valid-slots',
          },
          {
            text: t('continue'),
            width: '200px',
            onClick: () =>
              onSubmit({
                date: false,
                available_min,
                available_max,
              }),
          },
        ],
      });
    } else if (slots.length < 5 && !isABVariant() && !shopId) {
      showModal({
        title: t('doyouhavemoredisponibility'),
        content: t(`getanappointmentfaster`, { label: 5 - slots.length }),
        showModal: true,
        showButton: [
          {
            text: t('continue'),
            variant: 'neutral',
            width: '200px',
            color: 'black',
            datatestid: 'button-modal-valid-slots',
            onClick: () =>
              onSubmit({
                date: false,
                available_min,
                available_max,
              }),
          },
          {
            text: t('addslots'),
            width: '200px',
          },
        ],
      });
    } else {
      onSubmit({
        date: false,
        available_min,
        available_max,
      });
    }
  };

  const fullSelect = (dayID, slots, value) => {
    const day = moment(moment(days[dayID].date));
    const restartCheck = slots.filter((slot) => !slot.checked && !slot.full);

    slots.forEach((slot) => {
      const isChecked = value || !slot.checked;
      if (isChecked && !slot.full && !!restartCheck)
        toggleSlot(
          day.set({ hour: slot.min }).format('YYYY-MM-DD HH:00:00'),
          day.set({ hour: slot.max }).format('YYYY-MM-DD HH:00:00'),
        );
    });
  };

  return (
    <div>
      <MoreSlotsModal
        open={moreSlots}
        close={() => setMoreSlots(false)}
        days={days}
      />
      {loading && <DateTimeModuleSkeleton />}
      {!!days && !loading && (
        <DateTimeModuleWrapper>
          {Object.keys(days)
            .slice(0, 6)
            .map((dayID, index) => {
              const day = moment(days[dayID].date);
              const { slots: daySlots } = days[dayID];
              let checkedSlots = daySlots.map((slot) => {
                const checked = slots.find(
                  (s) =>
                    s.available_min ===
                    day
                      .set({ hour: slot.min })
                      .format('YYYY-MM-DD HH:00:00') &&
                    s.available_max ===
                    day.set({ hour: slot.max }).format('YYYY-MM-DD HH:00:00'),
                );
                return { ...slot, checked: !!checked };
              });
              if (zone?.id == 1) {
                checkedSlots = addSlotsBonus(day, checkedSlots);
              }
              const amSlots = checkedSlots.filter((slot) => slot.min <= 13);
              const pmSlots = checkedSlots.filter((slot) => slot.min >= 14);
              const disabledAM = disabledAgendaCheckbox(amSlots);
              const disabledPM = disabledAgendaCheckbox(pmSlots);
              const valueAMSlots = valueAgendaCheckBox(amSlots, disabledAM);
              const valuePMSlots = valueAgendaCheckBox(pmSlots, disabledPM);
              return (
                <React.Fragment key={dayID}>
                  <StyledContainer
                    borderRadius={4}
                    bg="gray.lightest"
                    ml={index % 2 === 1 ? 3 : 0}
                    mr={index % 2 === 0 ? 3 : 0}
                    mb={5}>
                    <DateAndAllSelect
                      {...{
                        disabledAM,
                        disabledPM,
                        valueAMSlots,
                        valuePMSlots,
                        fullSelect,
                        dayID,
                        amSlots,
                        pmSlots,
                        day,
                        shopId,
                      }}
                    />
                    <ListSlots
                      {...{
                        amSlots,
                        averageOptiv,
                        toggleSlot,
                        day,
                        pmSlots,
                        index,
                        shopId,
                      }}
                    />
                  </StyledContainer>
                  {(index === 1 || Object.keys(days).length <= 1) &&
                    !shopId && <HelpBox {...{ openModal }} />}
                </React.Fragment>
              );
            })}
        </DateTimeModuleWrapper>
      )}
      <Link
        style={{ flex: 1 }}
        color="blue.electric"
        onClick={() => openModal({ type: 'slots' })}>
        {shopId ? t('datetimemodule.links.1') : t('datetimemodule.links.2')}
      </Link>
      <Separator axe="horizontal" mt={4} />
      {!loading && (
        <SelectedSlots {...{ slots, fixed, submit, toContinue, shopId }} />
      )}
    </div>
  );
};

DateTimeModule.propTypes = {
  position: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  available_min: PropTypes.string,
  available_max: PropTypes.string,
  selectDate: PropTypes.func,
  selectAvailable: PropTypes.func,
  product_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  capabilities: PropTypes.array,
};
export default DateTimeModule;
