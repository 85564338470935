import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
// Hook
import useDeviceWidth from '../../hooks/useDeviceWidth';
// Components
import Box from '../shared/atoms/Box';
import Heading from '../shared/atoms/Heading';
import Link from '../shared/atoms/Link';
import Text from '../shared/atoms/Text';
import ToolkitFix from '../shared/ToolkitFix';
import SectionTitle from '../shared/molecules/SectionTitle';
import SectionBackground from '../shared/molecules/SectionBackground';
import Container from '../shared/atoms/Container';
import Button from '../shared/atoms/Button';
// Icons
import AngleRight16 from '../icons/AngleRight16';
import Separator from '../Separator';

const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledBox = styled(Box)`
  flex: 1;
`;

const StyledToolkitWrench = styled(ToolkitFix)`
  position: relative;
  left: -38px;
  top: -49px;
  transform: rotate(45deg);
`;

const StyledToolkitCircle = styled(ToolkitFix)`
  transform: rotate(45deg);
`;

const StyledContainerTool = styled.div`
  margin-top: 58px;
  margin-left: 20px;
  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    margin-bottom: 16px;
    margin-left: 0px;
  }
`;

const StyledText = styled(Text)`
  font-size: 18px;
`;

const LinkFAQ = ({ text, link, end = false }) => (
  <>
    <Link target="_blank" color="black" href={link} after={<AngleRight16 />}>
      <Box flexDirection="row" justifyContent="space-between" width="100%">
        <StyledText>{text}</StyledText>
      </Box>
    </Link>
    {!end && <Separator axe="horizontal" />}
  </>
);

const FAQ = ({
  home = false,
  link = 'https://faq.roulezjeunesse.com/',
  ...rest
}) => {
  const { isMobile } = useDeviceWidth();
  const { t } = useTranslation();
  return (
    <SectionBackground {...rest}>
      <Container>
        <Box flexDirection={{ xs: 'column', md: 'row' }}>
          <StyledBox
            pr={{ xs: 0, lg: 5 }}
            width={{ xs: 1, lg: 1 / 2 }}
            mb={{ xs: 6, md: 0 }}>
            {home && (
              <StyledDiv>
                <SectionTitle color="blue.main" tool="spring">
                  {t('wetellyoueverything')}
                </SectionTitle>
              </StyledDiv>
            )}
            <Heading color="black" level={2} mt={2} mb={{ xs: 2, md: 5 }}>
              {t('faq.texts.1')}
            </Heading>
            <Button
              mb={5}
              target="_blank"
              variant="outlinedBlack"
              href={link}
              after={<AngleRight16 />}
              animation>
              {t('faq.cta')}
            </Button>
            <StyledContainerTool>
              <StyledToolkitCircle
                name="circle"
                color="blue.main"
                size={isMobile ? '88px' : '9em'}
              />
              <StyledToolkitWrench
                name="wrench"
                color="violet.lighter"
                size={isMobile ? '88px' : '9em'}
              />
            </StyledContainerTool>
          </StyledBox>
          <StyledBox pl={{ xs: 0, lg: 5 }} width={{ xs: 1, lg: 1 / 2 }}>
            <LinkFAQ
              text={t('faq.texts.2')}
              link="https://faq.roulezjeunesse.com/article/50wl3keuxt-j-ai-postul-que-se-passe-t-il-ensuite"
            />
            <LinkFAQ
              text={t('faq.texts.3')}
              link="https://faq.roulezjeunesse.com/article/zjahkzio4o"
            />
            <LinkFAQ
              text={<>{t('faq.texts.4')}</>}
              link="https://faq.roulezjeunesse.com/article/jo8c3tziga-quelles-sont-les-r-parations-prises-en-charge-par-roulezjeunesse"
            />
            <LinkFAQ
              text={t('faq.texts.5')}
              link="https://faq.roulezjeunesse.com/article/cl15c3lnxv-comment-se-passe-l-approvisionnement-en-pi-ces"
            />
            <LinkFAQ
              text={t('faq.texts.6')}
              link="https://faq.roulezjeunesse.com/article/j9on38dmfe-comment-sont-calcul-s-les-prix"
            />
            <LinkFAQ
              text={t('faq.texts.7')}
              link="https://faq.roulezjeunesse.com/article/ow48ouin6t-comment-se-passe-une-intervention"
              end
            />
          </StyledBox>
        </Box>
      </Container>
    </SectionBackground>
  );
};

export default FAQ;
