import React from 'react';
// Components
import Box from '../shared/atoms/Box';
import Link from '../shared/atoms/Link';
// Icons
import AngleRight16 from '../icons/AngleRight16';

const ZonesSectionLinks = ({ zones }) => (
  <Box my={0} as="ul" flexDirection="row" flexWrap="wrap">
    {zones.map((zone, idx) => (
      <Box key={idx} width={{ xs: 1 / 2, md: 1 / 3, lg: 1 / 6 }}>
        <li style={{ marginBottom: '0.75em' }}>
          <a
            href={`/reparateur-a-domicile/${zone.slug}`}
            style={{ color: 'black', fontWeight: zone.slug === '' ? 'bold' : '' }}
          >
            {zone.full}
          </a>
        </li>
      </Box>
    ))}
  </Box>
);

export default ZonesSectionLinks;
