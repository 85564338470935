import React from 'react';
import Box from '../atoms/Box';
import Heading from '../atoms/Heading';
import Text from '../atoms/Text';

export default function StepsExplained({ icons, headings, texts, ...props }) {
  return (
    <Box
      flexDirection={{ xs: 'column', lg: 'row' }}
      alignItems={{ xs: 'center', lg: 'flex-start' }}
      {...props}>
      <Box
        width={{ xs: 1, md: 3 / 4, lg: 1 / 3 }}
        pr={{ xs: 0, lg: 3 }}
        pb={{ xs: 3, lg: 0 }}
        alignItems={{ xs: 'center', lg: 'flex-start' }}>
        {icons && icons[0]}
        <Heading level={6} mt={3}>
          {headings[0]}
        </Heading>
        <Text textAlign={{ xs: 'center', lg: 'initial' }} variant="small">
          {texts[0]}
        </Text>
      </Box>
      <Box
        width={{ xs: 1, md: 3 / 4, lg: 1 / 3 }}
        px={{ xs: 0, lg: 3 }}
        py={{ xs: 3, lg: 0 }}
        alignItems={{ xs: 'center', lg: 'flex-start' }}>
        {icons && icons[1]}
        <Heading level={6} mt={3}>
          {headings[1]}
        </Heading>
        <Text textAlign={{ xs: 'center', lg: 'initial' }} variant="small">
          {texts[1]}
        </Text>
      </Box>
      <Box
        width={{ xs: 1, md: 3 / 4, lg: 1 / 3 }}
        pl={{ xs: 0, lg: 3 }}
        pt={{ xs: 3, lg: 0 }}
        alignItems={{ xs: 'center', lg: 'flex-start' }}>
        {icons && icons[2]}
        <Heading level={6} mt={3}>
          {headings[2]}
        </Heading>
        <Text variant="small" textAlign={{ xs: 'center', lg: 'initial' }}>
          {texts[2]}
        </Text>
      </Box>
    </Box>
  );
}