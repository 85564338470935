import React from 'react';
import moment from 'moment';
// Components
import Box from '../shared/atoms/Box';
import Text from '../shared/atoms/Text';

const OpenHoursShop = ({ open_hours, today }) => {
  return (
    <Box
      mb={1}
      ml="5px"
      flexDirection="row"
      justifyContent="space-between"
      itemProp="openingHours">
      <Box mr={5}>
        {Object.entries(open_hours).map(([key, value], index) => (
          <Text
            mb={1}
            key={key}
            color="black"
            fontWeight={key === today ? '500' : '400'}>
            {moment()
              .day(index + 1)
              .format('dddd')}
          </Text>
        ))}
      </Box>
      <Box>
        {Object.entries(open_hours).map(([key, value]) => (
          <Text
            mb={1}
            key={key}
            textAlign="start"
            color="black"
            fontWeight={key === today ? '500' : '400'}>
            {value[0] !== ''
              ? `${value[0]} - ${!value[3] ? value[1] : value[3]}`
              : 'Fermé'}
          </Text>
        ))}
      </Box>
    </Box>
  );
};

export default OpenHoursShop;
