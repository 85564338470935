import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
// Components
import Skeleton from 'react-loading-skeleton';
import Box from '../shared/atoms/Box';
import Text from '../shared/atoms/Text';
import { capitalizeFirstLetter } from '../../utils/utils';
import Row from '../shared/atoms/Row';

const StyledBox = styled(Box)`
  max-width: 250px;
`;

export default function OpenHours({ open_hours, loading }) {
  if (loading) {
    return (
      <StyledBox
        mb={1}
        ml="5px"
        flexDirection="row"
        justifyContent="space-between"
        itemProp="openingHours">
        <Box mr={5}>
          <Skeleton height={120} />
        </Box>
      </StyledBox>
    );
  }
  if (open_hours === null) {
    return <Text>Pas d'horaires renseignés</Text>;
  }
  return (
    <StyledBox
      mb={1}
      ml="5px"
      flexDirection="row"
      justifyContent="space-between"
      itemProp="openingHours">
      <Box>
        {Object.entries(open_hours).map(([key, value], index) => (
          <Row key={index} between>
            <Box flexDirection="row">
              <Box key={key} flexDirection="row" alignItems="center" mb={1}>
                <Text
                  color={value[0] !== '' ? 'green.main' : 'gray.dark'}
                  mr={2}
                  variant="small">
                  ●
                </Text>
              </Box>
              <Text color="black" variant="medium">
                {capitalizeFirstLetter(
                  moment()
                    .day(index + 1)
                    .format('dddd'),
                )}
              </Text>
            </Box>
            <Text
              mb={1}
              key={key}
              textAlign="start"
              color="black"
              variant="medium">
              {value[0] !== ''
                ? `${value[0]} - ${!value[3] ? value[1] : value[3]}`
                : 'Fermé'}
            </Text>
          </Row>
        ))}
      </Box>
    </StyledBox>
  );
}
