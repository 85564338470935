import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.504 3.137a1 1 0 01.36 1.368l-3.465 5.93c-.285.488-.53.907-.76 1.233-.24.343-.518.664-.9.897a3 3 0 01-1.895.421c-.445-.05-.832-.222-1.196-.431-.346-.199-.745-.475-1.21-.796L2.43 10.37a1 1 0 011.137-1.645l1.975 1.365c.507.35.837.578 1.102.73.255.146.365.171.421.177a1 1 0 00.632-.14c.048-.03.137-.1.306-.34.176-.25.378-.595.69-1.127l3.443-5.895a1 1 0 011.368-.36z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgCheck16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgCheck16;
