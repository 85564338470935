import i18n from '../i18n';

const constants = {
  CDN_BASE_URL: true // now always in /static
    ? '/static'
    : 'https://d2mapqkz3rznon.cloudfront.net',
  products: [
    {
      id: 1,
      name: i18n.t('velo'),
      pron: 'le',
      possessif: 'mon',
      picto: 'bicycle.png',
    },
    {
      id: 2,
      name: i18n.t('scooter'),
      pron: 'la',
      possessif: 'ma',
      picto: 'scooter.png',
    },
    {
      id: 3,
      name: 'e-scooter',
      pron: 'le',
      picto: 'scooter.png',
    },
  ],
  STRIPE_KEY_TEST:
    'pk_test_51PL4zwDvQ5rVbR2X68tvDUswq5vXNyeft6gRAH5dSBtA93wq8K0k7Jl1HIJpn6d9pixfOd3FRN0T1YU9iMukH3x100nEnjo2GG',
  STRIPE_KEY_LIVE:
    'pk_live_51PL4zwDvQ5rVbR2Xvvp8lBpJqVpsew4t2W2QKnGn1vvlyF7ALLej2GDHUIg0bN8jB7dzMFql0n9cNhgJHoJjZIOv00o6ne1cdT',
  EMAIL_CYPRESS: 'fred@roulezjeunesse.com',
  PASSWORD_CYPRESS: 'roulezjeunesse@2024',
};

export default constants;
