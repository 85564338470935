import React from 'react';

const defaultCtx = {
  partenaire: null,
  tracking: null,
  cobranding: false,
};

const PartenaireContext = React.createContext(defaultCtx);
export default PartenaireContext;
