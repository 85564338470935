import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import React, { useState, useEffect } from 'react';
import {
  setCourseDetails,
  setPhotos,
  updateBikesAndCourseDetails,
  updateBikeDescription,
  addNewBike,
} from '../../actions/course';
import Dropzone from '../../components/shared/organisms/NewDropzone';

import Heading from '../../components/shared/atoms/Heading';
import Button from '../../components/shared/atoms/Button';
import Box from '../../components/shared/atoms/Box';
import DottedList from '../../components/shared/atoms/DottedList';
import Input from '../../components/shared/atoms/Input';
import Separator from '../../components/Separator';
import Alert from '../../components/shared/molecules/Alert';

import ArrowRight16 from '../../components/icons/ArrowRight16';
import AngleRight16 from '../../components/icons/AngleRight16';
import Trash24 from '../../components/icons/Trash24';
import Plus16 from '../../components/icons/Plus16';
import { colors } from '../../styles/theme';

const StyledButton = styled(Button)`
  width: 200px;
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    min-width: 100%;
  }
`;
const StyledAlert = styled(Alert)`
  a {
    text-decoration: underline;
    color: ${(p) => p.theme.colors.yellow.dark};
    :hover {
      text-decoration: underline;
      color: ${(p) => p.theme.colors.yellow.dark};
      opacity: 80%;
    }
  }
`;
const RepairDetails = () => {
  const { t } = useTranslation();
  const { course_details, photos, selectedShop } = useSelector((s) => s.course);
  const { questions } = useSelector((s) => s.diag);
  const { devis } = useSelector((s) => s.diag);
  const dispatch = useDispatch();
  const history = useHistory();

  const currentBikes = useSelector((state) => state.course.bikes);

  const handleAddBike = () => {
    // This example assumes you have an action to add a bike to the Redux state
    const newBike = { description: '' }; // Define a new bike object
    dispatch(addNewBike(newBike));
  };

  const handleDeleteBike = (index) => {
    // Directly use `currentBikes` from the Redux state
    const updatedBikes = currentBikes.filter((_, i) => i !== index);
    dispatch(updateBikesAndCourseDetails(updatedBikes)); // Dispatch the update to Redux
  };

  const handleBikeDescriptionChange = (index, event) => {
    // Dispatch an action to update the bike description in the Redux store
    dispatch(updateBikeDescription(index, event.target.value));
  };

  const handleSetPhotos = (p) => dispatch(setPhotos(p));

  const submit = (e) => {
    e.preventDefault();

    // Retrieve the existing application state from local storage
    const stateData = localStorage.getItem('state');
    const appState = stateData ? JSON.parse(stateData) : {};

    // Ensure the 'course' object exists within the state
    if (!appState.course) {
      appState.course = {};
    }

    // Navigate to the next page
    history.push('/reparation/informations');
  };

  return (
    <div>
      <Heading level={2} mb={{ xs: 4, lg: 5 }}>
        {selectedShop?.id
          ? t('repairdetails.headings.1')
          : t('repairdetails.heading')}
      </Heading>
      <Box as="form" onSubmit={submit} mt={4}>
        <Heading level={5}>
          <Trans
            i18nKey="repairdetails.multibike"
            components={{
              styledspan: <small style={{ colors: colors.primary.main }} />,
            }}
          />
        </Heading>
        <DottedList>
          <>
            <ArrowRight16 mr={2} />
            {t('multibike.title')}
          </>
        </DottedList>

        {currentBikes.map((bike, index) => (
          <div
            key={index}
            style={{ position: 'relative', marginBottom: '1rem' }}>
            <Input
              id={`bikeDescription-${index}`}
              as="textarea"
              onChange={(e) => handleBikeDescriptionChange(index, e)}
              value={bike.description}
              placeholder={`Vélo ${index + 2} - ${t(
                'repairdetails.placeholder',
              )}`}
              rows="3"
              mt={4}
              style={{ width: '100%', boxSizing: 'border-box' }}
            />

            {currentBikes.length > 0 && (
              <Button
                type="button"
                onClick={() => handleDeleteBike(index)}
                style={{
                  position: 'absolute',
                  right: '0px',
                  bottom: '-45px',
                  background: 'none',
                  cursor: 'pointer',
                }}
                aria-label="Delete bike description">
                <Trash24></Trash24>
              </Button>
            )}
          </div>
        ))}

        <Button
          type="button"
          before={<Plus16 />}
          color="primary.main"
          mt={4}
          onClick={handleAddBike}>
          {t('multibike.add')}
        </Button>
        <Separator axe="horizontal" bg="gray.light" my={6} />
        <Heading level={5}>
          <Trans
            i18nKey="repairdetails.title"
            components={{
              styledspan: <small style={{ colors: colors.primary.main }} />,
            }}
          />
        </Heading>
        <DottedList>
          <>
            <ArrowRight16 mr={2} />
            {t('addpictures.title')}
          </>
        </DottedList>

        <Dropzone photos={photos} setPhotos={handleSetPhotos} mt={5} mb={7} />
        <StyledButton
          type="submit"
          animation
          after={<AngleRight16 />}
          alignSelf="flex-end"
          data-testid="button-course-details-continuer"
          mb={5}
          zIndex={1001}>
          {t('continue')}
        </StyledButton>
      </Box>
    </div>
  );
};

export default RepairDetails;
