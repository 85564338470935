import React from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
// Components
import Button from '../shared/atoms/Button';
import Input from '../shared/atoms/Input';
import Box from '../shared/atoms/Box';
import Link from '../shared/atoms/Link';
import Alert from '../shared/molecules/Alert';
import WrapperForm from './WrapperForm';
// Icons
import AngleRight16 from '../icons/AngleRight16';
import Eye24 from '../icons/Eye24';
import Message24 from '../icons/Message24';
import Lock24 from '../icons/Lock24';
import Infos24 from '../icons/Infos24';
// validationSchema
import { loginValidationSchema } from './ValidationSchema';

const StyledButton = styled(Button)`
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    min-width: 100%;
  }
`;

const LoginForm = ({
  resetSend,
  submit,
  onChange,
  RESET_PASSWORD,
  SIGNUP,
  _switch,
  withTitle,
  ctaLabel,
  bg,
  noPadding,
  ...props
}) => {
  const { t } = useTranslation();
  // const {

  // } = props;

  return (
    <WrapperForm
      withTitle={withTitle || t('loginform.titles.1')}
      _switch={_switch}
      text={t('loginform.texts.1')}
      linkText={t('signup.exclamation')}
      chooseSwitch={SIGNUP}
      withSwitch
      bg={bg}
      noPadding={noPadding}
      {...props}>
      {resetSend && (
        <Alert icon={<Infos24 color="blue.dark" />} borderRadius="xs" mb={4}>
          <Trans i18nKey="loginform.alert" />
        </Alert>
      )}
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={loginValidationSchema}
        onSubmit={submit}>
        {({ isSubmitting, setFieldValue, submitCount, errors }) => (
          <Form>
            <Field
              name="email"
              render={({ field }) => (
                <Input
                  mb={{ xs: 2, lg: 4 }}
                  color="black"
                  bg="white"
                  before={<Message24 />}
                  placeholder={t('email')}
                  data-testid="input-email"
                  onKeyUp={onChange}
                  onChange={(e) => {
                    const value = e.target.value.toLowerCase().trim();
                    setFieldValue('email', value);
                  }}
                  error={!!submitCount && errors.email}
                  {...field}
                />
              )}
            />
            <Field
              name="password"
              render={({ field }) => (
                <Input
                  mb={{ xs: 2, lg: 4 }}
                  color="black"
                  bg="white"
                  type="password"
                  data-testid="input-password"
                  before={<Lock24 />}
                  after={<Eye24 />}
                  placeholder={t('password')}
                  error={!!submitCount && errors.password}
                  {...field}
                />
              )}
            />
            <Box my={5} alignItems="center">
              <Link
                color="blue.electric"
                onClick={() => _switch(RESET_PASSWORD)}
                ml={2}
                fontWeight="bold">
                {t('forgotmypassword')}
              </Link>
            </Box>
            <Box>
              <StyledButton
                after={<AngleRight16 />}
                disabled={isSubmitting}
                alignSelf="center"
                fullWidth
                data-testid="login-form-on-submit"
                animation>
                {ctaLabel || t('cta.login')}
              </StyledButton>
            </Box>
          </Form>
        )}
      </Formik>
    </WrapperForm>
  );
};

export default LoginForm;
