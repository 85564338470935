import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#Help_Color_56_svg__clip0)">
      <path
        d="M30.952 17.48L33.92 6.4c-3.803-.58-12.658-1.017-17.653 1.867-4.995 2.883-9.344 10.25-10.894 13.574l12.31 3.298c.85-1.531 3.176-4.954 5.673-6.396 2.498-1.442 6.104-1.444 7.596-1.264zM24.54 38.996L21.54 50.197c3.845.586 12.797 1.028 17.847-1.887 5.05-2.916 9.447-10.364 11.014-13.724l-12.446-3.334c-.86 1.548-3.21 5.009-5.735 6.467-2.525 1.458-6.171 1.459-7.679 1.277z"
        fill="#D5FF4F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.732 39.215a21.92 21.92 0 01-2.435-15.722l9.678 2.593a11.952 11.952 0 001.417 8.13 11.952 11.952 0 006.332 5.292l-2.593 9.677a21.92 21.92 0 01-12.399-9.97zm25.053-.607a11.952 11.952 0 01-8.13 1.417l-2.593 9.678a21.92 21.92 0 0015.723-2.435 21.92 21.92 0 009.97-12.398l-9.678-2.593a11.952 11.952 0 01-5.292 6.33zm15.487-5.67l-9.678-2.593c.488-2.696.06-5.572-1.417-8.13a11.952 11.952 0 00-6.331-5.292l2.593-9.677a21.92 21.92 0 0112.398 9.97 21.92 21.92 0 012.435 15.722zM32.507 6.728l-2.593 9.678a11.952 11.952 0 00-8.13 1.417 11.952 11.952 0 00-5.291 6.331l-9.678-2.593a21.92 21.92 0 019.97-12.398 21.92 21.92 0 0115.722-2.435zM7 40.215C.373 28.736 4.306 14.058 15.785 7.431 27.264.803 41.942 4.736 48.569 16.215c6.628 11.48 2.695 26.158-8.784 32.785C28.305 55.627 13.627 51.694 7 40.215zm12.124-7c-2.761-4.782-1.122-10.898 3.66-13.66 4.784-2.761 10.9-1.122 13.66 3.66 2.762 4.783 1.124 10.9-3.66 13.66-4.782 2.762-10.898 1.123-13.66-3.66z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="Help_Color_56_svg__clip0">
        <path fill="#fff" d="M0 0h56v56H0z" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgHelpColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgHelpColor56;
