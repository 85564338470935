import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2a1 1 0 000 2h.75v2.32c0 .974.219 1.937.642 2.8a5.702 5.702 0 001.804 2.15l1.027.73-1.027.73a5.702 5.702 0 00-1.804 2.15 6.38 6.38 0 00-.642 2.8V20H5a1 1 0 100 2h14a1 1 0 100-2h-.75v-2.32a6.38 6.38 0 00-.642-2.8 5.702 5.702 0 00-1.804-2.15L14.777 12l1.027-.73a5.701 5.701 0 001.804-2.15 6.38 6.38 0 00.642-2.8V4H19a1 1 0 100-2H5zm11.25 2h-8.5v2.32c0 .677.153 1.338.438 1.92.285.583.69 1.061 1.166 1.4l1.87 1.328.002.002c.175.125.307.29.394.468a1.283 1.283 0 010 1.124c-.086.179-.219.343-.394.469l-.003.001-1.869 1.328a3.704 3.704 0 00-1.166 1.4 4.38 4.38 0 00-.438 1.92V20h8.5v-2.32a4.38 4.38 0 00-.438-1.92 3.703 3.703 0 00-1.166-1.4l-1.87-1.328-.002-.002a1.248 1.248 0 01-.394-.468 1.283 1.283 0 010-1.124c.086-.179.219-.343.394-.469l.003-.001 1.869-1.328a3.704 3.704 0 001.166-1.4 4.381 4.381 0 00.438-1.92V4z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgHourglass24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgHourglass24;
