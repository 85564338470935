import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.502 30.217c-3.52-4.822-8.752-8.333-8.752-13.005 0-4.672 3.92-8.462 8.752-8.462 4.833 0 8.748 3.79 8.748 8.462s-5.232 8.183-8.748 13.005z"
      fill="#28D898"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.5 6H50v44H30.5V36H17v13a1 1 0 11-2 0V36H6V21.75h24.5V6zm2 2v40H48V8H32.5zM8 23.75V34h22.5V23.75H8zm29.187.8a1 1 0 011 1v3.5a1 1 0 01-2 0v-3.5a1 1 0 011-1zm1-10.2a1 1 0 00-2 0v3.5a1 1 0 102 0v-3.5zm-1 21.4a1 1 0 011 1v3.5a1 1 0 01-2 0v-3.5a1 1 0 011-1zm7.125-10.2a1 1 0 10-2 0v3.5a1 1 0 102 0v-3.5zm-1-12.2a1 1 0 011 1v3.5a1 1 0 11-2 0v-3.5a1 1 0 011-1zm1 23.4a1 1 0 10-2 0v3.5a1 1 0 002 0v-3.5z"
      fill="#000"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgBuildingColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgBuildingColor56;
