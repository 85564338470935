import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.476 5.008a1.1 1.1 0 01.516 1.468l-3.872 8.065c-.394.822-.72 1.502-1.032 2.028-.322.546-.682 1.026-1.19 1.381a4.1 4.1 0 01-2.653.729c-.619-.046-1.174-.275-1.73-.58-.536-.294-1.163-.712-1.922-1.217L4.39 15.416a1.1 1.1 0 111.22-1.832l2.162 1.44c.809.539 1.358.903 1.802 1.146.434.238.667.302.834.315a1.9 1.9 0 001.23-.338c.136-.095.304-.27.556-.696.257-.436.543-1.03.964-1.906l3.85-8.021a1.1 1.1 0 011.468-.516z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgCheck24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgCheck24;
