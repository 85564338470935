import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M9 11a2 2 0 100-4 2 2 0 000 4z" fill="#000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 11c0-2.8 0-4.2.545-5.27A5 5 0 015.73 3.545C6.8 3 8.2 3 11 3h2c2.8 0 4.2 0 5.27.545a5 5 0 012.185 2.185C21 6.8 21 8.2 21 11v2c0 2.8 0 4.2-.545 5.27a5 5 0 01-2.185 2.185C17.2 21 15.8 21 13 21h-2c-2.8 0-4.2 0-5.27-.545a5 5 0 01-2.185-2.185C3 17.2 3 15.8 3 13v-2zm8-6h2c1.433 0 2.388.002 3.121.061.71.058 1.035.161 1.241.266a3 3 0 011.311 1.311c.105.206.208.53.266 1.24.06.734.061 1.689.061 3.122v2c0 .61 0 1.132-.005 1.588l-4.283-3.874-9.321 6.767a2.996 2.996 0 01-.064-.119c-.105-.206-.208-.53-.266-1.24C5.001 15.387 5 14.432 5 13v-2c0-1.433.002-2.388.061-3.121.058-.71.161-1.035.266-1.241a3 3 0 011.311-1.311c.206-.105.53-.208 1.24-.266C8.613 5.001 9.568 5 11 5zM6.978 18.8c.204.056.487.105.9.139.734.06 1.689.061 3.122.061h2c1.433 0 2.388-.002 3.121-.061.71-.058 1.035-.161 1.241-.266a3 3 0 001.311-1.311 1.64 1.64 0 00.107-.27l-4.206-3.806L6.978 18.8z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgPicture24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPicture24;
