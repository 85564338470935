import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 13.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM8 15A7 7 0 108 1a7 7 0 000 14z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.908 5.37a.75.75 0 01.221 1.038L9.392 9.089l-.014.022c-.138.212-.265.408-.384.563-.129.167-.287.339-.507.468a1.75 1.75 0 01-1.044.232 1.772 1.772 0 01-.658-.208c-.174-.09-.371-.213-.586-.347l-1.096-.682a.75.75 0 01.794-1.274l1.073.67c.244.152.39.242.503.3.07.036.101.046.11.048a.25.25 0 00.143-.032.621.621 0 00.08-.09c.077-.101.17-.244.327-.486l1.738-2.68a.75.75 0 011.037-.222zM7.724 8.85h.001-.001zm-.138.032h-.002.002z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgCheckCircle16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgCheckCircle16;
