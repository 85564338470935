import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#Question_Color_32_svg__clip0)">
      <circle cx={25} cy={25} r={7} fill="#28D898" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.468 25.14l-.54-.268-.592.111-4.083.764.764-4.083.11-.592-.268-.54A10.15 10.15 0 015.8 16c0-5.633 4.567-10.2 10.2-10.2 5.633 0 10.2 4.567 10.2 10.2 0 5.633-4.567 10.2-10.2 10.2a10.15 10.15 0 01-4.532-1.06zM4 28l1.898-.355 4.769-.892A11.95 11.95 0 0016 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16c0 1.916.449 3.727 1.247 5.333l-.892 4.77L4 28zm9.727-13.913a.85.85 0 00.791-.902v-.01a2.942 2.942 0 01.17-1.05c.099-.254.237-.463.426-.609.18-.138.462-.266.94-.266.431 0 .79.137 1.03.35.226.201.4.514.4 1 0 .855-.578 1.291-.947 1.57l-.104.078-.009.007-.008.008c-.374.308-.736.66-.971 1.21-.226.523-.3 1.143-.3 1.927a.85.85 0 101.7 0c0-.702.072-1.048.162-1.256.077-.181.195-.325.48-.561l.054-.04c.336-.24 1.642-1.172 1.642-2.943 0-.942-.36-1.728-.97-2.27-.597-.531-1.378-.78-2.16-.78-.81 0-1.47.227-1.979.621-.5.386-.795.887-.971 1.342a4.628 4.628 0 00-.282 1.762V13.296l.824-.055-.823.056a.85.85 0 00.905.79zM14.6 21.6a1.4 1.4 0 112.8 0 1.4 1.4 0 01-2.8 0z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="Question_Color_32_svg__clip0">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = false;
const SvgQuestionColor32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgQuestionColor32;
