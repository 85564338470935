import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles/constants';
import { Row } from './blocks/Grid';

const BBox = styled.div`
  border: 1px solid ${colors.lightgrey};
  padding: 15px 20px;
  margin: 0 0 10px;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  transition: 0.2s ease;

  label {
    cursor: pointer;
    user-select: none;
    font-weight: 500;
  }

  :hover {
    background-color: ${colors.lightblue};
    color: white;
  }

  ${(props) =>
    props.checked &&
    `
      background-color: ${colors.blue};
      color:white;
  `}
`;

export default function Checkbox({
  label,
  onClick,
  text,
  check = false,
  checkable = true,
  value,
  disabled,
  ...props
}) {
  return (
    <BBox
      onClick={!disabled ? onClick : null}
      checked={value}
      disabled={disabled}
      {...props}>
      {check && <input type="checkbox" checked={value} readOnly={true} />}
      <Row>
        <div>
          <label style={{ color: disabled ? colors.lightgrey : 'inherit' }}>
            {label}
          </label>
          {text && <div>{text}</div>}
        </div>
        {checkable && (
          <div style={{ flex: 0, display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                borderRadius: '50%',
                backgroundColor: value ? colors.green : '#FFFFFF',
                width: 15,
                height: 15,
                lineHeight: '20px',
                textAlign: 'center',
                userSelect: 'none',
              }}
            />
          </div>
        )}
      </Row>
    </BBox>
  );
}
