import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Box from '../../shared/atoms/Box';
import Separator from '../../Separator';
import Text from '../../shared/atoms/Text';
import Link from '../../shared/atoms/Link';
import IconTooltip from '../../shared/atoms/IconTooltip';
// Icons
import Calendar24 from '../../icons/Calendar24';
import CreditCard24 from '../../icons/CreditCard24';
import User24 from '../../icons/User24';
import Book24 from '../../icons/Book24';
import Infos16 from '../../icons/Infos16';
import { getLang } from '../../../utils/utils';

function AccountNav({ ...props }) {
  const { t } = useTranslation();
  const client = useSelector((s) => s.client);
  const { pathname } = useLocation();

  return (
    <Box
      borderColor="gray.light"
      borderWidth="1px"
      borderRadius="sm"
      borderStyle="solid"
      alignSelf="start"
      px={2}
      pt={{ xs: 2, lg: 4 }}
      style={{ flex: 0.33 }}>
      <Box
        flexDirection={{ xs: 'row', lg: 'column' }}
        justifyContent={{ xs: 'space-between', md: 'space-around' }}>
        <Box
          display={{ xs: 'none', lg: 'inline-block' }}
          mb={{ xs: 0, lg: 2 }}
          ml={2}>
          <Text color="gray.dark">{t('hello')}</Text>
          <Text fontWeight={2} fontSize="18px">
            {client.prenom} {client.nom}
          </Text>
        </Box>
        <Link to="/account" style={{ flex: 1 }}>
          <Box
            width={1}
            flexDirection={{ xs: 'column', lg: 'row' }}
            bg={{
              xs: null,
              lg: pathname == '/account' ? 'gray.lightest' : null,
            }}
            borderRadius="xs"
            p={{ xs: 0, lg: 3 }}
            alignItems="center">
            <Calendar24
              mr={{ xs: 0, lg: 3 }}
              color={pathname == '/account' ? 'blue.electric' : 'black'}
            />
            <Text
              textAlign={{ xs: 'center', lg: 'start' }}
              fontSize={{ xs: 'xs', md: 'md' }}
              lineHeight={{ xs: '1', md: '1.25' }}
              color={pathname == '/account' ? 'blue.electric' : 'black'}>
              {t('myappointments')}
            </Text>
          </Box>
        </Link>
        {/* <Link to="/account/carnet-entretien" style={{ flex: 1 }}>
          <Box
            width={1}
            flexDirection={{ xs: 'column', lg: 'row' }}
            bg={{
              xs: null,
              lg: pathname.includes('/account/carnet-entretien')
                ? 'gray.lightest'
                : null,
            }}
            borderRadius="xs"
            p={{ xs: 0, lg: 3 }}
            alignItems="center">
            <Book24
              mr={{ xs: 0, lg: 3 }}
              color={
                pathname.includes('/account/carnet-entretien')
                  ? 'blue.electric'
                  : 'black'
              }
            />
            <Text
              textAlign={{ xs: 'center', lg: 'start' }}
              fontSize={{ xs: 'xs', md: 'md' }}
              lineHeight={{ xs: '1', md: '1.25' }}
              color={
                pathname.includes('/account/carnet-entretien')
                  ? 'blue.electric'
                  : 'black'
              }>
              {t('maintenancebooklets')}
            </Text>
          </Box>
        </Link> */}
        <Link to="/account/cartes" style={{ flex: 1 }}>
          <Box
            width={1}
            flexDirection={{ xs: 'column', lg: 'row' }}
            bg={{
              xs: null,
              lg: pathname == '/account/cartes' ? 'gray.lightest' : null,
            }}
            borderRadius="xs"
            p={{ xs: 0, lg: 3 }}
            alignItems="center">
            <CreditCard24
              mr={{ xs: 0, lg: 3 }}
              color={pathname == '/account/cartes' ? 'blue.electric' : 'black'}
            />
            <Text
              textAlign={{ xs: 'center', lg: 'start' }}
              fontSize={{ xs: 'xs', md: 'md' }}
              lineHeight={{ xs: '1', md: '1.25' }}
              color={
                pathname == '/account/cartes' ? 'blue.electric' : 'black'
              }>
              {t('paymentmethods')}
            </Text>
          </Box>
        </Link>
        <Link to="/account/informations" style={{ flex: 1 }}>
          <Box
            width={1}
            flexDirection={{ xs: 'column', lg: 'row' }}
            alignItems="center"
            bg={{
              xs: null,
              lg: pathname == '/account/informations' ? 'gray.lightest' : null,
            }}
            borderRadius="xs"
            p={{ xs: 0, lg: 3 }}>
            <User24
              mr={{ xs: 0, lg: 3 }}
              color={
                pathname == '/account/informations' ? 'blue.electric' : 'black'
              }
            />
            <Text
              textAlign={{ xs: 'center', lg: 'start' }}
              fontSize={{ xs: 'xs', md: 'md' }}
              lineHeight={{ xs: '1', md: '1.25' }}
              color={
                pathname == '/account/informations' ? 'blue.electric' : 'black'
              }>
              {t('myaccount')}
            </Text>
          </Box>
        </Link>
      </Box>
      <Separator axe="horizontal" mt={2} mb={0} />
      <Box flexDirection="row" justifyContent="space-between" px={2} py={4}>
        <Box flexDirection="row" alignItems="center">
          <Text mr={1}>{t('availablefunds')}</Text>
          <IconTooltip
            icon={<Infos16 />}
            tooltipText={t('accountnav.funds.tooltip')}
          />
        </Box>
        <Text fontWeight={2}>
          {client.points}
          {getLang().currency}
        </Text>
      </Box>
    </Box>
  );
}

export default AccountNav;
