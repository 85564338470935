import resolve from 'contentful-resolve-response';
import fetch from 'isomorphic-fetch';
import { ACCESS_TOKEN, CONTENTFUL_BASE_URL, SPACE_ID } from './constants';

function resolveResponse(res = null) {
  if (res && !res.resolved) {
    return resolve(res);
  }
  return res;
}

function resolveSingleEntry(res = null) {
  const resolvedResponse = resolveResponse(res);
  return Array.isArray(resolvedResponse)
    ? resolvedResponse[0]
    : resolvedResponse;
}

async function getContentfulData(type) {
  const url = `${CONTENTFUL_BASE_URL}/spaces/${SPACE_ID}/entries/?access_token=${ACCESS_TOKEN}&content_type=${type}&include=5`;
  return fetch(url).then((r) => r.json());
}

export { resolveResponse, resolveSingleEntry, getContentfulData };
