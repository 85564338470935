// contentful
export const CONTENTFUL_BASE_URL = 'https://cdn.contentful.com';
export const SPACE_ID = 'n0z8m0uvv6qx';
export const ACCESS_TOKEN = 'P9oKEEwrl3Q_sf5e8wgXDoyQtPEeZiIPpD726QfaPQU';

// URL key
export const CITIES_PAGE_URL = `${CONTENTFUL_BASE_URL}/spaces/${SPACE_ID}/entries?access_token=${ACCESS_TOKEN}&content_type=city&include=5&limit=1000`;
export const BIKE_PRESTA_URL =
  'https://api.roulezjeunesse.com/prestations/front';
export const SHOPS_CITIES_URL = 'https://api.roulezjeunesse.com/shops/cities';
export const SCOOTER_PRESTA_URL =
  'https://api.roulezjeunesse.com/prestations/front?product_id[0]=2';
