import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
// Shared components
import SectionBackground from '../shared/molecules/SectionBackground';
import Heading from '../shared/atoms/Heading';
import ToolkitFix from '../shared/ToolkitFix';

const ToolkitBG = styled(ToolkitFix)`
  position: absolute;
  transform: matrix(-0.71, -0.71, -0.71, 0.71, 0, 0);
  width: 450px;
  height: auto;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const SpanColor = styled.span`
  color: ${(p) => p.theme.colors.primary.main};
`;

const Satisfaction = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <SectionBackground
      alignItems="center"
      bg="yellow.main"
      p={10}
      style={{ position: 'relative', overflow: 'hidden' }}
      {...props}>
      <Trans
        t={t}
        i18nKey="satisfaction.heading"
        components={{
          color: <SpanColor />,
          title: (
            <Heading level={3} color="black" center style={{ zIndex: 1 }} />
          ),
        }}></Trans>
      <ToolkitBG name="spring" color="yellow.lighter" />
    </SectionBackground>
  );
};

export default Satisfaction;
