import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space, layout } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 3 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M18.962.417a1.5 1.5 0 012.121.045l11.5 12a1.5 1.5 0 01-2.166 2.076l-11.5-12a1.5 1.5 0 01.045-2.121zM.004 43.896a1.5 1.5 0 011.6-1.392l16.58 1.149a1.5 1.5 0 11-.207 2.993l-16.58-1.15a1.5 1.5 0 01-1.393-1.6zM17.223 85.795a1.5 1.5 0 001.787 2.41l13.349-9.903a1.5 1.5 0 10-1.787-2.409l-13.35 9.902z"
      fill="#1D1D1D"
    />
  </svg>
);

const SIZE = 64;
const CAN_SET_COLOR = true;
const SvgPopLeft = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space};
  ${layout};
`;
export default SvgPopLeft;
