import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import * as api from '../../../../utils/api';
// Components
import Box from '../../../shared/atoms/Box';
import Heading from '../../../shared/atoms/Heading';
import Button from '../../../shared/atoms/Button';
import DottedList from '../../../shared/atoms/DottedList';
import Modal from '../../../../modals/Modal';
import Input from '../../../shared/atoms/Input';
import Spinner from '../../../Spinner';
// Icons
import InfosColor56 from '../../../icons/InfosColor56';

const UpdateDetails = ({ course, showModal, openModal, getCourse }) => {
  const { t } = useTranslation();
  const [commentaire, setCommentaire] = useState(course.course_details);
  const [loading, setLoading] = useState(false);

  const handleSetDetails = async () => {
    setLoading(true);
    const param = {
      course_details: commentaire,
    };
    try {
      await api.put(`/courses/${course.id}`, param);
      getCourse();
    } catch (err) {
      console.error('error handleSetDetails', err);
    }
    setLoading(false);
    openModal(false);
  };

  return (
    <Modal
      zIndex="1002"
      hasCloseAction={true}
      width="medium"
      open={showModal === 'UPDATE_DETAILS'}
      onRequestClose={() => openModal(false)}>
      <Box mb={4}>
        <InfosColor56 />
      </Box>
      <Heading mb={4} level={5}>
        {t('updatedetailsmodal.title')}
      </Heading>
      <DottedList>
        <Trans
          i18nKey="repairdetails.list"
          components={{
            list: <li />,
          }}
        />
      </DottedList>
      <Input
        as="textarea"
        onChange={(e) => setCommentaire(e.target.value)}
        value={commentaire}
        placeholder={t('repairdetails.placeholder')}
        rows="3"
        mt={4}
      />
      <Box
        alignItems="flex-end"
        justifyContent="flex-end"
        flexDirection="row"
        mt={5}>
        <Button
          size="compact"
          color="blue.electric"
          onClick={handleSetDetails}
          style={{ width: '100%' }}>
          {loading ? <Spinner small /> : t('save')}
        </Button>
      </Box>
    </Modal>
  );
};

export default UpdateDetails;
