import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6.246v10.285c.481 0 .962.115 1.4.346l2.477 1.301-.473-2.757a3.01 3.01 0 01.866-2.664l2.003-1.953-2.768-.402a3.01 3.01 0 01-2.267-1.647L12 6.246zM12 3c.36 0 .72.188.906.563l2.125 4.307c.148.298.432.505.761.553l4.753.69a1.01 1.01 0 01.56 1.724l-3.44 3.352a1.01 1.01 0 00-.29.894l.812 4.734a1.01 1.01 0 01-1.466 1.065l-4.25-2.235a1.01 1.01 0 00-.941 0l-4.251 2.235a1.01 1.01 0 01-1.466-1.066l.812-4.733a1.01 1.01 0 00-.29-.894l-3.44-3.352a1.01 1.01 0 01.56-1.724l4.753-.69a1.01 1.01 0 00.76-.553l2.126-4.307A.999.999 0 0112 3z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgStarhalf24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgStarhalf24;
