import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
// Components
import Text from '../shared/atoms/Text';

const SubitleSelector = ({ partenaire, ...props }) => {
  const { t } = useTranslation();
  const partenaireName = partenaire === 'darty' ? 'Darty' : 'Fnac';
  switch (partenaire) {
    case 'darty':
    case 'fnac':
      return (
        <p className={'subtitle'}>
          <Trans
            i18nKey="subtitleselector.texts.1"
            components={{
              strong: <b />,
              partenaireName,
            }}
          />
        </p>
      );
    case 'ratp':
      return (
        <p className={'subtitle'}>
          <Trans
            i18nKey="subtitleselector.texts.2"
            components={{
              strong: <b />,
            }}
          />
        </p>
      );
    case 'inscription':
      return <Text color="black">{t('subtitleselector.texts.3')}</Text>;
    case 'clientscdiscount':
      return (
        <p className={'subtitle'}>
          Cdiscount et Roulezjeunesse vous offrent 10€ de crédit sur votre
          prochaine réparation !
        </p>
      );
    default:
      return (
        <p className={'subtitle'}>
          <Trans
            i18nKey="subtitleselector.texts.4"
            components={{
              strong: <b />,
            }}
          />
        </p>
      );
  }
};

export default SubitleSelector;
