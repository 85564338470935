import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 8.667l1.939.83.827.356c2.193.943 3.394 1.492 4.432 2.254 1.03.756 1.94 1.665 2.695 2.695.762 1.038 1.311 2.239 2.254 4.432l.355.827.831 1.939L22 2 2 8.667zm5.588.245l11.25-3.75-3.75 11.25c-.516-1.11-.992-1.99-1.582-2.794a14.567 14.567 0 00-3.124-3.124c-.804-.59-1.683-1.066-2.794-1.582z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgGeoloc24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgGeoloc24;
