import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g filter="url(#Pin_geoloc_48_svg__filter0_dd)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.686 45.355l.318.312.34-.334c5.347-5.252 12.99-12.758 12.99-19.86 0-7.255-5.966-13.14-13.33-13.14s-13.338 5.885-13.338 13.14c0 7.112 7.664 14.629 13.02 19.882zm.376-14.688c2.774 0 5.022-2.239 5.022-5 0-2.762-2.248-5-5.022-5s-5.023 2.238-5.023 5c0 2.761 2.249 5 5.023 5z"
        fill="#d5ff4f"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.004 13.083c-6.96 0-12.588 5.56-12.588 12.39 0 3.304 1.791 6.796 4.35 10.186 2.481 3.287 5.596 6.365 8.237 8.957 2.642-2.596 5.76-5.68 8.242-8.97 2.552-3.386 4.338-6.873 4.338-10.173 0-6.83-5.62-12.39-12.58-12.39zm-14.088 12.39c0-7.68 6.32-13.89 14.088-13.89 7.768 0 14.08 6.211 14.08 13.89 0 3.802-2.036 7.62-4.642 11.075-2.612 3.465-5.892 6.687-8.556 9.303l-.357.35a.75.75 0 01-1.05.001l-.336-.329c-2.668-2.616-5.955-5.84-8.574-9.31-2.612-3.46-4.652-7.282-4.652-11.09zm14.146-4.056c-2.363 0-4.273 1.906-4.273 4.25 0 2.344 1.91 4.25 4.273 4.25 2.362 0 4.272-1.906 4.272-4.25 0-2.344-1.91-4.25-4.272-4.25zm-5.773 4.25c0-3.18 2.588-5.75 5.773-5.75 3.185 0 5.772 2.57 5.772 5.75 0 3.178-2.587 5.75-5.772 5.75s-5.773-2.572-5.773-5.75z"
      fill="#fff"
    />
    <defs>
      <filter
        id="Pin_geoloc_48_svg__filter0_dd"
        x={0.667}
        y={0.333}
        width={58.667}
        height={65.333}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={8} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={4} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgPingeoloc48 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPingeoloc48;
