import React from 'react';
import Box from '../atoms/Box';
import Text from '../atoms/Text';

import SvgTool124 from '../../icons/Tool124';
import SvgTool224 from '../../icons/Tool224';
import SvgTool324 from '../../icons/Tool324';
import SvgTool424 from '../../icons/Tool424';
import SvgTool524 from '../../icons/Tool524';
import SvgTool624 from '../../icons/Tool624';

const switchIconTool = (tool) => {
  switch (tool) {
    default:
    case 'octo':
      return SvgTool124;
    case 'square':
      return SvgTool224;
    case 'wrench':
      return SvgTool324;
    case 'hexa':
      return SvgTool424;
    case 'circle':
      return SvgTool524;
    case 'spring':
      return SvgTool624;
  }
};

export default function SectionTitle({
  color,
  tool = 'octo',
  styledText,
  children,
  ...rest
}) {
  const IconTool = switchIconTool(tool);
  return (
    <Box {...rest}>
      <Text color={color} mb={2} {...styledText}>
        <IconTool color={color} mr={2} mb={1} />
        <b>{children}</b>
      </Text>
    </Box>
  );
}
