import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '../../shared/atoms/Box';
import Button from '../../shared/atoms/Button';
import PopRight from '../../icons/PopRight';
import PopLeft from '../../icons/PopLeft';

const ButtonsWithPop = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <Box
      flexDirection={{ xs: 'column', sm: 'row' }}
      justifyContent="center"
      alignSelf="center"
      width={{ xs: '100%', sm: '85%', md: '75%', lg: '65%', xl: '50%' }}
      px={{ xs: 4, sm: 0 }}
      {...props}>
      <PopLeft display={{ xs: 'none', sm: 'block' }} />
      <Button mr={2} mt={1} to="/carte-cadeau/acheter" fullWidth>
        {t('buttongiftcardpopup.text')}
      </Button>
      <Button
        mt={1}
        variant="filledGrayLightest"
        to="/carte-cadeau/activer"
        fullWidth>
        {t('buttongiftcardpopup.cta')}
      </Button>
      <PopRight display={{ xs: 'none', sm: 'block' }} />
    </Box>
  );
};
export default ButtonsWithPop;
