import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { setLang } from '../actions/lang';
import reducers from '../reducers/index';
import { dumpStore, loadStore } from './reparation';

function configureStore({ ssr = false, forceLang = false }) {
  const preloadedStore = loadStore();
  const middlewares = composeWithDevTools(applyMiddleware(thunkMiddleware));
  const store = createStore(reducers, preloadedStore, middlewares);
  setLang(forceLang || store.getState().lang);
  if (forceLang) {
    store.lang = forceLang;
  }
  if (!ssr) {
    store.subscribe(() => {
      const { course, client, diag, lang } = store.getState();
      dumpStore({
        course,
        client,
        diag,
        lang,
      });
    });
  }
  return store;
}
export default configureStore;
