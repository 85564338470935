import { useContext } from 'react';
import { ModalCtx } from '../contexts/ModalContext';

const useAlert = () => {
  const { showAlert } = useContext(ModalCtx);

  return showAlert;
};

export const useContact = () => {
  const { showAlert, setShowModal } = useContext(ModalCtx);

  return { showAlert, setShowModal };
};

export default useAlert;
