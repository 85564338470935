import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M28 23a1 1 0 011 1v16a1 1 0 11-2 0V24a1 1 0 011-1zM29 17a1 1 0 10-2 0v2a1 1 0 102 0v-2z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 52c13.255 0 24-10.745 24-24S41.255 4 28 4 4 14.745 4 28s10.745 24 24 24zm0-2c12.15 0 22-9.85 22-22S40.15 6 28 6 6 15.85 6 28s9.85 22 22 22z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.007 16a1 1 0 00-2 0v2H39a1 1 0 100 2h1.007v2a1 1 0 102 0v-2H43a1 1 0 100-2h-.993v-2z"
      fill="#FFE249"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 36a1 1 0 10-2 0v1.966h-1a1 1 0 100 2h1V42a1 1 0 102 0v-2.034h1a1 1 0 100-2h-1V36z"
      fill="#28D898"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.002 10a1 1 0 10-2 0v2H8a1 1 0 100 2h1.002v2a1 1 0 102 0v-2h1.002a1 1 0 100-2h-1.002v-2z"
      fill="#1F77F9"
    />
  </svg>
);

const SIZE = 56;
const CAN_SET_COLOR = false;
const SvgInfosColor56 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgInfosColor56;
