import React, { useEffect, useState, createRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import * as api from '../../utils/api';
import { capitalizeFirstLetter, slugify } from '../../utils/utils';
// Components
import SearchMap from '../../components/shared/organisms/SearchMap';
import Box from '../../components/shared/atoms/Box';
import Container from '../../components/shared/atoms/Container';
import Heading from '../../components/shared/atoms/Heading';
import Link from '../../components/shared/atoms/Link';
import Text from '../../components/shared/atoms/Text';
import InfosComponents from '../../components/repair/InfosComponents';
// Hooks
import useShopsCities from '../../hooks/useShopsCities';
import { setAddress, setPlaceId, setPosition } from '../../actions/course';
import useZones from '../../hooks/useZones';

const SearchMapContainer = styled(Box)`
  height: 80vh;
  position: -webkit-relative;
  position: relative;
  margin: 0 -1.5rem;
  ${(p) => p.theme.mediaQueries.desktop} {
    height: 530px;
    margin: 0;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.xs}) {
    height: 70vh;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    max-height: 480px;
  }
`;

const RelativeContainer = styled(Container)`
  position: relative;
`;

const parseSlug = (slug) => {
  const splitSlug = slug.split('-');
  if (parseInt(splitSlug[splitSlug.length - 1])) {
    return slug.slice(0, slug.length - 6);
  }
  return slug;
};

const AnnuaireCity = () => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const [parsedSlug, setParsedSlug] = useState('');
  const dispatch = useDispatch();
  const { shopsCities, othersCities, name } = useShopsCities(parseSlug(slug));
  const [shops, setShops] = useState(shopsCities);
  const zones = useZones();
  const [refs, setRefs] = useState([]);
  const [indexShopClick, setIndexShopClick] = useState(null);
  const isInZone = useSelector((state) => state.course.isInZone);

  useEffect(() => {
    const refs = Array(shops.length)
      .fill(0)
      .map((_) => createRef());
    setRefs(refs);
  }, [shops]);

  useEffect(() => {
    if (shopsCities) setShops(shopsCities);
  }, [shopsCities]);

  useEffect(() => {
    const parsedSlug = parseSlug(slug);
    setParsedSlug(parsedSlug);
    dispatch(setPlaceId(''));
    dispatch(setPosition(parsedSlug));
    dispatch(setAddress(parsedSlug));
  }, [slug]);

  return (
    <RelativeContainer fluid>
      <Container mb={8}>
        <Helmet
          title={t('citydirectory.meta.title', { city: name })}
          meta={t('citydirectory.meta.description', { city: name })}
        />
        <Heading mt={4}>{t('citydirectory.heading', { city: name })}</Heading>
        <Text variant="large" mb={{ xs: 4, lg: 5 }} mt={-4}>
          {isInZone ?
            t('citydirectory.inzone.text', { city: name })
            : shopsCities && t('citydirectory.text', {
              city: name,
              workshops: shopsCities.map(shop => capitalizeFirstLetter(shop.value.name.toLowerCase()))
            })
          }
        </Text>
        <SearchMapContainer>
          <SearchMap
            inputSearch={Boolean(isInZone)}
            zones={zones}
            shops={shops}
            refs={refs}
            setIndexShopClick={setIndexShopClick}
          />
        </SearchMapContainer>
        <InfosComponents
          shops={shops}
          isInZone={isInZone}
          refs={refs}
          positionError={true}
          indexShopClick={indexShopClick}
          annuaire={true}
        />
        <Heading level={3} mt={6} mb={4}>
          {t('citydirectory.title', {
            city: capitalizeFirstLetter(parsedSlug),
          })}
        </Heading>
        <Box mb={5}>
          {othersCities.map((city, index) => (
            <Link
              key={index}
              to={`/annuaire/atelier-velo-trottinette/${slugify(city)}`}
              color="blue.electric"
              mr={2}
              mb={2}
              style={{ minWidth: 'auto' }}>
              {t('citydirectory.links', {
                city: capitalizeFirstLetter(city),
              })}
            </Link>
          ))}
        </Box>

        <Box mt={6}>
          <Heading level={2} mb={4}>
            {t('citydirectory.seo.title', { city: name })}
          </Heading>
          <Text>
            {t('citydirectory.seo.content', {
              city: name,
              mainShop: shopsCities?.[0]?.value?.name ? capitalizeFirstLetter(shopsCities[0].value.name.toLowerCase()) : '',
              secondShop: shopsCities?.[1]?.value?.name ? capitalizeFirstLetter(shopsCities[1].value.name.toLowerCase()) : '',
              nearbyCity1: othersCities?.[0] || '',
              nearbyCity2: othersCities?.[1] || ''
            })}
          </Text>
        </Box>
      </Container>
    </RelativeContainer>
  );
};

export default AnnuaireCity;
