import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 34 34"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M34,2.45A2.49,2.49,0,0,0,31.55,0H2.45A2.48,2.48,0,0,0,0,2.45V31.55A2.49,2.49,0,0,0,2.45,34H31.55A2.48,2.48,0,0,0,34,31.55V2.45ZM10.12,29.24H5.08V13.1h5.04ZM7.6,11a2.93,2.93,0,1,1,2.93-2.93A2.93,2.93,0,0,1,7.6,11Zm21.64,18.24H24.2V21.45c0-1.85-.03-4.22-2.57-4.22s-2.96,2-2.96,4.08v8H13.63V13.1h4.83v2.2h.07a5.3,5.3,0,0,1,4.78-2.63c5.11,0,6.05,3.36,6.05,7.74v8.83Z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgLinkedIn24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;

export default SvgLinkedIn24;
