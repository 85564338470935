import React from 'react';
// Shared components
import SectionBackground from '../shared/molecules/SectionBackground';
import Row from '../shared/atoms/Row';
import Container from '../shared/atoms/Container';
import Box from '../shared/atoms/Box';

const ContainerColor = ({
  img,
  alt,
  text,
  bg = null,
  title = null,
  reverse = false,
  ...props
}) => {
  return (
    <SectionBackground
      alignItems="center"
      bg={bg}
      py={{ xs: 5, lg: 10 }}
      {...props}>
      <Container>
        <Row gap={75} md>
          <Box>
            {img && <img src={img} alt={alt} />}
            {title}
          </Box>
          <Box>{text}</Box>
        </Row>
      </Container>
    </SectionBackground>
  );
};

export default ContainerColor;
