/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import Box from '../shared/atoms/Box';
import Text from '../shared/atoms/Text';
// Icons
import ToolKitFix from '../shared/ToolkitFix';
import Heading from '../shared/atoms/Heading';
import Separator from '../Separator';
import Button from '../shared/atoms/Button';

const StyledButton = styled(Button)`
  ${(p) =>
    p.selected &&
    css`
      color: ${p.theme.colors.primary.main} !important;
      box-shadow: inset 0px 0px 0px 2px ${p.theme.colors.primary.main};
    `}
`;

const PrestationDisplay = ({ price, duration, description }) => (
  <Box flexDirection="row" justifyContent="space-between" mb={1}>
    <Box width="100%">
      <Box flexDirection="row" mr={4}>
        <Box flex={1}>
          <Text coor="gray.dark">{description}</Text>
        </Box>
        <Box flex={1} alignItems="flex-end">
          <Text fontWeight="bold">{price}</Text>
        </Box>
      </Box>
      <Text color="gray.dark">{duration}min</Text>
    </Box>
  </Box>
);

export default function SectionInformations({
  details,
  product,
  products,
  createAppointment,
  setProduct,
}) {
  const [prestationPromote, setPrestationPromote] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (details && !!details.length && details[0]?.prestations_promote) {
      setPrestationPromote(
        details[0]?.prestations_promote.filter(
          (prestation) => prestation.product_id == product,
        ),
      );
    }
  }, [details, product]);

  return (
    <Box flex={5}>
      <Box borderRadius="xs" p={4}>
        {products && (
          <>
            <Heading level={6}>{t('standalone.indformations.title.1')}</Heading>
            <Box flexDirection="row" mb={4}>
              {products.map((p) => (
                <StyledButton
                  key={p.id}
                  variant="outlinedBlack"
                  selected={product == p.id}
                  size="compact"
                  onClick={() => setProduct(p.id)}
                  mr={2}
                  mb={2}
                  noFocusStyle={true}
                  style={{ minWidth: 'auto' }}>
                  {p.name}
                </StyledButton>
              ))}
            </Box>
          </>
        )}
        {!!prestationPromote.length &&
          prestationPromote.map((prestation, index) => (
            <Fragment key={index}>
              <PrestationDisplay {...prestation} onClick={createAppointment} />
              {index < prestationPromote.length - 1 && (
                <Separator axe="horizontal" my={2} />
              )}
            </Fragment>
          ))}
      </Box>
    </Box>
  );
}
