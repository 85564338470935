import React from 'react';
import { useTranslation } from 'react-i18next';

const ProductSelector = ({ partenaire, ...props }) => {
  const { t } = useTranslation();
  switch (partenaire) {
    case 'darty':
    case 'fnac':
      return <span>{t('scooter')}</span>;
    default:
      return <span>{t('bike')}</span>;
  }
};

export default ProductSelector;
