import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import Box from '../shared/atoms/Box';
import Link from '../shared/atoms/Link';
// Icons
import Question24 from '../icons/Question24';

const StyledHelp = styled(Box)`
  border: 1px solid ${(p) => p.theme.colors.gray.light};
`;

const LinkWithBorder = styled(Link)`
  flex: 1;
  border-right: 1px solid ${(p) => p.theme.colors.gray.light};
  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    border-right: none;
    border-bottom: 1px solid ${(p) => p.theme.colors.gray.light};
  }
`;

const HelpBox = ({ openModal }) => {
  const { t } = useTranslation();
  return (
    <StyledHelp
      width="100%"
      p={4}
      mb={5}
      borderRadius="xs"
      flexDirection={{ xs: 'column', lg: 'row' }}>
      <LinkWithBorder
        before={<Question24 />}
        color="blue.electric"
        onClick={() =>
          openModal({
            title: t('howmakingappointmentsworks'),
            content: t('helpbox.modals.1.text'),
          })
        }
        pr={{ xs: 0, lg: 4 }}
        pb={{ xs: 4, lg: 0 }}>
        {t('howmakingappointmentsworks')}
      </LinkWithBorder>
      <Link
        style={{ flex: 1 }}
        before={<Question24 />}
        color="blue.electric"
        onClick={() => openModal({ type: 'slots' })}
        pl={{ xs: 0, lg: 4 }}
        pt={{ xs: 4, lg: 0 }}>
        {t('desireddatenotavailable')}
      </Link>
    </StyledHelp>
  );
};

export default HelpBox;
