import React from 'react';
import styled from 'styled-components';

const LinearGradient = styled.span`
  background: linear-gradient(to right, #76d29b, #f24c37, #f2d84b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const TextGradiant = ({ children, ...props }) => {
  return <LinearGradient {...props}>{children}</LinearGradient>;
};

export default TextGradiant;
