import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { colors } from '../../styles/constants';
// Components
import Text from '../shared/atoms/Text';
import Box from '../shared/atoms/Box';

const Badge = styled('span')`
  border-radius: 8px;
  font-size: 11px;
  margin-left: 4px;
  padding: 3px 7px 3px 6px;
  background: ${(p) => p.theme.colors.gray.lightest};
  vertical-align: middle;
  font-weight: 600;
`;

const StyledBox = styled(Box)`
  position: relative;
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid
  ${(p) => (p.disabled ? colors.grey : p.theme.colors.gray.light)};
  transition: 0.2s ease;
  ${(p) =>
    !p.exemple &&
    !p.disabled &&
    css`
      cursor: pointer;
      &:hover {
        border-color: ${(p) => p.theme.colors.blue.electric};
      }
    `}
  ${(props) =>
    props.checked &&
    css`
      background-color: ${(p) => p.theme.colors.blue.electric};
      & p {
        color: white;
      }
    `}
  ${space}
`;

const Point = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: ${(p) => p.theme.colors.blue.electric};
  box-shadow: 0 0 0 2px white;
  ${space}
`;

const AbsolutePoint = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: -3px;
  left: -3px;
  background-color: ${(p) => p.theme.colors.blue.electric};
  box-shadow: 0 0 0 2px white;
  ${space}
`;

// Yield badge is a red badge with a white text positioned on the opposite side of AbsolutePoint, meaning at the top right corner
const YieldBadge = styled.div`
  position: absolute;
  top: -3px;
  right: -3px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: ${(p) => p.theme.colors.primary.main};
  box-shadow: 0 0 0 2px white;
  ${space}
`;

const AgendaCheckbox = ({
  text,
  onClick,
  mostAvailabilities = false,
  yieldAmount = 0,
  value = null,
  disabled = false,
  exemple = false,
  index,
  ...props
}) => {
  const { t } = useTranslation();
  const allSelect = text === t('allmorning') || text === t('allafternoon');
  return (
    <StyledBox
      $index={index}
      py={2}
      px={3}
      onClick={!disabled ? onClick : null}
      checked={!!value}
      disabled={!!disabled}
      exemple={exemple}
      {...props}>
      <Box flexDirection="row" alignItems={'center'} justifyContent={'center'}>
        {exemple && <Point mr={2} />}
        {!disabled && !!mostAvailabilities && <AbsolutePoint />}
        <Text
          color={
            disabled ? 'gray.dark' : allSelect ? 'blue.electric' : 'black'
          }
          variant="small"
          fontWeight={allSelect ? '600' : '500'}
          textAlign="center">
          {text}
        </Text>
        {!disabled && !!yieldAmount && <Badge> +{yieldAmount}€</Badge>}
      </Box>
    </StyledBox>
  );
};

export default AgendaCheckbox;
