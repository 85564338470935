import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx={16} cy={17} r={6} fill="#d5ff4f" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.003 2l.255.25C20.268 6.19 26 11.82 26 17.146c0 5.143-3.998 9.369-9.1 9.816V29a.9.9 0 11-1.8 0v-2.04C10 26.51 6 22.286 6 17.145c0-5.334 5.748-10.971 9.764-14.91L16.003 2zM24.2 17.145c0 4.128-3.175 7.567-7.3 8.008v-3.78l3.736-3.737a.9.9 0 10-1.272-1.272L16.9 18.827V13a.9.9 0 10-1.8 0v12.152c-4.124-.443-7.3-3.882-7.3-8.007 0-2.106 1.185-4.505 3.123-7.039 1.523-1.991 3.363-3.882 5.079-5.58 1.715 1.698 3.553 3.588 5.076 5.58 1.937 2.534 3.122 4.933 3.122 7.04z"
      fill="#1D1D1D"
    />
    <path
      d="M12 17l4 4"
      stroke="#1D1D1D"
      strokeWidth={1.8}
      strokeLinecap="round"
    />
  </svg>
);

const SIZE = 32;
const CAN_SET_COLOR = true;
const SvgAutumn32 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgAutumn32;
