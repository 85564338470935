import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 4.5a1 1 0 011-1h5a1 1 0 011 1v3a1 1 0 01-1 1h-5a1 1 0 01-1-1v-3zM6 7V5h4v2H6z"
      fill="#1D1D1D"
    />
    <path
      d="M7 10a1 1 0 11-2 0 1 1 0 012 0zM10 11a1 1 0 100-2 1 1 0 000 2z"
      fill="#1D1D1D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4a3 3 0 013-3h6a3 3 0 013 3v6a3 3 0 01-1.724 2.716l1.254 1.254a.75.75 0 11-1.06 1.06l-2-2a.741.741 0 01-.029-.03H5.559a.756.756 0 01-.029.03l-2 2a.75.75 0 01-1.06-1.06l1.254-1.254A3 3 0 012 10V4zm3-1.5h6A1.5 1.5 0 0112.5 4v6a1.5 1.5 0 01-1.5 1.5H5A1.5 1.5 0 013.5 10V4A1.5 1.5 0 015 2.5z"
      fill="#1D1D1D"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgTrain16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgTrain16;
