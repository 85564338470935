import { useEffect, useState } from 'react';

const useStrapi = (route) => {
  const [loading, setLoading] = useState(true);
  const [elements, setElements] = useState(false);
  useEffect(() => {
    (async () => {
      setLoading(true);
      let elements = await fetch(
        `https://api.roulezjeunesse.com/autodiags/${route}`,
      );
      elements = await elements.json();
      setElements(elements);
      setLoading(false);
    })();
  }, [route]);

  return [loading, elements];
};

export default useStrapi;
