import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M10.357 3C9.273 3 8.4 3 7.691 3.058c-.728.06-1.369.185-1.96.487A5 5 0 003.544 5.73c-.302.592-.428 1.233-.487 1.961C3 8.4 3 9.273 3 10.357v1.286c0 1.084 0 1.958.058 2.666.06.729.185 1.369.487 1.961a5 5 0 002.185 2.185c.592.302 1.233.428 1.961.487C8.4 19 9.273 19 10.357 19H12a1 1 0 100-2h-1.6c-1.137 0-1.929 0-2.546-.051-.605-.05-.953-.142-1.216-.276a3.003 3.003 0 01-.459-.289l5.646-4.058 2.443 2.628a1 1 0 101.464-1.362L12.09 9.674l-6.972 5.01a5.946 5.946 0 01-.066-.538C5.001 13.529 5 12.736 5 11.6v-1.2c0-1.137 0-1.929.051-2.546.05-.605.142-.953.276-1.216a3 3 0 011.311-1.311c.263-.134.611-.226 1.216-.276C8.471 5.001 9.264 5 10.4 5h1.2c1.137 0 1.929 0 2.546.051.605.05.953.142 1.216.276a3 3 0 011.311 1.311c.134.263.226.611.276 1.216.05.617.051 1.41.051 2.546V12a1 1 0 102 0v-1.643c0-1.084 0-1.958-.058-2.666-.06-.728-.185-1.369-.487-1.96a5 5 0 00-2.185-2.186c-.592-.302-1.232-.428-1.961-.487C13.6 3 12.727 3 11.643 3h-1.286z"
      fill="#000"
    />
    <path
      d="M8.5 10a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM15 18a1 1 0 011-1h1v-1a1 1 0 112 0v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 01-1-1z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgPicturePlus24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgPicturePlus24;
