import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import SearchMap from '../shared/organisms/SearchMap';
import Box from '../shared/atoms/Box';
import Heading from '../shared/atoms/Heading';
import InfosComponents from './InfosComponents';

const SearchMapContainer = styled(Box)`
  height: 80vh;
  position: -webkit-relative;
  position: relative;
  margin: 0 -1.5rem;
  ${(p) => p.theme.mediaQueries.desktop} {
    height: 530px;
    margin: 0;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.xs}) {
    height: 70vh;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    max-height: 480px;
  }
`;

export default function RepairDefaultHomeAndShops({
  zones,
  refs,
  setIndexShopClick,
  shops,
  positionError,
  indexShopClick,
  isInZone,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Heading level={2} as="h1" mb={{ xs: 4, lg: 5 }}>
        {t('repairdefault.heading')}
      </Heading>
      <SearchMapContainer>
        <SearchMap
          zones={zones}
          shops={shops}
          inputSearch={shops && shops.length && !isInZone ? 'light' : true}
          refs={refs}
          setIndexShopClick={setIndexShopClick}
        />
      </SearchMapContainer>
      <InfosComponents
        isInZone={isInZone}
        shops={shops}
        refs={refs}
        positionError={positionError}
        indexShopClick={indexShopClick}
      />
    </>
  );
}
