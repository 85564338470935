import React, { createContext, useState, useEffect } from 'react';

import * as api from '../utils/api';

const defaultCtx = {
  courses: [],
  loading: true,
  pendingCount: 0,
};
export const CoursesCtx = createContext(defaultCtx);

export default function CoursesProvider({ children }) {
  const [courses, setCourses] = useState(defaultCtx.courses);
  const [loading, setLoading] = useState(defaultCtx.loading);
  const [pendingCount, setPendingCount] = useState(defaultCtx.pendingCount);

  const getCourses = async () => {
    setLoading(true);
    const res = await api.get('/courses');
    const coursesUpdated = Array.isArray(res) ? res.reverse() : [];
    const count = coursesUpdated.filter(({ status }) => status === '').length;
    setPendingCount(count);
    setCourses(coursesUpdated);
    setLoading(false);
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <CoursesCtx.Provider value={{ courses, pendingCount, getCourses, loading }}>
      {children}
    </CoursesCtx.Provider>
  );
}
