import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 43 141"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.8 1.273a1.5 1.5 0 00-1.732 2.45c4.99 3.524 13.187 11.552 20.676 22.155 7.484 10.596 14.16 23.62 16.262 37.113 4.427 28.4-2.1 54.613-10.932 72.192l-1.753-6.531a1.501 1.501 0 00-1.837-1.061 1.498 1.498 0 00-1.06 1.836l2.715 10.122.388 1.448 1.45-.387 10.133-2.712a1.5 1.5 0 10-.777-2.898l-6.713 1.797c9.145-18.069 15.908-45.032 11.35-74.268-2.2-14.112-9.14-27.57-16.775-38.382C16.564 13.343 8.135 5.042 2.799 1.273z"
      fill="#000"
    />
  </svg>
);

const SIZE = 134;
const CAN_SET_COLOR = true;
const SvgArrowLinkSalary = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgArrowLinkSalary;
