import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M7.7 9.5a.8.8 0 01.8-.8h8a.8.8 0 010 1.6h-8a.8.8 0 01-.8-.8zM8.5 5.7a.8.8 0 100 1.6h5a.8.8 0 000-1.6h-5z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.4 2a2.9 2.9 0 00-2.9 2.9v14.6h.007A2.7 2.7 0 007.2 22h12.7a.9.9 0 000-1.8H7.2a.9.9 0 010-1.8h6.698c1.215.005 2.782.005 4.009.003a2.897 2.897 0 002.893-2.898V4.9A2.9 2.9 0 0017.9 2H7.4zm7.77 14.603c.924.002 1.906.001 2.735 0 .605 0 1.095-.49 1.095-1.098V4.9a1.1 1.1 0 00-1.1-1.1H7.4a1.1 1.1 0 00-1.1 1.1v11.854c.282-.1.584-.154.9-.154h7.894a.92.92 0 01.077.003z"
      fill="#000"
    />
  </svg>
);

const SIZE = 24;
const CAN_SET_COLOR = true;
const SvgBook24 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgBook24;
