import * as React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

const SVG = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 3.5c0 .818-.393 1.544-1 2v5a3 3 0 01-3 3H4a3 3 0 01-3-3v-5a3 3 0 013-3h6.208A2.5 2.5 0 0115 3.5zm-3.555 2.267A2.504 2.504 0 0110.05 4H4a1.5 1.5 0 00-1.496 1.385L7.5 7.202l3.945-1.435zM2.5 6.98v3.52A1.5 1.5 0 004 12h7a1.5 1.5 0 001.5-1.5V6.98l-5 1.818-5-1.818z"
      fill="#000"
    />
  </svg>
);

const SIZE = 16;
const CAN_SET_COLOR = true;
const SvgNotificationdot16 = styled(SVG)`
  min-height: ${SIZE}px;
  min-width: ${SIZE}px;
  max-height: ${SIZE}px;
  max-width: ${SIZE}px;
  cursor: ${(p) => (p.$pointer || p.onClick ? 'pointer' : 'default')};
  ${(p) =>
    CAN_SET_COLOR &&
    css`
      path {
        fill: ${themeGet(`colors.${p.color}`, p.color)};
      }
    `}
  ${space}
`;
export default SvgNotificationdot16;
