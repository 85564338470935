import React, { useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import * as api from '../../../utils/api';
import Box from '../../shared/atoms/Box';
import Heading from '../../shared/atoms/Heading';
import Text from '../../shared/atoms/Text';
import Separator from '../../Separator';
import Input from '../../shared/atoms/Input';
import Button from '../../shared/atoms/Button';
import Link from '../../shared/atoms/Link';
import CardsCheckout from '../../shared/molecules/CardsCheckout';
// Icons
import User16 from '../../icons/User16';
import Message16 from '../../icons/Message16';
import Happy32Color from '../../icons/Happy32Color';
import Pin16 from '../../icons/Pin16';

const StyledBoxRecapMain = styled(Box)`
  @media (max-width: 410px) {
    flex-direction: column;
  }
`;

const StyledLasBoxRecap = styled(Box)`
  @media (max-width: 410px) {
    margin-top: 8px;
  }
`;
const signupValidationSchema = Yup.object().shape({
  emailGiftForm: Yup.string()
    .email("L'email est invalide")
    .required('Ce champs est requis'),
  prenomGiftForm: Yup.string().required('Ce champs est requis'),
  nomGiftForm: Yup.string().required('Ce champs est requis'),
});

const BuyGiftCardRecapPay = ({
  giftcard,
  setGiftcard,
  nextStep,
  previousStep,
  client,
  loading,
  setLoading,
  setShowModal,
}) => {
  const formikRef = useRef();
  const [cbs, setCbs] = useState([]);
  const { t } = useTranslation();
  const [card, setCard] = useState(null);
  const [stripePayment, setStripePayment] = useState({
    cvc: false,
    number: false,
    expiry: false,
    payment_method: '',
    message: '',
    state: null,
    complete: false,
  });

  const _setCard = (id) => {
    setCard(id);
  };

  const _fetchCards = async () => {
    if (!client || !client.id) {
      return;
    }
    const clientCbs = await api.get(`/clients/${client.id}/sources`);
    let clientCard = clientCbs.length ? clientCbs[0].id : '__ADD__';
    const defaultCard = clientCbs.find((c) => c.default);
    if (typeof defaultCard !== 'undefined') {
      clientCard = defaultCard.id;
    }
    setCbs(clientCbs);
    setCard(clientCard);
    setLoading(false);
  };

  const sendRequest = () => {
    const { state } = stripePayment;
    if (state === 'error') {
      setStripePayment({
        cvc: false,
        number: false,
        expiry: false,
        message: '',
        state: null,
      });
    } else {
      setStripePayment({ ...stripePayment, state: 'send' });
    }
  };
  const createCardConnectedUser = async () => {
    const response = await api.post('/giftcards', {
      amount: giftcard.customAmount ? giftcard.customAmount : giftcard.amount,
      giftcard_url: `https://www.roulezjeunesse.com/static/img/pictos/giftcard/gift-card-${giftcard.giftcardChoice}.png`,
      recipient_name: giftcard.recipientName,
      message: giftcard.message,
    });
    if (response.error_message) {
      setStripePayment({
        ...stripePayment,
        state: 'error',
        message: response.error_message,
      });
      setLoading(false);
    } else {
      setGiftcard({
        ...giftcard,
        amount: response.giftcard.amount,
        customAmount: '',
        id: response.giftcard.id,
        code: response.giftcard.code,
        created_at: response.giftcard.created_at,
      });
      nextStep();
      setLoading(false);
    }
  };

  useEffect(() => {
    _fetchCards();
    if (client.id) {
      formikRef.current.setFieldValue('prenomGiftForm', client.prenom);
      formikRef.current.setFieldValue('nomGiftForm', client.nom);
      formikRef.current.setFieldValue('emailGiftForm', client.email);
    }
  }, [client]);

  useEffect(() => {
    const { state } = stripePayment;
    if (state !== null) {
      setLoading(true);
    }
    if (state === 'error') {
      setLoading(false);
    }
    if (state === 'succes' || (card && card !== '__ADD__')) {
      if (client.id) {
        createCardConnectedUser();
      } else {
        nextStep();
        setLoading(false);
      }
    }
  }, [stripePayment]);

  return (
    <>
      <Box flex={1} mr={{ xs: 0, lg: 9 }}>
        <Box
          borderColor="gray.light"
          borderWidth="1px"
          borderRadius="sm"
          borderStyle="solid"
          p={4}>
          <StyledBoxRecapMain flexDirection="row" alignItems="flex-start">
            <img
              style={{ objectFit: 'contain', marginRight: 16 }}
              width={150}
              height={95}
              src={`/static/img/pictos/giftcard/gift-card-${giftcard.giftcardChoice}.png`}
            />
            <StyledLasBoxRecap>
              <Text fontSize="lg" fontWeight={2}>
                {t('buygiftcardrecap.texts.1', {
                  customAmount: giftcard.customAmount
                    ? giftcard.customAmount
                    : giftcard.amount,
                })}
              </Text>
              <Box mt={2} flexDirection="row">
                <User16 mr={1} mt={1} />
                <Text>{giftcard.recipientName}</Text>
              </Box>
              <Box flexDirection="row">
                <Message16 mr={1} mt={1} />
                <Text color={giftcard.message ? null : 'gray.dark'}>
                  {giftcard.message
                    ? giftcard.message
                    : t('buygiftcardrecap.texts.2')}
                </Text>
              </Box>
              <Box flexDirection="row">
                <Pin16 mr={1} mt={1} />
                <Text>
                  Intervention sur{' '}
                  <b>
                    Paris, Bordeaux, Lille, Lyon, Strasbourg, Nantes ou Toulouse
                  </b>
                </Text>
              </Box>
              <Link
                color="blue.electric"
                mt={2}
                onClick={() => previousStep()}>
                {t('modify.cta')}
              </Link>
            </StyledLasBoxRecap>
          </StyledBoxRecapMain>
          <Separator axe="horizontal" my={4} />
          <Box flexDirection="row" justifyContent="space-between">
            <Text color="black">{t('total')}</Text>
            <Text color="black" fontSize="large" fontWeight={2}>
              {giftcard.customAmount ? giftcard.customAmount : giftcard.amount}€
            </Text>
          </Box>
        </Box>
      </Box>
      <Box flex={1}>
        <Heading level={6} mb={4} mt={{ xs: 5, lg: 0 }}>
          {t('buygiftcardrecap.texts.3')}
        </Heading>
        <Formik
          ref={formikRef}
          initialValues={{
            prenomGiftForm: client.id ? client.prenom : '',
            nomGiftForm: client.id ? client.nom : '',
            emailGiftForm: client.id ? client.email : '',
          }}
          validationSchema={signupValidationSchema}
          onSubmit={() => sendRequest()}>
          {({ values, errors, submitCount }) => (
            <Form>
              <Box flexDirection={{ xs: 'column', lg: 'row' }}>
                <Field
                  name="prenomGiftForm"
                  render={({ field }) => (
                    <Input
                      mr={{ xs: 0, lg: 2 }}
                      style={{ flex: 1 }}
                      mb={{ xs: 2, lg: 4 }}
                      color="black"
                      data-testid="input-prenom"
                      bg="white"
                      label={t('firstname')}
                      importantLabel
                      disabled={client.id}
                      placeholder={'Jean-Michel'}
                      error={!!submitCount && errors.prenomGiftForm}
                      {...field}
                    />
                  )}
                />
                <Field
                  name="nomGiftForm"
                  render={({ field }) => (
                    <Input
                      ml={{ xs: 0, lg: 2 }}
                      style={{ flex: 1 }}
                      mb={{ xs: 2, lg: 4 }}
                      color="black"
                      data-testid="input-nom"
                      bg="white"
                      label={t('lastname')}
                      importantLabel
                      placeholder={'Rustine'}
                      disabled={client.id}
                      error={!!submitCount && errors.nomGiftForm}
                      {...field}
                    />
                  )}
                />
              </Box>
              <Field
                name="emailGiftForm"
                render={({ field }) => (
                  <Input
                    mb={{ xs: 2, lg: 4 }}
                    color="black"
                    bg="white"
                    data-testid="input-email"
                    placeholder="jm-rustine@gmail.com"
                    label={t('email')}
                    importantLabel
                    disabled={client.id}
                    error={!!submitCount && errors.emailGiftForm}
                    {...field}
                  />
                )}
              />
              {!client.id && (
                <Box
                  flexDirection="row"
                  bg="gray.lightest"
                  borderRadius="xs"
                  p={4}
                  mb={6}
                  mt={5}>
                  <Box mr={4}>
                    <Heading level={6}>{t('buygiftcardrecap.texts.4')}</Heading>
                    <Box flexDirection="row">
                      <Text>
                        <Trans
                          i18nKey="buygiftcardrecap.texts.5"
                          components={{
                            lien: (
                              <Link
                                color="black"
                                onClick={() => setShowModal(true)}
                              />
                            ),
                            annotation: <u />,
                          }}
                        />
                      </Text>
                    </Box>
                  </Box>
                  <Happy32Color />
                </Box>
              )}
              <CardsCheckout
                {...{
                  cbs,
                  _setCard,
                  card,
                  client,
                  stripePayment,
                  setStripePayment,
                  loading,
                }}
                isNotLoggedIn={!client.id}
                clientNotConnected={{
                  prenom: values.prenomGiftForm,
                  nom: values.nomGiftForm,
                  email: values.emailGiftForm,
                }}
                amount={
                  giftcard.customAmount
                    ? giftcard.customAmount
                    : giftcard.amount
                }
                giftcard={giftcard}
                setGiftcard={setGiftcard}
              />
              <Button
                fullWidth
                type="submit"
                loading={loading}
                className={'giftcard-buy-btn'}>
                {t('placeorder')}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default BuyGiftCardRecapPay;
