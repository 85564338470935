import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
// Shared Components
import { Trans, useTranslation } from 'react-i18next';
import Container from '../components/shared/atoms/Container.jsx';
import HeroShared from '../components/shared/molecules/HeroShared';
import Box from '../components/shared/atoms/Box.jsx';
import ContainerColor from '../components/reviews/ContainerColor.jsx';
import Satisfaction from '../components/reviews/Satisfaction.jsx';
import CustomerReviews from '../components/reviews/CustomerReviews.jsx';
import Heading from '../components/shared/atoms/Heading.jsx';
import HowItWorks from '../components/shared/organisms/HowItWorks.jsx';
import Text from '../components/shared/atoms/Text.jsx';

const StyledSpan = styled.span`
  color: ${(p) => p.theme.colors.primary.main};
`;

const StyledImg = styled.img`
  height: 56px;
  width: 56px;
`;

const Avis = () => {
  const { t } = useTranslation();

  // TODO to be removed
  return ("Avis");


  return (
    <Container fluid>
      <Helmet
        title={t('avis.meta.title')}
        meta={[
          {
            name: 'description',
            content: t(`avis.meta.description`),
          },
        ]}
      />
      <HeroShared
        title={t('avis.titles.1')}
        colorHexa="yellow.main"
        colorSquare="green.lighter"
        buttonLink={'/reparation'}
        sizeHeader={70}
        text={
          <>
            <Text mb={4}>{t('avis.texts.1')}</Text>
          </>
        }
        buttonText={t('avis.cta.1')}
        color="gray.lightest"
        img="/static/img/photos/Avis/hero-testimonials.png"
      />
      <CustomerReviews />
      <Satisfaction />
      <ContainerColor
        bg="violet.lighter"
        img="/static/img/photos/Avis/testimonial-story-1.png"
        alt={t('avis.alt.1')}
        text={
          <>
            <Heading level={3} mb={4}>
              {t('avis.headings.1')}
            </Heading>
            <Text variant="large" mb={4}>
              {t('avis.texts.2')}
              <br />
              <br />
              {t('avis.texts.3')}
            </Text>
            <Text fontWeight="bold" color="black">
              {t('avis.texts.4')}
            </Text>
          </>
        }
      />
      <ContainerColor
        reverse
        bg="green.lighter"
        img="/static/img/photos/Avis/testimonial-story-2.png"
        alt={t('avis.alt.2')}
        text={
          <>
            <Heading level={3} mb={4}>
              {t('avis.headings.2')}
            </Heading>
            <Text variant="large" mb={4}>
              {t('avis.texts.5')}
              <br />
              <br />
              {t('avis.texts.6')}
            </Text>
            <Text fontWeight="bold" color="black">
              {t('avis.texts.7')}
            </Text>
          </>
        }
      />
      <ContainerColor
        bg="blue.lighter"
        title={<Heading level={2}>{t('avis.headings.3')}</Heading>}
        text={
          <>
            <Text
              color="black"
              fontStyle="italic"
              fontWeight="600"
              fontSizes="20px"
              mb={4}
              mt={{ xs: '-75px', md: 0 }}
              style={{ lineHeight: '27px' }}>
              <Trans
                t={t}
                i18nKey="avis.texts.8"
                components={{
                  styledSpan: <StyledSpan />,
                }}
              />
            </Text>
            <Box>
              <Box flexDirection="row">
                <StyledImg
                  src="/static/img/photos/Avis/david.png"
                  alt="David"
                />
                <Box ml={2} justifyContent="center">
                  <Trans
                    t={t}
                    i18nKey="avis.texts.9"
                    components={{
                      text: <Text />,
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </>
        }
      />
      <HowItWorks bg="gray.lightest" />
    </Container>
  );
};

export default Avis;
