import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Heading from '../atoms/Heading';
import Box from '../atoms/Box';
import Text from '../atoms/Text';
import Container from '../atoms/Container';
import Button from '../atoms/Button';

import SvgFacebook24 from '../../icons/Facebook24';
import SvgGoogle24 from '../../icons/Google24';
import SvgAngleRight16 from '../../icons/AngleRight16';
import Card from '../atoms/Card';
import SvgStarfill24 from '../../icons/StarFill24';
import Avatar from '../atoms/Avatar';
import SectionBackground from '../molecules/SectionBackground';

const AuthorName = styled(Text)`
  font-weight: bold;
  font-style: italic;
`;

const SocialReview = ({ brand, count, grade, t, ...rest }) => {
  const BrandIcon = brand === 'Facebook' ? SvgFacebook24 : SvgGoogle24;
  return (
    <Box alignItems="baseline" flexDirection="row" {...rest}>
      <Box flexDirection="row" alignItems="baseline">
        <Heading m={0} level={5} color="black">
          {grade}
        </Heading>
        <Text>/5&nbsp;</Text>
      </Box>
      <Text>
        {t('reviews.texts.1', { brand })}
        {<BrandIcon ml={1} color="black" mt="-4px" />}
      </Text>
    </Box>
  );
};

const Stars = ({ ...props }) => (
  <Box flexDirection="row" {...props}>
    <SvgStarfill24 color="primary.main" />
    <SvgStarfill24 color="primary.main" />
    <SvgStarfill24 color="primary.main" />
    <SvgStarfill24 color="primary.main" />
    <SvgStarfill24 color="primary.main" />
  </Box>
);

const ReviewCard = ({ content, author, city, avatarUrl, t, ...rest }) => (
  <Card bg="white" {...rest}>
    <Stars mb={4} />
    <Text mb={6}>
      <i>{content}</i>
    </Text>
    <Box flexDirection="row">
      <Avatar src={avatarUrl} alt={t('reviews.texts.2')} mr={2} />
      <Box>
        <AuthorName color="black">{author}</AuthorName>
        <Text variant="medium">{city}</Text>
      </Box>
    </Box>
  </Card>
);

export default function Reviews({ forceResponsive = false, ...rest }) {
  const { t } = useTranslation();
  return (
    <SectionBackground py={{ xs: 7, md: 10 }} bg="green.lighter" {...rest}>
      <Container>
        <Box
          alignItems="center"
          flexDirection={
            forceResponsive ? 'column' : { xs: 'column', lg: 'row' }
          }>
          <Box
            width={forceResponsive ? 1 : { xs: 1, md: 3 / 4, lg: 1 }}
            pr={{ xs: 0, lg: 4 }}
            pb={{ xs: 4, lg: 0 }}>
            <Heading level={2} color="black" mb={4}>
              {t('reviews.headings.1')}
            </Heading>
            <Box flexDirection="row" mb={5}>
              <SocialReview
                grade="4.6"
                count="1156"
                brand="Google"
                mr={5}
                t={t}
              />
              <SocialReview grade="4.9" count="968" brand="Facebook" t={t} />
            </Box>
            <Button
              to="/avis"
              variant="outlinedBlack"
              after={<SvgAngleRight16 />}
              animation>
              {t('reviews.cta')}
            </Button>
          </Box>
          <Box
            alignItems="center"
            pl={{ xs: 0, lg: 4 }}
            pt={forceResponsive ? 4 : { xs: 4, lg: 0 }}>
            <Box
              width={forceResponsive ? 1 : { xs: 1, md: 3 / 4, lg: 1 }}
              flexDirection={
                forceResponsive ? 'column' : { xs: 'column', lg: 'row' }
              }>
              <ReviewCard
                content={t('reviews.texts.3')}
                t={t}
                author="Pauline"
                city="Paris"
                avatarUrl="/static/img/photos/pauline.png"
                mr={forceResponsive ? 0 : { xs: 0, lg: 3 }}
                mb={forceResponsive ? 6 : { xs: 6, lg: 0 }}
              />
              <ReviewCard
                content={t('reviews.texts.4')}
                t={t}
                author="Nicolas"
                city="Strasbourg"
                avatarUrl="/static/img/photos/nicolas.png"
                ml={forceResponsive ? 0 : { xs: 0, lg: 3 }}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </SectionBackground>
  );
}
