import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// Components
import moment from 'moment';
import Box from '../shared/atoms/Box';
import HourPicker from '../shared/molecules/HourPicker';
import useResaShop from '../../hooks/useResaShop';
import AgendaCheckbox from './AgendaCheckbox';
import { readableZeroedHour } from '../../utils/reparation';

const ListSlots = ({
  amSlots,
  averageOptiv,
  toggleSlot,
  day,
  pmSlots,
  index: dayIndex,
  shopId,
}) => {
  const { slots } = useSelector((s) => s.course);
  const { t } = useTranslation();
  const isShop = !!shopId;
  const handleChangeDate = (date) => {
    toggleSlot(
      date.format('YYYY-MM-DD HH:mm:00'),
      date.format('YYYY-MM-DD HH:mm:00'),
    );
  };

  return (
    <Box flexDirection="row" p={4}>
      <Box width="100%" mr={2}>
        {amSlots.map((slot, index) =>
          isShop ? (
            <HourPicker
              key={`${index}_am`}
              hour={slot.min}
              date={day}
              selectedSlot={slots[0]?.available_min}
              onChange={handleChangeDate}
              slot={{ 0: slot[0], 15: slot[15], 30: slot[30], 45: slot[45] }}
              disabled={slot.full}
            />
          ) : (
            <div key={`${slot.min}${slot.max}`}>
              <AgendaCheckbox
                mostAvailabilities={
                  !shopId && slot.averageAvailabilities >= averageOptiv
                }
                yieldAmount={slot.yieldAmount}
                disabled={slot.full}
                className="dateTimeModule-checkbox"
                data-testid={`am-${dayIndex}-${index}`}
                text={
                  slot.full ? (
                    <small>{t('full')}</small>
                  ) : (
                    `${readableZeroedHour(slot.min)}-${readableZeroedHour(
                      slot.max,
                    )}`
                  )
                }
                onClick={() =>
                  toggleSlot(
                    day.set({ hour: slot.min }).format('YYYY-MM-DD HH:00:00'),
                    day.set({ hour: slot.max }).format('YYYY-MM-DD HH:00:00'),
                  )
                }
                value={slot.checked}
              />
            </div>
          ),
        )}
      </Box>
      <Box width="100%" ml={2}>
        {pmSlots.map((slot, index) =>
          isShop ? (
            <HourPicker
              key={`${index}_pm`}
              hour={slot.min}
              date={day}
              onChange={handleChangeDate}
              selectedSlot={slots[0]?.available_min}
              slot={{ 0: slot[0], 15: slot[15], 30: slot[30], 45: slot[45] }}
              disabled={slot.full}
            />
          ) : (
            <div key={`${slot.min}${slot.max}`}>
              <AgendaCheckbox
                index={index}
                mostAvailabilities={slot.averageAvailabilities >= averageOptiv}
                disabled={slot.full}
                data-testid={`pm-${dayIndex}-${index}`}
                className="dateTimeModule-checkbox"
                yieldAmount={slot.yieldAmount}
                text={
                  slot.full ? (
                    <small>{t('full')}</small>
                  ) : (
                    `${readableZeroedHour(slot.min)}-${readableZeroedHour(
                      slot.max,
                    )}`
                  )
                }
                onClick={() =>
                  toggleSlot(
                    day.set({ hour: slot.min }).format('YYYY-MM-DD HH:00:00'),
                    day.set({ hour: slot.max }).format('YYYY-MM-DD HH:00:00'),
                  )
                }
                value={slot.checked}
              />
            </div>
          ),
        )}
      </Box>
    </Box>
  );
};

export default ListSlots;
